import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/vanhelmont.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series II, volume 3</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 207-208<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 18 October 1696<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (123k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Francis Mercury van Helmont (18 October 1696)</title>
            <meta name="description" content="An English translation of a LETTER TO FRANCIS MERCURY VAN HELMONT by Gottfried Wilhelm Leibniz, from 18 October 1696" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO FRANCIS MERCURY VAN HELMONT</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A II 3, p207</b>]<br /><br />

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Extract from my response<span style={{float: 'right'}}>Hanover, 18 October 1696</span><br />
        <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I thank you, Sir, for the <var>Chemical Aphorisms</var>,<a name="1" href="#note1"><sup>1</sup></a> the English title of the book about hell,<a name="2" href="#note2"><sup>2</sup></a> and for the <var>Two Hundred Queries in Favour of the Revolution of Souls</var>.<a name="3" href="#note3"><sup>3</sup></a> I will be sure to send to Professor von der Hardt, for Duke Rudolph August, the additions to the <var>Harmony of the Evangelists</var> by Mr Rosenroth,<a name="4" href="#note4"><sup>4</sup></a> and I will inform Duke Anton Ulrich of what you want to do with the other work, whose title is <var>Messias Puer</var>.<a name="5" href="#note5"><sup>5</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I see that the aphorisms that require that &#9794; and &#9792; be joined with &#9793; are marked by a line which seems to cross them out. I believe this is not unintentional.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I wish that the many fine thoughts you still have in metaphysics, physics, mechanics, and ethics will be published in your lifetime. For although nothing perishes in the world, nevertheless if they are published earlier they will be able to bear even more fruit.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;As nearly all of your two hundred queries are based on Holy Scripture, Madam the Electress,<a name="6" href="#note6"><sup>6</sup></a> who would rather see how your views could be confirmed even further by reason, would like one or two hundred proofs based on reason, [<b>A II 3, p208</b>] order, and experience. And as the queries are entirely unconnected and are effectively distinct, she would be delighted to see something similar with regard to arguments. And I remember, Sir, having already spoken to you about this. It would be sufficient for you to take a piece of paper and, without committing yourself to filling it all at once with a coherent meditation, which would tire you as well as the readers little used to meditations, you could from time to time write down separate proofs or bits of evidence as they come to mind, without troubling yourself about the order or connection, which would be easy to give them afterwards, if needs be, after you had amassed the material, all the more so since then there would be no rush.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Madam the Duchess of Orl&#233;ans advances against your example of hares the contrary experience in places known to her, where they have been destroyed through hunting.<a name="7" href="#note7"><sup>7</sup></a> But everything should be understood with moderation. The Spanish have certainly destroyed the men of some islands of America. The question is merely whether it is true, according to your opinion, that when enough of some species is left to propagate the race, births are more frequent after a great number of deaths. This is something which deserves to be verified more exactly.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The stories of Madam, your relation, and the daughter of Mr Rosenroth, are curious and extraordinary, and Madam the Electress will share them with Madam d'Orl&#233;ans.

                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> With his letter of 26 October 1696 (see A II 3, 206), van Helmont had enclosed a copy of [Francis Mercury van Helmont], <var>CLIII aphorismes chymiques: ausquels on peut facilement rapporter tout ce qui regarde la CHYMIE</var> (Paris: Laurent d'Houry, 1692). The English edition was published earlier: [Francis Mercury van Helmont], <var>One Hundred Fifty Three Chymical Aphorisms. Briefly containing Whatsoever Belongs to the Chymical Science</var> (London: W. Cooper and D. Newman, 1688).<br />
                <a name="note2" href="#2">2.</a> With his letter of 26 October 1696, van Helmont had sent Leibniz a page with the title of the following book: Samuel Richardson, <var>A Treatise of Hell</var> (London 1657). Van Helmont was likely thinking of Samuel Richardson, <var>Of the Torments of Hell. The Foundation and Pillars thereof Discovered, Searched, Shaken and Removed</var> (London, 1658).<br />
                <a name="note3" href="#3">3.</a> With his letter of 26 October 1696, van Helmont had enclosed a copy of [Francis Mercury van Helmont], <var>De revolutione animarum humanarum quanta sit istius doctrinae cum veritate christianae religionis conformitas problematum centuriae duae</var> (Amsterdam, 1690). The English edition was published earlier: [Francis Mercury van Helmont], <var>Two Hundred Queries Moderately Propounded Concerning the Doctrine of the Revolution of Humane Souls, and Its Conformity to the Truths of Christianity</var> (London: Rob Kettlewell, 1684).<br />
                <a name="note4" href="#4">4.</a> Van Helmont had sent Leibniz some additions to [Christian Knorr von Rosenroth], <var>Harmonia Evangeliorum oder Zusammenf&#252;gung der 4 H. Evangelisten</var> (Frankfurt 1672).<br />
                <a name="note5" href="#5">5.</a> This is the title of an unpublished work by Christian Knorr von Rosenroth (1636-1689), who was an intimate of Francis Mercury van Helmont and translator of many Kabbalistic texts. The manuscript of <var>Messias Puer</var> is now lost.<br />
                <a name="note6" href="#6">6.</a> Electress Sophie of Hanover (1630-1714), mutual friend of both Leibniz and van Helmont.<br />
                <a name="note7" href="#7">7.</a> In a text composed for Leibniz, Electress Sophie, and Elizabeth Charlotte, Duchess of Orl&#233;ans, in which he tried to establish the revolution of souls, van Helmont had written: &quot;Of the hare it is often said, and it has even passed into proverb, that the more of them are caught, the more of them are found. Notwithstanding the fact that the hare looks for its food near to men, that it is small and timid, and that it has no defense but its escape.&quot; Francis Mercury van Helmont, &quot;A R&#233;sum&#233; of Philosophy&quot; (September 1696), in <var>Leibniz and the Two Sophies</var>, ed. and trans. Lloyd Strickland (Toronto, 2011), 130. To this, the Duchess had responded: &quot;The example of the hares is completely the opposite - I have seen it in Versailles: over a short period of time many were caught, and in places where over fifty a year were caught scarcely three have been found since then.&quot; See Sophie to Leibniz (early October 1696), in <var>Leibniz and the Two Sophies</var>, 132.
        <br /><br /><br />
                &#169; Lloyd Strickland 2018
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
