import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/sophie1694.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series I, volume 10</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 89-90<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 6/16 December 1694<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (11k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Electress Sophie (6/16 December 1694)</title>
            <meta name="description" content="An English translation of a LETTER TO ELECTRESS SOPHIE by Gottfried Wilhelm Leibniz, from 6/16 December 1694" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO ELECTRESS SOPHIE</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<div id="note">Background: For many years Leibniz had a sporadic correspondence with Duchess Benedicta Henrietta (1652-1730), wife of Leibniz's former employer, Johann Friedrich (1625-1679), Duke of Brunswick-L&#252;neberg. The correspondence generally focused on matters of Catholic doctrine, which Leibniz knew to be of great interest to Benedicta. The following letter to Sophie was accompanied by a text Leibniz had written on St. Augustine's opinion on purgatory,<a name="1" href="#note1"><sup>1</sup></a> which was intended for Benedicta.</div><br /><br />
                [<b>A I 10, p89</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To Madam the Electress of Brunswick, 6 December 1694<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Madam<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Since it is said that purgatory is situated on the frontiers of the Kingdom of Pluto, I enclose the opinion of a great father of the Church on purgatory which I have looked further into in order to satisfy Madam the Duchess,<a name="2" href="#note2"><sup>2</sup></a> together with the despatches that <var>le Courrier de Pluton</var> has brought.<a name="3" href="#note3"><sup>3</sup></a> It is true that these despatches hardly please me. They smack of scandalmongering too much, and keep too well the infernal character, without preserving the respect which is due to great Princes. As for purgatory, I wanted to verify what I said recently to Your Electoral Highness. [<b>A I 10, p90</b>] I have exactly represented St. Augustine's opinion, drawn from his own words. From those words it is obvious that he speaks about purgatory with uncertainty, and that therefore it was not an article of faith in his time. And that therefore the Roman church has changed in a matter of faith. I am with devotion,<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Madam, to Your Electoral Highness etc.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTE:<br /><br />
                <a name="note1" href="#1">1.</a> See A I 10, pp90-3.<br />
                <a name="note2" href="#2">2.</a> Duchess Benedicta Henrietta.<br />
                <a name="note3" href="#3">3.</a> <var>Le Courrier de Pluton</var> [The Mail from Pluto] was a satirical pamphlet in the form of letters written by the dead to the living. The target of the satire was Louis XIV. See Emile Bourgeois and Louis Andr&#233;, eds., <var>Les Sources de l'Histoire de France IV: Journaux et Pamphlets</var> (Auguste Picard: Paris, 1924), p331.<br />
        <br /><br />
                &#169; Lloyd Strickland 2006
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
