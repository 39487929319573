import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/howthesoul.pdf'; 
import { Helmet } from 'react-helmet';

function SoulActsBody() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		    <var>S&#228;mtliche schriften und briefe series VI volume 4</var><br />
        Deutsche Akademie der Wissenschaften (ed)<br />
        p 1367<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		    Date: early 1677 - early 1678 (?)<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (140k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>How the Soul Acts on the Body</title>
            <meta name="description" content="An English translation of HOW THE SOUL ACTS ON THE BODY by Gottfried Wilhelm Leibniz, from early 1677 - early 1678?" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: HOW THE SOUL ACTS ON THE BODY</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A VI 4, p1367</b>]<br /><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The soul acts on the body as God does on the world, that is, not by means of miracle, but by mechanical laws. And so if&mdash;though it is impossible&mdash;minds were destroyed and the laws of nature remained, the same things would happen as if there were minds, and books would be written and read by human machines which would not understand anything. But we must know it is impossible to remove minds without violating the laws of mechanics. For the general laws of mechanics are the decrees of the divine will, and the special mechanical laws in each body (which follow from the general ones) are the decrees of its soul or form, striving for its own good or perfection. Therefore, God is that mind which leads all things to general perfection. And the soul is that sentient force which in each individual tends towards its specific perfection. And souls arose when God impressed on all things a conatus towards its specific perfection, so that from the conflict there would arise the greatest possible perfection. Everything in the whole of nature can be explained both by final causes and by efficient causes. Nature does nothing in vain, nature acts by the shortest paths, provided they are regular. Hence the shortest paths are to be sought not in the refracting surfaces themselves but in the tangents. But this is noted in passing. Souls do not act upon bodies in an extraordinary manner, nor does God so act upon nature, even if things may appear to happen in an extraordinary manner; because things were so constituted from the beginning that the general order involves something extraordinary in appearance.
		            <br /><br /><br />
		            &#169; Lloyd Strickland 2022
              <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default SoulActsBody;
