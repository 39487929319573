import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/diables.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
		Shelfmark LH I, 20 Bl. 302-303<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 1693<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (236k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Reading Notes on Nicolas Aubin's "Histoire des diables de Loudun"</title>
            <meta name="description" content="An English translation of READING NOTES ON NICOLAS AUBIN'S 'HISTOIRE DES DIABLES DE LOUDUN' by Gottfried Wilhelm Leibniz, from 1693" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: READING NOTES ON NICOLAS AUBIN'S <var>HISTOIRE DES DIABLES DE LOUDUN</var></h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>LH 1, 20 Bl. 302r</b>]<br /><br />
                <var>Histoire des diables de Loudun, ou de la possession des R&#233;ligieuses Ursulines, et de la condamnation et du suplice d'Urbain Grandier, Cur&#233; de la m&#234;me Ville.</var><a name="1" href="#note1"><sup>1</sup></a><br /><br />

                This history, the truth of which can hardly be doubted since it is obvious it was based on very good memoirs, can serve to show on the one hand how far man is capable of pushing his vengeance when it is not kept in check by fear of the divinity, and on the other, just how important it is that judges be enlightened, upright and careful about magic and sorcery in order not to punish the innocent along with the guilty. Those who reject all demonic activity, and who boldly maintain that there are no sorcerers, cannot draw any consequence from this history, since a particular fact, of the kind we have here, has never established a general rule. But those who believe too rashly in possessions will see in it how difficult it is to judge these sorts of things by the example of so many people in France who allowed themselves to be taken in by the so-called possessed of Loudun. Here is a summary of the matter.<a name="2" href="#note2"><sup>2</sup></a><br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A convent of nuns of St Ursula was founded at Loudun in 1626. This society found itself from the outset in great poverty, and could not be assisted by the other convents of the same order which, having only been established not long before, had not yet had the leisure to grow rich. The youngest of these ladies, having a playful mind and seeking only to amuse themselves, took advantage of the death of their director and of the view that he had come back as a spirit in the house in which they lived to terrify the young boarders they raised [<b>LH 1, 20 Bl. 302v</b>] in order to assist with their maintenance. Some of the nuns, even the simpler and older ones, to whom the game had not been revealed, were downright frightened. That encouraged the actresses, who made a thousand disturbances in the house and devised a thousand ways to better convince those not in on the plot that spirits had returned.<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;There was at that time a priest in Loudun called Urbain Grandier,<a name="3" href="#note3"><sup>3</sup></a> who had, on account of his cruel and haughty manners and even more on account of his love affairs, made himself some enemies, amongst others a canon called Mignon, who was the confessor of the Ursulines.<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This man thought he had found an infallible way of taking revenge on Grandier. He persuaded some of these nuns - already used to studying the Latins - to mimic the possessed, insinuating that this was the true way of increasing the revenues of the convent. He brought into his cabal various other priests and canons, who were also enemies of Grandier. Exorcisms were undertaken, and the devil did not fail to respond through the mouth of the possessed, saying that Grandier was a sorcerer responsible for all the evil on account of his pacts with the devil.<a name="4" href="#note4"><sup>4</sup></a> The intrigue continued for a long time. Grandier finally complained, and perhaps would have got himself out of trouble had the matter not come to the attention of Cardinal Richelieu,<a name="5" href="#note5"><sup>5</sup></a> against whom Grandier is said to have written a satire.<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;That was sufficient to irritate a man who has taken pleasure in vengeance his whole life. He gave the order to investigate and judge this case to a counsellor of the King called Laubardemont,<a name="6" href="#note6"><sup>6</sup></a> who had his favour, and who had already been to Loudun to demolish the castle, and had devised the whole thing with Grandier's enemies.

                [<b>LH 1, 20 Bl. 303r</b>]

                Loubardement therefore returned to Loudun equipped with complete authority, and he had no difficulty resolving himself to order a warrant against Grandier, who was warned of it but who, being innocent, did not think it appropriate to run away. He was put in prison, interrogated, tortured without confessing anything, sentenced to be burned alive as a sorcerer, and executed.<a name="7" href="#note7"><sup>7</sup></a><br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Grandier's death did not put an end to the possession. Aside from the fact that the fraud would have seemed overly crude, the nuns had developed a taste for it because it drew wealth to them from all sides, their not having a good votary to make a gift to the convent to help the poor possessed and cover the costs of exorcisms. The so-called possessions continued for several years, in which everything the Catholic Religion holds most holy and majestic was mocked by the impious profanations. But finally, with the convent rather wealthy, the nuns grew weary of all the toil this playacting required of them, and with the [Demonic] Powers no longer taking the same interest in this affair that they had in the beginning, and what is more significant, with the pensions that had been granted to the exorcists drying up, the possession, which had drawn people to Loudun from all corners of the kingdom, came to an end once and for all.<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The author does not neglect to include in this book all the proofs which serve to show that this possession was only a deceit of the nuns and monks, and it is credible that these proofs will seem convincing to all fair-minded persons.
                        <br /><br /><br />
                        <hr className='rightcol-hr' />
                        <br /><br />
                        NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> Nicolas Aubin, <var>Histoire des diables de Loudun, ou de la possession des religieuses ursulines, et de la condamnation &amp; du suppice d'Urbain Grandier, cur&#233; de la m&#234;me ville</var> (Amsterdam, 1693).<br />
                        <a name="note2" href="#2">2.</a> For accounts of the Loudun possessions, see Michel de Certeau, <var>The Possession at Loudun</var> (Chicago: University of Chicago Press, 2000). Also see Moshe Sluhovsky, "The Devil in the Convent," <var>The American Historical Review</var> 107 (2002), 1379-1411, and Craig E. Stephenson, "Looking Back: The possessions at Loudun," <var>The Psychologist</var> 27 (2014), 132-135: <a href="https://thepsychologist.bps.org.uk/volume-27/edition-2/looking-back-possessions-loudun" target="_blank">https://thepsychologist.bps.org.uk/volume-27/edition-2/looking-back-possessions-loudun</a><br />
                        <a name="note3" href="#3">3.</a> Urbain Grandier (1590-1634), a French Catholic priest.<br />
                        <a name="note4" href="#4">4.</a> For a copy of the supposed pact Grandier signed with the devil, see <a href="https://www.college.columbia.edu/core/content/pact-allegedly-signed-between-urbain-grandier-and-devil-submitted-evidence-during-1634-loudu" target="_blank">https://www.college.columbia.edu/core/content/pact-allegedly-signed-between-urbain-grandier-and-devil-submitted-evidence-during-1634-loudu</a>
                <br />
                        <a name="note5" href="#5">5.</a> Cardinal Richelieu (1585-1642), clergyman and statesman. He was appointed cardinal of the Catholic Church in 1622, and Louis XIII's chief minister in 1624.<br />
                        <a name="note6" href="#6">6.</a> Jean Martin de Laubardemont (c.1590-1653).<br />
                        <a name="note7" href="#7">7.</a> Grandier was executed on 18 August 1634.<br />
                <br /><br />
                        &#169; Lloyd Strickland 2016
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
