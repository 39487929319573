import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/brotherlylove.pdf'; 
import { Helmet } from 'react-helmet';

function BrotherlyLove() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>      
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series IV, volume 1</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 552-557<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 1669 (?)<br /><br />
		Translated from the Latin<br /><br />{isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (193k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Society of Brotherly Love</title>
            <meta name="description" content="An English translation of SOCIETY OF BROTHERLY LOVE by Gottfried Wilhelm Leibniz, from 1669 (?)" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: SOCIETY OF BROTHERLY LOVE</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}
                    <br></br>
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                  <>
                  [<b>A IV 1, 552</b>]<br /><br />
                <center>Society of Brotherly Love</center><br />

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;1) <var>True politics</var> is knowing what will bring the greatest benefit to oneself.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;2) <var>The greatest benefit to everyone</var> is what is most pleasing to God, since God is the most powerful being, [<b>A IV 1, 553</b>] and not to obey the most powerful, to oppose his will, is most dangerous, whereas to obey [him] comes with hope of a great reward, and since God is also most wise, this hope is joined to certainty.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;3) Whatever is done for the perfection of the whole world is <var>most pleasing to God</var>.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;4) Whatever is done for the perfection of the human race is done for the perfection of the whole world, because in the known world there is no class of things more perfect than human beings.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;5) The perfection of the human race consists in its being as wise and as powerful as possible.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;6) The wisdom and power of the human race is increased in two ways: by discovering new sciences and arts, and by becoming familiar with those already known.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;7) People will become familiar with already known sciences and arts (that is, with practical applications and useful rules) if from youth they are trained for piety, sobriety, health concern, modesty, labour, and all virtues in general; if the opportunities for sin are removed; if good and bad deeds cannot easily escape notice; if great rewards for the former and punishments for the latter happen with certainty; if people are deprived of the opportunity to disagree with each other; and if the need for charity is introduced.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;8) Arts and sciences will be augmented through <var>corresponding</var> as much as possible, and through conscientious <var>research</var> into the nature of things.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;9) Both discovery and the imparting of discoveries can occur through individual efforts and through the combined efforts of a society with widespread reach.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;10) But it is obvious that, in general, immeasurably greater benefit can arise through a society than from the unconnected efforts of individuals, efforts that are lacking something, like sand without lime.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;11) Such a society could best be established by a collusion of the devout. But when this group does not listen to reason, it follows only the customs of its ancestors. Anything you should attempt to achieve through them would be in vain.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;12) A society could be founded in two ways: either by cohabitation and quasi-communion, such as that of religious orders, or by association alone.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;13) J. J. B.,<a name="1" href="#note1"><sup>1</sup></a> the author of that work on establishing a certain society,<a name="2" href="#note2"><sup>2</sup></a> a work not lacking in ingenuity, wanted to establish a political society by communion.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;14) But such a society can hardly be established among married people without difficulty, which is why religious orders wisely exclude marriage and family. And great men will hardly allow themselves to accept the rule of religious orders, while our goal is to attract the greatest men.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;15) Therefore, it will be enough to have founded such a society in which everyone pursues their own business, albeit with a certain degree of dependency, at least for now, since not everything can be established perfectly at the beginning.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;16) But the bond by which they are connected shall be this: everyone receives a certain sum of money from the society, [<b>A IV 1, 554</b>] and is bound to it by sacred oaths, and binds himself absolutely that he would be wicked, disreputable, and a perjurer, and lastly the source of his own downfall and that of his people, if in any matter he does not obey the society, which is without sin.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;17) Also, the society should promise to support anyone accepted into the society, if he so requests, but in that case he is obliged to submit himself to it absolutely.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;18) No one has the right to withdraw from the society, under the aforementioned penalty; the same penalty applies to those who are expelled. But when it is a question of condemning someone, let the votes of both the directors and of [those working in the person’s] sphere of activity be obtained.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;19) A distinction should be made between those who are associated in offices and those who are, as it were, the officials in the collegiate bodies of the society.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;20) But the question arises as to how the society can attract people in the first place. This will happen in the first instance by the assurance of support, which will remove every concern about living a tolerable life.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;21) Secondly, by honour, for states will grant that members of the society have preference over other people of the same kind.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;22) Lastly, by stimulating piety, for this society will be devoted to promoting the benefit of the human race (devoted especially to medicine), and there is no more sacred goal than that.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;23) But how and by what means will the society be put in the position to be able to procure such a large sum of money and the goodwill of states so that these states grant its members privileges of honour and its decisions are enforced on their citizens?<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;24) This should be achieved by the members of the society everywhere doing everything for free. Doctors, judges, prefects, governors, counsellors, lawyers, professors, rectors, clerks, etc. should work without receiving any salary from the state or even from the people they serve, except what is freely given to them.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;25) In this way (following the example of the Jesuits who teach for free) they will above all gain the favour of the people, for it is well known that a good doctor and a good lawyer will attract crowds and have almost entire cities under their influence.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;26) Moreover, under this pretext, states will grant the society exemption from taxes in commercial undertakings.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;27) For not collecting taxes would not cost a state as much as would paying salaries.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;28) And the society cannot grow rich and acquire wealth by any greater right and benefit than by appropriating commerce for itself, for it cannot appropriate manufacturing for itself without aggravating the common folk; it guards against aggravating princes by foregoing salaries; a state can certainly do without merchants and appropriate for itself the profit they make and give it to its most useful servants, that is, to a society that claims to work for the common good.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;30)<a name="3" href="#note3"><sup>3</sup></a> In this way alone, the society can easily appropriate trade for itself wherever taxes are imposed. [<b>A IV 1, 555</b>] Or even, where, as in Holland, salaries are not equal to taxes, this [privilege] can be granted to the society, if not forever then at least for a certain time.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;31) There are also other privileges to be obtained, and indeed there will be no need for privilege to appoint members to offices ahead of others, for as long as they work for free they will be preferred for that reason.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;32) But a privilege will be needed to permit them sole exercise of those manufacturing techniques they have recently invented or will invent and undertake, and which are not yet publicly applied.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;33) Likewise, because many Dutch people will deem our society improper alongside the Dutch-Indian Company, effort must be made to obtain the right for the society to participate in trade alongside the company.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;34) Once that is accomplished, the wealthiest people of Holland will join the society and it will have a foundation.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;35) For in general I would like, for many reasons, the society to have its seat in Holland.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;36) Nevertheless, other means must be used to win over all princes. The emperor can be easily persuaded; to the Pope may be given the prospect of the propagation of the Catholic religion, and to the King of France [the prospect of] the extension of his power through this society, as was achieved for Spain through the Jesuits.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;37) Therefore, the Emperor and the King of France, in agreement with the Pope, should establish a number of ecclesiastical benefits throughout the Empire and France, so that all those who have more riches and fewer honours are forced to make a solid contribution to the society, and from now on will not be accepted unless they come from it. This would be enough of a foundation.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;38) There should be some association with states, as well as with the Indian Society, the Society of Jesus, the Dominicans, and all orders, to try to bind them closely to the society, likewise the English society and the states throughout Germany.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;39) Care must be taken to ensure that members of our society are moved into the highest positions so that they have everything in their hands. The clerics among them must be made confessors. Care must also be taken to ensure that a tax is levied on ownerless property and that which is passed on to non-natural heirs, that the young people among our members are educated in scientific matters and won over, that it gradually becomes possible to assail the East under the pretext of medicine and mathematics and to establish colonies under the pretext of the East Indian Society. In this way, the society can draw upon all the wealth of Holland.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;40) The other ministers of the society will direct correspondences and commerce throughout the world. Some will live on their own income, which they will not be able to obtain from the Church, on account of the society.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;41) They should sell their labour to no one, except in exchange for some remarkable artifice or study. In this way they will still easily acquire enough to live on.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;42) Gifts may still be accepted. Whatever is donated to a certain collegiate body may be used for other purposes only with the full authority of the directors.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;43) In the meantime, they may sell [raw] material, not labour, and because they are free from taxes, the more can be earned from the material.<br /><br />

                [<b>A IV 1, 556</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;44) If they invent a new practical way of manufacturing well-known products, they should not teach it to others, but should sell elaborated works to the artisans of the craft as merchandise.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;45) All formerly secular clerics lacking in the care of souls should be compelled to this rule, or to make a contribution to it.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;46) Or property may be reduced to vicarages, with the proceeds assigned to such a useful society.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;47) No animal is too rare that it may not be given up to a doctor of the society for dissection.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;48) The society should hold public registers of academies and rectorships of schools and a collegiate of the same.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;49) In this way, the society can easily put itself in such a position that it no longer has anything to fear and may sit at the helm of state. For it was founded as a seminary not so much of theologians (which is the aim of the Jesuits) as of people useful to the state. Military leaders can be bound to the society too, especially almost the whole of Holland can be won over in parts; fleets and colonists can be sent to America, the whole world can be subjugated not by violence but by gentleness; all<a name="4" href="#note4"><sup>4</sup></a> our sciences and by this means the most exquisite state can be established there, by means of the greater lands of Europe.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;50) Then, and only then, will the human race be cultivated everywhere, since till now more than half of it has remained untilled. Moreover, our society will be the arbiter of wars and will easily guarantee security to the world from unjust force. Especially since it will occupy the most important offices everywhere, bind the common people to itself, and hold the wealth of a country in its hand. O what a bright and auspicious day for the human race when these things will begin!<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;NB. If it were possible to make some religious beggars serve the society, such as the Capuchins, for whom there would be no need to take care of their upkeep, great benefit could be expected. For these men would attend to carriages and other services, and also take on the burden of writing.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;But where salaries are not paid, but offices are sold, what is the plan then? In that case, not only what the other person had given, but also what he had gained in profit, should be paid back, but in France it should be established by a different means, by the hope of domination, and in Germany by the remission of wages.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Once the society is prominent, only these men who live by useless trades (not manufacturing) will fall, and above all the merchants among them.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;NB. It may be useful for the society to take care that its members not accept any service on which depends the receipt of money and the rendering of accounts, so as to preserve the favour of the people. On the other hand, judicial and military offices are to be accepted. These are already sold for the least amount.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Pythagoreans had claimed the same administration for themselves throughout Italy, but with an unfortunate outcome, namely the destruction of the sect.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Communication should be made with Helmont and Crafft and Neub. and la Court and Labadie, and Curtius.<a name="5" href="#note5"><sup>5</sup></a><br /><br />

                [<b>A IV 1, 557</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Labour should be sold to the trades.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Strict moral laws, against drunkenness and lust, and for self-admonishment.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In states where there are no guilds, it is permitted to work in manufacturing trades, but in ordinary ones and above all in those which are less common and more artful; the rest should be left to the common people.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The society promises always to provide so that there is no scarcity in provisions but a lasting supply of grain.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The society promises the same thing in other necessary matters, to provide at all times, to maintain a continuous supply.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Unique ciphers shall be used when corresponding.
                <br /><br /><br />
                <hr className="rightcol-hr"/>
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> Johann Joachim Becher (1635-1682).<br />
                <a name="note2" href="#2">2.</a> Leibniz is referring to Becher's <var>Politischer Discurs von den eigentlichen Ursachen de&#223; Auff- und Abnehmens der St&#228;dt, L&#228;nder und Republicken</var> (Frankfurt: Zunner, 1668).<br />
                <a name="note3" href="#3">3.</a> There is no &#167;29.<br />
                <a name="note4" href="#4">4.</a> &quot;omnis&quot; (the Academy editors omit the word as unreadable).<br />
                <a name="note5" href="#5">5.</a> That is, Francis Mercury van Helmont (1614-1698), Johan Daniel Crafft (1624-1697), Pieter de la Court (1618-1685), Jean de Labadie (1610-1674), and John William Curtius (1598-1678). &quot;Neub.&quot; is unidentified.<br />
                <br /><br /><br />
                &#169; Lloyd Strickland 2021<br></br><br></br>
                </>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default BrotherlyLove;
