import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/lanion.pdf'; 
import { Helmet } from 'react-helmet';

function LanionMetaphysics() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series VI volume 4</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 1778-1783<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: summer 1678 - winter 1680/1?<br /><br />
		Note: Quotations from Lanion's book are (more often than not) in italics. Judging from Leibniz's analysis, Lanion seems to have closely followed the order and content of Descartes' <var>Meditations</var>, albeit from an occasionalist perspective.<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (21k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Analysis of Abbe Lanion's "Meditations on Metaphysics"</title>
            <meta name="description" content="An English translation of ANALYSIS OF ABBE LANION'S 'MEDITATIONS ON METAPHYSICS' by Gottfried Wilhelm Leibniz, from summer 1678 - winter 1680/1?" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: ANALYSIS OF ABB&#201; LANION'S <var>MEDITATIONS ON METAPHYSICS</var></h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A VI 4, p1778</b>]<br /><br />		
		        <center>Remarks concerning the <var>Meditations on Metaphysics</var>, published in 1678.<a name="1" href="#note1"><sup>1</sup></a></center><br />

                <center>Meditation 1</center><br />

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>As I sense that I am born free</var>)<br />
                It would be useful to say what freedom is. But I pass over that because the mention of freedom here does not concern the arguments that follow.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>I have heard it said that there is a God</var>)<br />
                It is still not necessary to bring God into it, although Mr Descartes did so too. But I do not see that the method gives us the occasion to bring him into it here, since we already have enough reasons to doubt, taken from things themselves, and since it is the remembrance of a thing overheard, that is, a chance thing, rather than order which makes us think about him. However I will not linger on that (: Although there are often grounds to make the same remark in what follows :), because it does not concern the force of the argument, but only the art of inventing.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>To establish something firm and solid in the sciences, I have to doubt everything</var>)<br />
                It seems to me that it is not necessary, especially today, to put so much insistence on that. One can demonstrate demonstrable things without always mentioning doubtful things.
                <br /><br />
                [<b>A VI 4, p1779</b>]<br /><br />

                <center>Meditation 2</center><br />

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>I am convinced by an internal feeling that all these things are in me</var>)<br />
                Here is the first time that conviction is mentioned, and the cause of this conviction is the internal feeling. But how come internal feelings are certain? That would have to be explained. It will be said to me that it is because, without that, there is nothing certain at all. So be it, another sceptic would perhaps say. But I am not of this view, and I think that something more satisfying can be said.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>I do not clearly conceive the nature of what is in me</var>)<br />
                That is perhaps closer to the truth than Mr Descartes.<br /><br />
                <center>Meditation 3</center><br />

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>I have sensed that I could not doubt that I was, or what is the same thing, I have conceived clearly and distinctly that I existed. I can therefore accept as a principle that everything I conceive clearly and distinctly is indubitable</var>)<br />
                It seems to me that the author wants to give a sign of <var>certitude</var> here, which is to feel that one cannot doubt. But this sign is obscure, and very much subject to the whims of men. There would be a little more clarity if he had said that it is when I do not find any reasons to doubt, but that only gives a conjecture or presumption. It could be put even better as: it is when I see that reasons for doubt cannot be found. That is good, but how can one see that?<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;. <var>Firstly</var>) <var>The idea that represents an infinitely perfect being in me is rather different from the one that represents a limited being in me</var>.)<br />
                I admit that it is time to speak of the infinitely perfect being here. But it would have been good to show us how and why. It is because, having considered ideas in general, I should now consider them in particular, and as there is no minimum in ideas, one has to begin at the other end, that is, with the greatest, if there is one.<br /><br />
                
                [<b>A VI 4, p1780</b>]<br /><br />

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;. <var>I must therefore conclude</var>) <var>Having the idea of an infinitely perfect being in me</var>)<br />
                I would like the author to take more pains to make us see that there is one. Is it because one can reason about it? But one can also reason about <var>the greatest number</var>, which nonetheless implies contradiction, as does <var>the greatest speed</var>. Many deep meditations are still required to complete the demonstration of God's existence, based on the existence of his idea.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(&#167;. <var>Now as one cannot</var>)<br />
                It seems that the author here wants to prove that there is an idea of the infinitely perfect being. <var>For the infinite substance</var>, he says, <var>has more reality than the finite, therefore I have in me the notion of the infinite rather than of the finite</var>. I do not really see this consequence, inasmuch as one can doubt whether it does not imply contradiction. He adds: <var>I conceive that I am not entirely perfect since I have in me the idea of a being more perfect than mine</var>. But to my mind that still does not prove the thesis. For I can conclude that binary is not an infinitely perfect number because I have or can see in my mind the idea of another which is more perfect, and yet another more perfect than that. But after that, I do not thereby have the idea of an infinite number, even though I see well enough that I can consider a number which is greater than any given number whatsoever.<br /><br />

                <center>Meditation 4</center><br />

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I do not fully understand the distinction the author makes between the understanding and the will. <var>I consider</var>, he says, <var>either insofar as being aware and receiving ideas and knowledge, which is what I call understanding, or insofar as pushed and determined towards these ideas, and this is what I call will</var>. But does this being pushed or determined towards certain ideas rather than towards any others not involve being aware of them?<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;What he says there about the cause of errors does not satisfy me either. It is true that one makes a mistake when one comes to a judgement of things without considering them enough, but how come one judges thoughtlessly? This is the question, or rather, in general, how come one judges? For [<b>A VI 4, p1781</b>] the reason why one judges badly depends on that.<a name="2" href="#note2"><sup>2</sup></a> He repeats at the end <var>that I should not come to a judgement when it is not in my power to refuse my consent</var>. But it is rather difficult to know what is in my power, and often we are led by a certain liking or whim. There have to be other, more distinct marks, to make us assured.<br /><br />

                <center>Meditation 5</center><br /><br />

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>I have</var> (he says) <var>a</var> CLEAR IDEA <var>when I am distinctly aware of the relations it has with one or several other ideas, and I have a</var> CONFUSED IDEA <var>when I only know this relation confusedly</var>.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;But it would be useful to know what <var>relation</var> is, and what it is to be aware of something <var>distinctly or imperfectly</var>. He adds that <var>the knowledge of myself is confused, since I only have it through an internal feeling, and not through a clear idea, since I do not have any idea of my thought</var>.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I am surprised that the author says that he does not have any idea (confused or clear) of thought. This is to take the word <var>idea</var> in a different way from Descartes. And I would like to be informed of its notion. However it is perhaps true that we do not have a sufficiently distinct idea of thought.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Everything that I clearly and distinctly conceive as belonging to something, belongs to it</var>. This rule does not suffice for us to have a distinct mark of what clear and distinct is. Yet there is one.<br /><br />

                [<b>A VI 4, p1782</b>]<br /><br />

                <center>Meditation 6</center><br />

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>I do not see how extension can have in itself the power to make itself intelligible. It is therefore necessarily the case that God, that is, an intelligent being and an infinite power, is the source of all my ideas</var>, and that we are aware of things in the substance of God himself. I consider the conclusion to be very true and very excellent, but in order to prove it I find a difficulty with it. For although extension could not make itself sensed, it can be accompanied by some other thing which will perhaps be able to do so.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The arguments that follow, to show that we cannot easily prove that there is extension outside of us, are very good.<a name="3" href="#note3"><sup>3</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I very much agree with what the author says about the simplicity of God's decrees, which are the cause of some particular evils, for example, those which arouse my thirst even when it is harmful for me to drink. Yet it could be objected: why could God not devise decrees that are universal but at the same time sufficiently simple, capable of excluding all particular evils? For if God is not able to do so, it will follow either that God is not as perfect as he can be, or that the nature of things in itself (which effectively comes back to God) is imperfect because it cannot provide God with such laws. It is as if one said that the nature of numbers is imperfect because it is impossible for it to provide a number which expresses the diagonal of the square. But one could respond that it would be considerably less perfect if it could provide this number.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I am obliged to say ingenuously that the proof which can be found in this meditation on the distinction between my mind and my body does not satisfy me. For since the author admits [<b>A VI 4, p1783</b>] that we do not conceive thought distinctly, the fact that he can doubt extension (that is, the extension he conceives distinctly) without being able to doubt thought is insufficient to know the extent of the distinction between what is extended and what thinks. If he could prove<a name="4" href="#note4"><sup>4</sup></a> that there is no extended body, or if he distinctly conceived what thought is, then the argument would be convincing.

                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> Abb&#233; de Lanion (pseudonym; real name: Guillaume Wander), <var>M&#233;ditations sur la m&#233;taphysique</var> [Meditations on Metaphysics] (Paris, 1678).<br />
                <a name="note2" href="#2">2.</a> [Leibniz's footnote:] The lack of experience to the contrary is the cause of errors.<br />
                <a name="note3" href="#3">3.</a> [Leibniz's footnote:] Every substance is free, if we were not free then God would not be free, whatever acts is free insofar as it acts.<br />
                <a name="note4" href="#4">4.</a> Reading 'il pouvoit prouver' in place of 'il pouvoit pouvoir'.<br /><br /><br />
                &#169; Lloyd Strickland 2005, 2007
              <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default LanionMetaphysics;