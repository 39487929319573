import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/falaiseau.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>Correspondenz von Leibniz mit der Prinzessin Sophie vol III</var><br />
		Onno Klopp (ed)<br />
		pp 142-143<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 8 July 1705<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (10k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Pierre de Falaiseau (8 July 1705)</title>
            <meta name="description" content="An English translation of a LETTER TO PIERRE DE FALAISEAU by Gottfried Wilhelm Leibniz, from 8 July 1705" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO PIERRE DE FALAISEAU</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                [<b>K III p142</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Extract from my letter to Mr Falaiseau, who recommended to me the books by Mr Sidney and Mr Locke on government.<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The book by the late Mr Algernon Sidney is well known to me, as well as what the late Mr Locke wrote against Filmer, [<b>K III p143</b>] and what he himself sent to me. Every man of good sense must acknowledge that government is for the common good. My definition of the state, or of what among the Latins is called the <var>Commonwealth</var>, is a great society of which the goal is common security. It would be hoped that one could obtain for men something more than security, namely happiness, and one must apply oneself to that; but security is at least essential, and without it the good stops. This is why subjects are allowed to take the oath of fidelity to the enemy of their master who has conquered them, their master no longer being able to do anything for their security. I will be delighted, Sir, to have your judgement on these thoughts, for you have knowledge of the present state of the world together with knowledge of history and of good principles.
        <br /><br /><br />
                &#169; Lloyd Strickland 2005
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
