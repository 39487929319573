import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/opinions.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>Opuscules et fragments in&#233;dits de Leibniz</var><br />
		Louis Couterat (ed.)<br />
		p 232<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: May 1693 (?)<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (74k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>The three degrees of strength in opinions</title>
            <meta name="description" content="An English translation of THE THREE DEGREES OF STRENGTH IN OPINIONS by Gottfried Wilhelm Leibniz, from May 1693 (?)" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: THE THREE DEGREES OF STRENGTH IN OPINIONS</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>C p232</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The three degrees of strength in opinions are logical certainty, physical certainty - which is only logical probability - and physical probability. An example of the first can be found in propositions of eternal truth; of the second in propositions which are known to be true from induction, like &quot;every man is bipedal&quot; (for on occasion men are born with one foot or none); of the third that &quot;the south is rainy&quot;, propositions which are usually true, although are sometimes wrong. You should be able to distinguish many that are never violated except supernaturally, like &quot;fire burns&quot;.
                <br /><br /><br />
                &#169; Lloyd Strickland 2017
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
