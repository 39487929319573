import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/fontenelle.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>Lettres et opuscles in&#233;dits de Leibniz</var><br />
        Louis-Alexandre Foucher de Careil (ed)<br />
        pp 224-225, 228<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 7 April 1703<br /><br />
		Translated from the French<br /><br />
        Note - the portion of this text that is included in my <var>Shorter Leibniz Texts</var> is not included here.<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (11k)</Link><br /><br />
        <Link to="/slt">Back to <var>Shorter Leibniz Texts</var></Link><br /><br />
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Bernard le Bovier de Fontenelle (7 April 1703)</title>
            <meta name="description" content="An English translation of a LETTER TO BERNARD LE BOVIER DE FONTENELLE by Gottfried Wilhelm Leibniz, from 7 April 1703" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO BERNARD LE BOVIER DE FONTENELLE</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>FC p224</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sir, in giving you the thanks that I owe for doing me the favour of sending some essays published in Paris from time to time which relate to the plan of the Royal Academy, I will not abuse the kindness that you have had of deferring to it, by asking your bookseller to take some to Mr Brosseau, for fear that you may truly say I am making you my errand-boy.<br /><br />

        [<b>FC p225</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I wrote to Father Bignon<a name="1" href="#note1"><sup>1</sup></a> in the last post, and time did not permit me to write to you also, Sir. I reserved it for today in order to tell you that the Chinese figure of Fuxi allows us now to speak, I believe, of this arithmetic in the Papers of the Academy; but I implore you, Sir, to insert there not my old manuscript, but the one I have just sent to Father Bignon that is shorter and talks about the agreement that Reverend Father Bonnet told me of, for it is he who has deciphered the enigma of Fuxi with the assistance of our binaries.<a name="2" href="#note2"><sup>2</sup></a> I had feared that the public would despise a speculation of which the fruit does not appear at first, but that remark perhaps renders it acceptable.<br /><br />

        ......<br /><br />

        [<b>FC p228</b>]<br /><br />
        
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I am not at all laconic, Sir; and I know not if I sin against what is owed to you in using this prolixity when I speak to such a penetrating mind as yours. Nevertheless I believe that one is always reasonable when one tries to explain clearly. For when one is not understood, this is most often the fault of the one who speaks rather than of the one who listens.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Your very humble and very obedient servant.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> Jean-Paul Bignon (1662-1743), director of the French Academy of Sciences and Arts and editor of the <var>Journal des Savants</var>.<br />
                <a name="note2" href="#2">2.</a> Leibniz is referring here to his essay 'Explanation of binary arithmetic', which was published in the <var>Memoires de l'Academie Royale des Sciences</var> (1703). An English translation of this essay is available <Link to="/binary">here</Link>.
        <br /><br /><br />
                &#169; Lloyd Strickland 2004<br />
                With gratitude to Elizabeth Vinestock for advice and suggestions
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
