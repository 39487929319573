import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/morellmay1698.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>S&#228;mtliche schriften und briefe series I, volume 15</var><br />
        Deutsche Akademie der Wissenschaften (ed)<br />
        pp 558-562<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        Date: 4/14 May 1698<br /><br />
        *** refers to illegible text<br /><br />
        Translated from the French<br /><br />
        Note - the portion of this text that is included in my <var>Shorter Leibniz Texts</var> is not included here.
        <br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (20k)</Link><br /><br />
        <Link to="/slt">Back to <var>Shorter Leibniz Texts</var></Link><br /><br />
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Andre Morell (4/14 May 1698)</title>
            <meta name="description" content="An English translation of a LETTER TO ANDRE MORELL by Gottfried Wilhelm Leibniz, from 4/14 May 1698" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO ANDR&#201; MORELL</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A I 15, p558</b>]<br /><br />
                <div align="right">Wolfenb&#252;ttel, 4/14 May 1698</div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sir<br /><br />						       
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Here are two letters by Abb&#233; Nicaise, the first of which reached me rather late, and only shortly before the second. I always find him a little too attached to received opinions, and to the air of society, which means that he will always be of the opinion of the court and of those with money. It is evident that if one allowed the Court of Rome to decide, it would be entirely neutral on the subject of the dispute between the Bishop of Meaux and the [<b>A I 15 p559</b>] Archbishop of Cambrai, but it is the Court of France that urges the condemnation of this Archbishop's book.<a name="1" href="#note1"><sup>1</sup></a> I see that the Jesuits are a little isolated now, but I do not know if those who currently have the upper hand are better. Each has his turn.<br />						       		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The good Mr Spanheim is very busy. The duties of his office hardly leave him any free time. However it is a shame that several worthy plans will be left behind. His absence from Berlin will not be favourable to fine letters.<br />						       
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Upon my return to Hanover, which is to say in the coming week, I will find out if Mr Helmont has arrived there, for he will be expected. I will ensure I ask him the location of the sources of salt in the territory of Berne, and will press him to reveal it, and I hardly doubt that he will do it, provided that he knows it himself, for often things are attributed to him, as well as to his father, which he does not admit at all.<br />						       
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I have written to Mr Witsen, Mayor of Amsterdam, and to Mr Burnet, Bishop of Salisbury, in order to let them know that we could benefit from the visit and intentions of the czar in matters more important than the profit of a few individuals, and that through his intervention we could arrange trade with China, which would be very important even for religion. But I believe that Holland, jealous of the Eastern Indian Company, will never allow that, and in England they just have too many other cares; and the kingdom of heaven is always the last thing to be considered, contrary to what would be right and proper.<br />						       
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If the mystics explained themselves better, and if others were sufficiently fair so as not to attribute scandalous explanations to them, many disputes would stop, and it would be a matter not of disputing things, but of doing them instead. But those who have no particular craving for the practical side of things are happy to throw themselves into the disputes.<br />						       
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If by the 'passive state of the soul' is meant only the submitting of one's will to that of God, and only wanting what he wants, then it is the most reasonable thing in the world. The same goes for the controversy concerning disinterested love, for, as the true love of God constitutes the perfection and happiness of the soul, its true interest is to love God above all things. And the supposition that a lover of God could be happy even if he would be damned, is a fiction and utterly impossible.<br />						       
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Indifference is merely the product of ignorance, which makes one suspend his judgement; nothing could be indifferent to the one who knows everything, for he would not fail to know the true value of everything; and as he would no more fail [<b>A I 15 p560</b>] to choose the best that is possible, it must be that the arrangement of the universe he has chosen is not indifferent to him. Nevertheless this does not prevent very great corruption of the visible world; but to judge the universe in its entirety, we have to consider this corruption together with its remedy. True reason always agrees with true revelation, and in my opinion it is wrong to oppose one to the other. Boehme always appeared deep to me, and one day I will read his works. But I would nevertheless wish to see the essence of them in a few words, for being distracted as I am by various occupations, and having reasons to doubt his ability to express himself well (and, moreover, having some evidence to support my sentiments from a good source), I do not know if it is reasonable to commit myself to so much reading before I know whether it would be sufficiently useful for me. It is not enough to see a well-drawn hypothesis, like in a painting; novels are ordinarily very clear and distinct, but they are not for that reason true histories. I am of your opinion, Sir, that we should abstain from disputes and endeavour to have something we are confident of. But we do not have to accept what is not plausible. I would not attribute to myself certain knowledge of everything I would wish to know in theology, but I nevertheless believe that I have enough certain knowledge of what appears to me to be the essentials.<br /><br />

        ......<br /><br />

        As there is no reason to doubt Copernicus' hypothesis, and that therefore our globe of the earth is only a satellite of that fixed star that we call the sun, it is easy to think that the earth is a very a small portion of the universe, and that therefore we should not judge from that [<b>A I 15 p561</b>] the designs of creation in general or of all the orders of angels. I believe, in fact, that the surface or crust of our earth is an effect of fire, and of a fusion or vitrification, and I even hold that the sea is merely a sort of <var>oleum per deliquium</var>,<a name="2" href="#note2"><sup>2</sup></a> as after the cooling, the humidity fell back on the earth, and moistened the fixed salt which now fills the sea.<br /><br />

        ......<br /><br />

        I do not know if these sentiments agree with yours and with those of your author. Nevertheless there is something in your letter with which I do not fully agree: you conceive angels to be placed first in a subtle matter; so be it, although I believe that as long as the world has lasted and will last, it has always had and will have forms of matter that are more or less subtle in an infinity of degrees, and that what is subtle in our view is always crude in comparison to an infinity of other forms that are more subtle. Also, I do not believe that there is a primary element, as Descartes imagined.<br />						       
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;With regards to the three classes of angels, that the first has fallen, that its fall was to set itself up as creator, or to awaken its dark centre, that to create and to awaken this dark centre is the same thing, that this must have produced a corporeal fire, that the fire of our globe is an effect of the fall, that water has appeared in order to extinguish it, that man was made in order to fill the place of the fallen angels, that the two colours of masculine and feminine (in red and in white) were together located in him in paradise, and that they were divided into male and female separately because of his fall from the paradisiacal life into the astral - these are ingenious thoughts, which on the one hand would have need of explanation, and on the other, of proof. I look down on nothing, but I also would not want [<b>A I 15 p562</b>] to put too high a value on things. I have received a letter for you, Sir, which I believe to be from Mr Gichtel...<br />						       
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I am sincerely, Sir, etc. etc.<br /><br />						       	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The extract from Mr Gichtel's letter is very well written, but all these fine opinions do not show enough of what I would like, which is to say the enthusiasm for the glory of God and the good of one's fellow man. Many pious men are only too quietest in manner, that is, they show their piety in their ways of imagining things, and if they have any zeal it is at the very most in order to make converts of their opinions. Whereas true zeal should be used to treat the evils of men, and to assist them in order to do as much as possible for the love of God. It is for this reason that one should ***. But where are those who want to do so? If I were acquainted with them, I would be willing to take measures with them.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> Leibniz is referring to the debate between  Fran&#231;ois F&#233;nelon (Archbishop of Cambrai) and Jacques B&#233;nigne Bossuet (Bishop of Meaux) over disinterested love, which occurred between 1697 and 1699.<br />
                <a name="note2" href="#2">2.</a> 'oil produced by deliquescence'.<br />
        <br /><br />
                &#169; Lloyd Strickland 2003
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
