import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/mary.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series IV volume 5</var><br />
        Deutsche Akademie der Wissenschaften (ed)<br />
        pp 502-503<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: not before 1693<br /><br />
        Translated from the French<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (149k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>On Mary Stuart and Popess Joan</title>
            <meta name="description" content="An English translation of ON MARY STUART AND POPESS JOAN by Gottfried Wilhelm Leibniz, written not before 1693" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: ON MARY STUART AND POPESS JOAN</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A IV 5, p502</b>]<br /><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The history of Mary Stuart, Queen of Scotland,<a name="1" href="#note1"><sup>1</sup></a> is like that of Popess Joan<a name="2" href="#note2"><sup>2</sup></a> - it is a question of sides. This can be judged from tome 2, book 8 of the <var>Histoire des r&#233;volutions d'Angleterre</var> [<b>A IV 5, p503</b>] to the year 1567, by Father d'Orleans. 'The history,' he says, 'of this famous Queen is among those learned in the crib and among those tragic events through which children develop the taste for reading and books.'<a name="3" href="#note3"><sup>3</sup></a> The fact is that on the Roman side, the Queen is represented as unfortunate, but not as worthy of being so, and she is used to stir up minds against the Protestants.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> Mary Stuart (1542-1587).<br />
                <a name="note2" href="#2">2.</a> In medieval times and the early modern period, the tale of Popess Joan - who was thought to have been Pope in either the 9th or 12th century AD - was widely circulated and (among Protestants) widely believed. In all likelihood she was a mythical figure, however.<br />
                <a name="note3" href="#3">3.</a> Pierre-Joseph d'Orl&#233;ans, <var>Histoire des r&#233;volutions d'Angleterre depuis le commencement de la monarchie</var> [History of the revolutions of England since the beginning of the monarchy] (Paris, 1693), vol. 2 p481.<br />
                <br /><br /><br />
                &#169; Lloyd Strickland 2007. Revised 2021
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
