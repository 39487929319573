import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/hartsoeker1711.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>Die philophischen schriften von Gottfried Wilheim Leibniz, vol. III</var><br />
		C. I. Gerhardt (ed)<br />
		pp 529-530<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 7 December 1711<br /><br />
		Translated from the French<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (95k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Nikolaus Hartsoeker (7 December 1711)</title>
            <meta name="description" content="An English translation of A LETTER TO NIKOLAUS HARTSOEKER by Gottfried Wilhelm Leibniz, from 7 December 1711" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO NIKOLAUS HARTSOEKER</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>G III p529</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A part of my new reasonings depends upon a great principle that is well known, but not sufficiently thought about, namely that nothing happens without a sufficient reason why, or rather without a determining reason. In accordance with this principle, which takes us beyond our predecessors, God never changes his will and operation without having some valid grounds for doing so. And when the thing in question is of a uniform and simple nature, we are in a position (thoroughly weak creatures as we are) to judge if there can be a reason or not. When God's will is employed all by itself, without there being in the natures of creatures the reason for this will or the way in which it works, then it is a miracle pure and simple, scarcely fitting in philosophy, as if God willed (for example) that the planets move in curved lines without being pushed by any other bodies. There is a law of nature which holds that there is no passage <var>per saltum</var>.<a name="1" href="#note1"><sup>1</sup></a> I once discussed it in Mr Bayle's <var>Nouvelles de la Republique des lettres</var>.<a name="2" href="#note2"><sup>2</sup></a> This law also does not allow there not to be any middle ground between the hard and fluid.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Every time we know something of God's works, we find order in them. That also means there are no perceptions without organs. And I agree that there is among creatures no substance that is separated from and destitute of all body. And in my view, a body is only ever pushed by bodies according to the laws of mechanics. And with all that, everything is full of souls and of organic bodies, and souls could not perish naturally. It can be said in a certain sense that there is knowledge in matter, if by &quot;matter&quot; is meant everything that is joined to it: but life or perception could not be produced and explained by material attributes, extension, shape, motion, and it is only distinct perception that can begin and stop.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If you take freedom to be a faculty to choose without grounds, I have to admit that it could not be reconciled with providence, and I add that it could not be reconciled with reason and truth either. I [<b>G III p530</b>] have explained this in my <var>Theodicy</var>. Such a freedom is impossible and conflicts with the great principle of the reason why, as do also the void, atoms, the perfectly hard and the perfectly fluid: and this principle puts us in a position to form a general, demonstrative philosophy.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The cause of planetary motion deserves a longer discussion and requires a lot of geometry. I have shown in the Acts of Leipzig that a certain circulation attributed to planets, together with their gravity, gives Kepler's ellipses. Mr Newton obtained it by a trajectory together with gravity. Science will be very obliged to you if you open up a new route to it. I am sincerely etc.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> &quot;by a leap.&quot;<br />
                <a name="note2" href="#2">2.</a> &quot;On a general principle useful in explaining the laws of nature through a consideration of the divine wisdom, to serve as a reply to Father Malebranche's response&quot; (<var>Nouvelles de la R&#233;publique des Lettres</var>, July 1687). English translation in Lloyd Strickland (ed), <var>The Shorter Leibniz Texts</var> (London: Continuum, 2006), pp131-134.<br /><br /><br />
                &#169; Lloyd Strickland 2005. Revised 2019<br />
                With thanks to Geert de Wilde
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
