import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/nicaise.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>S&#228;mtliche schriften und briefe series II, volume 3</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 588-590<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 6/16 August 1699<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (196k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Abbe Claude Nicaise (6/16 August 1699)</title>
            <meta name="description" content="An English translation of a LETTER TO ABBE CLAUDE NICAISE by Gottfried Wilhelm Leibniz, from 6/16 August 1699" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO ABB&#201; CLAUDE NICAISE</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>Note - This letter is a reply to Nicaise's letter to Leibniz of 2 August 1699; see A II 3, 582-584.</small><br /><br />
                [<b>A II 3, p588</b>]<br /><br />
                <div align="right">Hanover, 6/16 August 1699</div>
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sir<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You take me for a very negligent man if you think me capable of mislaying three times a thing I received but once. I don't know what combination of circumstances was responsible for the parcel you entrusted to Mr Brosseau not being delivered to me.<a name="1" href="#note1"><sup>1</sup></a> It is certain at least that I have never seen this last paper intended for me a second time by your kindness and the favour of Mr le President Boisot.<a name="2" href="#note2"><sup>2</sup></a> I have written to Mr Brosseau about it, but I am not hopeful that he will remember to whom he gave or entrusted it.<a name="3" href="#note3"><sup>3</sup></a> <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I am sorry to hear of Father Pagi's death, but consoled by the hope you give me, Sir, that his work will appear.<a name="4" href="#note4"><sup>4</sup></a> I have recently seen in the <var>Nouvelles de la R&#233;publique des lettres</var> the letter he wrote to you and also that of Abb&#233; de la Charmoye.<a name="5" href="#note5"><sup>5</sup></a>  The genealogies of sovereign houses at least are almost as important in history as chronology, because they show the changes undergone by states, which have passed from one family to another, and they often ground the rights and claims of princes, whereas accurately worked-out chronology (leaving aside sacred history) is scarcely useful except for verifying dates and titles. However, I have also worked on that of the ninth and tenth centuries, the history of the House of Brunswick having obliged me to do so, and in certain matters I agree with what Father Pagi has observed.<br /><br />
                [<b>A II 3, p589</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Father Bonjour's response to Mr Ludolf seemed to me so curt and devoid of substance that I do not see he has provided Mr Ludolf any cause to reply to it.<a name="6" href="#note6"><sup>6</sup></a> It is at any rate not my custom to write such letters, and I do not willingly waste the opportunity to learn something.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If Father Bonjour could defend the common calculation against the Septuagint, it would be at the expense of religion. For I have always thought that Abb&#233; de la Charmoye was right to think that the chronology of the Chinese (not to mention other arguments) obliges us to extend the antiquity of time.<a name="7" href="#note7"><sup>7</sup></a> The late Mr Diroys, theologian of Cardinal d'Estrees, and author of a book in favour of Holy Scripture,<a name="8" href="#note8"><sup>8</sup></a> told me in Rome that if one day he found, by good or bad luck, through the verified histories of some people, that the world is older than the Septuagint itself seems to suggest, the truth of religion could nevertheless still be defended since it is not said that those who Moses names were fathered from one another without any gaps. But I have no fear that we should be reduced to such an unfortunate excuse, and the Septuagint can suffice for us.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If Cardinal Noris <var>gode il papato, io godo il cardinalato</var>,<a name="9" href="#note9"><sup>9</sup></a> and imagine myself to be as blessed as anyone.<a name="10" href="#note10"><sup>10</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I have not had news from Mr Morell since his use of the baths,<a name="11" href="#note11"><sup>11</sup></a> but I will ask him for us both.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Archbishop of Cambrai is better off being out of the affair than he was being embroiled in it.<a name="12" href="#note12"><sup>12</sup></a> He comes out of it an able man, and he entered into it without thinking of the consequences it might have. God be praised that at least the journals will finally discuss something else.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It is doubtful whether the proceedings initiated against Cardinal Sfondrati's book by the prelates of France will now take over in Rome.<a name="13" href="#note13"><sup>13</sup></a> Is it true that a dispute has arisen between the Jesuits and the other missionaries in China regarding the homage paid to Confucius? Insofar as I have understood the matter, these good fathers are slightly wronged by that dispute, and since statues are erected to the dead, although pagan, the memory of the dead may also be honoured in another [<b>A II 3, p590</b>] way, provided that one does not expect the dead to be of assistance. It seems to me that the neophytes among the Jesuits are no more idolaters in this matter than that Italian poet who every year sacrificed a copy of Martial's epigrams to the manes of Catullus.<a name="14" href="#note14"><sup>14</sup></a> I would like that the practical morality of these fathers be as innocent in everything else and that as people they be as honourable as some of their number I have known. But to want an entire community to be lacking in faults is to ask too much, so long as the faults do not take over. It seems that their authority has lost some ground in France, and I say that on account of what the Archbishop of Rheims has done.<a name="15" href="#note15"><sup>15</sup></a> But they are like that Antaeus of the fable, who recovers stronger.<a name="16" href="#note16"><sup>16</sup></a> Do you know, Sir, who are the mainstays of the late Mr Arnauld's party? It should be people who are ardent and of merit who are held in high regard. I am passionately,<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sir&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Your very humble and very obedient servant<span style={{float: 'right'}}>Leibniz</span>
                        <br /><br /><br />
                        <hr className='rightcol-hr' />
                        <br /><br />
                        NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> Christophe Brosseau (1630-1717), Brunswick-L&#252;neburg's Resident in Hanover.<br />
                        <a name="note2" href="#2">2.</a> Jean-Jacques Boisot (1655-1731), councillor and president in Besan&#231;on.<br />
                        <a name="note3" href="#3">3.</a> Leibniz's letter to Brosseau of 16 August 1699 has now been lost. Brosseau replied to it on 31 August 1699, informing Leibniz that the missing parcel from Nicaise had been entrusted to a Mr Nettelbeck, a Hanoverian merchant. See A I 17, p431.<br />
                        <a name="note4" href="#4">4.</a> Anton Pagi, <var>Critica historica-chronologica in annales ecclesiasticos Baronii</var> (Geneva, 1705).<br />
                        <a name="note5" href="#5">5.</a> The Abb&#233; de la Charmoye was Paul Yves Pezron (1639-1706), a monk and chronologist. Charmoye's letter to Nicaise, written 23 February 1699, appeared in the June 1699 issue of the <var>Nouvelles de la R&#233;publique des lettres</var>, pp628-39.<br />
                        <a name="note6" href="#6">6.</a> Guillaume Bonjour-Favre (1670-1714), missionary and orientalist; Hiob Ludolf (1624-1704), orientalist and linguist.<br />
                        <a name="note7" href="#7">7.</a> Partly on the basis of Chinese history, Charmoye argued that 6000 years had elapsed between the creation of the world and the birth of Christ, instead of the 4000 that was accepted by most in his time. To accommodate this extra 2000 years of history, he argued that the Septuagint should be used for the purposes of sacred chronology rather than the Vulgate.<br />
                        <a name="note8" href="#8">8.</a> Fran&#231;ois Diroys/Dirois, <var>Preuves et pr&#233;jug&#233;s pour la Religion Chr&#233;tienne et Catholique contre les fausses religions et l'ath&#233;isme</var> [Proofs and Signs in Favour of the Christian and Catholic Religion against the False Religions and Atheism] (Paris, 1683).<br />
                        <a name="note9" href="#9">9.</a> 'enjoys the papacy, I enjoy the cardinalship.'<br />
                        <a name="note10" href="#10">10.</a> Henry Noris (1631-1704), a Church historian who became assistant librarian in the Vatican in 1692 and Cardinal in 1695. Noris' works occasioned a great deal of controversy and he was charged with teaching Jansenism and other unorthodoxies. Nicaise and Noris were correspondents; see Leon G. Pelissier (ed), <var>Lettres de l'Abb&#233; Nicaise au Cardinal Noris</var> (1686-1701) (Paris: Besancon, 1903).<br />
                        <a name="note11" href="#11">11.</a> Andr&#233; Morell (1646-1703), an antiquarian and correspondent of both Leibniz and Nicaise. According to an earlier letter Leibniz wrote to Nicaise, of 16 June 1699, Morell had gone to take the warm waters of T&#246;plitz.<br />
                        <a name="note12" href="#12">12.</a> Leibniz is referring to the dispute between  Fran&#231;ois F&#233;nelon (Archbishop of Cambrai) and Jacques B&#233;nigne Bossuet (Bishop of Meaux) over disinterested love, which occurred between 1697 and 1699 following the publication of F&#233;nelon's <var>Explication des Maximes des Saints</var> [Explanation of the Maxims of the Saints] (1697). The dispute was finally halted on 12 March 1699 by the condemnation of F&#233;nelon's book by Pope Innocent XII.<br />
                        <a name="note13" href="#13">13.</a> Celestino Sfondrati (1644-1696), made Bishop of Novara in 1686 and cardinal-priest in 1695, less than a year before his death. Leibniz is referring here to Sfondrati's posthumous work <var>Nodus praedestinationis ex sac. litteris doctrinaque SS. Augustini et Thomae, quantum homini licet, dissolutus</var> (Rome, 1697), which dealt with grace and predestination. This book occasioned controversy, and on 23 February 1697 Pope Innocent XII received a letter written by Bossuet and signed by other Bishops denouncing Sfondrati.<br />
                        <a name="note14" href="#14">14.</a> Leibniz is referring to Andrea Navagero (1483-1529), an admirer of the Roman poet Catullus (c.84-54 B.C.E.). Every year Navagero would ritually burn a copy of Martial's poems to appease the manes of Catallus.<br />
                        <a name="note15" href="#15">15.</a> On 15 July 1697, the Archbishop of Rheims, Charles-Maurice Letellier (1642-1710), censured two theses upheld by the Jesuits.<br />
                        <a name="note16" href="#16">16.</a> An allusion to the Greek myth of Antaeus, a giant who had great strength so long as he remained in contact with the ground. Hercules found, when wrestling Antaeus, that no matter how often he threw Antaeus to the ground, it made no impact on him and he even came back stronger (so eventually Hercules held Antaeus aloft, which drained his strength and allowed Hercules to kill him).
                <br /><br /><br />
                        &#169; Lloyd Strickland 2008. Revised 2018
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
