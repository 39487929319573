import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/fish.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>Protogaea</var><br />
		Jean-Marie Barrande (ed)<br />
		pp 202-203<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 1706<br /><br />
		Note: This text is Fontenelle's own published summary of a text, now lost, which Leibniz submitted to the Academy of Sciences in Paris. The summary was published in <var>l'Histoire de l'Acad&#233;mie des Sciences de Paris</var> (1706).<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (15k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Note on the Rocks which Contain Dried Out Plants and Fish</title>
            <meta name="description" content="An English translation of NOTE ON THE ROCKS WHICH CONTAIN DRIED OUT PLANTS AND FISH by Gottfried Wilhelm Leibniz, from 1706" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>BERNARD LE BOVIER DE FONTENELLE: NOTE ON THE ROCKS WHICH CONTAIN DRIED OUT PLANTS AND FISH</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>PR p202</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;What we reported in <var>l'Histoire</var> in 1703, about those rocks found in the Verona area which contain dried out plants and fish, has been confirmed by Mr Leibniz. He says that in the land of Brunswick around Osterode, in the County of Mansfield around Eisleben and in many other places in Germany, one finds veins of slate, more or less horizontal, in which there are representations, though very exact and very detailed, of various kinds of fish or plants, which appear in their natural length and width, but without any thickness. These traces are often etched in a mixture of copper, which even contains silver. Some of these plants are unknown in those regions, but they are found in pictures of plants from the Indies.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mr Leibniz conceives that a kind of soil covered the lakes and surrounding areas, and buried fish and plants in it, or that some muddy water loaded with soil enveloped them or carried them away. This soil has itself since hardened into slate, and the length of time, or some other cause, destroyed the delicate matter of the fish or plant, almost in the same manner in which the bodies of flies or ants that are found encased in yellow amber have disappeared and are not now tangible at all, but simple delineations. The matter of the fish or the plant, being consumed, left its form imprinted in the slate, by way of a hollow which remained in it, and this hollow has eventually been filled by a metallic matter, whether a subterranean fire burning the soil into slate has made the metal that was mixed in it come out, or a metallic vapour penetrating the slate fixed itself into these hollows. Mr Leibniz adds that one [<b>Pr p203</b>] can imitate this effect by a rather curious process. One takes a spider, or some other suitable animal, and buries it within clay, while keeping an opening that enters from outside into the hollow. One puts the mass in a fire in order to harden it; the matter of the animal disappears in cinders, which one can make come out by way of some liquid. After which one pours through the opening some melted silver, and when that cools down, one finds inside of the mass the figure of the animal wonderfully represented in silver.<a name="1" href="#note1"><sup>1</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Several authors have called these kinds of representations of fish or plants in rocks <var>tricks of nature</var>, but this is a pure poetic idea, which a philosopher such as Mr Leibniz cannot accept. If nature deceived, it would do so with more freedom; it would not submit to expressing so exactly the smallest traits of the originals, and, what is even more remarkable, to preserving their dimensions so precisely; in cases where this exactness is not found, these could be tricks, that is, arrangements in some way fortuitous. It is true that a representation of a plant from the Indies in a German rock seems <var>prima facie</var> contrary to Mr Leibniz's system. But the fact that the plant represented is found in the Indies is good evidence that at least there is no trick there; it is easy to imagine a number of accidents by which a plant will have been brought from the Indies to Germany, even in the times when there was no commerce between these countries by shipping; and lastly it appears from several sources that there must have been great physical changes in the surface of the Earth. Mr Leibniz believes that in the past the sea covered almost everything, and that afterwards a great part of its waters made a passage to enter into the hollow abysses which are inside our globe; from there came the shellfish of the mountains. But all these matters deserve a more ample discussion.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTE:<br /><br />
                <a name="note1" href="#1">1.</a> This example is drawn from Leibniz's <var>Protogaea</var> (1690-1), &#167;18.<br /><br /><br />
                &#169; Lloyd Strickland 2004
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
