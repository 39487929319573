import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/revolution.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>De l'horizon de la doctrine humaine</var><br />
		Michael Fichant (ed)<br />
		pp 66-76<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 1715<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (20k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Restitution</title>
            <meta name="description" content="An English translation of RESTITUTION by Gottfried Wilhelm Leibniz, from 1715" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: RESTITUTION</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>HD p66</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We can define the number of all possible books not exceeding a determinate length, consisting of meaningful or meaningless words, which consequently also includes all books <var>having a sense</var>.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I call 'a book of determinate length' one which does not exceed a certain number of letters. For example, a book in folio consisting of 10,000 pages, with each page consisting of 100 lines and each line of 100 letters, will be a book of 100,000,000 letters. We will call 'books of a determinate length' those which do not exceed this.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Now the number of possible books not exceeding such a size, or which can be formed at most of a hundred million <var>letters of the alphabet</var>, is finite.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Not only is this number finite, but we can even find out from the calculus of combinations how many possible books there are, long or short, with the least difference between them, not exceeding the proposed number of letters. Call this number N.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Moreover, let us suppose that the <var>public history of a year of the world</var> can be adequately described by a book of such a length, which contains a hundred million letters, or even by a shorter book.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It also follows that the number of possible public histories of the world which differ from each other, is limited and does not exceed the number N; for any of them would produce a new book.<br /><br />

        [<b>HD p68</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If it should now be supposed that the human race endures for a long enough time in the state in which it is now, so that it can produce material for public histories, it is necessary that the earlier public histories return exactly at some point, which I demonstrate thus.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Let us assume a number of years, greater than the number N, in which the human race remains in such a state. Certainly during N years it either happens that any year of new history differs as much as you like from the history of any preceding year, or the history of any year agrees exactly with the history of some preceding year. If the latter happens, we have the intended result; but if the former happens, it follows that all possible public histories are exhausted in this number of years, and since (by the hypothesis) the human race endures beyond N number of years, it follows that the earlier histories return exactly in the years following after N. Which was to be demonstrated.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;But it is clear enough that the same argument applies if it comes down to private histories, and that we would only need to imagine a longer book and allow more years.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For a book of a sufficient length, albeit of several volumes, in which would be described in the most detailed way what all individual men have done in the whole world within a year, is certainly possible.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Let us imagine that there is on the Earth a thousand million men (though the human race is far removed from this number for a very long time), and that each man is assigned to write down one year of his life bit by bit, producing a book as long as the one which we attributed to the annual public history, that is, consisting of a hundred million letters; it is clear at least that this is sufficient.<br /><br />

        [<b>HD p70</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For even if 10,000 hours are attributed to one year, 10,000 letters, or one page of 100 lines, each of which each is 100 letters, would still be sufficient for one man to describe each hour.<br /><br />
        <table width="100%" border="0">
        <tr>
        <td width="35%" align="right">100<br />100<br />-------------<br />10,000
        </td>
        <td width="45%" align="right">10,000<br />10,000<br />-----------------<br />100,000,000</td>
        <td width="20%">&nbsp;</td>
        </tr>
        </table>
        <br clear="all" />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Thus it will be sufficient that the work containing the annual history of the whole human race, down to the least details, does not exceed a number of letters which reaches a hundred thousand million millionion, or a hundred thousand millionionion.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For I abbreviate a million millions as a 'millionion', and a million millionions as a 'millionionion', and so on. When Marco Polo returned from China to Venice - and there is a description of his travels - he was called <var>Messer Millione</var> or Mr Million, because he would only speak by millions and made a great fuss of enormous numbers: this is also what we should do here.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Moreover, even the possible number of works different from each other to some extent, in which the multitude of letters does not exceed a hundred thousand million millionions, is finite. Indeed, an even greater number can easily be assigned, as a result of the calculus of combinations. Call this number Q.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If we now assume that the human race endures long enough in the state in which it is now, i.e. the state which supplies the material for history, it is necessary that the time will come in which the life of individuals would return bit by bit throughout an entire year by means of the same circumstances, which will be demonstrated by arguing in the same way with regard to the number Q as we argued a little earlier with regard to the number N.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Indeed, if we assume a slightly greater number of years than the number Q, it would necessarily happen that a whole year of the human race would at some point return such as it was before, with all its circumstances.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;And it can be demonstrated in the same way that there would be a time in which [<b>HD p72</b>] a whole century would return; indeed, a whole millennium, or even a whole million years, or a millionion.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;However it could not be demonstrated from the calculus alone that Leopold I or Louis XIV or myself or another individual would return precisely, because if some others return more often it is not necessary that all return.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;However, since it is established by metaphysical reasons that the present is pregnant with the future, it can be concluded that when one century returns exactly enough, more will return exactly enough too, since it is fitting that almost identical effects return when almost identical causes return.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;However, even if an earlier century returns as far as sensible events go, or those things which can be described in books, it will still not return completely in all respects, for there would always be differences, albeit imperceptible ones, and no books could adequately describe them since the continuum is actually divided into an infinity of parts, and indeed there is a world of an infinity of creatures in each part of matter, which cannot be described by any book no matter how long it is. Certainly, if bodies consisted of atoms, all things would return precisely into the same collection of atoms, as long as new atoms were not added from elsewhere; just as if Epicurus's world were supposed, which is separated from other worlds by the spaces between worlds. But such a world would thus be a mechanism which a creature of finite perfection could know perfectly, which does not hold good in the real world.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;And for this reason it could happen that things gradually make progress towards the best, albeit imperceptibly, after the revolutions.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It could even be asked whether those whose history is to be repeated more than once would be the same, equipped with a soul numerically the same (perhaps progressing gradually), or whether they would be actually different, although very similar. But such questions cannot be determined by the calculus and pertain to the doctrine of the fittingness of things, i.e. of what is best and most in keeping with divine wisdom.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;And these revolutions, while the human race remains in this state, would take place not just once, but many times, indeed [<b>HD p74</b>] a greater number of times than can be assigned. This is what the ancients, who spoke of the <var>Great Platonic Year</var>, also seem to have had in mind, although they were not adequately understood, since they did not pass down to posterity the reasons for their opinion; but it is clear from their words.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Besides, it can actually be concluded from this that the human race will not always remain in the same state, since it is not in keeping with the divine harmony to always play the same chord. And it should even be believed as a result of the natural principles of fittingness that things must progress towards the better, either gradually or even sometimes by leaps. For although things constantly seem to get worse, this should be thought to happen in the same way that we sometimes step back in order to jump with a greater impetus.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Finally, even if the human race is not always going to remain as it is now, let us just suppose that there are always minds which know and investigate the truth - it follows that at some point minds will arrive at truths independent of evidence from the senses, i.e. theorems of pure science, which of course can be exactly demonstrated by reasons which have already been discovered and which do not exceed a certain length (for example a page, if they are written); and, all the more, it is necessary that they reduce to brief formulae which can be written down in words.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Consequently, the new theorems to be discovered ought to increase in length to infinity, just as we see that there are certain geometrical propositions which are rather long and nevertheless beautiful.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If this were to happen, even those minds which are not yet sufficiently capable would necessarily become more capable so that they are able to grasp and invent such great theorems as would also be needed to understand nature more deeply by reducing physical truths to mathematics, for example, to understand the mechanism of an animal, to foresee [<b>HD p76</b>] future contingents with a certain degree of probability, and therefore to perform certain wonders in nature, which are now beyond the capacity of humans. For if we consider a fly as a subject of science, just as a circle is such an subject, it is evident that a definition of the fly which would explain its structure is considerably more complicated than the definition of a circle. And therefore the theorems to be demonstrated about the fly would be extremely lengthy, to say nothing about individuals, who are themselves subject to a kind of applied science in which one needs to move from theory to practice.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;But the sensible truths, i.e. those which are not based on pure reason but wholly or in part on experience, can be varied to infinity, although they do not become lengthier. Consequently they can always supply new material and new items of knowledge, i.e. in theorems increasing in length.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The reason for this is that sensations consist in confused perception, which can be varied in an infinity of ways while retaining its conciseness, and there can be infinite kinds of liveliness, of feelings, of sensibilities. This is otherwise in those truths which can be adequately known, i.e. by perfect demonstration, for as they can be explained with words, they have a length which is limited in proportion to the reason for the length. And each mind has a limit of its present capacity in respect to the sciences but no limit of its future capacity.
                <br /><br /><br />
                &#169; Lloyd Strickland 2006
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
