import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/evil.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
		Shelfmark LH 1, 1, 2 Bl. 23r<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: early 1707 - May 1708 (?)<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (102k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz: Letter on the difficulties sparked by reason with regard to the compatibility of the attributes of God with evil (early 1707 - May 1708 (?))</title>
            <meta name="description" content="An English translation of a letter from GOTTFRIED WILHELM LEIBNIZ ON THE DIFFICULTIES SPARKED BY REASON WITH REGARD TO THE COMPATIBILITY OF THE ATTRIBUTES OF GOD WITH EVIL, written early 1707 - May 1708 (?)" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LETTER ON THE DIFFICULTIES SPARKED BY REASON WITH REGARD TO THE COMPATIBILITY OF THE ATTRIBUTES OF GOD WITH EVIL</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 <small>This piece is an abandoned attempt at starting the preface of the <var>Theodicy</var>. After deleting this text, the manuscript continues with material that eventually became part of the preface (see G VI 39ff/H62).</small><br /><br /><br />
		        [<b>LH 1, 1, 2 Bl. 23r</b>]<br /><br />		


                <center>Letter on the difficulties sparked by reason<br />
                with regard to the compatibility of the attributes of God with evil</center>
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sir,

                You remember that I often had the honour to converse with a great princess<a name="1" href="#note1"><sup>1</sup></a> on some entries of Mr Bayle's <var>Dictionary</var> which tended to show the conflict of reason and religion. He made it known that his intention was to silence reason after having made it speak, and he made the triumph of faith consist in that. I indicated that I was not of his opinion, but that I was nonetheless delighted that such a great genius had given occasion to go further into matters which are as important as they are difficult. I admit that I have also examined them since my early youth. I had resolved more than once to put my thoughts in writing, for this incomparable princess exhorted me to do it.
                        <br /><br /><br />
                        <hr className='rightcol-hr' />
                        <br /><br />NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> Queen Sophie Charlotte of Prussia (1668-1705).
                <br /><br /><br />
                        &#169; Lloyd Strickland 2003 (revised 2016)
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
