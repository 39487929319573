import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/contingenttruths.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Sources:<br /><br />
		<var>Textes in&#233;dits tome 1</var><br />
		Gaston Grua (ed)<br />
		pp 325-326<br /><br />
		<var>S&#228;mtliche schriften und briefe series VI volume 4</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 1663-1664<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: summer 1689?<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (11k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>The Origin of Contingent</title>
            <meta name="description" content="An English translation of THE ORIGIN OF CONTINGENT TRUTHS by Gottfried Wilhelm Leibniz, from summer 1689?" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: THE ORIGIN OF CONTINGENT TRUTHS</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>Gr p325</b>] [<b>A VI 4, p1663</b>]<br /><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The reasons given for contingent truths must proceed to infinity; or it is to be understood by this that there is an actual infinity of creatures in any part of the universe, and every individual substance envelops the whole series of things in its own complete concept, and is in agreement with all other things, and to such a degree that it contains something of the infinite. Because this is not understood, the union of soul and body has also been taken to be inexplicable; for they do not mutually flow into each another, in metaphysical rigour, nor indeed does God move one thing on the occasion of the other and dislodge it from its own particular course; but each thing pursues its own laws from the initial institution and, by a marvellous but infallible act of direction, it is in agreement so exactly with the other that it is as if there were a true influx. And there is also something similar in all substances, even when they are very remote from each other, even if in those the agreement does not appear so distinctly.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If everything that exists is necessary, it would follow that only those things which exist at some time or other are possible (as Hobbes and Spinoza wish), and that matter would take on all possible forms (as Descartes wished). So no story could be fashioned which [<b>A VI 4, p1664</b>] did not exist at some time or place. Which is absurd. And so we shall say rather that from an infinity of possible series God chose one, for reasons beyond the grasp of creatures.<br /><br />
                [<b>Gr p326</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The cause of evil arises from the original limitation of creatures before all sin, and God decrees nothing except that alone which is pure positive, or consists in perfection, and therefore evil is only permitted by God, which is otherwise in men who by themselves do not tend towards the greater general good.<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Every truth which is not an identity admits proof; the necessary truths by showing that the contrary implies contradiction; the contingent truths by showing that what is done is more reasonable than its opposite. For as of the wise man, so of God, the first decree or resolution is to do all things with consummate reason. So if we imagined a case in which it was agreed that a triangle of given dimensions should exist, but with nothing in the data whence the kind of triangle could be derived, it would have to be said that God would produce an equilateral triangle, freely indeed, but without a doubt. For there is nothing in the data that would prevent the existence of another kind of triangle, so an equilateral triangle is not necessary. However the fact that no other type of triangle is chosen is sufficient to show that there is no other adequate explanation, except as is set out in this reasoning, for why an equilateral triangle is given preference over others. It is just the same if the instruction is for a line to be drawn from one given point to another given point, with no instruction on the type of line or its length; certainly it will be drawn as a straight line, but freely; just as there is nothing to prevent its being a curved line, equally there is nothing which says it should be.
                <br /><br /><br />
                &#169; Lloyd Strickland 2003<br />
                With gratitude to John Thorley for advice and suggestions
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
