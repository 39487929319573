import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/veit.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>S&#228;mtliche schriften und briefe series II, volume 1</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 543-544<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 29 December 1684/8 January 1685<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (12k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Veit Ludwig von Seckendorff (29 December 1684/8 January 1685)</title>
            <meta name="description" content="An English translation of a LETTER TO VEIT LUDWIG VON SECKENDORFF by Gottfried Wilhelm Leibniz, from 29 December 1684/8 January 1685" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO VEIT LUDWIG VON SECKENDORFF</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A II 1, p543</b>]<br /><br />
                The passage you cite from Aristotle's <var>Eudemian Ethics</var> VII chap 14, is splendid,<a name="1" href="#note1"><sup>1</sup></a> and from that it is rightly said that there is something more excellent than reason acting in us, divine in fact, although the reasons he puts forward concerning the enthusiasm and successes of the ignorant are not strong enough. The same thing can be demonstrated from the very nature of the mind, and by much better arguments. But I fear Aristotle may have had in mind here the dangerous opinion, of which he has elsewhere given an indication, of the universal agent intellect, which alone remains after death and is the same and in all men. [<b>A II 1, p544</b>] Averroists have revived this opinion. But the opinion itself, with that most awful addition left aside, is most excellent in itself, and conforms to reason and Scripture. For God is that light which illuminates all men coming into this world.<a name="2" href="#note2"><sup>2</sup></a> And the truth which speaks within us, when we understand theorems of eternal certainty, is the actual voice of God, which Augustine has observed too.<a name="3" href="#note3"><sup>3</sup></a>
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> Aristotle, <var>Eudemian Ethics</var>, VII 14, 1246b-1248b.<br />
                <a name="note2" href="#2">2.</a> John 1.9.<br />
                <a name="note3" href="#3">3.</a> Augustine, <var>In Johannis evangelium tractatus</var> 54.8.<br />
        <br /><br />
                &#169; Lloyd Strickland 2007-08
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
