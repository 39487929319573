import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/septnov1702.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>Correspondenz von Leibniz mit der Prinzessin Sophie vol II</var><br />
		Onno Klopp (ed)<br />
		pp 367, 379-380, 385<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 20 September - 11 November 1702<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (20k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to/from Electress Sophie (20 September - 11 November 1702)</title>
            <meta name="description" content="An English translation LETTERS TO/FROM ELECTRESS SOPHIE (SELECTIONS) by Gottfried Wilhelm Leibniz, from 20 September - 11 November 1702" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO/FROM ELECTRESS SOPHIE (SELECTIONS)</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 <div id="note">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Following the arrival of Isaac Jaquelot in Hanover on 15 September 1702, his book <var>Dissertation sur l'existence de Dieu</var><a name="1" href="#note1"><sup>1</sup></a> briefly became a topic of discussion in Sophie's correspondence with Leibniz, starting with the following letter. Although Leibniz did respond, his reply is no longer extant.</div><br /><br /><br />
		        <b><center>Sophie to Leibniz (20 September 1702)</center></b><br />

                [<b>K II p367</b>]<br /><br />

                <div align="right">Herrenhausen, 20 September 1702</div><br />

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I hope the good opinion Faltz has of his work<a name="2" href="#note2"><sup>2</sup></a> will be more to everyone's taste than the work of Mr Jaquelot, which I made Mr Klenck read.<a name="3" href="#note3"><sup>3</sup></a>  Although Mr Klenck is quite sure of his religion, he maintained that Mr Jaquelot's book would not convert atheists. It seems to me that this frankness displeased this learned man, who replied to him with thousands of arguments which silenced Klenck, but when he was asked if he was not convinced he said that he wasn't, that this book would not convert an atheist nor would his fine speech. I wouldn't dare to say that Klenck was right, although he didn't say it with many supporting arguments.<br /><br />
                <hr className='rightcol-hr' /><br />
                        <b><center>Sophie to Leibniz (7 October 1702)</center></b><br />

                <div id="note">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sophie's reply to a no longer extant letter from Leibniz from the end of September 1702, which was itself a reply to Sophie's letter of 20 September (see above).</div>
                <br />
                [<b>K II p379</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You are very much mistaken if you think Mr Jaquelot believes any of his arguments have been contradicted, as he considers them irrefutable. The fact is that one already agrees with what he claims to prove, and does not dare contradict him. Besides, he makes for very good conversation.
                <br /><br />
                <hr className='rightcol-hr' /><br />
                        <b><center>Leibniz to Sophie (mid-October 1702)</center></b><br />
                <div id="note">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Leibniz's reply to Sophie's letter of 7 October 1702 (see above).</div>
                [<b>K II p380</b>]<br /><br />

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I want to do a little read through of Mr Jaquelot's book again. When I read it a while ago, I found it learned throughout, but not equally strong throughout. That is unobjectionable, and it is customary to combine decisive arguments even with those which offer at least a high probability. For reasonings which are good for some are not always so for others, and sometimes an argument which is only probable is more effective on certain minds than the strongest demonstration.
                <br /><br />
                <hr className='rightcol-hr' /><br />
                        <b><center>Leibniz to Sophie (11 November 1702)</center></b><br />
                <div id="note">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Leibniz's follow-up to his letter of mid-October 1702 (see above).</div>
                [<b>K II p385</b>]<br /><br />

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Extract from my letter to Madam the Electress

                <div align="right">11 November 1702</div>
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mr Jaquelot has just seen me, and as his book is due to be reprinted,<a name="4" href="#note4"><sup>4</sup></a> the copies having sold out, he asked me to look over it and let him know my thoughts on it so that he can bolster the proofs and anticipate or refute objections. So if anyone wants to do that for him, well, the battlefield awaits. But I don't know if Mr Bar<a name="5" href="#note5"><sup>5</sup></a> and Mr Klencke will be the people to enter it with him. I would like Mr Toland to work on this,<a name="6" href="#note6"><sup>6</sup></a> which he could do without mentioning his own views since it is not a question of the truth of the matter but of Mr Jaquelot's proofs. I doubt, however, that Mr Toland is like me, who works for the public, and I think he would do the same as the antiquarian Dr Bon in Venice,<a name="7" href="#note7"><sup>7</sup></a> who, when he was given some old medal to decipher, gave the explanation and asked for some doppie in return,<a name="8" href="#note8"><sup>8</sup></a> as Mr Fountaine,<a name="9" href="#note9"><sup>9</sup></a> who has returned from this country, was telling me. The pieces of 24 Groschen of Hanover<a name="10" href="#note10"><sup>10</sup></a> were not enough to make him communicate his story. At least as many guineas would be needed, or some fine golden medals. I do not know if he has had any from the King etc.<a name="11" href="#note11"><sup>11</sup></a>
                <br /><br /><hr className='rightcol-hr' /><br />
                        NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> [Dissertation on God's existence] (The Hague, 1697).<br />
                        <a name="note2" href="#2">2.</a> Raimund Faltz (1658-1703), Swedish medallist, sculptor and painter. From 1690 he was in the employ of Sophie Charlotte's husband, Frederick III of Brandenburg. Sophie is referring to a medal struck by Faltz to commemorate her accession<br />
                        <a name="note3" href="#3">3.</a> Leopold von Klencke (1678-?).<br />
                        <a name="note4" href="#4">4.</a> In the end, Jaquelot's book was not reprinted until 1744.<br />
                        <a name="note5" href="#5">5.</a> Heinrich Sigismund von Bar (1655-1721).<br />
                        <a name="note5" href="#6">6.</a> John Toland (1670-1722), Irish freethinker.<br />
                        <a name="note6" href="#7">7.</a> Niccol&#242; Bon.<br />
                        <a name="note7" href="#8">8.</a> A doppia (plural: doppie) was an Italian gold coin.<br />
                        <a name="note8" href="#9">9.</a> Andrew Fountaine.<br />
                        <a name="note9" href="#10">10.</a> A gros was a silver coin used in many German states from the 14th to the 19th century. There were 24 Groschen in a Thaler.<br />
                        <a name="note10" href="#11">11.</a> The King referred to here is Friedrich I of Prussia.
                <br /><br /><br />
                        &#169; Lloyd Strickland 2009-11
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
