import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/bourguet20april.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>Die philophischen schriften von Gottfried Wilheim Leibniz, vol. III</var><br />
		C. I. Gerhardt (ed)<br />
		p 594<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 20 April 1716<br /><br />
		Translated from the French<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (88k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Louis Bourguet (20 April 1716)</title>
            <meta name="description" content="An English translation of a LETTER TO LOUIS BOURGUET by Gottfried Wilhelm Leibniz, from 20 April 1716" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO LOUIS BOURGUET</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>G III p594</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I have just received the honour of your letter, together with the enclosed for Mr Hermann, which I will send to him at once. It seems to me that Mr Newton and the English are flattered a little too much in his book. Aside from that, there are many good things in it.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If your friends in Milan have not yet found the opportunity to send the eggs to Augsburg, it will apparently no longer be the time to do it. Nevertheless I am obliged to you, Sir, for your efforts.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The deviation of the planets from the elliptical line can apparently only come from the operation of the planets amongst themselves, or from the resistance of the medium. We would have to combine observations with calculation in order to make a judgement on this. The most useful thing would be to determine the course of the Moon, after having clearly determined that of the Earth. Mr Flamsteed<a name="1" href="#note1"><sup>1</sup></a> claims that Mr Newton has not employed enough observations. Mr Zendrini has mentioned nothing to me about Count Riccati's theory on the planets. I hope that he combines observations with arguments.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mr Clark has not given me much satisfaction at all. He has not properly understood the force of this maxim, that nothing happens without a sufficient reason to determine it.<a name="2" href="#note2"><sup>2</sup></a> I do not remember this other Englishman who must have spoken about the matter.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I will not be able to think about my Dynamics, nor about other matters of philosophy or mathematics, before having been relieved of my present historical work. Moreover, I am sincerely etc. Hanover, 20 April 1716.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTE:<br /><br />
                <a name="note1" href="#1">1.</a> John Flamsteed (1646-1719), English astronomer.<br />
                <a name="note2" href="#2">2.</a> Leibniz is referring here to his increasingly bitter exchange of letters with Samuel Clarke (1675-1729), the English divine and supporter of Isaac Newton.<br />
                <br /><br />
                &#169; Lloyd Strickland 2004. Revised 2018
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
