import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/goldbach1712.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>Opera Omnia volume 3</var><br />
		Ludovic Dutens (ed)<br />
		pp 437-438<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 17 April 1712<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (209k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Christian Goldbach (17 April 1712)</title>
            <meta name="description" content="An English translation of a LETTER TO CHRISTIAN GOLDBACH by Gottfried Wilhelm Leibniz, from 17 April 1712" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO CHRISTIAN GOLDBACH</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>D III, p437</b>]<br /><br />
		        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It is not impossible that somewhere there are animals which have more musical sensibility than us and are delighted by musical proportions by which we are hardly affected. But I think that were our senses much keener, this would harm us more than benefit us, for we would sense many unpleasant things by sight, smell, and touch; also, those who have extraordinary acuity of sense in music are disturbed by certain errant notes that are not entirely avoidable in the construction of practical instruments, notes which nevertheless do not usually disturb the audience. In music, we do not count beyond five, just like those people who, even in arithmetic, do not proceed beyond three, and about whom the German saying about a simple man holds good: er kan nicht &#252;ber drey zehlen [he cannot count beyond three]. For all our usual intervals are of ratios composed from ratios between pairs of the prime numbers 1, 2, 3, and 5. If we had a little more acuity, we could proceed to the prime number 7. And I think there really are people like this. Consequently, the ancients did not completely shun the number 7. But there will be hardly anyone who will proceed to the [next] closest prime numbers, 11 and 13.<br />
		        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Moreover, I think that the reason for consonance must be sought from the congruity of the beats. Music is a secret exercise of arithmetic where the mind is unaware that it is counting. For, in confused or insensible perceptions, the mind does many things it cannot notice by a distinct apperception. Indeed, those who think that nothing happens in the soul of which it is not conscious are mistaken. Therefore, even if the soul does not realize it is counting, it nevertheless feels the effect of this insensible calculation, that is, the pleasure in consonances resulting therefrom or the vexation in dissonances. For pleasure arises from many insensible congruities. Generally, however, [<b>D III, p438</b>] those who attribute to the soul only the operations of which it is conscious, appraise the matter incorrectly. From that there have arisen many errors, committed not only by ancient philosophers but also by the Cartesians themselves and by other, more recent philosophers, like Locke and Bayle. But to return to the matter at hand, in the octave the second stroke of one of the series of strokes coincides with each stroke of the other series. In the fifth, each third of one series and the second of the other are in agreement. It can be said that the regular polygons of the circle and other bodies of the same kind have been put to use by the very acute Kepler &#940;&#960;&#961;&#959;&#963;&#948;&#953;&#972;&#957;&#965;&#963;&#945; [unfittingly], since they do not relate to the arithmetic of rational numbers.<br />
		        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I do not think that surd ratios are pleasing to the soul in themselves, except when they are not very distant from the rational ones that are pleasing; by accident, however, dissonances are sometimes pleasing, and are usefully employed, and are interposed in agreeable things like shadows in order and in light, so that we should then take pleasure in order even more. Your friend, whose paper you have communicated to me, and who wants to cut the monochord in the extreme and mean ratio, will actually discover that the intervals which then appear to our sense almost coincide with the major and minor sixth; that is, if AB is to BC as BC is to CA, the intervals will be roughly<br /><br />
                <table width="100%">
                <tr>
                <td width="40%">&nbsp;</td>
                <td width="20%">AB,</td>
                <td width="20%">BC,</td>
                <td width="20%">CA,</td>
                </tr>
                <tr>
                <td>A---------B,	as</td>
                <td>8,</td>
                <td>5,</td>
                <td>3,</td>
                </tr>
                <tr>
                <td>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;C</td>
                <td>minor sixth</td>
                <td>&nbsp;</td>
                <td>major sixth</td>
                </tr>
                </table>
                <br /><br />They are thus sufficiently close, like 809, 500, 309, when supposing that &#8730;5 is 2.236 or 2 236/1000; they are accurately expressed as &#8730;5 + 1, 2, &#8730;5-1, and while &#8730;5 (which is certainly irrational) is a little larger than 2.236, the error is smaller than 1/1000 (ACB). Accordingly, if anything is pleasing in this division, it will derive it from these neighbouring intervals.
                <br /><br /><br />
                        &#169; Lloyd Strickland 2018
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
