import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/cartesiandoubt.pdf'; 
import { Helmet } from 'react-helmet';

function CartesianDoubt() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>Opera Omnia volume VI, 1</var><br />
		Ludovic Dutens (ed)<br />
		p 319<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 1684 - 1698 (?)<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (176k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>On the Cartesian Method of Doubt</title>
            <meta name="description" content="An English translation of ON THE CARTESIAN METHOD OF DOUBT by Gottfried Wilhelm Leibniz, from 1684 - 1698 (?)" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: ON THE CARTESIAN METHOD OF DOUBT</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>D VI 1, p319</b>]<br /><br />Descartes, book 2, letter 92, p415.<a name="1" href="#note1"><sup>1</sup></a> He admits that there is no method of discerning which proposition should be taken for a principle except that prejudgements should be rejected, that is, that all things should be rejected about which there can be doubt.<a name="2" href="#note2"><sup>2</sup></a> But he does not identify those about which there can be doubt.<br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> Leibniz is referring to <var>Lettres de M. Descartes, o&#249; sont expliqu&#233;es plusieurs belles difficultez touchant ses autres ouvrages. Tome second</var>, ed. Claude Clerselier (Paris: Charles Angot, 1666), 415, which contains Descartes' letter to Mersenne of 15 November 1638.<br />
                <a name="note2" href="#2">2.</a> &quot;I know of no other way of making sound judgements about the notions which can be taken for principles, except that we must prepare our mind to divest itself of all the views with which it is preoccupied, and to reject as doubtful everything that might be doubtful.&quot; Descartes to Mersenne, 15 November 1638, in Ren&#233; Descartes, <var>The Philosophical Writings of Descartes. Volume III</var>, eds. and trans. John Cottingham, Robert Stoothoof, Dugald Murdoch, and Anthony Kenny (Cambridge: Cambridge University Press, 1991), 129.
                <br /><br /><br />
                &#169; Lloyd Strickland 2018
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default CartesianDoubt;
