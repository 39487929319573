import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Image from '../images/key-large.jpg';
import { Helmet } from 'react-helmet';

function Key() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        <a href="https://www.amazon.co.uk/gp/product/0198844980/" target="_blank">Order from Amazon (UK)</a><br /><br />
        <a href="https://www.pdcnet.org/leibniz/content/leibniz_2020_0030_0147_0161" target="_blank">Review by Adam Harmer, from <var>The Leibniz Review</var></a> (subscription required for access)<br /><br />
      </div>
    );
  }

  return (
    <div>
      <Helmet>
            <title>Leibniz's Key Philosophical Writings: A Guide</title>
            <meta name="description" content="A book edited by Lloyd Strickland and Paul Lodge on 'Leibniz's Key Philosophical Writings: A Guide'" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
              
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ'S KEY PHILOSOPHICAL WRITINGS: A GUIDE</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}
                   
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                  <img src={Image} alt="Leibniz's Key Philosophical Writings: A Guide" style={{ float: 'left', marginRight: '20px', height: isMobile ? '220px' : '364px', width: 'auto' }} />
                  <p style={{ margin: '10px 0px 0px 0px', fontSize: '13px' }}>Published October 2020, by Oxford University Press.</p>
                  <p style={{ fontSize: '13px' }}>Co-edited with Paul Lodge.</p>
                  <p>From the back cover blurb:</p>
                  <p>Gottfried Wilhelm Leibniz (1646-1716) is one of the most important and influential philosophers of the modern period, offering a wealth of original ideas in metaphysics, epistemology, ethics, and philosophical theology, among them his signature doctrines such as substance and monads, pre-established harmony, and optimism. This volume contains introductory essays on eleven of Leibniz's key philosophical writings, covering youthful works (&quot;Confessio philosophi&quot;, &quot;De summa rerum&quot;), seminal middle-period writings (&quot;Discourse on Metaphysics&quot;, &quot;New system&quot;), to masterpieces of his maturity (&quot;Monadology&quot;, &quot;Discourse on the Natural Theology of the Chinese&quot;), as well as his two main philosophical books (<var>New Essays on Human Understanding</var>, and <var>Theodicy</var>), and three of his most important philosophical correspondences, with Antoine Arnauld, Burcher De Volder, and Samuel Clarke. The essays, written by internationally-renowned experts on Leibniz, offer clear, accessible accounts of the ideas and arguments of these key writings, along with valuable information about their composition and context. By focusing on the primary texts, these essays enable readers to attain a solid understanding of what each text says and why, and give them the confidence to read the texts themselves. Offering a detailed and chronological view of Leibniz's philosophy and its development through some of his most important writings, this volume is an invaluable guide for those encountering Leibniz for the first time.<br clear = "all" /></p>
                  <div>
                  <b>CONTENTS</b><br /><br />
                  Acknowledgements<br />
                  List of contributors<br />
                  List of abbreviations<br /><br />
                    Introduction: A Sketch of Leibniz's Life and Philosophical Works<br />
                    - Paul Lodge and Lloyd Strickland<br /><br />

                    &quot;Confessio philosophi&quot;<br />
                    - Lucy Sheaf<br /><br />

                    &quot;De summa rerum&quot;<br />
                    - Mogens Laerke<br /><br />

                    &quot;Discourse on Metaphysics&quot;<br />
                    - Lloyd Strickland<br /><br />

                    The Correspondence with Arnauld<br />
                    - Julia Jorati<br /><br />

                    &quot;New System of the Nature of Substances&quot;<br />
                    - Julia Borcherding<br /><br />

                    The Correspondence with De Volder<br />
                    - John Whipple<br /><br />

                    <var>New Essays on Human Understanding</var><br />
                    - Martha Brandt Bolton<br /><br />

                    The <var>Theodicy</var><br />
                    - Paul Lodge<br /><br />

                    &quot;The Monadology&quot;<br />
                    - Lloyd Strickland<br /><br />

                    &quot;Discourse on the Natural Theology of the Chinese&quot;<br />
                    - Daniel J. Cook<br /><br />

                    The Correspondence with Clarke<br />
                    - Gregory Brown<br /><br />
            
                    References<br />
                    Index
                    <br></br><br></br>
                  </div>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Key;
