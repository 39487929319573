import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/buquoi.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
		Shelfmark LBr 68 Bl. 116<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 28 November 1711<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (187k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Critical Remarks on Abbe Buquoi's "Discours sur l'existence de Dieu"</title>
            <meta name="description" content="An English translation of CRITICAL REMARKS ON ABBE BUQUOI'S 'DISCOURS SUR L'EXISTENCE DE DIEU' by Gottfried Wilhelm Leibniz, from 28 November 1711" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: CRITICAL REMARKS ON ABB&#201; BUQUOI'S <em>DISCOURS SUR L'EXISTENCE DE DIEU</em></h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>LBr 68 Bl. 116</b>]<br /><br />		
		        <center>Hanover, 28 November 1711</center><br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I am delighted to have read the small discourse by Abb&#233; Bucquoi on the existence of God,<a name="1" href="#note1"><sup>1</sup></a> since not only does it contain several solid thoughts, but also expressions capable of moving, and which indicate that he was himself moved by this great truth.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Yet there are passages which would need to be developed and reduced to more conclusive form, which will not be difficult for him when he wants to take the trouble to do it. So I won't focus on that, but I do have to dwell on a passage which seems to me in need of a little correction.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It is not far from the beginning, but something similar seems to come up again in other passages. He finds that there is a manifest contradiction in saying that several parts of matter have their existence through themselves, because each would thus be sovereign and would have supremacy, which seems to him obviously contradictory. But it seems to me that the contradiction is not apparent so soon, and that a long sequence of arguments would be required to show it. For two things would have to be proved true, but they are not entirely obvious. (1) That <em>a being which has existence from itself is supremely perfect</em>, which opponents will apparently not accept because according to them it seems that all atoms exist eternally and from themselves. (2) That <em>it is impossible that there be several supremely perfect beings</em>. But it could be said, for example, that on the surface of a sphere there are several paths from one pole to the other which are equally the shortest, namely all those which go through the meridians, of which an infinity can be assigned. And on the same spherical surface there is an infinity of circles which are <em>circuli maximi</em>,<a name="2" href="#note2"><sup>2</sup></a> that is, the greatest, and consequently equal to each other. Thus the utmost degree does not always require that the subject endowed with it be unique. And it would have to be proved in particular that this consequence holds good in the case of the utmost perfection.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The independence of the parts of matter can be refuted without any need for these two suppositions. For it is sufficient to consider that each part of matter is a whole which still has parts. Now each whole is dependent on its parts, and receives its existence from them. Therefore there is no part of matter which is independent.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> <em>Pens&#233;es sur l'existence de Dieu</em>.<br />
                <a name="note2" href="#2">2.</a> 'the greatest circles'.<br /><br /><br />
                &#169; Lloyd Strickland 2014
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
