import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/descartes.pdf'; 
import { Helmet } from 'react-helmet';

function Descartes() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series VI volume 4</var><br />
        Deutsche Akademie der Wissenschaften (ed)<br />
        pp 1466-1467<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 1683 - 1685 (?)<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (71k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>On What is Absurd, False, and Problematic in Descartes</title>
            <meta name="description" content="An English translation of ON WHAT IS ABSURD, FALSE, AND PROBLEMATIC IN DESCARTES by Gottfried Wilhelm Leibniz, from 1683 - 1685 (?)" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: ON WHAT IS ABSURD, FALSE, AND PROBLEMATIC IN DESCARTES</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A VI 4, p1466</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Descartes says that matter takes on in order all the forms of which it is capable.<a name="1" href="#note1"><sup>1</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This strikes me as absurd.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Descartes' reasoning is false when he says that we have an idea of the God or the most perfect being because we understand the words &quot;being&quot; and &quot;perfect&quot; and &quot;supremely&quot;.<a name="2" href="#note2"><sup>2</sup></a> It will be proved by the same argument [<b>A VI 4, 1467</b>] that we have an idea of the fastest motion or highest number because we understand the words. And yet there is no idea of an impossible thing.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It seems to me that all bodies are only modes, for as there is no argument by which it can be shown that they act and are acted upon, they are nothing other than our sensations. Therefore body is nothing other than the object of sensations; God is the cause of actions that without reason we attribute to bodies. All actions are of God except ours, namely those of minds, therefore the reality of bodies is no different from the reality of dreams except that bodies are in agreement and depend upon a certain rule, and that rule originates from God's will, that is, from a higher intellect. The reason Descartes has fallen into difficulty over the union and mind and body<a name="3" href="#note3"><sup>3</sup></a> is because he considered bodies as substances, but I consider them as modes of the mind, and the reason different minds have harmonious perceptions is because they all communicate with the same Being, namely God. You will say that by the same argument it can be shown that one should not assert that there are minds, because there is no way of proving that there are. On the contrary, I answer that there is a way. For it can be shown <var>a priori</var> that there are many possible ways of perceiving a thing that do not disturb each other, and hence it is necessary that minds exist. But there is a demonstration with regard to bodies. For it is impossible that any reason be contrived - even by an all-knowing individual - which would prove that bodies are substances, from which it follows they are not substances.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> See Descartes, <var>Principles of Philosophy</var> III.47, in <var>The Philosophical Writings of Descartes</var>, ed. and trans. (Cambridge: Cambridge University Press, 1985), I: 258, and also Descartes' letter to Mersenne of 9 January 1639 in <var>Oeuvres de Descartes</var>, eds. Charles Adam and Paul Tannery (Paris: Vrin), II: 485.<br />
                <a name="note2" href="#2">2.</a> See Descartes' Second Set of Replies, in <var>The Philosophical Writings of Descartes</var> II, 115.<br />
                <a name="note3" href="#3">3.</a> The union of mind and body is discussed at length in <var>The Correspondence between Princess Elisabeth of Bohemia and Ren&#233; Descartes</var>, trans. Lisa Shapiro (Chicago: Chicago University Press, 2007).
                <br /><br /><br />
                &#169; Lloyd Strickland 2016<br />
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Descartes;
