import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/cartesianism.pdf'; 
import { Helmet } from 'react-helmet';

function Cartesianism() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}> 
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series VI volume 4</var><br />
        Deutsche Akademie der Wissenschaften (ed)<br />
        p 1356<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 1677?<br /><br />
		Translated from the French<br /><br />{isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (9k)</Link><br /><br />
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Cartesianism, the antechamber of the true philosophy</title>
            <meta name="description" content="An English translation of CARTESIANISM, THE ANTECHAMBER OF THE TRUE PHILOSOPHY by Gottfried Wilhelm Leibniz, from 1677?" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: CARTESIANISM, THE ANTECHAMBER OF THE TRUE PHILOSOPHY</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A VI 4, 1356</b>]<br /><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;One day I happened to say that Cartesianism, insofar as there is good in it, was only the antechamber of the true philosophy. A man of the company who frequented the court, who had some reading and who even involved himself with reasoning about the sciences, pushed the image to an allegory and perhaps a little too far, for he asked me at that point if I did not think it could be said, on this basis, that the ancients had shown us the stairs, and that the modern school had come to the guardroom; and if the innovators of our century had come as far as the antechamber, that he wished me the honour of introducing us into the cabinet of nature. This tirade of parallels made us all laugh, and I said to him 'You see Sir, that your comparison has delighted the company; but you have forgotten that there is the audience chamber between the antechamber and the cabinet, and that it will be enough if we obtain audience without claiming to penetrate into the interior.'
		        <br /><br /><br />
		        &#169; Lloyd Strickland 2005
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Cartesianism;
