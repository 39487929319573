import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/relation.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series VI, volume 4</var><br />
		Deutsche Akademie der Wissenschaften<br />
		p 1615<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: April - October 1686 (?)<br /><br />
        Translated from the French<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (153k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>On the general relation of all things</title>
            <meta name="description" content="An English translation of ON THE GENERAL RELATION OF ALL THINGS by Gottfried Wilhelm Leibniz, from April - October 1686 (?)" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: ON THE GENERAL RELATION OF ALL THINGS</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A VI 4, 1615</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The general and exact relation of all things to one another proves that all parts of matter are full of organism. For as each part of matter has to express the others, and as there are many organic parts among the others, it is clear that there must be something organic in what represents the organic.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I even add that there is no chaos in nature, nothing that is not artistically worked even down to its parts, however small they may be. It is true that we notice many crude pieces appearing to lack artistry, but what is worked is too small to be apparent, and yet it is everywhere. God’s wisdom never allows there to be true chaos, as this would be a defect in his artistry.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It follows that we could not identify parts having nothing organic about them, because such a part, by expresses the others which are organic, would have something organic about it too, <var>contrary to the hypothesis</var>.
                <br /><br /><br />
                &#169; Lloyd Strickland 2005. Revised 2022<br />
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
