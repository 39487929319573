import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/vonderhardt.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>S&#228;mtliche schriften und briefe series I, volume 14</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 881-882<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 21/31 December 1697<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (10k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Hermann von der Hardt (21/31 December 1697)</title>
            <meta name="description" content="An English translation of a LETTER TO HERMANN VON DER HARDT by Gottfried Wilhelm Leibniz, from 21/31 December 1697" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO HERMANN VON DER HARDT</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A I 14, p881</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Most distinguished Sir, Most honoured supporter<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The first volume of your <i>Constantiense Concilium</i> will come quickly enough,<a name="1" href="#note1"><sup>1</sup></a> with some copies sent to the English when it seems convenient.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I entirely agree with you about the letter-apostolate, that it should be discussed with the Most Serene Duke Rudolph August in person rather than through letters; but I am arranging for the matter to be delayed until the fair.<a name="2" href="#note2"><sup>2</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You judge very nobly that the propagation of the Christian religion through the far east will also be useful to us by a sort of reflection of light, for a more detailed acquaintance with the things of the east will very much contribute to the elucidation of Holy Scripture, especially the Old Testament, since it was itself written in the east and by easterners, who differ greatly from us in style, customs, and, in short, in very many things. What you infer about the gourd of Jonah,<a name="3" href="#note3"><sup>3</sup></a> that it is not much different from the Indian fig tree, is very likely. I have carefully reminded both Grimaldi and Bouvet that they should undertake to give us a truthful Chinese history, untarnished by any of our prejudices. For since truth should agree with truth, they should not be afraid that anything it contains [<b>A I 14, p882</b>] may serve as an obstacle to the Christian religion. I have also suggested that a sort of critical account of the Chinese authors and of their literary matters should be written according to the form we have in Greek and Latin works, so that the authentic and contemporary written works may be distinguished from forgeries and substitutes as far as that is possible. I honestly hope that it will be done, because actual books by the Chinese are brought forward in great number. For Bouvet himself has brought to the Most Christian King more than three hundred codices, on which he will give some report. And since other ones are extant everywhere throughout Europe, forgers are not practising their art with sufficient caution. If hereafter you should happen upon any of these codices I have asked or informed you about, let me know as soon possible. In the meantime, I wish you a very happy new year with many more to follow. Hanover, 21 December 1697.<br /><br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Your most devoted servant&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Gottfried Wilhelm Leibniz<br /><br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To Mr de Hardt, renowned professor at Helmstadt
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> Hermann von der Hardt, <i>Magnum oecumenicum Constantiense Concilium de universali ecclesiae reformatione, unione et fide</i>, 6 vols. (Frankfurt and Leipzig, 1696-1700).<br />
                <a name="note2" href="#2">2.</a> Brunswick Candlemas Fair, 1698.<br />
                <a name="note3" href="#3">3.</a> Jonah 4.6.
        <br /><br /><br />
                &#169; Lloyd Strickland 2014<br />
        With gratitude to John Thorley for advice and suggestions
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
