import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/placcius1678.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series II, volume 1</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		p 593<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 4/14 February 1678<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (72k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Vincent Placcius (4/14 February 1678)</title>
            <meta name="description" content="An English translation of a LETTER TO VINCENT PLACCIUS by Gottfried Wilhelm Leibniz, from 4/14 February 1678" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO VINCENT PLACCIUS</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A II 1, p593</b>]<br /><br />
		        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;From my letter to Placcius.<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Although Spinoza has fine thoughts here and there, ones not differing in the main from those I have demonstrated, nevertheless his most important opinions are those that can be proved the least, and are not demonstrated irrespective of what he actually promises. The only substance, according to him, is God. He thinks that creatures are modes or affections of God, that God lacks intellect and will, and that he does not act on account of an end, but according to the necessity of his own nature, just as the properties of a circle follow from its essence. Our mind will survive after death at least, but it will no longer perceive the present, or will the future, or remember the past. Leaving aside the fine ways he dresses it up, the happiness of all men, according to him, comes down to this - that when thinking about the inevitable necessity of things, we should be content with those that happen. How much better or truer for Christians? He does indeed say many excellent things about affections, but when he makes the word superfluous, and destroys providence and future life, I do not know whether men can be persuaded of any duties of life. And yet, I don't deny that some have cultivated virtue even without these things, like a number of Stoics, whose probity is to be lauded all the more since they are not enticed by rewards and punishments other than those that an honourable or dishonourable life brings with it. But I prefer to await your judgement on all these things and the work as a whole.


                <br /><br /><br />
                &#169; Lloyd Strickland 2007, 2019
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
