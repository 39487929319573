import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/noble.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>Leibniz</var><br />
		Jean Baruzi (ed)<br />
		pp 365-368<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: after 1690?<br /><br />
        Translated from the French<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (188k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Discourse on Noble Sentiments</title>
            <meta name="description" content="An English translation of DISCOURSE ON NOBLE SENTIMENTS by Gottfried Wilhelm Leibniz, from after 1690?" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: DISCOURSE ON NOBLE SENTIMENTS</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>B 365</b>]<br /><br />
                <center>Discourse on noble sentiments</center>
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Good sentiments</var> are those which tend to the good, or to virtue. And the <var>soul</var> in which these sentiments dominate <var>is good</var>.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Great sentiments</var> are those which incline one to do something great. And <var>the soul</var> is <var>great</var> when it is filled with them.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Noble sentiments</var> are those which are both good and great. And <var>the soul is noble</var> when it is good and great at the same time.<br /><br />

                [<b>B 366</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;There is something great even in evil, and there are great souls even among villains. And Machiavelli has remarked that the reason for the scarcity of great actions is that there are few very good or very wicked men.<a name="1" href="#note1"><sup>1</sup></a> That is, because there are few great souls.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Evil is only a privation, just as darkness is the privation of light. A certain prince was called great by his flatterers when he had lost a good part of his estates. But as is said in satire, he was great as a hole is great: the more one removes from it, the greater it becomes. It is in this way that evil is great.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;As evil can have greatness, good may likewise have modestness, or mediocrity. This is what most often happens, because I have already said that the great is rare throughout. But no matter how small the good may be, it will be sufficient provided it is proportionate to our talents and our strength. There are two ways of estimating things; that which is small absolutely becomes considerable in comparison. And even if one has a soul limited to minor things, one is very praiseworthy for it when one fulfils one's duty with it.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Talents are our internal strength, but what is commonly called our strength is the talent for external things whose administration God has confirmed to us.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;One can have a great soul when one's internal strength is great, even if the external goods do not correspond.<a name="2" href="#note2"><sup>2</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Internal strength, or strength of the soul, is of [<b>B 367</b>] two kinds: natural and acquired. Nature forms us, art completes us.<a name="3" href="#note3"><sup>3</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Those who, in the education of children, leave everything to nature, do not consider nature sufficiently. Let them go and consult hunters, riding-masters, and those who take care of horses, dogs, and birds; let them go and see gardeners at work, pruning and straightening trees.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It is true that it is not in our power to increase the strength nature has afforded. And yet art can give us strength that nature denied us.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;How to reconcile two truths that are so opposed? The fact is that art reunites and makes use of the strength which nature had dissipated and diverted. By joining several small streams into a canal, enough water is collected to make mills operate or ships sail. A concave mirror burns by collecting the sun's rays, which were scattered by the air.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It is the same with the soul's strength. We have naturally undisciplined minds, and from our childhood we are diverted by a thousand trifles which divide our attention. Art merely reunites and directs our thoughts.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;See that child as he pursues the first object. A frog or a butterfly attract him.
                We ourselves resemble a butterfly, which flutters around the fire as long as it burns. The majority of men are children their whole lives; they like to pursue trifles.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We should have only one magnet that [<b>B 368</b>] attracts us and gives us direction, which is true happiness. But we have innumerable, which make us inconstant.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Amusements divert us from the true path, and make us lose valuable time, just as they did to that Atalanta of the fable, who gathered golden apples thrown to stop her.<a name="4" href="#note4"><sup>4</sup></a> That cost her all her happiness.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We see serious people resemble Aesop's cat. Jupiter changed a cat into a girl at the request of a young man who desperately loved the cat and went on to marry the girl. She was dressed magnificently on her wedding day and kept her countenance, as far as it was possible. But a mouse appeared by chance. This object took away all her solemnity. Clothes, food, everything was thrown, overturned, trampled, to run after the mouse.<a name="5" href="#note5"><sup>5</sup></a> This is the image of men who do not have enough strength of mind. The slightest distraction makes them neglect the most important affairs.
                        <br /><br /><br />
                        <hr className='rightcol-hr' />
                        <br /><br />
                        NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> Niccol&#242; Machiavelli (1469-1527), diplomat, philosopher, and man of letters. Leibniz is thinking of Machiavelli's posthumously-published <var>Discorsi sopra la prima deca di Tito Livio</var> (Rome, 1531), I.27: &quot;Men very rarely know how to be entirely good or entirely bad.&quot; English edition: Niccol&#242; Machiavelli, <var>Discourses on Livy</var>, ed. and trans. Julia Conaway Bondanella and Peter Bondanella (Oxford: Oxford University Press, 1997), 81.<br />
                        <a name="note2" href="#2">2.</a> The as-yet unpublished transcription of this text in A VI 5 includes an extra line here: &quot;By giving a few pennies, that widow praised by Jesus Christ gave a great part of her property; she had generosity.&quot; See Mark 12.41-44; Luke 21.1-4.<br />
                        <a name="note3" href="#3">3.</a> &quot;art&quot; in the sense of &quot;craft&quot; or &quot;skill.&quot;<br />
                        <a name="note4" href="#4">4.</a> See Suzanne I. Barchers, <var>From Atalanta to Zeus: Readers Theatre from Greek Mythology</var> (Westport, CT: Teacher Ideas Press, 2001), 17.<br />
                        <a name="note5" href="#5">5.</a> Leibniz is thinking here of the fable &quot;The Cat-Maiden&quot;: &quot;The gods were once disputing whether it was possible for a living being to change its nature. Jupiter said &quot;Yes,&quot; but Venus said &quot;No.&quot; So, to try the question, Jupiter turned a Cat into a Maiden, and gave her to a young man for a wife. The wedding was duly performed and the young couple sat down to the wedding-feast. &quot;See,&quot; said Jupiter, to Venus, &quot;how becomingly she behaves. Who could tell that yesterday she was but a Cat? Surely her nature is changed?&quot;<br />
                &quot;Wait a minute,&quot; replied Venus, and let loose a mouse into the room. No sooner did the bride see this than she jumped up from her seat and tried to pounce upon the mouse. &quot;Ah, you see,&quot; said Venus, &quot;Nature will out.&quot;&quot; <var>The Big Book of Aesop's Fables</var>, ed. M. Scott Buck (Commerce Township, MI: Comlan, 2009).
                <br /><br /><br />
                        &#169; Lloyd Strickland 2018
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
