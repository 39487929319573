import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/elevens.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
		Shelfmark LBr 355 Bl. 5r<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 14 July 1703 (?)<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (168k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Casting out Elevens</title>
            <meta name="description" content="An English translation of CASTING OUT ELEVENS by Gottfried Wilhelm Leibniz, from 14 July 1703 (?)" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: CASTING OUT ELEVENS</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 <small>At the end of a letter to Edmond Halley of 14 July 1703, Leibniz wrote: &quot;I am here sending my casting of elevens.&quot;<a name="1" href="#note1"><sup>1</sup></a> The following text is Leibniz’s surviving draft of the text he enclosed with that letter.</small><br /><br /><br />
		        [<b>LBr 355 Bl. 5r</b>]<br /><br />
                <center>Casting out elevens<br /><br />
                If this is combined with [casting out] nines, it is more difficult for an error to creep in.</center><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It differs from [casting out] nines only in the fact that the latter is done by single digits, while [casting out] elevens is done by pairs of digits counting from right to left. The eleven remainder of each pair is obtained if the left digit is subtracted from the right, increased by 11 if need be.<a name="2" href="#note2"><sup>2</sup></a> Let the remainder of a pair be added to the next pair, and proceed in the same way to the end.<a name="3" href="#note3"><sup>3</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Example: 36945281 has an eleven remainder of 10. For subtracting 8 from 1 + 11 leaves 4. And 5 from 2 + 4 leaves 1. And 9 from 4 + 1 + 11 leaves 7, and 3 from 6 + 7 leaves 10.<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Or even better:<br /><br />

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Casting out nines differs from casting out elevens because in the former the digits are joined together as you like, [while] in the latter the digits of the odd positions, counting from right to left, are joined together, and the positions of the even digits are also joined together; from the remainder of the odd [positions], increased by 11 if need be, the remainder of the even [positions] is increased.
                Example: 36945281 has an elevens remainder of 10. For by casting 11 from 1 + 2 + 4 + 6 there remains 2, and by casting 11 from 8 + 5 + 9 + 3 there remains 3, and by subtracting 3 from 2 + 11, there remains 10. Therefore, the operation can be performed mentally without calculation.

                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> <var>Correspondence and Papers of Edmond Halley</var>, ed. Eugene Fairfield MacPike (London: Taylor and Francis, 1937), 200.<br />
                <a name="note2" href="#2">2.</a> That is, increased by 11 if the value of the left digit is greater than that of the right digit.<br />
                <a name="note3" href="#3">3.</a> end. │ Many other short-cuts will naturally emerge, but this is the most common and regular way. │ <var>deleted</var>.<br />
                <br /><br /><br />

                <br /><br /><br />
                &#169; Lloyd Strickland 2022
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
