import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/gobien1705.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series I, volume 25</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 43-44<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 17 August 1705<br /><br />
		Translated from the French<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (187k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Charles le Gobien (17 August 1705)</title>
            <meta name="description" content="An English translation of a LETTER TO CHARLES LE GOBIEN by Gottfried Wilhelm Leibniz, from 17 August 1705" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO CHARLES LE GOBIEN</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A I 25, 43</b>]<br /><br />
		        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;My Reverend Father<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I have no doubt that you will continue in your zeal for the Chinese Missions. I'm glad their cause took a better turn in Rome than it did in Paris.<a name="1" href="#note1"><sup>1</sup></a> For ultimately, it seemed to me there was a bit too much haste in the desire to condemn a nation, and even the ancestors of a nation, without understanding them. And I do not see why one could not say of the Chinese what several irreproachable theologians said of Job and others who lived before the law.<a name="2" href="#note2"><sup>2</sup></a> God desired that the Chinese themselves could be persuaded that their ancestors were as wise as is thought. In fact, the correspondence between the ancient characters of Fuxi<a name="3" href="#note3"><sup>3</sup></a> with the binary arithmetic I proposed indicates a great deal of solidity in these ancients.<a name="4" href="#note4"><sup>4</sup></a> And I hope that this discovery, by clarifying a great article of the faith, which is the origin of all things from God and nothing, through the analogy of the expression of all numbers by 1 and 0, will even make some impression on the Emperor<a name="5" href="#note5"><sup>5</sup></a> and on the wise men of that country. Thus it would be important [<b>A I 25, 44</b>] for the propagation of the faith and in the interest of Europe (so that Europe benefits in its turn from the knowledge of the Chinese just as they profit from ours) that the Chinese sciences and knowledge are inquired into more deeply, namely their characters, their history, both civil and literary, together with a fair appraisal, their ancient astronomy especially (which serves to better establish history and origins), their mechanics also and their manufacturing, and the knowledge of their medicinal herbs and other points of physics.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The great interest that religion and Europe have in this research makes me wish that a select number of able missionaries versed in history, languages, mathematics and physics may be devoted mainly to that, without being too distracted by pastoral concerns and by diaconical affairs and occupations. For several years I have been writing to you, my Reverend Father, and to Reverend Father Verjus, and to the missionaries themselves, to urge them to think about this important point, but the little progress that has been made so far to gain us entry into Chinese erudition makes me think it would be necessary to take more effective measures, since I am afraid that if we should lose favourable opportunities, they would not come back so easily.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It is not enough to have many churches in China. God forbid, but all that can be lost in a moment if the favour of the court changes one day under some other prince who prefers the nation [to be] hostile to foreigners. So we have to think about getting what cannot be taken from us any more, that is, to draw from the Chinese their most important knowledge, which is neither minor nor small in quantity. It will even make our people more necessary, and put them in a position to act more productively with these peoples.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I beg you, my Reverend Father, to share your thoughts on this with me, as well as those of Reverend Father Verjus,<a name="6" href="#note6"><sup>6</sup></a> and to hold on to the enclosed I am writing for Reverend Father Bouvet,<a name="7" href="#note7"><sup>7</sup></a> like those I have intended for Reverend Fathers Fontaney and Jartoux, which I sent to Reverend Father de La Chaise. Besides, I am sincerely
                        <br /><br /><br />
                        <hr className='rightcol-hr' />
                        <br /><br />
                        NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> Leibniz is referring here to the rites controversy, which concerned the religiosity (or otherwise) of Confucianism and Chinese rituals. While many Jesuits had held that the rites carried out by the Chinese to their ancestors, and in some cases to Confucius, were essentially civil and secular rather than religious, others argued otherwise, such as the theological faculty of the Sorbonne in Paris. Pope Clement XI eventually banned the rites in 1704. Leibniz sided with the Jesuits on the rites controversy; see his &quot;On the civil cult of Confucius&quot; (1700) in G. W. Leibniz, <var>Writings on China</var>, ed. and trans. Daniel J. Cook and Henry Rosemont Jr. (Chicago: Open Court, 1994), 61-66.<br />
                        <a name="note2" href="#2">2.</a> The Akademie editors note that this refers to the view that even those who did not belong to the people of God and lived in ignorance of the old covenant can still be justified.<br />
                        <a name="note3" href="#3">3.</a> In Chinese myth, the first Emperor of China, said to have lived in the 3rd millennium B.C.E.<br />
                        <a name="note4" href="#4">4.</a> Following an exchange of letters with Joachim Bouvet (1656-1730), a French Jesuit missionary who spent most of his adult life in China, Leibniz came to believe that his discovery of the binary system was in fact a rediscovery, and that it had originally been discovered by Fuxi many thousands of years before. Leibniz initially informed Bouvet of his binary system in a letter of 15 February 1701; see A I 19, 401-415; English translation: Lloyd Strickland and Harry Lewis, <var>Leibniz on Binary: The Invention of Computer Arithmetic</var> (Cambridge, Mass.: MIT Press, 2022), 126-134. In his reply of 4 November 1701, Bouvet drew Leibniz's attention to the similarity between the binary system and the &quot;system of small lines&quot; found in the Yijing, traditionally ascribed to Fuxi; see A I 20, 533-555; English translation: Lloyd Strickland and Harry Lewis, <var>Leibniz on Binary: The Invention of Computer Arithmetic</var> (Cambridge, Mass.: MIT Press, 2022), 163-175. Leibniz received Bouvet's letter only in April 1703, and was so excited by its contents that within a week he had written a paper for the <var>Memoires de l'Academie Royale des Sciences</var>, making public the binary system; the second half of the paper repeated and developed Bouvet's claim that Fuxi had anticipated Leibniz's discovery. See G. W. Leibniz, &quot;Explication de l'arithm&#233;tique binaire, qui se sert des seuls caracteres 0 &amp; 1; avec des remarques sur son utilit&#233;, &amp; sur ce qu'elle donne le sens des anciens figues Chinoises de Fohy,&quot; <var>Memoires de l'Academie Royale des Sciences</var> (1703), 85-89. English translation available <Link to="/binary">here</Link>.<br />
                        <a name="note5" href="#5">5.</a> At the time the Emperor was Kangxi (1656-1722).<br />
                        <a name="note6" href="#6">6.</a> See Leibniz's letter to Verjus of 17 August 1705 in <var>Leibniz on God and Religion</var>, ed. and trans. Lloyd Strickland (London: Bloomsbury, 2016), 334-337.<br />
                        <a name="note7" href="#7">7.</a> Leibniz wrote to Bouvet on 18 August 1705; see A I 25, 48-51. English translation available <a href="https://leibniz-bouvet.swarthmore.edu/letters/letter-m-18-august-1705-leibniz-to-bouvet/" target="_blank">here</a>.
                <br /><br /><br />
                        &#169; Lloyd Strickland 2020
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
