import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/more.pdf'; 
import { Helmet } from 'react-helmet';

function More() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>Textes in&#233;dits tome 2</var><br />
		Gaston Grua (ed)<br />
		pp 570-571<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: spring - summer 1676?<br /><br />
		Note - The italicised text within + ... + is Leibniz's own comments on the material.<br /><br />
		Translated from the Latin<br /><br />{isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (34k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Notes On Henry More's "Enchiridion Ethicum"</title>
            <meta name="description" content="An English translation of NOTES ON HENRY MORE'S 'ENCHIRIDION ETHICUM' by Gottfried Wilhelm Leibniz, from spring - summer 1676" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: NOTES ON HENRY MORE'S <var>ENCHIRIDION ETHICUM</var><a name="asterisk" href="#star"><sup>*</sup></a></h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                [<b>Gr p570</b>]<br /><br />		
                <center><var>ENCHIRIDION ETHICUM</var>, London 1668.</center><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Book I, chapter 1. What ethics is. <var>Ethics</var> is the art of living well and happily.<a name="1" href="#note1"><sup>1</sup></a> One is able to live well even when one does not live happily, since the success of one's actions may depend on nature.<a name="2" href="#note2"><sup>2</sup></a> But Aristotle rightly observed that external goods are also required for happiness.<a name="3" href="#note3"><sup>3</sup></a> But More himself then defines happiness as pleasure which the mind perceives from the sense of virtue and the knowledge of things rightly done.<a name="4" href="#note4"><sup>4</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Chapter 4. <var>The intellectual principles</var>, into which every reason of morality is generally resolved.<a name="5" href="#note5"><sup>5</sup></a> Good is that which is pleasant in the perception of life with the preservation of the percipient.<a name="6" href="#note6"><sup>6</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Book II. <var>On the virtues</var>.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Book II chapter 9 articles 14-18 presents the following notable points: '...No greater good in the whole of nature can be discovered than that love which we call the intellectual.<a name="7" href="#note7"><sup>7</sup></a> (<var>Nicomachean Ethics</var> X, 7 and 10)... There is no perturbation of the mind in this love, just the greatest serenity, and a state which is like that of the immortal gods, i.e. of the blessed spirits.'<a name="8" href="#note8"><sup>8</sup></a> He uses this phrase and explanation several times.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Book III, chapter 1. <var>On free will</var>, &#167;2. He rightly says that there are people who think that much would be detracted from the perfection of human nature if anyone [<b>Gr p571</b>] were good by nature or by some divine fate, because he would be good by necessity and deprived of free will. Just as God would certainly be less adorable if he cannot be bad.<a name="9" href="#note9"><sup>9</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Chapter 2. The objections against free will are refuted. There are two objections, one from divine prescience, the other from the nature of the good.<a name="10" href="#note10"><sup>10</sup></a> To the first he answers in such a way that I do not grasp his response.<a name="11" href="#note11"><sup>11</sup></a> In the second, all sense of difficulty is resolved in this verse from the <var>Nicomachean Ethics</var>: nobody is willingly evil.<a name="12" href="#note12"><sup>12</sup></a> Those in whom there is this foretaste of the true and highest good, have the power, it seems, to acquire a fuller acquaintance with it, or gradually to allow it to be extinguished.<a name="13" href="#note13"><sup>13</sup></a> But those in whom every sense which distinguishes the honest and vile is extinguished, and in whom alone the life of the animal flourishes...in these people I concede the will is always determined towards what appears to be the greater good, and they enjoy no greater liberty than beasts themselves... (&#167;8)<a name="14" href="#note14"><sup>14</sup></a> + <var> Therefore these men are punished unjustly, and if anyone has extinguished that sense of the greatest good in them, the actions occurring afterwards will be all excusable.</var> Absurd! +<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;There are two principles in us, divine and animal. One might say that the former is simply best, the latter dictates what is pleasant, useful and good for man himself...<a name="15" href="#note15"><sup>15</sup></a> Men often observe what is absolutely better in themselves, nevertheless they do not incline their will in that direction... regarding what they can and should do, they are themselves already conscious, but they prefer to experience joys. By all these points they declare the freedom of the human will, and so it is not necessary for the human will to be brought always to the greater good. (&#167; 9-12).<a name="16" href="#note16"><sup>16</sup></a> + <var>More correctly, to what it perceives to be the greatest good</var>. +<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Chapters 3-8. <var>On acquiring virtues</var>. Chapter 9. <var>On the acquisition of external goods</var>. Quick summary.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Chapter 10. <var>On that good which is external, supreme and eternal, according to the mind of philosophers</var>. Very clear in More's last chapter. (&#167; 14-20) If you are without patience, you may know with certainty that you are always carrying around a hidden betrayer either of your prince, of your country, of your religion, or of all these.<a name="17" href="#note17"><sup>17</sup></a>
                        <br /><br /><br />
                        <hr className='rightcol-hr' />
                        <br /><br />
                        NOTES:<br /><br />
                        <a name="star" href="#asterisk">*</a> <var>Manual of Ethics</var>.<br />
                        <a name="note1" href="#1">1.</a> '<var>What Ethicks or Morals are</var>. Ethicks <var>are defined to be the Art of Living well and happily</var>.' Henry More, <var>Enchiridion Ethicum</var>, trans. Edward Southwell (New York, The Facsimie Text Society, 1930) Book 1 Chapter 1 &#167;1 (p1).<br />
                        <a name="note2" href="#2">2.</a> 'The Reason why in the Definition above we call it, <var>The Art of Living both well and happily</var>, is, because a Man may live <var>well</var>, and yet not altogether so <var>happily</var>; which two differing kinds of Life the <var>Pythagoreans</var> did rightly distinguish; for by their Doctrin, it is one thing to be <var>perfect according to Nature</var>, another <var>according to Life</var>.' More, <var>Enchiridion Ethicum</var>, Book 1 Chapter 1 &#167;1 (pp1-2).<br />
                <a name="note3" href="#3">3.</a> 'We might add unto all, the Authority of <var>Aristotle</var> himself, who requires <var>external Goods</var> to the completing of Happiness. Now altho the good Things of Fortune, which we here recommend, cannot absolutely be said to be within our Power; yet we presume to say, that forasmuch as the Precepts, laid down by <var>Ethicks</var>, do admirably steer a man to their acquisition (as in due place it will appear) we must conclude, that such Externals are by good title referrable to <var>Ethicks</var>.' More, <var>Enchiridion Ethicum</var>, Book 1 Chapter 1 &#167;3 (p3).<br />
                <a name="note4" href="#4">4.</a> 'HAPPINESS <var>is that pleasure which the mind takes in from a Sense of Virtue, and a Conscience of Well-Doing</var>.' More, <var>Enchiridion Ethicum</var>, Book 1 Chapter 1 &#167;2 (p4).<br />
                <a name="note5" href="#5">5.</a> This is more or less the title of Book 1 Chapter IV: '<var>Certain Axioms or Intellectual Principles; into which almost all of the Reasons of Morality may be reduced</var>.' (p20)<br />
                <a name="note6" href="#6">6.</a> '<var>Good is that which is grateful, pleasant, and congruous to any Being, which hath Life and Perception, or that contributes in any degree to the preservation of it</var>.' More, <var>Enchiridion Ethicum</var>, Book 1 Chapter 4 Noema 1 (p21).<br />
                <a name="note7" href="#7">7.</a> 'there cannot, in the whole compass of Nature, be found a greater Good than is that <var>Love</var>, which (to free it from all the other Imputations) we call <var>Intellectual</var>.' More, <var>Enchiridion Ethicum</var>, Book II Chapter 9 &#167;14 (p156).<br />
                <a name="note8" href="#8">8.</a> 'Yet when all is said, perhaps this Love, which we insist upon, may not so truly be termed a Passion, as acknowledg'd to be the Peace and Tranquility of the Mind: nay a state of such Serenity, as hath no other Motions than those of Benignity and Beneficience. So that this Love may rather be thought a firm and unshaken Benignity, or Bounty of the Soul; such as has nothing more perfect, or more approaching to the immortal Gods. I mean hereby that State of the Blessed Spirits, unto which we ought all to aspire.' More, <var>Enchiridion Ethicum</var>, Book II Chapter 9 &#167;18 (p159). More quotes Aristotle's <var>Nicomachean Ethics</var> in Book II Chapter 9 &#167;18 (p159): '<var>That according to some Doctors we are not to converse with human things, altho we are Men, nor with things transitory, altho we are mere Mortals; but, as much as is possible, we should affect to live as do the immortal Gods: And this, as performing every thing in such sort, as conforms to that Principle, which is the most excellent thing within us</var>,' (N.E. X.7) and '<var>This most excellent thing within us, to be the Intellect</var>.' (N.E. X.9)<br />
                <a name="note9" href="#9">9.</a> '<var>Aristotle</var> has sometimes propos'd a Famous Question (but <var>Plato</var> in his <var>Menon</var> handles it more largely) and it hath affinity with this our Subject of <var>Free-Will</var>; as namely, <var>Whether Virtue gets into Men by Custom, or by Nature, or by some Divine Fate</var> (which is the same as <var>Good Fortune</var>?) There are some Men extremely scandaliz'd at the Affirmative Part of this Question; as thinking it a derogation from Humane Nature, to make Men at this rate necessarily Good, and to deprive them of all <var>Free-Will</var>. For they judg a Thing voluntarily done, to be of far different Merit from what happens by Compulsion: Which yet (I confess) sounds to me; as if God, who is Good, should be less Adorable, because he cannot be Naught. For I will presume that whoever is Good, either by Nature or the Divine Fate, is also endowed with so true and efficacious a Sense of Honesty, that he can no more go against this Sense, than that a sober Man should stab himself with a Dagger.' More, <var>Enchiridion Ethicum</var>, Book III Chapter 1 &#167;2 (p173).<br />
                <a name="note10" href="#10">10.</a> 'The first Objection ariseth from God's Fore-knowledg; which (they say) must take away all Contingency, and in Consequence, the Liberty of Man's Will. The second is taken from the Nature of Good, altho but Apparent: For as often or as long as  any thing seems Good or Excellent to any one in the Circumstances he then is in, his Will is necessarily compell'd to embrace it, because there is no Motive either to divert him, or suspend his Assent.' More, <var>Enchiridion Ethicum</var>, Book III Chapter 2 &#167;1 (pp181-2).<br />
                <a name="note11" href="#11">11.</a> 'As to the first of these Objections, the Answer is not hard. 'Tis true, we cannot otherwise think of God's Fore-knowledg, but to be every way clear and perfect, and without possibility of Error, as to those Objects about which he judges or does pronounce. And surely he does always judg and determine of things according as they are; that is to say, of a contingent thing, that it is contingent; and of a necessary thing that it is necessary. Whence it comes to pass, that those things, which are contingent and proceed from a Free Principle of Acting, they are allow'd to be such by God's Consent. For we ought not to confine God's Omniscience within narrower Bounds than we do his Omnipotence, which all Men acknowledg to be able to do whatever does not imply a Contradiction. And therefore, to dispatch this Difficulty in a few words: We say that the Fore-knowledg of contingent Effects, which proceed from a free Principle of Acting, does either imply a Contradiction, or it does not. If it does imply a Contradiction, then such Effects are not the Objects of God's Omniscience, nor determin'd by it, or rightly suppos'd to be determin'd at all. But if it do not imply a Contradiction, then we actually confess, that Divine Prescience and Man's Free-Will, are not inconsistent, but that both of them may fitly stand with each other. Therefore by neither way, can any sound or convincing Argument be drawn from God's Fore-knowledg against the Liberty of Man's Will.' More, <var>Enchiridion Ethicum</var>, Book III Chapter 2 &#167;2 (pp182-3).<br />
                <a name="note12" href="#12">12.</a> 'As to the other Difficulty, the whole Sense thereof falls within this Proverbial Saying, <var>Nemo est lubens Malus, aut Beatus invitus</var>; that is, No Man is willingly Wicked, or Happy against his Will.' More, <var>Enchiridion Ethicum</var>, Book III Chapter 2 &#167;3 (pp183-4).<br />
                <a name="note13" href="#13">13.</a> 'However, as to those, who are so endow'd as to have some Native Foretast of this high and Excellent Good; it seems to be plac'd within their Power, either to acquire to themselves a clearer and more extended Knowledg therein, or else to let that by degrees extinguish which already they have.' More, <var>Enchiridion Ethicum</var>, Book III Chapter 2 &#167;7 (p186).<br />
                <a name="note14" href="#14">14.</a> 'As for those Men, who throw off all Distinction of Things Honest and Vile; who have no other Sense than of the <var>Animal Life</var>; who consider only for themselves, be it Right or Wrong; who think that <var>Good</var> is but of one Sort, and this only referable to Animal Content (or if, perchance, they think Good to be various, yet still they fix and appropriate all to themselves;) In such Men as these, I do confess, their Will is perpetually determin'd to what is the most apparent Good. They enjoy no more Liberty than Brutes, whose Appetite is necessarily ty'd down to the greater Good.' More, <var>Enchiridion Ethicum</var>, Book III Chapter 2 &#167;8 (p187).<br />
                <a name="note15" href="#15">15.</a> 'But when we consider, how there is a double Principle in the greatest Part of Mankind; the one <var>Divine</var>, and the other <var>Animal</var>. How that the Voice and Dictate of the <var>Divine Principle</var>, is ever for that which is simply and absolutely the Best... When also on the other side, we consider that the <var>Animal</var> Principle dictates nothing to Man, but what to himself is either good, pleasing or advantageous.' More, <var>Enchiridion Ethicum</var>, Book III Chapter 2 &#167;9 (pp187-8).<br />
                <a name="note16" href="#16">16.</a> 'This is a thing, which all Men have experience of, that at some times, and even then when we behold clearly what were best and most consonant to the Divine Law; yet we do not excite our Minds to it; or put on that Courage, which we know we have, to pursue so fair and so fit an Object; but yield and go on where-ever the Stream of Pleasure, or of our own Utility, will carry us... In the mean time, while such Men as these do still go on, and still delude themselves with Apologies for their Sloth and Immorality (as either trusting to the Divine Goodness for Pardon, or else putting off their Amendments to a further Day) 'tis manifest, that altho they do persist to satisfie their ill Desires, and postpone their Repentance to future time: yet are they convinc'd, it were far better, if already done; and that 'tis equally now, as well as hereafter, within their Power to do it. And this is enough to shew, how plainly, even these confess the <var>Liberty of Man's Will</var>. And thus is it made evident, that 'tis not necessary, that Man's Will should still be carried on to the greater (that is, to the more excellent) Good. For it may, according to the Liberty it hath, desert what is absolutely the best; and either close with what is most grateful to the Animal Life, or suffer it self to be captivat'd by it, for want of exerting the Power and Faculties it hath.' More, <var>Enchiridion Ethicum</var>, Book III Chapter 2 &#167;&#167;10-12 (pp188-9).<br />
                <a name="note17" href="#17">17.</a> 'if you want the Armor of <var>Patience</var> against all Tribulations and Temptations whatever that may happen, you then carry in your Bosom that Serpent <var>Cowardice</var>, which will urge you to betray your Prince, your Country, your Friends, your Religion, and even all together.' More, <var>Enchiridion Ethicum</var>, Book III Chapter 10 &#167;14 (p264).

                <br /><br />
                        &#169; Lloyd Strickland 2004<br />
                        With gratitude to John Thorley for advice and suggestions
                        <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default More;
