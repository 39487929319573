import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/horneius.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
		Shelfmark LBr 146 Bl. 2<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: after 6 April 1693<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (144k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>On Horneius' Disputationum theologicarum</title>
            <meta name="description" content="A transcription of ON HORNEIUS' 'DISPUTATIONUM THEOLOGICARUM' by GOTTFRIED WILHELM LEIBNIZ, from after 6 April 1693" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: ON HORNEIUS' <var>DISPUTATIONUM THEOLOGICARUM</var></h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A IV 5, 491</b>]<br /><br />		

                Horneius in <var>Disputationum theologicarum</var>,<a name="1" href="#note1"><sup>1</sup></a> part 2, disputation 2 (about predestination), section 1, number 20: &quot;To gentiles,&quot; he says, &quot;who have never heard anything about Christ will nonetheless be given sufficient grace, by which they can be saved if they desire. This is proved first through those common principles mentioned before, and second, from chapters 1 and 2 of the letter to the Romans, where Paul expressly teaches that heathens are inexcusable and damnable not because God has cast them aside by some absolute decree and ... denied them knowledge of him, but because although they knew God they have not glorified him as God, and have exchanged ... the natural law inscribed in them for a falsehood.<a name="2" href="#note2"><sup>2</sup></a> Therefore, if they had glorified God as God, and it had pleased them to keep acknowledging him, then he would doubtless never stop inciting them to that by a secret inspiration, and he would have led them, in accordance with his own benevolence, even to the knowledge of his own Word, through which they would have been able to believe and be converted.&quot;<a name="3" href="#note3"><sup>3</sup></a> This was what he said.
                    <br /><br /><br />
                        <hr className='rightcol-hr' />
                        <br /><br />NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> That is, Conrad Horneius, <var>Disputationum theologicarum pars secunda, qua doctrina de incarnatione filii dei, et quae per eum facta est generis humani reparatione, explicatur</var> (Helmstadt: Muller, 1644).<br />
                        <a name="note2" href="#2">2.</a> See Romans 1.21 and 1.25.<br />
                        <a name="note3" href="#3">3.</a> Horneius, <var>Disputationum theologicarum pars secunda</var>, 208-209.<br /><br /><br />
                        &#169; Lloyd Strickland 2018
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
