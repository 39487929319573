import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/beatitude.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>Textes in&#233;dits tome 1</var><br />
		Gaston Grua (ed)<br />
		pp 96-98<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 1694-1696?<br /><br />
		Note - The italicised text within + ... + is Leibniz's own comments on the material.<br /><br />
		Translated from the Latin and French<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (14k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>On man, beatitude, God and Christ</title>
            <meta name="description" content="An English translation of ON MAN, BEATITUDE, GOD AND CHRIST by Gottfried Wilhelm Leibniz, from 1694-1696?" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: ON MAN, BEATITUDE, GOD AND CHRIST</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>Gr p96</b>]<br /><br />
		<ol>
		<li>In man there is spirit, soul and body: or mind, reason, flesh or sense. Mind considers higher things, reason middle things, the senses inferior things. There are three worlds, divine, angelic (spiritual), sensible. The superior world contains the inferior eminently. The inferior is a shadow of the superior.<a name="1" href="#note1"><sup>1</sup></a> God contains everything in one, and to this alone everything tends; this is the highest good of all. Good is internal and external, the former is true good, the latter is unworthy of the name 'good'. The internal good flows from us, the external kind can be said to be fortune (in the general meaning), and is not in our power.</li>
		<li>Particular external goods (of the body, of the character) are from fortune, and<a name="2" href="#note2"><sup>2</sup></a> are acquired from nature, or study, or chance; yet all things can be called accidents in a certain sense, and they come from heaven (i.e. the universal course of bodies), and as they are given by chance so they are taken away by it.</li>
		<li>Terrestrial man is from this world, or mud; whence he depends on the heavens or fortune; while men are eager for good fortune, they make themselves slaves of the stars, as do beasts. Fortune harasses slaves with its own motion, just as bundles of hay are hurled by threshing. But the immortal part of man must dominate by astral fate or the impressions of the body. Wisdom is greater than fate (those who reflect on their immortal part, also recognize angels as their brothers, God as their father, and the body as their slave). For the better part of us is created as something angelic, according to the image of God. Those who exercise themselves by contemplating these things, and dwell on eternal matters by leading the soul away from the senses, are released from corporeal contagion, and dominate the stars. Beneath their feet they see the clouds and stars of heaven.</li>
		<li>Sadness and joy are wrongfully linked to fortune.</li>
		<li>It only lends its gifts; it is wrong to hold onto them.</li>
		<li>Everything seeks the supreme good, in various ways.</li>
		<li>Wealth is misleading or fleeting, and not honourable.</li>
		<li>Honour is fortuitous and it deceives.<br /><br /></li>
		[<b>Gr p97</b>]<br /><br />
		<li>Posthumous glory limited.</li>
		<li>Pleasures, beauty, fleeting. No happiness on Earth.</li>
		<li>The earthly goods compete with each other. Only God reunites all the goods.</li>
		<li>Even reunited, they are only the shadow of true goods.</li>
		<li>True goodness, wisdom, justice and happiness are essentially in God.</li>
		<li>God united. Adhering to God we become 'a first-born witness.' (Jo. 17)</li>
		<li>Men become happy 'by the attainment of divinity,'<a name="3" href="#note3"><sup>3</sup></a>  sons of God through Christ.</li>
		<li>The end of things, that is the good. In distancing ourselves, we renounce our being.</li>
		<li>Everything obeys God. Evil is nothing, it comes from privation in creatures.</li>
		<li>'Evils exist insofar as not all things attain the highest good.' Nevertheless evil itself is useful for goods.</li>
		<li>To do evil is impotence. 'Evils serve good as beasts serve men.'</li>
		<li>Amelioration by punishment.</li>
		<li>Impunity is not chance but sickness. Pity for the wicked.</li>
		<li>Every event, providential, is good for those who are good.</li>
		<li>'An ascent from the sensible to the intellectual, from creatures to God... God produced angels by saying <var>let there be light</var>. From this light came stars (or rather forms, archeai, ideas, astral forces) (+ <var>or as I should say more philosophically, the active forces of the first universe</var> +) and from these came bodies.'</li>
		<li>'God is the maximum of infinity, the minimum of indivisibility... Christ is the centre of eternal life...'</li>
		<li>Lucifer sought his happiness in himself. It is a law of nature that condemns this 'self-love' as idolatry. 'To be turned towards oneself is to strive towards nothing. About this see German theology, and Kempis, and Tauler. In the end God shines on everything, like the sun, but those blinded by themselves do not see him.'</li>
		<li>Evil possible by blindness. 'But it is not God's way to violate the supreme order of things.'</li>
		<li>'True theology in the knowledge of Adam and Christ, of ourselves and God. Of Adam, to turn back to oneself, to eat the flesh and blood of Adam. Of Christ, to abandon oneself to God, to eat the flesh and blood of the son of God.'</li>
        </ol>
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'The external Adam does no harm, but the internal Adam does; and the external Christ does not [<b>Gr p98</b>] benefit, but the internal Christ does; therefore he who assumes Christ in faith has within himself life, light, God, the highest good. (+ <var>All things are returned to one, or to God, this is to conceive the harmony of things.</var> +) ... In the darkness of creatures shines the divine light.'
		<br /><br /><br />
		<hr className='rightcol-hr' />
		<br /><br />
		NOTES:<br /><br />
		<a name="note1" href="#1">1.</a> Here Leibniz wrote and then deleted: 'An angel has in itself, spiritually, every inferior world, so that it can actually exhibit it if it wishes.'<br />
		<a name="note2" href="#2">2.</a> Reading 'atque' in place of 'aeque'.<br />
		<a name="note3" href="#3">3.</a> This quotation, as well as those that follow, are from an unknown Christian Cabbalist source.<br /><br /><br />
		&#169; Lloyd Strickland 2003
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
