import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/nye.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>Textes in&#233;dits tome 1</var><br />
		Gaston Grua (ed)<br />
		pp 253-255<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 27 March 1705<br /><br />
		Note - The italicised text within + ... + is Leibniz's own comments on the material.<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (84k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Notes on Stephen Nye's "The System Of Grace And Free-Will"</title>
            <meta name="description" content="An English translation of NOTES AND COMMENTS ON STEPHEN NYE'S 'THE SYSTEM OF GRACE AND FREE-WILL' by Gottfried Wilhelm Leibniz, from 27 Mar. 1705" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: NOTES AND COMMENTS ON STEPHEN NYE'S <var>THE SYSTEM OF GRACE AND FREE-WILL</var></h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>Gr p253</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;When Mr Burnett of Kemney sent me this little book, I sent it back to him on the same day (27 March 1705) together with the following marginal notes:<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Page 3. The philosophers who make all our actions necessary allege that the understanding necessarily embraces apparent truth, and that the will necessarily chooses the apparent best. From which it follows that every error is involuntary, and every choice is necessary.<a name="1" href="#note1"><sup>1</sup></a> + <var>The present act of erring is not voluntary, but a past will often contributes to the causes of error. An action of the will does not always follow from a judgement of the understanding, for often, when we are not content with the present judgement, we take more time for investigation, and we are pleased to turn our thinking in another direction</var>. +<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Page 5. It is not true that we always choose what seems best, for sometimes we act only in order to show our freedom.<a name="2" href="#note2"><sup>2</sup></a> + <var>But then we have an impudent will that acts according to its own desire for a greater good than that which is apparent to us from elsewhere</var>. +<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Page. 6. If the will were to necessarily follow the present appearance of the good, we would never deliberate, and would always follow its first impulses.<a name="3" href="#note3"><sup>3</sup></a> + <var>Correct</var>. +<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Same page. But if one may defer a choice on one occasion, why not always?<a name="4" href="#note4"><sup>4</sup></a> + <var>I agree that a choice is never necessary. However a delay also has its own reasons, which are sometimes laid aside</var>. +<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Page 7. Not even God, although the most perfect being, and infallibly aware of the best, always chooses the best. God was able to make the world and all the creatures in it more perfect. He was able to make a more perfect world, more and better men, he was able to make all impeccable, and eliminate sin and misery from the universe.<a name="5" href="#note5"><sup>5</sup></a> + <var>This is badly put, nor in truth do I think that the whole universe could have been made better. And we should be wary lest we injure the divine goodness and wisdom by an excessive desire to uphold his freedom, that is, lest we overturn the principles of true theology.<a name="6" href="#note6"><sup>6</sup></a> If God always chooses the best, would he therefore not be free? Insistence [on this point] only proves our ignorance. Augustine's opinion is right, that God would not permit evil unless he could bring about a greater good from the evil</var>.<a name="7" href="#note7"><sup>7</sup></a> +<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Same page. Theologians are accustomed to adapt themselves to the sects of the philosophers, some of whom allow <var>to eph'h&#232;min</var>,<a name="8" href="#note8"><sup>8</sup></a> <var>to autexousion</var>,<a name="9" href="#note9"><sup>9</sup></a> [<b>Gr p254</b>] an absolute and arbitrary freedom, while others think that the irresistible grace of God is necessary.<a name="10" href="#note10"><sup>10</sup></a> + <var>Absolute freedom, which signifies a perfection, exists not in us but in God alone. However there can be no perfect indifference either in us or in God. Yet there is true spontaneity in us. It is true that we cannot [perform] spiritual good without divine grace, although the epithet 'irresistible' should be omitted. But the doctrine of supernatural divine grace is nothing to philosophers</var>. +<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Page 10. We exhort men in vain, if the opinion of inflexible people is to be observed. For they will reply to my urging that I am able to do nothing except when I am in possession of divine grace, which is not in my power to procure, and which is bestowed upon the few, namely only upon some people and at some times.<a name="11" href="#note11"><sup>11</sup></a> + <var>They certainly can do what is commanded, but with the assistance of grace. Moreover, it is rightly said that the grace for doing that which is in them is not lacking. And it is promised to all who sincerely want it</var>. +<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Page. 11. It seems God is not very sincere to declare that he wills the salvation of all, if underlying it is the condition of obedience, which cannot be fulfilled without his grace. From this it follows that God wills the death of those to whom he denies grace. And it is pointless to respond that we should pray to God to bestow grace, since the very disposition to pray proceeds from God.<a name="12" href="#note12"><sup>12</sup></a> + <var>Grace aids all those who are willing, even if all do not have the grace of willing. It is true that even a serious will requires divine grace. But this is still not sufficient, namely that nothing is wanting for him who is willing. Indeed  neither the will nor the grace of willing will be lacking for him who does what is needed in the nature of the thing to acquire the will</var>. +<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Page 12. Here is a system of grace and free will which is acquitted of all those theological and philosophical difficulties.<a name="13" href="#note13"><sup>13</sup></a> + <var>I hardly think that's true</var>. +<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Same page. <var>First</var>, we are unable to do or to will spiritual good without the incitement and help of divine grace.<a name="14" href="#note14"><sup>14</sup></a> + <var>Correct</var>. +<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Page 14. It is useful to distinguish between habitual grace, born out of many preceding acts of grace, and actual grace. The Pelagians or Semipalagians object that David wanted to build a temple, and Paul wanted to teach the faith in Bithynia, but God forbade both, and therefore this good impulse was not from God, i.e. from an influx of actual grace.<a name="15" href="#note15"><sup>15</sup></a> + <var>There is no need for this distinction at this point, as an impulse towards a good action or a good endeavour can come from God, even if God does not will it to be successful</var>. +<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Page 16. <var>Second</var>. The grace of God, sufficient for conversion and for habitually disposing the heart, is common to all, so that no one falls except by his own voluntary neglect.<a name="16" href="#note16"><sup>16</sup></a> + <var>Correct</var>. +<br /><br />

                [<b>Gr p255</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Page 20. We commonly say that men fall because of a lack of grace, but this error of vulgar speech is in need of correction. Grace was not lacking, but was neglected.<a name="17" href="#note17"><sup>17</sup></a> + <var>It has to be admitted that sufficient grace for those willing it is universal, and no one lacks it. But particular grace is sometimes lacking, such is the grace of willing</var>. +<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Same page. <var>Third</var>. Grace does not operate irresistibly, but by moral suasion.<a name="18" href="#note18"><sup>18</sup></a> + <var>Correct; we would always be able to resist if we willed to do so, but sometimes grace is such that it is certain that we will not be willing to resist</var>. +<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Page 23. <var>Four</var>. Our will, aroused by grace, can will and bring about good, and can conform to the divine intention moving us to God.<a name="19" href="#note19"><sup>19</sup></a> + <var>Then here is a particular grace besides the universal, namely the grace of willing, or at least of inclining towards willing, by which the will is aroused and vivified, as it were. For that certainly does not happen always and everywhere</var>. +<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Page 25. And this is the system free from the disadvantages which bedevil the other schemes.<a name="20" href="#note20"><sup>20</sup></a> + <var>He calls this system true, but I'm not sure whether he should even call it adequate</var>. +<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Page 29. We have never said - as the objection supposes - that God wills the salvation of all, or of any, unconditionally and absolutely, but on the condition that he uses grace.<a name="21" href="#note21"><sup>21</sup></a> + <var>The hypothetical or conditional will to save all is not sufficient. God wills simply and in earnest that all be saved and that all use grace rightly, but he does not will with the highest degree of will, that is, to speak in a human manner, he does not will with the greatest effort. Otherwise all would in fact be saved</var>. +

                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> 'The Philosophers that said, all our Actions are necessitated, offer'd for their Opinion; chiefly that, as the Understanding always, and necessarily embraceth (seeming) <var>Truth</var>; so the Will chooseth, and cannot but choose, what seemeth <var>good</var>, and <var>best</var>. In short, the Will ever followeth the present Dictate of the Understanding: what to the Understanding (for the present) seemeth <var>Truth</var>, or <var>good</var>; the Will cannot but approve, and practice. From these Maxims, it follows that, all Errors are <var>involuntary</var>, and all our Choices <var>necessary</var>.' Stephen Nye, <var>The System of Grace and Free-Will</var> (London, 1700), pp3-4.<br />
                <a name="note2" href="#2">2.</a> 'But it is not true, that we always choose what is Good or Best; for sometimes, only <var>to show that we are free</var>, and not determined fatally by the Goodness of Excellence of an Object or Choice' Nye, op. cit., pp5-6.<br />
                <a name="note3" href="#3">3.</a> 'And, if it were necessary to the Will, to choose what (for the present) appears to the Understanding to be Good, or Best; we could <var>never</var> advise, consult, or deliberate at all: for the Understanding sees at <var>first view</var>, a Goodness in some things, and in others that they are best <var>in some respects</var>.' Nye, op. cit., p6.<br />
                <a name="note4" href="#4">4.</a> 'It seems impertinent, to renew here the Exception, that, let the Consideration or Election be delayed never so long, we shall at last necessarily choose what shall seem <var>Good</var>, and <var>Best</var>: for I demand, why <var>now</var>, more than <var>before</var>? what seem'd Good and Best, did not before <var>necessarily</var> determine me, therefore neither can it <var>now</var>, merely because 'tis Good or Best.' Nye, op. cit., p6.<br />
                <a name="note5" href="#5">5.</a> 'the most perfect Being of all, GOD; who judgeth what is Good and <var>Best</var>, unerringly; doth not always choose the <var>Best</var>. He could have made the World, and every Creature in it, more perfect than they are: he could have made the World bigger, Mankind more numerous, and <var>morally</var> better: He could have made us all impeccable, as the Saints in Heaven are: He could have provided that there should neither be <var>Sin</var> nor <var>Misery</var> in the World.' Nye, op. cit., p7.<br />
                <a name="note6" href="#6">6.</a> Leibniz is here attacking a claim made on p7 of Nye's work: 'That he [sc. God] hath not chose these best things...was from the <var>Freedom</var> of his Will.'<br />
                <a name="note7" href="#7">7.</a> An allusion to Augustine, <var>Enchiridion</var>, 11.<br />
                <a name="note8" href="#8">8.</a> 'in our power' or 'in our control'.<br />
                <a name="note9" href="#9">9.</a> 'self-determination'.<br />
                <a name="note10" href="#10">10.</a> 'many Divines, and very potent Parties of Christians, have followed in the Question now before us, the sense of the particular Philosophers, or Sects of Philosophy, whom they admired. For some have contended for it, that we have a &#964;&omicron; &#949;&#966; &#951;&#965;&#953;&#957;, an &#913;&#965;&#964;&#949;&#950;&chi;&#959;&#964;&#959;&#957;; and absolute, and arbitrary Freedom of Will, to choose Evil or Good, and to refuse. While others have said, on the contrary, that all Men are under some such <var>natural</var>, or (if you will) <var>moral</var> Impotence; that we cannot choose or will Good, without the Grace of God, exciting us, and assisting us, <var>by irresistible Acts</var>.' Nye, op. cit., pp7-8.<br />
                <a name="note11" href="#11">11.</a> 'If, either we our selves do not rightly understand it [viz. grace and free will], or our People do not apprehend, what is our meaning; 'tis impossible they should make sense of what they hear in this place... But what sense do we suppose can they make of these two Sayings; and what Troubles must these Doctrines raise in the Mind; when we consider them with any heed? I must forgo every Sin, I must always do the thing that is right and good: and yet <var>I cannot do it</var>, but only by God's grace given to me; nor is <var>that</var> Grace in my Power to procure it: 'Tis the <var>free</var> Gift of God, to whomsoever he giveth it; and alas, how many have it not; or rather, how (<var>very</var>) few have it? And what follows from this? Why, that, all our Exhortations and Reproofs, are to no purpose: unless we could also (at the same time) give to the Persons reproved and exhorted <var>Grace</var>, to comply.' Nye, op. cit., pp10-11.<br />
                <a name="note12" href="#12">12.</a> 'It should seem, we can as little defend <var>the Sincerity</var> of God; in professing that, <var>He willeth not that any should perish</var>; and that <var>he willeth, that all may be saved</var>. For the condition of Salvation, being <var>Obedience</var>; and <var>that</var> Condition, not practicable but by <var>Grace</var>: it must needs be understood, that God willeth those Persons should perish, to whom he doth not give his Grace. There is no saying, in the case; let 'em <var>pray</var> for God's Grace. For all, that assert the necessity of Grace to every <var>good</var> Action, and Disposition, are obliged to maintain that; the <var>Affectus Orandi</var>, the hearty Disposition to pray for Grace or other spiritual Good, can arise only, from Grace, aforehand given to the Person so praying.' Nye, op. cit., pp11-12.<br />
                <a name="note13" href="#13">13.</a> 'The Subject we are upon will not admit of further prefacing. As I said therefore, I shall now propound a <var>Middle</var>, between the two Extremes of <var>Pelagianism</var> and <var>Jansenism</var>. The Doctrine, I take it, of the Catholick Church: a System concerning Grace, and the Human Will, which is clear of all the Difficulties, whether <var>Theological</var> or <var>Philosophical</var>, hitherto mentioned.' Nye, op. cit., p12.<br />
                <a name="note14" href="#14">14.</a> 'I. Whatsoever <var>Good</var> we will, or do, is neither done nor <var>willed</var>, without God's Grace; his Grace moving us thereto, and <var>assisting</var> us therein.' Nye, op. cit., p12.<br />
                <a name="note15" href="#15">15.</a> 'But for the right, and full apprehending this first Article; 'tis to be noted, that: By the Grace of God, is sometimes meant, <var>the Act</var>; and sometimes, <var>the Habit</var> of Grace. The <var>Act</var> of Grace, is any Operation, or <var>Motion of the Divine Spirit, upon our Spirits</var>; whereby it <var>exciteth</var> us to Good, or <var>enables</var> us therein. The <var>Habit</var> of Grace, is the gracious, or <var>good frame of Mind</var>; that is begot in us, by the many <var>former</var> good purposes and <var>Acts</var>; that had been caused in us, by the <var>particular</var> Motions, or Actings of the Divine Spirit. If the <var>Pelagians</var>, or <var>Semipelagians</var>, had observed this; they would not have alledged, the purpose of King <var>David</var>, to build a Temple to God: not St. <var>Paul's</var> essaying to preach the Gospel in <var>Bithynia</var>. They would not have pleaded that God forbade <var>David</var>; and forbad that Apostle to execute their Purposes: and that <var>therefore</var>, tho they were indeed <var>good</var> purposes; of necessity, they proceeded not from <var>a motion of God's spirit</var>. That God forbad them, sheweth indeed; those Purposes came not, from what we have called, the <var>Act</var> of Grace; namely, a <var>particular</var> Motion of the Divine Spirit.' Nye, op. cit., p14.<br />
                <a name="note16" href="#16">16.</a> '2... The Grace of God, sufficient to Conversion; and such a <var>Frame</var> of the Heart, as we have just now described; is common to all: Is so given to all, that no Person doth miscarry, but by a <var>wilful</var> neglect or dis-use of the Grace given to him.' Nye, op. cit., p16.<br />
                <a name="note17" href="#17">17.</a> 'when we see, or hear of some very ill <var>Action</var>, or <var>course of Life</var>, by any Person or Persons; we say ordinarily thereupon, <var>yes, for want of Grace</var>: Implying that such Sinner had not Grace. But the <var>particular</var>, and especially the <var>general</var> Proof that we have given, of the universality of Grace, ought (as I said) to caution us against that <var>vulgar Error</var>, in Speech. When others sin, let us say, according to the Truth; not, he <var>wanted Grace</var>; but, he <var>used not</var>, or <var>he neglected</var> the Grace common to <var>all</var>.' Nye, op. cit., p20.<br />
                <a name="note18" href="#18">18.</a> '3... This Grace doth not operate <var>in an unresistible manner</var>, but in a way sutable to our Reasonable Natures; that is to say, in the way of <var>moral Suasion</var>.' Nye, op. cit., p20.<br />
                <a name="note19" href="#19">19.</a> '4... For <var>our</var> Wills (<var>excited</var>, and <var>quickened</var> by Grace) <var>can will Good</var>, and <var>endeavour</var> it: in a word, can comply with the Intention of the Divine Spirit; moving us to Good.' Nye, op. cit., p23.<br />
                <a name="note20" href="#20">20.</a> 'And this, at length, is that System; Scheme, or Account, concerning Grace and Free-will: that, as I said, at first, is free of all the Incumbrances; that so much distress the other two Schemes.' Nye, op. cit., p25.<br />
                <a name="note21" href="#21">21.</a> 'In a word, it was never said, as is supposed in the Objection; that GOD willeth the Salvation, of ALL; or of <var>Any</var>, absolutely, and <var>unconditionally</var>: but only, on <var>this Supposition</var>; that they <var>use the Grace</var>, whereby he enables 'em to obey him.' Nye, op. cit., pp29-30.<br /><br /><br />
                &#169; Lloyd Strickland 2007-08<br />
                With gratitude to John Thorley for advice and suggestions
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
