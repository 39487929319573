import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/numberofmen.pdf'; 
import { Helmet } from 'react-helmet';

function NumberMen() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
		Shelfmark LH 34 Bl. 215-216<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 1683 (?)<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (105k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Essay on the number of men</title>
            <meta name="description" content="An English translation of ESSAY ON THE NUMBER OF MEN by Gottfried Wilhelm Leibniz, from 1683 (?)" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: ESSAY ON THE NUMBER OF MEN</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>LH 34 bl. 215V</b>]<br /><br />

                It does not strike me that this English author who,<a name="1" href="#note1"><sup>1</sup></a> on the occasion of the growth of the city of London speaks of the multiplication of the whole human race, reasons there with sufficient foundation,<a name="2" href="#note2"><sup>2</sup></a> by arguing for example that in the time of Moses there were only sixteen million souls and now there are 320 million.<a name="3" href="#note3"><sup>3</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	But I think one could speak more reasonably of the proportion which is maintained between the men of the same century, as much as these proportions can be verified in a big city, or in some country, without going on to count all of humankind.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I once did some reasoning on this matter, on the occasion of annuities for life, and I will relate here the articles or main points of the discourse I wrote about that.<a name="4" href="#note4"><sup>4</sup></a> Here they are:<br /><br />

                <center><var>Essay on some new reasonings about human life</var></center>
                article  1) use of this research.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2) what <var>probability</var> is and its estimation.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3) Rule for finding <var>mean probabilities</var>, at which we must stop in uncertainty.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4) Limits of human life, namely 81 years, counting for nothing the small number of those who surpass that.<br /><br />

                [<b>LH 34 Bl. 216r</b>]<br /><br />

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5) Particular considerations which we set aside here, without preventing us adding them to each in the application to particular cases.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6) <var>Fundamental assumption</var>: that 81 recently born children will die uniformly (that is, one per year) in the next 81 years.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7) Demonstration: that <var>the mean length of human life</var> is forty years and that a life annuity purchased for a newly born child should be considered equivalent to a pension of 40 years.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* You might perhaps think I’m saying this because 40 is half of 80, but I demonstrate it by a completely different principle, namely by articles 3 and 6.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8) Rule for finding the mean or presumptive life that a person of a certain age could probably still hope for, and consequently <var>the value of life annuities</var> that he buys: for example, it should be estimated that a ten-year-old child will live another 35 years and so reach 45, and the life annuity he buys ought to be considered equivalent to a pension which is due to last 35 years.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;9) Shorter <var>rule</var> to find the same thing.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10) The <var>proportion</var> of men who die at each age cannot determine, for example, that the thirty-sixth part of men aged 45 dies approximately each year.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;11) <var>Assumption</var>: that in a great multitude, the number of people in general, and even that of people of each age in particular, remains approximately the same as it was in the past year.<br /><br />

                [<b>LH 34 Bl. 216v</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;12) <var>Reasonable proportion</var> of the number of living persons of each age, according to which it turns out, for example, that there will be about two of 20 years for one of 50 years.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;13) <var>Consequence</var>: that almost as many people of one age die as of another, for example if in this year one hundred 20 year-olds die, about one hundred 50 year-olds – or people of any other age – will die.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;* We must except some particular cases according to article 5, as for example with regard to small children. We must also compare article 13 with article 10 to understand them correctly.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;14) Approximately the fortieth part of men die each year. And consequently, approximately as many, and perhaps a little more, must be born to maintain the number.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;15) Naturally, there could be nine to ten times more children born than those who are actually born.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> William Petty, <var>An Essay concerning the Multiplication of Mankind: together with another Essay in Political Arithmetick, concerning the Growth of the City of London</var> (London: Mark Pardoe, 1682).<br />
                <a name="note2" href="#2">2.</a> Leibniz also wrote &quot;little&quot; (very faintly) after &quot;sufficient&quot;, with neither word crossed out, making it unclear whether he thinks Petty's calculations are defensible or not. Given Leibniz goes on to eschew attempts to extrapolate from population changes in a city to population changes across the entire world, as Petty had done, it is likely that in this opening sentence he is saying that it does not seem Petty's calculations have sufficient foundation.<br />
                <a name="note3" href="#3">3.</a> See Petty, op. cit., 24.<br />
                <a name="note4" href="#4">4.</a> Leibniz is here referring to his &quot;Essay on some new reasonings about human life and the number of men&quot; (1680 (?)), A IV 3, 456-467.<br />
                <br /><br />
                &#169; Lloyd Strickland 2021
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default NumberMen;
