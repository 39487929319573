import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/pleasure.pdf'; 
import { Helmet } from 'react-helmet';

function Pleasure() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series VI volume 4</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 1488-1489<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: summer 1683 - winter 1685/1686?<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (159k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Pleasure. An Observation Against Descartes</title>
            <meta name="description" content="An English translation of PLEASURE. AN OBSERVATION AGAINST DESCARTES by Gottfried Wilhelm Leibniz, from summer 1683 - winter 1685/1686?" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: PLEASURE. AN OBSERVATION AGAINST DESCARTES</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A VI 4, 1488</b>]<br /><br />
                <center>Pleasure</center>
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Descartes thinks the cause of pleasure consists in the sense of our powers, namely, when we perceive ourselves strongly resisting something making an attack on us.<a name="1" href="#note1"><sup>1</sup></a> I think that's just a corollary. For in general, everything that assists an action (that is, a carrying out of something) brings about pleasure, but assistance is perceived the most when we are in danger, and for that reason it is felt more exquisitely at that time. And if every pleasure consisted solely in the knowledge of our strength, not only would brutes not perceive pleasure, something Descartes would grant, but neither would we perceive in them signs of pleasure entirely like those we detect in human beings. Therefore, pleasure must be explained in such a way that its material aspect, so to speak, can also be exhibited in brutes, for it is not apparent how those bodily movements which are also in brutes follow from the sense of our powers.
                 <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTE:<br /><br />
                <a name="note1" href="#1">1.</a> Descartes, <var>The Passions of the Soul</var> article 95: 'Young people often take pleasure in attempting difficult tasks and exposing themselves to great dangers even though they do not hope thereby to gain any profit or glory. This pleasure arises in the following way. The thought that the undertaking is difficult forms an impression in their brain which, when joined with the impression they could form if they were to think that it is a good thing to feel sufficiently courageous, happy, skilful, or strong to dare to take such risks, causes them to take pleasure in doing so. And the satisfaction which old people feel in recollecting the evils they have suffered results from their thinking that it is a good thing to have been able to survive in spite of them.'
                <br /><br /><br />
                &#169; Lloyd Strickland 2005. Revised 2022<br />
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Pleasure;
