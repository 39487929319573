import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Image from '../images/premontval.jpg';
import pdf from '../pdfs/sample.pdf'; 
import pdf2 from '../pdfs/flyerus.pdf'; 
import pdf3 from '../pdfs/flyer.pdf'; 
import { Helmet } from 'react-helmet';

function Premontval() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function scrollToElement(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        <Link to={pdf} target="_blank">Download a 15 page sample (from the introduction)</Link><br /><br />
        <Link to={pdf2} target="_blank">Flyer, with 30% discount (US)</Link><br /><br />
        <Link to={pdf3} target="_blank">Flyer, with 30% discount (UK and international)</Link><br /><br />
        <a href="https://www.amazon.com/Philosophical-Writings-Pr%C3%A9montval-Lloyd-Strickland/dp/1498563562/ref=sr_1_7?s=books&ie=UTF8&qid=1519405947&sr=1-7&keywords=lloyd+strickland" target="_blank">Order from Amazon (US)</a><br /><br />
        <a href="https://www.amazon.co.uk/Philosophical-Writings-Premontval-Lloyd-Strickland/dp/1498563562/ref=sr_1_1?ie=UTF8&qid=1522622702&sr=8-1&keywords=premontval&dpID=410a0ZrCE1L&preST=_SY291_BO1,204,203,200_QL40_&dpSrc=srch" target="_blank">Order from Amazon (UK)</a><br /><br />
        <a href="http://www2.mmu.ac.uk/news-and-events/news/story/7513/" target="_blank">Press release</a><br /><br />
      </div>
    );
  }

  function endorsements() {
    return (
      <>
      Testimonials:<br /><br />
        &quot;Lloyd Strickland's clear translations give us new access to Pr&#233;montval's impassioned, personal, and heterodox writings on a wide range of philosophical topics: human and divine freedom, necessity and chance, proofs of God's existence, providence, and the mind-body problem. The informative introductions for each selection and the copious explanatory notes allow us to understand and appreciate the texts both in their own terms and as contributions to the philosophical culture of Frederick the Great's Prussia. An impressive volume.&quot;<br />
        - David Forman, <var>University of Nevada, Las Vegas</var><br /><br />
        &quot;What Strickland has organized here is nothing short of astonishing. He brilliantly captures the heart of Pr&#233;montval's philosophical views with this selection of pivotal texts for English readers for the first time ever. Both students and researchers of modern philosophy will find immense value in this stunning volume.&quot;<br />
        - Charles Joshua Horn, <var>University of Wisconsin, Stevens Point</var><br /><br />
        &quot;Strickland should be applauded for making available for the first time in an accessible English translation the philosophical writings of the quixotic French mathematician and philosopher Andr&#233;-Pierre Le Guay de Pr&#233;montval. The threads of Pr&#233montval's often heterodox ideas shine through in Strickland's translation and, woven together with the accompanying introductory materials and notes, offer the reader rare insight into this long-overlooked <var>philosophe</var>. I have little doubt that <var>The Philosophical Writings of Pr&#233;montval</var> will stand the test of time as an invaluable window into the intellectual world of Enlightenment France.&quot;<br />
        - James A. T. Lancaster, <var>University of Queensland</var><br /><br />
        &quot;Strickland brings to the attention of Anglophone scholars of the Enlightenment an understudied and original thinker. This anthology provides novel access to the idiosyncratic philosophy of Pr&#233;montval, a controversial member of the Berlin Academy and rather prolific author of metaphysical and theological tracts.&quot;<br />
        - Tinca Prunea-Bretonnet, <var>University of Bucharest</var><br /><br />
        &quot;This edition exposes Anglophone readership to the thought of an original thinker who engaged critically with some of the most significant doctrines of his time. It makes a substantial contribution to our understanding of the complex interrelations between eighteenth-century French and German authors.&quot;<br />
        - Avi Lifschitz, <var>Magdalen College, University of Oxford</var><br /><br />
      </>
    );
  }
  
  return (
    <div>
      <Helmet>
            <title>The Philosophical Writings of Prémontval</title>
            <meta name="description" content="A book authored by Lloyd Strickland on 'The Philosophical Writings of Prémontval'" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()} <hr className="centered-hr" />
                    {endorsements()}
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>THE PHILOSOPHICAL WRITINGS OF PRÉMONTVAL</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}
                    <p>View testimonials <span style={{color: 'blue'}} onClick={() => scrollToElement('endorsements')}>here</span></p>
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                  <img src={Image} alt="The Philosophical Writings of Prémontval" style={{ float: 'left', marginRight: '20px', height: isMobile ? '220px' : '364px', width: 'auto' }} />
                  <p style={{ margin: '10px 0px 0px 0px', fontSize: '13px' }}>Published April 2018 by Rowman and Littlefield.</p><br></br>
                  <p>From the back cover blurb:</p>
                  <p>This volume contains the key philosophical writings of maverick Enlightenment philosopher Andr&#233;-Pierre Le Guay de Pr&#233;montval (1716-1764). Pr&#233;montval was a prolific member of the Berlin Academy of Sciences, and in his career as academic he wrote a series of essays and books on a range of core philosophical topics, such as necessity and contingency, free will, sufficient reason, personal identity, the nature of the mind and its relationship with the body, optimism, and the existence of God. Pr&#233;montval's philosophy, shaped by his opposition to key philosophers such as Descartes, Leibniz, and Wolff, is notable for a number of original and often provocative positions on key philosophical issues of the time, which he supported by inventive critiques and a raft of novel arguments. In addition to developing a highly original proof for the existence of God based on the principles of atheism, Pr&#233;montval argued that all possible beings exist, and do so necessarily and therefore eternally; he insisted that the universe unfolded through an interplay of chance and necessity, its direction influenced by God but not under God's direct control; and he considered free will a curse and the main impediment to the realization of the only aim fitting for God, which was to make all beings happy and holy as quickly as possible. His writings are notable for anticipating modern developments such as open theism, process theology, and animal theodicy. In this volume, Lloyd Strickland makes Pr&#233;montval's key philosophical writings available in English for the first time. In making these translations, Strickland&#8212;a well-respected translator of Leibniz's work&#8212;has consulted the original manuscripts to ensure the greatest accuracy, and as befits a scholarly edition, the texts are meticulously documented with copious annotations. Accompanying the texts is a substantial and informative introduction.<br clear = "all" /></p>
                  <b>CONTENTS</b><br /><br />
                    Notes for the Reader<br />
                    Abbreviations<br />
                    Pr&#233;montval's Life and Thought<br />
                    About the Texts and Translations<br />
                    Pr&#233;montval at the Academy<br /><br />
                    <ol>
                    <li>Theotimus; Fragment of a Discourse Read at the Academy, October 19, 1752</li>
                    <li>Thoughts on Freedom</li>
                    <li>Remark on the Alleged Distinction between Absolute Necessity and Hypothetical Necessity Conceived by Philosophers for the Explanation of Freedom: Memoir Read at the Royal Academy of Sciences, December 13, 1753</li>
                    <li>The Diogenes of d'Alembert (selections)</li>
                    <li>On Chance under the Rule of Providence</li>
                    <li>Letters to Leonhard Euler and Louis Bertrand, on Epicureanism</li>
                    <li>Remark on Mr Wolff's Definition of the Word &quot;Something&quot;</li>
                    <li>Reflections on an Almost Universal Failing in the Admiration of Nature's Wonders</li>
                    <li>The Chance Organizer</li>
                    <li>The Theology of Being, or Chain of Ideas from Being to God (pt. 1)</li>
                    <li>The Theology of Being, or Chain of Ideas from Being to God (pt .2)</li>
                    <li>On the Physical Self and Moral Self, or, on the Being and the Person</li>
                    <li>On the State of Simple Sensation: Whether it can be Capable of Happiness and Woe</li>
                    <li>On Psychocracy</li>
                    <li>The Continuation of Psychocracy</li>
                    <li>Conclusion of Psychocracy</li>
                    </ol>
                    <br />

                    Bibliography<br />
                    Index<br />
                    About the Author<br /><br />
                    <hr></hr>
                    <br />
                    My translations of two more of Pr&#233;montval's philosophical texts, which are <var>not part of the book</var>, can be found on this site, namely:<br /><br />
                    <Link to="/minerva"><>The False Minerva, Or, The Good Judgement of Momus (1755 - 1757)</></Link><br /><br />
                    <Link to="/paradox"><>Explanation of a Paradox Concerning the Real Existence of the Same Body in Several Places (11 November 1756)</></Link><br /><br />
                    Just click the links above to access these texts.<br /><br />
                  {isMobile ? (
                    <div id="endorsements">
                      <hr></hr><p style={{paddingTop: '10px'}}>Go back to the top of the page <span style={{color: 'blue'}} onClick={() => scrollToElement('top')}>here</span></p>
                      {endorsements()}
                    </div>
                  ) : (
                    <>
                    </>
                  )}
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Premontval;