import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/sophie1692.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>S&#228;mtliche schriften und briefe series I, volume 8</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		p 30<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 5 July 1692<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (10k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Duchess Sophie (5 July 1692)</title>
            <meta name="description" content="An English translation of a LETTER TO DUCHESS SOPHIE by Gottfried Wilhelm Leibniz, from 5 July 1692" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO DUCHESS SOPHIE</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A I 8, p30</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Madam<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I have just been sent from Brunswick a very large tooth from an extraordinary animal whose skeleton was found close to the aforementioned city.<a name="1" href="#note1"><sup>1</sup></a> And I have been asked for my opinion on it. The common man forcefully claims that it is from a giant. It would in that case have to have been relatively about the size of a house. The descriptions I find of elephant's teeth seem to make it plausible that this tooth was from such an animal; for it is reported to us that an elephant has 4 big teeth above, and as many below, full of grooves or notches, like the grindstones of mills, in order to reduce their meat into a flour-like paste, by dint of crushing it between these teeth. And these grooves are visible in this tooth.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Nevertheless as people did not start finding such bones in these countries and elsewhere today, and as elephants hardly live in cold countries, one could doubt if this tooth would not be from relics of some large sea monsters, since seashells and other remains of sea animals are often found in the middle of the land that the ocean perhaps once covered. For walruses or sea oxen are found in the North Sea that have some relation with the elephant, and there are even some of them that have tusks, which are valued as much as and more than ivory. But to better judge about all this, I replied than we should collect as much as possible all the pieces of the skeleton.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTE:<br /><br />
                <a name="note1" href="#1">1.</a> It was sent to Leibniz by Lorenz Hertel, on 15/25 June 1692 (see his letter to Leibniz in A I 8, pp6-7).<br />
        <br /><br />
                &#169; Lloyd Strickland 2005<br />
                With thanks to Geert de Wilde and Michael Pickles
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
