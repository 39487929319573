import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/trinitas.pdf'; 
import { Helmet } from 'react-helmet';

function Trinity() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}> 
        Source:<br /><br />
        <var>Textes in&#233;dits tome 2</var><br />
        Gaston Grua (ed)<br />
        p 559<br /><br />
        <var>S&#228;mtliche schriften und briefe series VI volume II</var><br />
        Deutsche Akademie der Wissenschaften (ed)<br />
        pp 287-88<br /><br />{isMobile ? '' : <hr className="centered-hr" />}
        Date: spring - autumn 1671<br /><br />
        Note - Leibniz' own deletions are enclosed in square brackets [ ... ] <br /><br />
        Translated from the Latin<br /><br /> {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (10k)</Link><br /><br />
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Trinity. Mind.</title>
            <meta name="description" content="An English translation of TRINITY. MIND. by Gottfried Wilhelm Leibniz, from spring - autumn 1671" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: TRINITY. MIND.</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                [<b>Gr p559</b>] [<b>A VI 2 p287</b>]<br /><br />
                GOD. MIND. <var>Understanding, Power</var><br /><br />
                <table width="100%" border="0">
                <tr>
                <td width="30%">BODY. WORLD.<br />Space<br />Figure<br />Motion<br /></td>
        <td width="35%">[SOUL] MIND. GOD.<br />Understanding<br />Imagination<br />Will. Power.<br /></td>
        <td width="35%"><br />Being<br />Knowing<br />Act or endeavour<br /></td></tr>
                </table><br clear = "all" />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Just as God would survive if creatures were destroyed, so Mind survives when the body is destroyed. And as God thinks things that he does not perceive by any sense, because they follow from his own nature, so [<b>A VI 2 p288</b>] does Mind. As Mind, thinking about action and existing things, has understanding and will or disposition, so God has the word and love. If however God did not think himself to be in act, he would certainly think, but would neither perceive nor have happiness.<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;As it cannot be otherwise than that God thinks himself to be in act, so it cannot be otherwise than that Mind thinks itself to be in act. Mind and God do not differ except that one is finite and the other infinite.

        <br /><br /><br />
        &#169; Lloyd Strickland 2004<br />
        With gratitude to John Thorley for advice and suggestions
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Trinity;
