import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/obligation.pdf'; 
import { Helmet } from 'react-helmet';

function Obligation() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>S&#228;mtliche schriften und briefe series VI volume 4</var><br />
        Deutsche Akademie der Wissenschaften (ed)<br />
        pp 2152-2154<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        Date: 1st half of 1677?<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (13k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>On the Obligation to Believe</title>
            <meta name="description" content="An English translation of ON THE OBLIGATION TO BELIEVE by Gottfried Wilhelm Leibniz, from 1st half of 1677?" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: ON THE OBLIGATION TO BELIEVE</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A VI 4, p2152</b>]<br /><br />
                <center><var>On the obligation to believe, which is offered as a demonstration</var></center><br />

                The reasoning:<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Definitions</var><br />
                <ol>
                <li><var>An obligation</var> is a necessity imposed under the fear of a just punishment.</li>
                <li><var>To believe</var> is to be conscious of the reasons which we find convincing.</li>
                <li><var>Things in our power</var> are those which happen if we want them to.</li>
                <li><var>Fear</var> is the desire to avoid something.</li>
                <li><var>Self-knowledge</var> is the memory of our actions.</li></ol>

                [<b>A VI 4, p2153</b>]<br /><br />
                <center><var>Observation</var></center><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It is not now in our power to remember or not remember any past thing.<br /><br />

                <center>Proposition I</center><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Self-knowledge is not in our power</var>.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Demonstration</var>: For self-knowledge is memory <var>by definition 5</var>. Memory is not in our power, <var>by the preceding observation</var>, therefore self-knowledge is not in our power either.<br /><br />

                <center><var>Proposition II</var></center><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>It is not in our power to believe or not believe something</var>.<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Demonstration</var>: For to believe is to be conscious of reasons which we find convincing about what is to be believed, <var>by definition 2</var>, and self-knowledge is not in our power, <var>by proposition 1</var>. Therefore neither is it in our power to believe or not believe.<br /><br />

                <center><var>Proposition III</var></center><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>There is no obligation to do those things which are not in our power</var>.<br /><br />

                [<b>A VI 4, p2154</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Demonstration</var>: Since fear of punishment is nothing other than the desire to avoid punishment, <var>by definition 4</var>, it follows that, to the person under an obligation, the only contribution punishment can make to the fulfilment of the obligation is the desire to avoid it, either by obedience or pledge. Therefore, since it is evident from this that the fear of punishment should only bring about the desire to obey, and indeed the obligation is a necessity which brings about the fear of punishment, <var>by definition 1</var>, it follows that there is no obligation except to the desire to obey; and hence to those things which even by the desire to obey, i.e. which - <var>by definition 3</var> - are not in our power, there is no obligation.<br /><br />

                <center><var>Proposition IV</var></center><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>There is no obligation to believe, but only to investigate with utmost application</var>.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To believe is not in our power, <var>by proposition 2</var>. There is no obligation to do those things which are not in our power, <var>by proposition 3</var>. Therefore there is no obligation to believe. Which was to be demonstrated.<br /><br />

                <center><var>Corollary</var></center><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;From this it follows that it is pointless to oblige someone by oath to believe or not believe something.<br /><br />

                <center><var>Scholium</var></center><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;From this I infer that an obligation or commandment to believe occasions only the greatest effort to obey, i.e. the most meticulous investigation into reasons for believing. However this investigation is to be moderated in such a way that we should not ask of anyone a greater feat than what, by his personal situation and way of life, he is capable of.<a name="1" href="#note1"><sup>1</sup></a> To express the matter more clearly: at this present moment I am not obliged to believe or remember anything, because it does not depend on me. But I am sometimes obliged to try to discover reasons, which I may remember, i.e. believe, in future. Most things in moral matters and natural theology could be demonstrated by a similar method. This would be of great importance to the tranquillity of the soul. For demonstration alone begets certainty, certainty alone begets a durable tranquillity, tranquillity alone begets happiness, tranquillity begets happiness.
                <br /><br /><br />
                        <hr className='rightcol-hr' />
                        <br /><br />
                        NOTE:<br /><br />
                        <a name="note1" href="#1">1.</a> Here Leibniz wrote and then deleted the following: 'Nothing can be objected to this demonstration if anyone accepts the definitions I have put forward. But if anyone were to doubt them, the discussion ends, for it would involve taking the words <var>obligation</var>, <var>to believe</var> etc. in a sense different from mine. However, I declare that I am satisfied with demonstrating the proposition according to the sense of the words which I have supposed, which I think does not differ from their ordinary usage.'
                        <br /><br /><br />
                        &#169; Lloyd Strickland 2004, 2007
              <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Obligation;
