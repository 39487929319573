import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/tentzel.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>S&#228;mtliche schriften und briefe series I volumes 12 &amp; 13</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: May 1696 - January 1697<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (21k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to/from Wilhelm Ernst Tentzel (May 1696 - January 1697)</title>
            <meta name="description" content="An English translation LETTERS TO/FROM WILHELM ERNST TENTZEL (SELECTIONS) by Gottfried Wilhelm Leibniz, from May 1696 - January 1697" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO/FROM WILHELM ERNST TENTZEL (SELECTIONS)</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 Background - in 1695, some bones were found in Tonna, near Gotha in Th&#252;ringen, which Wilhelm Ernst Tentzel claimed were from an elephant. This was hotly disputed by many other scientists. Tentzel published his view in his <var>Epistola de sceleto elephantino</var><a name="1" href="#note1"><sup>1</sup></a> in 1696, and it became a topic in his correspondence with Leibniz shortly afterwards...<br /><br /><br />
		        <b><center>Leibniz to Tentzel (21-2 May (?) 1696)</center></b><br />

                [<b>A I 12, p602</b>]<br /><br />

                I thank you for the assessment of your gentlemen doctors which was sent to me, and with which I admit I cannot agree, and plainly I agree with you that it is the remains of an animal. But I would not likewise confirm that it comes from an elephant. For I have for a long time now suspected, seeing how often these remains are discovered everywhere, and how unsuited these animals are to these regions, unless they were brought there by human agency, that some remains customarily attributed to elephants are from marine animals; for not only other, great arguments but also glossopetrae<a name="2" href="#note2"><sup>2</sup></a> convince us that a part of these regions was once immersed by the sea.<br /><br />
                <hr className='rightcol-hr' /><br />
                        <b><center>Tentzel to Leibniz (25 May/4 June 1696)</center></b><br />

                [<b>A I 12, p618</b>]<br /><br />

                Moreover, you have seen from the clearest comparison established by me that it is fairly certain that the animal discovered in Tonna cannot be other than a terrestrial elephant. For the marine animal, which others call Rosmarum or <var>walrus</var>, is plainly composed in a different way, and it is not of such a great size, but the largest of them hardly surpasses a larger ox, nor does it have a tail, not to mention other, greater proofs that are easily drawn from an accurate description of it by Worm, in <var>A Museum, or the History of Rare Things</var>, and Ray.<br /><br />
                <hr className='rightcol-hr' /><br />
                        <b><center>Leibniz to Tentzel (8/18 June 1696)</center></b><br />

                [<b>A I 12, p639</b>]<br /><br />

                I acknowledge what you say, and like you I certainly conclude, and this is what I have always thought, that such things are from the animal kingdom and are not formed by some plastic power of the earth, as are those things which are called tricks of nature, which are in any case much less developed, and less similar in details. Moreover, some will doubt whether we can be equally certain about the kind of animal from which those bones from Tonna come. For marine oxen have many things in common with elephants, and there is a very great variety of narwhal in the northern ocean, and no animals more easily attain such a great mass as do marine animals, for which there is a physical reason. Meanwhile, what you conclude for the elephant is quite probable, which can be sufficient in a matter less capable of  demonstration, since the skeleton is considered insufficiently preserved and described. Moreover, since remains of this kind are often discovered in Europe, it will have to be considered whether it is more probable that the remains are from a resident animal, or whether the remains are to be explained by means of the flood. There are many indications that a great part of these regions was at some point submerged, and modern day rivers do not prevent that from being so. And with holy scripture itself favouring the view, the globe of the Earth has undoubtedly undergone greater changes than the common man thinks.<br /><br />
                <hr className='rightcol-hr' /><br />
                        <b><center>Tentzel to Leibniz (24 June 1696)</center></b><br />

                [<b>A I 12, p653</b>]<br /><br />

                I shall not believe that the remains are those of a marine animal until something among marine animals is shown to me which is similar, and to which the description of the remains of our skeleton exactly corresponds as well as it does to an elephant. For a marine ox, by which in the manner of the Italians you mean a hippopotamus, does not have those two very large and long protruding teeth, but the curved teeth of a wild boar, and they are at most a foot in size; and these are not from the upper jaw, as in elephants and in the skeleton from Tonna, but rather from the lower jaw, not to mention other differences. However many kinds of narwhal there are, they are still not from the quadrupeds but from fish and whales, and they still have one horn, not two, as is obvious from Worm's accurate description of them. I grant that marine animals more readily attain a great mass, but in my <var>Letter</var><a name="3" href="#note3"><sup>3</sup></a> I have sufficiently shown that the largest of the elephants is bigger than the skeleton from Tonna.<br /><br />
                <hr className='rightcol-hr' /><br />
                        <b><center>Leibniz to Tentzel (17/27 June 1696)</center></b><br />

                [<b>A I 12, p661</b>]<br /><br />

                I agree that there is a great probability that it is an elephant, and as great a probability as can be hoped for in such things; although in our great ignorance of the things of the old world we have not sufficiently investigated whether marine elephants then were closer to terrestrial elephants than now. When Mr Helmont and I spoke about this matter to the most serene Electress<a name="4" href="#note4"><sup>4</sup></a> he said that it must be seen whether or not climates have changed so that these regions were warmer then than they are perceived to be now, and more suitable to true elephants. Thomas Burnett certainly thinks that the obliqueness of the ecliptic was not always what it is now,<a name="5" href="#note5"><sup>5</sup></a> although heat and cold can originate from somewhere other than [<b>A I 12, p662</b>] the sky, but I think it is more credible that the change has happened in elephants themselves, i.e. that elephants once existed which were more adapted to colder regions than the elephants that are found now, and that these were native to our lands, but in such cases the freedom of conjecture must be left to each individual. That which I conceded to you at the beginning can be sufficient.<br /><br />
                <hr className='rightcol-hr' /><br />
                        <b><center>Leibniz to Tentzel (10/20 July 1696)</center></b><br />

                [<b>A I 12, p707</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If the collar was discovered with the skeleton, by which it would be evident that the animal from Tonna has been buried within these last two thousand years, I would say that it now should be regarded as certain that this is a terrestrial [<b>A I 12, p708</b>] elephant such as the world still has today. Now, since there is such ignorance of the things of the ancient world, nothing can be ventured as certain other than that the animal was an elephant or analogous to an elephant, whether amphibious or terrestrial. How many species does America have elsewhere which are still unknown or very different? How many must we suppose have died if what Plato says about some vast submerged Atlantic continent is true?<a name="6" href="#note6"><sup>6</sup></a>  And what if formerly there were more amphibious creatures than now, when the greater part of the globe, as there is reason to think, was for a long time submerged by the waters? I am amazed that you should write that I clearly meant hippopotamuses when I referred to marine oxen, as if the ancients did not also call elephants <var>bos lucas</var>?<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It suits the prejudices of the common man to want to impute all the great changes of our globe to Noah's flood; you may have doubts about other prejudices, but you cannot therefore immediately deny this one. I grant that the sandy hill from which the skeleton was dug up was made from the sediments of the waters, just as is generally true of other strata of the Earth. But is it therefore directly from Noah's flood? Unless perhaps you think that the Earth lacked strata before Noah.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I would want to see by what method of argumentation you may ultimately prove what you state with such confidence on another occasion, that the altitude of the regions completely prevents us from believing that they were covered by sea at some time other than that of the well known flood. I am certainly not unwilling to believe that it is quite probable that at some time terrestrial elephants lived on these shores, but who believes that they were afterwards removed by the floods?<br /><br />
                <hr className='rightcol-hr' /><br />
                        <b><center>Tentzel to Leibniz (19/29 July 1696)</center></b><br />
                [<b>A I 12, p748</b>]<br /><br />

                If you have not observed a hippopotamus recently, it is necessary that you should mention other marine oxen; nor does the name prove that those [animals] they called <var>boves lucas</var> in Italy were actually elephants.<br /><br />
                <hr className='rightcol-hr' /><br />
                        <b><center>Leibniz to Tentzel (3/13 August 1696)</center></b><br />

                [<b>A I 13, p204</b>]<br /><br />

                I ask for no assent from you, since I claim nothing as certain. I think that your opinion is more probable than others, since we can show no animal corresponding more to your skeleton. I do not assert that <var>any species have died out</var>, although I am not prepared to say that it is absurd. But I think we must distinguish between extinct species and those that have greatly changed. Thus the dog and wolf, cat and tiger can be seen as being of the same species. The same can be said about the amphibious animals or marine oxen once analogous to the elephant.<br /><br />
                <hr className='rightcol-hr' /><br />
                        <b><center>Leibniz to Tentzel (12/22 November 1696)</center></b><br />

                [<b>A I 13, p346</b>]<br /><br />

                Certainly you have given an uncommon sign both of genius and erudition, and in my judgement you have proved that they are not tricks of nature, but that they are the remains of an animal, and indeed that they are either from an elephant or at least from an elephant-like animal, just as there are animals of the feline and canine species which are called neither cats nor dogs. In general, therefore, the first thing to be asked at this point is whether it was an elephant, or another [<b>A I 13, p347</b>] creature of the elephant species which may once have lived in these regions or seas, but were destroyed by very great changes in circumstances.<br /><br />
                <hr className='rightcol-hr' /><br />
                        <b><center>Leibniz to Tentzel (17/27 January 1697)</center></b><br />

                [<b>A I 13, p504</b>]<br /><br />

                I am amazed that there are among learned men those who still uphold those tricks of nature which have now been exploded by Steno<a name="7" href="#note7"><sup>7</sup></a> and other distinguished men, especially since it is not a matter of the coarse parts of animals but those ingeniously formed.<br /><br />
                <hr className='rightcol-hr' /><br />
                        NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> <var>Letter on an elephant skeleton</var>.<br />
                        <a name="note2" href="#2">2.</a> Sharks' teeth.<br />
                        <a name="note3" href="#3">3.</a> Tentzel, <var>Epistola de sceleto elephantino tonnae nuper effosso</var> (1696).<br />
                        <a name="note4" href="#4">4.</a> Francis Mercury van Helmont and Electress Sophie of Hanover. Helmont visited Hanover in March 1696 and had regular discussions with Leibniz and Sophie.<br />
                        <a name="note5" href="#5">5.</a>  See Thomas Burnet, <var>The Sacred Theory of the Earth</var> (London: Centaur Press, 1965), bk. II ch. III, pp146-7.<br />
                        <a name="note6" href="#6">6.</a> An allusion to Plato's <var>Critias</var>, an unfinished work about Atlantis.<br />
                        <a name="note7" href="#7">7.</a> Nicholas Steno (1638-1686).<br /><br /><br />
                        &#169; Lloyd Strickland 2006<br />
                        With gratitude to John Thorley for advice and suggestions
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
