import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/staff.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>S&#228;mtliche schriften und briefe series I, volume 20</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 480-481<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 23 September 1701<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (19k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Nathanael von Staff (23 September 1701)</title>
            <meta name="description" content="An English translation of a LETTER TO NATHANEAL VON STAFF by Gottfried Wilhelm Leibniz, from 23 September 1701" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO NATHANEAL VON STAFF</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
             	[<b>A I 20, p480</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I would have liked to be able to furnish you with something concerning the origin of this Raynierus Longicollus,<a name="1" href="#note1"><sup>1</sup></a> founder of the Most Serene House of Hessen and of the ancient Dukes of Brabant, and this is what made me delay responding to the honour of your letter. But after a close examination I have found nothing I can add to what is said in Butken's <var>Trophea Brabantina</var>.<a name="2" href="#note2"><sup>2</sup></a> And that is quite sufficient if the genealogy since that time can be accurately verified. I think I informed you that the life of Father Vignier, together with his portrait, can be found in Mr Perrault's <var>Hommes illustres de France</var>.<a name="3" href="#note3"><sup>3</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You will doubtless know, Sir, that the late Mr Winkelmann had written in German a chronicle of Hessen, of which I have even seen an unpublished volume.<a name="4" href="#note4"><sup>4</sup></a> Is there any hope that one day this work will appear?<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;There was in our court a learned French mathematician and inventor called Mr Papin, with whom I exchanged letters from time to time.<a name="5" href="#note5"><sup>5</sup></a> But he went to Holland perhaps more than a year ago, and I would like to know whether he has returned or has left the service and moved to England, as he had planned to do.<a name="6" href="#note6"><sup>6</sup></a> As I will be in Berlin in a few days, I would like to be able to receive and execute your orders thereon. I leave at the beginning of the coming week.<br /><br />

                [<b>A I 20, p481</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Our English visitors have all left now. They seemed very well intentioned towards our side, especially the Earl of Macclesfield, whom the King of Great Britain had sent to hand over the Act of Succession.<a name="7" href="#note7"><sup>7</sup></a> These gentlemen assured us that their partiality was that of the nation, and as this seems to be the case it makes me hope that the harm brought about by the delay of Parliament in the last session will be redressed.<a name="8" href="#note8"><sup>8</sup></a> Moreover, I am etc.

                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> Reginar I, Langhals [Longneck], Count of Hennegau (850-915/6).<br />
                <a name="note2" href="#2">2.</a> Christophe Butkens, <var>Troph&#233;es, tant sacr&#233;s que profanes du duch&#233; de Brabant</var> (Antwerp, 1641).<br />
                <a name="note3" href="#3">3.</a> Charles Perrault, <var>Les Hommes illustres qui ont paru en France pendant ce si&#232;cle</var> (Paris, 1700), vol. 2, pp17ff.<br />
                <a name="note4" href="#4">4.</a> Johann Just Winkelmann, <var>Gr&#252;ndliche und warhafte Beschreibung der F&#252;rstenth&#252;mer Hessen und Hersfeld</var> (vols. 1-5: Bremen, 1697; vol. 6: Cassel, 1754). Leibniz is presumably referring to volume 6, which was only published in 1754.<br />
                <a name="note5" href="#5">5.</a> Leibniz is referring to Denis Papin (1647-1714), who at the time of writing was Councillor to the Landgrave of Hessen-Kassel.<br />
                <a name="note6" href="#6">6.</a> Papin eventually settled in England in 1707.<br />
                <a name="note7" href="#7">7.</a> Charles Gerard, 2nd Earl of Macclesfield (1659-1701). In August 1701 he led a British delegation to deliver the Act of Succession to Leibniz's patroness, Electress Sophie of Hanover, whom the Act named as next in line to the British throne.<br />
                <a name="note8" href="#8">8.</a> Leibniz may be referring to the Disbanding Act of 1699, which reduced the British Army to 7000 men in order to curb William III's involvement in continental warfare.<br /><br /><br />
                &#169; Lloyd Strickland 2008
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
