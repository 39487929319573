import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/symbol.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series VI volume 4</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		p 916<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: summer 1688 (?)<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (11k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>On Symbols and the Characteristic Art</title>
            <meta name="description" content="An English translation of ON SYMBOLS AND THE CHARACTERISTIC ART by Gottfried Wilhelm Leibniz, from summer 1688 (?)" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: ON SYMBOLS AND THE CHARACTERISTIC ART</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A VI 4, p916</b>]<br /><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I call a <var>symbol</var> a visible sign representing thoughts.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The <var>characteristic art</var> is thus the art of forming and ordering symbols so that they reproduce thoughts, i.e. so that they have the relation to each other that thoughts have to each other.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;An <var>expression</var> is an aggregate of symbols representing the thing which is expressed.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>The law of expressions is this</var>: just as the idea of a thing to be expressed is composed from the ideas of those things, an expression of a thing is composed from the symbols for those things.

                <br /><br /><br />
                &#169; Lloyd Strickland 2007-08
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
