import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/happiness.pdf'; 
import { Helmet } from 'react-helmet';

function PublicHappiness() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series VI volume 4</var><br />
        Deutsche Akademie der Wissenschaften (ed)<br />
        pp 2842-2843<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 1680?<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (89k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>On Public Happiness</title>
            <meta name="description" content="An English translation of ON PUBLIC HAPPINESS by Gottfried Wilhelm Leibniz, from 1680?" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: ON PUBLIC HAPPINESS</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A VI 4, p2842</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Whatever is publicly useful, is to be done.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The common good is calculated from the goods of individuals collected into one sum.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;First of all, care must be taken to ensure that everyone is content, or that no one is afflicted by pain.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The next thing is to make sure that everyone<a name="1" href="#note1"><sup>1</sup></a> is happy, or lives as agreeably as possible.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It is better to be deprived of pleasures than to be afflicted by pains.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Joy</var> is a state of prevailing pleasures. <var>Sadness</var> that of prevailing pains.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Happiness</var> is a state of enduring<a name="2" href="#note2"><sup>2</sup></a> joy.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Misery</var> is a state of enduring<a name="3" href="#note3"><sup>3</sup></a> sadness.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A <var>necessary</var> good is one without which we would be miserable; other goods are merely called <var>useful</var>.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Goods and evils are to be distributed among people in such a way that the least evil, or the greatest common good, arises therefrom.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Care must be taken that people are <var>prudent</var>, <var>endowed with virtue</var>, and abundant in <var>resources</var> so that they know how to, want to, and are able to act in the best way, and that they neither think about, nor want to, nor are able to act in a bad way.<br /><br />

        [<b>A VI 4, p2843</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Prudence and virtue are instilled in minds by precepts and their rational bases&mdash;which are natural and civil rewards and punishments&mdash;as well as by use, in other words by the exercise of the virtues.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Precepts extend either to internal actions or thoughts, which moral theology involves or what is called the doctrine of cases of conscience, or to external actions which, by themselves, can come to the knowledge of others, and civil jurisprudence is concerned with these.<a name="4" href="#note4"><sup>4</sup></a> <a name="5" href="#note5"><sup>5</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;And resources, or the goods that can be distributed among people at the discretion of someone with power, are either spiritual or temporal, from which stems a twofold jurisprudence, ecclesiastical and secular.<br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> everyone &#124; lives as agreeably as possible &#124; <var>deleted</var>.<br />
                <a name="note2" href="#2">2.</a> enduring &#124; pleasure &#124; <var>deleted</var>.<br />
                <a name="note3" href="#3">3.</a> enduring &#124; pain &#124; <var>deleted</var>.<br />
                <a name="note4" href="#4">4.</a> Leibniz drew a border around this paragraph.<br />
                <a name="note5" href="#5">5.</a> these. &#124; Since the rational bases of the precepts are rewards and punishments, which consist in the distribution of goods and evils, and these bases are either natural, which are connected with action by the nature of the thing, or arbitrary, that is, decreed, these being dispensed by the will of someone with power: and indeed, in these, all &#124; <var>deleted</var>.
                <br /><br /><br />
                &#169; Lloyd Strickland 2004. Revised 2022
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default PublicHappiness;