import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/springs.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
        Shelfmark LH 37, 4 Bl. 32<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        Date: 1678 (?)<br /><br />
        Translated from the Latin<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (5k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>The Source of Springs</title>
            <meta name="description" content="An English translation of THE SOURCE OF SPRINGS by Gottfried Wilhelm Leibniz, from 1678 (?)" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: THE SOURCE OF SPRINGS</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>LH 37, 4 Bl. 32</b>]<br /><br />
                <center>The source of springs</center><br />

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Perhaps a great part of sea water trickles down into the interior of the earth, and being raised again by some subterranean heat, produces springs by vapour. Perhaps the earth is full of water within, and the vapours rise not by subterranean heat, but by the attraction of the surrounding air, for when the air is thin, as in summer, it draws denser air to itself.

                <br /><br /><br />
                  &#169; Lloyd Strickland 2023
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
