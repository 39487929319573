import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/molanusfeb1698.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>Textes in&#233;dits tome 1</var><br />
		Gaston Grua (ed)<br />
		pp 411-412<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 2 February 1698<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (11k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Gerhard Wolter Molanus (2 February 1698)</title>
            <meta name="description" content="An English translation of a LETTER TO GERHARD WOLTER MOLANUS by Gottfried Wilhelm Leibniz, from 2 February 1698" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO GERHARD WOLTER MOLANUS</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>Gr p411</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;When I received your letter, I was on the point of sending you what you now see attached. [<b>Gr p412</b>] As far as the argument of Musaeus<a name="1" href="#note1"><sup>1</sup></a> is concerned, I shall write separately on another occasion. For I don't want to put this off now any further; see how the learned Fabricius and Schmid have answered. For I am ordered to communicate with them the document that you are examining. Meanwhile I ask that you still conceal that I have received it from you. The aim is not for us to communicate their reply, but in the present circumstances for us to use it.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You will have seen my thoughts taken in every way other than they have been clearly explained by me, and that my explanations have been taken over from somebody or other, either the Thomists or others. Moreover, if I might say so, you will have seen my thoughts displayed, not so much from what I have clearly said, but from the views others have expressed.<a name="2" href="#note2"><sup>2</sup></a> For you change my propositions to others which I by no means accept. I said that every creature is essentially limited, and I called this limitation or negation a privative imperfection, and I added that this is the source of evil, not only of the capacity for sin, but also of the sin itself. For if creatures had had every degree of perfection, they would not have fallen.<a name="3" href="#note3"><sup>3</sup></a> I do not see what can be denied in this. But you, withdrawing from my words, ascribe to me the unavoidable determination of the supralapsarians, by what force of reasoning I do not know. You say the cause of the fall is the free will of the first-formed evil angels:<a name="4" href="#note4"><sup>4</sup></a> as if that opposes my view, or is denied by me. By the very limitation of these minds, when certain circumstances occurred it was inevitable that they should go wrong in their action. Therefore I assert that at no time did it come into my mind to say that a determination is unavoidable, so that Adam was unable not to be disobedient; rather I refute the necessity of this most assiduously, although I do concede the certainty of it.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> Johann Musaeus, Lutheran Professor at Jena.<br />
                <a name="note2" href="#2">2.</a> Here Leibniz wrote and then deleted: 'I do not know how they were attributed to me'.<br />
                <a name="note3" href="#3">3.</a> Here Leibniz wrote and then deleted: 'However you impute to me an unavoidable determination, which the supralapsarians think leads to positive evil.'<br />
                <a name="note4" href="#4">4.</a> Here Leibniz wrote and then deleted: 'I also concede that, but if they had not been attracted...' After deleting that, he then wrote: 'Nevertheless I think there is a reason why some creatures used their freedom better than others, which is sometimes to be found in their limitation, and sometimes in the circumstances, and I do not think this is opposed to my view.' This was deleted also.<br /><br /><br />
                &#169; Lloyd Strickland 2004<br />
                With gratitude to John Thorley for advice and suggesions
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
