import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/circumcision.pdf'; 
import { Helmet } from 'react-helmet';

function Circumcision() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series VI volume 4</var><br />
        Deutsche Akademie der Wissenschaften (ed)<br />
        pp 2285-2286<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 1679 - 1685 (?)<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (226k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>The Physical Origin of Circumcision</title>
            <meta name="description" content="An English translation of THE PHYSICAL ORIGIN OF CIRCUMCISION by Gottfried Wilhelm Leibniz, from 1679 - 1685 (?)" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: THE PHYSICAL ORIGIN OF CIRCUMCISION</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A VI 4, 2285</b>]<br /><br />
                <center>The Physical Origin of Circumcision</center><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The sacraments have a foundation in nature and contain a certain divine allegory, so that they are at the same time both signs and the things signified. Thus baptism by its very nature signifies cleansing from filth, and the Eucharistic supper the full communion of love between friends. By Christ's institution, baptism is a washing by which the soul is made clean. And the Holy Supper is a communion in which we become partakers of the same body of Christ and are united like branches of the same tree to the trunk, whose living members are animated to love by the flowing juice of grace. But what shall we say of the sacrament of the Old Law, namely circumcision? What is the occasion of the rite? What is the foundation of its meaning? This was unknown to theologians, but journeys to the east have taught us that circumcision arose among those peoples not by divine or human will but by a command of nature, due to the troublesome outgrowth of the foreskin among the Arabs, Egyptians and Ethiopians. Johann Vesling himself has seen that &quot;it often grows in young boys so that it becomes pointed in the manner of a tail&quot;, as Bartholin reported from the mouth of Vesling (<var>Reformed Anatomy</var>, p. 239) in the description of the penis.<a name="1" href="#note1"><sup>1</sup></a> Hence what reason suggested, divine authority consecrated, [<b>A VI 4, 2286</b>] transforming an approved custom to a covenant marking out the chosen people, so that circumcision of the foreskin would signify the restraint of lust and the manners fit for sanctity.<br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> Leibniz here mixes up the title and page number of two different works by Thomas Bartholin. He mentions Bartholin's <var>Anatomia, ex Caspari Bartholini parentis Institutionibus, omniumque recentiorum et propriis observationibus tertium ad sanguinis circulationem reformata</var> (The Hague: Adriaan Vlacq, 1655), but his near-quotation is from another of Bartholin's works, namely <var>Anatome ex omnium veterum recentiorumque observationibus inprimis Institutionibus b.m. parentis Caspari Bartholini, ad circulationem harvejanam, et vasa lymphatica quartum renovata</var> (Leiden: Hack, 1673), 239: &quot;Among these [sc. Hebrews and Turks], it is remarkable, as Johann Vesling told me, that the foreskin in young boys often grows so that it becomes pointed in the manner of a tail&quot;. As for Vesling, see Johann Vesling, <var>Syntagma anatomicum</var> (Padua: Paulo Frambotto, 1647), 66: &quot;The foreskin of Egyptian and Arab children often grows excessively and too much; it thins out as it grows, so that they are forced to cut off part of it no less out of fear of paraphimosis than from religious prescription.&quot;<br />
                <br /><br /><br />
                &#169; Lloyd Strickland 2016. Revised 2022<br />
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Circumcision;
