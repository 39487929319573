import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/existence.pdf'; 
import { Helmet } from 'react-helmet';

function Existence() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		    <var>S&#228;mtliche schriften und briefe series VI volume 4</var><br />
        Deutsche Akademie der Wissenschaften (ed)<br />
        p 1346<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />} 
        Date: 1677?<br /><br />
        Translated from the Latin<br /><br />{isMobile ? '' : <hr className="centered-hr" />} 
        <Link to={pdf} target="_blank">View this translation in PDF format (10k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Existence</title>
            <meta name="description" content="An English translation of EXISTENCE by Gottfried Wilhelm Leibniz, from 1677?" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: EXISTENCE</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                [<b>A VI 4, p1346</b>]<br /><br />
                <center>Existence</center><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It can be doubted very much whether existence is a perfection, i.e. a degree of reality, since it can be doubted whether existence is one of those things that can be conceived, i.e. one of the parts of an essence, or whether it is only a certain imaginary concept like the concept of heat and cold, which is only a denomination of our perception, not of the nature of things. Yet if we consider the matter carefully, [it is clear] that we conceive something more when we think that thing A exists than when we think that it is possible; therefore it seems to be true that existence is a certain degree of reality, or at any rate that it is some relation to degrees of reality. But existence is not some degree of reality or other, since it is possible to comprehend both the possibility and the existence of any degree of reality whatsoever. Existence, then, will be the excess of one thing's degrees of reality over the degrees of reality of a thing opposed to it. That is, that which is more perfect than all mutually incompatible things <var>exists</var>, and conversely what exists is more perfect than everything else. Therefore it is indeed true that what exists is more perfect than the non-existing, but it is not true that existence itself is a perfection, since it is only a sort of comparative relation of perfections among themselves.
                <br /><br /><br />
                &#169; Lloyd Strickland 2009
                <br /><br />
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Existence;