import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/particular.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series VI volume 4</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		p 1589<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: winter 1685/1686 (?)<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (9k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>On rejecting the particular will of God, against Malebranche</title>
            <meta name="description" content="An English translation of ON REJECTING THE PARTICULAR WILL OF GOD, AGAINST MALEBRANCHE by Gottfried Wilhelm Leibniz, from winter 1685/1686 (?)" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: ON REJECTING THE PARTICULAR WILL OF GOD, AGAINST MALEBRANCHE</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A VI 4, p1589</b>]<br /><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If, following Malebranche, God does those things by a universal will which are attributed to other creatures, simply by his own concurrence, I do not see that there is need to attribute particular wills to God in the formation of animals; but neither do I yet see how almost all miracles may be safely attributed to the simple works of an angelic nature. Thus there will in fact be no more miracles than the seven wonders of the world. But if we call 'particular' a will whose reason cannot be given, or which cannot be resolved into something general, then I think that no will of God is particular, since all things are done by God according to certain general laws. According to Malebranche, God does all things by this distinction alone: that some of his actions are in accordance with laws, while others are not, but he contradicts himself when saying that God does all things by general wills unless the order of the universe demands otherwise.
                <br /><br /><br />
                &#169; Lloyd Strickland 2005<br />
                With gratitude to John Thorley for advice and suggestions
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
