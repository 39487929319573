import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/demonstration.pdf'; 
import { Helmet } from 'react-helmet';

function Demonstration() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Sources:<br /><br />
		<var>Die Leibniz-Handschriften der K&#246;niglichen &#214;ffentlichen Bibliothek</var><br />
        Eduard Bodemann (ed)<br />
        p 74<br /><br />
        <var>S&#228;mtliche schriften und briefe series VI volume 4</var><br />
        Deutsche Akademie der Wissenschaften (ed)<br />
        p 1353<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 1677 (?)<br /><br />
		Translated from the Latin<br /><br />{isMobile ? '' : <hr className="centered-hr" />}
		<Link to={pdf} target="_blank">View this translation in PDF format (6k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Demonstration that God understands all possibles</title>
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: DEMONSTRATION THAT GOD UNDERSTANDS ALL POSSIBLES</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
		        [<b>A VI 4, 1353</b>]<br /><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;That God understands not only everything that is and will be, but also all possibles, can be shown in a remarkable way as follows.<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                Let there be some liquid that is enclosed and trying to flow out. It is obvious that it will always attempt to do so via all possible routes but that it succeeds only following the easiest route of all those possible. And from this it is obvious that all routes are attempted, since as each route is presented it immediately goes for the most convenient. But it could not choose the most convenient unless all routes were attempted at the same moment, for the most convenient is not determined except by the comparison of all. Moreover, it is agreed that nature is the work of God, and that when nature attempts something it does not come to pass except by the will of God, for bodies are not by themselves the cause of their own actions since they are not even the same for more than a moment.

		        <br /><br /><br />
		        &#169; Lloyd Strickland 2005. Revised 2022
            <br /><br />
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Demonstration;
