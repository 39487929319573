import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/caroline1706.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>S&#228;mtliche schriften und briefe series I, volume 25</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 599-600<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 13 February 1706<br /><br />
		Translated from the French<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (74k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Princess Caroline (13 February 1706)</title>
            <meta name="description" content="An English translation of a LETTER TO PRINCESS CAROLINE by Gottfried Wilhelm Leibniz, from 13 February 1706" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO PRINCESS CAROLINE</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A I 25, 599</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Madam<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You will be surprised that I say, Protestant as I am, that a kind of purification, or if you will, of purgatory seems necessary for the perfection of souls. But what prevents this purgatory being a pleasure in certain blessed souls? The Greeks and Romans took daily baths, which had a great deal of appeal. Afterwards they rubbed themselves with oil. If some good angel gave you a similar service, Madam, would you be concerned?<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Virgil, an author not unknown to you, supposes there to be two kinds of purification, one by water, the other by fire:<br /><br />

        <center><var>Infectum eluitur scelus aut exuritur igni</var>.<a name="1" href="#note1"><sup>1</sup></a></center>
        <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The ancient Christian poet Prudentius compared the purgatory initially imagined by the Christians of the East in his day with what the alchemists call a bain-marie,<a name="2" href="#note2"><sup>2</sup></a> which is to mix water and fire in a pleasing way.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Philippe II, the powerful monarch of Spain, had a terrible dread of the fire of purgatory when he was at the point of death. We learn of his final hours from the account given by his confessor. This confessor put his mind at ease by quoting from serious authors who maintained that souls were delighted with their purification, and would not want to get out of it.<a name="3" href="#note3"><sup>3</sup></a> If he had known that purgatory could be a pleasure, he would have been even better placed to console this bigoted prince. But to speak the truth, I think this would have been a white lie, and a pious fraud in relation to this prince, who was what bigots often are, that is, malicious and merciless. A fire of the third degree was needed for a soul whose tyranny had caused so much harm, especially in the Netherlands. In the event he was saved, he would have been put not in a bain-marie but in a vessel made of embers.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;But those who do only good to humankind, like you, Madam, who could not cause harm except at most by too much kindness, I believe that you will be purified like angel water placed in the sun,<a name="4" href="#note4"><sup>4</sup></a> or rather (to speak of an object capable of feeling pleasure) like a future Sultan that the white eunuchs guide to the great Lord.<br /><br />
        [<b>A I 25, 600</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The good angels to whom you will be entrusted, albeit a long time from now, will forgive me this comparison with white eunuchs, since it is acknowledged that they do not reproduce their kind, which needs no recruits.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I am with devotion etc.<br /><br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Madam, to Your Royal Highness<br />
        <span style={{float: 'right'}}>Your very submissive and very obedient servant</span><br />
        <span style={{float: 'right'}}>Leibniz</span><br />
        Hanover, 13 February 1706
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> &quot;the stain of guilt is washed away or burned up by fire.&quot; Vigil, <var>Aeneid</var> VI.742.<br />
                <a name="note2" href="#2">2.</a> Aurelius Prudentius Clemens, <var>Hamartigenia</var>, 961-966.<br />
                <a name="note3" href="#3">3.</a> Leibniz's source for the anecdote about Philip II (1527-1588) is uncertain. In Cerverra Turrianus' lengthy account of Philip II's death, <var>De felici excessu Philippi II. Hispaniarum Regis, Libri tres</var> (Freiburg, 1609), it is claimed that on his deathbed Philip kept re-reading a work by Louis of Granada (1505-1588) and drew comfort from it. Elsewhere in his account, Turrianus reports that Philip was aware of the necessity of purgation (55ff), that he willingly submitted himself to God's justice (58ff), and that on his deathbed he was greatly consoled by reading passages from a work by the mystical writer Ludovicus Blosius (69ff).<br />
                <a name="note4" href="#4">4.</a> Angel water [l'eau d'anges] is a seventeenth century perfume made from benzoin &quot;tears&quot;, Styrax resin, nutmeg and cinnamon, mixed with rose petals.<br /><br /><br />
                &#169; Lloyd Strickland 2016
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
