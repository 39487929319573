import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/bourguet1712.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
    	<var>Die philophischen schriften von Gottfried Wilheim Leibniz, vol. III</var><br />
		C. I. Gerhardt (ed)<br />
		pp 558-559<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: October? 1712 <br /><br />
		Translated from the French<br /><br />
        Note - the portion of this text that is included in my <var>Shorter Leibniz Texts</var> is not included here.<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (76k)</Link><br /><br />
        <Link to="/slt">Back to <var>Shorter Leibniz Texts</var></Link><br /><br />
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Louis Bourguet (October? 1712)</title>
            <meta name="description" content="An English translation of a LETTER TO LOUIS BOURGUET by Gottfried Wilhelm Leibniz, from October? 1712" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO LOUIS BOURGUET</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>G III p558</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;After<a name="1" href="#note1"><sup>1</sup></a> having seen your fine productions on literary origins, I am surprised to see that you are also as profound on philosophy as your letter, which Mr Herman has made me keep, suggests.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I am delighted that my <var>Theodicy</var> has the approval of people like you, Sir, and I would like to be sufficiently able to meet all the difficulties that might remain for them. I am rather sorry, just as much as you, that Mr Bayle did not read my work; I would have assuredly profited from it.<br /><br />

                .....<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;By the continued creation which I permit in the conservation, I mean only the continuation of the first dependence, and indeed creatures are always equally dependent on God.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You ask, Sir, what becomes of those animals that do not reach the [level of] development at which others of their species arrive. I answer that these are worms or other invisible animals which nonetheless have all their order and their purpose, and even their propagation [<b>G III p559</b>] as do the visible animals; there are also many seeds which do not mature into visible plants, yet they will have their effect.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;When animals subsist after their death, they subsist in new animals, reduced to a great smallness. However it is always the same soul, although in a body well arranged, enveloped, transformed.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You have assumed quite correctly, Sir, that my monads are not atoms of matter, but simple substances, endowed with power (I add of perception and of appetite), the bodies of which are only phenomena.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I will always be pleased to receive your reflections: however, I have still not seen your reply to my Latin letter. Nevertheless, I am sincerely etc.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTE:<br /><br />
                <a name="note1" href="#1">1.</a> Leibniz wrote on his copy: 'Response to Mr Bourguet's letter of 20 October 1712.'<br />
                <br /><br />
                &#169; Lloyd Strickland 2003
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
