import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/origen.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
		Shelfmark LBr 40 Bl. 18r<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 1702<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (178k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Notes on Bayle's "Dictionary", article 'Origen'</title>
            <meta name="description" content="An English translation of NOTES ON BAYLE'S 'DICTIONARY', ARTICLE 'ORIGEN' by Gottfried Wilhelm Leibniz, from 1702" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: NOTES ON BAYLE'S <var>DICTIONARY</var>, ARTICLE 'ORIGEN'</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>LBr 40 Bl. 18r</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mr Bayle, in the article &quot;Origen&quot;,<a name="1" href="#note1"><sup>1</sup></a> relates some excellent arguments from the <var>Parrhasiana</var>. &quot;The physical and moral evils of the human race are of a duration which is so short in comparison with eternity that they cannot prevent God being considered benevolent and a lover of virtue. There is infinitely less proportion between the time that this earth has to last than there is between a minute and a hundred million years. Among men, those who treat a child with some illness and cure it with a bitter medicine only laugh at the moans that they cause by this unpleasantness since they know that in a short time the child will no longer feel it, and that the medicine will do it good. There is infinitely more proportion between God and the most enlightened men than there is between them and the simplest children. Thus we cannot reasonably be surprised that God considers the evils we suffer as almost nothing, he alone who has a complete idea of eternity and who looks upon the beginning and end of our sufferings as infinitely closer together than the beginning and end of one minute. We ought to reason likewise about vices and virtuous actions, which with regard to God do not last long, and which ultimately change nothing in the universe. If a clockmaker made a clock which, once assembled, functions well for a whole year, except in one or two seconds, which would not be uniform when it began working, could one say that this craftsman would not pride himself on his skillfulness or on the precision in his works?'<a name="2" href="#note2"><sup>2</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mr Bayle replies to the Origenist on behalf of the Manichean that God's goodness must be perfect, and vice and misery, for a time which can be quite long, is contrary to him.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For my part, I think that [the author of] <var>Parrhasiana</var> was right to point out that evil is not so great as is thought. But Mr Bayle is also right to be surprised that there is any, however small or great the amount might be. What must end the difficulty is the fact that<a name="3" href="#note3"><sup>3</sup></a> this small amount of evil actually increases the good.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This maxim, <var>non facienda sunt mala, ut eveniant bona</var><a name="4" href="#note4"><sup>4</sup></a> requires limitation. The fact is that men are usually lacking in application. For example, someone believes that he would do right if he committed a poisoning to establish the true religion. But that is not allowed. The consequences of the permission of the poisoning are greater than the good he intends, which is not certain, and even if it was, it is not certain that it could not be obtained by a better way.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Father Doucin thinks that Origen is attributed with the view that creatures would ultimately lose themselves in the ocean of the divinity.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(But Mr Petersen<a name="5" href="#note5"><sup>5</sup></a> is of a different view with regard to Origen.)
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> Origen, one of the early Church Fathers, argued that all evils were medicinal, i.e. ultimately for the good of the sufferer, and for that reason were not in vain. See, for example, Origen, <var>Contra Celsus</var> VI.56. <br />
                <a name="note2" href="#2">2.</a> This passage is a quotation from Jean Le Clerc's <var>Parrhasiana</var> (Amsterdam, 1699), pp307-12. It is quoted by Bayle on p542 of his <var>Dictionary</var>, article 'Origen' note E. An English translation of Le Clerc's book was published as <var>Parrhasiana: or, Thoughts upon Several Subjects</var> (London, 1700). The passage quoted by Bayle and Leibniz is on pp222-3.<br />
                <a name="note3" href="#3">3.</a> that | evil is in effect a good by its consequences. | <var>deleted</var>.<br />
                <a name="note4" href="#4">4.</a> 'evils are not to be done in order to bring about good'.<br />
                <a name="note5" href="#5">5.</a> Johann Wilhelm Petersen (1649-1727), one-time superintendent of the churches of L&#252;neburg.
                 <br /><br /><br />
                &#169; Lloyd Strickland 2007. Revised 2022
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
