import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/boisot.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>S&#228;mtliche schriften und briefe series I, volume 14</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		p 167<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 10 May 1697<br /><br />
		Translated from the French<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (88k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Jean-Jacques Boisot (10 May 1697)</title>
            <meta name="description" content="An English translation of a LETTER TO JEAN-JACQUES BOISOT by Gottfried Wilhelm Leibniz, from 10 May 1697" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO JEAN-JACQUES BOISOT</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A I 14, 167</b>]<br /><br />


                To Monsieur le President Boisot at Besançon

                <div align="right">Hanover, 10 May 1697</div><br />

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I had the honour of receiving the catalogue of pieces you so generously offered to communicate to me to enrich my <var>Diplomatic Code</var>.<a name="1" href="#note1"><sup>1</sup></a> You not only carrying out, Sir, the wishes of the late Abb&#233; de St. Vincent, your brother, but you are also showing that you are driven by the same spirit, by contributing to the public good, and to the instruction of posterity. It would be desirable that these noble sentiments were a little more common, but since that cannot be, and the laws of nature do not allow diamonds to be as common as pebbles, there will be reason to prize all the more those who rise above the inclinations of what is deservedly called the common folk. I hope one day to be able to inform the public what they owe you.<a name="2" href="#note2"><sup>2</sup></a> However, I give you my thanks in advance, while waiting for me to be able to make my choice by more closely comparing this catalogue with what I have or can have. And I am sincerely and with obligation etc.
                        <br /><br /><br />
                        <hr className='rightcol-hr' />
                        <br /><br />
                        NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> Leibniz is referring to a now-lost catalogue of manuscripts pertaining to his historical research.<br />
                        <a name="note2" href="#2">2.</a> See the first page of Leibniz's preface in G. W. Leibniz, <var>Mantissa codicis juris gentium diplomatici</var> (Hanover: Freytag, 1700).<br /><br /><br />
                        &#169; Lloyd Strickland 2022
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
