import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/hartsoeker1710.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>Die philophischen schriften von Gottfried Wilheim Leibniz, vol. III</var><br />
		C. I. Gerhardt (ed)<br />
		pp 500-501<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: July/August 1710<br /><br />
		Translated from the French<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (11k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Nikolaus Hartsoeker (July/August 1710)</title>
            <meta name="description" content="An English translation of A LETTER TO NIKOLAUS HARTSOEKER by Gottfried Wilhelm Leibniz, from July/August 1710" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO NIKOLAUS HARTSOEKER</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>G III p500</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I published before 1672 a small theory of motion with a physical hypothesis, in which I explained the source of the consistency of bodies by the conspiring motion [of the vortex].<a name="1" href="#note1"><sup>1</sup></a> Mr Wallis, who at that time did the critical review in the <var>Transactions of England</var>, remarked that a great young English scholar, called Neilius, who had died a little while before, had been of the same opinion. Thus you see that it was not at all drawn from Father Malebranche, who only published his book some years after. This principle of the connection is obvious in experiments on the magnet, in which the metal filings, which are in themselves just like <var>arena sine calce</var>,<a name="2" href="#note2"><sup>2</sup></a> are bound by the motion of the magnetic matter.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;As for your objection, Sir, it is obvious that body C cannot remove from bodies A B C the part D E, without disrupting the conspiring motion which binds this part with the whole. But if the parts were at rest, nothing would prevent this detachment.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The great constancy of nature, far from permitting atoms, overthrows them. She acts in the small parts as she does in the large, it is all as it is here: if we had eyes sufficiently penetrating for that, we would see there that even the small parts can crumple and break, and there is nothing there which could be the cause of an infinite resistance. But nevertheless the system of things is sufficiently well formed to preserve certain natures, and to not easily permit certain destructions by these crude ways that we can employ, and which would not be able to have enough of an effect on the interior fissures.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;There is no need to explain the divisibility of matter, because naturally, if nothing prevents it, one part, distinct from another, can be separated from it; we must therefore look for a reason why such a thing is prevented; but to establish it as original or primitive in certain parts of matter is to resort either to miracle or an imaginary occult quality.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I do not have the slightest hope of succeeding in the transmutation of [<b>G III p501</b>] metals and I do not know of any experiment that confirms it. But in order to say absolutely that it is impossible, it would be necessary to have proofs of it.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> <var>New Physical Hypothesis</var>, <var>Theory of Abstract Motion</var>, 1671.<br />
                <a name="note2" href="#2">2.</a> 'sand without lime'.<br /><br /><br />
                &#169; Lloyd Strickland 2004
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
