import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/liebknecht.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>Protogaea</var><br />
		Jean-Marie Barrande (ed)<br />
		p 208<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 30 January 1711<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (93k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Johann Georg Liebknecht (30 January 1711)</title>
            <meta name="description" content="An English translation of a LETTER TO JOHANN GEORG LIEBKNECHT by Gottfried Wilhelm Leibniz, from 30 January 1711" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO JOHANN GEORG LIEBKNECHT<a name="1" href="#note1"><small><sup>1</sup></small></a></h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>PR p208</b>]<br /><br />
                <div align="center">Regarding a piece of petrified wood</div><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I owe you many thanks for sending me that elegant sketch of a piece of petrified or ironified wood; and any more of this kind which will follow by your favour. If learned and inquisitive men from different countries will compare their work, we will know the surface of our globe a little better. I regret that Steno's thoughts on this subject, of which he gave an indication in his little book <var>De solido intra solidum</var>,<a name="2" href="#note2"><sup>2</sup></a> have been forgotten. I do not know if you have seen my study entitled <var>Protogaea</var>, which was published a while ago in the <var>Acta Eruditorum</var>.<a name="3" href="#note3"><sup>3</sup></a> In that study, I supposed that our globe had been exposed to fire earlier than water; and that in primordial times it was submerged in water, at the time when the spirit of the lord moved over the waters. Therefore the aforementioned tree trunk, which you mention, hardened later on, and the juices filled with mineral penetrated its trunk. For the rest, farewell and think well of me. Hanover, 30 January 1711.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> 1679-1749. Professor of theology and mathematics at the university of Giesen.<br />
                <a name="note2" href="#2">2.</a> Nicholas Steno, <var>De solido intra solidum naturaliter contento dissertationis prodromus</var> [Forerunner of a dissertation on a solid naturally enclosed within a solid], 1669.<br />
                <a name="note3" href="#3">3.</a> G. W. Leibniz, &quot;Protogaea,&quot; <var>Acta eruditorum</var> (Jan. 1693), pp. 40-42. English translation available <Link to="/protogaeaarticle">here</Link>.
                <br /><br /><br />
                &#169; Lloyd Strickland 2005<br />
                With gratitude to John Thorley for advice and suggestions
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
