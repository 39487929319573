import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/choosingthebest.pdf'; 
import { Helmet } from 'react-helmet';

function ChoosingBest() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}> 
        Source:<br /><br />
		    <var>S&#228;mtliche schriften und briefe series VI volume 4</var><br />
        Deutsche Akademie der Wissenschaften (ed)<br />
        pp 1351-1352<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        Date: 1677?<br /><br />
        Translated from the Latin<br /><br />{isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (72k)</Link><br /><br />
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>On the Necessity of Choosing the Best</title>
            <meta name="description" content="An English translation of ON THE NECESSITY OF CHOOSING THE BEST by Gottfried Wilhelm Leibniz, from 1677?" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: ON THE NECESSITY OF CHOOSING THE BEST</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                  <br />
                  [<b>A VI 4, 1351</b>]<br /><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Suppose three points are given in order to form a triangle: I say that a wise person (if there is no special reason for doing otherwise) will form an equilateral triangle, for in this way all the points are treated in the same way. And the <var>species</var> of equilateral triangles is the <var>lowest</var>, [<b>A VI 4, 1352</b>] that is, all equilateral triangles are similar to each other. If the wise person's necessity of choosing the best destroyed freedom, it would follow that God does not act freely either when out of many things he chooses the best. The essences of things are like numbers. Just as two numbers are not equal to each other, so two essences are not equally perfect.

                  <br /><br /><br />
                  &#169; Lloyd Strickland 2004. Revised 2022
                  <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default ChoosingBest;