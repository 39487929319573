import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/walenburch.pdf'; 
import { Helmet } from 'react-helmet';

function Walenburch() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}> 
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series VI, volume 4</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 2471-2472<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: January - April 1677 (?)<br /><br />
		Translated from the Latin<br /><br />{isMobile ? '' : <hr className="centered-hr" />}
		<Link to={pdf} target="_blank">View this translation in PDF format (182k)</Link><br /><br />
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Notes on the Walenburchs’ "A General Treatise on the Controversies of Faith"</title>
            <meta name="description" content="An English translation of NOTES ON THE WALENBURCHS' 'A GENERAL TREATISE ON THE CONTROVERSIES OF FAITH' by Gottfried Wilhelm Leibniz, from January - April 1677 (?)" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: NOTES ON THE WALENBURCHS' <var>A GENERAL TREATISE ON THE CONTROVERSIES OF FAITH</var></h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 <div id="note">Written on the same sheet as “On the Breaking of Ecclesiastical Communion” (see A VI 4, 2148) and various drafts of “On the Obligation to Believe” (see A VI 4, 2149–2155), this short piece features extracts from and occasional comments on Adriaan and Peter van Walenburch's <var>Tractatus generales de controversiis fidei</var> [A General Treatise on the Controversies of Faith],<a name="1" href="#note1"><sup>1</sup></a> with Leibniz defending the Protestant principle of <var>sola scriptura</var> [by scripture alone] by a thought experiment. Although the Walenburchs’ book is in excess of 1000 pages, most of Leibniz’s comments relate to material from the first eight, with the piece ending abruptly mid-sentence. Leibniz enclosed his comments within [x ... x], though in the following this has been changed to (+ ... +) in line with the Academy edition.</div><br /><br />
                [<b>A VI 4, 2471</b>]<br />

                <center>Excerpts from the Walenburchs book</center><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Treatise 1. &quot;Examination of the principles of the faith.&quot;<a name="2" href="#note2"><sup>2</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&quot;Protestants hold, by way of principle, that scripture alone is sufficient to prove the articles of faith.&quot;<a name="3" href="#note3"><sup>3</sup></a> (+ The question is whether, if there were no other book on the Christian religion besides holy scripture, anything could be known about it from anywhere else. Let us imagine that a book of holy scripture written in the Arabic language was thrown by a shipwreck onto an island in the African sea where people understand the Arabic language. The inhabitants take the book and throw it in a pile with the other spoils. At night, they burn everything by setting fire to it, with only the book remaining undamaged. Astonished at this, the inhabitants eagerly read the book, and marvelling at its teaching, they resolve to follow it. The question is whether from that alone, with moderate judgement and diligence, they could fashion for themselves the true religion sufficient for their salvation? To me this appears very probable. +)<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Walenburchs: &quot;The common principle of Romans and Protestants is that the doctrine of the Christians of the early centuries, as is found in the books of the holy fathers and the ancient councils, is uncorrupted, at least as regards the necessary articles.&quot;<a name="4" href="#note4"><sup>4</sup></a> (+ The fathers may have certain opinions in common with the Roman Church, but not therefore those [<b>A VI 4, 2472</b>] which you have in the Roman Church, because of course the Roman Church makes union difficult by its anathemas. +)<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Whether the articles of faith should, according to the opinion of the Protestants, be in scripture so that there is no need for inferences? The Walenburchs affirm this.<a name="5" href="#note5"><sup>5</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&quot;Unless Protestants can prove from holy scripture how many articles of faith there are, their principle is not sufficient. But they cannot do this, because they disagree among themselves on this matter.&quot;<a name="6" href="#note6"><sup>6</sup></a> I do not accept this argument. It is sufficient for someone to believe an article even if they do not know whether it is necessary.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&quot;How can they legitimately raise any controversy with the Catholics, not knowing whether they have withdrawn from them due to a necessary error?&quot;<a name="7" href="#note7"><sup>7</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Calvinists cannot show why the agreement between them and the papists is more fundamental than between them and the Lutherans.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Decree of the National Synod of Charenton in 1631. (Walenburchs page 3)<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;p. 4: &quot;The question between the Protestants and the Papists is whether the Church is an infallible [judge] of controversies, not only as regards the necessary articles, for it is said the Protestants concede this, and in so doing they hand over the entire cause to us, but whether it is an infallible judge because of the gift of the Holy Spirit and the promise of Christ without any restriction to the articles whether they are necessary for all or not.&quot;<a name="8" href="#note8"><sup>8</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&quot;On the interpretation of scripture by scripture.&quot;<a name="9" href="#note9"><sup>9</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The second Helvetic confession, article 2: &quot;We recognize only that interpretation of scripture which agrees with the rule of faith and love&quot;<a name="10" href="#note10"><sup>10</sup></a> (+ the rule of faith and love, or the analogy of faith, which? +).<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If in a syllogism one proposition is of faith, and the other of reason, is it the case that the conclusion is theological?<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Musaeus admits that if the former proposition is of reason, it is absolutely necessary, that is, metaphysically certain<a name="11" href="#note11"><sup>11</sup></a> <a name="12" href="#note12"><sup>12</sup></a>

                        <br /><br /><br />
                        <hr className='rightcol-hr' />
                        <br /><br />
                        NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> Adriaan van Walenburch and Peter van Walenburch, <var>Tractatus generales de controversiis fidei</var> (Cologne: Johann Wilhelm Friessem, 1670).<br />
                        <a name="note2" href="#2">2.</a> Walenburch and Walenburch, <var>Tractatus generales de controversiis fidei</var>, 2.<br />
                        <a name="note3" href="#3">3.</a> Walenburch and Walenburch, <var>Tractatus generales de controversiis fidei</var>, 2: &quot;Protestants teach that the necessary articles are proved by scripture alone.&quot;<br />
                        <a name="note4" href="#4">4.</a> Walenburch and Walenburch, <var>Tractatus generales de controversiis fidei</var>, 14: &quot;On the common principle. The doctrine of the Christians of the first centuries, which is found in the books of the holy fathers and ancient councils, is uncorrupted, at least as regards the necessary articles.&quot;<br />
                        <a name="note5" href="#5">5.</a> Walenburch and Walenburch, <var>Tractatus generales de controversiis fidei</var>, 231: &quot;Therefore, the first principle of Protestants must be understood from their public confessions, and it should be noted that they teach in general that everything necessary for salvation is clearly and plainly contained in scripture, so much so that they do not need interpretation or inference.&quot;<br />
                        <a name="note6" href="#6">6.</a> This is Leibniz's summary of some of the argument in Walenburch and Walenburch, <var>Tractatus generales de controversiis fidei</var>, 3.<br />
                        <a name="note7" href="#7">7.</a> Walenburch and Walenburch, <var>Tractatus generales de controversiis fidei</var>, 3.<br />
                        <a name="note8" href="#8">8.</a> Walenburch and Walenburch, <var>Tractatus generales de controversiis fidei</var>, 4: &quot;The question is not whether the Church is an infallible judge of controversies as regards the necessary articles, because Protestants concede this, and in so doing they hand over the entire cause to us. Therefore, the question is whether the Church is an infallible judge of controversies because of the gift of the Holy Spirit and the promise of Christ without any restriction to the articles whether they are necessary for all or not&quot;.<br />
                        <a name="note9" href="#9">9.</a> Walenburch and Walenburch, <var>Tractatus generales de controversiis fidei</var>, 7.<br />
                        <a name="note10" href="#10">10.</a> The second Helvetic confession was drawn up by Heinrich Bullinger (1504–1575) in 1566. The relevant passage is from chapter 2 (not article 2). It is quoted in Walenburch and Walenburch, <var>Tractatus generales de controversiis fidei</var>, 138: &quot;But we hold that the interpretation of the Scripture to be orthodox and genuine which is gleaned from the Scriptures themselves ... and which agree with the rule of faith and love, and contributes much to the glory of God and man's salvation.&quot;<br />
                        <a name="note11" href="#11">11.</a> The text ends here and is clearly unfinished.<br />
                        <a name="note12" href="#12">12.</a> Walenburch and Walenburch, <var>Tractatus generales de controversiis fidei</var>, 8: &quot;We do not deny that when in an argument one proposition is from scripture, the other from evident and necessary reason, then the special and proper principle of a theological conclusion is the proposition of scripture, from which the conclusion also has that which pertains to theology. On the other hand, against the Reformed, Musaeus holds with us that, in such an argument, the proposition of reason is the material principle, and the true cause of the conclusion.&quot; They are referring to Johann Musaeus, <var>De usu principiorum rationis et philosophiae in controversiis theologicis libri III</var> (Jena: Lobenstein, 1664), 444.<br />
                <br /><br /><br />
                        &#169; Lloyd Strickland 2023
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Walenburch;