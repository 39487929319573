import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/causes.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series VI volume 4</var><br />
        Deutsche Akademie der Wissenschaften (ed)<br />
        pp 1640-1641<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: March 1689 - March 1690 (?)<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (10k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>On the system of occasional causes</title>
            <meta name="description" content="An English translation of ON THE SYSTEM OF OCCASIONAL CAUSES by Gottfried Wilhelm Leibniz, from March 1689 - March 1690 (?)" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: ON THE SYSTEM OF OCCASIONAL CAUSES</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A VI 4, p1640</b>]<br /><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The system of occasional causes should be partly accepted and partly rejected. Each and every substance is the true and real cause of its own <var>immanent</var> actions and has the power to act, and although it is sustained by divine concurrence it cannot happen that it be constituted only passively, and that is true with regard to corporeal substances as well as incorporeal ones. But on the other hand, [<b>A VI 4, p1641</b>] each and every substance (God alone excepted) is only the occasional cause of its <var>transeunt</var> actions on another substance. Therefore the true <var>reason of the union between body and soul</var>, and the reason why one body adapts itself to the state of another body, is nothing other than the fact that different substances of the same system of the world are created from the beginning in such a way that they mutually conspire together through the laws of their own nature.
                <br /><br /><br />
                &#169; Lloyd Strickland 2007
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
