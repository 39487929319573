import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/possibles.pdf'; 
import { Helmet } from 'react-helmet';

function Possibles() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		    <var>S&#228;mtliche schriften und briefe series VI volume 4</var><br />
        Deutsche Akademie der Wissenschaften (ed)<br />
        pp 1352-1353<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		    Date: 1677<br /><br />
		    Translated from the Latin<br /><br />{isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (86k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>That Not All Possibles Attain Existence</title>
            <meta name="description" content="An English translation of THAT NOT ALL POSSIBLES ATTAIN EXISTENCE by Gottfried Wilhelm Leibniz, from 1677" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: THAT NOT ALL POSSIBLES ATTAIN EXISTENCE</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A VI 4, 1352</b>]<br /><br />
                <center>That not all possibles attain existence, or, that there are certain possibles which neither exist nor have existed nor will exist</center><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I call &quot;possible&quot; that which can be supposed or understood without contradiction, for example, that in the whole world there are only little balls, that is, round bodies; in itself, this contains nothing that includes a contradiction. From this I now prove that certain of this kind of possibles have never existed or will ever exist. For if we assume that all things are little balls, it is impossible that they ever cease to exist by the forces of nature, and therefore all other shapes of atoms have neither existed naturally (otherwise little balls would never have been made from them) nor will exist. On the other hand, if any other shape is assumed, it is impossible that the shape of the little balls ever existed or will exist. So either way, it will be impossible for something from among the things possibles in themselves to exist on account of other things posited or on account of the state of the universe. And once we admit this, we have a God not such as Descartes and Spinoza conceive of him, but such as Christians teach.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If it can be demonstrated that no part of matter can be understood as separated from any other, yet that there are certain atoms, the same thing is considered clearly demonstrated. But even if we assume that all things are again subdivided, since subdivision can be established in various ways, then certainly not all possibles occur.
                <br /><br /><br />
                    
                    &#169; Lloyd Strickland 2017. Revised 2022
                    <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Possibles;
