import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/sciences.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series IV volume 5</var><br />
        Deutsche Akademie der Wissenschaften (ed)<br />
        pp 592-593<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: c. 1693<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (119k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>On Two Systems of the Sciences</title>
            <meta name="description" content="An English translation of ON TWO SYSTEMS OF THE SCIENCES by Gottfried Wilhelm Leibniz, from c. 1693" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: ON TWO SYSTEMS OF THE SCIENCES</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A IV 5, 592</b>]<br /><br />
                
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a name="1" href="#note1"><sup>1</sup></a> [All books are] assigned or miscellaneous. Assigned are of universal or particular matters. Universal matters are general or specific. General ones are <var>introductory</var> or treatises. Treatises are either <var>philological</var>, bearing on verbal matters, or real, bearing on <var>philosophical</var> matters. Specific treatises show various kinds of goods. And these are either <var>theological</var>, which aim at eternal happiness, or human, which teach how to attain the goods of this life. Human ones<a name="2" href="#note2"><sup>2</sup></a> are either <var>pragmatic</var>, concerning temporal happiness and the goods of the mind, or operative, concerning lower goods. Pragmatic ones are<a name="3" href="#note3"><sup></sup></a> <var>juridical-practical</var>, to which ethics and politics are connected. Operative ones concern the goods of the body, namely <var>medical</var>-physical, or they concern external conveniences, <var>mathematical-technical</var>. [Books on] particular matters concern times (<var>historical</var>) or places (<var>cosmographical</var>).<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Miscellaneous</var> ones include various things from different subjects at the same time.<a name="4" href="#note4"><sup>4</sup></a><br /><br />

[<b>A IV 5, 593</b>]<br /><br />

&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Another partition can be established, not according to the purpose but according to the object, without regard to our use; and thus far knowledge is either miscellaneous or assigned. Assigned knowledge<a name="5" href="#note5"><sup>5</sup></a> is of notions or truths (for inferences are also truths). Notions are intelligible or imaginable, and truths are either necessary or contingent, universal or particular, and are nothing other than connections of notions. Notions are either incomplete (of adjuncts) or complete (of subjects). Notions of adjuncts<a name="6" href="#note6"><sup>6</sup></a> are primitive or derivative, or mixed; primitive either absolutely, and these are absolute attributes, or relative to us, and these are sensible qualities.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> │ All books are general or specific. Specific are miscellaneous or assigned. Assigned are real or philological. Real are <var>theological</var>, which aim at │ <var>deleted</var>.<br />
                <a name="note2" href="#2">2.</a> ones │ treat of temporal happiness in general, and indeed either in society or of individual goods │ <var>deleted</var>.<br />
                <a name="note3" href="#3">3.</a> are │ <var>juridical-political</var> │ <var>deleted</var>.<br />
                <a name="note4" href="#4">4.</a> time. │ ¶ <var>Philological</var> comprises grammar and lexicons, and the verbal part of rhetoric and poetry. ¶ <var>Philosophical</var> [comprises] didactics, logic, oratory, that is, the persuasive part of rhetoric, mnemonics, ontology. │ <var>deleted</var>.<br />
                <a name="note5" href="#5">5.</a> knowledge │ is of truths of fact or of reason, of contingent or necessary truths │ <var>deleted</var>.<br />
                <a name="note6" href="#6">6.</a> adjuncts │ are simple or composite │ <var>deleted</var>.
                        <br /><br /><br />
                        &#169; Lloyd Strickland 2024.
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
