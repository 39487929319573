import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/peace.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series IV volume 4</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 544-546<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: c. 1691<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (213k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>On Religious Peace</title>
            <meta name="description" content="An English translation of ON RELIGIOUS PEACE by Gottfried Wilhelm Leibniz, from c. 1691" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: ON RELIGIOUS PEACE</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A IV 4, 544</b>]<br /><br />
		        <center>On Religious<a name="1" href="#note1"><sup>1</sup></a> Peace</center><br />	
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;On<a name="2" href="#note2"><sup>2</sup></a> the good of unity and the evils of schism. The greatest commandment is that of love.<br />	
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;God imparted a special grace to his Church, of which no one outside of it can be an equal participant. Ordination and mission belong here.<br />	
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We must obey its leaders unless they prescribe something which is manifestly contrary to the commandment of God, and therefore they have at least a presumption.<br />	
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Immense evils have sprung from religious quarrels: hatred and distrust, deadly wars, the success of unbelievers, impiety, the most dreadful sects in England and Holland, libertinism, and contempt of all religion.<br /><br />

                [<b>A IV 4, 545</b>]<br /><br />	
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The exhortation to ecclesiastical unity can be taken both by authority and by arguments. From authority, divine and human. Divine authority is contained both in the written word of God and in the tradition of the Church. Human authority is both that of the holy fathers and also of other serious and pious learned men, from whom we have both words and deeds.<br />	
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Arguments are taken both from the good of unity and from the evils of schism. The goods of unity<a name="3" href="#note3"><sup>3</sup></a> (namely spiritual, for I do not touch on temporal goods) are both in the future life and in the present life. In the future life, eternal salvation and greater glory.<br />	
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In the present life, spiritual virtues and joys. Eternal salvation is acquired in the Church both with greater certainty and ease. With greater certainty on account of faith, with greater ease on account of practice. On account of faith, for the Church is infallible in those matters pertaining to salvation. When in doubt, it is safer to follow the authority of its leaders, and they themselves have a presumption in their favour, especially those appointed by God.<a name="4" href="#note4"><sup>4</sup></a> <a name="5" href="#note5"><sup>5</sup></a><br />	
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Salvation is more easily acquired in the Church, both because there is a greater abundance of grace therein and thus a path &quot;from strength to strength&quot;,<a name="6" href="#note6"><sup>6</sup></a> about which more will be said later, and because the remission of sins is more easily obtained therein, for God ordained the sacrament of penance to heal the evils of the soul, without which remission can at least be obtained through contrition. But the difficulty of contrition is greater, as the same grace is granted to those who are seriously penitent when the sacrament is added.<br />	
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A greater measure of future glory is obtained in the Church, because there are greater virtues therein, about which more will now be said.<br />	
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In the present life, there is greater perfection in the Church, and this can generally be proved by authorities, by examples, and by arguments. By arguments from the governance of the Church, perfection in particular consists both in the development of virtues and in spiritual joy. Here I touch only on the divine virtues, faith and charity, under which I now include hope; it is [<b>A IV 4, 546</b>] about the perfection of the intellect and the will; it is the perfection of the intellect because God teaches us the truth through the Church.<br />	
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;There is perfection of the will insofar as we see in the Church a greater fervour for charity and other virtues pertaining to the perfection of charity.<br />	
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;There are spiritual joys as long as we assent to the truth, feel ourselves progress in piety, delight in divine contemplation, and generally whenever we feel the progress of perfection.<br />	
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The evils of schism are opposed to these, but I think I should prove that they are not divided separately, but are unfailingly opposed to their good counterparts, so that &quot;contraries are more clearly defined when placed side by side&quot;.<a name="7" href="#note7"><sup>7</sup></a> Among these evils it will be useful to discuss civil wars as spiritual evils, because they do not take place without great loss of souls and afford occasion for many crimes.<br />	
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Once the excellence of unity has been put forth, what the true church is should be explained. Its most essential feature is the unity of the hierarchy, as it is one state even though times have changed, but the unity of the hierarchy consists of succession of rulers.<br />	
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;God, however, has promised perpetual duration and assistance to this state. This well-known promise would be undermined if a fundamental error could be taught. Others may serve for illustration, but see Bellarmine and Musaeus on these subjects.<a name="8" href="#note8"><sup>8</sup></a>
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> In the margin here, Leibniz wrote: &quot;Barclay's <var>Paraenesis</var>&quot;. The reference is to John Barclay, <var>Paraenesis ad sectarios huius temporis de verae ecclesiae fide ac religione, libri II</var> (Cologne: Kinckium, 1625).<br />
                <a name="note2" href="#2">2.</a> In the margin here, Leibniz wrote: &quot;Add Erasmus on the lovable ecclesiae concordia&quot;. The reference is to Erasmus of Rotterdam, <var>Liber de sarcienda Ecclesiae Concordia</var> (Basel, 1533).<br />
                <a name="note3" href="#3">3.</a> In the margin here, Leibniz wrote: &quot;The goods of unity can generally be proven from the assistance promised by the divine spirit; the stronger prayers of the Church.&quot;<br />
                <a name="note4" href="#4">4.</a> God. │ God has promised the Church the presence and assistance of his spirit. The prayers of the church are stronger than those of individuals. │ <var>deleted</var>.<br />
                <a name="note5" href="#5">5.</a> In the margin here, Leibniz wrote: &quot;The necessity of authority: simple folk can have no other criterion of truth.&quot;<br />
                <a name="note6" href="#6">6.</a> Leibniz here quotes Psalm 83.8.<br />
                <a name="note7" href="#7">7.</a> Leibniz here takes over the words from Aquinas, <var>Summa theologiae</var> 1a2ae, q48, a3.<br />
                <a name="note8" href="#8">8.</a> Robert Bellarmine, <var>Disputationum roberti bellarmini politiani e societate jesu, s.r.e. cardinalis, de controversiis christianae fidei adversus huius temporis haereticos</var> (Lyon: Pillehotte, 1609); Johannes Musaeus, <var>Tractatus de ecclesia, quo duae eius antehac habitae disputationes: una de natura &amp; definitione ecclesiae, altera de eiusdem distinctione in universalem & particulares; uberius diducuntur &amp; ab adversariorum, cumprimis: Viti Erbermanni, obiectionibus & exceptionibus vindicantur</var> (Jena, 1671).

                <br /><br /><br />
                        &#169; Lloyd Strickland 2017. Revised 2022<br />
                With thanks to Serena Cammoranesi for advice and suggestions
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
