import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/leclerc.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
		Shelfmark LH 1, 20 Bl. 133r<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 1708<br /><br />
		Translated from the French<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (105k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Jean Le Clerc (?) (early 1708)</title>
            <meta name="description" content="An English translation of a letter from GOTTFRIED WILHELM LEIBNIZ TO JEAN LE CLERC (?), from early 1708" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO JEAN LE CLERC</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>LH 1, 20 Bl. 133r</b>]<br /><br />		

                I have learned that a Doctor of the Sorbonne whom I do not have the honour of knowing attacks me in a short piece published in the 14th volume of the <var>Biblioth&#232;que Choisie</var>,<a name="1" href="#note1"><sup>1</sup></a> wherein this Doctor criticizes the <var>Dissertations Historiques</var>, the first volume of which appeared last year in Rotterdam.<a name="2" href="#note2"><sup>2</sup></a> The learned author of these dissertations shared with me the manuscript of the first volume, which discusses the Unitarians; I wrote him a letter about this, which he did me the honour of attaching to his work.<a name="3" href="#note3"><sup>3</sup></a> I read the two other dissertations only in the printed work, and I did not even know that they would appear with the first. I find in them a number of learned and useful remarks and am surprised that the critic talks about them with contempt. But I admit to having a better opinion of the Jesuits and of the Missionaries in the East than this doctor dissertator seems to have of them, and I have even shown this in some previously published works. Yet I do not claim that my friends follow my views. Judgements should be even more free than wills.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;But here is what I am accused of. The Doctor of the Sorbonne claims that Mr Bull, the famous English author, in his defence of the Council of Nicaea,<a name="4" href="#note4"><sup>4</sup></a> reduces the dogma of the Trinity to saying merely that God has wisdom and charity, and that among these three things there is <var>nonnulla distinctio</var>.<a name="5" href="#note5"><sup>5</sup></a> On pp. 61-62 the author of the dissertation approves Mr Bull's book,<a name="6" href="#note6"><sup>6</sup></a> and in my letter I approve almost all the parts of the dissertation with praise. Therefore I approve this view about Mr. Bull.<a name="7" href="#note7"><sup>7</sup></a> This seems to be a tirade that goes a little too far in its conclusions. Notwithstanding this tirade, it is possible to doubt the view reached on Mr Bull, on the dissertation's author, and on myself. In encouraging the author to publish his dissertation, I did not embrace all his views, and this author only admires the justification of the Council of Nicaea in Mr Bull's book; if this English doctor has views of the sort spoken of in this Critique, I wash my hands of them. While I doubt that they are to be found there, this is something I do not have the time to examine right now.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I will use this opportunity to correct some mistakes and obscurities in my letter. When I say that the conversion of the Kingdom of Himyarites by Gregentius has all the air of a pious fable,<a name="8" href="#note8"><sup>8</sup></a> I am not denying the conversion of the Himyarites but the conversion attributed to Gregentius' dispute with a Jew, accompanied by miracles; there is an old account of this in Greek, which I think is fictitious.<a name="9" href="#note9"><sup>9</sup></a> In the middle of p. 180, there is &quot;tous les jours&quot; but it would be better to put &quot;tousjours&quot;.
                        <br /><br /><br />
                        <hr className='rightcol-hr' />
                        <br /><br />NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> Leibniz is referring here to the anonymously-authored article &quot;Sentiments d'un Docteur de Sorbonne, sur un Libelle intitul&#233;, DISSERTATIONS HISTORIQUES SUR DIVERS SUJETS. A Rotterdam chez Reinier Leers, 1707,&quot; <var>Biblioth&#232;que choisie, pour servir de suite &#224; la Biblioth&#232;que universelle</var> 14 (1707), 332-347. Jean Le Clerc, the likely recipient of Leibniz's letter, was the editor of this journal.<br />
                        <a name="note2" href="#2">2.</a> Mathurin Veyssiere de La Croze, <var>Dissertations historiques sur divers sujets</var> (Rotterdam, 1707). La Croze (1661-1739) was a French Benedictine historian and orientalist and later a Protestant convert.<br />
                        <a name="note3" href="#3">3.</a> In 1706, La Croze composed an essay entitled &quot;Reflexions historiques et critiques sur le Mahometisme, &amp; sur le Socinianisme&quot; and sent it to Leibniz. Leibniz gave his thoughts on it in a letter to La Croze of 2 December 1706. La Croze subsequently published Leibniz's letter (without Leibniz's knowledge or permission) along with three of his own essays in <var>Dissertations historiques sur divers sujets</var>. The book contained &quot;Reflexions historiques et critiques sur le Mahometisme, &amp; sur le Socinianisme&quot; (1-163), Leibniz's letter (164-181), &quot;Examen abreg&#233; du nouveau systeme du Pere Hardouin, sur sa critique des anciens auteurs&quot; (182-256), and &quot;Recherches historiques sur l'etat ancien et moderne de la religion chretienne dans les Indes&quot; (257-328). An English translation of Leibniz's letter can be found in LGR 337-344.<br />
                <a name="note4" href="#4">4.</a> George Bull, <var>Defensio Fidei Nicaenae de aeterna divinitate filii dei, ex scriptis catholic doctorum qui intra tria ecclesiae Christianae secula floruerunt</var> (Oxford, 1685).<br />
                <a name="note5" href="#5">5.</a> &quot;a certain distinction&quot;.<br />
                <a name="note6" href="#6">6.</a> La Croze claimed that Bull &quot;has justified with much erudition the Council of Nicaea, against the accusations of Socinians&quot;. La Croze, <var>Dissertations historiques sur divers sujets</var>, 62.<br />
                <a name="note7" href="#7">7.</a> The anonymous author wrote in his review of La Croze's book: &quot;Since he [La Croze] makes profession on pp. 61-62 to adhere to the view of <var>Bull</var>, in his <var>Defensio Fidei Nicaenae</var>, the Trinity that he embraces is by no means that of Christians. The Jews and perhaps even the Mohammedans would make do with that without changing their mind or religion. For would they scruple to admit that God has wisdom and charity, and that among these three things there is <var>nonnulla distinctio</var>? <var>Bull</var> claims no more than that. I am much grieved that a man of spirit, as is Mr <var>Leibniz</var>, appears not to have any other God, nor any other Trinity, than these latterly mentioned, especially in the letter he addresses to this author, which the author took care to have printed at the end of his first Dissertation, because it [Leibniz's letter] is a kind of approval of it [La Croze's dissertation], almost in all its parts, with praise.&quot; See &quot;Sentiments d'un Docteur de Sorbonne&quot;, op. cit., 333.<br />
                <a name="note8" href="#8">8.</a> In his letter to la Croze, Leibniz had written &quot;I don't doubt that there were many Christians, but the conversion of the Kingdom of Himyarites, which must have been procured by Gregentius, has all the air of a pious fable.&quot; LGR 338-339.<br />
                <a name="note9" href="#9">9.</a> The conversion of the Himyarites was said to have occurred following a four-day disputation between Herbanus, a Jewish Himyarite, and Gregentius, Bishop of Tephra (Dhafar). The disputation ended when Jesus Christ appeared in the clouds and miraculously struck the unbelieving Jews with blindness, though their sight was later restored when they were baptized. This event is said to have resulted in five and a half million Jews converting to Christianity. For more information see <var>S. Patris nostri Gregentii Tephrensis Archiepiscopi Disputatio cum Herbano Judaeo</var> (Paris, 1586), which is sometimes attributed to Gregentius.
                <br /><br /><br />
                        &#169; Lloyd Strickland 2016
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
