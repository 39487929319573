import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/theses.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
		Shelfmark LH 4, 8 Bl. 100-101<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 1690s (?)<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (109k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>24 Metaphysical Theses</title>
            <meta name="description" content="An English translation of 24 METAPHYSICAL THESES by Gottfried Wilhelm Leibniz, from the 1690s (?)" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: 24 METAPHYSICAL THESES</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>LH 4, 8 Bl. 100r</b>]<br /><br />
                 <div style={{textAlign: "right"}}>About 80<a name="1" href="#note1"><sup>1</sup></a></div><br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. There is a <var>reason</var> in Nature why something exists rather than nothing. This is a consequence of that great principle that nothing happens without a reason, just as there must be a reason why this exists rather than another.<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. This reason must be in some <var>Real Being</var>, or cause. For a <var>cause</var> is nothing other than a real reason, and truths about possibilities and <var>necessities</var> (i.e. where the possibility of the opposite is denied) would not produce anything unless the possibilities were founded in an actually existing thing.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3. Now this Being must be necessary, otherwise a cause would in turn have to be sought outside it for why it exists rather than not, which is contrary to the hypothesis. Plainly, this Being is the ultimate reason for things, and it is usually referred to by the single word &quot;God&quot;.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4. There is, therefore, a cause on account of which existence prevails over non-existence, in other words, <var>the necessary Being is Existifying</var>.<a name="2" href="#note2"><sup>2</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5. But the cause which brings it about that something exists, or that possibility demands existence, also brings it about that every possible<a name="3" href="#note3"><sup>3</sup></a> has a tendency towards existence, since in general a reason for restricting this to certain possibles cannot be found.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6. So it can be said that <var>every possible is existence-seeking</var>,<a name="4" href="#note4"><sup>4</sup></a> as it is founded on an actually existing necessary being, without which there is no way a possible may attain actuality.<br /><br />
        [<b>LH 4, 8 Bl. 101v</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7. However, it does not follow from this that all possibles exist, but it would if all possibles were compossible.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8. But because some things are incompatible with others, it follows that certain possibles do not attain existence. Some possibles are incompatible with others not only with respect to the same time, but also universally, because future possibles are enfolded in present ones.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;9. Meanwhile, from the conflict of all possibles demanding existence this at least follows, that there exists that series of things through which the greatest amount exists, that is, the maximal series of all possibles.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10. Further, this series alone is determinate, just as of lines is the straight line, of angles the right angle, and of shapes the most capacious, namely the circle or sphere. And just as we see liquids naturally collect in spherical drops, so in the nature of the universe the most capacious series exists.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;11. Therefore what exists is the most perfect, since <var>perfection</var> is nothing other than quantity of reality.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;12. Further, perfection is not to be located in matter alone, that is, in something filling time and space, whose quantity would have been the same in any event, but in form or variety.<br /><br />
        [<b>LH 4, 8 Bl. 101r</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;13. From which it follows that matter is not everywhere alike, but rendered dissimilar by forms, otherwise there would not obtain as much variety as possible. Not to mention what I have demonstrated elsewhere, that otherwise no diverse phenomena would have existed.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;14. It also follows that that series has prevailed through which there arises the greatest amount of what is distinctly cogitable.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;15. Further, distinct cogitability<a name="5" href="#note5"><sup>5</sup></a> gives order to a thing and beauty to the thinker. For <var>order</var> is nothing other than a distinctive relation of many things, and confusion is when many things are present but there is no way of distinguishing one from another.<a name="6" href="#note6"><sup>6</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;16. This rules out atoms, and in general any bodies in which there is no reason for distinguishing one part from another.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;17. And it follows in general that the world is &#922;&#959;&#963;&#956;&#959;&#957;,<a name="7" href="#note7"><sup>7</sup></a> full and adorned, i.e. made in such a way that it fully satisfies an intelligent being.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;18. For an intelligent being, <var>pleasure</var> is nothing other than the perception of beauty, order, and perfection. And every pain contains something disordered, albeit relative to the percipient, since absolutely speaking all things are ordered.<br /><br />
        [<b>LH 4, 8 Bl. 100v</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;19. Consequently, when something in the series of things displeases us, that arises from a defect of our understanding. For it is not possible that every mind should understand all things distinctly, and the harmony in the whole cannot appear to those who observe only some parts rather than others.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;20. It is a consequence of this that justice is observed in the universe also, since <var>justice</var> is nothing other than order or perfection in respect to minds.<a name="8" href="#note8"><sup>8</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;21. And the greatest consideration is given to minds, because through them one obtains the greatest possible variety in the smallest possible space.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;22. And it can also be said that minds are the primary unities of the world and the closest likenesses of the first Being, since they distinctly perceive<a name="9" href="#note9"><sup>9</sup></a> necessary truths, that is, the reasons responsible for moving the first Being and forming the universe.<a name="10" href="#note10"><sup>10</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;23. Also, the first cause is of the highest <var>goodness</var>, for while it produces as much perfection as possible in things, it simultaneously bestows as much pleasure as possible upon minds, since <var>pleasure</var> consists in the perception of perfection.<a name="11" href="#note11"><sup>11</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;24. So much so that evils themselves serve a greater good, and since minds experience pains this must be necessary to advance to greater pleasures.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> This is written on the top right hand side of the first manuscript page. Its significance is unclear.<br />
                <a name="note2" href="#2">2.</a> &quot;Existificans&quot;. This is a word coined by Leibniz.<br />
                <a name="note3" href="#3">3.</a> possible ǀ (inasmuch as it is founded on a being necessarily existing in actuality) ǀ <var>deleted</var>.<br />
                <a name="note4" href="#4">4.</a> &quot;Existiturire&quot;. This is a word coined by Leibniz.<br />
                <a name="note5" href="#5">5.</a> cogitability ǀ , with respect to a thinker, gives ǀ <var>deleted</var>.<br />
                <a name="note6" href="#6">6.</a> another. ǀ &#182;  (16) From this it further follows that the world was made in such a way that ǀ <var>deleted</var>.<br />
                <a name="note7" href="#7">7.</a> &quot;a cosmos&quot;.<br />
                <a name="note8" href="#8">8.</a> than ǀ order in respect to minds ǀ <var>deleted</var>.<br />
                <a name="note9" href="#9">9.</a> perceive ǀ the causes which the first being ǀ <var>deleted</var>.<br />
                <a name="note10" href="#10">10.</a> universe. ǀ These reasons are necessary truths. ǀ <var>deleted</var>.<br />
                <a name="note11" href="#11">11.</a> Reading &quot;perfectionis&quot; in place of &quot;perceptionis&quot;.<br /><br /><br />
                &#169; Lloyd Strickland 2016. Revised 2022
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
