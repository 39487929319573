import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/nov1696.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>S&#228;mtliche schriften und briefe series I volume 13</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 89-90<br /><br />
	    {isMobile ? '' : <hr className="centered-hr" />}
		Date: 4/14 November 1696<br /><br />
		Translated from the French<br /><br />
		Note - the portion of this text that is included in my <var>Shorter Leibniz Texts</var> is not included here.<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (15k)</Link><br /><br />
        <Link to="/slt">Back to <var>Shorter Leibniz Texts</var></Link><br /><br />
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Electress Sophie (4/14 November 1696)</title>
            <meta name="description" content="An English translation of a LETTER TO ELECTRESS SOPHIE by Gottfried Wilhelm Leibniz, from 4/14 November 1696" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO ELECTRESS SOPHIE</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A I 13, p89</b>]<br /><br />
                <div align="right">Hanover, 4 November 1696</div><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Madam. I am infinitely delighted with the approval that one of the greatest princesses<a name="1" href="#note1"><sup>1</sup></a> gives to some meditations which Your Electoral Highness had been so good as to send to her.<a name="2" href="#note2"><sup>2</sup></a> That is better than the judgement of a number of doctors. I did insert some thoughts of this nature in the <var>Journal des Savants</var> of Paris last year,<a name="3" href="#note3"><sup>3</sup></a> and some people possessing great penetration have informed me that I may well have said the truth.<a name="4" href="#note4"><sup>4</sup></a> Even the late Mr Arnauld, although a leader in the field and also a defender of Cartesianism, admitted to having been struck by some of my arguments when I communicated with him through letters on these matters.<a name="5" href="#note5"><sup>5</sup></a> There have been clever Cartesians who have grumbled about the fact that I have attempted to re-establish the right for beasts to have souls, that I go so far as to grant a kind of duration to those souls, and that I even show that all bodies, far from being only simple extended masses, contain some vigour and life. But I have learned that the success of my other discoveries has [<b>A I 13, p90</b>] lessened the desire that some people had to make objections to me, since one is obliged to acknowledge that even in mathematics, which was Mr Descartes' strength, the method that I have proposed goes well beyond his, which is what the Marquis de l'Hospital has just recognized in a considerable work published recently.<a name="6" href="#note6"><sup>6</sup></a> Nevertheless, as I know how important it is to join the thoughts of some to those of others, I will always be delighted to benefit from the reflections and insights of enlightened and moderate people, of which there is no shortage in France.
        <br /><br />
                .....
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> Duchess Elisabeth Charlotte of Orl&#233;ans.<br />
                <a name="note2" href="#2">2.</a> Leibniz is referring to a letter written in German from the first half of October 1696 for Sophie and Elisabeth Charlotte (see A I 13, 46-51). An English translation of this letter can be found in my volume <var>Leibniz and the Two Sophies</var> (Toronto: CRRS, 2011), 134-140.<br />
                <a name="note3" href="#3">3.</a> 'New System of the Nature of Substances and their Communication, and of the Union which Exists between the Soul and the Body', <var>Journal des Savants</var>, June/July 1695, G IV, pp477-87. English translation in SLT 68-77.<br />
                <a name="note4" href="#4">4.</a> The Marquis de L'Hospital. See his letter to Leibniz from 3 September 1695, in A III 6, 489.<br />
                <a name="note5" href="#5">5.</a> Presumably a reference to a remark made by Arnauld in his letter to Leibniz of 28 September 1686 that he was 'especially struck by the argument that in every true affirmative proposition, necessary or contingent, universal or particular, the concept of the attribute is in a sense included in that of the subject: <var>praedicatum inest subjecto</var> [the predicate belongs to the subject].' G II, 64. English translation in H. T. Mason (ed), <var>The Leibniz-Arnauld Correspondence</var> (Manchester University Press: Manchester, 1967), 77.<br />
                <a name="note6" href="#6">6.</a> <var>Analyse des infiniment petits, pour l'intelligence des lignes courbes</var> (Paris, 1696).<br />
                <br /><br />
                &#169; Lloyd Strickland 2003
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
