import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/polite.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
		Shelfmark LH 34 Bl. 180<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: May 1712<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (96k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>What is it to be Polite?</title>
            <meta name="description" content="An English translation of WHAT IS IT TO BE POLITE? by Gottfried Wilhelm Leibniz, from May 1712" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: WHAT IS IT TO BE POLITE?</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>LH 34 Bl. 180r</b>]<br /><br />

                May 1712<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The strength and meaning of terms depend on the received usage.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;When it is said that a man is polite, it is thought that this is praising him.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;There is no likelihood of saying that a polite man ought to be a rascal.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Yet it is not impossible that he is, just as it is not impossible for an accomplished man to be mean and a beautiful woman to be shameless.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A good exterior is always an advantage, although it is not always accompanied by a good interior.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A good exterior means that one judges advantageously in advance the person who is endowed with it.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;And there is some reason<a name="1" href="#note1"><sup>1</sup></a> to hope that the one who appears reasonable in manners will also be reasonable in something further.<a name="2" href="#note2"><sup>2</sup></a><br /><br />

                [<b>LH 34 Bl. 180v</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;But I admit that this is only a slight clue, on which one is not permitted to base one’s trust.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If there was even excess and affectation in<a name="3" href="#note3"><sup>3</sup></a> manners,<a name="4" href="#note4"><sup>4</sup></a> one could suspect either that the person is concerning himself with trifles or that he is trying to deceive.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A reasonable man should try to be polite, but if he has any real talent he should make it known that he is not preoccupied by manners alone.<a name="5" href="#note5"><sup>5</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;One can be polite without giving in to flattery and baseness.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;When a<a name="6" href="#note6"><sup>6</sup></a> polite man is obliged to displease, he will displease<a name="7" href="#note7"><sup>7</sup></a> less than another who is not polite.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;His refusals and objections will usually be accompanied by something to lessen the bitter taste. He will know how to gild the pill.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> reason ǀ to believe that a good ǀ deleted. <var>deleted</var>.<br />
                <a name="note2" href="#2">2.</a> more ǀ although one cannot ǀ <var>deleted</var>.<br />
                <a name="note3" href="#3">3.</a> in ǀ the words ǀ <var>deleted</var>.<br />
                <a name="note4" href="#4">4.</a> Reading &quot;les manieres&quot; in place of &quot;les maniere&quot;.<br />
                <a name="note5" href="#5">5.</a> alone. ǀ &#182; One should also avoid giving in to flattery and baseness ǀ <var>deleted</var>.<br />
                <a name="note6" href="#6">6.</a> Reading &quot;un&quot; in place of &quot;on&quot;.<br />
                <a name="note7" href="#7">7.</a> displease ǀ as little as possible ǀ <var>deleted</var>.<br /><br /><br />
                &#169; Lloyd Strickland 2021
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
