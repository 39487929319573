import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/bourguetapril1716.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>Die philophischen schriften von Gottfried Wilheim Leibniz, vol. III</var><br />
		C. I. Gerhardt (ed)<br />
		pp 591-593<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 3 April 1716<br /><br />
		Translated from the French<br /><br />
        Note - the portion of this text that is included in my <var>Shorter Leibniz Texts</var> is not included here.<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (13k)</Link><br /><br />
        <Link to="/slt">Back to <var>Shorter Leibniz Texts</var></Link><br /><br />
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Louis Bourguet (3 April 1716)</title>
            <meta name="description" content="An English translation of a LETTER TO LOUIS BOURGUET by Gottfried Wilhelm Leibniz, from 3 April 1716" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO LOUIS BOURGUET</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>G III p591</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I have just received the honour of your letter, and I thank you first of all for the kindness you have shown in giving some silkworm eggs to a friend for me. There is probably still time to send them during the month of April, and even up to the beginning of May, for in the Alps and in Germany the heat does not come so early. In any case it is better to risk it: I hope that the eggs will be of good stock. By putting the paper in a small tin box, I believe that the eggs will remain fresher.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;As regards the comparison between the instant and unity, I add again that unity is a part of number greater than unity, but that the instant is not strictly speaking a part of time. For in the style of mathematicians at least, the whole and the part must be homogenous.<br /><br />

                ......<br /><br />

                [<b>G III p592</b>]<br /><br />
                For since in metaphysics we do not have the advantage of the mathematicians of being able to fix ideas by diagrams, the rigour of the reasoning must compensate for that, which can hardly be obtained in these matters except by observing the logical form. This is what I have observed more than once; and I have noticed that Mr Descartes and Mr Spinoza, in distancing themselves from the rigour of logical form in their so-called metaphysical demonstrations, have fallen into paralogisms. Thus I beg you, Sir, to think, how you could reduce your arguments about that to a due form, for I do not yet see how to do it. Without that there will always be remarks and objections to make, without one knowing if one is well advanced or not.<br /><br />

                ......<br /><br />


                [<b>G III p593</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You are right to think, Sir, that Madam the Princess of Wales<a name="1" href="#note1"><sup>1</sup></a> must have a wonderfully elevated mind, since she goes so deeply into matters so sublime. To confirm this I will tell you that she has read the <var>Theodicy</var> more than once, and with appreciation, and that she laughed at those who had wanted to turn her away from this reading under the pretext that things in it were too abstract.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Professor of Lausanne was not obliged to know my pre-established harmony, but as he did not understand anything about it, he could have avoided mocking it. The best thing is that his judgement about that will not be taken seriously, any more than that of Mr Pufendorf on the question of whether morality depends on the will of God. He became a little too angry, seeing that I did not attach great enough importance to his author on this matter of the source of morality. Besides, I am sincerely etc.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;From Hanover, 3rd April 1716.<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;P.S.&nbsp;&nbsp;&nbsp;The news of the Jesuits' massacre in China was false. The monarch of China is reconciled with his heir. The King of Portugal solicits for the Jesuits in the Court of Rome, and does not want the constitution of the Pope against Chinese rituals to be published in Macao. The Muscovites still continue their caravan to go to China. There is in Peking a temple of the Greek Religion.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mr Hobbes has already had the idea that the Moon turns around its axis.<a name="2" href="#note2"><sup>2</sup></a> Reasoning must be joined to observation. Mr Flamsteed,<a name="3" href="#note3"><sup>3</sup></a> a great English observer, has made it known to me that he is still not in agreement with Mr Newton on many things. If the motion of the Moon were sufficiently known, we would already have the longitudes at sea.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> Princess Caroline.<br />
                <a name="note2" href="#2">2.</a> Thomas Hobbes, <var>De corpore</var> [On body] (1655), IV.26.9.<br />
                <a name="note3" href="#3">3.</a> John Flamsteed (1646-1719), English astronomer.<br />
                <br /><br />
                &#169; Lloyd Strickland 2005<br />
                With thanks to Geert de Wilde
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
