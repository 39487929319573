import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/johannf.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series I, volume 2</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 227-228<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: autumn 1679<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (74k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Duke Johann Friedrich (autumn 1679)</title>
            <meta name="description" content="An English translation of a LETTER TO DUKE JOHANN FRIEDRICH by Gottfried Wilhelm Leibniz, from autumn 1679" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO DUKE JOHANN FRIEDRICH</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A I 2, 227</b>]<br /><br />
                <p align="right">No. 1.</p><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;After having carefully considered what would be the best way to obtain the desired declarations, I concluded that a paper should be forged as to appear to have been written by a Catholic to convert a Protestant, and that this Catholic explains everything in the most favourable and most accommodating way he can, without wronging his own belief. In this way, Rome will always be more disposed to favour than to criticize such a discourse.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Now, these favourable declarations would be of two kinds: one that an individual may desire, which consist in the explanation of a few points of belief, the other that a sovereign or even a republic may claim, which are concessions or dispensations.<br /><br />

        [<b>A I 2, 228</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The declarations for an individual are, for example, with regard to the object of the adoration in the Eucharist, with regard to the meaning of anathematisms, with regard to justification and penance etc.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The declarations concerning a sovereign or body are, for example, with regard to communion in two kinds, the marriage of ecclesiastics, and the secularization of the goods of the Church.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Both can be included in the aforementioned paper written in the guise of a Catholic and addressed to a Protestant.
        If such a paper were approved in Rome, and if besides that there were a willingness there to make concessions a prince might rightly desire, I hold that we could move from theory to practice, and perhaps Your Serene Highness could have as much of an effect on the person, which Mr. de Condom wants to see, as Mr de Condom himself.<a name="1" href="#note1"><sup>1</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If everything was well-disposed, I think I could give wonderful expedients both for winning over people and for the glory and advantage of Your Serene Highness and his house: for I hold that one could obtain forever and attach to this house the bishoprics of Hildesheim and Osnabruck, with the approval of Rome, Vienna, and Paris, and this in a way which does not offend canon law nor ecclesiastical constitutions. This expedient has not yet come to anyone’s mind, but when the door is opened, others will benefit from it too.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I have had a terrible time resolving myself to making this opening, not knowing how Your Serene Highness will take it, and whether it is timely. In any case, I beg Your Serene Highness to return this paper to me as well as the first one. I admit that if someone could do something of this nature for the general good of Christendom, he would have made good enough use of his life.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> Leibniz is referring to Jacques-Benigne Bossuet (1627–1704).<br /><br /><br />
                &#169; Lloyd Strickland 2021
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
