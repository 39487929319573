import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/bristol.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
		Shelfmark LH 4, 4, 1 Bl. 1-6<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 4 March 1715<br /><br />
		Written in English<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this text in PDF format (248k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>George Smalridge, Bishop of Bristol to Sophie, Electress of Hanover, on Leibniz's 'Theodicy' (4 March 1715)</title>
            <meta name="description" content="A transcription of a letter from GEORGE SMALRIDGE, BISHOP OF BRISTOL, TO SOPHIE, ELECTRESS OF HANOVER, ON LEIBNIZ'S 'THEODICY', from 4 March 1715" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>GEORGE SMALRIDGE, BISHOP OF BRISTOL, TO SOPHIE, ELECTRESS OF HANOVER, ON LEIBNIZ'S <var>THEODICY</var></h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 <small id="note">For further details on this text, see Lloyd Strickland, &quot;The reception of the <var>Theodicy</var> in England.&quot; In W. Li (ed.) <var>Leibniz, Caroline und die Folgen der englischen Sukzession</var> (Stuttgart: Franz Steiner Verlag, 2016), 69-91. The final version of this paper can also be found <a href="https://www.academia.edu/8951244/The_Reception_of_the_Theodicy_in_England" target="_blank">here</a>.</small><br /><br />
                [<b>LH 4, 4, 1 Bl. 1r</b>]<br /><br />

                Madam,<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The book, which Your Royall Highness was pleas'd to put into my hands,<a name="1" href="#note1"><sup>1</sup></a> when I was in town, I did, soon after my return to this place, peruse &amp; consider with the best attention &amp; the best application I could; and when I had gone through it, I did intend in obedience to Your Highnesses Commands to do my self the honour of giving You [<b>LH 4, 4, 1 Bl. 1v</b>] my thoughts concerning It. But I was taken with first a great heaviness, and afterwards an acute pain in my head, which hath for some weeks indespos'd me for writing, &amp; which is not yet quite remov'd, tho' I thank God it is in great measure abated. Were my head never so clear, I should not hope to write any thing worthy of Your Highnesses view; as it is now more than ordinarily weak and confus'd, I should be unpardonable in preferring to trouble Your Highness with my crude thoughts, if it were not still more inexcusable not to write at all, after Your Highness had condescended both to permit me so to do, &amp; to signifie, that you expected to have heard from me.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;My Lady Nottingham, when she first mention'd [<b>LH 4, 4, 1 Bl. 2r</b>] this book to me from Your Royal Highness, told me that you complain'd of the obscurity of it. I cannot but think that there is great reason for that complaint, for though it doth not become me to measure the extent of Your Highnesses abilities by the common standard, yet I believe I may, without too much presumption, say, that there is scarce any other person of your sex, who can thoroughly understand all the parts of this book.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The subject it self, of which the learned author treats is very nice &amp; intricate, such as hath puzzled the wisest and ablest heads in all ages, and such as those who have most maturely weigh'd &amp; consider'd, have most readily acknowledg'd to be attended with great, if not insuperable, difficulties. [<b>LH 4, 4, 1 Bl. 2v</b>] The author in treating of this subject hath employ’d many school-Forms, & metaphysical distinctions (such as <var>absolute</var> and <var>hypotheticall</var> necessity; the necessity of <var>consequence</var>, &amp; of the <var>consequent</var>; the <var>antecedent</var> &amp; <var>consequent</var> will of God; science of <var>simple intelligence</var>, of <var>vision</var>, &amp; a <var>middle</var> science between these two; the <var>physicall</var> &amp; <var>metaphysicall</var> communication of the soul with the body; and the like) which terms to persons not vers'd in the peculiar idiom &amp; language of the Schools must of necessity be, what ever by some, who have been sufficiently skill'd in this sort of learning, they have been declar'd to be, mere jargon, empty words without any meaning, and utterly unintelligible. The author hath also inserted several citations, from Greek &amp; Latin authors, which he hath not translated [<b>LH 4, 4, 1 Bl. 3r</b>] into the language in which he writes, &amp; which therefore a reader, who is not acquainted with Greek &amp; Latin, can make nothing of, &amp; you ought to know, not merely for the sake of the citations themselves, but also because without the understanding of these, what goes before or follows after cannot be well understood.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The author doth in severall places illustrate what he delivers by similitudes or examples, taken from mathematics or naturall philosophy; which resemblances, tho' to persons skill'd in those sciences they may make the matters treated of clearer, yet to others unacquainted with those parts of learning, or who have not searcht into the depths of them, they must necessarily render what is said rather more, than less, obscure.<br /><br />

                [<b>LH 4, 4, 1 Bl. 3v</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The author doth in severall places allude to books, which he had before publish'd, &amp; which he supposes the reader of this to be well acquainted with; but it may happen that some readers may have never seen, or never consider'd those former discourses of his, &amp; therefore may be the less prepar'd, & the less able to understand what is advanced in this.
                The author hath in the prosecution of this subject made many, &amp; sometimes very long digressions, which tho' in themselves perhaps very usefull &amp; instructive, yet, as they are brought in here, do interrupt the thread of the discourse, &amp; thereby make it more difficult for the reader to carry on the pursuit of the principall subject in his thoughts.<br /><br />

                [<b>LH 4, 4, 1 Bl. 4r</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For the reasons which have been alledg'd, &amp; for many others, which might be offer'd, this book must to the generality of readers, at least in some parts of it, appear difficult and obscure.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;But however, it is very easie for any intelligent reader with the least degree of attention to discover in it many excellent thoughts, a great compass of knowledge and learning, a close way of reasoning, a solidity of judgment, much candour towards those from whom the author differs &amp; against whom he writes, &amp; which must render it still more valuable to all serious and devout Christians, a true spirit of piety, an ardent zeal for the glory of God, for the vindicating his attributes, for inspiring the reader with [<b>LH 4, 4, 1 Bl. 4v</b>] a Love of Him, &amp; for rectifying those falser notions of reason or about religion, which must have a very bad influence upon mens practise.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;What this author hath at large alleg'd to prove that the permission of evil, &amp; even of sin, is consistent with the goodness, wisdom, &amp; holiness of God, will, I believe, appear satisfactory to all unprejudic'd and well-disposed minds; but still there will be room for cavils from those who are irreligiously inclin'd; &amp; even sober, and pious persons, who are firmly persuaded of the divine attributes, may not be able fully & clearly to answer all the objections which may be brought against them.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Your Royal Highness will find the objection against [<b>LH 4, 4, 1 Bl. 5r</b>] the goodness of God drawn from the permission of evil consider'd, and answer'd by Archbishop Tillotson in the 3rd sermon of the 7th volume of the sermons publish'd after his death.<a name="2" href="#note2"><sup>2</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The answers given there by the Archbishop, &amp; more at large by the author of the learned book, which Your Royal Highness put into my hands, appear to me very solid, but were they less satisfactory than they are, I should not at all be stagger'd in my firm belief of the divine wisdom and goodness, tho' I were not able to reconcile these with the sufferance of evil.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For since it is evident from experience, that God doth permit evil; &amp; since it is demonstrable by reason &amp; by revelation, that God is holy &amp; good, these truths must be consistent one with the other, whether I by [<b>LH 4, 4, 1 Bl. 5v</b>] my shallow reasoning can make out their consistency or not.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The attempt of learned men to reconcile all appearances of repugnancy between such undoubted truths, as do seem to interfere with each other, is extremely laudable; and the reasons which they have offer'd to prove the permission of evil &amp; the goodness of God to be fairly consistent, are much stronger than any which are brought to prove them repugnant; but still there may remain some difficulties not to be solv'd whilst we are in this state of imperfection, but reserv'd, till we are translated to a state of greater illumination.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In the mean-time it will become all humble, serious, &amp; sober-minded Christians, rather to apply themselves to the diligent &amp; conscientious practise of known duties, [<b>LH 4, 4, 1 Bl. 6r</b>] than to perplex their minds with an over-curious search into hidden &amp; mysterious truths; as considering, that <var>secret things belong to the Lord our God; but that those things which are reveal'd, belong to us, that we may do all the words of the law</var>.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;That God would direct Your Royal Highness by his Holy Spirit in the true knowledge of Him &amp; of his Word; that He would confirm &amp; strengthen you in all goodness, &amp; pour down upon Your Regal Person &amp; Family the choisest of his blessings, is the earnest prayer of,                                
                <br /><div align="center">Madam,<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Your most dutifull, most obedient, &amp;<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;most obliged servant<br /><br />
                </div>
                Christ-Church, Oxford<br />
                Mar. 4th 1714<a name="3" href="#note3"><sup>3</sup></a><div align="center">George Bristol</div>

                        <br /><br />
                        <hr className='rightcol-hr' />
                        <br /><br />NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> Namely, Leibniz's <var>Theodicy</var>.<br />
                        <a name="note2" href="#2">2.</a> Tillotson's sermon (&quot;The goodness of God&quot;) can be found in John Tillotson, <var>The Remaining Discourses, on the Attributes of God</var>, 7 vols. (London, 1700), VII: 51-80.<br />
                        <a name="note3" href="#3">3.</a> It was English convention at the time to treat the months of January through March as belonging to the previous year. I would like to thank Monika Meier for pointing this out to me.<br /><br /><br />
                        Transcribed by Lloyd Strickland, 2014
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
