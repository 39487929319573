import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/landgrave1681.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
		Shelfmark LBr F 20 Bl. 31r<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: April 1681<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (176k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Landgrave Ernst von Hessen-Rheinfels (April 1681)</title>
            <meta name="description" content="An English translation of a LETTER FROM GOTTFRIED WILHELM LEIBNIZ TO LANDGRAVE ERNST VON HESSEN-RHEINFELS, from April 1681" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO LANDGRAVE ERNST VON HESSEN-RHEINFELS</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>LBr F 20 Bl. 31r</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;My lord<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This winter I wrote a very long letter for Your Serene Highness which I addressed to Mr. Jungman, following his order. I hope it has been delivered, because I would not much like it to have been lost: not because it deserves to be preserved, but because I spoke with much frankness, since I spoke to a prince of sound judgment and lofty mind.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I am sending here part of the curriculum vitae of the late Serene Highness, my master, which I have finished off.<a name="1" href="#note1"><sup>1</sup></a> And I will send the rest in another post. It is believed His Serene Highness, my master, will go to the baths.<a name="2" href="#note2"><sup>2</sup></a> But I don’t know if this will be in Ems or in Wiesbaden.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;There is a rumour<a name="3" href="#note3"><sup>3</sup></a> that the Duke of Mecklenburg-Güstrow shows some disposition to become a Catholic,<a name="4" href="#note4"><sup>4</sup></a> and that this alarms his court and his territory; but I have heard nothing for sure about this.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I have seen a fairly well-written book, whose title is <var>Entretien de la politique du clerg&#233; de France, et des moyens dont on se sert pour attirer les Huguenots</var> [Dialogue about the Policy of the Clergy of France, and of the Means Used to Win Over the Huguenots].<a name="5" href="#note5"><sup>5</sup></a> The author relates many remarkable details. But he adds many things that smack a little of the minister, that is, the man who acts out of passion and party interest rather than by reason, such as when he wants us to believe that the Bishop of Condom’s book destroys the essentials of the Catholic religion by the moderate explanations it has given to disputed articles.<a name="6" href="#note6"><sup>6</sup></a> From this it is clear that it would be very unfortunate if the Catholic gentlemen<a name="7" href="#note7"><sup>7</sup></a> had complaisance for the Protestants, whereas a man who seeks only the public good should praise God for it, far from exposing the well-intentioned to the blame and mockery of false zealots.<a name="8" href="#note8"><sup>8</sup></a> For my part, I am convinced that<a name="9" href="#note9"><sup>9</sup></a> the differences, when they are thoroughly examined, consist for the most part only in abuses, which are reproached.<a name="10" href="#note10"><sup>10</sup></a>
                        <br /><br /><br />
                        <hr className='rightcol-hr' />
                        <br /><br />
                        NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> That is, Duke Johann Friedrich (1625-1679).<br />
                        <a name="note2" href="#2">2.</a> That is, Duke Ernst August (1629-1698).<br />
                        <a name="note3" href="#3">3.</a> rumour &#124; of some change or stirring at the court of &#124; <var>deleted</var>.<br />
                        <a name="note4" href="#4">4.</a> That is, Gustav Adolph (1633–1695), who was Duke of Mecklenburg-G&#252;strow from 1636 until his death.<br />
                        <a name="note5" href="#5">5.</a> Leibniz is referring to [Pierre Jurieu], <var>La politique du clerg&#233; de France, ou Entretiens curieux de deux catholiques romains, l'un parisien &amp; l'autre provincial, sur les moyens dont on se sert aujourd'huy, pour destruire la religion Protestante dans ce Royaume</var> (n.p.: n.p., 1681).<br />
                        <a name="note6" href="#6">6.</a> Namely, Jacques Benigne Bossuet, <var>Exposition de la doctrine de l'eglise catholique sur les materieres de controverse</var> (Paris: Mabre-Cramoisy, 1671). See [Jurieu], <var>La politique du clerg&#233; de France</var>, 89-90, 92-93, 112.<br />
                        <a name="note7" href="#7">7.</a> gentlemen &#124; moderate themselves, whereas a man &#124; <var>deleted</var>.<br />
                        <a name="note8" href="#8">8.</a> This sentence, and most of the previous one, is drawn verbatim from Leibniz's critical notes on <var>La politique du clerg&#233; de France</var> in A IV 3, 213-218, at 217.<br />
                        <a name="note9" href="#9">9.</a> that &#124; the schism is &#124; <var>deleted</var>.<br />
                        <a name="note10" href="#10">10.</a> Compare Leibniz's verdict on <var>La politique du clerg&#233; de France</var> in a letter to Christian Philipp of 1/11 April 1681: &quot;I have seen a rather nice book about the policy of the clergy of France to destroy the Protestants of France. There are, however, things which smack a little too much of the minister, that is, where passion is apparent. For example, when the author has a Catholic criticize the Bishop of Condom as if he were destroying the essence of the Roman religion; instead he [Bossuet = Bishop of Condom] should be praised for his moderation. It seems that ministers would be sorry to lose the subject of their invectives, if the Papists became reasonable. Besides, this author says fine things by way of an apology of the Huguenots of France. But I cannot stand that he casually attacks Mr. Huet's work in favour of the truth of the Christian religion, as if it were a collection of criticism without judgement. And I find that Mr. Huet combined great solidity with great erudition, although there are things with which I do not agree concerning the parallel of pagan theology with the Jewish.&quot; The attack on Huet can be found in [Jurieu], <var>La politique du clerg&#233; de France</var>, 97.<br /><br /><br />
                        &#169; Lloyd Strickland 2022
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
