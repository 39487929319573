import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/equilibrium.pdf'; 
import { Helmet } from 'react-helmet';

function Equilibrium() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		    <var>S&#228;mtliche schriften und briefe series VI volume 4</var><br />
        Deutsche Akademie der Wissenschaften (ed)<br />
        p 1355<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        Date: 1677?<br /><br />
        Translated from the Latin<br /><br />{isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (91k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>On indifference of equilibrium</title>
            <meta name="description" content="An English translation of ON INDIFFERENCE OF EQUILIBRIUM by Gottfried Wilhelm Leibniz, from 1677?" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: ON INDIFFERENCE OF EQUILIBRIUM</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                [<b>A VI 4, p1355</b>]<br /><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If God were to say &quot;I wish this balance, which has been placed in equilibrium, to incline to some side, yet I do not wish there to be any reason why it should incline to one rather than the other&quot;, God would command things that mutually oppose each other, because it cannot be that something happens without a cause through which it is possible to understand why it happens rather than not. And such is the fiction of those who introduce an indifference of equilibrium into the will, as if God would have wanted two things at the same time: a will that is perfectly indifferent to each option and yet also self-determining.
                <br /><br /><br />
                &#169; Lloyd Strickland 2016. Revised 2022
                <br /><br />
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Equilibrium;