import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/molanus1699.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>S&#228;mtliche schriften und briefe series I, volume 17</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 610-611<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: early November 1699<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (11k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Gerhard Wolter Molanus (early November 1699)</title>
            <meta name="description" content="An English translation of a LETTER TO GERHARD WOLTER MOLANUS by Gottfried Wilhelm Leibniz, from early November 1699" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO GERHARD WOLTER MOLANUS</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A I 17, p610</b>]<br /><br />
		        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;As for irresistible grace, and moral actions which are necessary and free at the same time, which the letter mentions next<a name="1" href="#note1"><sup>1</sup></a> - this requires an explanation. Sometimes [<b>A I 17, p611</b>] the word 'necessity' is taken in such a broad way that whatever is certain and determined is reckoned as necessary, as for example when Our Lord said that it must needs be that offences happen.<a name="2" href="#note2"><sup>2</sup></a> And in this way all future contingents would be necessary, as their truth is determined. But when one speaks in philosophical terms, 'free' and 'contingent' are distinguished from 'necessary'. And in this sense, grace need not necessitate us at all, yet resistance and obstacles can come from us, even if they do not actually do so. It is even more accurate to say that the good actions of God, of the confirmed angels, and of the glorified saints, are not necessary, although they are certain, the reason being that they are made by choice, whereas necessity holds good when there is no choice to make. When there are several paths, there is freedom to choose, and although one is better than another it is that very thing which makes the choice. And even if there is only a single good choice, as when there is just one bridge over a deep and fast-flowing river, one nonetheless chooses between the bridge and the river, although the two choices are hardly equal. But if one found oneself in a narrow street, between two high walls, there would only be one possible path, and that represents necessity. From this it is clear that it is not the indifference of equilibrium, so to speak, that constitutes freedom, but the faculty to choose between several possibles, even if they are not equally feasible or acceptable to the one who acts.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                        <br /><br />
                        NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> Leibniz is here referring to a letter by Jeremias Sterky, a professor in theology at Lausanne, which had been passed on to Molanus, who in turn passed it on to Leibniz.<br />
                        <a name="note2" href="#2">2.</a> Luke 17.1: 'Then said he unto the disciples, It is impossible but that offences will come: but woe unto him, through whom they come!' Matthew 18.7: 'Woe unto the world because of offences! for it must needs be that offences come; but woe to that man by whom the offence cometh!'
                <br /><br /><br />
                        &#169; Lloyd Strickland 2007
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
