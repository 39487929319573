import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/hansch.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>Opera Omnia volume 5</var><br />
		Ludovic Dutens (ed)<br />
		p 169<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 15 March 1713<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (64k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Michael Gottlieb Hansch (15 March 1713)</title>
            <meta name="description" content="An English translation of a LEIBNIZ TO MICHAEL GOTTLIEB HANSCH by Gottfried Wilhelm Leibniz, from 15 March 1713" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO MICHAEL GOTTLIEB HANSCH</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>D V, p169</b>]<br /><br />
                He [Christian Goldbach] objects that what follows from the choice of the best is a fatal necessity.<a name="1" href="#note1"><sup>1</sup></a> But unless I am mistaken, such objections have recently been answered in my book,<a name="2" href="#note2"><sup>2</sup></a> so there is no need for repetition. The <var>necessity</var> which flows from the choice of the best, which I call moral, is not to be shunned, nor can it be avoided without denying supreme divine perfection in the process. But the necessity that is to be avoided is the one which consists in the necessity of the object, namely where the very opposite object involves a contradiction in its own concept, likewise if the necessity is brute, which is the sort of thing maintained by the like of Democritus, Hobbes and Spinoza, who claim that the world exists not as a result of choice but as a result of the necessity of things, as for instance from the concourse of atoms or from matter and motion. If a world different from our world were to imply contradiction in its own concept, this world would be absolutely necessary. But because an infinity of other worlds can be imagined and distinctly conceived, like the stories of Milesia or Utopia,<a name="3" href="#note3"><sup>3</sup></a> and only the choice of the best, extrinsic to the object, means that our world exists rather than those ones, it follows that our world is necessary only morally, and is absolutely speaking contingent. Otherwise it is to want to talk about problems where there aren't any, and to aspire to paradoxes along with Bayle, although in fact it is only to quarrel about the use of words, ignoring their scope, which should be established in advance in the case of the necessity to be avoided, in order that existence of things may be sought in divine wisdom and power rather than in the necessity of the objects, that is, in the extrinsic nature of things. Farewell, and think kindly of me.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sent from Vienna, 15 March 1713
                <br /><br /><br />
                <hr className='rightcol-hr' />
                        <br /><br />
                        NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> See Goldbach's letter to Leibniz of 5 January 1713: LBr 1015 Bl. 7-8.<br />
                        <a name="note2" href="#2">2.</a> Leibniz is referring here to his <var>Theodic&#233;e</var> (Amsterdam, 1710).<br />
                        <a name="note3" href="#3">3.</a> A reference to the Milesian Tales of Aristides from the second century BCE, and the novel <var>Utopia</var> by Thomas More, published in 1516.
                <br /><br /><br />
                        &#169; Lloyd Strickland 2011 (revised 2016)
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
