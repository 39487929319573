import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/lacroze.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
       <var>Opera Omnia volume 5</var><br />
		Ludovic Dutens (ed)<br />
		pp 484-485<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 8 October 1707<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (10k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Maturinus Veyssiere La Croze (8 October 1707)</title>
            <meta name="description" content="An English translation of a LETTER TO MATURINUS VEYSSIERE LA CROZE by Gottfried Wilhelm Leibniz, from 8 October 1707" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO MATURINUS VEYSSI&#200;RE LA CROZE</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>D V, p484</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You have delighted me by informing me of your commitment to research into Chinese characters, and your hope of making progress in this. This research seems to me all the more important - beyond what I imagine - since, if we were able to discover the key to Chinese characters, [<b>D V, p485</b>] we would find something which would help the analysis of thoughts. Consider Reverend Father Cima,<a name="1" href="#note1"><sup>1</sup></a> an Italian national of the order of St. Augustine, who told me that the fundamental characters are believed to be somewhere in the region of 400, and that the others are only compositions of them.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This father was a missionary in the Indies and sometimes found himself in the court of Peking in his capacity as doctor, so he has some knowledge of Chinese medicinal plants and remedies. He returned to Europe on a Danish ship, and as he wants to make his court to the King, I take the liberty, Sir, of referring him to you by imploring you to show him favour and to acquaint him with Mr de Gundelsheim, to whom I offer my greatest respects. I also beg you to question this missionary about many things, and to pass on to me what you educe from him, since I have only been able to speak with him for a few hours. It is true that he has not been in China long enough to acquire knowledge of their characters; he can nonetheless say many useful things about that country, to which he seems to want to return, as is the case with most of those who have come from there. It also seems that he could provide some description of it. Moreover, I am sincerely...<br /><br />
                <div align="right">Hildesheim, 8 October 1707</div>
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> Nicolas Agostino Cima (?-1722), a missionary in China.<br /><br /><br />
                &#169; Lloyd Strickland 2008
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
