import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/goal-sciences.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series IV volume 5</var><br />
        Deutsche Akademie der Wissenschaften (ed)<br />
        pp 591-592<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: after 9/19 October 1693<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (74k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>On the goal of the sciences</title>
            <meta name="description" content="An English translation of ON THE GOAL OF THE SCIENCES by Gottfried Wilhelm Leibniz, from after 9/19 October 1693" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: ON THE GOAL OF THE SCIENCES</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A IV 5, p591</b>]<br /><br />
                It seems it may not improperly be said that the goal of theology is beatitude, the goal of jurisprudence is judgement, that is, the goods permitted in another's opinion; the goal of medicine is the health or goods of the body; the goal of philosophy is systematic knowledge, that is, knowledge requiring reasoning; the goal of history is knowledge requiring memory, that is, knowledge of singular events; and the goal of gymnastics is bodily skill. But yet there is this difference, that in the former three the goal is internal and in the latter three it is external.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Theology shows the way of obtaining perpetual happiness, through the divine will or grace.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Jurisprudence shows the way of obtaining temporal happiness through the human will, for it also includes politics.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Medicine shows the way of using our body for happiness.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Technology shows the way of arranging other things which can be instruments to the former [disciplines].<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Theology</var> teaches how best to conduct oneself towards God.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Jurisprudence</var> towards another man.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Polymathy</var> towards our mind.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Medicine</var> towards our body<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Technology</var> towards external things.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;But there are still difficulties. For logic better teaches how to cultivate the intellect, ethics the will, and politics other men. History should be separated from philosophy. Gymnastics should be separated from medicine, and the instrument of politics from eloquence.<br /><br />
                [<b>A IV 5, 592</b>]<br />
                <br /><br />
                Theology is a sort of divine jurisprudence, setting forth the laws of our fellowship with God. Therefore universal jurisprudence also underlies its foundations.
                        <br /><br /><br />
                        &#169; Lloyd Strickland 2019.<br />With thanks to John Thorley for advice and suggestions
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
