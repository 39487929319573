import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/bodysubstance.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series VI volume 4</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		p 1637<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: March 1689 - March 1690 (?)<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (10k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Body is not a substance</title>
            <meta name="description" content="An English translation of BODY IS NOT A SUBSTANCE by Gottfried Wilhelm Leibniz, from March 1689 - March 1690?" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: BODY IS NOT A SUBSTANCE</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A VI 4, p1637</b>]<br /><br />
                <center><var>Body is not a substance but only a mode of being or coherent appearance</var></center>
                <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Now by 'body' I do not mean what the Scholastics compose out of matter and a kind of intelligible form, but what the Democriteans elsewhere call 'bulk'. This, I say, is not a substance. For I shall demonstrate that if we consider bulk as a substance we end up implying a contradiction, because of the labyrinth of the continuum. Here we should consider in particular, first, that there cannot be atoms, since they conflict with divine wisdom, second, that bodies are actually divided into infinite parts, although not into points, and consequently, that there cannot be any way to designate one body, but that any portion of matter whatsoever is a being by accident, and, indeed, is in perpetual flux. Now if we say this much, that bodies are coherent appearances, it puts an end to all inquiry about infinitely small things which cannot be perceived. But here too that Herculean argument of mine holds good, that all those things which are such that it is impossible for anyone to perceive whether they exist or not, are nothing. Indeed, this is the nature of bodies, for if God himself wished to create corporeal substances of the kind people imagine, he would not have accomplished anything, nor could he perceive himself to have done anything, since ultimately nothing but appearances are perceived. So coherence is the sign of truth, its cause is the will of God, and its formal reason is the fact that God perceives that something is the best, i.e. most harmonious, or, that something is pleasing to God. And so divine will is itself the existence of things, so to speak.
                <br /><br /><br />
                &#169; Lloyd Strickland 2009
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
