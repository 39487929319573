import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/hospital3.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>S&#228;mtliche schriften und briefe series III, volume 6</var><br />
        Deutsche Akademie der Wissenschaften (ed)<br />
        pp 417-418<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        Date: 14/24 June 1695<br /><br />
        Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (12k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to the Marquis de l'Hospital (14/24 June 1695)</title>
            <meta name="description" content="An English translation of a LETTER TO THE MARQUIS DE L'HOSPITAL by Gottfried Wilhelm Leibniz, from 14/24 June 1695" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO THE MARQUIS DE L'HOSPITAL</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A III 6, p417</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I have heard that Mr Huygens<a name="1" href="#note1"><sup>1</sup></a> has been a little ill.<a name="2" href="#note2"><sup>2</sup></a> I will write to him at the first opportunity,<a name="3" href="#note3"><sup>3</sup></a> in the hope he will be better. His preservation is extremely important to us. And he would quite rightly need - even more than I do - young people capable of profiting from his opinions, and of helping him carry out his ideas. His name should come after that of Galileo, Kepler, and Descartes. It is also to him, after those people, to whom I have the most obligation. I did not forget to show it publicly when the opportunity arose. And aside from his profound knowledge, I have great admiration for the sincerity he has shown in doing justice to others when the occasions presented themselves. After having become acquainted through you, Sir, with the use of my calculus, he might easily have misrepresented it and adapted it to ancient expressions, but he employed it in a completely different way. If you write to him, Sir, I implore you to exhort him, with me, to give us the many fine thoughts he undoubtedly has, even in philosophy, and especially in physics, without endeavouring to write systematic treatises, which would be troublesome for him.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;As for you, Sir, as you are at your peak, and as the highest point we have reached in geometry marks only your beginnings, it is easy to conclude just how much progress should be expected from your extraordinary insights. By being willing to have some obligation to me you increase the obligation I have to you, and you show that your insight goes hand in hand with this obliging temperament, the source of which is a great store of civility, which is worth even more than the most profound knowledge.<br /><br />

        [<b>A III 6, p418</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Be good enough, Sir (I beg you), to let Reverend Father Malebranche know again how much I am obliged for his courtesies. I owe him a lot in metaphysics, and I think that, taking ideas as he does for the immediate external object of our thoughts, it can be said that we see them in God. However my explanation is a little different from his system of occasional causes,<a name="4" href="#note4"><sup>4</sup></a> because of the notion of substance I have. I hope he will see it soon, and I will be delighted to have his judgement on it.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> Christiaan Huygens (1629-1695), Dutch mathematician.<br />
                <a name="note2" href="#2">2.</a> See the letter from Basnage de Beauval to Leibniz of 19 April 1695 (G III 115-16).<br />
                <a name="note3" href="#3">3.</a> Leibniz wrote to Huygens a week later, on 21 June/1 July 1695; see A III 6, 418-22.<br />
                <a name="note4" href="#4">4.</a> Leibniz is referring to his 'New system of the nature of the communication of substances, as well as the union that exists between the soul and the body', published in the <var>Journal de S&#231;avants</var> on 27 June and 4 July 1695 (English translation in SLT 68-77).
        <br /><br />
                &#169; Lloyd Strickland 2007, 2009
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
