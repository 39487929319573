import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/idolatries.pdf'; 
import { Helmet } from 'react-helmet';

function IdolatriesRevived() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>S&#228;mtliche schriften und briefe series VI volume 4</var><br />
        Deutsche Akademie der Wissenschaften (ed)<br />
        p 2284<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 1701 (?)<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (141k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Idolatries Revived Among Christians</title>
            <meta name="description" content="An English translation of SUMMARY OF JOHN TOLAND's 'IDOLATRIES REVIVED AMONG CHRISTIANS' by Gottfried Wilhelm Leibniz, from 1701 (?)" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: SUMMARY OF JOHN TOLAND'S &quot;IDOLATRIES REVIVED AMONG CHRISTIANS&quot;</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 <small>Although the Academy editors catalogue the following piece as one of Leibniz's works, it is in fact Leibniz's own summary of some material written by John Toland in 1701 and subsequently published in Toland's <var>Letters to Serena</var> of 1704:<br /><br />

                 <blockquote style={{padding: isMobile ? '' : '0 70px 0 70px'}}>Having given this summary Account, Serena, of antient and modern Heathenism, we may remark that almost every Point of those superstitious and idolatrous Religions are in these or grosser Circumstances reviv'd by many Christians in our Western Parts of the Word [sic], and by all the Oriental Sects: as Sacrifices, Incense, Lights, Images, Lustrations, Feasts, Musick, Altars, Pilgrimages, Fastings, religious Celibacy and Habits, Consecrations, Divinations, Sorcerys, Omens, Presages, Charms, the Worship of dead Men and Women, a continual Canonization of more, Mediators between God and Men, good and evil Daemons, guardian Genius's, Male and Female tutelar Powers to whom they dedicate Temples, appoint Feasts and peculiar Modes of Worship, not only cantoning all Places among 'em, but likewise the Cure of Diseases, and the disposal of every thing which Men are glad to want or enjoy... In plain and proper Terms this is Antichristianism, nothing being more diametrically repugnant to the Doctrin of Christ... But if any shou'd wonder how Men cou'’d leave the direct and easy Path of Reason to wander the such inextricable Mazes, let him but consider how in very many and considerable Regions the plain Institution of Jesus Christ cou'd degenerate into the most absurd Doctrins, unintelligible Jargon, ridiculous Practices, and inexplicable Mysterys...<a name="1" href="#note1"><sup>1</sup></a></blockquote></small>
                 <br />[<b>A VI 4, 2284</b>]<br /><br />Many idolatries have been revived among Christians: sacrifices, frankincense and incense, lights, images, lustrations, festivals, songs, sacred pilgrimages, fasting, celibacy, vestments, divinations, magic, omens, prognostication, incantations, cult of the dead, canonizations or deifications of new mediators between God and men, good and evil spirits, guardian angels and other powers of either sex to whom temples are dedicated, feast days honoured, particular cults devoted in which, with each one assigned its own province, there is healing of sicknesses and the dispensation of things that aid or injure men. Thus anti-Christianity has prevailed in place of Christ, whose institutes, clear to the understanding and very easy to keep, have degenerated into absurd dogmas, unintelligible words, inexplicable mysteries, and ridiculous practices.
                 <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> John Toland, <var>Letters to Serena</var> (London: Bernard Lintot, 1704), 127-129.
                <br /><br /><br />
                &#169; Lloyd Strickland 2021
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default IdolatriesRevived;
