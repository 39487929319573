import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/jurieu.pdf'; 
import { Helmet } from 'react-helmet';

function Jurieu() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series IV, volume 3</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 218-219<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: April 1683 - May 1684<br /><br />
		Translated from the French<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (255k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Remarks on Mr Jurieu's book against the Bishop of Condom, now the Bishop of Meaux</title>
            <meta name="description" content="An English translation of REMARKS ON MR JURIEU'S BOOK AGAINST THE BISHOP OF CONDOM, NOW THE BISHOP OF MEAUX (April 1683 - May 1684) by Gottfried Wilhelm Leibniz, from January - April 1677 (?)" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: REMARKS ON MR JURIEU'S BOOK AGAINST THE BISHOP OF CONDOM, NOW THE BISHOP OF MEAUX</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 <div id="note">Jacque-Bénigne Bossuet's <var>Exposition of the Doctrine of the Catholic Church</var> attracted the ire of Protestant opponents long after its initial publication in 1671, with one of the most bitter responses published a decade later, <var>Preservatif contre le changement de religion</var> [Preservative Against the Change of Religion] by the Calvinist Pierre Jurieu (1637–1713). In this short set of remarks on Jurieu's book, Leibniz summarized and responded to five of the six reflections Jurieu makes in article 1 of this book, in each case defending Bossuet against Jurieu. Leibniz's description of Bossuet as &quot;the Bishop of Condom, now the Bishop of Meaux&quot; narrows down the date of composition, as although Bossuet became Bishop of Meaux in 1681, Leibniz first refers to him this way in a letter to Spinola of mid-April 1683.<a name="1" href="#note1"><sup>1</sup></a> Moreover, it is likely that Leibniz's notes were written before he read Jurieu's subsequent work, <var>Suite du préservatif contre le changement de religion</var> [Follow up to Preservative Against the Change of Religion],<a name="2" href="#note2"><sup>2</sup></a> in May 1684.<a name="3" href="#note3"><sup>3</sup></a></div><br /><br />
                [<b>A IV 3, 218</b>]<br /><br />

                <center>Remarks on Mr Jurieu's book against the Bishop of Condom, now the Bishop of Meaux</center><br />

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I will skip everything up to article 1, because what precedes it is like a preface and doesn't dwell upon anything specific.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&quot;Article 1 contains general reflections on Mr. de Condom's book.<a name="4" href="#note4"><sup>4</sup></a><br /><br />

                [<b>A IV 3, 219</b>]<br></br><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Reflection 1</var>. The reunion asked for is unjust, for they want the Protestants to give way in everything, without being granted anything.<a name="5" href="#note5"><sup>5</sup></a> I answer that if the separation is unjust, a pure and simple reunion is not unjust. Moreover, the Pope has been willing to accommodate the weak by granting to converts points which were very considerable but which do not affect the dogmas of the Catholic Church.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Reflection 2</var>. Protestants have just as much reason to ask Catholics to come to them. The party of Rome says it is the true Church, but that is what it is not conceded to them; the Protestants are the restorers of the religion of the Apostles.<a name="6" href="#note6"><sup>6</sup></a> The answer that should be made comes back to the previous one, because with the Protestants having left the Church in Rome, it is up to them to prove that they were obliged to do so in order to re-establish the religion of the Apostles.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Reflection 3</var>. Mr. de Condom's assuagings are not sincere, otherwise there would not appear so much hatred towards Protestants.<a name="7" href="#note7"><sup>7</sup></a> Here is my answer: the less difference there is, the less reason the Protestants had to separate, and therefore schism is all the more blameworthy. Moreover, Mr. de Meaux does not say that the difference is small, but that what is put forward to reproach the Church is of little importance.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Reflection 4</var>. That Mr. de Condom's book was written to excuse the Roman Church; now, any man who excuses, acknowledges that there is evil.<a name="8" href="#note8"><sup>8</sup></a> Answer: quite often one excuses or rather defends what is fundamentally good and only appears bad to certain people according to their prejudices. But even if it is admitted that there many abuses that are blameworthy, are the Protestants not forced to admit that there are always many abuses in the world, and that there are abuses among them as well?<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Reflection 5</var>. That the sense of Mr. de Condom is different from that of the Council of Trent. For he claims that anyone who wants to refute him must show that he forsakes the disputes in their entirety, therefore he is claiming to remove or lessen them by the sole means of exposition, and therefore he must give a new meaning.<a name="9" href="#note9"><sup>9</sup></a> Answer: it is enough that he puts the best gloss on the innocence of the Church’s sentiments and shows it all at once and in few words.
		        <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> See A I 3, 567.<br />
                <a name="note2" href="#2">2.</a> Pierre Jurieu, <var>Suite du pr&#233;servatif contre le changement de religion</var> (The Hague: Arnout Leers, 1683).<br />
                <a name="note3" href="#3">3.</a> See A I 4, 331.<br />
                <a name="note4" href="#4">4.</a> Pierre Jurieu, <var>Preservatif contre le changement de religion, ou id&#233;e juste &amp; veritable de la Religion Catholique Romaine oppos&#233;e aux portraits flatt&#233;s que l'on en fait, &amp; particulierement &#224; celuy de Monsieur de Condom</var> (The Hague: Abraham Arondeus, 1681), 32-63.<br />
                <a name="note5" href="#5">5.</a> Jurieu, <var>Preservatif contre le changement de religion</var>, 32-38.<br />
                <a name="note6" href="#6">6.</a> Jurieu, <var>Preservatif contre le changement de religion</var>, 38-43.<br />
                <a name="note7" href="#7">7.</a> Jurieu, <var>Preservatif contre le changement de religion</var>, 43-45.<br />
                <a name="note8" href="#8">8.</a> Jurieu, <var>Preservatif contre le changement de religion</var>, 45-47.<br />
                <a name="note9" href="#9">9.</a> Jurieu, <var>Preservatif contre le changement de religion</var>, 47-52.<br />
                <br /><br /><br />
		        &#169; Lloyd Strickland 2022
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Jurieu;
