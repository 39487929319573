import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/goldbach.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
		Shelfmark LBr 1015 Bl.  8v<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: after 5 January 1713<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (53k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Christian Goldbach (after 5 January 1713)</title>
            <meta name="description" content="An English translation of a letter from GOTTFRIED WILHELM LEIBNIZ TO CHRISTIAN GOLDBACH, written after 5 January 1713" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO CHRISTIAN GOLDBACH</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>LBr 1015 Bl. 8v</b>]<br /><br />		

                Once you suppose that God chooses the best it immediately follows that lesser goods are impossible and the best is necessary, but those things which are necessary according to such a hypothesis are attributed not absolute necessity but hypothetical necessity, in the same way that the things God foresees must happen necessarily. Moreover, absolutely necessary things are ultimately understood as those things whose opposite implies contradiction, or those things which are determined not by choice, or reason of the best, but by the necessity of the object. Therefore in this matter there has been, pure and simple, a dispute about a word. We put choice in opposition to absolute and brute necessity, which is what one is compelled to acknowledge when deriving things not from freedom, that is, depending upon spontaneous choice and thought about motive, but from the concourse of atoms or something similar devoid of original choice. But it is a moral and a happy and a desirable necessity that God must choose the best, as I responded to Bayle recently.<a name="1" href="#note1"><sup>1</sup></a> To ask for a different freedom or contingency is to wish that God not act according to supreme wisdom and goodness, that is, it is to destroy his perfection. And so it is possible to deduce from this that we should not shun or avoid this necessity but acknowledge and exalt it.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;These objections have recently been answered in my book, in which I have amply shown that the only necessity to be avoided is the one which consists in the necessity of the object,<a name="2" href="#note2"><sup>2</sup></a> namely where the very opposite of the object involves a contradiction in its own concept. Certain ancients maintain such a necessity, as do Hobbes and Spinoza, who hold that all things emerged just from matter and motion, or from the concourse of particles. If a world different from our world were to imply contradiction in its own concept, this world would be absolutely necessary. But because infinite worlds can be imagined and distinctly conceived, like the stories of Milesia or Utopia,<a name="3" href="#note3"><sup>3</sup></a> and only the choice of the best, extrinsic to the object, means that our world exists rather than those, it follows that our world is necessary only morally, and is absolutely speaking contingent. Otherwise it is to want to talk about problems where there aren't any, and to aspire to paradoxes (as Bayle does), although in fact it is only to quarrel about the use of words, ignoring their scope, which should be established in advance so that the existence of things may be sought in divine wisdom and power rather than in natured nature [<var>natura naturata</var>], that is, in the intrinsic natures of things.
                        <br /><br /><br />
                        <hr className='rightcol-hr' />
                        <br /><br />NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> See <var>Theodicy</var> &#167;175.<br />
                        <a name="note2" href="#2">2.</a> See <var>Theodicy</var> &#167;45.<br />
                        <a name="note3" href="#3">3.</a> A reference to the Milesian Tales of Aristides from the second century BCE, and the novel <var>Utopia</var> by Thomas More, published in 1516.
                <br /><br /><br />
                        &#169; Lloyd Strickland 2011 (revised 2016)
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
