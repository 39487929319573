import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/remond-jan1716.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>Die philophischen schriften von Gottfried Wilheim Leibniz, vol. III</var><br />
		C. I. Gerhardt (ed)<br />
		pp 664-665<br /><br />
	    {isMobile ? '' : <hr className="centered-hr" />}
		Date: 17 January 1716<br /><br />
		Translated from the French<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (188k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Nicolas Remond (17 January 1716)</title>
            <meta name="description" content="An English translation of a LETTER TO NICOLAS REMOND by Gottfried Wilhelm Leibniz, from 17 January 1716" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO NICOLAS REMOND</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>G III p664</b>]<br /><br />

                <div align="right">Hanover, 17 January 1716</div><br />

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sir,<br />
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I owe you many thanks for the trouble you took over a little loose sheet of my making,<a name="1" href="#note1"><sup>1</sup></a> and which Madam and you, Sir, brought to the attention of Monsignor the Regent.<a name="2" href="#note2"><sup>2</sup></a> It is enough that this great Prince deigned to cast his eyes on it, and that the beginning did not displease him. The nobles present at the time will apparently have known me only by what you were kind enough to say to them about me, and this perhaps having been only too favourable can have weight only by your authority.<a name="3" href="#note3"><sup>3</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I am afraid that Reverend Father Germon will not be too much of my opinion regarding Salic law, just as (between the two of us) I am not too much of his when he wrote against Father Mabillon, and when he makes a show of the alleged corruption of books by heretics.<a name="4" href="#note4"><sup>4</sup></a> Not only should Mr Homberg's finished pieces be published [<b>G III, 665</b>] but also his essays, experiments and conjectures, since physics is often conjectural. You will oblige me, Sir, by informing me about the phosphorus of honey and rye, and especially about the latter since it does not come <var>ex regno animali</var> [from the animal kingdom].<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I am delighted with the honour that Monsignor the Duke of Orleans gave to the sciences, when he made them his business.<a name="5" href="#note5"><sup>5</sup></a> The Duke of Antin must be an able Lord,<a name="6" href="#note6"><sup>6</sup></a> since His Royal Highness entrusted him with the supervision of the Academy of Inscriptions:<a name="7" href="#note7"><sup>7</sup></a> my &quot;Origins of the French&quot; is somewhat within the jurisdiction of this Academy. I have heard that the Marquis de Torcy had already formed a kind of Academy of Politics, which would be a nursery for public ministers.<a name="8" href="#note8"><sup>8</sup></a> I hope Mr Sully will have at least returned to you my paper I had sent you, Sir, through him, and of which he had asked you for a copy for the Duke of Arenberg; for I wouldn't want it to get lost, it not being for everyone.<a name="9" href="#note9"><sup>9</sup></a> Far from having forgotten the Chinese, I have written a whole discourse on their theology, concerning God, spirits and the soul.<a name="10" href="#note10"><sup>10</sup></a> And it seems to me that we can give a very reasonable sense to their ancient authors. It does not seem to me that the dialogue between the Christian philosopher and the Chinese philosopher, imagined by Father Malebranche,<a name="11" href="#note11"><sup>11</sup></a> suits the characters enough.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Thanks to God, my arthritic ailments are not very painful when I am at rest. So far they do not prevent me from working with the pen in my hand, except when they attack the right hand.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Since health is the most important thing after virtue, I am thinking of some project to be submitted to the wisdom of Monsignor the Regent, concerning the means of better advancing in medicine. I hold that by taking suitable measures, we could advance more in 40 years than otherwise in 400, and that His Royal Highness, being in the vigour of his age, could himself even enjoy the fruits of his attention to this. I am sincerely etc.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;P. S. I beg you, Sir, to close the letter to your brother before sending it to him: I leave it open,<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>namque tu solebas</var><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>meas esse aliquid putare nugas</var>.<a name="12" href="#note12"><sup>12</sup></a> 

                        <br /><br /><br />
                        <hr className='rightcol-hr' />
                        <br /><br />
                        NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> Leibniz is referring to to a French translation he had made of his little book <var>De origine francorum disquisitio</var> (Hanover: Foerster, 1715). The French translation was published as &quot;Essai sur l’origine des Fran&#231;ois&quot; in G. W. Leibniz, <var>Recueil de diverses pieces sur la Philosophie, la Religion Naturelle, L'Histoire, les Mathematiques, &amp;c.</var>, ed. Pierre Desmaizeaux (Amsterdam: Changuion, 1720), 287-325.<br />
                        <a name="note2" href="#2">2.</a> Namely Philippe II, Duke of Orl&#233;ans (1674-1723), who served as Regent from 1715-1723.<br />
                        <a name="note3" href="#3">3.</a> In his letter of 23 December 1715, Remond informed Leibniz that &quot;As soon as I had the little treatise on the Origin of French properly copied and bound, I had the honour to show it to Madam, who I found very informed and who had already spoken about it to His Royal Highness. The next day, as I was at Madam the Duchess of Orleans' in the evening, His Royal Highness went down there; I sent for my present which was received with every possible approval; first he read the verses with which he seemed to flatter me and the beginning of the dissertation, then ordered that it be carried to his cabinet and put on his table. He did me the honour of ordering me to give you many thanks. The few courtiers who were present at first thought that it was some poor book of my making, but I very quickly disabused them and as soon as I mentioned your name respect took the place of jokes and you were spoken of as the ornament of mankind.&quot; G III, 663.<br />
                        <a name="note4" href="#4">4.</a> See Barth&#233;l&#233;my Germon, <var>De veteribus regum francorum</var> (Paris: Rigaud, 1706).<br />
                        <a name="note5" href="#5">5.</a> Remond had informed Leibniz in his letter of 23 December 1715 that &quot;The Duke of Orleans has personally reserved for himself the supervision of the Academy of Sciences.&quot; G III, 663.<br />
                        <a name="note6" href="#6">6.</a> Louis Antoine de Pardaillan (1664-1736), the first Duke of Antin.<br />
                        <a name="note7" href="#7">7.</a> That is, the Acad&#233;mie royale des Inscriptions et M&#233;dailles. In 1716, it was renamed Acad&#233;mie royale des Inscriptions et Belles-Lettres.<br />
                        <a name="note8" href="#8">8.</a> Jean Baptiste de Colbert, Marquis de Torcy (1665-1746), founded the Acad&#233;mie Politique in March 1712.<br />
                        <a name="note9" href="#9">9.</a> Leibniz is referring here to his essay, &quot;Principles of nature and graced, based on reason.&quot; In his letter of 4 September 1715, Remond had informed Leibniz that Sully had the copy of this essay that Leibniz had sent to Remond, and that the Duke of Aremberg wanted to read it too. See G III, 650. In his subsequent letter of 23 December 1715, Remond told Leibniz, &quot;As for Mr Sully, I don’t hear about him&quot; (G III, 663), which prompted Leibniz's concern with the whereabouts of the copy of the &quot;Principles of nature and grace&quot; that had been in Sully's possession.<br />
                        <a name="note10" href="#10">10.</a> Namely the &quot;Discourse on the natural theology of the Chinese.&quot; For an English translation, see G. W. Leibniz, <var>Writings on China</var>, trans and ed. Daniel J. Cook and Henry Rosemont Jr. (Chicago: Open Court, 1994), 75-138.<br />
                        <a name="note11" href="#11">11.</a> Namely Nicolas Malebranche, <var>Entretien d'un philosophe chretien et d'un philosophe chinois sur l'existence et la nature de Dieu</var> (Paris: Michel David, 1708).<br />
                        <a name="note12" href="#12">12.</a> &quot;for you were accustomed to think of my trifles as something worthwhile.&quot; Quoted from Catullus, Poem 1.3-4.<br /><br /><br />
                        &#169; Lloyd Strickland 2019
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
