import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/foucher1685.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series II, volume 1 (2nd edition)</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 878-879<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: end 1685<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (21k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Simon Foucher to Leibniz (end 1685)</title>
            <meta name="description" content="An English translation of a LETTER FROM SIMON FOUCHER TO LEIBNIZ, from end 1685" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>SIMON FOUCHER TO LEIBNIZ</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A II 1, p878</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I have still not been able to see the Journals of Leipzig for the month of November last.<a name="1" href="#note1"><sup>1</sup></a> It is difficult finding them in Paris because of our French journals, whose privilege does not allow one to enjoy others. You will find in this parcel what has been printed of my labour since I had the honour of seeing you, namely the response to Father Robert,<a name="2" href="#note2"><sup>2</sup></a> the commentary on half of my verse of the <var>Sagesse des Anciens</var>,<a name="3" href="#note3"><sup>3</sup></a> that is, of the first 52 verses. Some difficulty from booksellers has made me delay finishing the rest of this book.<a name="4" href="#note4"><sup>4</sup></a> It has still not been put up [<b>A II 1, p879</b>] for sale. I would willingly send you my <var>Logique des Academiciens</var> if I could, but I no longer have a copy of it.<a name="5" href="#note5"><sup>5</sup></a> I hope it will be reprinted soon. I ought to append <var>l'Apologie des Academiciens</var> to the response to Father Robert.<a name="6" href="#note6"><sup>6</sup></a> I have held back responding to him on this subject in order to write a separate book on it. The matter quite deserves it, it seems to me.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mr Huet,<a name="7" href="#note7"><sup>7</sup></a> whom you know, has beseeched me to give you his regards. Our friend Mr Lantin<a name="8" href="#note8"><sup>8</sup></a> has put to music an ode written by Mr Huet.<a name="9" href="#note9"><sup>9</sup></a> If I had been able to have it, I would have sent it to you. The disputes between Mr Arnauld and Father Malebranche are still carrying on. I wish you had been present at some discussions that Father Malebranche and I have had together on philosophy. It always seems to me that his opinion on ideas, which are not ways of being of the soul, is untenable. I do not say the same thing about his views on grace, and I do not give any verdict on this matter, which is beyond my mind. A new book called <var>l'&#233;levation des eaux</var> by an Englishman has recently been seen here.<a name="10" href="#note10"><sup>10</sup></a> I have not yet examined it enough to speak to you about it. A new royal square has been made in Paris, in which will be placed the statue of the King made by the order of the Marshal of Feuillade.<a name="11" href="#note11"><sup>11</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mr Ozanam<a name="12" href="#note12"><sup>12</sup></a> has recently given us a new book on Arithmetic and the progression of numbers.<a name="13" href="#note13"><sup>13</sup></a> As for moral philosophy, I do not find any more indisputable or more useful than that of Epictetus. As for the commentaries by the emperor Marcus Aurelius Antonius, I find them full of such great meaning and so much good that I could not fail to read them. I know that Plato's ethics is the source of that of the Stoics, and especially of more moderns. But it seems to me that these moderns have surpassed ancient ideas. Despite all that, Sir, we would need a very special philosophy, since we still do not have one which is not faulty. I would quite like to have one from your hand, as well as from our friend Mr Lantin. He made me hope for a <var>history of pleasure and pain</var><a name="14" href="#note14"><sup>14</sup></a> - I still do not see anything of it, and I fear that all his works will be lost when death overtakes him. I wish you both an eternal life.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I am, Sir, your very humble servant&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Foucher

                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> Foucher is thinking here of Leibniz's essay 'Meditationes de cognitione, veritate, et ideis', published in the <var>Acta Eruditorum</var>, of Leipzig, in November 1684.<br />
                <a name="note2" href="#2">2.</a> Simon Foucher, <var>Nouvelle Dissertation sur la Recherche de la verit&#233;, contenant la Reponse &#224; la Critique de la Critique de la Recherche de la verit&#233;. O&#249; l'on d&#233;couvre les erreurs des dogmatistes, tant anciens que nouveaux. Avec une discution particuliere du grand principe des Cartesiens</var> [New dissertation on the Search after Truth, containing the response to the critique of the critique of the Search after Truth, in which the errors of the dogmatists, both ancient and new, are revealed, together with a particular discussion on the great principle of the Cartesians] (Paris, 1679).<br />
                <a name="note3" href="#3">3.</a> Simon Foucher, <var>De la sagesse des anciens, o&#249; l'on fait voir que les principales maximes de leur morale ne sont pas contraires au christianisme</var> [On the wisdom of the ancients, in which it is shown that the principal maxims of their ethics are not contrary to Christianity] (Paris, 1682).<br />
                <a name="note4" href="#4">4.</a> A third part of Foucher's <var>De la sagesse des anciens</var> was never printed.<br />
                <a name="note5" href="#5">5.</a> Simon Foucher, <var>Dissertation sur la recherche de la v&#233;rit&#233; ou sur la philosophie des Acad&#233;miciens, o&#249; l'on r&#233;fute des pr&#233;jug&#233;s des dogmatistes, tant anciens que nouveaux, avec un examen particuli&#232;r des sentiments de M. Descartes</var> [Dissertation on the search after truth, or on the philosophy of the Academicians, in which the prejudices of the dogmatists, both ancient and new, are refuted, together with a particular examination of Mr Descartes' views] (Dijon, 1673). The first volume of this book is entitled <var>Logique des acad&#233;miciens</var> [Logic of the Academicians].<br />
                <a name="note6" href="#6">6.</a> Simon Foucher, <var>Dissertation sur la recherche de la v&#233;rit&#233;, contenant l'apologie des acad&#233;miciens...pour servir de r&#233;ponse &#224; la Critique de la critique etc., avec plusieurs remarques sur les erreurs des sens et sur l'origine de la philosophie de M. Descartes</var> [Dissertation on the search after truth, containing the apology for the academicians, to serve as a response to the critique of the critique etc., together with several remarks on the errors of the senses and on the origin of Mr Descartes' philosophy] (Paris, 1687). The 'Father Robert' is Robert Desgabets (1610-1678), who wrote <var>Critique de la Critique de la Recherche de la Verit&#233;</var> [Critique of the Critique of the Search after Truth] (Paris, 1675), which was a response to Foucher's <var>Critique de la Recherche de la Verit&#233; o&#249; l'on examine en m&#234;me-tems une partie des principes de Mr Descartes</var> [Critique of the Search after Truth, in which a part of Mr Descartes' principles are examined at the same time] (Paris, 1675).<br />
                <a name="note7" href="#7">7.</a> Pierre Daniel Huet (1630-1721).<br />
                <a name="note8" href="#8">8.</a> Jean Baptiste Lantin (1620-1695).<br />
                <a name="note9" href="#9">9.</a> Pierre Daniel Huet, <var>Ode, cum inter aulae tumultus patriae desiderio teneretur</var>.<br />
                <a name="note10" href="#10">10.</a> Samuel Morland, <var>Elevation des Eaux, par toute sorte de machines, reduite a la mesure, au poids, et a la balance</var> [Raising of waters by any kind of machines, reduced to measure, weight and balance] (Paris, 1685).<br />
                <a name="note11" href="#11">11.</a> Probably a reference to the Place des Victoires in Paris, which contained a statue of Louis XIV commissioned by Fran&#231;ois d'Aubusson, Duke de la Feuillade.<br />
                <a name="note12" href="#12">12.</a> Jacques Ozanam (1640-1717).<br />
                <a name="note13" href="#13">13.</a> The Akademie editors state that this report has not been confirmed.<br />
                <a name="note14" href="#14">14.</a> Foucher is referring to Lantin's missing manuscript <var>Trait&#233; de la joie et de la douleur</var> [Treatise on joy and pain].


        <br /><br /><br />
                &#169; Lloyd Strickland 2007
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
