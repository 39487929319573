import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/salvation.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
        Shelfmark LH 1, 1, 4 Bl. 73<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        Date: 1702(?)<br /><br />
        Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (172k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>On salvation outside the visible church</title>
            <meta name="description" content="An English translation of a ON SALVATION OUTSIDE THE VISIBLE CHURCH by Gottfried Wilhelm Leibniz, from 1702?" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: ON SALVATION OUTSIDE THE VISIBLE CHURCH</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>LH 1, 1, 4 Bl. 73</b>]<br />
                <br />
                On salvation outside the visible church<br /><br />
                Milletiere<a name="1" href="#note1"><sup>1</sup></a> inappropriately dedicated a book on controversy (entitled <var>La Victoire de la verit&#233; pour la paix de l'&#201;glise</var>)<a name="2" href="#note2"><sup>2</sup></a> to Charles II, King of England, when he was in exile, in order to invite the King of Great Britain to embrace the Catholic faith. When he did so, an English bishop who was with the King responded to him through a discourse in 8&#186; printed in Geneva in 1655. And as Milletiere appeared to maintain that King Charles I was a true martyr only in the Roman Church, in which he claimed that the King had died, the prelate refuted this fable, and as for Milletiere's maxim that there was no other church than his capable of engendering such a child, the bishop opposed it with a very remarkable dogma that Richard Smith, Bishop of Chalcedon (who had had ordinary authority over the papists in England around 1626 and sometime after) had maintained in two treatises that he published, namely: &quot;if those who live in the communion of the Protestant church endeavour to learn the truth, and cannot attain it on account of their insufficiency, but implicitly embrace it by preparing their hearts to receive it, and are always ready to do so at such time when it shall please God to reveal it to them, they cannot fail the true church, faith, and salvation.&quot;<a name="3" href="#note3"><sup>3</sup></a> Having related this in his <var>Dictionnaire</var>, article &quot;Milletiere&quot;, p2112, Mr Bayle adds: &quot;Here is a maxim able to furnish many reflections for a supplement to the <var>Philosophical Commentary on Compel them to come in</var>.&quot;<a name="4" href="#note4"><sup>4</sup></a> He adds in the margin: &quot;compare what Caramuel says in Nicolle's work on the unity of the church, p. 71.&quot;




                    <br /><br /><br />
                    <hr className='rightcol-hr' />
                    <br /><br />
                    NOTES:<br /><br />
                    <a name="note1" href="#1">1.</a> Th&#233;ophile Brachet de La Milleti&#232;re (1588-1665), a Calvinist theologian.<br />
                    <a name="note2" href="#2">2.</a> Th&#233;ophile Brachet de La Milleti&#232;re, <var>La Victoire de la verit&#233; pour la paix de l'&#201;glise</var> (Paris, 1651). Leibniz gives the title as <var>La Triomphe de la verit&#233; pour la paix de l'&#201;glise</var>.<br />
                    <a name="note3" href="#3">3.</a> Quoted from Pierre Bayle, <var>Dictionnaire historique et critique</var> (Rotterdam, 1702, 2ed), 2112: article &quot;Milletiere&quot; note G.<br />
                    <a name="note4" href="#4">4.</a> A reference to Bayle's <var>Commentaire philosophique sur ces paroles de Jesus-Christ: Contrain-les d'entrer</var> (Canterbury [Amsterdam], 1686).<br /><br /><br />
                    &#169; Lloyd Strickland 2016
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
