import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/purification.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>Otium Hanoveranum</var><br />
		Joachim Friedrich Feller (ed)<br />
		p 179<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: not later than spring 1698<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (9k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>On the Time of Purification</title>
            <meta name="description" content="An English translation of ON THE TIME OF PURIFICATION by Gottfried Wilhelm Leibniz, written no later than Spring 1698" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: ON THE TIME OF PURIFICATION</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>OH p179</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>The time of purification</var> lasts as long as is needed for a soul to turn over in its contemplations the wickedness of its former sin, and therefore this pain consists in a vision of sin, evil and the devil, just as heavenly joy consists in the vision of God and the good. But those who die in enmity towards God, their evil is infinite since a will to harm is an infinite evil. For he who wills to harm has not just willed [to harm] that which he has harmed, but also all those things which, since he cannot harm them, he has neglected, and these are infinite. However the act of harming without the will to do so does not have a definite nature.
                <br /><br /><br />
                &#169; Lloyd Strickland 2007
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
