import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/bayle-slt.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>Die philophischen schriften von Gottfried Wilheim Leibniz, vol. III</var><br />
		C. I. Gerhardt (ed)<br />
		pp 58-59, 61<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 1702?<br /><br />
		Translated from the French<br /><br />
        Note - the portion of this text that is included in my <var>Shorter Leibniz Texts</var> is not included here.<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (12k)</Link><br /><br />
        <Link to="/slt">Back to <var>Shorter Leibniz Texts</var></Link><br /><br />
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Pierre Bayle (1702?)</title>
            <meta name="description" content="An English translation of a LETTER TO PIERRE BAYLE by Gottfried Wilhelm Leibniz, from 1702?" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO PIERRE BAYLE</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>G III p58</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Your letters could never come too late, since they say things that do not age at all, but at the same time they could never come too soon, because of the pleasure and the instruction that they give. Thus people must be extremely obliged to you for them whenever they arrive, and myself especially, since I profit from them in a wholly unique way. If I had known that your excellent <var>Dictionary</var> would reappear so soon, I would have begged you to add my response to it in order to have everything there together; and also presently if you make me aware of the difficulties that might remain, and if I can give some clarification to them, you could say what you deem apt about them with your judgement.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I will nevertheless follow the thread of your letter. You remarked there, Sir, that strong minds are stumped by the difficulties of the free will of man, and that they say that they cannot understand how, if the soul is a created substance, it can have its own true and internal force of acting. I would like to understand more distinctly why created substance cannot have such a force, since I would rather believe that without it, it could not be a substance. In my opinion, the nature of substance consists in this ordered tendency, from which phenomena arise by order, which it received at the start and which is conserved in it by the author of things, from whom all realities or perfections always emanate by a kind of continual creation.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;With regards to free will, I am of the opinion of the Thomists and other philosophers who believe that everything is predetermined, and I do not see any grounds to doubt it. Yet that does not stop us from having a freedom exempt not only from constraint, but also from necessity, and in that it is with us just as it is with God himself, who is [<b>G III p59</b>] always determined in his actions too, since he could not fail to choose the best. But if he did not have anything from which to choose, and if what he does were the only thing possible, he would be subjected to necessity. The more one is perfect, the more one is determined to the good, and therefore freer at the same time. For we have a power and knowledge all the more extended, and a will all the more strengthened in the limits of perfect reason.
        <br /><br />

        ......<br /><br />

        [<b>G III p61</b>]<br /><br />
        
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sir, it certainly is with good reason that people admire the fact that these immense factual researches you have made have not harmed your wonderful reflections on what is most profound in philosophy. I likewise cannot always exempt myself from these sorts of discussions, having even been obliged to consider some genealogical questions, which would be most frivolous if the interest of states did not often depend on them. I have also worked extensively on the history of Germany inasmuch as it relates to this country, which has even furnished me with some observations pertaining to universal history. Thus I learned not to neglect factual knowledge. But if I had the choice, I would prefer natural history to civil history, and the customs and laws that God established in nature to those which are observed among men. I am sincerely, and with much obligation etc.
                <br /><br /><br />
                &#169; Lloyd Strickland 2005<br />
                With thanks to Geert de Wilde
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
