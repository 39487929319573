import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/stpierre.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>Recueil de diverses pieces, sur la philosophie, la religion naturelle, l'histoire, les mathematiques, &c. par Mrs Leibniz, Clarke, Newton, & autres auteurs c&#233;l&#232;bres, volume 2</var><br />
		Amsterdam: 1740 (2nd edition)<br />
		pp 158-161<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 7 Febuary 1715<br /><br />
		Translated from the French<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (14k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Abbe de St. Pierre (7 February 1715)</title>
            <meta name="description" content="An English translation of a LETTER TO ABBE DE ST. PIERRE by Gottfried Wilhelm Leibniz, from 7 February 1715" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO ABB&#201; DE ST. PIERRE</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>p158</b>]<br /><br />
		        <div align="right">Hanover, 7 February 1715</div>
                <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sir<br /><br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I consider myself very honoured by the communication about your project,<a name="1" href="#note1"><sup>1</sup></a> and by your request for my opinion on a matter which concerns all of human kind, and which is not entirely outside of my scope, since from my youth I have applied myself to law, and especially to that of nations. The package from Mr Varignon reached Hanover a long time before I was back home, and I have been very busy after my return. But I have finally made [<b>p159</b>] some effort to pull myself away, and to read your excellent work with care. I found it very sound and agreeable, and after having understood your system I took a particular pleasure in the variety of objections, and in your clear and rounded way of responding to them. Men are only lacking the will to deliver themselves from an infinity of evils. If five or six people wanted to, they could bring the great schism in the West to an end, and sort out the church. A sovereign who wants to do so can protect his estates from the plague. The House of Brunswick has not been entirely unsuccessful in this, thanks to God. In my time, the plague has stopped at its frontiers. A sovereign could even protect his estates from famine. But to bring wars to an end, another Henry IV, together with some great princes of his time, would have to welcome your great project. The problem is that it is difficult to get great princes to listen to it. One individual dare not take too much licence, and I am even afraid that minor sovereigns dare not propose it to great ones. A minister could perhaps do so at the point of death, especially if family interests did not oblige him [<b>p160</b>] to continue his politics to the tomb and beyond. However it is always good to inform the public of it; somebody will be able to be touched by it when they are least thinking about it.<br /><br />

                        <div align="center"><var>Semper tibi pendeat hamus</var><br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Quo minime reris gurgite piscis erit</var><br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ovid<a name="2" href="#note2"><sup>2</sup></a><br /><br /></div>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;There is no minister now who would want to propose to the Emperor that he give up the succession of Spain and the Indies. The maritime powers and so many others have needlessly concerned themselves with it. Most often it is chance events that prevent men from being happy. The hope of making the Spanish monarchy pass into the House of France was the source of fifty years of war, and it is to be feared that the hope of bringing them out of it will disturb Europe for another fifty years. To help the Emperor to dispel the Turks from Europe would perhaps be the way to overcome this problem. But such a scheme would still have great difficulties.<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;As you are preparing, Sir, an expanded third edition, it would perhaps be good that your work be even [<b>p161</b>] more embellished by examples and by history. The arguments do not become better, but this embellishment gives them ingress. This was the fashion in the time of Mr de la Mothe le Vayer.<a name="3" href="#note3"><sup>3</sup></a> French writers today, under the pretext of distancing themselves from pedantry, have lost a little too much of the habit of including flashes of erudition into their works. They are no less solid for it, but they are drier. A certain middle ground would work well in a work like yours. But if that would hold you up too much, there is no need to bother yourself with it. My remarks can nevertheless give some occasion for it. I wish you Sir, as long a life as is needed to enjoy the fruits of your labours, and I am sincerely,<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sir<br /><br />
                <div align="right">Your very humble and very obedient servant<br /><br />
                Leibniz
                </div>
                        <br /><br /><br />
                        <hr className='rightcol-hr' />
                        <br /><br />
                        NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> Namely, the plan for perpetual peace in Europe.<br />
                        <a name="note2" href="#2">2.</a> A slight misquote of Ovid, <var>Ars Amatoria</var> III.425-6: 'semper tibi pendeat hamus; quo minime credas gurgite, piscis erit' [Always have the bait ready. The fish will come and bite when you least expect it].<br />
                        <a name="note3" href="#3">3.</a> Fran&#231;ois de la Mothe le Vayer (1588-1672).
                <br /><br /><br />
                        &#169; Lloyd Strickland 2007
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
