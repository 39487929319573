import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/transmigration.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
		Shelfmark LBr 40 Bl. 22-23<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: c. 1702 - 1705<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (186k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>On Unities and Transmigration</title>
            <meta name="description" content="An English translation of ON UNITIES AND TRANSMIGRATION by Gottfried Wilhelm Leibniz, from c. 1702 - 1705" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: ON UNITIES AND TRANSMIGRATION</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 <small>For further details and analysis of this text, see Lloyd Strickland, &quot;Leibniz vs. transmigration: a previously unpublished text from the early 1700s&quot;, <var>Quaestiones Disputatae</var>, 7(2), 139-159. Available <a href="https://www.academia.edu/32742756/Leibniz_vs_transmigration_a_previously_unpublished_text_from_the_early_1700s" target="_blank">here</a>.
                </small><br /><br /><br />
                [<b>LBr 40 Bl. 22r</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If there were no true unities, there would be no multitude. Now true unities must not have parts otherwise they would be only accumulations of these parts, and consequently they would be multitudes, and not true unities at all. It may even be said that unities alone are entirely real beings, since accumulations or aggregates are formed by thought, which consists of such and such unities at the same time. And all the reality of things consists only in these unities.<a name="1" href="#note1"><sup>1</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;That being so, since there are some modifications and some changes of modification in things, this must be the result of modifications and changes in the unities. And these unities must contain some reality too, otherwise they would be nothing. They must also have predicates which make them different from each other,<a name="2" href="#note2"><sup>2</sup></a> and capable of change.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Now the variety in the unity, or in the indivisible, is precisely what we oppose to the modifications of extension—that is, to figures and motions—and consequently is what we call perception, and sometimes thought, when it is accompanied by reflection. So it is clear that these unities are nothing other than what is called &quot;soul&quot; in animals, &quot;principle of life&quot; in living things, and &quot;primitive entelechy&quot; in all organic bodies, or natural machines, which have some resemblance with animals.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Now, as there is no way of explaining how one unity has influence on another, and as it is unreasonable to resort to invoking<a name="3" href="#note3"><sup>3</sup></a> a particular direction of God, as if he always gave to souls or unities impressions which correspond to the body’s passions, it remains only to say that each unity expresses—by its own nature and according to its point of view—everything that happens outside. So the union of the soul with [<b>LBr 40 Bl. 22v</b>] its body, in which it is dominant, is nothing other than the spontaneous agreement of their phenomena.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;And since the passage from one impression to another in the body can always be explained by mechanical laws, we should not be surprised that the soul also passes, of itself, by virtue of its representative nature, from one representation to another, and consequently from joy to pain, just as the situation of the body—and of the universe with regard to this body—requires. And it was well noted by Socrates, according to Plato, that the passage or path from pleasure to pain is very short.<a name="4" href="#note4"><sup>4</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;From all that it also follows that souls cannot perish naturally, any more than the universe, and that some perceptions must always remain in them, just as they have always had for as long as they have existed, since nothing comes into them from outside, and since everything happens in them in a perfect spontaneity.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Yet it must be admitted that they are quite often in a state of sleep, in which their perceptions are not sufficiently distinguished to attract attention and establish memory. But as each unity is the mirror of the universe in its way, it is reasonable to think that there will be no eternal sleep for it, and that its perceptions develop in a certain order, doubtless the best that is possible. It is like in crystallizations of mixed salts that are finally separated and return to some order.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It should even be said that, accordingly to the precise correspondence of the soul and body, the organic body always subsists, and can never be destroyed, so that not only the soul but also the animal must remain. This is due to the fact that the least part of the organic body is still organic, nature’s machines being folded in themselves to infinity. Thus fire and other external forces can only ever disturb the outside.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We cannot always determine whether certain masses are animated or entelechied, because we cannot always say whether they form an organic body or are only accumulations, as for example I cannot decide anything about the sun, the globe of the Earth, or a diamond.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                There is some probability that all intelligent created substances have an organic body which is proper to them. However, a question would be whether it is possible that there are some substances which pass from body to body in a certain order and others which are always attached to the same body.<br /><br />

                [<b>LBr 40 Bl. 23r</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;But I doubt this change could be distinctly explained, and consequently I doubt it is in conformity with order. For we would have to suppose the destruction of an organic body in order to deprive it of the soul, for every organic body has in it—by dint of reason—what it can fittingly have in it. And every organic body of nature, being infinitely enfolded, is indestructible. And the proof that it is infinitely enfolded is that it expresses everything. Moreover, the body must express the future state of the soul or entelechy it has, and it does that by expressing its own future state.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Assuming that no new organic bodies are formed and that the old ones are not destroyed, what indication will we have to say that the soul of one organic body has gone into another, besides that two souls are not in the same organic body, and that there would thus have to be an exchange of souls? Moreover, this exchange of souls is noticeable in bodies or it isn’t: if it is not noticeable, it is contrary to order since the body must express everything. If it should be noticeable there, we would have to see how that might happen. What means are there of expressing the passage of a soul by the laws of mechanics?<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Yet it would be possible to except minds, in which it would be not mechanical laws but moral laws which would record the transfer and the identity of one soul with another. For I call minds the entelechies or souls that have the capacity for eternal truths, sciences, and demonstrations, and which may be considered as subjects of a government such as that of the City of God, whose monarch is the supreme<a name="5" href="#note5"><sup>5</sup></a> intelligence. Now it could happen that one and the same intelligence passed from one body into another.<a name="6" href="#note6"><sup>6</sup></a> In that case, the mechanical laws themselves would make reborn elsewhere a life which continues my life, and an intelligence which claims for itself what has happened to me, its perceptions and the movements of its body (which mutually correspond) leading it to have thoughts that would, in effect, be the memory of the principal things that have happened to me, so that morally this intelligence would be me, and would continue as me. That seems possible, but it seems to me more in keeping with order that moral identity is always accompanied by a physical identity, and that each unity, being the universe in miniature, is governed also according to moral laws.
                <br /><br /><br />
                        <hr className='rightcol-hr' />
                        <br /><br />
                        NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> unities. │ As unities do not have parts containing what is called 'soul' in animals and 'entelechy' in other organic beings │ <var>deleted</var>.<br />
                        <a name="note2" href="#2">2.</a> other, │ each varied and subject to some change │ <var>deleted</var>.<br />
                        <a name="note3" href="#3">3.</a> invoking │ a perpetual intervention │ <var>deleted</var>.<br />
                        <a name="note4" href="#4">4.</a> See Plato, <var>Phaedo</var>, 60b.<br />
                        <a name="note5" href="#5">5.</a> supreme │ substance │ <var>deleted</var>.<br />
                        <a name="note6" href="#6">6.</a> another │ otherwise than by mechanical laws │ <var>deleted</var>.
                        <br /><br /><br />

                        <br /><br /><br />
                        &#169; Lloyd Strickland 2016
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
