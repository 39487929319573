import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/bierling1709.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>Die philophischen schriften von Gottfried Wilheim Leibniz, vol. VII</var><br />
		C. I. Gerhardt (ed)<br />
		pp 488-489<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 24 October 1709<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (10k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Friedrich Wilhelm Bierling (24 October 1709)</title>
            <meta name="description" content="An English translation of a LETTER TO FRIEDRICH WILHELM BIERLING by Gottfried Wilhelm Leibniz, from 24 October 1709" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO FRIEDRICH WILHELM BIERLING</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>G VII p488</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I agree that logic, if well taught and practically applied, is not to be scorned at all. Indeed, nothing more useful could befall mortals than a logic more perfect than the one we possess. In Locke's book there are some particular points which are not badly expounded, but in general he has strayed far from the straight and narrow and has not understood the nature of the mind and of truth. If he had adequately considered the difference between necessary truths, i.e. those perceived by demonstration, and those which are known to us only through induction, he would have noticed that necessary truths can only be proved from principles intrinsic to the mind, because the senses certainly teach us what happens but not what happens necessarily. Likewise, he has not adequately observed that the ideas of being, of one and the same substance, of truth, of good, and many other things are innate in our mind because it is innate to itself, and that it discovers all these things in itself. For indeed, there is nothing in the intellect which was not in the senses, except the intellect itself. Many other critical observations could be made on Locke, since he even silently undermines the natural immateriality of the soul. He inclines towards the Socinians [<b>G VII p489</b>] (as even his friend Mr Le Clerc says), whose philosophy concerning God and the mind has always been rather poor.<br /><br /><br />
                &#169; Lloyd Strickland 2007
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
