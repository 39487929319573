import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Image from '../images/monadology.jpg';
import { Helmet } from 'react-helmet';

function Monadology() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        <a href="http://www.amazon.co.uk/Leibnizs-Monadology-New-Translation-Guide/dp/074869322X/ref=sr_1_4?ie=UTF8&qid=1411897821&sr=8-4&keywords=monadology" target="_blank">Buy from Amazon (UK)</a><br /><br />
	<a href="https://edinburghuniversitypress.com/book-leibniz-039-s-monadology.html" target="_blank">Buy directly from the publisher</a><br />
    <br />{isMobile ? '' : <hr className="centered-hr" />}
    <p>Testimonial</p>
    &quot;The <i>Monadology</i> is probably the most widely studied of Leibniz's works but it is also among the most difficult. This book provides an in-depth commentary that is clearly written, superbly documented and philosophically penetrating; it will be a great help to students of all levels.&quot;<br />
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Nicholas Jolley, <var>Emeritus Professor of Philosophy, University of California, Irvine</var><br /><br />
      </div>
    );
  }
 
  return (
    <div>
      <Helmet>
            <title>Leibniz's Monadology</title>
            <meta name="description" content="A book authored by Lloyd Strickland on 'Leibniz's Monadology'" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ'S MONADOLOGY</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                  <img src={Image} alt="Leibniz's Monadology" style={{ float: 'left', marginRight: '20px', height: isMobile ? '220px' : '304px', width: 'auto' }} />
                  <p style={{ margin: '10px 0px 0px 0px', fontSize: '13px' }}>Published September 2014 by Edinburgh University Press (UK). Distributed in the US by Oxford University Press (US).</p>
                  <br></br><p>From the back cover blurb:</p>
                  A fresh translation and in-depth commentary of Leibniz's seminal text, the <var>Monadology</var>.<br /><br />
                    Written in 1714, the <var>Monadology</var> is widely considered to be the classic statement of Leibniz's mature philosophy. In the space of 90 numbered paragraphs, totalling little more than 6000 words, Leibniz outlines - and argues for - the core features of his philosophical system. Although rightly regarded as a masterpiece, it is also a very condensed work that generations of students have struggled to understand.<br /><br />
                    Lloyd Strickland presents a new translation of the <var>Monadology</var>, alongside key parts of the <var>Theodicy</var>, and an in-depth, section-by-section commentary that explains in detail not just <var>what</var> Leibniz is saying in the text but also <var>why</var> he says it. The sharp focus on the various arguments and other justifications Leibniz puts forward makes possible a deeper and more sympathetic understanding of his doctrines.<br clear = "all" /><br />
                    Key features:<br /><br />
                    <ol>
                    <li>A new translation of Leibniz's seminal text, by a well-known translator of Leibniz's works</li>
                    <li>A complete, in-depth, section-by-section commentary of the text, bringing to light Leibniz's arguments, principles and assumptions</li>
                    <li>Includes a detailed introduction, substantial supplementary texts, a glossary of terms, questions for further study and suggestions for further reading to help you gain a solid understanding of the text</li>
                    </ol>
                    <br />
                    <b>Contents:</b><br /><br></br>
                    <ol>
                    <li>Introduction</li>
                    <li>About the Text and Translation</li>
                    <li>The <var>Monadology</var></li>
                    <li>The Structure of the <var>Monadology</var></li>
                    <li>The <var>Monadology</var>: Text with Running Commentary</li>
                    <li>Appendix (including:<ol>
                    <li>Complete translations of all 130+ sections of the <var>Theodicy</var> (1710) referenced in the <var>Monadology</var> itself</li>
                    <li>Complete translation of <var>Principles of Nature and Grace</var> (1714)</li>
                    <li>Complete translation of Leibniz's <var>Appendix on Monads</var> (1714)</li></ol></li>
                    <li>Glossary of Terms</li>
                    <li>Questions for Further Study</li>
                    <li>Further Reading</li>
                    <li>Index</li>
                    </ol>
                    <br />
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Monadology;