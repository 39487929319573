import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/vindication.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Sources:<br /><br />
		<var>S&#228;mtliche schriften und briefe series VI volume 4</var><br />
        Deutsche Akademie der Wissenschaften (ed)<br />
        p 1528<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: early 1686 (?)<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (72k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>A vindication of divine justice and human freedom</title>
            <meta name="description" content="An English translation of A VINDICATION OF DIVINE JUSTICE AND HUMAN FREEDOM by Gottfried Wilhelm Leibniz, from early 1686?" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: A VINDICATION OF DIVINE JUSTICE AND HUMAN FREEDOM</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A VI 4, p1528</b>]<br /><br />
                <center><var>A vindication of divine justice and human freedom,</var></center>
                <center><var>based upon a consideration of the complete idea</var></center>
                <center><var>God has about the creatable thing.</var></center><br />   
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;There are two famous labyrinths in errors, one of which has chiefly exercised theologians, the other philosophers; the former is about freedom, the latter about the composition of the continuum, since the former touches upon the inner nature of the mind, the latter upon the inner nature of the body. And just as we can be geometers and physicists even if we do not consider whether a line is composed of points (so long as we assume, in place of indivisibles, quantities so small that any error which may then arise is smaller than a given number, i.e. as small as we want), so it is possible to satisfy theological truth even if we do not know the manner in which things and the action of things depend upon God as well as upon each other (provided that we assume, instead of actual things themselves, the complete notions or ideas of possible things, which&mdash;it cannot be denied&mdash;are in the divine mind before every decree of the will and before the existence of things).
                        <br /><br /><br />
                        &#169; Lloyd Strickland 2003. Revised 2022
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
