import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/foucher1695.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>S&#228;mtliche schriften und briefe series II, volume 3</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		p 55<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 5/15 July 1695<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (99k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Simon Foucher (5/15 July 1695)</title>
            <meta name="description" content="An English translation of A LETTER TO SIMON FOUCHER by Gottfried Wilhelm Leibniz, from 5/15 July 1695" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO SIMON FOUCHER</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A II 3 p55</b>]<br /><br />

                Extract from my letter to Mr Abb&#233; Foucher <span style={{float: 'right'}}>5/15 July 1695</span><br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You will have seen that my entire system<a name="1" href="#note1"><sup>1</sup></a> is based on the consideration of real unity that is indestructible and <var>sui juris</var>.<a name="2" href="#note2"><sup>2</sup></a> Each expresses the whole universe in a manner peculiar to it, and this happens by the laws of its own nature without it receiving influences from outside, except that of God, who has caused it to subsist by a continual renewal ever since he created it. If Mr Lantin had lived, I think he would have taken a special pleasure in these considerations, as can be judged by a letter he wrote to me about 24 years ago,<a name="3" href="#note3"><sup>3</sup></a> thinking from then that my meditations about <var>conatus</var> could even clarify matters of metaphysics.<a name="4" href="#note4"><sup>4</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If the public receives these meditations well, I will be encouraged to give in addition some rather singular thoughts of mine designed to remove the difficulties <var>regarding fate and contingency</var>, and to clarify an essential difference that can be conceived between material forms and intelligences or spirits. This will be found all the more curious because mathematics serves it marvelously in such a way that unless one already has some smattering of mathematics it would be difficult for it to come to mind.
                        <br /><br /><br />
                        <hr className='rightcol-hr' />
                        <br /><br />
                        NOTE:<br /><br />
                        <a name="note1" href="#1">1.</a> That is, Leibniz's &quot;New system of the nature of the communication of substances&quot; (SLT 68-77), which had been published in the <var>Journal des Savants</var> 27 June and 4 July 1695.<br />
                        <a name="note2" href="#2">2.</a> &quot;of its own right&quot; or &quot;independent.&quot;<br />
                        <a name="note3" href="#3">3.</a> Jean-Baptiste Lantin (1620-1695). No correspondence between Lantin and Leibniz has survived.<br />
                        <a name="note4" href="#4">4.</a> Leibniz is referring here to his <var>Theoria motus abstracti</var>; see A VI 2, 258-276.<br /><br /><br />
                        &#169; Lloyd Strickland 2018
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
