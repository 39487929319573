import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/divisions.pdf'; 
import { Helmet } from 'react-helmet';

function Divisions() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series VI volume 4</var><br />
        Deutsche Akademie der Wissenschaften (ed)<br />
        pp 574-576<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: summer 1683 - winter 1685/6 (?)<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (169k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Divisions</title>
            <meta name="description" content="An English translation of DIVISIONS by Gottfried Wilhelm Leibniz, from summer 1683 - winter 1685/6 (?)" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: DIVISIONS</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A VI 4, p574</b>]<br /><br />
                Every meaningful word is either a term or a particle or a composite of them.
                <p style={{marginLeft: "40px"}}>A verb is composed of a name, or term, and the particle <var>est</var> [is]. The adverb is to the verb as the adjective is to the substantive. Under particles I also include affixes, that is,  parts of words which are not meaningful when taken separately, like <var>re</var>- and -<var>tive</var>.</p>
                Every term is either concrete or abstract.
                <p style={{marginLeft: "40px"}}>But it is fitting to exclude abstracts now, since they can be eliminated if needs be.</p>
                A concrete term is either a substance, i.e. a thing, or an accident, i.e. a mode, which does not exhibit a complete concept, unless the thing is implied.
                <p style={{marginLeft: "40px"}}>Since I have excluded abstracts, I have also excluded substantive nouns which do not signify a substance but an accident. In concretes, then, every substantive signifies a substance, and every adjective signifies an accident. Thus <var>circle</var> (substantive) signifies a circular thing or substance. And under adjective is always understood accident, unless [<b>A VI 4, p575</b>] it is not understood substantively by ellipsis.</p>
                Complete is the concrete term which already includes everything that can be predicated of the same subject; it is also called <var>individual substance</var>.
                <p style={{marginLeft: "40px"}}>That is, he who perfectly understands the concept of some individual substance would already know all of its predicates from this concept. Thus in the concept of Peter the Apostle, God conceives everything that has ever happened or will happen to this Peter, just as in the [concept of the] circular he conceives everything that geometers can demonstrate of it. But in the concept of the circular, he does not conceive everything that can be stated about the circular, because the subject which can be thought circular is not conceived at the same time.</p>
                Substance is a term which cannot be predicated of its complete subject by accident but only by itself. But an accident is, by its own nature, indefinite, whether it is predicated by accident or indeed by itself.
                <p style={{marginLeft: "40px"}}>Thus when I say <var>man</var>, <var>animal</var>, <var>body</var>, <var>substance</var>, or <var>thing</var>, I say by that that this term concerns the essence of the individuals about which it is said, for it is said by itself of Peter that he is a man, that he is an animal etc. But it is said by accident of Peter that he was a sinner, nailed to the cross, for these things followed from the concurring series of external things. In this way we have finally undone the knot that has defeated philosophers till now, for thus far they have not set forth any precise notion how substance is distinguished from accident. But, you will say, <var>omnipotence</var> cannot be said except by itself, since it is said only of God. It can be responded that it is also said of the man that [<b>A VI 4, p576</b>] God made omnipotent. And the proposition &quot;this man is omnipotent&quot; is at any rate true by accident.</p>
                A being is either real or apparent.
                <p style={{marginLeft: "40px"}}>Real like the Sun, imaginary like the parhelion, or a rainbow or other phenomena.</p>
                Every thing is either a being by itself or a being by accident; a being by itself is what is really one, like a man, an animal; a being by accident is what is also called a being by aggregation, like a crowd of men, a pile of wood, a machine.
                <p style={{marginLeft: "40px"}}>If animals are machines, if bodies are devoid of substantial form, they will be beings by accident; indeed, in the end it can be shown that, supposing every body is a being by accident, a body will be an imaginary thing, or a phenomenon, like the rainbow.</p>
                Every thing is either perfect, or absolute, which is called <var>God</var>, or limited, which is called <var>creature</var>.
                <p style={{marginLeft: "40px"}}>From which it is clear that God is by his very definition a complete term, or an individual substance, and consequently that he is unique.</p>
                <br /><br /><br />
		
		        &#169; Lloyd Strickland 2018
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Divisions;
