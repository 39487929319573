import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/extract.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series I volume 12</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 534-535<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 3 April 1696<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (90k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to ???: "Extract from a letter to one of my friends" (3 April 1696)</title>
            <meta name="description" content="An English translation of EXTRACT FROM A LETTER TO ONE OF MY FRIENDS by Gottfried Wilhelm Leibniz, from 3 April 1696" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO ???: &quot;EXTRACT FROM A LETTER TO ONE OF MY FRIENDS&quot;</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A I 12, p534</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Extract from a letter to one of my friends, 3 April 1696<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I have just read the fine book on controversies by the late Mr Pufendorf,<a name="1" href="#note1"><sup>1</sup></a> and I find in it many excellent thoughts, worthy of the reputation of the author. If there are sentiments here and there that I would not want to follow entirely, that should not detract from the credit that the book deserves. In profound and difficult matters, the cleverest man in the world is liable to fall short sometimes.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I have a tendency to believe that the opinions of the Reformed, in the manner that they are taught today in German universities, particularly as regards predestination, are very excusable. And I said and wrote more than 20 years ago that I believe the schism that exists between the Evangelicals and the Reformed could and should be removed. It seems it is the opinion of Mr Pufendorf himself that the differences over Holy Communion are not sufficiently important to merit a separation. And the moderation he displays on that subject very much deserves to be praised. And although he dwells more on the article of predestination, where he seems to think that the Reformed attack the foundations of the faith, I nevertheless find that what they say about it could just as easily be interpreted favourably. And apart from certain expressions, the harshness of which ought to be softened, and which are also found in Luther's excellent work <var>de servo Arbitrio</var>,<a name="2" href="#note2"><sup>2</sup></a> the rest appears to me to be quite acceptable or at least very excusable.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;When one says with us that election comes about <var>ex fide praevisa</var>,<a name="3" href="#note3"><sup>3</sup></a> that is, that God chooses those whom he foresees persevering in living faith, one speaks the truth. But this does not say enough, because this very faith is a gift that God does not give to all. It is rightly said that God offers it to all men and that all do not receive it; but as all the good in us comes from God, it must be considered that the very will to receive what God offers us is itself a gift from God. Thus everything boils down to grace, pure and simple, as Saint Augustine well understood.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I agree that one must not imagine in God an absolute good pleasure, without any reason, <var>ubi stet pro ratione voluntas</var>,<a name="4" href="#note4"><sup>4</sup></a> and if some Reformed have had this idea of [<b>A I 12, p535</b>] God then they were wrong, for it is contrary to God's wisdom to act without reason. Thus it must be held as certain that there are reasons which have inclined God to bestow his graces in such a way that they have had a full effect in some and no effect in others. But these reasons should not be sought in our good qualities (namely faith or works) which are themselves gifts from God, but in the harmony of the universe or in this <var>altitudo divitiarum</var><a name="5" href="#note5"><sup>5</sup></a> of which St. Paul speaks.<a name="6" href="#note6"><sup>6</sup></a> It is sufficient that we know in general that God chooses the best, in accordance with what the perfection of the universe demands, although the detail be incomprehensible to us in this life.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;But I do not know how I became involved, without realising it, in this profound discussion, which men could have done without; but since questions <var>de fato</var><a name="7" href="#note7"><sup>7</sup></a> have been a thorny subject for so many centuries, and for so many nations, one cannot always avoid examining them in order to reduce their difficulty.

                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />

                <a name="note1" href="#1">1.</a> Samuel Pufendorf, <var>Jus feciale divinum. Sive de consensu et dissensu Protestantium exercitatio postuma</var> (Lubeck, 1695).<br />
                <a name="note2" href="#2">2.</a> Martin Luther, <var>De servo arbitrio</var> [<var>On the bondage of the will</var>] (Wittemberg: Johann Lufft, 1525).<br />
                <a name="note3" href="#3">3.</a> &quot;from faith foreseen&quot;.<br />
                <a name="note4" href="#4">4.</a> &quot;where the will stands in place of reason&quot;.<br />
                <a name="note5" href="#5">5.</a> &quot;depth of riches&quot;.<br />
                <a name="note6" href="#6">6.</a> See Romans 11.33.<br />
                <a name="note7" href="#7">7.</a> &quot;about fate&quot;.<br />
        <br /><br />
                &#169; Lloyd Strickland 2004. Revised 2019.<br />
                With gratitude to Elizabeth Vinestock for advice and suggestions.
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
