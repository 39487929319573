import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/1694notes.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
        Shelfmark LH I, 5, 2, Bl. 30<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        Date: 1694<br /><br />
        Translated from the French<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (140k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Reading notes on "Verhandeling van de Helle"</title>
            <meta name="description" content="An English translation of READING NOTES ON 'VERHANDELING VAN DE HELLE' by Gottfried Wilhelm Leibniz, from 1694" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: READING NOTES ON <var>VERHANDELING VAN DE HELLE</var><a name="1" href="#note1"><sup>1</sup></a></h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>LH I, 5, 2, Bl. 30r</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;What you see written in a book, Revelations 1.11.<a name="2" href="#note2"><sup>2</sup></a> Some conceal the truths which can expose them to scorn or hatred. This is not in keeping with the example of Jesus Christ and the apostles. Hebrews 11.26.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The authority of men should not be used as a proof, but as a witness against them.<a name="3" href="#note3"><sup>3</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I will try to explain the true explanation of the words 'Sheol', 'Haides' and 'Gehenna'. William Whitaker says in his response to Campion,<a name="4" href="#note4"><sup>4</sup></a> on p215: I will provide you with fifty of the most ancient confessions of faith in which no mention is made of the descent of Jesus Christ into hell.<a name="5" href="#note5"><sup>5</sup></a> And William Perkins, in his book on faith, believes that this article slipped in there afterwards by negligence, since there are more than 300 confessions of the ancient councils and fathers in which it is not to be found, just as it is not to be found in the Nicean creed either.<a name="6" href="#note6"><sup>6</sup></a> Bucer understands it of the burial of our Lord, and Calvin of his sadness and anxiety, in which he was as lost, being rejected by God. And St. Bernard seems to have had a similar sentiment.<a name="7" href="#note7"><sup>7</sup></a>  Ames, on p65 of his <var>Marrow of Theology</var>, admits that Scripture does not explain itself clearly on the place of hell and the manner of punishments.<a name="8" href="#note8"><sup>8</sup></a> Doctor Fulk in his Defence of the Anglican version of the Bible, admits on pp13, 87, and 89 that there is no word, whether Hebrew, Greek or Latin, which corresponds to this notion that we have of hell, as if it were the place of sufferings of the wicked.<a name="9" href="#note9"><sup>9</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'Sheol' is nothing other than the tomb. All the Rabbis take it for such, or else a deep place. In Jonah 2.2-3, the belly of the great fish is called Sheol.<a name="10" href="#note10"><sup>10</sup></a> And Psalms 141.7, and Genesis 37.35.<a name="11" href="#note11"><sup>11</sup></a> Beza is of the same view. And Fulk, <var>Defense</var> p91.<a name="12" href="#note12"><sup>12</sup></a> And St. Augustine himself, on Psalms 16,13. And the Chaldee interpreter too, see Job 21.5,13-14.<a name="13" href="#note13"><sup>13</sup></a> Instead of Sheol, the Septuagint uses 'Haides', in Genesis 3.14. It is spoken of the gates of Sheol, or Haides, in Isaiah 38.10, Psalms 19.14, Matthew 16.8.<a name="14" href="#note14"><sup>14</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Lightfoot, in his letter on his <var>Harmony</var>, says that it is known that the word 'Gehenne' is taken from the valley of this name, or Hinnom, where there was the idol of Moloch, and where children passed by the fire in honour of Moloch; 2 Kings. 23.10, Jos 15.8.<a name="15" href="#note15"><sup>15</sup></a> The Protestants often confess that Matthew 5.22, 25.41,46, and Luke 12.5 must be understood of Tophet, the valley of the son of Hinnom.<a name="16" href="#note16"><sup>16</sup></a> Impurities were cast out there, and the bodies of criminals were thrown there in accordance with a sentence of the Synedrian, in order to be burned with the carrion (more correctly: with the corpses); some were burned there by pouring molten lead into the body, Talmud in Synedrian per 7.<a name="17" href="#note17"><sup>17</sup></a> 'Gehenna' is again used in James 3.6.<a name="18" href="#note18"><sup>18</sup></a> If the passage from Matthew 5.22 must be understood of hell, it will follow that certain sins do not deserve it.<a name="19" href="#note19"><sup>19</sup></a> The opposition of the Synedrian with regard to <var>Racha</var>, shows that it is only a matter of a punishment ordered by the Jews.<a name="20" href="#note20"><sup>20</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Eternity and perpetuity do not strictly mean a perpetual slavery, as in Exodus 21.6, perpetual priesthood, as in Exodus 40.15, or that the Jews will eternally hold the Promised Land, as in Isaiah 61 and Isaiah 63.18.<a name="21" href="#note21"><sup>21</sup></a> There are often hyperbolic expressions in Scripture, as when it is said in John 21.25 that the world could not contain the books of the miracles of Jesus Christ. Thus it is said in Nahum 3.9 that the forces of the army of the Ethiopians are infinite.<a name="22" href="#note22"><sup>22</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The story of Lazarus and the rich man is only a parable, and does not signify a true event, nor even that there is such a place for the deceased.<a name="23" href="#note23"><sup>23</sup></a> It seems strange that the condemned rich man has so much love for his brothers.<a name="24" href="#note24"><sup>24</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;What is said in Isaiah 66.24 of the worm that shall not die and the flame that shall not be extinguished is meant of the corpses of the impious and especially of Gog and Magog. See Ezekiel 29.4-10. Ezekiel 37.36 - corpses are an object of abomination.<a name="25" href="#note25"><sup>25</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;When it is said that the weeds must be consumed by fire, in Matthew 13.30, it is understood of the fire that will consume the earth with the wicked at the end of the world.<a name="26" href="#note26"><sup>26</sup></a> See verse 39. The eternal condemnation, in Mark 16.16, 2 Thessalonians  2.2, and Romans 14.23, must be understood of a sentence that is without appeal and is not reversed; such is the sentence of the second death.<a name="27" href="#note27"><sup>27</sup></a><br /><br />

            [<b>LH I, 5, 2, Bl. 30v</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The first author of the eternal flames was the heretic Marcion, who even maintained that Jesus Christ was not a true man, and that there are two principles, one good and one bad.<a name="28" href="#note28"><sup>28</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The fear of hell, in order to turn men away from bad actions, does not furnish sufficient proof. Also it does not prevent men from sinning. A drunken Armenian admitted to being in a state of damnation but he consoled himself that the next day he would be in the state of grace.<a name="29" href="#note29"><sup>29</sup></a> Current pleasure is more effective than a distant fear.<a name="30" href="#note30"><sup>30</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Men sin because they have not received from on high any sufficient power against sin, p79.<a name="31" href="#note31"><sup>31</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Each fills his measure of sin, p81, 1 Thessalonians 2.13, and Daniel 12.10, 2 Peter 2.14.<a name="32" href="#note32"><sup>32</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The word of God was given to the Jews to be communicated to us, according to Romans 3.2, but they<a name="33" href="#note33"><sup>33</sup></a> have communicated nothing about eternal punishments to us.<a name="34" href="#note34"><sup>34</sup></a>
            <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;God's mercy is more extensive than his desire for retaliation, for the latter only goes up to the fifth generation, according to Deuteronomy 7.9.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The punishment of the impious is not eternal life but death.<a name="35" href="#note35"><sup>35</sup></a> Genesis 3.24,<a name="36" href="#note36"><sup>36</sup></a> Romans 5.21, John 6.58, John 14.19, 1 John 4.9, Job 3.36, John 3.15, John 10.28,<a name="37" href="#note37"><sup>37</sup></a> Romans 8.13, 1 Corinthians 3.17, 2 Thessalonians 2.10, 2 Peter 2.14, Luke. 11.3, 2 Peter. 3.16, Romans 6.21,23, Psalms 42.8, Job 4.20, Genesis 2.17 and 3.4,<a name="38" href="#note38"><sup>38</sup></a> 2 Peter 2.12, 1 Corinthians 15.26,<a name="39" href="#note39"><sup>39</sup></a> Job 6.47, 1 John 2.25, John 17.2, Job 3.15, Acts 13.48, John 5.29.<a name="40" href="#note40"><sup>40</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Adam would have died even if he had not sinned, p97.<a name="41" href="#note41"><sup>41</sup></a> Basil says it, as does Augustine in his <var>Confessions</var>; and the reason is because of his body.<a name="42" href="#note42"><sup>42</sup></a>
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> <var>Verhandeling van de Helle</var> was published anonymously in 1694. Although sometimes credited to Francis Mercury van Helmont, it was in fact a Dutch translation of Samuel Richardson's <var>A Discourse of the Torments of Hell</var> (London, 1658). In the notes below, I key Leibniz's reading notes to Richardson's book.<br />
                <a name="note2" href="#2">2.</a> Richardson, <var>Of the Torments of Hell</var>, preface: 'What thou seest writ in a Book, Rev I, II.'<br />
                <a name="note3" href="#3">3.</a> Richardson, <var>Of the Torments of Hell</var>, preface: 'I alledge not the sayings of men for proof, but for a witnesse against themselves.'<br />
                <a name="note4" href="#4">4.</a> The reference is to William Whitaker, <var>Ad rationes decem Edmundi Campiani ... responsio</var> (London, 1581).<br />
                <a name="note5" href="#5">5.</a> Richardson, <var>Of the Torments of Hell</var>, pp1-2: 'Dr. William Whitaker saith, I could produce fifty of the most ancient Creeds that have not these words (he descended into hell) in his answer to Campion, page 215.'<br />
                <a name="note6" href="#6">6.</a> Richardson, <var>Of the Torments of Hell</var>, p2: 'Mr. William Perkins on the Creed saith, It seems likely that these words, (He descended into Hell) were not placed in the Creed at first, and that it crept in by negligence; for above threescore Creeds of the most ancient Councills and Fathers want this clause (he descended into Hell) among the rest not found in the Nicene Creed, nor found in the Romish Church, nor used in the Church of the East.'<br />
                <a name="note7" href="#7">7.</a> Richardson, <var>Of the Torments of Hell</var>, pp3-4: 'Mr. Bucer saith, Christ descending into hell is to be understood of his Buriall; Mr. Calvin saith, Hell is the sorrow of the minde Christ was in before his death... Bernard makes the grief of Christs soul his hell.'<br />
                <a name="note8" href="#8">8.</a> Richardson, <var>Of the Torments of Hell</var>, p4: 'Doctor Ames, in his Marrow of Divinity, pag. 65. saith, That of the place of hel, and manner of torture there, the Scripture hath not pronounced anything distinctly.'<br />
                <a name="note9" href="#9">9.</a> Richardson, <var>Of the Torments of Hell</var>, p5: 'Doctor Fulk saith plainly, That neither in the Hebrew, Greek nor Latine, there is no word proper for hell (as we take hell) for the place of punishment of the ungodly. Fulk Defence Translation, pag. 13. 87. 89.'<br />
                <a name="note10" href="#10">10.</a> Richardson, <var>Of the Torments of Hell</var>, pp6-7: 'the word in the Hebrew, for which the English word <var>Hell</var> is put, is <var>Sheol</var>; the proper signification of <var>Sheol</var> is the Grave, as all that be learned in the Hebrew doe know... we learn the propriety of the Hebrew word from the learned Rabbies... Rabbi <var>Levi</var>, according to the opinion of the Learned, expounds <var>Sheol</var> to be the lowest Region of the world opposite to Heaven... <var>Jonah</var> calls the <var>belly</var> of the <var>Whale Sheol</var>, Jon. 2.2,3.'<br />
                <a name="note11" href="#11">11.</a> Richardson, <var>Of the Torments of Hell</var>, pp7-8: '<var>Our bones are scattered at the very brink or mouth of Sheol</var>, <var>Psal</var>. 141.7. <var>Jacob</var> said, <var>I will go down to my son Joseph to Sheol: Gen</var>. 37.35.'<br />
                <a name="note12" href="#12">12.</a> Richardson, <var>Of the Torments of Hell</var>, p8: 'The Protestant writers say <var>Sheol</var> properly signifies the grave. Doctor <var>Fulk</var> Answer to the preface <var>Remist</var>. pag. 22. so also in his <var>Defence</var>, pa.91. Mr. <var>Beza</var> saith, that <var>Sheol</var> properly signifies nothing but the grave or pit.'<br />
                <a name="note13" href="#13">13.</a> Richardson, <var>Of the Torments of Hell</var>, pp8-9: 'and <var>Augustine</var> on <var>Psal</var>. 16.13. for <var>lowest hell</var> read lowest grave... The <var>Chaldee</var> Paraphrast retaineth the word <var>Sheol</var>, and translates it the house of the grave, pag. 11.15. they interpret <var>Sheol</var>, <var>Keburata</var>, the grave: <var>Job</var> 21.5.13,14.'<br />
                <a name="note14" href="#14">14.</a> Richardson, <var>Of the Torments of Hell</var>, p11: 'The Greek translates <var>Sheol</var> into <var>Haiden</var> or <var>Haides</var>, of <var>Adam</var>, because <var>Adam</var> tasted death and went to the grave, <var>Gen</var>. 3. 19. The gates of <var>Sheol</var> is death; <var>Sheol</var> and <var>Haides</var> are said to have gates, <var>Isa</var>. 38.10, <var>Psal</var>,. 9.14. <var>Mat</var>. 16.8. The <var>Septuagint</var> expresse a place generally to receive the dead.'<br />
                <a name="note15" href="#15">15.</a> Richardson, <var>Of the Torments of Hell</var>, pp12-15: 'Doctor <var>Lightfoot</var> in his Epistle of his <var>Harmony</var>, saith, It is well known the judgement of <var>Gehinna</var> is taken from the valley of <var>Gehinna</var>, <var>Tosophet</var>, or <var>Gehinnom</var>, are names of the places of Idolatry, there was the Idoll <var>Moloch</var>... King <var>Josiah</var> defiled <var>Tophet</var> the valley of the son of <var>Hinnom</var>, that no man might make his son or daughter pass through the fire to <var>Moloch</var>, 2 <var>King</var>. 23. 10.'<br />
                <a name="note16" href="#16">16.</a> Richardson, <var>Of the Torments of Hell</var>, p14: 'The Protestant Writers confess that <var>Mat</var>. 5.22. <var>Mat</var>. 25.41,46, <var>Luke</var> 12.5. is to be understood of the fire of the valley of the son of <var>Hinnom</var>, which is <var>Tophet</var>.'<br />
                <a name="note17" href="#17">17.</a> Richardson, <var>Of the Torments of Hell</var>, pp15-16: 'The Synedrian of the Jewes for some offences sentenced the bodies of the offenders to lie unburied in that valley to burne with the carrion cast there, which among the Jewes was counted a great disgrace; and for offences most criminal they burned alive in that valley; they set the malefactor in a dunghill up to his knees, and put a towell about his neck and another an other way, till strangling him forced him to open his mouth, then they poured scalding lead into his mouth, which went down into his body, and so burnt his bowels, <var>Talmud in Sanhedr</var>. Per. 7.'<br />
                <a name="note18" href="#18">18.</a> Richardson, <var>Of the Torments of Hell</var>, p17: 'the Apostle preaching to the Jewes used the word <var>Gehennah</var>, <var>Jam</var>. 3.6.'<br />
                <a name="note19" href="#19">19.</a> Richardson, <var>Of the Torments of Hell</var>, p17: 'the last of the three sins is said to be judged to the fire of <var>Gehennah</var>, which if it were to be understood as some would have it, it will follow, that some sins deserve not hel.'<br />
                <a name="note20" href="#20">20.</a> Richardson, <var>Of the Torments of Hell</var>, p17: '<var>Racha</var> a word of disgrace, which signifies an empty fellow, or wicked wretch... <var>Racha</var> in danger of the Councel, it say Fool, in danger of Hell fire, to burn in the valley of the son of <var>Hinnom</var>.'<br />
                <a name="note21" href="#21">21.</a> Richardson, <var>Of the Torments of Hell</var>, pp18-19: '<var>ever</var> and <var>everlasting</var> are of a like signification, and is used for a limited time, a time during life, <var>He shall serve his master for ever</var>, <var>Exod</var>. 21.6. <var>Lu</var>. 24.46, that is, untill his own or Masters death, longer he could not serve him: the everlasting Priesthood (<var>Exod</var>. 40.15.) was but untill Christ came, then it was to cease, as appears <var>Heb</var> 11.12,13,14. it is said they shall inherit the land for ever; <var>Isa</var>. 61.21. that ever was but a little while, as appears <var>Isa</var>.63.18.' Note that Richardson's first Isaiah reference is wrong; it should be 60.21. Leibniz repeats this mistake.<br />
                <a name="note22" href="#22">22.</a> Richardson, <var>Of the Torments of Hell</var>, pp20-1: 'consider that the Scripture sometime use words that exceed their signification, and are not strictly to be understood according to their letter and signification of those words, as <var>John</var> 21.25... so sinne and the strength of the Aethiopian army are said to be infinite, <var>Job</var> 22.5. <var>Nah</var>.3.9.'<br />
                <a name="note23" href="#23">23.</a> Richardson, <var>Of the Torments of Hell</var>, p22: '<var>The Story</var> of <var>Dives</var>, <var>Luk</var>. 16.30. Is not any proof of any torments in hell, because it is a parable, not a history.'<br />
                <a name="note24" href="#24">24.</a> Richardson, <var>Of the Torments of Hell</var>, p24: 'How comes <var>Dives</var> to have such charity in hell to his Five Brethren, seeing he had none to them when on earth?'<br />
                <a name="note25" href="#25">25.</a> Richardson, <var>Of the Torments of Hell</var>, pp28-9: 'Of <var>Isa</var>. 66.24. They shall go forth and look upon the men that have transgrest against me, for their worme shall not die neither shall their fire be quenched... the late annotation of the Bible on <var>Isa.66</var>.24. say the carcase are the forces of Gog, and Magog which shall be slain neer Jerusalem. <var>Eze</var>.29.4. to 10. and 37.36. containeth, is apparent, for after the slaughter is made of them, they shall lie along time unburied, and seven moneths shall the children of Israel be a burying them, that they may clense the Land.'<br />
                <a name="note26" href="#26">26.</a> Richardson, <var>Of the Torments of Hell</var>, p36 (incorrectly marked as p28 in the 1658 edition, and p38 in the 1660 edition): '<var>Of burning the Tares</var>, Mat. 13.30. Is at the end of the world, verse 39. The Tares are the wicked, the Harvest is the end of the world.'<br />
                <a name="note27" href="#27">27.</a> Richardson, <var>Of the Torments of Hell</var>, p39: 'The word <var>damned</var>, <var>Mar</var>.16.16. 2 <var>Thes</var>.22. <var>Rom</var>, 14.23. in Greek is judged; Damnation is Judgement; eternall Damnation is eternall Judgement; a Judgement is a Sentence, the Sentence is to a second death, called Eternal, because it is not to be reversed.'<br />
                <a name="note28" href="#28">28.</a> Richardson, <var>Of the Torments of Hell</var>, p74: 'The first Author of the opinion of the torments of hell never to end, was <var>Marcion</var> the Heretick, that held that Christ was not a man but in semblance, and that there was two beginnings, two Gods, one good, one bad.'<br />
                <a name="note29" href="#29">29.</a> Richardson, <var>Of the Torments of Hell</var>, pp103-4: 'that the fear of the torments of Hell is no such preserver against sin, is evident... the greatest sinners...hope to repent and lead new lives, before they die; though they sin for the present they hope to make God amends for all, as an Arminian being drunk said that he was now in the state of damnation, but he said he would be in the state of grace tomorrow, so he comforted himself.'<br />
                <a name="note30" href="#30">30.</a> Richardson, <var>Of the Torments of Hell</var>, p105: 'the lusts of men are stronger than the fear of hell'.<br />
                <a name="note31" href="#31">31.</a> Richardson, <var>Of the Torments of Hell</var>, p107: 'Men sinne because they have not received power from on high against sinne'.<br />
                <a name="note32" href="#32">32.</a> Richardson, <var>Of the Torments of Hell</var>, pp109-10: 'the measure of mens sins are set; men cannot do more nor less than their measure, they fill up their measure alway, I <var>Thes</var>. 2.13..., Daniel 12.10..., 2 Pet. 2.14.'<br />
                <a name="note33" href="#33">33.</a> Reading 'ils' for 'il'.<br />
                <a name="note34" href="#34">34.</a> Richardson, <var>Of the Torments of Hell</var>, pp114-15: 'The Jews (unto whom were committed the Oracles of God) <var>Rom</var>. 3.2. to give unto us, they have delivered no such thing [as the doctrine of hell] to us.'<br />
                <a name="note35" href="#35">35.</a> This is a summary of the general position adopted by Richardson in <var>Of the Torments of Hell</var>. See especially pp122ff.<br />
                <a name="note36" href="#36">36.</a> Cited in Richardson, <var>Of the Torments of Hell</var>, p126.<br />
                <a name="note37" href="#37">37.</a> Cited in Richardson, <var>Of the Torments of Hell</var>, p127 (although Richardson does not cite John 3.15, but 1 John 3.14-15).<br />
                <a name="note38" href="#38">38.</a> Cited in Richardson, <var>Of the Torments of Hell</var>, p128 (although Richardson does not cite Psalms 42.8, but Psalms 99.7).<br />
                <a name="note39" href="#39">39.</a> Cited in Richardson, <var>Of the Torments of Hell</var>, p129.<br />
                <a name="note40" href="#40">40.</a> Cited in Richardson, <var>Of the Torments of Hell</var>, p130.<br />
                <a name="note41" href="#41">41.</a> Richardson, <var>Of the Torments of Hell</var>, p130: 'If Adam had not sinned, he should have died.'<br />
                <a name="note42" href="#42">42.</a> Richardson, <var>Of the Torments of Hell</var>, pp131-2: '<var>Basil</var> saith, if God had given <var>Adam</var> an immutable and unchangeable nature, he had created a God and not a Man: <var>Augustine</var> in his Book of <var>Confessions</var> saith, because the Lord created man of nothing, therefore he left in man a possibility to return into nothing.'<br />

            <br /><br />
                &#169; Lloyd Strickland 2006, revised 2016
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
