import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'; 
import { Helmet } from 'react-helmet';

function Abbreviations() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
 
  return (
    <div>
        <Helmet>
            <title>Abbreviations used in this website</title>
            <meta name="description" content="Details of abbreviations used on this website" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={2} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                    <></>
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>ABBREVIATIONS USED IN THIS WEBSITE</h2>
                  <table width="100%" border="0">
                    <tr>
                        <td width="15%"><b>A</b>
                        </td>
                        <td width="85%"><var>S&#228;mtliche Schriften und Briefe</var> (ed. Akademie der Wissenschaften), multiple volumes in 6 series, cited by series (reihe) and volume (band), Berlin: Akademie Verlag, 1923-.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">&nbsp;
                        </td>
                    </tr>
                    <tr>
                        <td><b>Bel</b>
                        </td>
                        <td><var>Confessio Philosophi</var> (ed. Yvon Belaval), Paris: Vrin, 1961.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">&nbsp;
                        </td>
                    </tr>
                    <tr>
                        <td><b>BH</b>
                        </td>
                        <td><var>Die Leibniz-Handschriften der K&#246;niglichen &#214;ffentlichen Bibliothek zu Hannover</var> (ed. Eduard Bodemann), Hanover: Hahn, 1895.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">&nbsp;
                        </td>
                    </tr>
                    <tr>
                        <td><b>C</b>
                        </td>
                        <td><var>Opuscules et fragments in&#233;dits de Leibniz</var> (ed. Louis Couterat), Paris, 1903.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">&nbsp;
                        </td>
                    </tr>
                    <tr>
                        <td><b>D</b>
                        </td>
                        <td><var>Opera Omnia</var> (ed. Ludovic Dutens), 6 volumes, Geneva, 1768.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">&nbsp;
                        </td>
                    </tr>
                    <tr>
                        <td><b>E</b>
                        </td>
                        <td><var>Opera Philosophica</var> (ed. J. E. Erdmann), Berlin: Eicher, 1839-1840.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">&nbsp;
                        </td>
                    </tr>
                    <tr>
                        <td><b>FC</b>
                        </td>
                        <td><var>Lettres et Opuscules In&#233;dits de Leibniz</var> (ed. Louis Alexandre Foucher de Careil), Paris: Ladrange, 1854.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">&nbsp;
                        </td>
                    </tr>
                    <tr>
                        <td><b>G</b>
                        </td>
                        <td><var>Die Philosophischen Schriften von Gottfried Wilhelm Leibniz</var> (ed. C. I. Gerhardt), 7 volumes, Berlin: Weidmann, 1875-1890.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">&nbsp;
                        </td>
                    </tr>
                    <tr>
                        <td><b>GM</b>
                        </td>
                        <td><var>Mathematische Schriften</var> (ed. C. I. Gerhardt), 7 volumes, Berlin: A. Asher, 1849-1863.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">&nbsp;
                        </td>
                    </tr>
                    <tr>
                        <td><b>Gr</b>
                        </td>
                        <td><var>Textes in&#233;dits</var> (ed. Gaston Grua), 2 volumes with successive pagination, Paris: Presses Universitaires de France, 1948.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">&nbsp;
                        </td>
                    </tr>
                    <tr>
                        <td><b>GW</b>
                        </td>
                        <td><var>Briefwechsel zwischen Leibniz und Christian Wolf</var> (ed. C. I. Gerhardt), Halle: H. W. Schmidt, 1860.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">&nbsp;
                        </td>
                    </tr>
                    <tr>
                        <td><b>H</b>
                        </td>
                        <td><var>Theodicy</var> (trans. E. M. Huggard), Chicago: Open Court, 1990.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">&nbsp;
                        </td>
                    </tr>
                    <tr>
                        <td><b>HD</b>
                        </td>
                        <td><var>De l'horizon de la doctrine humaine</var> (ed. Michael Fichant), Paris: Vrin, 1991.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">&nbsp;
                        </td>
                    </tr>
                    <tr>
                        <td><b>JT</b>
                        </td>
                        <td><var>A Collection of Several Pieces of John Toland, now first publish'd from his Original Manuscripts: with some memoirs of his life and writings</var> (ed. Pierre Desmaizeaux), London: J. Peele, 1726, vol. II.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">&nbsp;
                        </td>
                    </tr>
                    <tr>
                        <td><b>K</b>
                        </td>
                        <td><var>Correspondenz von Leibniz mit der Prinzessin Sophie</var> (ed. Onno Klopp), 3 volumes, Hildesheim: Georg Olms, 1973.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">&nbsp;
                        </td>
                    </tr>
                    <tr>
                        <td><b>LDB</b>
                        </td>
                        <td><var>The Leibniz-Des Bosses Correspondence</var> (trans. and ed. Brandon Look and Donald Rutherford), New Haven: Yale University Press, 2007.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">&nbsp;
                        </td>
                    </tr>
                    <tr>
                        <td><b>LGR</b>
                        </td>
                        <td><var>Leibniz on God and Religion</var> (trans. and ed. Lloyd Strickland), London: Bloomsbury, 2016.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">&nbsp;
                        </td>
                    </tr>
                    <tr>
                        <td><b>ML</b>
                        </td>
                        <td><var>Malebranche et Leibniz</var> (ed. Andr&#233; Robinet), Paris: Librairie Philosophique J. Vrin, 1955.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">&nbsp;
                        </td>
                    </tr>
                    <tr>
                        <td><b>NE</b>
                        </td>
                        <td><var>New Essays On Human Understanding</var> (trans. Peter Remnant &amp; Jonathan Bennett), Cambridge: Cambridge University Press, 1996.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">&nbsp;
                        </td>
                    </tr>
                    <tr>
                        <td><b>OH</b>
                        </td>
                        <td><var>Otium Hanoveranum</var> (ed. Joachim Friedrich Feller), Leipzig, 1718.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">&nbsp;
                        </td>
                    </tr>
                    <tr>
                        <td><b>P</b>
                        </td>
                        <td><var>Philosophical Writings</var> (trans. Mary Morris &amp; G. H. R. Parkinson), London: Dent, 1973.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">&nbsp;
                        </td>
                    </tr>
                    <tr>
                        <td><b>Pr</b>
                        </td>
                        <td><var>Protogaea</var> (ed. Jean-Marie Barrande), Toulouse: Presses Universitaires du Mirail, 1993.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">&nbsp;
                        </td>
                    </tr>
                    <tr>
                        <td><b>SLT</b>
                        </td>
                        <td><var>Shorter Leibniz Texts</var> (trans. Lloyd Strickland), London: Continuum, 2006.
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">&nbsp;
                        </td>
                    </tr>
                  </table>
                </Col>
                <Col md={2}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Abbreviations;