import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/fontaney1703.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>S&#228;mtliche schriften und briefe series I, volume 22</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 372-373<br /><br />
	    {isMobile ? '' : <hr className="centered-hr" />}
		Date: 17 April 1703<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (100k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Jean de Fontaney (17 April 1703)</title>
            <meta name="description" content="An English translation of a LETTER TO JEAN DE FONTANEY by Gottfried Wilhelm Leibniz, from 17 April 1703" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO JEAN DE FONTANEY</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A I 22, 372</b>]<br /><br />

                Extract from my letter to Reverend Father Fontaney<br /><br />

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;My Very Reverend Father<br /><br />

                [<b>A I 22, 373</b>]<br /><br />

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I have received the honour of your letter from Canton of 15 September 1701, and I thank you very much for your remembrance, whose effects I look forward to feeling when you have the opportunity and the time to provide me with illumination. I beg Your Reverence, along with Very Reverend Father Bouvet and other friends, to think about the enclosed questions, besides the ones I sent to this father both now and before.<a name="1" href="#note1"><sup>1</sup></a> I congratulated him for deciphering the enigma of the lines of Fuxi with my binary arithmetic.<a name="2" href="#note2"><sup>2</sup></a> This discovery will appear important to the Chinese. I am afraid that the favour of Europeans and the esteem we have for the Chinese will not last when they have obtained our sciences from us, so while we have the means to do so I wish we endeavoured more than we have so far to benefit Europe with the sciences from China too. And let us try to urge the Emperor to make descriptions of all the arts, both to give means of perfecting them and so that the Chinese have nothing hidden for the Tartars; it will also be the means of perfecting the dictionary that the Emperor has made, because the terms of arts could be included therein. Many people - both among the Protestants and in France - have not been pleased that I have sided with your missions. The pope has wisely suspended his judgement about the religion of the ancient and modern Chinese: we should not condemn people without having heard them.<a name="3" href="#note3"><sup>3</sup></a> However, no matter the opinion of some of them, it's a great step that the Emperor declares himself in favour of the truth. Someone told me that since the defeat of the King of Eluth, we can now go to China both from Persia through the Uzbeks and from Mogul lands. But if it were safe to go by sea, as you did, this path would be preferable to all others.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> See Leibniz's letter to Bouvet of early April 1703, A I 22, 347-368, at 365; English translation: Lloyd Strickland and Harry Lewis, <var>Leibniz on Binary: The Invention of Computer Arithmetic</var> (Cambridge, Mass.: MIT Press, 2022), 178-188, at 186-187.<br />
                <a name="note2" href="#2">2.</a> By &quot;lines of Fuxi&quot; Leibniz means the Chinese Yijing. Bouvet had suggested - erroneously as it happens - that Leibniz's discovery of binary arithmetic was the key to understanding the mysterious hexagrams of the Yijing. See Bouvet's letter to Leibniz of 4 November 1701, A I 20, 533-555; English translation: Lloyd Strickland and Harry Lewis, <var>Leibniz on Binary: The Invention of Computer Arithmetic</var> (Cambridge, Mass.: MIT Press, 2022), 163-175.<br /> 		
                <a name="note3" href="#3">3.</a> Leibniz here alludes to an old French saying: &quot;one should not condemn without a hearing.&quot;<br /><br /><br />
                &#169; Lloyd Strickland 2018<br />
                With thanks to Daniel J. Cook for advice and suggestions.<br />
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
