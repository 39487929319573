import '../App.css';
import React from "react";
import { Row, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function MobileLinks() {
  return (
    <><>
      <Helmet>
        <title>Links</title>
        <meta name="description" content="Links to submission details, academic profiles, abbreviation details, and Leibniz resources" />
      </Helmet>
    </><div className="RightColumn" style={{ textAlign: 'center', padding: '0px 0px 0px 0px' }}>
        <br></br>
        <Container className="Container">
          <div className="main-content">

            <p><a href="https://mmu.academia.edu/StricklandLloyd" target="_blank">Click here to see my academia.edu entry</a></p>
            <p><a href="https://www.researchgate.net/profile/Lloyd-Strickland" target="_blank">Click here to see my ResearchGate profile</a></p>
        
            <hr></hr>
            <p>LEIBNIZ LINKS:</p>
            <a href="http://www.mv.helsinki.fi/home/mroinila/" target="_blank">LEIBNIZ - AN UNIVERSAL PHILOSOPHER</a><br />Leibnizian resources<br /><br />
            <a href="http://www-history.mcs.st-andrews.ac.uk/Biographies/Leibniz.html" target="_blank">GOTTFRIED WILHELM VON LEIBNIZ</a><br />Leibniz biography and weblinks<br /><br />
            <a href="http://plato.stanford.edu/entries/leibniz-ethics" target="_blank">LEIBNIZ'S ETHICS</a><br />An article by Andrew Youpa, from the Stanford Encyclopedia of Philosophy<br /><br />
            <a href="http://philosophyfaculty.ucsd.edu/faculty/rutherford/Leibniz/index.php" target="_blank">TEXTS AND TRANSLATIONS</a><br />Donald Rutherford's site<br /><br />

            <a href="https://leibniz-bouvet.swarthmore.edu/" target="_blank">THE LEIBNIZ - BOUVET CORRESPONDENCE</a><br />

            English translations (by Berkowitz and Cook) of this important correspondence<br /><br />
            <a href="https://philpapers.org/asearch.pl?strict=1&searchStr=Leibniz,%20Gottfried%20Wilhelm&filterMode=authors" target="_blank">LEIBNIZ RESOURCES</a><br />
            Hundreds of books and papers from philpapers<br /><br />
            <a href="http://www.gwleibniz.com/site_links/links.html" target="_blank">LEIBNITIANA</a><br />
            A wealth of resources in this great site by Gregory Brown<br /><br />

            <a href="http://www.earlymoderntexts.com" target="_blank">EARLY MODERN TEXTS</a><br />Jonathan Bennett's collection of texts from early modern philosophy

            <br /><br />

            <a href="http://www.leibnizbrasil.pro.br" target="_blank">LEIBNIZ BRASIL</a><br />

            Brazilian Leibniz site, with Portugese translations and Leibniz links<br /><br />

            <a href="http://www.leibnizsociedad.org/" target="_blank">SOCIEDAD ESPA&Ntilde;OLA LEIBNIZ</a><br />Spanish Leibniz Society<br /><br />

            <a href="http://www.philosophypages.com/ph/leib.htm" target="_blank">GOTTFRIED WILHELM LEIBNIZ - PHILOSOPHY PAGES</a><br />Leibniz information and links<br /><br />
            <a href="https://christopherpnoble.com/" target="_blank">CHRISTOPHER NOBLE'S BLOG</a><br />
            With posts on Leibniz and more<br /><br />

            <a href="http://en.wikipedia.org/wiki/Gottfried_Leibniz" target="_blank">GOTTFRIED LEIBNIZ</a><br />Wikipedia entry<br /><br />

            <a href="http://sites.northwestern.edu/germanphil/resources/" target="_blank">THE CHICAGO AREA CONSORTIUM ON GERMAN PHILOSOPHY</a><br />Links to sites on Leibniz and other German philosophers<br /><br />

          </div>

          <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
        </Container>
      </div></>
  );
}

export default MobileLinks;


