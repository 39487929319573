import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/bourguetjuly.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>Die philophischen schriften von Gottfried Wilheim Leibniz, vol. III</var><br />
		C. I. Gerhardt (ed)<br />
		pp 594-596<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 2 July 1716<br /><br />
		Translated from the French<br /><br />
        Note - the portion of this text that is included in my <var>Shorter Leibniz Texts</var> is not included here.<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (11k)</Link><br /><br />
        <Link to="/slt">Back to <var>Shorter Leibniz Texts</var></Link><br /><br />
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Louis Bourguet (2 July 1716)</title>
            <meta name="description" content="An English translation of a LETTER TO LOUIS BOURGUET by Gottfried Wilhelm Leibniz, from 2 July 1716" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO LOUIS BOURGUET</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
             	[<b>G III p594</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;As you have asked me for a recommendation for Mr Malpac, I have written directly to Mr Chamberlaine, a well-known figure and author of the present state of Great Britain, and I asked him to be favourable to Mr [<b>G III p595</b>] Malpac if he happens to find him in London. There is a kind of conference or assembly at Mr Chamberlaine's house, of the sort that his acquaintance will be of use to Mr Malpac in order for him to make the acquaintance of others.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It is most certain that each state of the universe envelops the infinite: and what is more, each portion of the universe envelops it too, the reason for which is that each part of matter is actually subdivided and contains some well-ordered variety. Otherwise there would be some chaos in nature, or at least something without form.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mr Clarke, in order to combat my maxim than nothing happens without a sufficient reason, and in order to maintain that God does something by an absolutely pure will without any reason, has claimed that space, being everywhere uniform, is indifferent to God's placing of bodies in it. I responded that this very fact proves that space is not an absolute being, but an order of being, or something relative, and which would only be ideal if bodies did not exist in it. Otherwise something would happen for which there would not be any determining reason. On this I also say that it is with space as it is with time; that time separated from things is not an absolute being, but an ideal thing; and that for this reason we cannot ask why God did not create the world a thousand years earlier. For as time is only this relation of successions, it would be the same thing, and the difference only consists in a misunderstood fiction. Otherwise we would have to admit that God would have done something without reason, and as this is an absurdity we would have to have recourse to the eternity of the world.<br /><br />

                ......<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The doctrine of comets is still rather obscure, and the immense size of their tails is very awkward: posterity will form better opinions on them than we do, after a great number of observations.
                The most useful astronomic observations at present would be those that would serve to determine the course of the Moon, which we do not yet know with enough precision.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I made my court to the Czar by the waters of Pyrmont,<a name="1" href="#note1"><sup>1</sup></a> and here as well, because His Majesty stayed for two nights after his return from the waters, in a [<b>G III p596</b>] boating house near here. I would not be able to admire the vivacity and judgement of this great Prince enough. He makes clever people come from all directions, and when he speaks to them, they are all astonished about it, not least because he speaks to them in the right way. He inquires about all the mechanical arts: but his great curiosity is for everything related to navigation; and consequently he also likes astronomy and geography. I hope that we will learn, through his means, whether Asia is attached to America. I am sincerely etc. Hanover, 2 July 1716.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTE:<br /><br />
                <a name="note1" href="#1">1.</a> Bad Pyrmont is a small town in Lower Saxony, Germany, and is famous, even today, for its spring waters.<br />
                <br /><br />
                &#169; Lloyd Strickland 2004
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
