import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/plusultra.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>S&#228;mtliche schriften und briefe series VI volume 4</var><br />
        Deutsche Akademie der Wissenschaften (ed)<br />
        pp 674-677<br /><br />
		    {isMobile ? '' : <hr className="centered-hr" />}
		    Date: April - October 1686 (?)<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (178k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Plus Ultra</title>
            <meta name="description" content="An English translation of PLUS ULTRA by Gottfried Wilhelm Leibniz, from April - October 1686 (?)" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: PLUS ULTRA</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A VI 4, p674</b>]<br /><br />
                <center>PLUS ULTRA<a name="1" href="#note1"><sup>1</sup></a><br />
                or, beginnings and samples<br />
                OF THE GENERAL SCIENCE<br />
                concerning the renewal and increase of knowledge,<br />
                and also concerning perfecting the mind and the discovery of things<br />
                for public happiness</center><br />

                [<b>A VI 4, p675</b>]<br />
                <br /><center>Arrangement of the work</center><br />

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1) The reasons that compelled the author to write; where also [is treated] why he has concealed his name. The friendship of great princes and their like-minded opinions.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2) History of erudition.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3) On the present state of erudition, that is, on the Republic of Letters.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4) On the evils men suffer from their own fault. On those things which have been usefully discovered to assist human life.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5) On procuring the happiness of men.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6) On the correction of Scholastics and the reason for study, where also [is treated] on schools.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7) On the renewal of the sciences, where [is placed material] on systems and their authors, and on the demonstrative encyclopaedia to be put together.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;On languages and rational grammar.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8) Elements of eternal truth, and on the art of demonstrating in all disciplines as in mathematics.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;9) On a new sort of general calculus, with whose assistance all disputes are resolved among those who assent to it; it is the Kabbalah of the wise.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10) On the art of inventing.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;11) On synthesis, that is, the combinatorial art.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;12) On analysis.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;On characteristics.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;13) On specific combinatory, that is, on the science of forms or qualities in general or on the similar and dissimilar.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;14) On specific analysis, that is, on the science of quantities in general, or on the great and small.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;15) On the general mathematics composed from the preceding two.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;16) On arithmetic.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;17) On algebra.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;18) On geometry.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;19) On optics.<br /><br />

                [<b>A VI 4, p676</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;20) Phorography (of which tornatoria is a species), that is, on the vestiges of motion.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;22)<a name="2" href="#note2"><sup>2</sup></a> <var>Dynamics</var>, that is, on the cause of motions, or on cause and effect, and potency and actuality.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;23) On the resistance of solids.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;24) On the motions of liquids.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Nautics, where [is treated] the new laws of rhombus [lines].<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;25) <var>Mechanics</var>, from the compass and use of the preceding.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;26) Elements of physics, on the causes of qualities and the way sensing occurs.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;27) Physical astronomy concerning the system of the world, or the principles of bodies.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;28) Special physics concerning the things all around us.<a name="3" href="#note3"><sup>3</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;29) On meteors.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;29)<a name="4" href="#note4"><sup>4</sup></a> On dry lands and the nature of minerals.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;30) On plants.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;31) On animals.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;31) On medicine.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Provisional medicine.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;On different craftsmen.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;On the nature of the mind and the passions of the soul.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Politics, that is, on governing men.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;On the sufficiency of things and on commerce and manufactured things, that is, economics, where [is treated] whether it is possible to discover the means by which the few have equal power to the multitude.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;On warfare.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;On jurisprudence, where [is treated] on the law of nature and of peoples, and likewise different positive laws.<br /><br />
                [<b>A VI 4, p677</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Especially on Roman law and on ecclesiastic law.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;On public law and on fetial law.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;On the best commonwealth.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Natural theology.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;On the truth of the Christian religion.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;On the concord of Christians and the conversion of gentiles.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;On a society of God-lovers.
                <br /><br /><br />
                    <hr className='rightcol-hr' />
                    <br /><br />
                    NOTES:<br /><br />
                    <a name="note1" href="#1">1.</a> The title means something like &quot;Yet further&quot; or &quot;Further beyond.&quot;<br />
                    <a name="note2" href="#2">2.</a> The mistake in numbering is Leibniz's.<br />
                    <a name="note3" href="#3">3.</a> In the margin Leibniz wrote here: &quot;games of nature.&quot;<br />
                    <a name="note4" href="#4">4.</a> The mistake in numbering is Leibniz's.<br />
                    <br /><br /><br />
                    &#169; Lloyd Strickland 2020
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
