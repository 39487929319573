import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/perfectbeing.pdf'; 
import { Helmet } from 'react-helmet';

function PerfectBeing() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>     
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series VI, volume 3</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 578-579<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 18-21 November (?) 1676<br /><br />
		Translated from the Latin<br /><br />{isMobile ? '' : <hr className="centered-hr" />}
		<Link to={pdf} target="_blank">View this translation in PDF format (193k)</Link><br /><br />
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>That a Most Perfect Being Exists</title>
            <meta name="description" content="An English translation of THAT A MOST PERFECT BEING EXISTS by Gottfried Wilhelm Leibniz, from 18-21 November (?) 1676" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: THAT A MOST PERFECT BEING EXISTS</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A VI 3, 578</b>]<br /><br />
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I call &quot;perfection&quot; every simply quality which is positive and absolute, i.e., which expresses whatever it expresses without any limits.<br />
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;But since a quality of this kind is simple, it is for that reason unanalyzable or indefinable, for otherwise either it won't be one simple quality but an aggregate of several, or, if it is one, it will be circumscribed by limits, and therefore will be understood through the negations of further progress, contrary to the hypothesis, for it is assumed to be purely positive.<br />
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;From this it is not difficult to show that all <var>perfections are compatible with each other</var>, i.e. that they can be in the same subject.<br />
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For let there be a proposition of this kind: &quot;A and B are incompatible&quot; (understanding by &quot;A&quot; and &quot;B&quot; two simple forms or perfections of this kind; it is the same if several are assumed at the same time). It is evident that this proposition cannot be demonstrated without an analysis of the terms &quot;A&quot; or &quot;B&quot; (either one or both), for otherwise their nature would not enter into the reasoning, and incompatibility could be demonstrated equally of any other things whatsoever as well as of them. But (according to the hypothesis) they are unanalyzable. Therefore this proposition cannot be demonstrated of them.<br />
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;But it certainly could be demonstrated of them if it were true, since this proposition is not known through itself. Yet all necessarily true propositions are either demonstrable or known through themselves. [<b>A VI 3, 579</b>] Therefore this proposition is not necessarily true, i.e. it is not necessary that &quot;A&quot; and &quot;B&quot; are not in the same subject. Therefore they can be in the same subject, and since this reasoning is the same with regard to any other assumed qualities of this kind, all perfections are therefore compatible.<br />
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Therefore there is, or there can be understood, a subject of all perfections, i.e. a most perfect being.<br />
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;From this it is also evident that it exists, since existence is contained in the number of perfections.
(The same can also be shown of forms composed of absolutes, if there are any.)<br />
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;When I was at the Hague I showed this argument to Mr. Spinoza, who thought it to be sound. Since he contested it at first, I wrote it down to describe it, and read this paper to him.<br /><br />
<center>Scholium</center><br />
Descartes' argument for the existence of a most perfect being supposed that a most perfect being can be understood, i.e. is possible. For with this supposed - that there is a notion of this kind - it immediately follows that this being exists, because we have supposed it to be such that it immediately contains existence. But it will be asked whether it is in our power to suppose such a being, or whether such a notion really exists, and can be understood clearly and distinctly without contradiction. For opponents will say that such a notion of a most perfect being, or of a being existing through its essence, is a chimera. Nor is it enough for Descartes to appeal to experience and allege that he clearly and distinctly senses something like this notion in himself. For this is to break off from the demonstration, not complete it, unless he shows a way in which others can also arrive at an experience of this kind. For whenever we admit experience into what is being demonstrated, we should show others a way of bringing about the same experience, unless we want to convince them solely by our own authority.


		<br /><br /><br />
		&#169; Lloyd Strickland 2017
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default PerfectBeing;