import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/instrument.pdf'; 
import { Helmet } from 'react-helmet';

function Instrument() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series VI, volume 4</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 156-160<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: March - April 1679 (?)<br /><br />
		Translated from the French<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (24k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>On an Instrument or Great Art of Thinking</title>
            <meta name="description" content="An English translation of ON AN INSTRUMENT OR GREAT ART OF THINKING by Gottfried Wilhelm Leibniz, from March - April 1679 (?)" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: ON AN INSTRUMENT OR GREAT ART OF THINKING</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 <center>*** Please note that what follows is a draft translation from 2003. A more recent and more polished translation is available in my <Link to="/leibnizonbinary" target="_blank"><>Leibniz on Binary: The Invention of Computer Arithmetic</></Link> (MIT Press, 2022, co-authored with Harry Lewis) ***</center><br /><br />
		        [<b>A VI 4, p156</b>]<br /><br />
		        <center><var>On an instrument or great art of thinking</var><a name="1" href="#note1"><sup>1</sup></a></center><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The highest form of man's <var>happiness</var> consists in his perfection increased as much as possible.<a name="2" href="#note2"><sup>2</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Vigour</var>, or the state of increased perfection, is as much above <var>health</var> as <var>illness</var> is below it. Indeed <var>perfection</var> is a more excellent degree of health. Just as <var>illness</var> consists of a impaired functioning of the <var>faculties</var>, so <var>perfection</var> consists in a furtherance of one's power or faculties.<br /><br />

                [<b>A VI 4, p157</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The foremost of human faculties is the <var>power of thinking</var>.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The power of thinking can be assisted either by bodily aids or mental aids.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Bodily aids are bodies which are applied to the corporeal organs themselves, whereby torpor is dispelled, imagination strengthened, and the senses sharpened. But these are not to be discussed here.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The aids prescribed for the mind consist in certain ways of thinking, whereby other thoughts are made easier.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The greatest mental aid is if a few thoughts can be found from which infinite other thoughts may arise in order. Just as from the assumption of a few numbers, from unity up to ten, all the other numbers can be derived in order.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Whatever is thought by us is either conceived through itself, or involves the concept of another.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Whatever is involved in the concept of another is in turn either conceived through itself, or involves the concept of another.  And so on.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;And so one must either proceed to infinity, or all thoughts are resolved into those which are conceived through themselves.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If nothing is conceived through itself, nothing will be conceived at all. For what is conceived only through others will be conceived in so far as those others are conceived, and so on in turn: and hence we shall only be said to conceive something in actuality when we happen upon those things which are conceived through themselves.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I shall illustrate the matter with an analogy. I give to you a hundred which are to be received from Titius; Titius will send you on to Caius; Caius to Maevius, but if you are continually sent on like this you will never be said to have received anything.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It is necessary that those things which are perceived through themselves are numerous. For let there be <var>a</var>, which is conceived through <var>b</var>, or which involves <var>b</var>. I say that <var>a</var> itself necessarily involves not just <var>b</var> alone, but  something else as well; for if it is conceived through <var>b</var> alone, then at any rate nothing else can be conceived in <var>a</var> which cannot be conceived in <var>b</var>, and so there will be no distinction between <var>a</var> and <var>b</var>, but this is contrary to the hypothesis, for we have assumed that <var>a</var> is conceived through another, namely <var>b</var>. It is therefore necessary that <var>a</var> is conceived through two things at least, for example <var>b</var> and <var>c</var>.<br /><br />

                [<b>A VI 4, p158</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Although the things which are conceived are infinite, it is nevertheless possible that there are only a few things which are conceived through themselves. For infinites can be constructed through the combination of a small number of things.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Indeed, this is not only possible but also credible or probable, for nature usually accomplishes as much as possible with the smallest possible number of assumptions, i.e. it operates in the simplest way.<a name="3" href="#note3"><sup>3</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It could be the case that there is only one thing which is conceived through itself, evidently God himself, and besides him there is nothing, or privation, which I shall show by a remarkable analogy. We generally count out numbers through a decimal progression, so that when we reach ten, we start again from unity, and I do not now dispute that doing this is convenient. Nevertheless I shall show that instead of this it is possible to use a dyadic progression,<a name="4" href="#note4"><sup>4</sup></a> so that as soon as we reach two we start again from unity, in this way:<br /><br />
                <table width="100%" border="0">
                <tr>
                    <td width="11%">(0)</td><td width="11%">(1)</td><td width="11%">(2)</td><td width="11%">(3)</td><td width="11%">(4)</td><td width="11%">(5)</td><td width="11%">(6)</td><td width="11%">(7)</td><td width="12%">(8)</td>
                </tr>
                <tr>
                    <td>0</td><td>1</td><td>10</td><td>11</td><td>100</td><td>101</td><td>110</td><td>111</td><td>1000</td>
                </tr>
                <tr>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td>&nbsp;</td><td>(9)</td><td>(10)</td><td>(11)</td><td>(12)</td><td>(13)</td><td>(14)</td><td>(15)</td><td>(16)</td>
                </tr>
                <tr>
                    <td>&nbsp;</td><td>1001</td><td>1010</td><td>1011</td><td>1100</td><td>1101</td><td>1110</td><td>1111</td><td>10000</td>
                </tr>
                </table>
                <br clear = "all" />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I will not now touch on the<a name="5" href="#note5"><sup>5</sup></a> immense advantages of this progression: it is sufficient to note how, with this wonderful method, all numbers may be expressed in this way by unity and nothing.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Moreover, although there is no hope that in this life men can arrive at this hidden series of things, in which it is apparent how all things proceed from<a name="6" href="#note6"><sup>6</sup></a> pure Being and nothing, it is nevertheless sufficient that the analysis of ideas is advanced as far as the demonstrations of truths require.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Every idea is perfectly analysed only when it can be demonstrated a priori that it is possible. For if we give some definition, and from that it is not apparent that the idea we ascribe to the thing is possible, we cannot trust the demonstrations which we have deduced from the definition, because if that idea happens to involve a contradiction it can be the case contradictories are true of it, and at the same time too, and thus our demonstrations will be useless. From this it is clear that definitions are not arbitrary, and this is a secret to which hardly anyone has paid sufficient attention.<br /><br />

                [<b>A VI 4, p159</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Since, however, it is not in our power to perfectly demonstrate the possibility of things a priori, i.e. to analyse them down to God and nothing, it will be sufficient for us to reduce the vast majority of them to a certain few, the possibility of which can either be supposed and postulated, or proved by experience. All the lines of motion in the whole of geometry are thus reduced to just two motions, one in a straight line and the other in a circule. For with these two supposed it can be demonstrated that all other lines, for example the parabola, the hyperbola, the conchoid, and the spiral, are possible. However Euclid has not taught how a straight line is to be drawn and a circle described, but held that it was sufficient to postulate them. Yet by assuming space, body, a straight line and continuous motion, the possibility of a circle can also be demonstrated. Indeed even a straight line can be demonstrated by assuming space and body and continuous motion. However what is to be thought of these three continuums seems to depend on a consideration of divine perfection. But Geometry does not necessarily have to go this far. For even if there were no straight lines and circles in nature, nor could there be any, it will still be sufficient that there can be shapes which differ so little from straight and circular ones that the error is smaller than any one cares to mention. This is sufficient for the certainty of a demonstration and equally for its uses. Moreover, it is easily demonstrated that there can be shapes of this kind, merely by granting this one thing: there are some lines.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It is difficult to have from the outset the<a name="7" href="#note7"><sup>7</sup></a> complete definitions of these ideas (i.e., definitions showing the possibility of the thing a priori). In the meantime we shall employ nominal definitions of them; that is, we shall analyse the idea of one thing into other ideas, through which it can be conceived, even though we cannot proceed all the way to the first ideas. And this will be sufficient when it is apparent from experience that the thing is possible. For example, we can define fire as a hot and bright vapor, and it is acceptable to define the rainbow as a coloured bow in the clouds, for it is evident enough from experience that concepts of this kind are possible even if we cannot show from the outset their possibility a priori, by explaining their generation or cause.<br /><br />
                [<b>A VI 4, p160</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;There are some things for which there are no nominal definitions. Thus, there are no nominal definitions of heat and light themselves, for whoever is ignorant of what is signified by the word hot cannot be otherwise helped than by presenting him with the thing being discussed or by referring to equivalent words in a language known to him, or by stirring up his memory of it with another method, if he has experienced heat before. Yet nobody doubts that there is some cause of heat which, if it were known perfectly, would certainly provide a definition of heat.

                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> thinking | in which are discussed the true Characteristic, the Cabala, algebra, the combinatorial art,  the universal Scripture, the language of nature | <var>deleted</var>.<br />
                <a name="note2" href="#2">2.</a> possible. | The foremost of human perfections is the power of thinking. And to increase perfection is nothing other than to further one's power. | <var>deleted</var>.<br />
                <a name="note3" href="#3">3.</a> way. | <var>The alphabet of human thoughts</var> is a catalogue of those things which are conceived through themselves, and whose combination gives rise to the rest of our ideas. | <var>deleted</var>.<br />
                <a name="note4" href="#4">4.</a> That is, the binary system, as Leibniz goes on to make clear.<br />
                <a name="note5" href="#5">5.</a> the | wonderful advantages of this expression | <var>deleted</var>.<br />
                <a name="note6" href="#6">6.</a> from | God | <var>deleted</var>.<br />
                <a name="note7" href="#7">7.</a> the | real | <var>deleted</var>.
                <br /><br /><br />
		        &#169; Lloyd Strickland 2003, 2009
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Instrument;