import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/morell1699.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>S&#228;mtliche schriften und briefe series I, volume 17</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 473-474<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 1/11 September 1699<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (13k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Andre Morell (1/11 September 1699)</title>
            <meta name="description" content="An English translation of a LETTER TO ANDRE MORELL by Gottfried Wilhelm Leibniz, from 1/11 September 1699" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO ANDR&#201; MORELL</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A I 17, p473</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The theological war between the pietists and anti-pietists is still going on, and does not in any way serve to increase piety. A gentleman called Mr Arnold has given us an <var>Ecclesiastic History</var>,<a name="1" href="#note1"><sup>1</sup></a> in which he stridently scoffs at the ecclesiastic order, and it is said that he expressly quitted his chair in History at Giessen in order not to have any connection with Babylon.<a name="2" href="#note2"><sup>2</sup></a> But it seems to me that this zeal would require a little moderation. I have not yet read his <var>Ecclesiastic History</var>, but I have read another book in German verse which is attributed to him, [<b>A I 17, p474</b>] and which seems to me to be too strong.<a name="3" href="#note3"><sup>3</sup></a> One should attack vices without showing too much animosity towards people. It seems to me that one could work towards the propagation of truth and piety with a meekness worthy of a true charity, whereas the sectarian and schismatic mind of those who unleash their fury on abuses seems to show that there is a little ambition in their doings. And I fear that these gentlemen would perhaps be worse if they were the masters, just as William Penn, leader of the Quakers in England, made a small faction in Pennsylvania, according to what Mr Helmont told me, who suspected him of a little artificiality.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbspI very much approve the manner of Jakob B&#246;hme, who in the middle of his profound speculations always retained his humility and his meekness. I would like that what is good be approved everywhere, without war being waged on those who introduce bad into it. So I would like well-intentioned people, no matter what side or profession they are from, to get on well with each other, without falling out over differences which do not cause great harm to the main thing, which is piety. It is said that there was a great stir in Berne against Chiliasm; perhaps there are excesses on both sides. But the paper is running out, and it only remains for me to repeat my sincere and fervent wishes for your recovery.
        <br /><br /><br />
        <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> Gottfried Arnold, <var>Unparteyische Kirchen- und Ketzer Historie, von Anfang des Neuen Testaments biss auf das Jahr Christi 1688</var> [Impartial history of church and heretic, from the beginning of the New Testaments to the year 1688] (Frankfurt and Mainz, 1699).<br />
                <a name="note2" href="#2">2.</a> In order to defend himself against such rumours, Gottfried Arnold later published his <var>Offenhertziges Bek&#228;nt&#252;s</var> [Candid announcement] (Frankfurt and Leipzig, 1699).<br />
                <a name="note3" href="#3">3.</a> According to the Akademie editors, Leibniz may be referring here to an unidentified anonymous work which was wrongly attributed to Arnold.
        <br /><br /><br />
                &#169; Lloyd Strickland 2007
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
