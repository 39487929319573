import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/dictionary.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
		Shelfmark LH 4, 2, 2g Bl. 1-2<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: May 1714 (?)<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (153k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Some Remarks Made While Leafing Through the Letter "A" of Bayle's Dictionary</title>
            <meta name="description" content="An English translation of SOME REMARKS MADE WHILE LEAFING THROUGH THE LETTER 'A' OF BAYLE'S 'DICTIONARY' by Gottfried Wilhelm Leibniz, from May 1714 (?)" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: SOME REMARKS MADE WHILE LEAFING THROUGH THE LETTER &quot;A&quot; OF BAYLE'S <var>DICTIONARY</var><a name="1" href="#note1"><sup>1</sup></a></h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>LH 4, 2, 2g Bl. 1r</b>]<br /><br />
                <center>Some Remarks Made While Leafing Through the Letter &quot;A&quot; of Bayle's <var>Dictionary</var></center><br />
                    
                <br />
                On ABELARD, p23. Mr Bayle writes &quot;Accursius had said in <var>De quinque Pedum praescriptione</var>: 'Peter Bailard, who boasted that he could draw a good meaning from any expression, no matter how difficult, on this matter confessed his ignorance'. Alciatus, in calling him 'a celebrated professor his time' seems to have meant professor of law. Petrus Crinitus in book 25, chapter 4 of <var>de Honesta disciplina</var> says 'Johannes Bejalardus, the most learned man of those who profess civil law, ingeniously declared his ignorance of it'. But Paquier in book 6 chapter 7 of his <var>Recherches</var>, and Fran&#231;ois d'Amboise in his apologetic preface in Abelard's works, thought that this Father Bailard of the commentator was none other than the celebrated Abelard. But I do not think he<a name="2" href="#note2"><sup>2</sup></a> ever engaged in explaining the civil law.&quot;<a name="3" href="#note3"><sup>3</sup></a><br />
                REMARK. I share the view of Paquier and Fran&#231;ois d'Amboise that the commentator intended to speak of the famous Peter Abelard who had lived in the time that the digests were beginning to be known and read, calling him Peter and certainly not John. Mr de Monnoye remarks in relation to Mr Bayle himself that Jacobus Philipus of Bergamo called him &quot;Baliardus&quot;, and I have remarked that he is sometimes called &quot;Bajolardus&quot; or &quot;Bajalardus&quot; in the manuscripts, as for example in the titles of Latin theological manuscripts of the Emperor's library, where I find <var>Petri Bajolard, Dialogus christiani et philosophi de veritate christianae fidei</var>,<a name="4" href="#note4"><sup>4</sup></a> on 4<sup>o</sup> parchment. We do not find a jurisconsult earlier than Accursius who bore the name of Bailardus or something similar, and as his comment refers to a famous man, traces of him should be found if he had ever existed. This commentator intended to speak of a famous man who had the reputation of having a universal erudition but who stopped just short of it. In terms of audacity this is similar to another learned man who boasted he could explain everything, whom Thomas More confounded when ambassador in France by proposing to him certain terms of Scottish law. But to my mind More was wrong, as too is the one who wanted to pour scorn on Abelard for not having been able to explain some difficult passage of ancient Roman law.
                <br /><br />
                On ABULFEDA. Mr Bayle says that &quot;he loved geography as is clear from his work entitled <var>Chorasmiae et Mawaralnabrae, hoc est, regionum extra fluvium oxum descriptio ex tabulis Abulfedae ismaelis, principis Hamah</var>,<a name="5" href="#note5"><sup>5</sup></a> published in London in 1650 by John Graves... As the copy belonging to the Imperial Library lent to Schrickard was not legible in several places, he left the greatest part of the labour and glory to John Graves, inferring from that that Fabricius in <var>Specimen Linguae Arabicae</var> p99 was wrong to say, <var> apud Konig</var>, that Schrickard has translated into Latin the work of Abulfeda. Spizelius in <var> Specimen Biblioth</var> cites the same Fabricius [<b>LH 4, 2, 2g Bl. 1v</b>] as having said that Schickard has translated the whole work.<a name="6" href="#note6"><sup>6</sup></a> Spizelius did not know in 1668, nor Konig in 1678, that Abulfeda had been printed in England.&quot;<a name="7" href="#note7"><sup>7</sup></a> In the annotations, Mr Bayle corrects Moreri for having said that Abulfeda treated his geography by climates and that he hopes for the missing parts. &quot;For,&quot; he says &quot;one has never seen from Abulfeda anything but the description of some parts of Asia situated on the other side of the Oxus.&quot;<a name="8" href="#note8"><sup>8</sup></a><br />
                REMARK: Here, Moreri was better informed than Mr Bayle. It is certain that we have a complete geography from Abulfeda, though it is still in manuscript. Graves gave us only a small part of it, in the form of an essay.<a name="9" href="#note9"><sup>9</sup></a> Fabricius as well as Spizel and Konig were right to say that Schickard had translated all of Abulfeda. Here is what I know about this: Mr Schickard copied the Arabic manuscript of Abulfeda that Mr Le Grage, the librarian of the Emperor, had passed on to him, and at the same time he made a Latin translation that he called <var>Versionem Tumultuariam</var>. He planned to print this work, but his death prevented him from doing so. Since then, the Englishman Mr Graves printed a small part of it, but Mr Thevenot had planned to complete Mr Schickard's project. I purchased the copy and the latter's version for the library of the Very Christian King at Mr Carcavi's request. Yet the plan of Mr Thevenot, who succeeded Mr Carcavi in the charge of keeper of the King's library, also remains unfinished. Now Mr Seebach, librarian of the King of Poland in Dresden and a man well-versed in Arabic, intends to give us this author, who will provide us with good information about the interior of Asia and Africa, about which the Mahommedans have been better informed than us.
                <br /><br />
                [<b>LH 4, 2, 2g Bl. 2r</b>]<br /><br />

                ALTHUSIUS (Johannes). Mr Bayle writes: &quot;jurisconsult from Germany... some jurisconsults of his country are extremely angry with him because he maintained that the sovereignty of states belongs to the people. He wrote a treatise <var>De jurisprudentia Romana</var>, another <var>De Civili Conversatione</var>, and another entitled <var>Dicaeologica</var>.&quot;<a name="10" href="#note10"><sup>10</sup></a><br />
                REMARK: He was syndic in the town of Emden. He has given us an abridgement of roman jurisprudence in 12<sup>o</sup> and in 8<sup>o</sup>, which he has altered more than once.<a name="11" href="#note11"><sup>11</sup></a> Finally he published in 4<sup>o</sup> a very polished system of jurisprudence under the name <var>Dicaeologica</var>.<a name="12" href="#note12"><sup>12</sup></a> This work was quite well regarded even though he had followed his own method like Connanus, Vigelius, Doneau, Vulterius and some others, without following that of institutes or digests. If the doctrine of the power of the people over the prince, which he maintained in his system of politics, is restricted to cases of extreme necessity, it can be excused. But in that time, both those of the Roman Church and those of the Augsburg Confession accused the Reformers of going too far on this point,<a name="13" href="#note13"><sup>13</sup></a> and of being monarchomachs.<br /><br />

                ANGLUS (Thomas). Mr Bayle writes: &quot;English priest... He was a long time a domestic of Sir Kenelm Digby. He claimed to use Aristotle's principles to bring light to the most impenetrable mysteries of religion.&quot;<a name="14" href="#note14"><sup>14</sup></a><br />
                REMARK: He was known in England under the name Thomas White. And he was still alive in 1675. I have a rather substantial devotional work of his in English. He has also given us several Latin books of which Mr Bayle is ignorant, among others his <var>Euclides physicus</var> and <var>Euclides metaphysicus</var>.<a name="15" href="#note15"><sup>15</sup></a> I think I have these two works. In them, he claims to give demonstrations in the Euclidean manner, but I have found some faults therewith. Nonetheless he is very penetrating and profound. The more abstract parts of Mr Digby's great work on the immortality of the soul, principally those concerning the composition of the continuum, appear to be from him.<a name="16" href="#note16"><sup>16</sup></a> Mr Bayle had seen his little book entitled <var>Statera appensa</var>,<a name="17" href="#note17"><sup>17</sup></a> but he had not seen the main one, on which this little one was based, that is, [<b>LH 4, 2, 2g Bl. 2v</b>] his ethics, under the title <var>Statera morum</var>,<a name="18" href="#note18"><sup>18</sup></a> of which the <var>Statera appensa</var> is only the apology. He sided with the disciples of St Augustine against the Jesuits, and Mr Holden, English doctor of the Sorbonne, was his friend and shared his view, as is clear from the book entitled <var>Analysis Fidei</var>,<a name="19" href="#note19"><sup>19</sup></a> which was opposed to the book by Father Gregory of Valencia which has the same title.<a name="20" href="#note20"><sup>20</sup></a> In it, Mr Holden maintained - in much the same way as Mr White - that the Church cannot make new articles of faith, and that all it can do regarding faith is bear witness to it.<br /><br />

                ARAGON (Mary of). Bayle: &quot;Wife of the Emperor Otto III, and Daughter of a King of Aragon, became infamous for her lewdness, which at last brought her the punishment of fire.&quot;<a name="21" href="#note21"><sup>21</sup></a><br /> 
                REMARK. I am surprised Mr Bayle did not know that this whole story is made up. Emperor Otto III died young, without marrying. An archbishop of Milan had ordered him to look for a wife in Constantinople, where his mother, Theophanu, was from. This we discover in Arnolfe's history of Milan, which I was the first to publish.<a name="22" href="#note22"><sup>22</sup></a> But the emperor died before carrying out this plan.<br /><br />

                ARISTARCHUS. Mr Bayle writes &quot;Famous grammarian... much esteemed by Ptolomy Philometor... He revised Homer's poems. The error of those who thought him a contemporary of Peisistratos is very ancient. On p93 of <var>De Patria Homeri</var>, Allatius cites the passage from a commentator on Dionysius of Thrace to the effect that when putting Homer's poems into order, Peisistratos was assisted by Aristarchus and Zenodotus. Eusathius in <var>A Iliados</var> asserts the same thing.&quot;<a name="23" href="#note23"><sup>23</sup></a><br />
                REMARK: If it is true, as Plutarch says in his book on Homer, that Aristarchus was responsible for the division, order and numbering (according to the Greek alphabet) of Homer's books,<a name="24" href="#note24"><sup>24</sup></a> he<a name="25" href="#note25"><sup>25</sup></a> must be much more ancient than Ptolomy Philometor, especially since Ausonius means him when referring to the one &quot;who assembled the sacred work of mutilated Homer&quot;.<a name="26" href="#note26"><sup>26</sup></a> Nevertheless, if it is true that this Aristarchus, to whom Ptolomy Philometor had entrusted the education of his son, has worked on Homer, there must have been two famous Aristarchuses in criticism and both were applied to Homer's work, one being a contemporary of Peisistratos, the other of Ptolomey Philometor. As for Zenodotus, we come back to the same question of whether he was a contemporary of the first or the second Aristarchus, for it is not likely that there would also have been two Zenodotuses who were contemporaries of the two Aristarchuses, and that all four worked on the criticism of Homer's poems.<br /><br />

                [Marginal comments]<br /><br />

                p. 350. &quot;Aristotle's logic is weak.&quot;<a name="27" href="#note27"><sup>27</sup></a><br />
                REMARK. I am not of this view. His logic strikes me as very strong, and the best of his works on philosophy. And if he is the first who had established the form of syllogisms, he deserves to be placed among the innovators. For it is something as certain as mathematics.<br /><br />

                p.352. &quot;Moving intelligences are necessary.&quot;<a name="28" href="#note28"><sup>28</sup></a><br />
                REMARK: I am astonished that Mr Bayle was able to give in to such a poorly-founded opinion, especially today when mechanical principles sufficient to explain the laws of motion of the stars have been found. But Mr Bayle, less versed in mathematics, was not informed about them.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> Paul Ritter, compiler of the Ritterkatalog, gave this text a provisional date of 1698, but that cannot be correct because in it Leibniz refers to his publication of an ancient text on the history of Milan, which occurred in 1711. This text cannot therefore have been written before 1711. I have opted to date this text to May 1714 on the slightly flimsy grounds that Leibniz uses the same (mis)quote from Ausonius in a letter to Hasperg of 2 May 1714 as he does in this text, indicating that they might have been written around the same time.<br />
                <a name="note2" href="#2">2.</a> Evidently Abelard.<br />
                <a name="note3" href="#3">3.</a> This passage is a mixture of quotations from and paraphrases of various parts of note Y of Bayle's article on Abelard, with the final sentence coming from the article itself (not the note). See Pierre Bayle, <var>Dictionnaire historique et critique</var> (Rotterdam, 1702, 2ed), 23, art. &quot;Abelard&quot;.<br />
                <a name="note4" href="#4">4.</a> &quot;Dialogue between a Christian and a Philosopher on the truth of Christian faith&quot;.<br />
                <a name="note5" href="#5">5.</a> [<var>A description of Chorasmia and Mawaralnabra, or the countries beyond the river Oxus, from the tables of Abulfeda Ismael, Prince of Hamab</var>]<br />
                <a name="note6" href="#6">6.</a> The passage from &quot;inferring from that ... the whole work&quot; is marginal comment C in Bayle's work, and not part of the main text.<br />
                <a name="note7" href="#7">7.</a> Bayle, <var>Dictionnaire historique et critique</var>, 36-37, art. &quot;Abulfeda&quot;.<br />
                <a name="note8" href="#8">8.</a> Bayle, <var>Dictionnaire historique et critique</var>, 36, art &quot;Abulfeda&quot; note B.<br />
                <a name="note9" href="#9">9.</a> Abulfeda, <var>Chorasmi&#230; et Mawaralnahr&#230;, hoc est, regionum extra fluvium Oxum descriptio, ex tabulis Abulfed&#230; Ismaelis, Principis Hamah</var>, ed. J. Gravius (London, 1650).<br />
                <a name="note10" href="#10">10.</a> Bayle, <var>Dictionnaire historique et critique</var>, 177, art. &quot;Althusius&quot;.<br />
                <a name="note11" href="#11">11.</a> Johannes Althusius, <var>Juris Romani Libri duo: Ad Leges Methodi Rameae conformati: Et Tabul&#226; illustrati</var> (Basel, 1586). Subsequent editions - under different titles - were published in Herborn 1588, Basle 1589, Herborn 1592, and Herborn 1599.<br />
                <a name="note12" href="#12">12.</a> Johannes Althusius, <var>Dicaeologica libri tres, totum et universum Jus, quo utimur, methodice complectentes</var> (Frankfurt, 1618).<br />
                <a name="note13" href="#13">13.</a> As Leibniz implies, Althusius was a Reformer, i.e. a Calvinist.<br />
                <a name="note14" href="#14">14.</a> Bayle, <var>Dictionnaire historique et critique</var>, 254, art. &quot;Anglus (Thomas)&quot;.<br />
                <a name="note15" href="#15">15.</a> Thomas Anglus, <var>Euclides Physicus, sive de principiis naturae Stoecheidea</var> (London, 1657); Thomas Anglus, <var>Euclides metaphysicus, sive de principiis sapientiae</var> (London, 1658).<br />
                <a name="note16" href="#16">16.</a> Kenelm Digby, <var>Two treatises: in the one of which, the nature of bodies; in the other, the nature of mans soule, is looked into: in way of discovery of the immortality of reasonable soules</var> (London, 1645).<br />
                <a name="note17" href="#17">17.</a> I. S., <var>Statera appensa quoad salutis assequend&#230; facilitatem</var> (London, 1661).<br />
                <a name="note18" href="#18">18.</a> Thomas Anglus, <var>Dux vitae; sive, Statera morum, ethico-politico-theologica, admirabili &amp; nova methodo tradita &amp; in III. tomos divisa, I. Mores circa amabilia homini, II. Fortitudinem & iustitiam, III. Virtutes supernaturales cum prudentia complectens</var> (London, 1672)<br />
                <a name="note19" href="#19">19.</a> Henry Holden, <var>Divinae fidei analysis, seu de fidei Christianae resolutione libri duo</var> (Paris, 1652).<br />
                <a name="note20" href="#20">20.</a> Gregory of Valencia, <var>Analysis Fidei Catholicae, hoc est, Ratio Methodica Eam In Universvm Fidem ex certis principiis probandi, quam sancta romana ecclesia, adversus multiplices sectariorum errores, profitetur</var> (Ingolstadt, 1585).<br />
                <a name="note21" href="#21">21.</a> Bayle, <var>Dictionnaire historique et critique</var>, 282, art &quot;Aragon (Marie d')&quot;.<br />
                <a name="note22" href="#22">22.</a> Leibniz is referring to &quot;Arnulphi gesta mediolanensium&quot; [Arnolfe's Deeds of Milan], first published in G. W. Leibniz (ed), <var>Scriptores Rerum Brunsvicensium illustrantium tomus tertius</var> (Hanover, 1711), 727-746. The passage in question is on 730 (book I chapter XI of Arnolfe's Deeds).<br />
                <a name="note23" href="#23">23.</a> This passage is a mixture of quotations from and paraphrases of various parts of Bayle's article on Aristarchus and also note D of the same article. See Bayle, <var>Dictionnaire historique et critique</var>, 335-336, art. &quot;Aristarchus&quot;.<br />
                <a name="note24" href="#24">24.</a> &quot;There are two poems by Homer, the <var>Iliad</var> and the <var>Odyssey</var>, each divided into as many books as there are letters in the alphabet [24], not by the poet himself but by the scholars of the school of Aristarchus.&quot; Plutarch, <var>Essay on the Life and Poetry of Homer</var>, eds. J. J. Keaney and Robert Lamberton (Atlanta: Scholars Press, 1996), 69.<br />
                <a name="note25" href="#25">25.</a> Evidently Aristarchus.<br />
                <a name="note26" href="#26">26.</a> Leibniz's quotation is slightly wide of the mark; it should read &quot;who assembled the mutilated works of the sacred Homer&quot;! See <var>D. Magni Ausonii Burdigalensis Opera ex doctorum virorum emendatione</var> (Basel, 1781), 286.<br />
                <a name="note27" href="#27">27.</a> Bayle, <var>Dictionnaire</var>, 350: &quot;the weakest of his works, I mean his Logic and his Physics&quot;.<br />
                <a name="note28" href="#28">28.</a> Bayle, <var>Dictionnaire</var>, 352: &quot;there are some doctrines of Aristotle that the Moderns have rejected and which must in the end be adopted.&quot; Bayle then directs the reader to marginal note (p), which reads: &quot;Such is the hypothesis of moving intelligences.&quot;<br />
        <br /><br />
                &#169; Lloyd Strickland 2016
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
