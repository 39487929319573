import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/system.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
		Shelfmark LH 4, 1, 4k Bl. 39<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 1710 - 1714 (?)<br /><br />
		Translated from the French<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (156k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Some remarks on my system</title>
            <meta name="description" content="An English translation of SOME REMARKS ON MY SYSTEM by Gottfried Wilhelm Leibniz, from 1710 - 1714 (?)" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: SOME REMARKS ON MY SYSTEM</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>LH 4, 1, 4k Bl. 39</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Nothing can be concealed in my system, because everything in it has a perfect connection.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We have to have very accurate and very precise distinctions; for example, we must distinguish between the infinite<a name="1" href="#note1"><sup>1</sup></a> and the whole; the whole is opposed to nothing and the infinite is opposed to the finite. We must also distinguish between a substance and an aggregate of substances, <var>inter substantiam and substantias</var>.<a name="2" href="#note2"><sup>2</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The distinctions made by Scholastics are not always to be despised; for example, the one they make between the true one, <var>unum per se</var>,<a name="3" href="#note3"><sup>3</sup></a> and the aggregative one.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;My remarks on Mr Gassendi, Father Malebranche, Mr Descartes, Spinoza, and Mr Locke serve to prepare minds.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I cannot always explain myself fully, but I always try to speak accurately.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I begin as a philosopher but I end as a theologian. One of my great principles is that nothing happens without reason. This is a principle of philosophy. Ultimately, however, it is nothing other than an acknowledgement of divine wisdom, though I do not speak of this at first.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In my opinion, organization can only begin by a miracle today or at the beginning of things. This is because it is infinite and the parts of natural machines are still machines.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Epicurus and Mr Descartes were mistaken in thinking a body of a man or beast can be formed naturally or mechanically from a non-organic mass.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> infinite ǀ and the universe ǀ <var>deleted</var>.<br />
                <a name="note2" href="#2">2.</a> &quot;between a substance and substances.&quot;<br />
                <a name="note3" href="#3">3.</a> &quot;one through itself.&quot;<br /><br /><br />
                &#169; Lloyd Strickland 2019
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
