import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/soul.pdf'; 
import { Helmet } from 'react-helmet';

function AnimalSoul() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
       Source:<br /><br />
			<var>S&#228;mtliche schriften und briefe series VI volume 4</var><br />
        Deutsche Akademie der Wissenschaften (ed)<br />
        p 1474<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 1683 - 1685?<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (168k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>On the Animal's Soul</title>
            <meta name="description" content="An English translation of ON THE ANIMAL'S SOUL by Gottfried Wilhelm Leibniz, from 1683 - 1685?" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: ON THE ANIMAL'S SOUL</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A VI 4, 1474</b>]<br /><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I am afraid the opinion of the Cartesians will one day be refuted by experience if people were to take greater care in training animals. When writing to More, Descartes correctly asserts that he knows demonstratively that all the actions of beasts can be explained without invoking souls, such that the contrary cannot be demonstrated,<a name="1" href="#note1"><sup>1</sup></a> but this should be understood according to metaphysical rigour, whereby it cannot be demonstrated of other human beings either.<br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTE:<br /><br />
                <a name="note1" href="#1">1.</a> &quot;there are two different principles causing our movements. The first is purely mechanical and corporeal, and depends solely on the force of the spirits and the structure of our organs, and can be called the corporeal soul. The other, an incorporeal principle, is the mind or that soul which I have defined as a thinking substance. Thereupon I investigated very carefully whether the movements of animals originated from both of these principles or from one only. I soon perceived clearly that they could all originate from the corporeal and mechanical principle, and I regarded it as certain and demonstrated that we cannot at all prove the presence of a thinking soul in animals... But though I regard it as established that we cannot prove there is any thought in animals, I do not think it can be proved that there is none, since the human mind does not reach into their hearts.&quot; Descartes, Letter to Henry More 5 February 1649 in John Cottingham, Robert Stoothoff, Dugald Murdoch &amp; Anthony Kenny (eds), <var>The Philosophical Works of Descartes Vol. III</var> (Cambridge: Cambridge University Press, 1991), 365.
                <br /><br /><br />
                &#169; Lloyd Strickland 2005. Revised 2021<br />
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default AnimalSoul;
