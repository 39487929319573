import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/theodicaea.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
		Shelfmark LH 1, 6, 2 Bl. 20r<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: December 1695<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (93k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Theodicy</title>
            <meta name="description" content="An English translation of THEODICY by Gottfried Wilhelm Leibniz, from December 1695" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: THEODICY</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>LH 1, 6, 2 Bl. 20r</b>]<br /><br />
		        <center>THEODICY</center>

                <center>Or, on behalf of divine justice.</center>
                <center>Catholic demonstrations up to</center>
                <center>mathematical certainty and form</center>
                <center>from natural theology and accurate jurisprudence</center>
                <center>with which human kind can be freed from doubts</center>
                <center>concerning contingency and fate, freedom and</center>
                <center>predestination</center>
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Because the perfections of nature are nothing other than the ordinary grace of God, I am not able to remove with mathematical certainty the problems concerning the assistance of grace, nor do I believe them capable of being settled by reason, since it is in accordance with the choice of divine wisdom how much perfection to concede to nature, and how much it may wish to reserve for grace. This, then, is to be learned from revelation, and I think that what seems to be taught by the opinions of holy men and by the judgement of the Church needs to be brought together. And I think the popes, who proclaim that this is something not yet settled, have acted wisely.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Writing by Mr Jurieu on this subject. Vincent Baron. Molina on Thomas. Pseudo Dionysius to Hildesheim. The Stoics, Lipsius and Scioppius. Mahomedans who say 'it is written', idle talk. Selected dissertations of Vo&#235;tius.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;These are very ancient doubts. New studies: concerning the original imperfection of creatures before sin, from which the cause of sin is to be sought. All things are good but nothing is absolutely perfect. Concerning the composition of things from perfection and limitation, i.e. from action and privation, and concerning the origin of things from God and nothing, in which concerns the origin of numbers from 1 and 0, i.e. from unity and nothing. Concerning the root of contingency, where it is shown according to certain considerations that the contingent has the same relationship to the necessary as surd number does to rational number. Concerning the connection of things, where it is shown that God, according to his supreme wisdom, would not establish from one single act that which he could establish from the whole series of the universe; the consequence of which is that God does not determine whether a man should sin or be punished, but whether a man who is going to sin and be punished, and the possible series of things in which that is contained, should be admitted to existence before another possible form of the universe.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;All contingencies are infallible and certain, but they are not necessary.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Perfection is pure act, i.e. pure positive. What is usually said about act and power, we shall rightly say about the positive and the privative, i.e. about the absolute and the limited.
                <br /><br /><br />
                        &copy; Lloyd Strickland 2003
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
