import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/bierling1713.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>Opera Omnia volume 5</var><br />
		Ludovic Dutens (ed)<br />
		pp 390-391<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: January - April 1713<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (193k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Friedrich Wilhelm Bierling (Jan. - Apr. 1713)</title>
            <meta name="description" content="An English translation of a LEIBNIZ TO FRIEDRICH WILHELM BIERLING by Gottfried Wilhelm Leibniz, from January - April 1713" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO FRIEDRICH WILHELM BIERLING</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>D V, p390</b>]<br /><br />
                It is to be wished rather than hoped that there are wise men who are always guided only by the counsel of right reason. These things concern the idea of the best republic, which we envision in order that we might approach it as much as is possible. I greatly disapprove of the fact that in the work of distinguished men like <var>Samuel Pufendorf</var>, <var>Christian Thomasius</var>, and those who follow them, it is taught that the immortality of the soul, as well as punishments and rewards beyond this present life, are known through revelation alone. The <var>Pythagoreans</var> and <var>Platonists</var> understood this better. I have observed in a letter to <var>B&#246;hmer</var> about Pufendorf's little book on duties,<a name="1" href="#note1"><sup>1</sup></a> that this foundation of natural theology<a name="2" href="#note2"><sup>2</sup></a> is evident to any populace which acknowledges divine providence and the consequences of it, even leaving aside metaphysical arguments, of which we have insuperable examples. [<b>D V p391</b>] A doctrine of morals, justice, and duties, which depends on the goods of this life alone, is very imperfect, as I have shown in the same letter. The doctrine of providence is useless without the immortality of the soul, and has no more power to obligate men than Epicurus' gods, which are devoid of providence. Accordingly, if God does not convey to us the principles from which we might learn of our immortality, natural theology is useless, and is of no use whatsoever against practical atheism. And it was possible for men to be atheists before revelation, since the divinity does not always avenge itself in this life. In order to uphold the immortality of the soul it is not necessary to say that the soul is a separate substance, for it could always remain clothed with a subtle body, such as I also attribute to angels. It remains to say, farewell, and think well of me. 1713
                <br /><br /><br />
                <hr className='rightcol-hr' />
                        <br /><br />
                        NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> The letter in question was written in 1706, and is printed in <var>Political Writings</var>, trans. and ed. Patrick Riley (Cambridge: Cambridge University Press, 2ed), 65-75.<br />
                        <a name="note2" href="#2">2.</a> In other words, the immortality of the soul.
                <br /><br /><br />
                        &#169; Lloyd Strickland 2016
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
