import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom'; 
import { Helmet } from 'react-helmet';
import axios from 'axios';
import DOMPurify from 'dompurify';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Search = () => {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const query = useQuery();
  const keywords = query.get('keywords') || '';
  const [results, setResults] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://www.leibniz-translations.com/search.php', {
          params: { keywords },
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (Array.isArray(response.data.results)) {
          setResults(response.data.results);
        } else {
          setResults([]); 
        }
      } catch (error) {
        setResults([]); 
      }
    };

    fetchData();
  }, [keywords]);
  
  return (
    <div>
        <Helmet>
            <title>Search Results</title>
            <meta name="description" content="Search Results" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={2} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                    <></>
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>Search Results for "{DOMPurify.sanitize(keywords)}"</h2>
                  Found {results.length} result(s).
                  <br></br><br></br>
                  {results.length > 0 ? (
                    results.map((result, index) => (
                      <Col key={index} xs={12} style={{marginBottom: '20px'}}>
                        <div style={{ display: 'flex', flexDirection: 'column', height: '100%', position: 'relative' }}>
                          <Row>
                            <div style={{ display: 'flex' }}>
                              <div style={{ marginRight: '25px' }}>
                                <span className="circle">{index + 1}</span>
                              </div>
                              <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                                <Link to={result.page_link}>
                                  <h6 className="textTitle" style={{ margin: 0 }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(result.page_title) }} />
                                </Link>
                                <p style={{ color: 'grey', margin: 0, marginBottom: '17px' }}></p>
                                <hr style={{ position: 'absolute', bottom: 0, width: 'calc(100% - 75px)', margin: 0 }}/>
                              </div>
                            </div>
                          </Row>
                        </div>
                      </Col>
                    ))
                  ) : (
                    <p>No results found</p>
                  )}
                  <br /><br /><br />
                </Col>
                <Col md={2}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Search;

