import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/necessity.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>Textes in&#233;dits tome 1</var><br />
        Gaston Grua (ed)<br />
        pp 249-252<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        Date: 1705<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (282k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Notes on J.C.'s "Answer to the Query of a Deist Concerning the Necessity of Faith"</title>
            <meta name="description" content="An English translation of NOTES ON J.C.'S 'ANSWER TO THE QUERY OF A DEIST CONCERNING THE NECESSITY OF FAITH' by Gottfried Wilhelm Leibniz, from 1705 ?" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: NOTES ON J.C.'S <var>ANSWER TO THE QUERY OF A DEIST CONCERNING THE NECESSITY OF FAITH</var></h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>Gr p249</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1705. Mr Burnett has passed on the following work, of 12 pages in quarto: J.C. <var>Answer to the Query concerning the Necessity of Faith</var>.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The letter is to someone who asked whether or not it is contrary both to divine justice and clemency to damn a man living among pagans, where either the Christian faith is unknown, or represented so badly that it is considered incredible and absurd; a man who, it is said, conducts himself towards God and his neighbour as the moral law of nature and conscience prescribe, insofar as he understands it, or who at any rate recovers from his error. Indeed, the same person who raised the query thought it absurd that anyone should be damned on account of a lack of faith at which he could not arrive by reason, or on account of a received error, which was not easily avoided by reason.<a name="1" href="#note1"><sup>1</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The author calls this an &quot;Antichristian&quot; query, and he lays down the following assertion in opposition to it: salvation, insofar as it imports either remission of sin or fruition of God, is not due to man, nor attainable by him except through the right faith in a mediator between God and men, namely Christ, and that therefore such a mediator and such a faith exists.<a name="2" href="#note2"><sup>2</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It seems to me that between the query and assertion there is no disagreement at all, and in any case different things are confused in the assertion. The fruition of God is not due to man, but freedom from punishment (which is salvation, properly speaking) is due to the innocent, and punishment to the sinner; nor does repentance bestow a remission of sins by its own nature, or save one from punishment. However there is no eternal punishment unless sin is also eternal.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The author then presents some positions. The first is that the remission of sin and the justification of the sinner are not due to repentance nor [<b>Gr p250</b>] attainable by his own power.<a name="3" href="#note3"><sup>3</sup></a> This thesis is true insofar as it is said that remission is not due on account of repentance alone. But what if repentance constitutes a natural penalty for sin? Therefore it has to be proved that this cannot be obtained by human powers. The author does not prove his thesis very well here. For he runs into the old difficulty of the infinity of sin, namely: because sin includes disobedience and resistance to God's known will, which is an infinite good, therefore sin also contains an infinite injustice.<a name="4" href="#note4"><sup>4</sup></a> But leaving aside that the author in this way falls into the opinion of those who distinguish philosophical and theological sin, many sinners do not in fact consider that God is offended by sin, with regard to whom the author's argument is invalid. It should be known that the conclusion is not sufficiently justified even when someone does offend God, because doubtless the sin is infinite since the infinite can be offended, although it cannot be infinitely offended, just as if you were to cut a part AB from an infinite straight line AB etc. And although God does not have parts, nevertheless there are considered to be parts which can be offended in God, namely his reputation or honour among men.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The second position is that the fruition of God is not due to the simple innocence of man.<a name="5" href="#note5"><sup>5</sup></a> I admit that this is true. Nor is the author wrong to say that in the query there is falsely supposed a man who arrives at charity and the use of reason, ignorant of or mistaken about the principles of Christianity, and yet without any malice of will and inculpable [in his observation of] the law of nature. For in fact there is no such man.<a name="6" href="#note6"><sup>6</sup></a> He adds that there is some original defect in everyone, which even shows itself in infants, and emerges into actuality at a certain time, with the first use of reason.<a name="7" href="#note7"><sup>7</sup></a> But even leaving this aside, there is still nothing due to man, who has nothing of the good by himself.<a name="8" href="#note8"><sup>8</sup></a> The conclusion is broader than the thesis; it must be conceded that the fruition of God is not due even to a man who is innocent and lives according to the law of nature, but to say that nothing is due to a man who cherishes virtue is to go too far. Impunity is due to an innocent man, and some happiness is also due to a man working naturally in accordance with virtue; I mean natural happiness, not that supreme and supernatural happiness which consists in divine fruition. And although God will have produced all the goods of man, nevertheless his wisdom will also award goods to good men, just as an artist who makes a statue will keep it in an honoured place, if it is outstanding. But it must be admitted that men who are entirely innocent and always act in accordance with virtue are not found in the world. But if anyone were such, his right to natural happiness, which Grotius calls a legal claim, would not be perfect, [<b>Gr p251</b>] but would require an act of charity, his having congruous but not condign merit.<a name="9" href="#note9"><sup>9</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The author's third position is that salvation, insofar as it consists of remission of sins and the fruition of God, is not absolutely unattainable by man.<a name="10" href="#note10"><sup>10</sup></a> This proposition is true for the reason he adds, that God's power and goodness are infinite and man has the capacity for so much grace.<a name="11" href="#note11"><sup>11</sup></a> Not badly put. For the rest, when the discussion is about the fruition of God, I would prefer to say beatification instead of salvation.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The fourth position is that it is most expedient that there be a mediator between God and men, who is at the same time God and man.<a name="12" href="#note12"><sup>12</sup></a> For infinite satisfaction is fitting for infinite sin in order that man enjoy infinite good.<a name="13" href="#note13"><sup>13</sup></a> Not badly put. For as sin is not formally but terminatively infinite, so also it is fitting that satisfaction be infinite in the same way. Sin is terminatively infinite from the object, satisfaction from the efficient cause. Even the fruition of God is not formally infinite but terminatively so.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Fifth<a name="14" href="#note14"><sup>14</sup></a> position. There really is a mediator between God and man, namely Jesus, who was God and man.<a name="15" href="#note15"><sup>15</sup></a> The author proves this by motives of credibility: divine justice, miracles performed by Christ, the wonderful propagation of Christ's doctrine, and the superior sanctity of his dogmas to human doctrines.<a name="16" href="#note16"><sup>16</sup></a> When he speaks of the justice of the mediator, he says that Christ has satisfied divine justice for us.<a name="17" href="#note17"><sup>17</sup></a> It can be noted that even if one cannot in a certain sense be punished for another, nevertheless one can make satisfaction for another, both by making good the evil and by paying the penalty, which is equivalent to punishment.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The sixth<a name="18" href="#note18"><sup>18</sup></a> and final position is that insofar as remission of sins and the fruition of God pertain to salvation, a faith in Christ is necessary.<a name="19" href="#note19"><sup>19</sup></a> I note that these two things - remission of sins and the fruition of God - are not necessarily connected, though they can be connected by divine institution. For the rest, he proves his thesis like this: in accordance with a law of nature, gratitude or acknowledgement of the benefit is required for the benefit as well as a love of the benefactor.<a name="20" href="#note20"><sup>20</sup></a> And in this acknowledgement is included knowledge of the truth and mysteries connected to the benefits.<a name="21" href="#note21"><sup>21</sup></a> I respond that this knowledge is not necessarily required for gratitude. Also, gratitude is only required from someone who is fully informed. No ingratitude can be ascribed except to someone who knows the benefit or to someone affectedly or flagrantly ignorant of it. For that reason, some people claim that implicit faith is sufficient. [<b>Gr p252</b>] And indeed it seems sufficient for salvation if everything else is present; but what is sufficient for beatification cannot be affirmed with certainty since it depends on God's will and mercy, the innermost workings of which are neither known nor revealed to us.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Toward the end the author gives his responses to objections. When the objector is surprised that God creates so many men who are deprived of their natural end, beatitude,<a name="22" href="#note22"><sup>22</sup></a> it can be responded, if I am not mistaken, that the beatitude consisting in the fruition of God is not man's natural but supernatural end. Then it is objected that it is better for a man not to exist than to be eternally unhappy.<a name="23" href="#note23"><sup>23</sup></a> The author denies this.<a name="24" href="#note24"><sup>24</sup></a> I would answer by drawing a distinction: it would be better for such a man himself not to exist, because Christ too expressly said it would be better for such a man not to have been born,<a name="25" href="#note25"><sup>25</sup></a> but for the universe itself it is better that the matter be as it is. He also says that pains are not positive things.<a name="26" href="#note26"><sup>26</sup></a> I should think that pain, like error, is positive, but is founded in the privative. It is rightly said that God is good to all, and unjust to no one.<a name="27" href="#note27"><sup>27</sup></a> But although it is said on the last page of the same book that God has admitted some to beatitude and excluded others,<a name="28" href="#note28"><sup>28</sup></a> I think it should be noted that no one is excluded by God unless first excluded by himself.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Lastly, what he proposes - that whatever God wills is just because he wills it, since the will of God is the supreme and <var>unaccountable</var> rule of justice and goodness and not liable to give an account of itself - I no more approve of this.<a name="29" href="#note29"><sup>29</sup></a> The ultimate rule of justice is not God's will but his wisdom. The will of the wise is a consequence of his understanding. But he would be a despot and tyrant if his will stood in the place of reason. And although God is not obligated to give an account to anyone, he cannot thereby be called <var>unaccountable</var>, for he cannot not satisfy himself, that is, he acts in all respects for the highest reason, which makes him accountable to himself.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTE:<br /><br />
                <a name="note1" href="#1">1.</a> &quot;In the Discourse we had when we last met, you demanded of me, Whether it was not a shocking Sentence, and hardly consistent with the Justice and Clemency of God, to affirm, That a Person born and bred in a Pagan Country, where Christianity is either not at all known, or at least so represented, as to render it incredible and absur'd; yet this Person living up to the dictates of a good Conscience in Morality, that is, To the Prescript of the Law of Nature, in regard to God, and his Neighbour, to the best of his knowledg; Or in case of frailty and failure, becoming Repentant of his fault, should nevertheless be Damn'd for want of a Belief, (tho' contrary to his Reason) of the Articles and Misteries of the Christian Faith?&quot; J.C., <var>An Answer to the Query of a Deist, concerning the Necessity of Faith</var> (n.p.: n.p., 1687), p1.<br />
                <a name="note2" href="#2">2.</a> &quot;To this (as I may term it) Antichristian Query, I, as a Christian, return by way of Answer, this opposite Assertion (<var>viz</var>.) <var>Salvation, as it imports either Remission of Sin, or Fruition of God, is not due to Man, nor attainable by him, otherwise than by a right Faith in one Mediator between God and Man Christ Jesus. And therefore there actually is such a Mediator, and such a Faith</var>.&quot; J.C., op. cit., p1.<br />
                <a name="note3" href="#3">3.</a> &quot;My first position is, That <var>Remission of Sin, and Justification of a Sinner, tho' Repentant, is not, by Vertue of such Repentance alone, due to the Sinner, nor attainable by him</var>.&quot; J. C., op. cit, p2.<br />
                <a name="note4" href="#4">4.</a> &quot;Now Sin, as it includes a disobedience and repugnance to the known Will and Law of God an Infinite good, carries along with it an infinite degree of Injustice, and on its part despoils the Almighty of his Soveraignity.&quot; J. C., op. cit, p2.<br />
                <a name="note5" href="#5">5.</a> &quot;My second Position is, The Fruition of God in regard to Man, tho' of himself innocent, and free from Sin, is not by force or efficacy of such innocence due to Man.&quot; J. C., op. cit, p2.<br />
                <a name="note6" href="#6">6.</a> &quot;Before I undertake to prove this Position, it is fitting I first advertise; your Query, as to this part of it, is bottomed on a false supposition (viz.) That there is extant a Person arriv'd to the use and maturity of Reason, wholly ignorant, or mislead in the Principles of Christianity; yet without any malignity of Will, spotless and inculpable in the observance of the Law of Nature; for in reality there is no such Person.&quot; J. C., op. cit, p2.<br />
                <a name="note7" href="#7">7.</a> &quot;That there is in Man's Nature, from his very Conception and Birth, propagated in him, and inherent to him, a strong Propension to Vice and Wickedness; and as strong an Aversion from Vertue and Goodness, which Corruption of human Nature is termed original Concupiscence, and Injustice. And tho' this depravation doth not discover itself in Infants, by reason of the Organs of the Body, on which the use and exercise of the rational Faculties of the Soul depend, are not qualify'd and dispos'd in Infants for such exercises. Yet so soon as these Impediments in the Organs are removed, and Man by degrees arrives to the use and liberty of his Will and Reason in discerning Good and Evil, we manifestly and daily see, infected and vitiated Nature, hurried by the corrupt Inclinations radicated in it, without any new addition or change made in the Soul it self, or its inclinations, willfully, and not by force or compulsion, breaks forth like an Ulcer into all Abominations, and when let loose and comply'd with vents it self in Murders Rapines Brutalities <var>&amp;c</var>.&quot; J. C., op. cit, pp2-3.<br />
                <a name="note8" href="#8">8.</a> &quot;Is this then that spotless Innocence, and Integrity suppos'd in the Person represented in the Query, such as many challenge the Frution of God in Glory? Surely no. But waving this groundless and delusive Supposition, I still assert; The Frution of God in Glory, in regard to Man even of himself innocent and free from Sin, is not by Force or Efficacy of such Innocence, due to Man, nor attainable by him.&quot; J. C., op. cit, p3.<br />
                <a name="note9" href="#9">9.</a> &quot;Condign merit&quot; is an absolute right to reward deriving from grace, while congruous merit is a lesser right awarded for good works carried out freely.<br />
                <a name="note10" href="#10">10.</a> &quot;my third Position is, Salvation as it imports Remission of Sin, and fruition of God, is not absolutely unattainable by Man.&quot; J. C., op. cit, p4.<br />
                <a name="note11" href="#11">11.</a> &quot;To prove this, I Argue; There is nothing whereby to render the benefit of Man's Salvation unattainable, but either some limit or deficiency of power and goodness on God's part in conferring this benefit, or want of capacity on Man's part in receiving the benefit.&quot; J. C., op. cit, p4.<br />
                <a name="note12" href="#12">12.</a> &quot;My fourth Position is; It is most expedient, that remission of Sin, and fruition of God should be attainable by the interposition of one Mediator between God, and Man Jesus Christ, true God and Man.&quot; J. C., op. cit, p5.<br />
                <a name="note13" href="#13">13.</a> &quot;To explain and render rational this Position, Note, It is Man did, and doth commit Sin, and it is Man stands liable to satisfaction for the injury done to God by Sin; (as hath been shewn in the first Position) And because the injury done to God an infinite good, by Sin, is infinite, the satisfaction ought also to be so; but Man, a Creature, can make no such satisfaction, (as hath been likewise shewn in the same Position.)&quot; J. C., op. cit, p5.<br />
                <a name="note14" href="#14">14.</a> Leibniz wrote &quot;sixth&quot; but this appears to be an error.<br />
                <a name="note15" href="#15">15.</a> &quot;Now my fifth Position is, There is <var>de facto</var> a Mediator between God and Man, Christ Jesus, who being God, became Man, Dy'd upon Cross, &c.&quot; J. C., op. cit, pp5-6.<br />
                <a name="note16" href="#16">16.</a> &quot;I shall prove this important, Position, by such Rational Evidences and Motives of Credibility, as may be convincing to an impartial Understanding. The first Evidence or Motive, is taken from the Plenitude of the Mercy and Justice of God, (so often inculcated and now repeated)... The Second Evidence or Motive of Credibility, is taken from the Stupendious Miracles done by Christ whilst on Earth, to prove himself to be God, and his Doctrine or Gospel Suprem and Divine... The Third rational evidence of motive or Credubility, is taken from the wonderful Propagation of the Gospel of Christ... The fourth and last rational evidence or motive of Credibility, is taken from the Purity, Sanctity, and Rectitude of manners contain'd in the Principles, and relucent in the Practise of the true Christian Religion, and remonstrating the same to be no less than Divine.&quot; J. C., op. cit, pp6-7.<br />
                <a name="note17" href="#17">17.</a> &quot;The Justice of God called for Satisfaction, condign Satisfaction for Mans injustice done by Sin, But (alas) worthless Man could (as is said before) make no such Satisfaction, therefore God becoming Man, and Dying for Man, made Satisfaction in the utmost rigor of Justice for the Sin of Man.&quot; J. C., op. cit, p6.<br />
                <a name="note18" href="#18">18.</a> Leibniz wrote &quot;seventh&quot; but this is a mistake.<br />
                <a name="note19" href="#19">19.</a> &quot;My last conclusive Position is, There is necessary to Salvation, as it imports Remission of Sin and Fruition of God, a Faith in Christ, as the indispensable Condition, or means introductive and Applicative of his Merits and Mercies to Man.&quot; J. C., op. cit, p8.<br />
                <a name="note20" href="#20">20.</a> &quot;To prove my position, I argue thus; Upon the Reception of a Benefit <var>Gratis</var>, and by Benevolence bestowed on a Rational Creature; the very Law of Nature dictates there is required and due, an Owning or Acknowledgment to be made of the Benefit; Yes, and a Love to be render'd by the Receiver to the Benefactor.&quot; J. C., op. cit, p8.<br />
                <a name="note21" href="#21">21.</a> &quot;But it is impossible, such an Acknowledgment shou'd be made, or Love render'd, without a previous Knowledg of the Benefit received... But this Acknowledgement can never be made, nor love render'd without a Knowledg of the Truths and Misteries including the Benefits.&quot; J. C., op. cit, p8.<br />
                <a name="note22" href="#22">22.</a> &quot;Fifthly, it is objected, to what purpose, and with what appearance of Justice, has God in so many Ages, Created and fixed in the World so many Persons, who yet he knew neither would nor could ever attain to their natural End, and final Happiness?&quot; J. C., op. cit, p11.<br />
                <a name="note23" href="#23">23.</a> &quot;Were it not better such Persons [i.e. those who will miss out on beatitude] never had been?&quot; J. C., op. cit, p11.<br />
                <a name="note24" href="#24">24.</a> &quot;It is answered, (over and above what hath been already sufficiently said, and proved concerning this matter) A Beeing even in a condemned Sinner, is preferable to no Beeing, as far as his Beeing is the work of his Creator, for it is simply better to be something than nothing; but the dismal Miseries and Torments attending the Sinner and annex'd to his Crime are no positive Beeing, but a privation of well-Beeing.&quot; J. C., op. cit, p11.<br />
                <a name="note25" href="#25">25.</a> See Mark 14.21: &quot;For the Son of Man is to go just as it is written of Him; but woe to that man by whom the Son of Man is betrayed! It would have been good for that man if he had not been born&quot; and Matthew 26.24: &quot;The Son of Man is to go, just as it is written of Him; but woe to that man by whom the Son of Man is betrayed! It would have been good for that man if he had not been born.&quot;<br />
                <a name="note26" href="#26">26.</a> See the quoted passage in note 24.<br />
                <a name="note27" href="#27">27.</a> &quot;Thus, he is Good to all, but unjust to none; he extends or limits the effects of his mercy and goodness, according to the measure of his own Will, but still without injustice to any.&quot; J. C., op. cit, p11.<br />
                <a name="note28" href="#28">28.</a> &quot;the same Soveraign Lord hath amongst the number of Mankind, in all Ages, destin'd and elected, some to the everlasting fruition of himself, an happiness they could not justly demand; And excluded others from the same fruition an happiness they could not justly require.&quot; J. C., op. cit, p12.<br />
                <a name="note29" href="#29">29.</a> &quot;And this God, the Just, the Good, Will, because he Will; and to conclude, It is true, God cannot Will, but what is Just and Good; but it is likewise true, what he Will is therefore Just and Good, because he Will; for the Will of God is the Supream and Unaccountable Rule both of Justice and Goodness.&quot; J. C., op. cit, p12.
                <br /><br /><br />
                &#169; Lloyd Strickland 2008, 2018<br />
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
