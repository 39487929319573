import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/jaquelot.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
        Shelfmark LH 1, 1, 4 Bl. 74.
        <br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        Date: September 1704 (?)<br /><br />
        Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (151k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Notes on a Draft of the Second Part of Isaac Jacquelot's "Conformity of Faith with Reason", Against Bayle</title>
            <meta name="description" content="An English translation of NOTES ON A DRAFT OF THE SECOND PART OF ISAAC JACQUELOT'S 'CONFORMITY OF FAITH WITH REASON', AGAINST BAYLE by Gottfried Wilhelm Leibniz, from September 1704 (?)" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: NOTES ON A DRAFT OF THE SECOND PART OF ISAAC JAQUELOT'S <var>CONFORMITY OF FAITH WITH REASON</var>, AGAINST BAYLE</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>LH 1, 1, 4 Bl. 74.</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Part 2, chapter 3, p63a.<a name="1" href="#note1"><sup>1</sup></a> I would be of the opinion that if we were in a perfect indifference both within and without, we could not choose. And it would not be sufficient to see the necessity of the choice, for there also has to be the means to choose, that is, to prefer one side to another by some true or apparent inclination or reason. One could not even choose by chance, as Mr Bayle proposes,  if everything was midway between two options, and chance could not distinguish two equal cases anyway. There would have to be a reason to attribute heads to one and tails to the other rather than the contrary. And the perceptible or imperceptible reason that would allow us to distinguish the options in itself already proves that there is no perfect equivalence. Indeed, when we choose without thinking about it, such as to turn right rather than left at the end of a road, there is a reason that determines us to it, albeit an imperceptible one and without there being any deliberation. And these sorts of imperceptible reasons enter into our choices in the case of an apparent indifference.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Chapter 5, p71b.<a name="2" href="#note2"><sup>2</sup></a> I do not see these <var>terrible consequences</var> that follow if we grant<a name="3" href="#note3"><sup>3</sup></a> an imperishable soul to this infinite multitude of animals. Why would it be more permissible for Mr Gassendi to maintain imperishable material atoms everywhere than for me to maintain atoms of substance everywhere, that is, simple substances that are always preserved?<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Page 75a.<a name="4" href="#note4"><sup>4</sup></a> Self love only leads us too far because of our lack of knowledge, otherwise we would see that we are acting against our own good.<a name="5" href="#note5"><sup>5</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Chapter 9, p109a (p264).<a name="6" href="#note6"><sup>6</sup></a> We know today that the same quantity of motion is not conserved. Yet I have discovered that the same quantity of absolute force is conserved.<a name="7" href="#note7"><sup>7</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Chapter 10, p114a.<a name="8" href="#note8"><sup>8</sup></a> It would be truly a contradiction to say that there are as many centuries as hours.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Chapter 12, p140b.<a name="9" href="#note9"><sup>9</sup></a> I am delighted it is maintained that faith in time is justifying.<br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTE:<br /><br />
                <a name="note1" href="#1">1.</a> &quot;Spinoza plainly admits that a man in the same situation as Buridan's ass, that is, in a supposed equilibrium, would die of hunger because he could not make up his mind. But if he is asked what judgement should be made of such a man, he admits he does not know what to think, any more than about madmen and children. It is certain that Spinoza speaks in a manner consistent with his principles. According to him, man has no power to determine himself, nor even to suspend his judgement. One must stop here and caution the reader to judge the nature of the principles by this consequence.&quot; Isaac Jaquelot, <var>Conformit&#233; de la foy avec la raison; ou d&#233;fense de la religion, contre les principales difficultez r&#233;pandues dans le Dictionnaire historique &amp; critique de Mr. Bayle</var> (Amsterdam: Desbordes and Pain, 1705), 152. Jaquelot here refers to Spinoza, <var>Ethics</var>, Part II, proposition 49, scholium; see Spinoza, <var>Ethics</var>, trans. Andrew Boyle, revised by G. H. R. Parkinson (London: Everyman, 1993), 78-80.<br />
                <a name="note2" href="#2">2.</a> &quot;Nevertheless, all things considered, I want to say that from the impossibility of a body thinking, and the terrible consequences that follow if we grant a spiritual soul to this infinite multitude of animals, it seems that the most reasonable course is to consider animals as machines formed by the hand of the Almighty.&quot; Jaquelot, <var>Conformit&#233; de la foy avec la raison</var>, 174.<br />
                <a name="note3" href="#3">3.</a> grant &#124; a spiritual soul &#124; <var>deleted</var>.<br />
                <a name="note4" href="#4">4.</a> &quot;the cause of this sin [sc. the first sin committed by angels], or rather the sin itself, was the desire that originated from self-love.&quot; Jaquelot, <var>Conformit&#233; de la foy avec la raison</var>, 184.<br />
                <a name="note5" href="#5">5.</a> good. &#124; Thus the source of moral evil is a privation. &#182;  Chapter 8, p93. I find this <var>a priori</var> argument against two principles to be very reasonable: that it is unnecessary to admit two principles since one is sufficient. &#182; Malice is not a reality, as is goodness; it consists in the tendency towards a good of which one does not know the evil. &#124; <var>deleted</var>. Leibniz's remark about &quot;two principles&quot; refers to the following passage from Jaquelot: &quot;it is much more reasonable to speak of a single eternal, independent and infinite principle than to suppose two. Indeed, there is a demonstration of a single principle, because there has to be with all necessity a being that is necessary, independent, the cause and principle of other beings. But as soon as you have posited this very perfect being as the source and origin of all others, reason is satisfied and no longer needs another principle.&quot; Jaquelot, <var>Conformit&#233; de la foy avec la raison</var>, 223.<br />
                <a name="note6" href="#6">6.</a> In a discussion about continued creation, Jacquelot makes reference to &quot;inanimate bodies which act necessarily, producing their action because God, having created in the universe a certain quantity of motion that he conserves...&quot;. Jaquelot, <var>Conformit&#233; de la foy avec la raison</var>, 263-4.<br />
                <a name="note7" href="#7">7.</a> In response to Leibniz's remark, in the printed version of <var>Conformit&#233; de la foy avec la raison</var> Jaquelot added a marginal note to his comment about the same quantity of motion being preserved: &quot;It is true that great philosophers claim that the same quantity of motion is not conserved. But the renowned Mr Leibniz has discovered that the same quantity of absolute force is conserved.&quot; Jaquelot, <var>Conformit&#233; de la foy avec la raison</var>, 264.<br />
                <a name="note8" href="#8">8.</a> &quot;[I]t is inconceivable that the parts of a grain of sand can be equal to the parts of the universe, though this is a necessary consequence of the divisibility of extension to infinity. It is also certain that there is an eternity to retrace in the past, whatever this eternity might be. Nevertheless, it necessarily follows from this that there are as many centuries as there are hours and moments, which seems to imply contradiction.&quot; Jaquelot, <var>Conformit&#233; de la foy avec la raison</var>, 275.<br />
                <a name="note9" href="#9">9.</a> &quot;I have only one word to say about the faith that is called <var>in time</var> since those who possess it lose it forever ... I believe, to speak my mind freely, that it must be called a justifying and salutary faith, and that it truly puts in a state of salvation those who possess it.&quot; Jaquelot, <var>Conformit&#233; de la foy avec la raison</var>, 337-8.
                <br /><br /><br />
                &#169; Lloyd Strickland 2003. Revised 2019
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
