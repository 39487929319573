import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/tschirnhaus.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series II, volume 1 (2nd edition)</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 860-861<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 17 October 1684<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (16k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Ehrenfried Walter von Tschirnhaus (17 October 1684)</title>
            <meta name="description" content="An English translation of a LETTER TO EHRENFRIED WALTER VON TSCHIRNHAUS by Gottfried Wilhelm Leibniz, from 17 October 1684" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO EHRENFRIED WALTER VON TSCHIRNHAUS</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A II 1, p860</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For quite some time now my correspondences from France and everywhere else have been interrupted. And now I hear of hardly any novelties as regards the world of letters aside from what the <var>Acts</var> of Leipzig tell me.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In the past I have heard it said that Mr Gallet is an able man, but I have difficulty believing that the ring of Saturn is merely a simple appearance, as he says.<a name="1" href="#note1"><sup>1</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In Holland people are now disputing loudly and passionately about whether beasts are machines, and even the common man amuses himself with it, and ridicules the Cartesians, who imagine that a beaten dog cries in much the same way as an accordion when touched. For my part, although I grant the Cartesians that all the external operations of beasts can be explained mechanically, I nevertheless believe that beasts have some knowledge, and that there is something in them [<b>A II 1, p861</b>] which is not actually extended, and which can be called a soul, or, if you like, a substantial form.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I have been told that Mr Carcavy<a name="2" href="#note2"><sup>2</sup></a> no longer has the custodianship of the King's library, that a certain Abb&#233; Vares was charged with it but he died soon afterwards, and that Mr Cordemoy, who then claimed the position, has died too.<a name="3" href="#note3"><sup>3</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I am surprised that Mr Arnauld and Mr Malebranche, who were such good friends when I was in Paris, now produce writings against each other. I have not yet read the works of this conflict,<a name="4" href="#note4"><sup>4</sup></a> but as far as I can judge through their other works, Father Malebranche has a lot of spirit but Mr Arnauld writes with more judgement. There are many nice thoughts in the <var>recherche de la verit&#233;</var>,<a name="5" href="#note5"><sup>5</sup></a> but the author is far from having penetrated very far into analysis and into the art of discovery generally, and I couldn't help laughing when I saw his view that algebra is the first and most sublime of the sciences, and that truth is merely a relation of equality and inequality, that arithmetic and algebra are the only sciences that give the mind all the perfection and depth of which it is capable, and lastly that arithmetic and algebra are together the true logic. And yet I do not see that he himself has a great knowledge of algebra. The praise he bestows upon algebra should be given to Symbolics in general, of which algebra is only a rather particular and rather limited example.
                        <br /><br /><br />
                        <hr className='rightcol-hr' />
                        <br /><br />
                        NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> Leibniz is referring to Jean Charles Gallet's essay 'Systeme nouveau des apparences des planetes' in <var>Journal des S&#231;avans</var>, 15 May 1684, pp180-183.<br />
                        <a name="note2" href="#2">2.</a> Pierre de Carcavy (1600-1684); from 1663 - 1683 he was the librarian of the library of the King and minister Colbert.<br />
                        <a name="note3" href="#3">3.</a> Abb&#233; Vares died mid-September 1684; Gerauld de Cordemoy died 8 October 1684. Leibniz was informed of these events by Christophe Brosseau in a letter of 5 October 1684 (see A I 4, no. 396).<br />
                        <a name="note4" href="#4">4.</a> The dispute between Arnauld and Malebranche was triggered by Arnauld's <var>Des vrayes et des fausses id&#233;es, contre ce qu'enseigne l'auteur de la Recherche de la verit&#233;</var> (Cologne, 1683), which prompted Malebranche to publish his <var>Reponse de l'auteur de la Recherche de la verit&#233; au livre de M. Arnauld Des vrayes et des fausses id&#233;es</var> (Rotterdam, 1684). Arnauld responded with the <var>D&#233;fense...contre la R&#233;ponse au livre Des vrayes et des fausses id&#233;es</var> (Cologne, 1684), and Malebranche later countered with <var>Trois lettres de l'auteur de la Recherche de la verit&#233;, touchant la d&#233;fense de Mr. Arnauld contre la r&#233;ponse au livres des vrayes et des fausses id&#233;es</var> (Rotterdam, 1685).<br />
                        <a name="note5" href="#5">5.</a> Nicolas Malebranche, <var>De la recherche de la verit&#233;</var> [The Search after Truth] (Paris, 1674-5).
                <br /><br /><br />
                        &#169; Lloyd Strickland 2007
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
