import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import image from '../images/legacy-normal.jpg';
import pdf from '../pdfs/legacy-flyer.pdf'; 
import { Helmet } from 'react-helmet';

function Legacy() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        <a href="https://www.routledge.com/Leibnizs-Legacy-and-Impact/Weckend-Strickland/p/book/9781138102620" target="_blank">Order from the publisher</a><br /><br />
        <a href="https://www.amazon.co.uk/Leibnizs-Routledge-Studies-Seventeenth-Century-Philosophy/dp/1138102628/" target="_blank">Order from Amazon (UK)</a><br /><br />
        <a href="https://www.amazon.com/Leibnizs-Routledge-Studies-Seventeenth-Century-Philosophy/dp/1138102628/" target="_blank">Order from Amazon (US)</a><br /><br />
        <Link to={pdf} target="_blank">Flyer, with 20% discount</Link><br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        Testimonial:<br /><br />
        &quot;A fine collection of original essays by leading Leibniz scholars on the impact of his thought in areas as diverse as legal theory, evolution, mathematics, environmental philosophy, computing, and the social sciences.&quot;<br />
        - Richard Arthur, <var>McMaster University, Canada</var><br /><br />
        <a href="https://www.pdcnet.org/leibniz/content/leibniz_2020_0030_0119_0140" target="_blank">Review by Markku Roinila, from <var>The Leibniz Review</var></a> (subscription required for access)<br /><br />
      </div>
    );
  }
 
  return (
    <div>
      <Helmet>
            <title>Leibniz's Legacy and Impact</title>
            <meta name="description" content="A book edited by Lloyd Strickland and Julia Weckend on 'Leibniz's Legacy and Impact'" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ'S LEGACY AND IMPACT</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                  <img src={image} alt="Leibniz's Legacy and Impact" style={{ float: 'left', marginRight: '20px', height: isMobile ? '220px' : '304px', width: 'auto' }} />
                  <p style={{ margin: '10px 0px 0px 0px', fontSize: '13px' }}>Published September 2019, by Routledge.</p>
                  <p style={{ fontSize: '13px' }}>Co-edited with Julia Weckend</p>
                  <p>From the back cover blurb:</p>
                  <p>This volume tells the story of the legacy and impact of the great German polymath Gottfried Wilhelm Leibniz (1646-1716). Leibniz made significant contributions to many areas, including philosophy, mathematics, political and social theory, theology, and various sciences. The essays in this volume explores the effects of Leibniz's profound insights on subsequent generations of thinkers by tracing the ways in which his ideas have been defended and developed in the three centuries since his death. Each of the 11 essays is concerned with Leibniz's legacy and impact in a particular area, and between them they show not just the depth of Leibniz's talents but also the extent to which he shaped the various domains to which he contributed, and in some cases continues to shape them today. With essays written by experts such as Nicholas Jolley, Pauline Phemister, and Philip Beeley, this volume is essential reading not just for students of Leibniz but also for those who wish to understand the game-changing impact made by one of history's true universal geniuses.<br clear = "all" /></p>
                  <p style={{fontWeight: 'bold'}}>CONTENTS</p>
                  Acknowledgements<br />
                  List of Figures<br />
                  Abbreviations<br /><br />
                  <b>Introduction</b>:<br />
                    Making Waves: Leibniz's Legacy and Impact<br />
                    - Lloyd Strickland, Julia Weckend<br /><br />

                    <b>Part 1: Early Receptions</b><br />
                    Leibniz and the Royal Society Revisited<br />
                    - Philip Beeley<br /><br />

                    Staying Optimistic! The Trials and Tribulations of Leibnizian Optimism<br />
                    - Lloyd Strickland<br /><br />

                    Leibniz and Hume - A Point of Contact<br />
                    - Julia Weckend<br /><br />

                    Kant's &quot;True Apology for Leibniz&quot;<br />
                    - Nicholas Jolley<br /><br />


                    <b>Part 2: Legacy in Science and Metaphysics</b><br />
                    Leibnizian Conservation in d'Alembert's <var>Trait&#233; de dynamique</var><br />
                    - Tzuchien Tho<br /><br />

                    Russian Leibnizianism<br />
                    - Fr&#233;d&#233;ric Tremblay<br /><br />

                    Monkeys and Monads: The Unexpected Marriage between Darwinian Evolutionary Theory and Leibnizian Metaphysics<br />
                    - Jeremy Dunham<br /><br />

                    &quot;The point of view is in the body&quot;: Leibnizian Perspectivism in Contemporary Anthropologies<br />
                    - Arnaud Pelletier<br /><br />

                    <b>Part 3: Impact in Law, Political Thought and Ecology</b><br />
                    The Impact of Leibniz's Geometric Method for the Law<br />
                    - Christopher Johns<br /><br />

                    Leibniz and Political Thought<br />
                    - Douglas Moggach<br /><br />

                    <var>Theoria cum praxi</var>: Leibniz's Legacy into the Future<br />
                    - Pauline Phemister<br /><br />
                  List of Contributors<br />
                  Index
                  <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Legacy;
