import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/foucher.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>S&#228;mtliche schriften und briefe series II, volume 2</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 712-713<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: end of June 1693<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (12k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Simon Foucher (March 1693)</title>
            <meta name="description" content="An English translation of A LETTER TO SIMON FOUCHER by Gottfried Wilhelm Leibniz, from end of June 1693" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO SIMON FOUCHER</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A II 2 p712</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;One must be delighted, Sir, that you have given a reasonable sense to the doubts of the <em>Academicians</em>.<a name="1" href="#note1"><sup>1</sup></a> It is the best apology that you could make for them. I will be delighted to one day see their opinions digested and clarified by your efforts. But you will be obliged from time to time to lend them some rays of your wisdom, as you have begun to do.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It is true that I wrote two small discourses twenty years ago, one on the <em>Theory of Abstract Motion</em>,<a name="2" href="#note2"><sup>2</sup></a> in which I considered motion outside the system as if it was a purely mathematical thing, the other on <em>The Hypothesis of Concrete and Systematic Motion</em>,<a name="3" href="#note3"><sup>3</sup></a> such as is actually found in nature. They may have something good in them, since you along with others judged them thus, Sir. Nevertheless there are several parts on which I believe myself to be better instructed at present; and I now express myself quite differently on the subject of indivisibles, amongst other things. It was the essay of a young man who had still not gone deeply into mathematics. The laws of abstract motion that I gave then should operate in fact if there was nothing in body but what is conceived there according to Descartes, and even according to Gassendi. But as I have found that nature behaves quite differently with regard to motion, it is one of my arguments against the received notion of the nature of body, as I indicated in the journal.<a name="4" href="#note4"><sup>4</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;As for <em>indivisibles</em>, when one understands by that the simple extremities of time or the line, one could not conceive of new extremities in them, nor of actual or potential parts. Thus points are neither large nor small, and it is not at all necessary to leap in order to pass them. Nevertheless the continuum, although it has such indivisibles everywhere, is not composed of them, [<b>A II 2, p713</b>] as it seems the objections of sceptics suppose; objections which, in my opinion, are scarcely insurmountable, as one will find if one puts them down in due form. Father Gregory of St. Vincent has very well demonstrated by calculation even of the divisibility to infinity, the place where Achilles must catch the tortoise which is ahead of him, according to the proportion of speeds. Thus geometry serves to dissipate these apparent difficulties.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I am so much in favour of <em>actual infinity</em> that instead of admitting that nature abhors it, as is commonly said, I hold that it assumes it everywhere, in order to better show the perfections of its author. Thus I believe that there is no part of matter that is not, I do not say divisible, but actually divided, and consequently, the least particle must be considered as a world full of an infinity of different creatures.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTE:<br /><br />
                <a name="note1" href="#1">1.</a> Quite likely an allusion to Foucher's <em>Dissertations sur la recherche de la verit&#233;, contenant l'histoire et les principes de la philosophie des Acad&#233;miciens. Avec plusieurs r&#233;flexions sur les sentimens de M. Descartes</em> (Paris 1693).<br />
                <a name="note2" href="#2">2.</a> To be found in A VI 2, 258-276.<br />
                <a name="note3" href="#3">3.</a> To be found in A VI 2, 219-257. The correct title is <em>Hypothesis physica nova</em> [<em>New Physical Hypothesis</em>].<br />
                <a name="note4" href="#4">4.</a> An allusion to &quot;Extrait d'une Lettre de M. de Leibniz &#224; M. Foucher Chanoine de Dijon, sur quelques axiomes de philosophie,&quot; Journal des S&#231;avans (2 June 1692), 247-249.<br /><br /><br />
                &#169; Lloyd Strickland 2004. Revised 2013<br />
                With gratitude to Elizabeth Vinestock for advice and suggestions
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
