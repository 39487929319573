import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/hospital2.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>S&#228;mtliche schriften und briefe series III, volume 6</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		p 624<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 15/25 January 1696<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (10k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to the Marquis de l'Hospital (15/25 January 1696)</title>
            <meta name="description" content="An English translation of a LETTER TO THE MARQUIS DE L'HOSPITAL by Gottfried Wilhelm Leibniz, from 15/25 January 1696" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO THE MARQUIS DE L'HOSPITAL</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A III 6, p624</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I am delighted that my principle of continuity, according to which nature does not act <var>per saltum</var>,<a name="1" href="#note1"><sup>1</sup></a> has not displeased you, Sir. I see that Reverend Father Malebranche has not had the opportunity to consider it sufficiently, because there are still rules in his last published discourse on motion which do not agree with it. <a name="2" href="#note2"><sup>2</sup></a> But I have not wanted to touch on that, in order not to displease him, since he is occupied with so many other fine meditations, which do not permit him to give enough time to these matters...
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> 'by a leap'.<br />
                <a name="note2" href="#2">2.</a> Leibniz is referring to Nicolas Malebranche's <var>Des loix de la communication des mouvemens</var> (Paris, 1692).<br />
        <br /><br />
                &#169; Lloyd Strickland 2005
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
