import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/refuting.pdf'; /*Change pdf*/
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {       /*Change left hand panel content*/
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
        Shelfmark LH 4, 3, 5e, Bl. 19<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        Date: c. 1695 (?)<br /><br />
        Translated from the Latin<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (93k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Refuting Opinions about the Universe by Consideration of the Final Cause</title> {/*Change title*/}
            <meta name="description" content="An English translation of REFUTING OPINIONS ABOUT THE UNIVERSE BY CONSIDERATION OF THE FINAL CAUSE by Gottfried Wilhelm Leibniz, from c. 1695" /> {/*Change meta description*/}
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: REFUTING OPINIONS ABOUT THE UNIVERSE BY CONSIDERATION OF THE FINAL CAUSE</h2> {/*Change title*/}
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br /> {/*Change page content*/}
                 <small>Background: Written on a small scrap of paper torn from a larger sheet. The manuscript bears no watermark, but is catalogued among Leibniz's writings from 1695.</small><br /><br /><br />

[<b>LH 4, 3, 5e, Bl. 19</b>]<br /><br />

&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Many opinions about the universe can best be refuted by consideration of the final cause, such as if someone supposes that the same things always remain in the world, with the distinction that what ceases here now is born elsewhere; or if someone at any rate establishes a certain finite period after which all prior things return in the same order as before. That these opinions are false is proved from the fact that God would thus have no end in his working—for which good indeed change happens—and all things are as before, as when a sphere simply revolves around its own centre; but I refute these opinions also from the nature of the efficient cause, since prior things are involved in posterior ones, therefore the former differ from the latter.
                <br /><br /><br />
                  &#169; Lloyd Strickland 2024
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
