import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/bases.pdf'; 
import { Helmet } from 'react-helmet';
import Mobile from '../images/bases.png';
import Mobile2 from '../images/bases2.png';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
		Shelfmark LH 35, 3 B 11 Bl. 11v<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 1703 (?)<br /><br />
        Translated from the Latin<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (82k)</Link><br /><br />
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Periods and Number Bases</title>
            <meta name="description" content="An English translation of PERIODS AND NUMBER BASES by Gottfried Wilhelm Leibniz, from 1703 (?)" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <td className="pdf-container">
                    <h2 className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: PERIODS AND NUMBER BASES</h2>
                    {isMobile ? renderLinks() : ''}
                    {isMobile ? <><img src={Mobile} width="100%"/> <img src={Mobile2} width="100%"/></> : <embed src={pdf} className="responsive-embed"/>}
		              </td>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;