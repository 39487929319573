import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Image from '../images/2sophies.jpg';
import { Helmet } from 'react-helmet';

function Sophies() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function scrollToElement(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        <a href="https://www.amazon.co.uk/Leibniz-Two-Sophies-PB/dp/077272086X/ref=sr_1_1?dib=eyJ2IjoiMSJ9.VOevua_Cm90RuYR5jcD14QooMqAsUxDOgQ3lx-6A4ANDBdEkH3yeuE9R3AlE1Mc5RiDF2wNWlzCQXaGTEslKKc2YbkFY7zpLPRVqndjIabUuL_0pQmzr8nw1VnvuYloHS6lpSkuqJurOGuY-EPhNdkFVF1QloYDUNDJF0ZBeFto.ymBuRrurKI0frOTsVUHb99JjaMC-Bv5R6LMR-0vqks4&dib_tag=se&keywords=leibniz+2+sophies&qid=1718112492&s=books&sr=1-1" target="_blank">Order from Amazon (UK)</a><br /><br />
        <a href="https://press.uchicago.edu/ucp/books/book/distributed/L/bo23515129.html" target="_blank">Buy direct from the publisher</a><br /><br />
      </div>
    );
  }

  function endorsements() {
    return (
      <>
      Testimonial:<br /><br />
      &quot;In his introduction, Lloyd Strickland proposes that Sophie, Electress of Hanover, and her daughter, Queen Sophie Charlotte of Prussia, found consolation in the idea of divine justice.
        Too long themselves unfairly dismissed as philosophical lightweights, proper justice may now be given to their views through this edition of their private correspondences with Leibniz. Appearing for the first time in English translation, the philosophical selections cover topics from the nature of substance to universal salvation and evidence the independence of the women's thought as they defend materialism and challenge Leibniz's conviction that God created the best possible world. The edition also boasts copious and highly informative editorial notes.&quot;<br />
        &nbsp;&nbsp;- Pauline Phemister, <var>Reader in Philosophy and Deputy Director, Institute for Advanced Studies in the Humanities, University of Edinburgh</var><br /><br />
      </>
    );
  }
  
  return (
    <div>
      <Helmet>
            <title>Leibniz and the Two Sophies</title>
            <meta name="description" content="A book authored by Lloyd Strickland on 'Leibniz and the Two Sophies'" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()} <hr className="centered-hr" />
                    {endorsements()}
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ AND THE TWO SOPHIES: THE PHILOSOPHICAL CORRESPONDENCE</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}
                    <p>View a testimonial <span style={{color: 'blue'}} onClick={() => scrollToElement('endorsements')}>here</span></p>
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                  <img src={Image} alt="Leibniz and the Two Sophies" style={{ float: 'left', marginRight: '20px', height: isMobile ? '220px' : '324px', width: 'auto' }} />
                  <p style={{ margin: '10px 0px 0px 0px', fontSize: '13px' }}>Published 2011.</p><br></br>
                  <p>LEIBNIZ AND THE TWO SOPHIES is a critical edition of all of the philosophically important material from the correspondence between the philosopher Gottfried Wilhelm Leibniz (1646-1716) and his two royal patronesses, Electress Sophie of Hanover (1630-1714), and her daughter, Queen Sophie Charlotte of Prussia (1668-1705). In this correspondence, Leibniz expounds in a very accessible way his views on topics such as the nature and operation of the mind, innate knowledge, the afterlife, ethics, and human nature. The correspondence also contains the only known philosophical writings by Sophie and Sophie Charlotte, and thus provides a valuable insight into their views and concerns, as well as throwing light on their characters. The texts are accompanied by a substantial introductory essay which sets the context for the correspondence and analyzes its main philosophical themes.<br clear = "all" /></p>
                  <p style={{fontWeight: 'bold'}}>CONTENTS</p>
                  <p style={{fontWeight: 'bold'}}>Editor's Introduction</p>
                  <ul style={{listStyle: 'none'}}>
                  <li>The Other Voice<br /></li>
                  <li>Personal Relations: Sophie, Sophie Charlotte, and Leibniz<br /></li>
                  <li>A Brief Overview of the Correspondences<br /></li>
                  <li>Leibniz's Presentation of His Philosophy in the Correspondences<br /></li>
                  <li>Sophie and Philosophy<br /></li>
                  <li>Note on Texts and Translations<br /></li>
                  </ul>
                  <p style={{fontWeight: 'bold'}}>Translations</p>
                  <ol>
                  <li>Sophie to Leibniz (5/15 October 1691)</li>
                  <li>Sophie to Anna Katharina von Harling for Leibniz (8/18 October 1691)</li>
                  <li>Sophie to Anna Katharina von Harling for Leibniz (10/20 October 1691)</li>
                  <li>Leibniz to Sophie (13/23 October 1691)</li>
                  <li>Sophie to Leibniz (15/25 October 1691)</li>
                  <li>Leibniz to Sophie (16/26 October 1691)</li>
                  <li>Sophie to Leibniz (20/30 October 1691)</li>
                  <li>Leibniz to Sophie (23 October/2 November 1691)</li>
                  <li>Leibniz to Sophie Charlotte (10/20 February 1692)</li>
                  <li>Leibniz: Summary of a conversation with Sophie (29 December 1692/8 January 1693)</li>
                  <li>Leibniz to Sophie (3/13 September 1694)</li>
                  <li>Sophie to Leibniz (4/14 September 1694)</li>
                  <li>Leibniz to Sophie (second half of September (?) 1694)</li>
                  <li>Elisabeth Charlotte to Sophie (2 August 1696)</li>
                  <li>Leibniz to Sophie (4/14 August 1696)</li>
                  <li>Leibniz to Sophie and Elisabeth Charlotte (6/16 August 1696)</li>
                  <li>Francis Mercury van Helmont: A R&#233;sum&#233; of Philosophy (September 1696)</li>	
                  <li>Sophie to Leibniz (early October 1696)</li>
                  <li>Leibniz: Thoughts on van Helmont's Doctrines (first half of October (?), 1696)</li>
                  <li>Sophie to Leibniz (early November 1696)</li>
                  <li>Leibniz to Sophie and Duchess Elisabeth Charlotte of Orl&#233;ans (28 October/7
                  November 1696)</li>
                  <li>Leibniz to Sophie and Elisabeth Charlotte (4/14 November 1696)</li>
                  <li>Sophie to Leibniz (8/18 or 9/19 May 1697)</li>
                  <li>Leibniz to Sophie (9/19 May 1697)</li>
                  <li>Leibniz to Sophie Charlotte (9/19 May 1697)</li>
                  <li>Marie de Brinon to Sophie (2 July 1697)</li>
                  <li>Leibniz to Sophie (July 1697)</li>
                  <li>Sophie to Marie de Brinon (13/23 August 1697)</li>
                  <li>Leibniz to Sophie (10/20 September 1697)</li>
                  <li>Leibniz to Sophie (fall (?) 1697)</li>
                  <li>Leibniz to Sophie (5/15 August 1699)</li>
                  <li>Sophie Charlotte to Leibniz (22 August/1 September 1699)</li>
                  <li>Leibniz to Sophie Charlotte (28 November/8 December 1699)</li>
                  <li>Sophie Charlotte to Leibniz (9/19 December 1699)</li>
                  <li>Sophie to Leibniz (2 June 1700)</li>
                  <li>G. W. Molanus: The soul and its nature (1 or 2 June 1700)</li>
                  <li>Leibniz: The soul and its operations (12 June 1700)</li>
                  <li>Sophie to Leibniz (16 June 1700)</li>
                  <li>Leibniz to Sophie (middle-end June 1700)</li>
                  <li>Sophie to Leibniz (26 June 1700)</li>
                  <li>Leibniz to Sophie (19 November 1701)</li>
                  <li>Sophie to Leibniz (21 November 1701)</li>
                  <li>Leibniz to Sophie (30 November 1701)</li>
                  <li>Sophie Charlotte to Leibniz (end of March 1702)</li>
                  <li>Leibniz to Sophie Charlotte (29 March 1702)</li>
                  <li>Leibniz to Sophie Charlotte (22 April 1702)</li>
                  <li>Leibniz: On what is Beyond the External Senses and Matter (March-June (?) 1702)</li>
                  <li>Leibniz: Letter on what is Beyond the Senses and Matter (March-June (?) 1702)</li>
                  <li>Leibniz: Letter on what is Independent of Sense and Matter (mid-June (?) 1702)</li>
                  <li>John Toland to Sophie Charlotte (late July-early November 1702)</li>
                  <li>Leibniz to Sophie Charlotte (August-early November (?) 1702)</li>
                  <li>Leibniz: Reflections on the Doctrine of a Single Universal Spirit (August-early
                  November (?) 1702)</li>
                  <li>Leibniz to Sophie (9 September 1702)</li>
                  <li>Sophie to Leibniz (13 September 1702)</li>
                  <li>Leibniz to Sophie (mid-September 1702)</li>
                  <li>Leibniz to Sophie Charlotte (first half of November (?) 1702)</li>
                  <li>Leibniz to Sophie (18 November 1702)</li>
                  <li>Sophie to Leibniz (27 November 1702)</li>
                  <li>Sophie to Leibniz (13 December 1702)</li>
                  <li>Leibniz to Sophie Charlotte (5 August 1703)</li>
                  <li>Sophie Charlotte to Leibniz (14 August 1703)</li>
                  <li>Leibniz to Sophie Charlotte (17 November 1703)</li>
                  <li>Sophie Charlotte to Leibniz (4 December 1703)</li>
                  <li>Leibniz to Sophie Charlotte (7 December 1703)</li>
                  <li>Leibniz to Sophie Charlotte (8 May 1704)</li>
                  <li>Sophie Charlotte to Louise von Hohenzollern (summer 1704 (?))</li>
                  <li>Leibniz: The Principle of Uniformity (summer 1704 (?))</li>
                  <li>Leibniz to Sophie (31 October 1705)</li>
                  <li>Leibniz to Sophie (6 February 1706)</li>
                  <li>The Duke of Orl&#233;ans to Leibniz (21 February 1706)</li>
                  <li>Leibniz to Sophie (early? March 1706)</li>
                  <li>Louise Hollandine to Sophie (14 November 1707)</li>
                  <li>Leibniz to Sophie (29 November 1707)</li>
                  <li>Leibniz to Sophie (25 September 1708)</li>
                  <li>Leibniz to Sophie (April 1709)</li>
                  <li>Bernard le Bovier de Fontenelle to the Marquis de La Fare (c. 1700-1711?)</li>
                  <li>Leibniz to Sophie (26 June 1711)</li>
                  <li>Sophie to Leibniz (27 April 1713)</li>
                  <li>Leibniz to Sophie (6 May 1713)</li>
                  <li>Sophie to Leibniz (16 May 1713)</li>
                  <li>Leibniz to Sophie (1714?)</li>
                  </ol>
                  <b>Appendix 1: Fragmenta</b><br /><br />
                  <ol>
                  <li>Sophie to Leibniz (4/14 May 1691)</li>
                  <li>Leibniz to Sophie (early August (?) 1697)</li>
                  <li>Leibniz to Sophie Charlotte (29 December 1697/8 January 1698)</li>
                  <li>Sophie Charlotte to Leibniz (May 1698)</li>
                  <li>Leibniz to Sophie Charlotte (27 April/7 May 1699)</li>
                  <li>Leibniz to Sophie (12/22 July 1699)</li>
                  <li>Leibniz to Sophie Charlotte (August (?) 1702)</li>
                  <li>Sophie to Leibniz (10 January 1705)</li>
                  <li>Leibniz to Sophie (4 January 1707)</li>
                  </ol>
                  <b>Appendix 2: Supplementary Texts</b><br /><br />
                  <ol>
                  <li>Robert Scott to Sophie (9/19 November 1691)</li>
                  <li>Leibniz (and Francis Mercury van Helmont?): &quot;Preface to the Second Edition of
                  Bo&#235;thius's <var>Consolation of Philosophy</var>&quot; (9 June 1696)</li>
                  <li>[Unknown author] to Sophie: Thoughts on the Fleming-Leibniz-Toland Debate
                  (August-November (?) 1702)</li></ol><br />
                  <b>Bibliography</b><br></br><br></br>
                  {isMobile ? (
                    <div id="endorsements">
                      <hr></hr><p style={{paddingTop: '10px'}}>Go back to the top of the page <span style={{color: 'blue'}} onClick={() => scrollToElement('top')}>here</span></p>
                      {endorsements()}
                    </div>
                  ) : (
                    <>
                    </>
                  )}
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Sophies;