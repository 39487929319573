import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/fontaney1704.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>S&#228;mtliche schriften und briefe series I, volume 23</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 580-582<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 28 July 1704<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (116k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Jean de Fontaney (28 July 1704)</title>
            <meta name="description" content="An English translation of a LETTER TO JEAN DE FONTANEY by Gottfried Wilhelm Leibniz, from 28 July 1704" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO JEAN DE FONTANEY</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A I 23, 580</b>]<br /><br />

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;My Reverend Father<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Your successful journey bodes well for the Mission you oversee, which seems to me the most important of all. I wholeheartedly wish you every success both in France and on your return [to China].<a name="1" href="#note1"><sup>1</sup></a><br /><br />
                [<b>A I 23, 581</b>]
                <br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Does it not seem that the Emperor of China could be inclined to the foundation of some colleges or academies which serve to cultivate sciences and teachings in the European way, and whose members could be Tartars, Chinese and Europeans? The example of the king could contribute to this.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mr Sloane is a friend of mine;<a name="2" href="#note2"><sup>2</sup></a> I would be very happy if he corresponded with Reverend Father Visdelou.<a name="3" href="#note3"><sup>3</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It is certain that the author of the linear characters attributed to Fuxi must have thought deeply about them when inventing them, and that they correspond perfectly to my binary arithmetic.<a name="4" href="#note4"><sup>4</sup></a> Would to God that one could guess so well the reasons behind their ordinary characters, but these have apparently been more corrupted.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I read that there are different distinct languages in various provinces of China itself, and that Korea has a distinct language too. It would be good to have the Lord’s Prayer in it and other samples.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You will oblige us, my Reverend Father, by publishing as soon as possible the maps of Reverend Father Gerbillon's voyages in the [parts of] Tartary neighbouring China. To speed things up, I would recommend Mr Delisle,<a name="5" href="#note5"><sup>5</sup></a> a geographer in Paris, who seems intelligent and diligent. The Englishman Mr Worsley, who made an trip to Arabia especially to follow in the footsteps of Moses, told me when passing through here that he had sent Mr Delisle his map, which he had made himself of the top of the Red Sea and which is very different from what we have.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I am glad the report in the Holland Gazette of a persecution in China was untruthful.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You, my Reverend Father, and Reverend Father Bouvet had brought other interesting information [from China] and will have brought more recently. I asked your friends to let me know about it as you and this father wish, but received no word, and although Reverend Father Verjus also seemed very disposed to assist me with this, I do not know what has hindered him. Your presence [in Paris] may provide me with something more.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I hope that Rome will proceed carefully when it comes to condemning the doctrines of the ancient Chinese and certain practices of the modern ones.<a name="6" href="#note6"><sup>6</sup></a> For one must understand people before condemning them. It is a long time since I heard from Reverend Father Le Comte, and I do not know where he is.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Father Bouvet once told me that the Chinese Emperor is having a kind of very extensive dictionary made which explains the Chinese Characters in Tartar.<a name="7" href="#note7"><sup>7</sup></a> [<b>A I 23, 582</b>] The missionaries should at the same time work towards having it translated into some European language and the whole thing printed in Paris. For Tartar is easier to explain. It would be like a key for the missions, especially if the pronunciations of Chinese and Tartar words in Latin letters were added.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Reverend Father Bouvet would like Kepler's discourse written on the occasion of a letter Father Terrentius had written to Europe;<a name="8" href="#note8"><sup>8</sup></a> I mentioned it to him. It will no doubt be found in Paris, and as it is short it would be easy to have it copied.
                        <br /><br /><br />
                        <hr className='rightcol-hr' />
                        <br /><br />
                        NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> Fontaney did not go back to China after returning to France in 1702.<br />
                        <a name="note2" href="#2">2.</a> Hans Sloane (1660-1753), physician and collector of plant and animal specimens and other curiosities.<br />
                        <a name="note3" href="#3">3.</a> Claude de Visdelou (1656–1737), one of five Jesuits sent to China by Louis XIV in 1685.<br />
                        <a name="note4" href="#4">4.</a> By &quot;linear characters attributed to Fuxi&quot; Leibniz means the hexagrams of the Chinese Yijing. Joachim Bouvet had suggested - erroneously as it happens - that Leibniz's discovery of binary arithmetic was the key to understanding the mysterious hexagrams of the Yijing. See Bouvet's letter to Leibniz of 4 November 1701, A I 20, 533-555. For an English translation, see Lloyd Strickland and Harry Lewis, <var>Leibniz on Binary: The Invention of Computer Arithmetic</var> (Cambridge, Mass.: MIT Press, 2022), 163-175.<br />
                        <a name="note5" href="#5">5.</a> Guillaume Delisle (1675-1726), a French cartographer admitted to the Acad&#233;mie Royale des Sciences in 1702.<br />
                        <a name="note6" href="#6">6.</a> Leibniz here alludes refers to the rites controversy, which concerned the religiosity (or otherwise) of Confucianism and Chinese rituals. While many Jesuits had held that the rites carried out by the Chinese to their ancestors, and in some cases to Confucius, were essentially civil and secular rather than religious, others argued otherwise, such as the theological faculty of the Sorbonne in Paris. Pope Clement XI eventually banned the rites in 1704. Leibniz sided with the Jesuits on the rites controversy; see his &quot;On the civil cult of Confucius&quot; (1700) in G. W. Leibniz, <var>Writings on China</var>, ed. and trans. Daniel J. Cook and Henry Rosemont Jr. (Chicago: Open Court, 1994), 61-66.<br />
                        <a name="note7" href="#7">7.</a> This is the Qingwen jian [Mirror of the Manchu Language].<br />
                        <a name="note8" href="#8">8.</a> Namely Joannes Terrentius, <var>Epistolium ex regno Sinarum ad mathematicos Europaeos missum: Cum commentatiuncula Joannis Keppleri Mathematici</var> (&#379;aga&#324;: Cobius, 1630).

                <br /><br /><br />
                        &#169; Lloyd Strickland 2020
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
