import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/bourguetmarch1716.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>Die philophischen schriften von Gottfried Wilheim Leibniz, vol. III</var><br />
		C. I. Gerhardt (ed)<br />
		pp 588-591<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: mid-to-late March 1716<br /><br />
		Translated from the French<br /><br />
        Note - the portion of this text that is included in my <var>Shorter Leibniz Texts</var> is not included here.<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (17k)</Link><br /><br />
        <Link to="/slt">Back to <var>Shorter Leibniz Texts</var></Link><br /><br />
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Louis Bourguet (mid-to-late March 1716)</title>
            <meta name="description" content="An English translation of a LETTER TO LOUIS BOURGUET by Gottfried Wilhelm Leibniz, from mid-to-late March 1716" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO LOUIS BOURGUET</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>G III p588</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I have received the honour of two of your letters, and to you I return very humble thanks, and reciprocal wishes for those contained in the last letter, dated from Morges, and I am delighted that your journey was felicitous. But I am not delighted that Count Riccato has put prickly things in his response - this is the way to continue and embitter disputes. Mr Zendrini has given some books for me to Mr l'Agent Farinelli, and there will apparently be something of what you have noted, and for which I am obliged to you.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To come to the matter of your first letter, the sequence of things is always contingent, and one state does not necessarily follow from another, preceding state, whether there is a beginning or not. The connection of two states is a natural consecution, but not a necessary one, just as it is natural for the tree to bear fruits even though for certain reasons it can happen that it does not bear any. Unity is a part of number, for there is proportion between the number and the unity contained in the number, but the instant is not a part of time, consequently they do not have any proportion between them. It is most true that the notion of the eternity of God is entirely different from that of time, for it consists in necessity, and that of time in contingency. But it does not follow, [<b>G III p589</b>] if one does not find other means, that contingency has a beginning.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I come to your second letter, and I will say to you, Sir, that when I talk about the reason for the rarity of twins in certain species, I do not ask for any final cause, but the efficient cause. For the knowledge of this reason would serve to make generation better known. While writing this second letter you have meditated very well on the principal matter of the first. The eight propositions you mention at the start can pass,<a name="1" href="#note1"><sup>1</sup></a> except perhaps the last one, where it is said (1) that all limited beings can only respond at one time to a limited number of relations, if limited is for you equivalent to finite. For I think that these limited beings are always infinite in number, and one must not (2) put among the postulates what is in question, namely that their aggregation cannot receive at the start all the perfection which may be conferred upon them. This collection can have all its perfection, although the individual things that compose it can increase and diminish in perfection. You say, Sir, that one could never produce a complete relation, to which it is impossible to add others. But a relation of one state of the universe never receives any additions without it having at the same time a subtraction or diminution in order to pass into another state.<br /><br />

        ......<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We are in a kind of solitude here, ever since our Court went to England; thus I do not know of any opportunity to obtain some employment for Mr Malpac. Berlin would suit him better. If you go to Geneva, Sir, have the goodness to give my recommendations to Mr Turretin, to whom I hope that my last letter will have been [<b>G III p590</b>] delivered last year. There is in Lausanne a learned man who has produced a wonderful book on the beautiful, and makes it known that he has good connections. There is another there who commented on Pufendorf on the Right of Nature, and has criticized me for the manner with which I speak in passing in the <var>Theodicy</var> about his author, who maintains that moral truths depend on the will of God, a doctrine that has always seemed to me extremely unreasonable, and I remarked that Mr Pufendorf should not be taken seriously on this matter. Thereupon the Professor of Lausanne became angry with me, and says that the sentiment of his author will always appear more reasonable than my pre-established harmony. But I think I have good authority to say also that his judgement should not be taken seriously on this matter.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Moreover, I ask a small favour of you, Sir; it requires a small expenditure, but I will return it punctually. There are in this neighbourhood Lords and Ladies who take great pleasure in rearing silkworms, having gardens where there are many white mulberry trees. They desire some ounces of good eggs that are of good stock and well preserved, for they have sometimes been disappointed by what was sent to them. I would be very much obliged to you, Sir, if through a reliable friend you could obtain four ounces of such eggs, and send them directly to me by post well-enveloped and well-protected. If the eggs have been on paper, they spoil easily when trying to take them off, whereas they come off more easily if they have been on wool; it is good also if the eggs are taken from butterflies whose cocoons were big and beautiful. I imagine that good eggs of this nature will come from the Milan area, but you know better, Sir, concerning the most suitable place. These eggs ought to be sent to me before the weather becomes hot, for fear that the eggs may hatch out on the way.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mr Herman's book has appeared. It flatters certain Englishmen a little too much, but these gentlemen are no more accommodating for that and would want to be thought the only ones capable of doing anything good on these matters. Mr Newton believes that the force of the universe is continually diminishing, like that of a watch, and needs to be restored by a special action of God, whereas I maintain that God made things at the outset in such a way that the force could not disappear. Thus his Dynamics [<b>G III p591</b>] are very different from mine, and in my opinion do not agree with the perfection of the divine operations. An author called Mr Clark, Chaplain to the King, has entered into dispute with me about that in letters, prompted by what I wrote about it to Madam the Princess of Wales;<a name="2" href="#note2"><sup>2</sup></a> and Her Royal Highness, who has read my <var>Theodicy</var> with attention, has revealed that my sentiments seemed more acceptable to her. You will have the goodness, Sir, to show me how I ought to address my letters to you. Those that you may want to do the honour of sending to me could be sent to Mr Schroek, the agent of His Majesty the King of Great Britain, Elector of Brunswick, at Augsburg; but the eggs should be sent to me quite simply by post. I am sincerely etc.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> Leibniz is referring here to the 8 propositions to be found in Bourguet's letter of 7 February 1716, an English translation of which is available <Link to="/bourguet1716">here</Link><br />
                <a name="note2" href="#2">2.</a> Princess Caroline.<br /><br /><br />
                &#169; Lloyd Strickland 2003<br />
                With gratitude to Elizabeth Vinestock for advice and suggestions
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
