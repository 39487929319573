import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/axiom.pdf'; 
import { Helmet } from 'react-helmet';

function Axiom() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series VI volume 4</var><br />
        Deutsche Akademie der Wissenschaften (ed)<br />
        p 57<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: October 1677 - December 1678 (?)<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (72k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>The Axiom: Nothing is in the Intellect That Was Not in the Senses</title>
            <meta name="description" content="An English translation of THE AXIOM: NOTHING IS IN THE INTELLECT THAT WAS NOT IN THE SENSES by Gottfried Wilhelm Leibniz, from October 1677 - December 1678 (?)" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: THE AXIOM: NOTHING IS IN THE INTELLECT THAT WAS NOT IN THE SENSES</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A VI 4, p57</b>]<br /><br />
                <center><var>The axiom: nothing is in the intellect that was not in the senses</var></center><br />

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;So stated, this axiom is false. For at any rate, in the intellect are the mind itself and its properties, which never were in the senses. The axiom can be corrected in this way: nothing exists in concept that was not in perception; that is, we do not think of anything we have not experienced, at least within ourselves. For it seems that perceptions are prior to simple concepts, and concepts are formed by the memory of perceptions, with this one exception, that we may also remember that we have perceived even then.
                        <br /><br /><br />
		        &#169; Lloyd Strickland 2019
              <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Axiom;