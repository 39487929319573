import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/studious.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series IV volume 7</var><br />
        Deutsche Akademie der Wissenschaften (ed)<br />
        p 863<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: after June 1690<br /><br />
        Translated from the French<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (72k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>On Studious People</title>
            <meta name="description" content="An English translation of ON STUDIOUS PEOPLE by Gottfried Wilhelm Leibniz, written after June 1690" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: ON STUDIOUS PEOPLE</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A IV 5, p502</b>]<br /><br />One of the things that surprises me is that studious people who apply themselves intensely to analysis do not yield anything new, for example the late Father Prestet,<a name="1" href="#note1"><sup>1</sup></a> Reverend Father Malebranche's friend. I think this happens in part because they follow too closely the road that others have already taken. One has to deviate from the main road to find something, much like a traveller who goes to Greece to find inscriptions that others have not yet observed.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> That is, the priest and mathematician Jean Prestet (1648-1690).<br />
                <br /><br /><br />
                &#169; Lloyd Strickland 2021
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
