import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/river.pdf'; /*Change pdf*/
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {       /*Change left hand panel content*/
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
        Shelfmark LH 36 Bl. 184-185<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        Date: c. 1705 (?)<br /><br />
        Translated from the Latin<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (189k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>How to Cross a Large River in View of an Enemy Army </title> {/*Change title*/}
            <meta name="description" content="An English translation of HOW TO CROSS A LARGE RIVER IN VIEW OF AN ENEMY ARMY by Gottfried Wilhelm Leibniz, from c. 1705" /> {/*Change meta description*/}
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: HOW TO CROSS A LARGE RIVER IN VIEW OF AN ENEMY ARMY</h2> {/*Change title*/}
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br /> {/*Change page content*/}
                 [<b>LH 36 Bl. 184r</b>]<br /><br />
                
                 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sometimes it is necessary to cross a large river in view of an enemy army, and I have seen the plans and models that a highly experienced general presented for this purpose to a great prince, along with a discourse he had written on the matter, the substance of which was as follows.<a name="1" href="#note1"><sup>1</sup></a><br />
                 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The terrain offers particular advantages through the height of the riverbank, the bend of the river, and the islands that lie within it,<a name="2" href="#note2"><sup>2</sup></a> and the discourse I just mentioned explained the advantages that can be derived therefrom, but since one does not always have the choice of terrain, I will speak only of a method that is always applicable, assuming the river is deep enough to carry a boat.<br />
                 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Now, this method is to make flatboats that are cannon-proof, equipped with artillery and men, and designed in such a way that the very earth they carry can be used to quickly cover and entrench themselves to take a position as soon as they have landed.<br /><br />

[<b>LH 36 Bl. 185v</b>]<br /><br />

&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For this purpose, since earth and cannons are very heavy, these boats must have sufficient hollowness because the rule of a boat's or vessel's carrying capacity is that a vessel carries without sinking the weight of the water it could contain.<br />
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In the aforementioned general's plan, there was a particular invention for the construction of the parapet that made it suitable for quick use on land after landing; but since this invention requires time and preparations that must be made at leisure before the campaign, I will not talk about it. It will suffice that the parapet be made of earth-filled sacks or wicker baskets filled with earth, and that there be a number of small carts with one or two wheels (with musket-proof armour, if deemed necessary) that men can handle and transport these sacks of earth by means of them. And things can be arranged in such a way that there is no need for great difficulty in transporting.<br />
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;With these boats, one will cross a river despite the artillery the enemy may have in place, which can even be faced directly, although it is more convenient to avoid it; the enemy's batteries are not as mobile as these.<br />
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;When, by means of these flatboats, enough men have crossed to entrench themselves and take cover, the rest will pass without difficulty under the cover of this entrenchment and with the help of the artillery that has already crossed or is still in these floating batteries.<br /><br />

[<b>LH 36 Bl. 185r</b>]<br /><br />

&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If the enemy does not counter these cannon-proof boats with similar boats, they can only inconvenience ours by using bombs, against which it is not so easy to protect the men. But besides the fact that we can avoid them by landing further up- or downstream, where the enemy’s mortars cannot follow; besides that, I say, it is also known that mortars are not very accurate, and the enemy will not be well prepared, as they will not expect it. Therefore, it will be fitting to have these boats constructed in such a way that the enemy and their spies cannot learn in time what we intend to do. Thus, by manufacturing the boats in a somewhat remote location and within a closed enclosure, and preparing what is needed for the parapet and entrenchment elsewhere, the boats will be loaded only when we are ready to use them, making it difficult for the enemy to prevent their deployment.<br />
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In case we can or want to avoid the enemy's batteries on the opposite shore, the flatboat itself does not need to be cannon-proof; it is enough for it to transport men, artillery, and sacks or baskets filled with earth, and everything needed to quickly take cover. I believe this is the most expedient method when there is no time to spare. However, if we could use this same [<b>LH 36 Bl. 184v</b>] earth to make the boat itself cannon-proof, it could yield more significant advantages, even dislodging the enemy from their position and landing right under their noses, at the most advantageous spot.<a name="3" href="#note3"><sup>3</sup></a><br />
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;When one controls the crossing and does not have enough small boats to build a bridge over a river whose width is considerable, the rest of the troops, both cavalry and infantry, can cross on flosses, which are commonly used on the Danube, made of floating wood and capable of carrying a large number of men and horses at once.<a name="4" href="#note4"><sup>4</sup></a><br />
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> follows. │ ¶ As far as I remember, he advised choosing a spot, if convenient, where the river makes a significant bend, so that the convex side is towards the enemy. This forces the enemy to divide their army to defend multiple points, while ours remains united. ¶ It is also good if the terrain is higher on our side, when possible. │ <var>deleted</var>.<br />
                <a name="note2" href="#2">2.</a> it, │ but as one does not always have the choice, and the discourse mentioned │ <var>deleted</var>.<br />
                <a name="note3" href="#3">3.</a> spot. │ ¶ As the width of the river is not much of an issue, it would be better to cross the Danube when │ <var>deleted</var>.<br />
                <a name="note4" href="#4">4.</a> once. │ ¶ Crossing the Danube at Donauwörth or further upstream still requires crossing the Lech, and there is reason to believe that there will be resistance at both of these crossings. But if there were a way to cross the Danube between Donauwörth and Ingolstadt, it would be easy to penetrate into the heart of Bavaria. It is true that the Danube becomes significantly wider after receiving the Lech, but │ <var>deleted</var>.
                <br /><br /><br />
                  &#169; Lloyd Strickland 2024
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
