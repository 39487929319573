import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/more2.pdf'; 
import { Helmet } from 'react-helmet';

function HenryMoreSoul() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>S&#228;mtliche schriften und briefe series VI volume 4</var><br />
        Deutsche Akademie der Wissenschaften (ed)<br />
        pp 1678-1680<br /><br />
        Parts of this text can also be found in:<br />
        <var>Textes in&#233;dits tome 2</var><br />
        Gaston Grua (ed)<br />
        pp 509-511<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        Date: January 1677 - January 1678?<br /><br />
        Note - The italicised text within + ... + is Leibniz's own comments on the material.<br /><br />
        Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (27k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Notes On Henry More's "The Immortality of the Soul"</title>
            <meta name="description" content="An English translation of NOTES ON HENRY MORE'S 'THE IMMORTALITY OF THE SOUL' by Gottfried Wilhelm Leibniz, from January 1677 - January 1678?" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: NOTES ON HENRY MORE'S <var>THE IMMORTALITY OF THE SOUL</var></h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A VI 4, p1678</b>]<br /><br />		
                <center><var>Manuscript of the translation</var></center>
                <center><var>of Mr H. More's The Immortality Of The Soul,</var></center>
                <center><var>made by Mr Briot, dedicated to Monseigneur the Duke of Hanover</var></center><br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mr More says that his demonstration is as certain as is possible by reason. He nevertheless says in the preface that his demonstration is not completely perfect.<a name="1" href="#note1"><sup>1</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;He defines body as a substance discerpible into pieces and parts, and impenetrable, and spirit as a substance indiscerpible but penetrable. And he reasons well when he says that one notion is as easy to conceive as the other.<a name="2" href="#note2"><sup>2</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;He assures us that the least parts of matter have some magnitude, otherwise their repetition would not be extended, for a million nothings is nothing.<a name="3" href="#note3"><sup>3</sup></a> But these parts are nevertheless indivisible. And he says that such are the parts formed when a globe touches a plane, for the repetition of the touching by motion produces a line.<a name="4" href="#note4"><sup>4</sup></a> This indivisibility does not stop it from being intellectually divisible.<a name="5" href="#note5"><sup>5</sup></a> He maintains that there is a certain centre of spirit, which throws some rays from a sphere of activity to the edges, as it were.<a name="6" href="#note6"><sup>6</sup></a> He says that the parts of indivisibles are essential rather than integral, page 47.<a name="7" href="#note7"><sup>7</sup></a> + <var>To my mind that destroys his reasoning, for not being integral they are without extension or distance, and consequently would not make an extended thing. Moreover, this reasoning appears to me too certain: that extension is not composed of points, because it would be composed of extended nothings. For points are little more than nothings with location. I do not see what connection the understanding and the will have with the notion that our author gives to spirit.</var> +<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Matter is not active in itself, he says, because it can be reduced to rest.<a name="8" href="#note8"><sup>8</sup></a> Spirit can move itself and self-penetrate by a contraction.<a name="9" href="#note9"><sup>9</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;He explains the union of spirit with matter by saying that it is no more difficult than the union of parts of matter among themselves without any cement.<a name="10" href="#note10"><sup>10</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Angels are beings composed of spirit and body, as are men, beasts and plants.<a name="11" href="#note11"><sup>11</sup></a> He posits a vegetative soul, a vegetative and sensitive soul, and a vegetative, sensitive and rational soul.<a name="12" href="#note12"><sup>12</sup></a><br /><br />

        [<b>A VI iv p1679</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Even though Descartes' reasoning proves that the notion of thought is different from the notion of extension, and consequently that it is possible that there is a thinking thing without extension, he does not prove that extension and thought cannot be the properties of the same substance, page 66.<a name="13" href="#note13"><sup>13</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;He quotes his <var>treatise on atheism</var>, in book 3 of which he reports many histories of apparitions.<a name="14" href="#note14"><sup>14</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Helmont thought that the upper orifice of the stomach was the seat of the common sense.<a name="15" href="#note15"><sup>15</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The heart is not the seat of the [common] sense, for Mr More says that it has been remarked that the crocodile will fight and defend itself for quite a long time after its heart has been removed, and Calcidius reports the same thing of the sea tortoise and the wild goat: to which can be added what Galen said of beasts which are sacrificed. That is, that after their heart was removed, and laid on the altar, they were seen at the same time not only breathing and roaring loudly, but also rushing and running quite far away, until the loss of their blood made them fall.<a name="16" href="#note16"><sup>16</sup></a> This appears to me all the more credible since I have seen with my own eyes a frog completely disembowelled, its heart, stomach and guts taken out of its body by a very skilful anatomist friend of mine, and after all that it continued for quite a long time to see, to avoid objects in its path, and to jump with as much agility as before, whereas a small wound to the head deprives these animals of life and motion in an instant.<a name="17" href="#note17"><sup>17</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The anatomist Laurens says that when a nerve is tied, motion and sensation do not fail to continue from the ligature up to the head, but below that they are lost.<a name="18" href="#note18"><sup>18</sup></a><br /><br />

        [<b>A VI 4, p1680</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It seems obvious from the wounds and gashes that the brain receives without this function being lost, that the whole thing is not the seat of the common sense, for, as Galen observes, these cuts do not take away sensation or motion, unless they penetrate as far as its ventricles.<a name="19" href="#note19"><sup>19</sup></a> 
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> '<var>the Title of my Book doth not necessarily imply any promise of so full and perfect a Demonstration, that nothing can be added for the firmer assurance of the Truth</var>.' Henry More, <var>The Immortality of the Soul, So farre forth as it is demonstrable from the Knowledge of NATURE and the Light of REASON</var> (London, 1659), preface.<br />
                <a name="note2" href="#2">2.</a> More defines body as '<var>A substance impenetrable and discerpible</var> [i.e. actually divisible]' and spirit as '<var>A substance penetrable and indiscerpible</var>.' More, <var>The Immortality of the Soul</var>, I. 3, &#167;1.<br />
        <a name="note3" href="#3">3.</a> '<var>The least that is conceivable is so little, that it cannot be conceived to be discerpible into less... As little as this is, the repetition of it will amount to considerable magnitudes... Magnitudes cannot arise out of mere Non-Magnitudes.</var> For multiply <var>Nothing</var> ten thousand millions of times into nothing, the Product will be still <var>Nothing</var>.' More, <var>The Immortality of the Soul</var>, I. 6, axioms XII, XIII, XIV.<br />
        <a name="note4" href="#4">4.</a> 'if this Globe be drawn upon a Plane, it constitutes a Line; and a <var>Cylinder</var> drawn upon a Plane, or this same Line described by the Globe multiplied into itself, constitutes a <var>superficies</var> [surface] etc.' More, <var>The Immortality of the Soul</var>, I. 6, axiom XIII.<br />
        <a name="note5" href="#5">5.</a> 'For every Quantity is <var>intellectually</var> divisible; but something Indiscerpible was afore demonstrated to be Quantity, and consequently divisible, otherwise Magnitude would consist of Mathematicall points.' More, <var>The Immortality of the Soul</var>, I. 6, axiom XV.<br />
        <a name="note6" href="#6">6.</a> 'Thus have I found a possibility for the Notion of the <var>Center</var> of a Spirit, which is not a Mathematicall point, but Substance, in Magnitude so little, that it is <var>Indiscerpible</var>; but in virtue so great, that it can send forth out of it self so large a Sphere of <var>Secondary Substance</var>, as I may so call it, that it is able to actuate grand Proportions of Matter, this whole Sphere of life and activity being in the mean time utterly Indiscerpible.' More, <var>The Immortality of the Soul</var>, I. 6, axiom XV.<br />
        <a name="note7" href="#7">7.</a> 'That <var>division into parts</var> does not imply any <var>discerpibility</var>, because the parts conceived in one of these <var>Minima Corporalia</var> (as I may so call them) are rather <var>essential</var> or <var>formal</var> parts then [than] integral, and can no more actually be dissevered, then [than] Sense and Reason from the Soul of a man.' More, <var>The Immortality of the Soul</var>, I. 6, &#167;6.<br />
        <a name="note8" href="#8">8.</a> 'Matter is not active of it self, because it is reducible to Rest.' More, <var>The Immortality of the Soul</var>, I. 7, &#167;1.<br />
        <a name="note9" href="#9">9.</a> 'this <var>self-moving</var> Substance, which we call a Spirit, cannot penetrate it self, but it must needs therewith contract itself.' More, <var>The Immortality of the Soul</var>, I. 7, &#167;3.<br />
        <a name="note10" href="#10">10.</a> More, <var>The Immortality of the Soul</var>, I. 7, &#167;5. More argues that although we cannot conceive how spirit and matter unite, we are similarly unable to conceive of how parts of matter unite either.<br />
        <a name="note11" href="#11">11.</a> 'For I look upon <var>Angels</var> to be as truly a compound Being, consisting of Soule and Body, as that of Men &amp; Brutes.' More, <var>The Immortality of the Soul</var>, I, 8, &#167;1.<br />
        <a name="note12" href="#12">12.</a> The vegetative soul is discussed in I. 8, &#167;3, the sensitive soul in I. 8, &#167;4, and the rational soul in I. 8, &#167;5. This is followed (&#167;6) by an explanation of the difference between the souls of human and angels.<br />
        <a name="note13" href="#13">13.</a> More, <var>The Immortality of the Soul</var>, I. 8, &#167;9.<br />
        <a name="note14" href="#14">14.</a> Henry More, <var>An Antidote against Atheisme, or an Appeal to the Naturall Faculties of the Minde of Man, whether there be not a God</var> (London, 1653).<br />
        <a name="note15" href="#15">15.</a> 'Those that place the Common <var>Sensorium</var> out of the Head, have seated it either in the <var>upper orifice</var> of the <var>stomack</var>, or in the <var>Heart</var>. The former is <var>Van-Helmont's</var> Opinion, the other <var>Mr. Hobbs</var> his.' More, <var>The Immortality of the Soul</var>, II. 7, &#167;4. More is referring to Jean Baptiste van Helmont, <var>Works</var> (London, 1664), pp283-8.<br />
        <a name="note16" href="#16">16.</a> More is referring to Galen's <var>De placitis Hippocratis et Platonis libri IX</var>, II.<br />
        <a name="note17" href="#17">17.</a> 'Wherefore it is a very rash thing to assert, that the <var>Heart</var> is the Seat of <var>Common Sense</var>, unless by some plain experience it could be evinced to be so, whenas indeed Experiments are recorded to the contrary... And that the <var>Crocodile</var>, his Heart being cut out, will live for a considerable time, and fight, and defend himself. The like is observed of the <var>Sea-Tortoise</var>, and the <var>wild Goat</var>, as <var>Calcidius</var> writes. To which you may add what <var>Galen</var> relates of sacrificed Beasts, that their Hearts being taken out and laid upon the Altar, they have been seen in the mean time not onely to breath, and roar aloud, but also to run away, till the expence of Blood has made them fall down. Which Narrations to me are more credible, I having seen with mine own eyes a <var>Frog</var> quite exenterated, heart, stomack, guts and all taken out by an ingenious friend of mine, and dexterous Anatomist, after which the <var>Frog</var> could see, and would avoid any object in its way, and skipped as freely and nimbly up and down, as when it was entire, and that for a great while. But a very little wound in the <var>Head</var> deprives them immediately of Life and Motion.' More, <var>The Immortality of the Soul</var>, II. 7, &#167;9.<br />
                <a name="note18" href="#18">18.</a> 'As that out of <var>Laurentius</var>, that a Nerve being tied, Sense and Motion will be preserved from the ligature up towards the <var>Head</var>, but downwards they will be lost.' More, <var>The Immortality of the Soul</var>, II. 7, &#167;10. More is referring to Andr&#233; du Laurens (1558-1609), physician and anatomist, and his book <var>Historia anatomica humani corporis et singularum ejus partium multis controversiis et observationibus novis illustrata</var> (Frankfurt, 1599) IV. 7.<br />
                <a name="note19" href="#19">19.</a> 'That the <var>whole Brain</var> is not the Seat of Common Sense, appears from the wounds and cuts it may receive without the destruction of that Faculty; for they will not take away <var>Sense</var> and <var>Motion</var>, unless they pierce so deep as to reach the <var>Ventricles of the Brain</var>, as Galen has observed.' More, <var>The Immortality of the Soul</var>, II. 7, &#167;12.

        <br /><br />
                &#169; Lloyd Strickland 2004, 2007
              <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default HenryMoreSoul;