import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/fontaney1701.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
      	<var>S&#228;mtliche schriften und briefe series I, volume 19</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 417-419<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: mid-February 1701<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (202k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Jean de Fontaney (mid-February 1701)</title>
            <meta name="description" content="An English translation of a LETTER TO JEAN DE FONTANEY by Gottfried Wilhelm Leibniz, from mid-February 1701" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO TO JEAN DE FONTANEY</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A I 19, 417</b>]<br /><br />

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;My Reverend Father<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I must thank you for your kindness in bringing me Reverend Father Bouvet's letter from China.<a name="1" href="#note1"><sup>1</sup></a> A long absence prevented me from fulfilling this obligation.<a name="2" href="#note2"><sup>2</sup></a> Now I add to these very humble thanks my joy at your safe arrival and my fervent wishes for the success of your fine plans, with offers of service, if there is anything in which I may be useful to you now or in the future.<a name="3" href="#note3"><sup>3</sup></a> For, notwithstanding the differences in party and nation, Your Reverence must count me as an enthusiastic supporter of your missions to China, because I believe they will have a great effect on the Christian religion and because I consider them as a means of maintaining a commerce of light between Europe and China, advantageous to both sides if it is well managed.<br /><br />

                [<b>A I 19, 418</b>]<br /><br />

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I hope that Reverend Father Bouvet will have passed on to you something of the kindness he has for me, for such transfers occur without diminution, as one takes <var>lumen de lumine</var> [light from light]. He even claims I should be hopeful of being informed of everything you have brought and that whatever I might wish to be communicated to me will be so.<a name="4" href="#note4"><sup>4</sup></a> I will not abuse these graces, and I beg you, my Reverend Father, to provide me, as you think fit, with this information and lists or accounts of the books, drugs, instruments, and other things for which Europe is indebted to you. However, as I am afraid of losing the opportunity to reply to Reverend Father Bouvet, I am writing to him beforehand,<a name="5" href="#note5"><sup>5</sup></a> without prejudice to my writing again later when I will be more instructed. I beg you, however, to convey the letter at the earliest opportunity, and lastly to honour me with your commands, at least through your friends, for your time is too precious to use it on that. I am sincerely<br /><br />

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;P.S. The renowned Kepler once published a short discourse on the occasion of a letter that Father Terrentius of your company wrote from China.<a name="6" href="#note6"><sup>6</sup></a> I do not doubt that you will find it in your libraries in Paris, that you will make some reflection on it and have the goodness to inform me of your thoughts. From what I have learned I cannot judge whether Europe or China will have the advantage of having you, you who have led the great and fine enterprise of the French mission of your company, which has been so successful. On this matter I wish for whichever tempted you more, which I think must also be the most useful to the public.<br /><br />

                P.P.S. As you have made your great journey, my Reverend Father, with religious and philosophical designs, at the same time I would also dare to ask you about your observations of magnetic declination, and about the winds and currents which you added to the memoires of Reverend Father Gouye.<a name="7" href="#note7"><sup>7</sup></a> Also, if you have obtained any ancient Chinese astronomical observations. Also, what do you say about Mr Witsen's map of Eastern Tartary produced from the memories of Muscovites?<a name="8" href="#note8"><sup>8</sup></a> What do you think of Chinese characters, and whether [<b>A I 19, 419</b>] they happen to have stem characters from which the others are formed, composed or derived according to certain rules? Whether they formerly knew Pythagoras' theorem and other similar theorems, and if they knew demonstrations of them? What do you think of the accuracy of their ancient chronology? Whether they have some important secrets or practices in medicine. Whether their mines have practices different from ours, and if they have metals and minerals unknown to us. Whether gout and kidney stones are as common there as in Europe; whether the pox has been there for a long time, likewise scurvy. Whether they have historians of a considerable antiquity, for example of two thousand years. Whether any Chinese prince is learning European languages. Finally, whether some Chinese may be brought over who can stay in Europe and help with translations.
                        <br /><br /><br />
                        <hr className='rightcol-hr'/>
                        <br /><br />
                        NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> Namely Bouvet's letter to Leibniz of 19 September 1699. English translation available <a href="https://leibniz-bouvet.swarthmore.edu/letters/letter-f-19-september-1699-bouvet-to-leibniz/" target="_blank">here</a>.<br />
                        <a name="note2" href="#2">2.</a> The Academy editors here note that, following his stay in Berlin from May to August 1700, Leibniz travelled to Teplice in September and on to Austria, returning to Hanover at the end of the year.<br />
                        <a name="note3" href="#3">3.</a> Fontaney returned to France in August 1700 and travelled back to China in March 1701.<br />
                        <a name="note4" href="#4">4.</a> See A I 17, 490-491. English translation available <a href="https://leibniz-bouvet.swarthmore.edu/letters/letter-f-19-september-1699-bouvet-to-leibniz/" target="_blank">here</a>.<br />
                        <a name="note5" href="#5">5.</a> Leibniz wrote to Bouvet on 15 February 1701. See A I 19, 401-415; English translation: Lloyd Strickland and Harry Lewis, <var>Leibniz on Binary: The Invention of Computer Arithmetic</var> (Cambridge, Mass.: MIT Press, 2022), 126-134.<br />
                        <a name="note6" href="#6">6.</a> Joannes Terrentius and Johannes Kepler, <var>R.P.J. Terrentii e Societate Jesu Epistolium ex regno Sinarum ad mathematicos Europ&#230;os missum: cum Commentatiuncula Joannis Keppleri mathematici</var> (&#379;aga&#324;: Cobius, 1630).<br />
                        <a name="note7" href="#7">7.</a> Thomas Gouye, <var>Observations physiques et mathematiques pour servir &#224; l'histoire naturelle &amp; &#224; la perfection de l'Astronomie & de la G&#233;ographie: Envoy&#233;es de Siam &#224; l'Acad&#233;mie Royale des Sciences &#224; Paris, par les Peres Jesuites Fran&#231;ois qui vont &#224; la Chine en qualit&#233; de Math&#233;maticiens du Roy. Avec les reflexions de messieurs de l'academie, &amp; quelques notes du Pere Gouye, de la Compagnie de Jesus</var> (Paris: Martin, Boudot, and Martin, 1688).<br />
                        <a name="note8" href="#8">8.</a> Namely, Nicolaas Witsen's <var>Nieuwe Lantkaarte</var> (1687). Witsen explained the origin of the map as follows: &quot;'Tis almost 28 years since I Travelled into <var>Russia</var>, and being there merely for my own Satisfaction, I not only Conversed with the Inhabitants of those Countreys, but with <var>Tartars</var> of all sorts. There I grew first informed not only in the Situation of those Parts, but of such Countries as lay very remote. I have not ceased from that time, by various Methods I have found, to send Letters unto, and receive Answers from the most Northern and North-East parts of the World. For I have maintain'd a constant Correspondence in <var>Mosco</var>, <var>Astracan</var>, <var>Georgia</var>, <var>Ispahan</var>, <var>Polonia</var>, and <var>Constantinople</var>. I have had Letters every year from Pekin, the chief City of China. I have gathered Volumes of Journals and Registers, which set forth Names of Mountains, Rivers, Cityes and Towns, together with a vast number of Drafts made by my own Order, which describe the Territories that I have mentioned. 'Tis from the Fund, which has been gathering for so many years, and by comparing and adjusting all these Materials, and by persevering without intermission therein, that the Map is made up.&quot; From Nicolaas Witsen's letter in <var>Philosophical Transactions</var> 193 (1691), 493.

                <br /><br /><br />
                        &#169; Lloyd Strickland 2018
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
