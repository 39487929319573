import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/urlsperger.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
		Shelfmark LBr 948 Bl. 1<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 4 January 1713<br /><br />
		Translated from the German<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (151k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Samuel Urlsperger to Leibniz (4 January 1713)</title>
            <meta name="description" content="An English translation of a letter from SAMUEL URLSPERGER TO GOTTFRIED WILHELM LEIBNIZ, from 4 January 1713" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>SAMUEL URLSPERGER TO LEIBNIZ</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>LBr 948 Bl. 1r</b>]<br /><br />		
                <var>Most Excellent Sir</var>,<br />
                <br />
                This is to follow up on what Your Excellence through Prof Wolff desires from me.<a name="1" href="#note1"><sup>1</sup></a> The cause for the long delay are my <var>fata contraria</var>,<a name="2" href="#note2"><sup>2</sup></a> which prevented me from getting to my books in order to put together the requested extract. Since I, however, have had letters to inform me that for the moment there is no open position either in W&#252;rttemberg nor in my fatherland, I have decided for myself to spend this winter in Halle, and to have my English books redirected from N&#252;rnberg, so I [<b>LBr 948, Bl. 1v</b>] make use of them for a small work, which I have planned to put together this winter. And since the very same have arrived in fact quite a few hours before the mail leaves again, I should not lose time to copy out the ending from the <var>Memoirs of Literature</var> in order to send it to Your Excellency most obediently.<a name="3" href="#note3"><sup>3</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I pray therefore not to receive this hasty note with bad grace, being pressed for time, but to rather allow me the honour of further high affections; for which together with my humble recommendation I remain<br />
                Your Excellency's<br /><br />
                                    
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Most humble.<br /><br />

                Halle, AD 4. Jan 1713<span style={{float: 'right'}}>M. Samuel Urlsperger</span>

                        <br /><br /><br />
                        <hr className='rightcol-hr' />
                        <br /><br />NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> Christian Wolff (1679-1754), one of Leibniz's correspondents and supporters. He was at the time Professor of Mathematics and Natural Philosophy at the University of Halle.<br />
                        <a name="note2" href="#2">2.</a> 'adverse fates'.<br />
                        <a name="note3" href="#3">3.</a> The <var>Memoirs of Literature</var> was an English-language journal (pamphlet might be a better description), issued weekly between 1710 and 1714. Several issues featured extracts from Leibniz's <var>Theodicy</var>; Urlsperger made copies of these extracts and enclosed them with this letter.<br /><br />
                        <br />
                        &#169; Julia Weckend 2015
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
