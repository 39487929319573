import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'; 
import { Helmet } from 'react-helmet';

function Forbidden() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
 
  return (
    <div>
        <Helmet>
            <title>Error 403 - Forbidden!</title>
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={2} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                    <></>
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>ERROR! YOU HAVE TRIED TO ACCESS A PDF FILE VIA AN UNAUTHORISED LINK!</h2>
                    <br />
                    Please note that the PDF files on this site can only be accessed by users of this site. Links to them are not permitted.<br /><br />Please return to <a href="https://www.leibniz-translations.com">Leibniz Translations home page</a>.
                    <br /><br /><br /><br />
                </Col>
                <Col md={2}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Forbidden;