import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/prime.pdf'; 
import { Helmet } from 'react-helmet';

function PrimeTest() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>Journal de S&#231;avans</var><br />
		1678<br />
		pp 75-76<br></br><br></br>
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: February 1678<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (103k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Extract from a letter written from Hanover by Mr. Leibniz to the author of the journal, containing a new observation about the way of testing whether a number is prime</title>
            <meta name="description" content="An English translation of EXTRACT FROM A LETTER WRITTEN FROM HANOVER BY MR. LEIBNIZ TO THE AUTHOR OF THE JOURNAL, CONTAINING A NEW OBSERVATION ABOUT THE WAY OF TESTING WHETHER A NUMBER IS PRIME, from February 1678" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`} style={{fontSize: isMobile ? '20px' : ''}}>EXTRACT FROM A LETTER WRITTEN FROM HANOVER BY MR. LEIBNIZ TO THE AUTHOR OF THE JOURNAL, CONTAINING A NEW OBSERVATION ABOUT THE WAY OF TESTING WHETHER A NUMBER IS PRIME</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 <div id="note">The following is from a letter to Jean Paul de la Roque, who was at the time editor of the <var>Journal des S&#231;avans</var>. The complete letter - now lost - was probably written at the end of December 1677 or early January 1678. The following extract was published in the February 1678 issue of the <var>Journal des S&#231;avans</var>.</div><br /><br />


                [<b>JS 75</b>]<br /><br />

                Extract from a Letter Written from Hanover by Mr. Leibniz to the Author of the Journal, Containing a New Observation about the Way of Testing Whether a Number is Prime<br /><br />

                I have made some observations on prime numbers, which are important, in my opinion, for the perfection of the science of numbers, in which one calls primes those which cannot be divided, nor produced by multiplication, whereas all the others can be produced and divided by these. If their progression were well known, it would help reveal to us the mystery of numbers in general: but it has seemed so bizarre till now that no mark or affirmative property of them could be found, [<b>JS 76</b>] and all that is known about them is that they are indivisible. It is even difficult to recognize this [indivisibility] in large numbers, without testing it by a multitude of others, which is strangely long-winded. I believe I have found the true way to enter their nature,<a name="1" href="#note1"><sup>1</sup></a> but not yet having had enough time to complete it, I will give you here a positive property, which seems to me curious and useful, even though it is not reciprocal,<a name="2" href="#note2"><sup>2</sup></a> for at least all the numbers that don’t have it will be excluded from the outset. Here is this property. <var>Any prime number above five being diminished either by 1 or 5 disjunctively, is divisible by 6</var>.<a name="3" href="#note3"><sup>3</sup></a> For example, 7 minus 1 is 6; 11 minus 5 is 6; 13 minus l is 12; 17 minus 5 is 12; 19 minus 1 is 18; 37 minus 1 is 36; 101 minus 5 is 96; 103 minus 1 is 102, which divided by 6 is 17; 10007 minus 5, divided by 6, is 1667; 510511 minus 1, divided by 6, is 85084 etc.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> See Leibniz's &quot;Numeri primi eorumque genesis mira&quot; of 6 and 7 September 1677 (LH 35, 4, 17 Bl. 1-2).<br />
                <a name="note2" href="#2">2.</a> In other words, if one takes a number, divides it by 6, and gets the remainder 1 or 5, this does not mean it is prime.<br />
                <a name="note3" href="#3">3.</a> Or as the Academy editors put it, &quot;The theorem says that any prime ({'>'} 5) divided by 6 leaves the remainder 1 or 5.&quot; A III 2, 307.<br /><br /><br />
                &#169; Lloyd Strickland 2021
              <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default PrimeTest;
