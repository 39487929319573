import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/wolff.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>Briefwechsel zwischen Leibniz und Christian Wolff
        </var><br />
		C. I. Gerhardt (ed)<br />
		pp 18, 46 &amp; 50<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 21 February 1705, 9 November 1705 &amp; 8 December 1705<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (15k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Christian Wolff (selections from 1705)</title>
            <meta name="description" content="An English translation LETTERS TO CHRISTIAN WOLFF (SELECTIONS) by Gottfried Wilhelm Leibniz, from 1705" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO CHRISTIAN WOLFF (SELECTIONS)</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>GW p18</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I come to your specimen of practical philosophy of written mathematics, and its first chapter.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We are not able to give a nominal definition of pleasure, and pleasantness is no more familiar than pleasure; yet pleasure admits of a real definition, and I think it is nothing other than a sense of perfection. It is the same with regard to other clear but confused ideas; so a definition of the colour green can be given, but it is not nominal, but nevertheless it is real, containing the cause, as it is evidently constructed from blue and yellow.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I do not think that a blessedness, which is entirely the fruit of prayers, can exist in a creature, but rather the true blessedness of a created mind consists in being free to progress to greater goods. It is not enough to enjoy a contented and tranquil mind, for that is also what happens with the stupid.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;That God directs everything towards his own glory is also the same as saying that he directs everything according to the greatest perfection of things, for true glory consists in that, to act in the best way. Those who are punished are not the ones who impede the perfection of things, for, to put it briefly, that is impossible, but the ones who do not prevent the perfection of things from being impeded. These people by their own punishment contribute to the perfection of things.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In defining ownership it would be necessary to explain what it means to have as one's own. To be my property, and me to be the owner, are equally clear.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 21 February 1705<br /><br /><br />
                [<b>GW p46</b>]<br />
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Rational souls also always preserve moral laws of their own character and in the best commonwealth they dwell under God's as ruler. And I think angels should not be conceived otherwise, except that they surpass us by miles in the vigour and subtlety of their minds and bodies, and perhaps by some means they have in their power their own transformations. And so in my view all things everywhere happen in the same way, by a great uniformity of nature in the greater and the lesser, visible and invisible, and they vary only in their degree of magnitude and perfection.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 9 November 1705<br /><br /><br />
                [<b>GW p50</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You say correctly that the essence of creatures depends on the divine intellect, their existence on its will. However the divine will in turn obeys the rule of the intellect. For God does not will unless he is aware of what in his intellect is the best.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The origin of evil is from the limitation of creatures.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;That the mind is determined to one thought rather than another does not arise from a purely indifferent decision, but on the contrary it has its own reasons. However it must be said that the mind is a most spontaneous thing, as indeed it appears from my system.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It is true that being in itself exists necessarily, and unless it is assumed, no beings will exist because of another being. But it is not so easy to accurately demonstrate that God is being in itself, or is omniscient, omnipotent and unique. Lucretius said that all atoms are beings in themselves, therefore other arguments need to be added, but we have the majority of them now.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 8 December 1705
        <br /><br /><br />
                &#169; Lloyd Strickland 2004<br />
                With gratitude to John Thorley for advice and suggestions
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
