import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/philipp.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series II, volume 1</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		p 518<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 11/21 March 1681<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (11k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Christian Philipp (11/21 March 1681)</title>
            <meta name="description" content="An English translation of a LETTER TO CHRISTIAN PHILIPP by Gottfried Wilhelm Leibniz, from 11/21 March 1681" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>CHRISTIAN PHILIPP TO LEIBNIZ</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Your <var>theoria motus concreti</var> has been included in the works of Thomas Browne <var>de Pseudodoxia epidemica</var>, translated by a man called Rautner. If you have seen this book, I beg you to let me know your opinion of it, because there are several other pieces that the translator has added to it. I beg you also to let me know your view of the writings by Mr Steno and those by Mr Weigel, professor at Jena.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- 19 March 1681.<br /><br /><br />
                <center><h2>LEIBNIZ TO CHRISTIAN PHILIPP</h2>
                        </center>
                        <br />
                        [<b>A II 1, p518</b>]<br /><br />
                        <div align="right">Hanover, 11 March 1681</div><br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I have not seen the translation of Browne's <var>Pseudodoxia Epidemica</var>,<a name="1" href="#note1"><sup>1</sup></a> which was made by Rautner, and I am obliged to you for letting me know about the inclusion of my piece in it. I am far from disowning this piece, which I entitled <var>Physical Hypothesis</var><a name="2" href="#note2"><sup>2</sup></a> and which - I do not know how - has been very well received everywhere, although I imagine that I would say many different things now, if I felt like writing.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;As for my opinion of the works of Mr Steno and Mr Weigel, I have to admit that everything Mr Steno has given us in physics is excellent, but most praiseworthy is his treatise <var>De solido intra solidum</var>.<a name="3" href="#note3"><sup>3</sup></a> I have often urged him to push it further, and to draw from it consequences to discover the beginning of humankind, the general flood, and other fine truths which confirm what Holy Scripture tells us.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mr Weigel is doubtless very clever, but often he is scarcely intelligible, and it seems that he does not always have clear thoughts. I'd rather he applied himself to giving us many fine observations, which he has been able to do while practising mechanics, than amusing himself with general reasonings, in which it seems to me he sometimes gets lost. In spite of that I still have a great deal of respect for him, and recognize that there are many fine thoughts in all his writings.
                        <br /><br /><br />
                        <hr className='rightcol-hr' />
                        <br /><br />
                        NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> Thomas Browne, <var>Pseudodoxia Epidemica</var> (1680).<br />
                        <a name="note2" href="#2">2.</a> Leibniz is referring to his <var>New Physical Hypothesis</var> (1671).<br />
                        <a name="note3" href="#3">3.</a> Nicholas Steno, <var>De solido intra solidum naturaliter contento dissertationis prodromus</var> (Florence, 1669).
                <br /><br /><br />
                        &#169; Lloyd Strickland 2007
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
