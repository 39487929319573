import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import * as Images from '../images';
import { Helmet } from 'react-helmet';

function MobileBooks() {

  const [books, setBooks] = useState([]);
  function BooksDisplay() {
    return (
      <div>
        {books.map((book, index) => (
          <><Row key={index}>
            <Col xs={4}><Link to={book.page}><img loading="lazy" src={Images[book.picture]} style={{ height: '131px', maxWidth: '100%' }} alt={book.title} /></Link></Col>
            <Col><Link to={book.page}><p style={{ fontSize: '15px', marginTop: '20px', color: 'black' }}>{book.title}</p></Link></Col>
          </Row><hr /></>
        ))}
      </div>
    );
  }

  useEffect(() => {
    const BooksList = [
      // Put new books here at the top of this array
      { title: "LEIBNIZ ON BINARY: THE INVENTION OF COMPUTER ARITHMETIC (2022)", picture: 'binary', page: '/leibnizonbinary'},
      { title: "100 AWESOME LATERAL THINKING PUZZLES (2022)", picture: 'lateral', page: '/lateral'},
      { title: "LEIBNIZ'S KEY PHILOSOPHICAL WRITINGS: A GUIDE (2020)", picture: 'key', page: '/key'},
      { title: "LEIBNIZ'S LEGACY AND IMPACT (2019)", picture: 'legacy', page: '/legacy'},
      { title: "PROOFS OF GOD IN EARLY MODERN EUROPE (2018)", picture: 'proofs', page: '/proofs'},
      { title: "THE PHILOSOPHICAL WRITINGS OF PRÉMONTVAL (2018)", picture: 'premontval', page: '/premontval'},
      { title: "TERCENTENARY ESSAYS ON THE PHILOSOPHY AND SCIENCE OF LEIBNIZ (2017)", picture: 'tercentenary', page: '/tercentenary'},
      { title: "LEIBNIZ ON GOD AND RELIGION (2016)", picture: 'godandreligion', page: '/godandreligion'},
      { title:  "LEIBNIZ'S MONADOLOGY (2014)", picture: 'monadology', page: '/monadology'},
      { title: "LEIBNIZ AND THE TWO SOPHIES (2011)", picture: 'sophies', page: '/sophies'},
      { title: "SHORTER LEIBNIZ TEXTS (2006)", picture: 'slt', page: '/slt'},
      { title: "LEIBNIZ REINTERPRETED (2006)", picture: 'reinterpreted', page: '/reinterpreted'},
    ];
    setBooks(BooksList);
  }, []);

  return (
    <>
    <Helmet>
        <title>All My Books</title>
        <meta name="description" content="Links to pages detailing information about books written by Lloyd Strickland" />
    </Helmet>
    <div className="RightColumn" style={{ textAlign: 'center', padding: '0px 0px 0px 0px' }}>
      <br></br>
      <h2>All My Books</h2>
      <p>(Click for details)</p><br></br>
      <Routes>
        <Route path="/" element={
          <Container className="Container">
            <div className="main-content">
              <BooksDisplay books />
            </div>
            <br></br>
            <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
          </Container>
        } />
      </Routes>
    </div></>
  );
}

export default MobileBooks;


