import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/essence.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>Opera Philosophica</var><br />
		J. E. Erdman (ed)<br />
		p 112<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 18 June 1691<br /><br />
		Translated from the French<br /><br />
        Note - the portion of this text that is included in my <var>Shorter Leibniz Texts</var> is not included here.<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (10k)</Link><br /><br />
        <Link to="/slt">Back to <var>Shorter Leibniz Texts</var></Link><br /><br />
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz: Whether the Essence of Body Consists in Extension (18 June 1691)</title>
            <meta name="description" content="An English translation of WHETHER THE ESSENCE OF BODY CONSISTS IN EXTENSION by Gottfried Wilhelm Leibniz, from 18 June 1691" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: WHETHER THE ESSENCE OF BODY CONSISTS IN EXTENSION</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>E p112</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You ask me, Sir, for the reasons I have for believing that the idea of body or matter is different from that of extension. It is true, as you say, that many able people are today accused of this opinion, that the essence of body consists in length, breadth and depth. However there are still some who cannot be accused of having too much attachment to Scholasticism, but who are not content with this opinion either.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mr Nicole, in a passage of his <var>Essays</var>, shows himself to be one of those, and it seems to him that there is more prejudice than light in those who do not appear frightened of the difficulties which occur there.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It would need a very ample discourse in order to explain very distinctly what I think about that. Nevertheless here are some considerations that I submit to your judgement, and I hope that you will share your views with me.<br /><br />
        .....
                <br /><br /><br />
                &#169; Lloyd Strickland 2005<br />
                With thanks to Geert de Wilde
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
