import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/muller.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
    	<var>S&#228;mtliche schriften und briefe series I, volume 17</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		p 287<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 18/28 June 1699<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (97k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Phillip Muller (18/28 June 1699)</title>
            <meta name="description" content="An English translation of a LETTER TO PHILLIP MULLER by Gottfried Wilhelm Leibniz, from 18/28 June 1699" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO PHILLIP M&#220;LLER</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A I 17, p287</b>]<br /><br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The eternal evangelical has found a refuter.<a name="1" href="#note1"><sup>1</sup></a> It seems that he aims to revive Origen's view about the punishment of creatures being only temporary. He is of the same mind as the Socinians, who annihilate the souls of the impious. And I remember having seen, in the kind of book customary among Socinians, a published demonstration against the eternity of punishments by Soner, a philosopher from Altdorf.<a name="2" href="#note2"><sup>2</sup></a> But their opinion on the extinction of soul also destroys the natural immortality of minds, which Origen maintained. It would be better if such things are not stirred up among the common people.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                        <br /><br />
                        NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> A reference to Johann Wilhelm Petersen (1649-1727), one-time superintendent of the churches of L&#252;neburg. Leibniz does not reveal the identity of Petersen's refuter. <br />
                        <a name="note2" href="#2">2.</a> Ernst Soner, <var>Demonstratio Theologica et Philosophica, quod aeterna impiorum supplicia non arguant Dei justitiam, sed injustitiam</var> [A Theological and Philosophical Demonstration that the Eternal Punishments of the Impious do not Prove the Justice of God but His Injustice] (Altdorf, 1603). In 1708 Leibniz planned to reissue Soner's book together with a preface of his own. An English translation of Leibniz's preface can be found in LGR 325-326.
                <br /><br /><br />
                        &#169; Lloyd Strickland 2007
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
