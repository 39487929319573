import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/definitions.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series VI volume 4</var><br />
        Deutsche Akademie der Wissenschaften (ed)<br />
        pp 626, 867, 869 &amp; 937<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: #1 - mid-1685? #2 - summer 1687 - end 1696? #3 - August 1688 - January 1689?<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (11k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Definitions</title>
            <meta name="description" content="An English translation of DEFINITIONS by Gottfried Wilhelm Leibniz, from 1685 - 1696?" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: DEFINITIONS OF METAPHYSICAL AND LOGICAL NOTIONS</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A VI 4, p626</b>]<br /><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Existence</var> cannot be defined any more than can being or pure positive, so of course how can any clearer idea be presented to us; but it should be known that every possible would exist if it could, but because not every possible can exist, with some impeding others, those exist which are more perfect. And so that which is most perfect is certainly understood to exist. But there is some most perfect being, i.e. the most perfect being is possible, because it is nothing other than pure positive.<br /><br /><br />
                <center><h2>LEIBNIZ: DEFINITIONS: BEING, POSSIBLE, EXISTING</h2>
                </center>
                <br />
                [<b>A VI 4, p867</b>]<br /><br /><var>Being</var>&nbsp;&nbsp;possible limit.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Possible</var>&nbsp;&nbsp;that which does not imply contradiction.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Existing</var>&nbsp;&nbsp;compossible with the most perfect.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Compossible</var>&nbsp;&nbsp;that which does not imply contradiction with another.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>More perfect</var>&nbsp;&nbsp;is that which has more of reality or of positive entity.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Necessary</var>, the opposite of which is impossible.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Contingent</var>&nbsp;&nbsp;is not necessary.<br /><br />
                .....<br /><br />

                [<b>A VI 4, p869</b>]<br /><br />

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Being</var> is that which is distinctly thinkable.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Existing</var> is that which is distinctly perceptible.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A chimera, or <var>false being</var>, is that which is thinkable, but not distinctly.<br /><br /><br />
                <center><h2>LEIBNIZ: DEFINITIONS: SOMETHING, NOTHING, OPPOSITE, POSSIBLE</h2>
                        </center>
                        <br />
                        [<b>A VI 4, p937</b>]<br /><br />
                <var>Something</var> is that which can be thought.<br />
                <var>Nothing</var> is that which cannot be named, cannot be thought like <var>Blitiri</var>.<br />
                <var>Opposites</var> are where one thing is the contradiction of the other<br />
                <var>Possible</var> is whatever can be understood.<br />
                <var>Impossible</var> is that which cannot be understood, or that which implies contradiction, like <var>a four-sided triangle</var>.<br />
                <var>Necessary</var> is where the opposite of which is impossible.<br />
                <var>Contingent</var> is where the opposite of which is possible.<br />
                <var>Primitive</var> is that which is understood through itself.<br />
                <var>Derivative</var> is that which must be understood by resolution into other things, like <var>fire</var> is resolved into <var>light</var> and <var>heat</var>.<br />
                <var>Prior by nature</var> is that which is less derivative, and so that which enters into the concept of another thing is itself prior by nature.
                <br /><br /><br />
                &#169; Lloyd Strickland 2003, 2007
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
