import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/mariotte.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series III, volume 1</var><br />
		Deutsche Akademie der Wissenschaften<br />
		pp 139-141<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: October 1674<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (11k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Edme Mariotte (October 1674)</title>
            <meta name="description" content="An English translation of A LETTER TO EDME MARIOTTE by Gottfried Wilhelm Leibniz, from October 1674" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO EDME MARIOTTE</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A III 1, 139</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Among the geometrical and arithmetical observations I have been making for some time, there are two which seem to me very important, and which are entirely complete, because they needed to be discovered by mental effort rather than carried out by laborious work. The variety of things I have had to do till now has not allowed me to put the finishing touches to a few other observations, which perhaps are no less considerable.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>One of these two observations</var> is indeed geometrical, and the other arithmetical, but geometry itself serves to reduce to arithmetic the most important questions of geometry, as are indeed those of quadratures, on which depend the dimensions of curves, of surfaces, of solids, the determinations of the centres of gravity, and consequently many mechanical problems.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;After Vi&#232;te, Mr. Descartes worked to reduce the questions of geometry to the resolutions of equations, the calculation of which is entirely arithmetic. But both he and Viète touched only on <var>rectilinear questions</var>, that is, in which one seeks or supposes only the size of a few straight lines, or rectilinear figures, to which indeed all problems of planes, solids, sursolids etc. are reduced. But it must be admitted that <var>curvilinear problems</var> are of a completely different nature, and it may be said that they are neither plane nor solid nor sursolid, but of the infinitesimal degree, if one wanted to solve them by means of equations.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;As for the problems of rectilinear geometry, we can say that they are in our power when they can be reduced to an equation, because to solve all equations we need only work according to the plan of Viète and Descartes, except that we still lack short cuts to find the best ways.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Therefore, since geometry is perfected in proportion as it is reduced to pure analysis, or to calculation in general, it follows that we have to find a means of reducing the problems of quadratures to calculation, [<b>A III 1, 140</b>] which is done by determining the progressions, the sum of which gives the quadrature of the figure. And since we do not yet know the means of giving the sums of irrational progressions, we have to work to reduce the difficulties of quadratures in the search of sums of rational progressions.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This is what Archimedes first started to do, and what Cavalieri and Father Gregory of St. Vincent and some illustrious geometers of our times have pushed further, although neither Vi&#232;te nor Descartes have given us anything in this matter. But although work on all sorts of hyperboloid and paraboloid curves has been carried out successfully, nothing has yet been done to make the geometric quadrature of the circle and the ellipse easier, because irrational progressions are encountered everywhere.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Nevertheless, I had the good fortune of finally finding the transformation of the circle into another, <var>equivalent rational figure</var>, by means of which I can give a progression of infinite rational numbers, whose sum is finite and equal to the area of the circle, or the circumference, supposing the radius to be 1. So now, the great question of the quadrature of the circle is reduced to the pure arithmetic of rational numbers, and I dare say that this is one of the most important insights recently given in geometry.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The other of the two aforementioned new observations is purely arithmetical, and consists in a <var>very universal method of giving the solutions of problems in whole numbers</var> when they have already been found in fractions, and when it is possible to find the integers and the fractions by this same solution. To show the generality of this method, I can solve problems of such nature: find an integer which, when multiplied, increased, decreased, divided, and this several times and in whatever order it pleases you to carry out these operations, ultimately produces, by given numbers, [<b>A III 1, 141</b>] an integer; which even, if you want to process it once more in this way, by other givens, and in another order of operations, still gives an integer; which, treated a third time etc. and this as many times as you like, always gives an integer at the end. If the problem is impossible, as sometimes happens, I always find the impossibility; I can even solve it in the case of minima, and if you like in the case of maxima lower than prescribed terms, and all this by pure analysis.
                <br /><br /><br />
                &#169; Lloyd Strickland 2022
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
