import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/review1721.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>Journal de S&#231;avans</var><br />
		1721<br></br>
		pp 496-502<br></br><br></br>
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 1721<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (191k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Review of the second volume of "Collection of Various Pieces on Philosophy, Natural Religion, History, Mathematics, etc., by Mr Leibniz, Mr Clarke, Mr Newton, and Other Famous Authors"</title>
            <meta name="description" content="An English translation of REVIEW OF THE SECOND VOLUME OF COLLECTION OF VARIOUS PIECES ON PHILOSOPHY, NATURAL RELIGION, HISTORY, MATHEMATICS, ETC. BY MR LEIBNIZ, MR CLARKE, MR NEWTON, AND OTHER FAMOUS AUTHORS, from 1721" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>REVIEW OF THE SECOND VOLUME OF <var>COLLECTION OF VARIOUS PIECES ON PHILOSOPHY, NATURAL RELIGION, HISTORY, MATHEMATICS, ETC. BY MR LEIBNIZ, MR CLARKE, MR NEWTON, AND OTHER FAMOUS AUTHORS</var></h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>JS p496</b>]<br /><br />

        <var>Recueil de diverses Pieces sur la Philosophie, la Religion naturelle, l'Histoire, les Mathematiques, &amp;c. par MM. Leibniz, Clarke, Newton, &amp; autres Auteurs c&#233;l&#232;bres</var>. A Amsterdam, chez Duvillard &amp; Changuion. 1720. In 12. 2 vols. Vol 1: pp409; vol. 2: 429pp.<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The second volume of this <var>Collection</var> begins with the letters of Mr Leibniz and Mr Newton on the invention of fluxions and the differential calculus.<a name="1" href="#note1"><sup>1</sup></a> We will not enter here into the details of the beginning of this dispute, which lasted for several years; they are already familiar from the book printed in 1712 under the title <var>Commercium epistolicum D. Johannis Collins et aliorum de analysi promota jussu Societatis Regiae in lucem editum</var>.<a name="2" href="#note2"><sup>2</sup></a> The extract from this book had been inserted in the <var>Philosophical Transactions</var> and was translated into French and printed in London under the title <var>Extract from the Book Titled Commercium epistolicum, etc., Published by Order</var> [<b>JS p497</b>] <var>of the Royal Society on the Occasion of the Great Dispute Between Mr Leibniz and Mr Keill Over the Right of Invention of the Method of Fluxions, Called by Some the Differential Method</var>. This Extract was included in the seventh volume of the <var>Journal Litteraire</var>;<a name="3" href="#note3"><sup>3</sup></a> it gave rise to writings by the partisans of Mr Newton and those of Mr Leibniz. Then the latter complained about the Royal Society of London, as if it had condemned him without hearing him; he added that he had several pieces which had not been included in the London Collection and which could nevertheless serve to clear up this literary dispute. Thereupon the Royal Society of London declared on 20 May 1714 that it had not claimed that the report of its Commissioners had passed for the decision of the Society, and Mr Leibniz was advised that if he had new pieces to communicate they would be included in the <var>Philosophical Transactions</var>.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Things remained there until Abb&#233; Conti came to England in 1715. Mr Leibniz congratulated him for being in a country where there were very able people, &quot;But they would like to pass&quot; (he added) &quot;as the only inventors, and it is in this apparently they will not succeed. [<b>JS p498</b>] As Mr Bernoulli has very rightly judged, it does not seem that Mr Newton had the characteristic and the infinitesimal algorithm before me, although it would have been very easy for him to have come to it, if he had been aware of it.&quot;<a name="4" href="#note4"><sup>4</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Much was said at the Court of England about this part of Mr Leibniz's letter, which was in the form of a postscript: the King of England himself asked when Mr Newton would reply.<a name="5" href="#note5"><sup>5</sup></a> He replied to it by a letter to Mr Leibniz and by his remarks on a letter from this learned German. Mr Newton does not deny that Mr Leibniz could have found out for himself the method at the heart of their disputes, but he maintains that he [Leibniz] had it only from him [Newton], and that his [Newton's] method was sufficiently explained in letters to Mr Oldenburg that the latter passed to Mr Leibniz several years before Mr Leibniz published anything on this subject. On the other hand, Mr Leibniz maintains that he had formulated some principles of his method before Mr Oldenburg had passed Mr Newton’s letters to him, and that afterwards he found his general method by arbitrary series, and that he entered into the calculus of differences; he adds that this method is different from that [<b>JS p499</b>] of Mr Newton, since it is not by the fluxions of the lines but by the differences in the numbers that he reached it. It follows from Mr Leibniz's expressions that he had some knowledge of Mr Newton's method before publishing his own; but were these two methods sufficiently different, when they were first published, for both of these two learned men to be regarded as the first inventor? This is the nub of the matter, all the more difficult to decide since often there are no rules for determining what difference there has to be between two methods - especially when these two methods are not fully developed - so that each of them can be considered as new. The French mathematicians resolved to give to Mr Newton and to Mr Leibniz the praises they deserved, and they applied themselves more to profiting from the new methods than to figuring out which one was the first inventor.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The letters on the invention of fluxions and the differential calculus are followed by several letters and several small pieces by Mr Leibniz. Most of the letters are written to Mr Remond, to whom Mr Leibniz sent several of the small pieces included in this collection. [<b>JS p500</b>] Some of these small pieces have already been published.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The first contains reflections on Mr Locke's <var>Essay concerning Human Understanding</var>.<a name="6" href="#note6"><sup>6</sup></a> This philosopher was very attached to experience, whereas Mr Leibniz, full of the spirit of system, wanted to penetrate into nature and into the first principles of the soul and body. This is why they did not judge each other too favourably.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This love Mr Leibniz had for systems gave him a liking for the work of Abb&#233; de St. Pierre entitled <var>Project for a Perpetual Peace in Europe</var>. &quot;I am convinced&quot; (he said) &quot;that such a project is broadly feasible, and that its execution would be one of the most useful in the world.&quot;<a name="7" href="#note7"><sup>7</sup></a> This is what prompted him to comment on this project,<a name="8" href="#note8"><sup>8</sup></a> which other learned men have looked upon as a political fiction.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The &quot;Dialogue between Ariste and Philarete&quot; contains an examination of the principles of Father Malebranche, in particular various parts of the <var>Dialogues on Metaphysics and on Religion</var>.<a name="9" href="#note9"><sup>9</sup></a> Philarete is Mr Leibniz who, just like the ancient philosophers, speaks in this dialogue in a popular manner and without revealing what he really thought.<br /><br />

        [<b>JS p501</b>]<br /><br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;When the Earl of Shaftsbury's Letter on Enthusiasm, which was translated into French and printed under the title <var>Essay on the use of Raillery and Good Humour in Conversations, which Turn on the More Important Matters</var>,<a name="10" href="#note10"><sup>10</sup></a> fell into the hands of Mr Leibniz, he made some remarks on this letter, in which he proposed to show that the author of this writing pushes the use of raillery too far. Since then, Mr Leibniz gave his judgement on all the works of Shaftsbury which were published in 1711 under the title of <var>Characteristics of Men, Manners, Opinions, Times</var>.<a name="11" href="#note11"><sup>11</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We say nothing about the &quot;Essay on the Origin of the French&quot; since it is only the translation Mr Leibniz made of his Latin Dissertation <var>de origine Francorum</var>.<a name="12" href="#note12"><sup>12</sup></a> Mr Leibniz had sent this translation to Mr Remond to present it to the King and to Mr Torcy.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mr Desmaizeaux places among Mr Leibniz's dissertations the letter he wrote to Mr Remond on the [<b>JS p502</b>] book by the Jesuit Father du Tertre entitled <var>Refutation of a New System of Metaphysics Proposed by Father Malebranche .. Author of the Search after Truth</var>.<a name="13" href="#note13"><sup>13</sup></a> The author declares himself sometimes for Father Malebranche and sometimes for Father du Tertre.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The final pieces of this Collection concern Mr Leibniz's favourite system, namely the pre-established harmony. The author endeavours to respond to the objections made to him on this subject by Mr Desmaizeaux and by Mr Bayle in his <var>Critical Dictionary</var> in the article &quot;Rorarius.&quot;<a name="14" href="#note14"><sup>14</sup></a> 
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> <var>Recueil de diverses Pieces sur la Philosophie, la Religion naturelle, l'Histoire, les Mathematiques, &amp;c. par MM. Leibniz, Clarke, Newton, &amp; autres Auteurs c&#233;l&#232;bres</var>, 2 vols. (Amsterdam: Duvillard &amp; Changuion. 1720), II: 3-112.<br />
                <a name="note2" href="#2">2.</a> <var>Commercium epistolicum D. Johannis Collins et aliorum de analysi promota jussu Societatis Regiae in lucem editum</var> (London: Pearson, 1712).<br />
                <a name="note3" href="#3">3.</a> See <var>Journal Literaire de l'ann&#233;e M.DCC.XV. Tome septi&#232;me, premi&#232;re partie</var> (The Hague: Jean van Duren, 1738, 3ed), 114-158.<br />
		<a name="note4" href="#4">4.</a> <var>Recueil de diverses pieces</var>, II: 3-4.<br />
                <a name="note5" href="#5">5.</a> For details, see A. Rupert Hall, <var>Philosophers at War: The Quarrel Between Newton and Leibniz</var> (Cambridge: Cambridge University Press, 1980), 220-221.<br />
                <a name="note6" href="#6">6.</a> <var>Recueil de diverses pieces</var>, II: 143-154. English translation: G. W. Leibniz, <var>The Philosophical Works of Leibnitz</var>, ed. and trans. George Martin Duncan (New Haven: The Tuttle, Morehouse &amp; Taylor Company, 1908, 2ed), 100-105.<br />
                <a name="note7" href="#7">7.</a> <var>Recueil de diverses pieces</var>, II: 173.<br />
                <a name="note8" href="#8">8.</a> See <var>Recueil de diverses pieces</var>, II: 173-184. English translation: G. W. Leibniz, <var>The Political Writings of Leibniz</var>, trans. and ed. P. Riley (Cambridge: Cambridge University Press, 1988, 2ed), 178-183.<br />
                <a name="note9" href="#9">9.</a> <var>Recueil de diverses pieces</var>, II: 211-244. English translation: G. W. Leibniz, <var>Philosophical Papers and Letters</var>, trans. and ed. Leroy E. Loemker (Dordrecht: D. Reidel, 1969, 2nd ed.), 618-627.<br />
                <a name="note10" href="#10">10.</a> Reviewer's note: &quot;We are wrong. The <var>Letter on Enthusiasm</var> and the <var>Essay on the Use of Raillery</var> etc. are two different works which have been translated into French by two different authors. The latter work is properly a defence of the former.&quot;<br />
                <a name="note11" href="#11">11.</a> See <var>Recueil de diverses pieces</var>, II: 245-268 and 269-286. Partial English translation of the latter: G. W. Leibniz, <var>Philosophical Papers and Letters</var>, trans. and ed. Leroy E. Loemker (Dordrecht: D. Reidel, 1969, 2nd ed.), 629-634.<br />
                <a name="note12" href="#12">12.</a> Leibniz's &quot;Latin dissertation&quot; is <var>De origine francorum disquisitio</var> (Hanover: Foerster, 1715), which he subsequently translated into French as &quot;Essai sur l'origine des Fran&#231;ois&quot;; see <var>Recueil de diverses pieces</var>, II: 287-325.<br />
                <a name="note13" href="#13">13.</a> Rodolphe Du Tertre, <var>R&#233;futation d'un nouveau syst&#232;me de m&#233;taphysique propos&#233; par le P... M...</var>, 3 vols. (Paris: Mazieres, 1715). Leibniz's letter to Remond about this book can be found in <var>Recueil de diverses pieces</var>, II: 326-336. An English translation of Leibniz's letter is available <Link to='/remond1715'>here</Link>.<br />
                <a name="note14" href="#14">14.</a> See Pierre Bayle, <var>Historical and Critical Dictionary: Selections</var>, trans. and ed. Richard H. Popkin (Indianapolis: The Bobbs-Merill Company, Inc., 1965), 235-239 and 245-254.<br /><br /><br />
                &#169; Lloyd Strickland 2019
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
