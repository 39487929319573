import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/remond1715.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>Die philophischen schriften von Gottfried Wilheim Leibniz, vol. III</var><br />
		C. I. Gerhardt (ed)<br />
		pp 656-660<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 4 November 1715<br /><br />
		Translated from the French<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (184k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Nicolas Remond (4 November 1715)</title>
            <meta name="description" content="An English translation of a LETTER TO NICOLAS REMOND by Gottfried Wilhelm Leibniz, from 4 November 1715" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO NICOLAS REMOND</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>G III p656</b>]<br /><br />
            <div align="right">Hanover, 4 November 1715.</div>
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I have just received your package, and thank you for the interesting pieces you have shared with me. I say nothing to you on the argument about Homer, but as, after the sacred books, he is the most ancient of all the authors whose works remain to us, I would wish that the historical and geographical difficulties, which their great antiquity give rise to in his works, and principally in the <var>Odyssey</var>, be clarified as regards ancient geography: because fantastic as the voyages of Ulysses are, it is still certain that Homer sent him to the countries which were spoken of at that time, but which it is difficult to recognize nowadays.<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I move on to the philosophical pieces which concern Reverend Father Malebranche (the loss of whom I greatly regret) and which strive to clarify the natural theology of the Chinese. The <var>Refutation</var> of this Father's system, divided into three small volumes, is, without doubt, the work of an able man, because it is clear and ingenious;<a name="1" href="#note1"><sup>1</sup></a> I even approve of some of it, but one part of it is extreme. Too great a distance from the opinions of Descartes and of Father Malebranche is shown, even when they make good sense. It is time to give up these animosities, which the Cartesians themselves have perhaps attracted in showing too much contempt for the ancients and for the School, in which there are nevertheless also sound ideas that deserve our attention. Thus one must do justice to both sides, and benefit from the discoveries of both: just as one has the right to reject what either side advances without foundation.<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1) It is right to refute the Cartesians when they say that the soul is nothing other than thought, likewise when they say that matter is nothing other than extension. For the soul is a subject or <var>something concrete</var> which thinks, and matter is an extended subject, or one endowed with extension. [<b>G III p657</b>] This is why I hold that space should not be confused with matter, although I agree that there is, naturally, no empty space: the School is right to distinguish the concretes from the abstracts, when it is a matter of exactitude.<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2) I grant to the Cartesians that the soul always actually thinks, but I do not grant that the soul is aware of all of its thoughts. For our great perceptions and our appetites, of which we are aware, are composed of an infinity of little perceptions [<var>petites perceptions</var>] and small inclinations, of which we cannot be aware. And it is in the insensible perceptions that the reason for what happens in us is found, just as the reason for what takes place in sensible bodies consists in insensible movements.<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3) It is certainly right to refute Reverend Father Malebranche in particular when he maintains that the soul is purely passive. I believe I have demonstrated that every substance is active, and the soul especially. This is also the idea that the ancients and the moderns have had of it, and Aristotle’s entelechy, which has caused such a stir, is nothing other than force or activity, that is, a state from which action follows naturally if nothing prevents it. But pure primary matter taken without the souls or lives which are united to it, is purely passive: also, strictly speaking, it is not a substance, but something incomplete. And secondary matter (as, for example, the organic body) is not a substance, but for another reason: because it is a plurality of several substances, just like a pond full of fish, or a flock of sheep, and consequently it is what is called <var>unum per accidens</var><a name="2" href="#note2"><sup>2</sup></a> - in a word, a phenomenon. A true substance (such as an animal) is composed of an immaterial soul and an organic body, and it is the compound of these two that is called <var>unum per se</var>.<a name="3" href="#note3"><sup>3</sup></a><br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(4) As for the efficiency of secondary causes, it is again right to maintain it against the opinion of this Father. I have demonstrated that every substance or monad (such as are souls) follows its own laws, by producing its actions without being capable of being disturbed by the influence of another simple created substance; and that thus bodies do not change the ethical-logical laws of souls, just as souls do not change the physical-mechanical laws of the body either. This is why secondary causes truly act, but without any influence of one [<b>G III p658</b>] simple created substance on another; and souls agree with bodies and among themselves by virtue of the pre-established harmony, and not at all by a mutual physical influence, except the metaphysical union of the soul and its body, which makes them compose <var>unum per se</var>, an animal, a living being. It is therefore right to refute the opinion of those who deny the action of secondary causes, but this must be done without reviving the false influences, such as the species of the School.<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(5) Father Malebranche used this argument: that extension, not being a mode of being of matter, must be its substance. The author of the <var>Refutation</var> distinguishes (Vol. 1 p. 91) between the purely negative modes of being and the positive modes of being, and he claims that extension is one of the modes of being of the second sort, which he thinks can be conceived through themselves. But there are no positive modes of being; they all consist in the variety of limitations, and all can be conceived only through the being of which they are the modes and ways. And as for extension, it may be said that it is not a mode of being of matter, and yet that it is not a substance either. &quot;What is it then?&quot; you will say, Sir. I reply that it is an attribute of substances, and there is a great difference between attributes and modes of being.<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(6) Moreover, it seems to me that the author of the <var>Refutation</var> does not combat particularly well the opinion of the Cartesians on infinity, which they rightly consider to be anterior to the finite, and of which the finite is only a limitation. He says (p. 303 of the first volume) that if the mind had a clear and direct view of infinity, Father Malebranche would not have needed so much reasoning to make us think of it. But by the same argument one would reject the very simple and very natural knowledge that we have of the divinity. These sorts of objections are worthless, for there is need of labour and application to give to men the necessary attention for the simplest notions, and one can scarcely succeed in doing that except by calling them back to themselves from their dissipation. This is also why the theologians who have written works on eternity needed a lot of discourses, comparisons, and examples, in order to make it better understood, even though there is nothing simpler than the notion of eternity. But the fact is that in such matters everything depends on the attention. The author adds (Vol. 1. p. 307) that in the so-called knowledge of infinity, [<b>G III p659</b>] the mind sees only that lengths can be placed end to end and be repeated as much as one likes. Very well, but this author might consider that knowing that this repetition can always be made already amounts to knowing infinity.<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(7) In his second volume, the same author examines the natural theology of Father Malebranche, but his beginning seems to me to be extravagant, although he claims to represent only the suspicions of others. When this Father says that God is being in general, this is taken [by the author] to be some vague and notional being, as is the genus in logic, and he [the author] is not far from accusing Father Malebranche of atheism. But I think the Father meant not a vague and indeterminate being but absolute being, which differs from particular limited beings as space, absolute and without limits, differs from a circle or a square.<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(8) It is more reasonable to combat the opinion of Father Malebranche on ideas. For there is no necessity (so it seems) to take them for something which is outside of us. It is sufficient to consider ideas as notions, that is, as modifications of our soul. This is how the School, Mr Descartes and Mr Arnaud take them. But as God is the source of possibilities and consequently of ideas, one can excuse and even praise this Father for having changed the terms and for having given a more refined meaning to ideas by distinguishing them from notions and by taking them for perfections which exist in God, in which we participate by our knowledge. This mystical language of the Father was therefore not necessary, but I find it useful, because it allows us to better envisage our dependence on God. It even seems that as Plato talked of ideas, and as St. Augustine talked of the truth, they had similar thoughts, which I find very reasonable, and this is the part of Father Malebranche’s system that I would be very happy to keep along with the phrases and formulas which depend on it, just as I am very happy to keep the most solid part of the theology of the mystics. And so far from saying, with the author of the <var>Refutation</var> (Vol. 2, p. 304), that the system of St. Augustine is &quot;somewhat contaminated by Platonic language and opinions,&quot; I would say that it is enriched by them, and that they give relief to it.<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(9) I say almost as much as the opinion of Father Malebranche when he asserts that we see all things in God. I say that this is a expression one can [<b>G III p660</b>] excuse and even praise, provided one understands it right, because it is easier to make a mistake over this than in the preceding article on ideas. It is therefore good to consider that, not only in the system of Father Malebranche, but also in mine, God alone is the immediate external object of souls, exerting on them a real influence. And although the common School seems to allow other influences by means of certain species, which it believes that objects send into the soul, it nonetheless recognizes that all our perfections are a continual gift of God, and a limited participation in his infinite perfection. Which is sufficient for us to think that also what is true and good in our knowledge is an emanation from the light of God, and that it is in this sense it may be said that we see things in God.<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(10) The third volume refutes Father Malebranche’s system of revealed theology, especially with regard to grace and predestination. But as I have not sufficiently studied the particular theological opinions of this author, and as I think I have sufficiently clarified the matter in my <var>Essays on Theodicy</var>, I will refrain from entering into this at present.<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It now remains for me to speak to you, Sir, of the natural theology of the learned Chinese, according to what the Jesuit Father Longobardi and Father Antoine of Saint Mary of the minor orders tell us about them in the treatises you sent to me to get my opinion on them, as well as on the way in which Reverend Father Malebranche set about giving to an educated Chinese person some entry into our theology, but that requires a separate letter. The one that I have just written being already rather verbose, I am sincerely, etc.
            <br /><br /><br />
            <hr className='rightcol-hr' />
            <br /><br />
            NOTES:<br /><br />
            <a name="note1" href="#1">1.</a> Rodolphe Du Tertre <var>R&#233;futation d'un nouveau syst&#232;me de m&#233;taphysique propos&#233; par le P... M...</var> [Refutation of a New System of Metaphysics Proposed by Father Malebranche], 3 vols. (Paris: Raymond Mazieres, 1715).<br />
            <a name="note2" href="#2">2.</a> &quot;a unity by accident.&quot;<br />
            <a name="note3" href="#3">3.</a> &quot;a unity by itself.&quot;<br /><br /><br />
            &#169; Lloyd Strickland 2004. Revised 2019<br />
            With gratitude to Elizabeth Vinestock for advice and suggestions
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
