import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/malebranche1676.pdf'; 
import { Helmet } from 'react-helmet';
import img from '../images/malebranche.jpg';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series II, volume 1</var><br />
		Deutsche Akademie der Wissenschaften<br />
		pp 254-255<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: first half of 1676<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (16k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Nicolas Malebranche (first half of 1676)</title>
            <meta name="description" content="An English translation of A LETTER TO NICOLAS MALEBRANCHE by Gottfried Wilhelm Leibniz, from the first half of 1676" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO NICOLAS MALEBRANCHE</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A II 1, p254</b>]<br /><br />
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To Father Malebranche<br /><br />
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;My Reverend Father<br /><br />
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;While returning to my home I thought deeply about what both of us said. It is very true, as you clearly acknowledged, that we would not be able to reflect enough on all things in the heat of conversation, unless we subjected ourselves to rigorous rules, which would be too tedious. However it is much more convenient to follow these rules on paper. I wanted to try this out.<br />
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We were concerned with the vexed question of whether space is really distinct from matter, if there can be a void, or if instead everything which is extended is matter. You support the latter, namely that the essence of matter consists only in extension. And in order to prove that this supposed void would only be a portion of matter, you pointed out to me that this void has really distinct parts; for example, a completely empty vase, separated into two by a body which cuts it. Now everything that is really distinct from something else is separable from it, to which you said that, as a result, the parts of this void are separable, therefore they are movable. Therefore this so-called void is a portion of matter. Or, in order to speak a little more formally, and by propositions:<ol>
<li>The void (the one in the aforementioned vase, for example) has really distinct parts.</li>
<li>Two really distinct things are separable.</li>
<li>Two extended things that are separable are movable.</li>
<li>Everything that has movable parts is matter.</li>
<li>Therefore the so-called void is matter.</li></ol>
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In this reasoning I am obliged to ask for the proof of two propositions, namely the second and the third. I have already contested the second with you, but now I see that the third is not without its difficulties, and I will start with that.<br />
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Therefore I ask that it be proved that two separable extended things are movable, or can change their distance. I do not need to give the reason which makes me doubt this, for in the matter of demonstration it is always right to doubt a proposition which has not been proved. I do it anyway, so that you can better understand my thinking.<br />
		<img src={img} alt="diagram" align="left"></img>
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Let the empty space <var>ABCD</var> be separated into two parts by the body <var>EF</var>. I say that the space <var>ABFE</var> is separable from the space <var>EFCD</var> without motion, or without one moving away from the other; namely by the destruction of one, without the [<b>A II 1, p255</b>] destruction of the other. For let us suppose that the right side of the vase is curved, or that the parallelogram <var>ABFE</var> is changed into the curved figure <var>EGFE</var>; I say that a part of the whole space <var>ABCD</var> remains, i.e. <var>DEFC</var>, and that the other, namely <var>ABFE</var>, is destroyed, and changed into <var>EGFE</var>. And there is no need to say that the first space <var>ABFE</var> still remains, even though it is no longer designated by any body, because I believe we must accept that the parts within the continuum exist only to the extent that they are effectively determined by matter or motion. Therefore I conclude that the parts of space can be separated, although without removing them, because one of these two empty rectilinear places has given way to an empty curvilinear place. But I do not intend to prejudge you with this, in case you are able to prove, by a separate reason, that the elongability or the mobility of an extended body is a consequence of the separability, even though the removal, as I have just proved, is not a result of the separation.<br />
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This is why I ask for proof of the third proposition. Now I come to the second, namely that two really distinct things are separable. Your proof seems to me to boil down to the following:<ol>
<li>Two really distinct things can be <var>perfectly</var> understood, one without the other. I add this word <var>perfectly</var> because I believe it conforms to your opinion.</li>
<li>Two things perfectly intelligible, one without the other, can exist one without the other, or are separable.</li>
<li>Therefore, two really distinct things are separable.</li></ol>
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I have thought deeply about this, and this is the way in which I remain in agreement with the second proposition of this prosyllogism: if <var>perfectly understanding</var> a thing is to understand all the requisites sufficient to constitute it, then I accept the proposition that when all the requisites sufficient to constitute a thing can be understood without understanding all the requisites sufficient to constitute another thing, the one can exist without the other. But then I do not grant the first proposition of this prosyllogism, namely that with two really distinct things, all the requisites of one can always be understood without understanding all the requisites of the other. Nevertheless, if you could prove your propositions universally, without having regard for my distinction, then well done.<br />
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I hope that you will conclude from what I have just said that I have attempted to clear up the matter, that I have written this for the love of truth, and that perhaps I am not entirely unworthy of instruction. And I assure you that you would not be able to convince me without drawing from me a sincere confession to your advantage.<br />
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;After that, perhaps you will recognize me as a philosopher, that is, as a connoisseur of the truth, with so much passion, that I am,<br />
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;My reverend father,<br />
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Yours etc.<br />
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Leibniz.<br /><br /><br />
		&#169; Lloyd Strickland 2004, 2006
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
