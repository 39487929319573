import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/gobien1699.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>S&#228;mtliche schriften und briefe series I, volume 17</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 294-295<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: end of June 1699 (?)<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (175k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Charles le Gobien (end June 1699 (?))</title>
            <meta name="description" content="An English translation of a LETTER TO CHARLES LE GOBIEN by Gottfried Wilhelm Leibniz, from end of June 1699 (?)" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO CHARLES LE GOBIEN</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A I 17, p294</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Extract from my letter to the Reverend Father Gobien S.J.<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I finally received what you had the kindness to send me of your very useful works,<a name="1" href="#note1"><sup>1</sup></a> for which I am very much obliged to you. If what you sent me the first time went through M&uuml;nster, it may have been held back.<a name="2" href="#note2"><sup>2</sup></a> For at present the relationship between that court and ours is not too good,<a name="3" href="#note3"><sup>3</sup></a> although in my view that should not hinder the duties of honesty.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I am sorry to see through an excerpt that you, my Reverend Father, had printed from my preface that you only had a copy of my <var>Novissima Sinica</var> on loan.<a name="4" href="#note4"><sup>4</sup></a> If I had known this, I would have sent a copy by post.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The rumour of the death of the Emperor of China reached us in two ways, namely via Russia and also by an English vessel from the East Indies, but I am delighted that this [rumour] is dying down.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I hope that Reverend Father Bouvet will remember my questions.<a name="5" href="#note5"><sup>5</sup></a> One of the things I am curious to know is the locations of the peoples of Scythia or Tartary and also their languages, to thereby judge how they are related. I am also waiting for more insight on the analysis of Chinese characters and on the critique of their ancient histories.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I am delighted to hear what you tell me, my Reverend Father, of the last exploits of the Chinese against the Elouths. I would very much like a kind of map of the countries between [<b>A I 17, p295</b>] China, the Eastern Sea, Russia, Uzbekistan, and the Indies, in order to better understand the relations.
        I hope this victory means your fathers will be able to take a path on land that Father Grimaldi had tried without success, which would be to go to China through Persia and Uzbekistan, that is, through Bukhara, because I think it was this war with the Elouths that had prevented him from getting through.<a name="6" href="#note6"><sup>6</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Thank you also for the letter from Madam Constance, who shows a heroic virtue. If Reverend Father Tachard finds learned academies in the Kingdom of Orissa, I have no doubt that he will gain insights from them. For the Siamese epoch on which Mr de la Loub&#232;re had reported clearly shows that there were once very able people in this country.<a name="7" href="#note7"><sup>7</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If the court language of these peoples of Orissa is close to Persian, I conclude that it is a former colony or rather conquest of the Uzbeks.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It has been a long time since I heard anything from Reverend Father Kocha&nacute;ski, but your obliging words about him give me reason to write to him again.<a name="8" href="#note8"><sup>8</sup></a> He is very old, but I hope he is still alive. I would like to deserve your gifts by some service etc.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> Namely, [Charles le Gobien], <var>Lettre sur les progrez de la religion &agrave; la Chine</var> (n.p.: n.p., 1697).<br />
                <a name="note2" href="#2">2.</a> In his letter to Leibniz of 10 June 1699, le Gobien mentioned that he was disappointed to hear that a copy of his <var>Histoire de l'edit de l'empereur de la Chine</var> (Paris: J. Anisson, 1698), which he had sent to M&uuml;nster five or six months earlier, had not been passed on to Leibniz as he had hoped. See A I 17, 248-249.<br />
                <a name="note3" href="#3">3.</a> The Akademie editors note that M&uuml;nster was one of the most resolute opponents of Hannover's elevation to an Electorate.<br />
                <a name="note4" href="#4">4.</a> In his <var>Lettre sur les progrez de la religion &agrave; la Chine</var>, 1-2, le Gobien explained that he had had a copy of Leibniz's <var>Novissima Sinica</var> (1697) in his hands for only a few hours, as the book's owner did not wish to part with it. Nevertheless, le Gobien did have time to made a French translation of a short excerpt from the preface of Leibniz's book, which he printed in <var>Lettre sur les progrez de la religion &agrave; la Chine</var>, 3-14.<br />
                <a name="note5" href="#5">5.</a> See Leibniz's letter to Bouvet of 2 December 1697: A I 14, no. 470; English translation available <a href="https://leibniz-bouvet.swarthmore.edu/letters/letter-b-2-december-1697-leibniz-to-bouvet-2/" target="_blank">here</a>.<br />
                <a name="note6" href="#6">6.</a> The Akademie editors note that Grimaldi's attempt to get to China by land failed due to the Tsar's refusal to allow him to pass through.<br />
                <a name="note7" href="#7">7.</a> See Simon de la Loub&#232;re, <var>Du royaume de Siam</var> (Amsterdam: Abraham Wolfgang, 1691).<br />
                <a name="note8" href="#8">8.</a> Adam Adamandy Kocha&nacute;ski (1631-1700), a Polish mathematician, physicist and clockmaker. Kocha&nacute;ski's last letter to Leibniz is dated 11 June 1698; see A I 15, no. 140.
        <br /><br /><br />
                &#169; Lloyd Strickland 2020
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
