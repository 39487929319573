import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/polygamy.pdf'; 
import { Helmet } from 'react-helmet';

function Polygamy() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series VI, volume 3</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 381-382<br /><br />
		Parts of this text can also be found in:<br />
		<var>Textes in&#233;dits tome 2</var><br />
		Gaston Grua (ed)<br />
		pp 852-853<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 4 October 1675<br /><br />
		Translated from the French and Latin<br /><br />{isMobile ? '' : <hr className="centered-hr" />}
		<Link to={pdf} target="_blank">View this translation in PDF format (10k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>On Polygamy</title>
            <meta name="description" content="An English translation of ON POLYGAMY by Gottfried Wilhelm Leibniz, from 4 October 1675" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: ON POLYGAMY</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
		        [<b>A VI 3, p381</b>]<br /><br />
                <div align="right">4 Oct. 1675</div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This morning I received a letter from Mr Gallois. I saw Mr Tschirnhaus, who told me that he saw in Holland a book published from a few pages in Latin by a German author who was the prot&#233;g&#233; of Mr Konigsmarck.<a name="1" href="#note1"><sup>1</sup></a> In this book he claims to prove that polygamy is necessary and in accordance with the law of God and of nature; and that monogamy is an invention of the devil [<b>A VI 3, p382</b>] opposed to humankind and its growth. He puts forward a number of passages from Holy Scripture which are very far fetched. Yet he does not permit women to have many husbands. As one might expect. He says that he spoke to many clever men who were in agreement with him, but who replied that they would not dare to say it. On this he accuses them of being cowards who do not dare to admit the recognized divine truth, and says that they commit a sin <var>in spiritum sanctum, ut resistentes agnitae veritati</var> [against the Holy Spirit, in that they resist a recognized truth]. At the end he puts forward this passage from Holy Scripture: <var>cursed is he who does the work of God deceitfully</var>;<a name="2" href="#note2"><sup>2</sup></a> and in the margin: <var>NB, this is the work of the Lord, he understands the act of procreation</var>.<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mr Tschirnhaus says that there is a library in Holland (in Amsterdam) which holds lectures on the Cartesians and the Socinians. For that passes for the same thing in Holland.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> Johann Leyser, <var>Discursus de polygamia</var>, 1673.<br />
                <a name="note2" href="#2">2.</a> Jeremiah 48.10.<br /><br /><br />
                &#169; Lloyd Strickland 2005
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Polygamy;
