import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/caroline.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
		Shelfmark LH 4, 4, 1 Bl. 7 - 8<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 29 March 1715<br /><br />
		Translated from the French<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (197k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Princess Caroline (29 March 1715)</title>
            <meta name="description" content="An English translation of a letter from GOTTFRIED WILHELM LEIBNIZ TO CAROLINE, from 29 March 1715" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO CAROLINE</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>LH 4, 4, 1 Bl. 7r</b>]<br /><br />		
                Such is the judgement that Lord Bishop of Bristol makes on my book<a name="1" href="#note1"><sup>1</sup></a> that I shall not make further appeal except perhaps to Madam the Princess of Wales on one point, when he seems to overly exaggerate the book's obscurity. For Her Royal Highness has not found it overly obscure. It is true, as he very rightly says, that it would not be reasonable "to mesure the extent of her Royal Highnesses abilities by the common standard". As for the philosophical terms (as, for example, the different kinds and distinctions of evil, of necessity, and of God's knowledge), it seems that they are explained clearly enough, and after that it is acceptable to make use of them, for otherwise one would need too many circumlocutions. And it is like this everywhere where terms of art are used. One cannot say that these are words devoid of sense, "mere jargon, empty words", for intelligible definitions of them have been given. And he who would desire to avoid terms of art would become more obscure because he would become too long-winded, and the reader would lose his way in the multitude of words. It is as if instead of saying "two", "three" and "four", one wanted always to substitute "one and one", and "one and one and one" and "one and one and one and one".<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I admit that the passages in which I use some physical and mathematical notions, and rely on what I have said elsewhere, cannot fail to be obscure to the majority of readers, though they can skip these passages without detriment. Yet they are useful for those who want to want to go considerably deeper into the matter, and who profess to be devoted to research. For it was thought that they would find some new insights in them. [<b>LH 4, 4, 1 Bl. 7v</b>] All one could really say about that would be that perhaps these passages ought to have been distinguished from others, in order to warn readers to skip them. But besides the fact that it is not normal practice to do this, there is perhaps no such passage in which one cannot find something clear and useful, and even in their difficult parts, the attentive reader will perhaps not fail to catch a glimpse of a half light, the consideration of which he will not regret.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I use the example of the inertia of matter in order to explain, by comparison, the privative nature of evil. A reader who is neither a philosopher nor a mathematician will find that difficult. But if he wanted to take the trouble to think hard about what I say, he will not regret it.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;As for my digressions, perhaps they are not long enough to lose the thread of the main subject, and it is easy to get back to it. Far from complaining about my digressions, others have been grateful to me for them. They have been delighted that I have livened up the matter a bit.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A charge of which I recognise that I am guilty is that I should explain certain Latin or Greek citations in French.
                Moreover, my book has been reported in journals - in a Latin and in a German one of Leipzig,<a name="2" href="#note2"><sup>2</sup></a> in the Journal of Paris,<a name="3" href="#note3"><sup>3</sup></a> in that of Trevoux,<a name="4" href="#note4"><sup>4</sup></a> in the <var>Nouvelles de la Republique des lettres</var> of Holland,<a name="5" href="#note5"><sup>5</sup></a> in Mr de la Roche's <var>Memoirs of Literature</var>, of London<a name="6" href="#note6"><sup>6</sup></a> - and not one of the journal editors has accused me of obscurity.<a name="7" href="#note7"><sup>7</sup></a> There are even editors who have praised me for having brought clarity to a very obscure matter. And that has led to the book being recently reprinted in Paris, notwithstanding that I spoke in it as a Protestant.<br /><br />

                [<b>LH 4, 4, 1 Bl. 8r</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;But even if I acknowledge all this obscurity that the Bishop of Bristol attributes to me, I am nonetheless more than content with his favourable remarks about the passages of my book that he finds clear, and these, I hope, make up the majority of it.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It seems he believes there are objections I have still not resolved. I have endeavoured not to leave any out, and I will always be obliged to those who advise me of new ones. But one should not count as objections the oft-made complaints about the obscurity of the interior of things. For example, when reducing the objection against the permission of evil into form and responding to it, it is enough to show that God can have, and even does have, just reasons to permit it, but it is not necessary to explain these reasons in detail; and to exaggerate the extent of its impenetrability is not to make an objection. Every objection can be reduced into good form, and to give a form to this so-called objection, one would have to start with this false maxim: everything I cannot know, is not.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I am delighted to learn of the passage in which the late Bishop of Canterbury, Tillotson, spoke of the matter I deal with,<a name="8" href="#note8"><sup>8</sup></a> and I will look it up. I recall having seen in the hands of Madam the Electress a sermon by the late Mr Sharp, Bishop of York, delivered (if I am not mistaken) to the court and published as a pamphlet, which benefited me greatly. But it was lost.
                        <br /><br /><br />
                        <hr className='rightcol-hr' />
                        <br /><br />NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> Leibniz is referring here to a letter from George Smalridge, Bishop of Bristol, to Caroline, sent 4 March 1715. In the letter, Smalridge renders his verdict on Leibniz's <var>Theodicy</var>. Smalridge's letter is printed as an appendix to Lloyd Strickland, "The reception of the <var>Theodicy</var> in England," in <var>Leibniz, Caroline und die Folgen der englischen Sukzession</var>, ed. Wenchao Li (Stuttgart: Steiner, 2016), 69-90 (the appendix is 88-90).<br />
                        <a name="note2" href="#2">2.</a> <var>Acta Eruditorum</var> (March 1711), 110-21, and (April 1711), pp. 159-168.<br />
                        <a name="note3" href="#3">3.</a> That is, the <var>Journal des S&#231;avans</var>. A notice of forthcoming publication can be found in <var>Journal des S&#231;avans</var> (17 February 1710), 111. However the <var>Theodicy</var> was not reviewed in this journal. Leibniz himself complained of this to correspondents; see e.g. Leibniz to Grimarest, 4 June 1712, in Dutens V, 65.<br />
                <a name="note4" href="#4">4.</a> <var>Memoires pour l'histoire des sciences & des beaux Arts</var> (July 1713), 1178-99.<br />
                <a name="note5" href="#5">5.</a> <var>Nouvelles de la Republique des lettres</var> (September 1710), 314-42; (October 1710), 363-96.<br />
                <a name="note6" href="#6">6.</a> <var>Memoirs of Literature</var> LX (30 April 1711), pp. 237-239; LXI (7 May 1711), pp. 241-243; LXV (11 June 1711), pp. 257-259; LXV (18 June 1711), pp. 261-263.<br />
                <a name="note7" href="#7">7.</a> This is a rather disingenuous claim, inasmuch as Leibniz was well aware that the editor of the <var>Memoires pour l'histoire des sciences & des beaux Arts</var> [a.k.a. <var>Journal de Tr&#233;voux</var>] had found some things in the <var>Theodicy</var> obscure, as he had been informed of this by a correspondent, Barth&#233;l&#233;my des Bosses. See LDB, p. 447. And Leibniz clearly acknowledged the charge, for to Des Bosses he wrote "I should very much like to know as soon as possible what the criticisms were from the <var>M&#233;moires de Tr&#233;voux</var> concerning the things that might appear erroneous or obscure in my book". Leibniz to des Bosses, 21 April 1714, LDB, p. 327.<br />
                <a name="note8" href="#8">8.</a> See John Tillotson, <var>The Remaining Discourses, on the Attributes of God</var>, 7 vols. (London, 1700), VII: pp. 51-80.
                <br /><br /><br />
                        &#169; Lloyd Strickland 2015
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
