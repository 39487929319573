import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/petersen.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>De l'horizon de la doctrine humaine</var><br />
		Michael Fichant (ed)<br />
		p 25<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: after 15 October 1706<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (10k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Johann Wilhelm Petersen (after 15 October 1706; unsent draft)</title>
            <meta name="description" content="An English translation of a LETTER TO JOHANN WILHELM PETERSEN by Gottfried Wilhelm Leibniz, from after October 1706" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO JOHANN WILHELM PETERSEN</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>HD p25</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I, who am often accustomed to think about how the talents of great men might be put to the best use, have seen that you are able to accomplish what I have often wished for - a fit work about heavenly things in the form of an epic poem. For although theology shines forth in prose, it would be even more sublime if clothed in Virgilian majesty, which you alone, out of everyone, could do best. The substance of such a work would be thus: first, God, sufficient in his secret and perpetual eternity, then cosmogony, then the economy of providence in the government of things. But the second part of the work should be about future matters, whether they pertain to bodies or souls or any other things, and here it should be about the purification of souls and the restitution of things, or rather their gradual improvement and elevation. I would like that the last but not least part of the work be devoted to the grandeur of the celestial kingdom, or, as I shall call it, the divine court. There the extraordinary virtues of the angels should be depicted in vivid colours, and the happiness of blessed souls celebrated: to them, innumerable worlds are exhibited, not just the world under our feet; and as they see for all time the different scenes of divine wisdom and goodness, their love and veneration for the supreme mind grows more and more. Here is the holy place for the most elegant fictions, although the truth surpasses anything we can imagine, no matter how beautiful. But aside from you, with your divinely inspired power of expression, and your insight into the inner recesses of divine matters, I know of no one from whom such a work could be hoped for.<br /><br /><br />
                &#169; Lloyd Strickland 2007
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
