import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/landgrave1684.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series I volume 4</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 319-322<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 1/11 January 1684<br /><br />
		Translated from the French<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (177k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Landgrave Ernst von Hessen-Rheinfels (1/11 January 1684)</title>
            <meta name="description" content="An English translation of LETTER TO LANDGRAVE ERNST VON HESSEN-RHEINFELS by Gottfried Wilhelm Leibniz, written 1/11 January 1684" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO LANDGRAVE ERNST VON HESSEN-RHEINFELS</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A I 4, 319</b>]<br /><br />

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;My Lord<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I hope that Your Serene Highness will do me enough justice to believe that never has an expression been more true and more sincere than that with which I begin both this letter and this year at the same time; praying to God that he keep Your Serene Highness for the course of this year and a long series of others and that he long let you enjoy it with the same bodily health which has accompanied you up to now, and the same mental vigour, which everyone admires, and finally with a full satisfaction, proportionate to the greatness of your virtues and the elevation of your rank; in the midst of all kinds of joys and prosperities with which heaven can bless your person and your most serene family. It is true that these wishes can come across as interested, for where would I find a prince who has at the same time both so many insights and so much kindness for me? But I had the same wishes when I had the honour of knowing you only by reputation. For my passion for the public good and my knowledge of what it is to be a great prince make me wish for the conservation of those I believe to be such.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Your Serene Highness’ remarks are entirely in line with my views, even when they appear to be opposed to them. For considering what little disposition people have for the Spanish, and the danger from the Ottomans as a whole, I very much doubt that the declaration of the Spanish is in season. I can hardly believe that the court in Vienna approves it. As far as I can judge, from letters I have received from Mr de Grote, who is in Berlin, this court still remains in the same sentiments, and puts peace with France as the foundation of our safety. As long as it continues, as long as the Turk harasses us, and as long as there is a bishop of M&#252;nster at the disposal of France (because the king of Denmark will perhaps always be so), and finally as long as Amsterdam persists in refusing consent to raise forces, when all these things happen at once, it is impossible, to speak courteously, to get France to listen to reason. It will take divine intervention for that, but a politician must not count on miracles. I therefore believe, given the circumstances, that the Spanish would do better to take advantage of the new [<b>A I 4, 320</b>] delay that France offers them, in order to make some compromise, since they can only lose at present. For France will not fail to give them cause to break off relations in a better time.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I still have to respond to what Your Serene Highness very rightly said about another article of my letter, that one cannot be half-Catholic. That is very far from my views too, and to explain myself more clearly, I hold that one can be in the internal communion of the Catholic Church without being in the external, as for example when one is unfairly excommunicated by the error or malice of the judge. But so Your Serene Highness better understands that I am not far from his views: <var>quemadmodum non privatio, sed contemtus sacramenti damnat</var>,<a name="1" href="#note1"><sup>1</sup></a> all the same I maintain that he who wants to be a member of the Church by this internal communion must make every possible effort to be also in the external communion of the Catholic Church, visible and recognizable by the continual succession of her hierarchy, such as I believe to be what is called the Roman. I say much more, namely that this hierarchy we see there – namely the distinction of the supreme pontiff (since there must be a director) of bishops and priests – is of ordinary divine right. I even add that, by a special assistance of the Holy Spirit promised to her, the visible Catholic Church is infallible in all points of belief necessary for salvation.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;After all these declarations Your Serene Highness will say to me, so why don’t you give yourself to her? Here is why: it can happen that in the Church, despite her being infallible in the articles of faith necessary for salvation, some other errors or abuses<a name="2" href="#note2"><sup>2</sup></a> creep into people’s minds, and by demanding the consent of those who wish to be her members and who believe they have a demonstration of the contrary, it is impossible for them to be in the external communion, so long as they want to be sincere. For example, when Jansenists were required to sign a proposition of fact, about which they thought they knew the contrary, it was not in their power to obey, even if they had been excluded from the external communion of the faithful. The same holds good not only in matters of fact, which depend on the senses, but also in the matters which depend upon reasoning. For example, if the Holy Fathers, who thought the roundness of the Earth quite absurd and even contrary to the analogy of the faith, had demanded the astronomers of their time disavow it, or if the Church of today had demanded from our astronomers the condemnation of the Copernican system. For it is certain that there would have been some excellent astronomers for whom it would have been impossible to give her their support without dissimulation, since opinion is not a thing that depends on the power of the will and that can be changed at one’s pleasure.<br /><br />

                [<b>A I 4, 321</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;So to return to me: there are some philosophical opinions for which I believe I have a demonstration and which it would be impossible for me to change in my present state of mind, while I see no way of responding to my arguments. Now although these opinions are not, as far as I know, opposed to Holy Scripture, tradition, or the definition of any council, they are nonetheless disapproved of and even sometimes censured by theologians of the school, who imagine that the contrary is of the faith. I will be told that I can hide them to avoid censure. But that cannot be. For these propositions are of great importance in philosophy, and when one day I wish to explain some important discoveries I think I have made concerning the search for truth and the advancement of human knowledge, I will have to put them forward as fundamental. It is true that if I had been born in the Roman Church I would not leave it, unless I was excluded, by being refused communion over the unwillingness I would perhaps have to subscribe to certain common opinions. But now, as I was born and raised outside the communion of Rome, I think it is neither sincere nor safe to present myself to enter it, when it is known that one might not be accepted if his heart were discovered. He would always have to be constrained to hide his feelings lest he expose himself to a <var>turpius ejicitur quam non admittitur hospes</var>,<a name="3" href="#note3"><sup>3</sup></a> which would scandalize many people, and instead of bringing peace of mind it would throw me into very great perplexities; besides the civil danger for relapsers. It is true that these opinions, which the monks condemn, would perhaps be approved and at least tolerated by very pious and very enlightened bishops and theologians, but it is not safe to expose oneself over a &quot;perhaps,&quot; and I would have to try to know this in advance.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I have thought about this very often and for several years, but I have not yet found a way. By this, Your Serene Highness will see that I am revealing the depths of my heart, and as I am professing sincerity I hope I have written this for you alone. For I wish to justify myself in your mind. What has led me even more to explain myself so freely is that it occurred to me that perhaps Your Serene Highness, more than anyone, could help me out of this uncertainty. For I confess to you very willingly that I would like to be in the communion of the Church of Rome, at whatever price I can, to enjoy a union so desirable, provided I can do so with a true peace of mind and this tranquillity of conscience I enjoy now, knowing full well I do not omit anything for my part. If I knew that Your Serene Highness took the matter to heart, I would explain myself more distinctly on the way of escaping uncertainty, for which I would be indebted to your goodness. I am sending the rest of the piece, as he knows, and I am with devotion<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;My Lord, to Your Serene Highness, your very humble, very obedient and very submissive servant&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;L
                <br /><br />
                [<b>A I 4, 322</b>]<br /><br />
                P.S. I saw in the gazette that a certain book entitled <var>L'esprit de Mons. Arnauld</var> was defended in the United Provinces.<a name="4" href="#note4"><sup>4</sup></a> Perhaps it is a refutation of his <var>Apology for Catholics</var>.<a name="5" href="#note5"><sup>5</sup></a> Your Serene Highness will have seen it, apparently.
                        <br /><br /><br />
                        <hr className='rightcol-hr' />
                        <br /><br />
                        NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> &quot;just as the privation of the sacraments does not damn but the contempt for them.&quot;<br />
                        <a name="note2" href="#2">2.</a> In the margin Leibniz wrote and then deleted the following: &quot;NB. I say especially errors or abuses, [but] here I am talking only about errors in the minds of the theologians of the Roman Church; on another occasion I will speak of the abuses received by the people. And so long as it is not permissible for me to publicly declare that I do not approve them, I cannot belong to her.&quot;<br />
                        <a name="note3" href="#3">3.</a> &quot;it is worse to turn out a guest than not to admit him.&quot; Ovid, <var>Tristia</var> V.VI.13. See Ovid, <var>Tristia. Ex Ponto</var>, trans. A. L. Wheeler, revised by G. P. Goold (Harvard University Press, 1924), 232.<br />
                        <a name="note4" href="#4">4.</a>[Pierre Jurieu], <var>L'esprit de Mons. Arnauld, tir&#233; de sa conduite, &amp; des Ecrits de luy &amp; de ses Disciples, particulierement de l'Apologie pour les Catholiques</var>, 2 vols. (Deventer: Jean Colombius, 1684).<br />
                        <a name="note5" href="#5">5.</a>Antoine Arnauld, <var>Apologie pour les Catholiques</var>, 2 vols. (Liege: Bronkart, 1681-2).<br /><br /><br />
                        &#169; Lloyd Strickland 2019-2020
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
