import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/earlylife.pdf'; /*Change pdf*/
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {       /*Change left hand panel content*/
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
        Shelfmark LH 41, 1, Bl. 3<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        Date: 1697 (?)<br /><br />
        Translated from the Latin<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (108k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>On My Early Life and Writings</title> {/*Change title*/}
            <meta name="description" content="An English translation of ON MY EARLY LIFE AND WRITINGS by Gottfried Wilhelm Leibniz, from 1697 (?)" /> {/*Change meta description*/}
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: ON MY EARLY LIFE AND WRITINGS</h2> {/*Change title*/}
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br /> {/*Change page content*/}
                 <small>Background: Leibniz here presents a short biography of his academic achievements and chief publications prior to his being offered the post of counsellor in Hannover at the end of 1675. Much of his information appears to be drawn from the title pages of his books. However, he gives the wrong month for his year of birth and the wrong year for the publication of one book (<var>Specimina juris</var>), as well as omitting the last digit of his year of birth and that of another book, indicating that when he did have to rely on memory, he sometimes came up short. The piece was originally intended as a list of his publications, and opened with the statement “These youthful writings are mostly to be revised...”, with the first item in the list being his bachelor's dissertation from 1663; however, Leibniz then added some early biographical detail, writing it above and around the opening statement. The piece is dated on the basis of the watermark.</small><br /><br /><br />
                 [<b>LH 41, 1 Bl. 3r</b>]<br /><br />
                
                Born in August 164....<a name="1" href="#note1"><sup>1</sup></a><br />
These youthful writings are mostly to be revised, corrected, and polished at some point so that they can be published anew.<br />
1659. I wrote a 300-verse poem in one day without elision, to be recited during Pentecost vigils, since the person whose duty it was had been absent.<br />
1661. I left school.<br />
1662. I attended lectures by Thomasius.<br />
1663. 30 May. In Leipzig, I held a bachelor’s disputation <var>On the Principle of Individuation</var> under the supervision of Jakob Thomasius.<a name="2" href="#note2"><sup>2</sup></a> I spent the following summer in Jena.<br />
1664. In winter, I received my master’s degree, and on 3 December of the same year I defended, as supervisor, <var>Specimen of Philosophical Questions [Collected] from the Law</var>.<a name="3" href="#note3"><sup>3</sup></a><br />
1665. On July 14th, I held my first <var>Juridical Disputation on Conditions</var> under the supervision of Bartholomäus Leonhard Schwendendörffer;<a name="4" href="#note4"><sup>4</sup></a> the second disputation was held on August 17th under his supervision.<a name="5" href="#note5"><sup>5</sup></a><br />
1666. In Leipzig I held a dissertation <var>On the Combinatorial Art</var>.<a name="6" href="#note6"><sup>6</sup></a> The title page of the disputation is missing, so I cannot specify the day. I believe it was for a position in the faculty. The dissertation was republished in Frankfurt in 1690 without my knowledge,<a name="7" href="#note7"><sup>7</sup></a>  edited by Heinrich Christoph Cröcker.<a name="8" href="#note8"><sup>8</sup></a><br />
1666. 5 November. In Altdorf, I held a disputation <var>On Perplexing Cases in the Law</var>.<a name="9" href="#note9"><sup>9</sup></a><br />
1667. A collection of my <var>Specimens in Law</var> was published by the Nuremberg bookseller Johann Philipp Miltenberger, as stated in my copy despite part of the title page being removed.<a name="10" href="#note10"><sup>10</sup></a><br />
1667. <var>A [New] Method for Learning and Teaching Jurisprudence</var> was published in Frankfurt by Zunner.<a name="11" href="#note11"><sup>11</sup></a> An index for it was created by Johann Bernhard Zinzerling, a professor in Rostock.<a name="12" href="#note12"><sup>12</sup></a><br />
1669. I produced <var>Specimen of Political Demonstrations for Electing the King of the Poles</var>.<a name="13" href="#note13"><sup>13</sup></a> The title page has Vilnius 1669,<a name="14" href="#note14"><sup>14</sup></a> but it was actually published in Danzig. 12º.<br /><br />
[<b>LH 41, 1 Bl. 3v</b>]<br /><br />
<var>Plan for Reorganizing the Body of Law</var>. Mainz, 166...<a name="15" href="#note15"><sup>15</sup></a><br />
1670. In Mainz, printed by Christophor Küchler, <var>New Physical Hypothesis</var>.<a name="16" href="#note16"><sup>16</sup></a> 12º.<br />
From there, I departed for France in the spring of 1672.<br />
I was called to Hannover at the end of 1675.<a name="17" href="#note17"><sup>17</sup></a>
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> Leibniz omitted the last year of his birth.<br />
                <a name="note2" href="#2">2.</a> Gottfried Wilhelm Leibniz, <var>Disputatio metaphysica de principio individui</var> (Leipzig: Henning Köhler, 1663).<br />
                <a name="note3" href="#3">3.</a> Gottfried Wilhelm Leibniz, <var>Specimen quaestionum philosophicarum ex jure collectarum</var> (Leipzig: Johannis Wittigau, 1664).<br />
                <a name="note4" href="#4">4.</a> Gottfried Wilhelm Leibniz, <var>Disputatio juridica de conditionibus</var> (Leipzig: Johannis Wittigau, 1665). Bartholomäus Leonhard Schwendendörffer (1631-1705), a German legal scholar and professor at Leipzig University.<br />
                <a name="note5" href="#5">5.</a> Gottfried Wilhelm Leibniz, <var>Disputatio juridica posterior de conditionibus</var> (Leipzig: Johannis Wittigau, 1665).<br />
                <a name="note6" href="#6">6.</a> Gottfried Wilhelm Leibniz, <var>Dissertatio de arte combinatoria</var> (Leipzig: Fickium and Seuboldum, 1666).<br />
                <a name="note7" href="#7">7.</a> That is, Gottfried Wilhelm Leibniz, <var>Ars combinatoria</var> (Frankfurt: Heinrich Christoph Cröcker, 1690).<br />
                <a name="note8" href="#8">8.</a> Heinrich Christoph Cröcker (d. 1727), a bookseller active in Frankfurt and Jena.<br />
                <a name="note9" href="#9">9.</a> Gottfried Wilhelm Leibniz, <var>Disputatio inauguralis de casibus perplexis in jure</var> ([Altdorf]: Georg Hagen, 1666).<br />
                <a name="note10" href="#10">10.</a> Gottfried Wilhelm Leibniz, <var>Specimena juris</var> ([Nuremburg]: [Johann Philipp Miltenberger], 1669).<br />
                <a name="note11" href="#11">11.</a> Gottfried Wilhelm Leibniz, <var>Nova methodus discendae docendaeque jurisprudentiae</var> (Frankfurt: Johann David Zunner, 1667).<br />
                <a name="note12" href="#12">12.</a> Johann Bernhard Zinzerling (1625-1669), jurist and professor of poetry at the University of Rostock.<br />
                <a name="note13" href="#13">13.</a> Gottfried Wilhelm Leibniz, <var>Specimen demonstrationum politicarum pro eligendo rege polonorum</var> (Vilnius [Danzig]: [n.p.], 1659 [sic; actually 1669]).<br />
                <a name="note14" href="#14">14.</a> The title page actually states 1659, but this is an error.<br />
                <a name="note15" href="#15">15.</a> Leibniz omitted the last digit of the date, presumably because he could not remember it. He is referring to: Gottfried Wilhelm Leibniz, <var>Ratio corporis iuris reconcinnandi</var> ([n.p.]: [n.p.], [1668]).<br />
                <a name="note16" href="#16">16.</a> Gottfried Wilhelm Leibniz, <var>Hypothesis physica nova</var> (Mainz: Christophor Küchler, 1670).<br />
                <a name="note17" href="#17">17.</a> Leibniz neglects to mention here that he did not actually arrive in Hannover until the end of 1676.
                <br /><br /><br />
                  &#169; Lloyd Strickland 2024
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
