import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/grimarest.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>Opera Omnia volume 5</var><br />
		Ludovic Dutens (ed)<br />
		pp 64-66<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 4 June 1712<br /><br />
		Translated from the French<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (197k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Jean-Leonor le Gallois de Grimarest (4 June 1712)</title>
            <meta name="description" content="An English translation of a LEIBNIZ TO JEAN-LEONOR LE GALLOIS DE GRIMAREST by Gottfried Wilhelm Leibniz, from 4 June 1712" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO JEAN-LEONOR LE GALLOIS DE GRIMAREST</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>D V, p64</b>]<br /><br />

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To Mr de Grimarest<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sir<span style={{float: 'right'}}>Hanover, 4 June 1712</span><br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I beg you once again to omit the &quot;Excellency&quot; when you do me the honour of writing to me, because aside from the fact that this term is better suited to others, I know it is hardly used in France, except for ambassadors.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I have no doubt that, among your learned men, there are many who are able and are capable of clarifying antiquities by drawing a parallel between our arts, manufactured items, and household things, and those of the ancients. Doubtless only the will would be needed, by choosing some particular matter. It was not long ago that Mr Sperling, antiquary of the King of Denmark, gave us a small dissertation <var>de Crepidis veterum</var> [On the Sandals of the Ancients].<a name="1" href="#note1"><sup>1</sup></a> There are many similar pieces, but usually authors do not take sufficient pains to compare the ancient with the modern - which would nevertheless be most useful - to see if any use can be derived from the ways of the ancients.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sir, since you have written a book, which may be called a military course, you must hasten to give it to the public now that this matter is the object of everyone's thoughts and the greatest concern of most powers. I have no doubt it is a very instructive work, and you cannot be lacking a bookseller.<br /><br />

                [<b>D V, p65</b>]<br /><br />


                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I think most people are looking to read for pleasure, but there will always be many who will also look for the useful. And I think that from Charlemagne to our time, Paris has always been the place in Europe where there are the most able people gathered together. Thus, among a crowd of half-learned people, of which you spoke to me, Sir, there are doubtless also many people with sound knowledge, about whom I will always be delighted to hear news. And sometimes even the half-learned are as useful and more useful than the most able, when they apply themselves to doing something useful and their application makes up for what is perhaps lacking in their knowledge. So Paris will always be capable of furnishing us with fine things. Mr Cuper,<a name="2" href="#note2"><sup>2</sup></a> one of the most able men of our time in [the study of] antiquity, fairly praises what Mr Baudelot has given on the antiquities of Notre Dame in Paris,<a name="3" href="#note3"><sup>3</sup></a> and I do not see why he should be despised so much. I am always more inclined to praise what is good in works than to criticize what is bad.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I am a little surprised not to hear that my <var>Theodicy</var> has been reviewed in the <var>Journal des savans</var>. Apparently some of the gentlemen who profess a great attachment to St. Augustine are not pleased that I could not avoid departing from his sentiments in some way. St Augustine was doubtless a great man, and had a great deal of wit, but it is quite apparent that he formed his system gradually, according as he was prevailed upon, without having had a complete plan at the outset. Thus, not having always foreseen the difficulties that would inconvenience him, he was sometimes reduced to resorting to bad excuses. We now have greater aids in all kinds of matters, to form a better plan. Perhaps also the difficulty of the matter has meant that this review of my book has been deferred, and it will nonetheless come out one day. Nevertheless, it is quite curious that this work is finding renowned approvers both in Rome and Geneva, according to letters I have received. Reverend Father Malebranche does not despise it either. I do not see any difficulty with the introduction of this book into the kingdom,<a name="4" href="#note4"><sup>4</sup></a> and I think it is up to the booksellers to bring it there. But apparently they do not know about it because your journals have not spoken of it.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Father Bignon and Mr Fontenelle will know better what is holding things up.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I do not think that trituration alone is sufficient to explain digestion, although it contributes a great deal and we know that a continual motion changes even mild liquids into acids. We are not yet in a position to explain the figures that compose bodies, and in order to perfect the practice of their art able doctors will do better to concern themselves with what is more certain and more connected with experience.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I have seen something of Mr de St Pierre's plan to maintain a perpetual peace in Europe.<a name="5" href="#note5"><sup>5</sup></a> I remember the motto of a [<b>D V, p66</b>] cemetery with these words: <var>Pax perpetua</var>.<a name="6" href="#note6"><sup>6</sup></a> For the dead do not fight, but the living are of a different temper and the most powerful hardly respect tribunals. All these gentlemen would have to give a <var>caution bourgeoise</var>,<a name="7" href="#note7"><sup>7</sup></a> or deposit in the bank of the tribunal - a king of France, for example, 100 million crowns, and a king of Great Britain in proportion - so that the sentences of the tribunal could be executed on their money in case they were resistant. I do not know whether Abb&#233; de St. Pierre will have seen a little book published more than 50 years ago entitled <var>Le Nouveau Cyn&#233;e</var> [The New Cyneas], whose author - who does not name himself - gives princes the advice that Cyneas gave to Pyrrhus, to prefer their peace and convenience over their ambition, and at the same time proposes such a common tribunal.<a name="8" href="#note8"><sup>8</sup></a>
                I remember a learned prince once of my acquaintance wrote a similar discourse, and wanted Lucerne in Switzerland to be the seat of the tribunal.<a name="9" href="#note9"><sup>9</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For my part, I would be minded to establish it in Rome itself, and to make the Pope its president, just as in fact he once acted as judge between the Christian princes: but at the same time the ecclesiastics would have to resume their old authority, and an interdict and excommunication would have to make kings and kingdoms tremble, as in the days of Nicholas I or Gregory VII. And to make the Protestants consent to it, we would have to pray to His Holiness to restore the form of the Church as it was in the time of Charlemagne when he held the Council of Frankfurt, and to renounce all subsequent councils that cannot be considered as ecumenical. It would also have to be the case that Popes resembled the first bishops of Rome. These are projects that will succeed as easily as that of Abb&#233; de St. Pierre, but since it is permissible to make up stories, why should we think badly of his fiction, which would bring us back to the golden age?<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I will be glad to learn the name of the surgeon or French doctor who demonstrates anatomy in wax figures, along with that of his partner, the painter, and that of the anatomist in the Schools of Surgery who imitates this work. I think &quot;etiquette&quot; is a general term, not suited only to the etiquette of the palace. And I doubt it is found that these superfluous words, est hic quaestio [this is the question], were in use on the extracts attached to paper bags; and what is the relation between these words and the style of the palace?<a name="10" href="#note10"><sup>10</sup></a> Thus I think this derivation is as probable as the one which derives &quot;Huguenots&quot; from the beginning of a speech by a Reformed man who said: <var>huc nos venimus</var> [we have come here].<a name="11" href="#note11"><sup>11</sup></a> There is little evidence that a Swede incited the French to call fagots coterets.<a name="12" href="#note12"><sup>12</sup></a> I rather think that coterets is corrupted from c&#244;telets [little ribs], c&#244;tes [ribs], costae [ribs], being pieces that are thin and long, like those that make up the fagots, which resemble the ribs, costis [ribs], of our bones. It was easier than the German words trinquer [to toast],<a name="13" href="#note13"><sup>13</sup></a> gast [guest], etc. to spread in France.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You are right to say, Sir, that few governors take care of their pupils properly. Most people bring them from their home. But your friend would deserve such a place in the event of a vacancy. Your letters, sir, have the benefit of good things. They are agreeable as they are long. I am obliged to you, being<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sir, your very humble and very obedient servant,<span style={{float: 'right'}}>Leibniz</span>
                <br /><br /><br />
                <hr className='rightcol-hr' />
                        <br /><br />
                        NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> Otto Sperling, <var>De crepidis veterum nunc vulgo Pantoffeln dictis</var> (Copenhagen, 1698). The title translates as: On the Sandals of the Ancients, now Commonly Called Slippers.<br />
                        <a name="note2" href="#2">2.</a> Gisbert Cuper (1644-1716), a philologist and classical scholar.<br />
                        <a name="note3" href="#3">3.</a> Charles C&#233;sar Baudelot de Dairval (1648-1722), a collector of antiquities. Leibniz is referring to Dairval's <var>Description des bas-reliefs anciens trouvez depuis peu dans l'&#233;glise Cath&#233;drale de Paris</var> (Paris: Pierre Cot, 1711).<br />
                        <a name="note4" href="#4">4.</a> That is, France.<br />
                        <a name="note5" href="#5">5.</a> For Leibniz's letter to St. Pierre about the project for perpetual peace, <Link to="/stpierre">click here</Link>. Leibniz would later write his observations on St. Pierre's plan; see R 178-183.<br />
                        <a name="note6" href="#6">6.</a> Leibniz made a similar remark in the preface of his <var>Codex juris gentium diplomaticus</var> (1693): &quot;a joker in Holland put up a sign on his house in accordance with local custom - perpetul peace - and under the fine words he drew a picture of cemetery.&quot; A IV 5, 51.<br />
                        <a name="note7" href="#7">7.</a> A good guarantee.<br />
                        <a name="note8" href="#8">8.</a> [Emeric Cruc&#233;], <var>Le Nouveau Cyn&#233;e; ou, discours des occasions et moyens d'&#233;stablir une paix g&#233;n&#233;rale &amp; libert&#233; du commerce par tout le monde</var> (Paris: Jacques Villery, 1623).<br />
                        <a name="note9" href="#9">9.</a> Landgrave Ernst von Hesse-Rheinfels (1629-1693).<br />
                        <a name="note10" href="#10">10.</a> Diderot would later explain this idea in more detail: &quot;The origin of this word 'etiquette' comes from the time when proceedings were drawn up in Latin; written on the bag was 'est hic quaestio inter N... and N...', and often, instead of writing 'quaestio' in full, only 'quaest' was put, which makes 'est hic quaest'. From which the practitioners made, by corruption, etiquette.&quot; <var>Encyclop&#233;die, ou Dictionnaire raisonn&#233; des sciences, des arts et des m&#233;tiers. Tome treizi&#232;me</var> (Berne and Lausanne, 1781), 220.<br />
                        <a name="note11" href="#11">11.</a> In a 19th century encyclopaedia, the story is outlined thus: &quot;a German, who was arrested and questioned concerning the conspiracy of Amboise before the Cardinal of Lorraine, stopped short in his defence after he had uttered the words <var>Huc nos venimus</var>; whence the bystanders, not understanding Latin, said the prisoners were people who came from <var>Huc nos</var>.&quot; <var>Encyclopaedia Metropolitana. Volume XX</var>, eds. Edward Smedley, Hugh James Rose, and Henry John Rose (London, 1845), 382.<br />
                        <a name="note12" href="#12">12.</a> At the time, a coteret was defined as a fagot, especially such as is made of great sticks or cloven wood.<br />
                        <a name="note13" href="#13">13.</a> 'trinquer' is actually a French word derived from the German 'trinken'.
                <br /><br /><br />
                        &#169; Lloyd Strickland 2018
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
