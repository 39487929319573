import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/leibnizmolanus.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>S&#228;mtliche schriften und briefe series I, volume 18</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: June 1700<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (95k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to (and from) Gerhard Wolter Molanus (June 1700)</title>
            <meta name="description" content="An English translation of a LETTER TO GERHARD WOLTER MOLANUS by Gottfried Wilhelm Leibniz, from June 1700" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>GERHARD WOLTER MOLANUS TO LEIBNIZ</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A I 18, 696</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;...As for the rest, I will say it in confidence. When our Serene Electress<a name="1" href="#note1"><sup>1</sup></a> who, as you know, can never refrain from paradoxes, interrupted me during lunch recently, she provoked me to a discussion about the definition of the soul and its real distinction from an extended thing. She then asked me to write down my thoughts on this matter, which I did and sent them to her.<a name="2" href="#note2"><sup>2</sup></a> Her Serene Electress attacked them, not indeed by responding to my arguments, but by multiplying questions, as she is in the habit of doing, some of which were irrelevant while others were very easy to answer. In the end, she declared that she would make you the arbiter of this dispute, and to that end would send my paper to you, which I have no doubt she has done.<a name="3" href="#note3"><sup>3</sup></a><br /><br />
                [<b>A I 18, 697</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For my part, I dare to hope and pray that you think like I do in this regard, namely that the soul is a thinking thing and is really distinct from an extended thing; if this is not granted, what then will become of the immortality of the soul? But if, against all my expectations, you think otherwise, then our Serene Electress must surely not be aware of it, and I therefore beg you either to deign to help me with your response or, if this is too burdensome, at least decide not to harm me with it. Our friendship demands this of you, and I am confident that you will act accordingly, given my firm belief in your regard for me.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Hannover, 4 June 1700
                <br /><br /><br />
                <center><h2>LEIBNIZ TO GERHARD WOLTER MOLANUS</h2>
                        </center>
                        <br />
                        [<b>A I 18, 718</b>]<br /><br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;From my reply of 22 June 1700, at Berlin<br /><br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Her Serene Highness the Electress sent me what you wrote in French. Obviously I approve of your opinion that the body is extended and the soul is thinking, and that they are distinct from each other, although I think that the Cartesian proof of this has some difficulties. For in order to conclude that extension and thought are incompatible in one and the same subject, the Cartesians should offer definitions of both. Therefore, I do not so much disagree with you or the Cartesians about this as try to complete what they have left unexplained and insufficiently well-founded. For I define both extension (which involves plurality, continuity, and coexistence) and thought, which is of the multitude expressed in a single thing, and so to speak the <var>Iliad</var> in a nutshell. For souls are true unities, that is, simple substances, lacking plurality or parts. Consequently, no natural way of destroying them can be conceived.<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;	Two reasons led me to go to Berlin: the will of the Serene Electress of Brandenburg,<a name="4" href="#note4"><sup>4</sup></a> and the Elector's plan to establish a society of sciences, on which I was instructed to give my opinion. I hope the project will proceed, as I see the plan is not being set aside even amid these present troubles. An observatory will be established, learned men will be invited, and various resources will be sought to support the enterprise. I was ordered to draft the foundation charter. If writing is all that is needed, we have everything in our power.
                        <br /><br /><br />
                        <hr className='rightcol-hr' />
                        <br /><br />
                        NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> Electress Sophie.<br />
                        <a name="note2" href="#2">2.</a> Molanus’ paper, &quot;The soul and its nature&quot;, is printed in A I 18, 92–96; English translation: Gottfried Wilhelm Leibniz, <var>Leibniz and the Two Sophies</var>, ed. and trans. Lloyd Strickland (Toronto: CRRS, 2011), 191–196.<br />
                        <a name="note3" href="#3">3.</a> Sophie sent Molanus' paper to Leibniz with her letter of 2 June 1700. See A I 18, 90–92; English translation: Leibniz, <var>Leibniz and the Two Sophies</var>, 189–190.<br />
                        <a name="note4" href="#4">4.</a> Sophie Charlotte.
                <br /><br /><br />
                        &#169; Lloyd Strickland 2006. Revised 2024
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
