import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/toland1708.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
		Shelfmark LBr 933 Bl. 12<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 6 October 1708<br /><br />
		Written in English<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this text in PDF format (103k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>John Toland to Leibniz (6 October 1708)</title>
            <meta name="description" content="A transcription of a letter from JOHN TOLAND TO GOTTFRIED WILHELM LEIBNIZ, from 6 October 1708" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>JOHN TOLAND TO LEIBNIZ</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>LBr 933 Bl. 12r</b>]<br /><br />		

                Sir<span style={{float: 'right'}}>1708</span><br /><br />

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I take the liberty of writing to you in English, because you understand it as well as any liveing tongue besides yr own. The book I do my self the honor to send you, and which I have just publish'd, is not with a pretence to teach you any thing (being always ready to learn of so great a master) but as a mark of the veneration I have for yr person, which is no way inferior to the esteem and admiration, which with the rest of the world I justly entertain for yr extraordinary learning & parts. I know what progress, much beyond the common reach of princes, his most serene Highness the Electoral prince has made in the latin tongue, and of his knowledg is not confin'd to words, but extends to the reality of things in more than one science. Wherefore I earnest beg the favor of you to lay me in all duty & humility at the feet of highness, desiring he wou'd please to accept of this book I take the liberty to present him for some hours amusement, which I shall always reckon a most singular honor and happiness. I highly approve of that letter of yours which is prefix'd to Monsr la Crose's French Dissertations;<a name="1" href="#note1"><sup>1</sup></a> and hope you'll not think it a trouble to inform me, whethr you have ever read the Spaccio de la bestia triomphante of [<b>LBr 933 Bl. 12v</b>] Jordano Bruno. The reason of my demand you shall know after I have the happiness to receive yr answer. I thank you from the bottom of my soul, that her Royal Highness, whom I value above all persons liveing, continues in so good health, and, as I am inform'd, in as good humor & temper as ever. You'll please to direct yr letters for me at Mr Johnson's bookseller in the Hague. I am, with the utmost zeal and sincerity,<br />
                <div style={{textAlign: 'center'}}>Sir,<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Yr most faithfull and<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;obedient ser</div><br />
                <br />
                At The Hague, this 6<span style={{float: 'right'}}>J. Toland</span><br />
                Oct. n.s. 1708

                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> The letter in question is one Leibniz wrote on 2 December 1706 to Maturinus Veyssi&#232;re La Croze (1661-1739), a French Benedictine historian and orientalist, later a Protestant convert. It was written in response to an essay La Croze had composed, 'Reflexions historiques et critiques sur le Mahometisme, & sur le Socinianisme on Mahometism and Socinianism'. La Croze subsequently published that essay, along with Leibniz's letter (albeit without Leibniz's permission), in a book entitled <var>Dissertations historiques sur divers sujets</var> (Rotterdam, 1707). An English translation of Leibniz's letter is available in <var>Leibniz on God and Religion</var>, trans. and ed. Lloyd Strickland (London: Bloomsbury, 2016), 337-344.<br /><br /><br />
                Transcribed by Lloyd Strickland, 2014
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
