import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/schulenburg-slt.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>Die mathematische schriften von Gottfried Wilheim Leibniz, vol. VII</var><br />
		    C. I. Gerhardt (ed)<br />
		    pp 238-240<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		    Date: 29 March 1698<br /><br />
        Translated from the Latin<br /><br />
        Note - the portion of this text that is included in my <var>Shorter Leibniz Texts</var> is not included here.<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (12k)</Link><br /><br />
        <Link to="/slt">Back to <var>Shorter Leibniz Texts</var></Link><br /><br />
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Johann Christian Schulenburg (29 March 1698)</title>
            <meta name="description" content="An English translation of a LETTER TO JOHANN CHRISTIAN SCHULENBURG by Gottfried Wilhelm Leibniz, from 29 March 1698" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO JOHANN CHRISTIAN SCHULENBURG</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>GM VII p238</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Although Mr Sanderus has asked for more in my name than I might have dared to ask myself, I would be unwilling to waste your time about that, nevertheless the greatest profit for me comes from your welcome letters which are most kind, and I am also greatly delighted by the gifts, for which I owe you extraordinary thanks. [<b>GM VII p239</b>] Each treatise which you have sent is on a subject very agreeable to me. For the urns discovered under the burial mounds, with which the Blumian Theses are concerned, throw light on the ancients of these regions. Certainly the application of the mathematical calculus to practice, and in addition the reasoning in the Semi-Mathematics Of Metaphysics, both of which are found in Knollean Theses, are plainly to my taste. I should wish the urns to be sketched out, and the other things you mention in the preface, and I should wish for Mr Knolle to proceed with that which he has begun to adorn with his illuminating studies. His meditations on metaphysics seemed to me to contain something beautiful and profound and, if I might be permitted to add this also, something consistent with my own views.<br /><br />

                .....<br /><br />

                [<b>GM VII p240</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;As for the rest, I ask that if the opportunity arises you may urge Mr Knolle, in my name, if that may seem useful, that he proceed with his noble meditations, as I should wish to frequently see similar things either in Mathematics or in that higher Philosophy. Furthermore, I should wish the cultivation of that most sublime Mathematics to be stimulated, which contains the science of infinity, certain elements of which were published by me, brought forth in the proposed new calculus, which Huygens and other eminent men have welcomed, not without praise, and which others have now made famous, especially the Bernoulli brothers and also the Marquis l'Hospital in his own treatise. And it has been ascertained that there is no better method for accessing nature than by geometry, which, proceeding by infinite intermediate degrees in every change, has, in my opinion, the character of the infinite author. What you once took the trouble to demonstrate to me regarding the residence of our sun, according to the famous astronomer Eimmart, I believe to be entirely true, if we may understand the Earth to be among the planets or satellites of the sun; but if something deeper underlies this, I confess that the mind of the author is not clear to me. Newton, the distinguished mathematician, thinks the vortices of the heavens must be destroyed, but to me, as I once put forward in the Leipzig <var>Acts</var>, not only can they be conserved, but they are also seen to proceed most beautifully from their harmonic revolution, the admirable properties of which I have discovered.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I would like sometime to learn more precisely about the observations of Eimmart, and to profit from your most learned thoughts also; but I realize your occupations are laborious, and furthermore I am sorry about your far from robust health, I hope and pray for better things, that in time you may look out for yourself, which I think needs to be done. Farewell. Sent from Hanover 29. March 1698.
                <br /><br /><br />
                &#169; Lloyd Strickland 2003<br />
                With gratitude to Elizabeth Vinestock for advice and suggestions
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
