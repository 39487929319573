import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/landgrave2.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series II, volume 1</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		p 544<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 29 December 1684/8 January 1685<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (12k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Landgrave Ernst von Hessen-Rheinfels (29 December 1684/8 January 1685)</title>
            <meta name="description" content="An English translation of a LETTER TO LANDGRAVE ERNST VON HESSEN RHEINFELS by Gottfried Wilhelm Leibniz, from 29 December 1684/8 January 1685" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO LANDGRAVE ERNST VON HESSEN-RHEINFELS</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A II 1, p544</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;...I have still not seen either Father Malebranche's book on ideas,<a name="1" href="#note1"><sup>1</sup></a> or Mr Arnauld's two books on this subject.<a name="2" href="#note2"><sup>2</sup></a> Father Malebranche, author of <var>de la recherche de la verit&#233;</var>,<a name="3" href="#note3"><sup>3</sup></a> is very clever and has some very good and solid thoughts, but he has others which are a little hyperbolic and rashly conceived. In some way, he follows Descartes' footsteps, but even Descartes does not satisfy me on these matters. Their principle (that everything that can be concluded from the idea one has of a thing can be truly attributed to it) is very questionable, and unless they give signs of a distinct conception it is pointless to say that everything one conceives clearly and distinctly is true. This analysis of our thoughts is of great importance, as much for judging as for inventing. And I have given my views on this in a short paper published in the November 1684 issue of the <var>Acta Eruditorum</var> of Leipzig, p537,<a name="4" href="#note4"><sup>4</sup></a> about which I would be delighted to learn Mr Arnauld's opinion. I do not doubt that Your Serene Highness receives these <var>Acts</var> from time to time. They are printed every month and are rather well made. Some of those who work on them are my friends.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> Nicolas Malebranche, <var>Reponse de l'auteur de la Recherche de la verit&#233; au livre de M. Arnauld Des vrayes et des fausses id&#233;es</var> (Rotterdam, 1684).<br />
                <a name="note2" href="#2">2.</a> Antoine Arnauld, <var>Des vrayes et des fausses id&#233;es, contre ce qu'enseigne l'auteur de la Recherche de la verit&#233;</var> (Cologne, 1683); and <var>D&#233;fense...contre la R&#233;ponse au livre Des vrayes et des fausses id&#233;es</var> (Cologne, 1684).<br />
                <a name="note3" href="#3">3.</a> <var>The Search after Truth</var> (2 vols, Paris, 1674-5).<br />
                <a name="note4" href="#4">4.</a> 'Meditationes de cognitione, veritate et ideis', <var>Acta Eruditorum</var>, November 1684 pp537-42.<br />
        <br /><br />
                &#169; Lloyd Strickland 2007
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
