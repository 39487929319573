import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/machine.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
		Shelfmark LH 42, Bl. 63r<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: April 1695<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (72k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>My Arithmetic Machine</title>
            <meta name="description" content="A transcription of MY ARITHMETIC MACHINE by GOTTFRIED WILHELM LEIBNIZ, from April 1695" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: MY ARITHMETIC MACHINE</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>LH 42, Bl. 63r</b>]<br /><br />		
                <center>April 1695</center><br />
                I have finally had completed, on a large scale, my arithmetic machine so that it can go to 12-digit products. It is completely different from Napier’s rabdology and from Pascal’s machine. For that of Mr Pascal is only for additions and subtractions, in which there is not a great advantage. And in Napier’s rabdology, even with the improvements of Mr Petit, Mr Moreland, and Mr Grillet, you have to make countless small separate additions, and you are only in effect spared the trouble of using the Pythagorean table. But in mine, if you want to multiply a number of 8 digits by a number of 4 digits, for example, you have only to make four turns of the wheel, and thereby everything is done and complete. It is the same with division. For if you divide a number of 12 digits by a number of 8 digits, it will be enough to make four turns of the wheel and the whole division will be done since we will find the quotient as well as the remainder. We just have to continue the operation and make it a little longer in order to go to slightly larger numbers.<br /><br /><br />
                        &#169; Lloyd Strickland 2020
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
