import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/minerva.pdf'; 
import { Helmet } from 'react-helmet';

function Minerva() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>Vues Philosophiques; ou protestations et declarations sur les principaux objets des connoissances humaines</var> vol. II<br />
        Andr&#233; Pierre Le Guay de Pr&#233;montval.<br />
        pp 10-18
        <br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        Date: 1755 - 1757<br /><br />
        Translated from the French<br /><br />{isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (105k)</Link><br /><br />
        <Link to="/premontval">Back to <var>The Philosophical Writings of Pr&#233;montval</var></Link><br /><br />
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Andre-Pierre Le Guay de Premontval: The False Minerva, Or, The Good Judgement of Momus</title>
            <meta name="description" content="An English translation of THE FALSE MINERVA, OR, THE GOOD JUDGEMENT OF MOMUS by Andre-Pierre Le Guay de Premontval, from 1755 - 1757" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>THE FALSE MINERVA, OR, THE GOOD JUDGEMENT OF MOMUS</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>VP II, 10</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;One day, the cries of living beings rose to the heavens. Through millions of opposing demands the faraway gods did not understand anything except that everything was moaning.<br /><br />

                [<b>VP II, 11</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&quot;Inhabitants of Olympus,&quot;exclaimed Jupiter, &quot;ah! can we, with the goodness on which we pride ourselves, allow this universal discontent? To what would we better use our infinite power than to make happy everything that breathes? I will succeed: I swear by the Styx.&quot;<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Inspired, all the gods applauded this project, and took the same oath.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Here they are descended on the Earth. Look, Mortals: men, animals, clearly tell us your desires. The omnipotence of the gods has sworn to satisfy them.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&quot;I do not want a superior,&quot; said Caesar. &quot;And I, not even an equal,&quot; continued Pompey.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&quot;Ah,&quot; said Alexander (because the difference of time is nothing for the gods), &quot;Ah! How small is this Earth for my triumphs! How cramped I am in it! [<b>VP II, 12</b>] It is too little to extend my conquests. How can I not open a path to the stars, which I suspect are filled with peoples and empires, though my traitor of a master<a name="1" href="#note1"><sup>1</sup></a> does not say a word to me about this?&quot;<a name="2" href="#note2"><sup>2</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Proud Philosophers aspired that all that had been said and found before them had been thought of only by them.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The children of Aesculapius took the god by the beard, and asked him at the top of their voices about the diseases, the malign influences of the stars, and all sorts of troublesome accidents, while the altars were smoking everywhere to obtain perfect health from the gods.<br /><br />

                [<b>VP II, 13</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&quot;Peace, divine peace, heavenly concord, come reign among us,&quot; cried the quiet labourers, the shy merchants. &quot;Infernal Erinys, shake your torches everywhere. Great gods, deliver the Earth&quot;: these were the wishes of the bloody satellites of Mars and the black supporters of Themis, less furious and more to be feared.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Same discords among the animals, on land, in water, and in the air.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The wolf wanted his table well-stocked with sheep; these in their turn condemned him to die of hunger.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;No pike who did not aspire to eat his like and never have such a fate.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;There was no little bird that did not base his cuisine on insects, cursing the hawk whose avid eye devoured him.<br /><br />

                [<b>VP II, 14</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&quot;What violence,&quot; exclaimed Jupiter again! &quot;Are these, then, the works of our goodness! Where were we when all this was done? But what will we do though?&quot;<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The gods looked at each other, and then glanced at Minerva, who said that all was good. &quot;Yes, but everything is furious with this 'good', zounds my daughter,&quot; said Jupiter! &quot;We have sworn to make them content. Once again, what should we do?&quot;<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;No response. &quot;Oh well! I'll take care of it myself,&quot; said Momus. &quot;Wise goddess, what do you think of these creatures? Do you find them very sensible?&quot;<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&quot;Very foolish,&quot; retorted Minerva. &quot;By all the gods, very foolish, and very impertinent! But this does not prevent the gods who made them from being all-wise.&quot;<a name="3" href="#note3"><sup>3</sup></a><br /><br />

                [<b>VP II, 15</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&quot;That's not the issue,&quot; Momus retorted. &quot;Jupiter swore by the Styx, as did we, not that we should make these creatures wise, but that we should make them happy. The other oath might have been better. Let us hold on to this one, and it will be my business, immortal gods, and you prudent Minerva, since you do not want it to be yours.&quot;<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&quot;At first I noticed that they all agree in desiring a long life. I grant them immortality, and an immortality more peaceful than that which we enjoy. With the aid of a secret that I discovered recently, I hold them in a complete inaction; even better, in a lethargic sleep, which saves us from sharing with so many new guests the nectar and ambrosia: great savings for our cellars!&quot;<br /><br />

                [<b>VP II, 16</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&quot;Then, instead of that cruel and fatal folly, which you put up with in them, wise goddess, I will inspire them with the sweet and benign, at least as to the effects. It is only a question of touching certain fibres of their brains. See these heroes as they dream only about fights and triumphs, and as each of them is soon the sole master of all the Earth, without shedding a drop of blood!&quot;<a name="4" href="#note4"><sup>4</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&quot;See this wolf (another hero!) as he feeds on carnage, without any innocent ewes having to complain about our justice!&quot;<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&quot;See these philosophers, see these poets, see these dandies! There is not one who does not prevail over his rivals after a hard fought struggle.&quot;<br /><br />

                [<b>VP II, 17</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&quot;See these coquettes! Not one who does not take all the accolades for herself. This old woman! This decrepit old woman steals worshippers from Venus herself.&quot;<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&quot;What treasures piled up around this miser!&quot;<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&quot;What exquisite food on the table of this hedonist. What concerts! What perfumes! And what ravishing objects destined for his pleasures!&quot;<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&quot;In two words, my opinion is that since nothing is so easy as to satisfy people with illusions, and since it is impossible to succeed with realities, we decided to make so many happy at so little expense.&quot;<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;What could Minerva say that was wiser and more sensible? But the jealous goddess, who took vengeance so cruelly on Arachne, trembled with rage, and would have transformed the god into some new monster had this not surpassed her power. She had the help of confused savants. She spoke a lot; [<b>VP II, 18</b>] threatened to make larger volumes; came out with strong metaphysical verbiage, of which no one understood anything; mixed in strong invectives which everybody understood very well; at last she called the god an ignorant, wrongheaded half-wit who did not know ontology. Her conclusion was, &quot;that for all to be good, there must be countless evils of every kind.&quot; The gods were ashamed of not being of her view; opinion governs them as well as us. They returned to Olympus, saying that &quot;if the inhabitants of the Earth were not content, they should be.&quot;<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A fine decision, which amused Momus and made him laugh at their expense.<br /><br />

                <center><var>Desipere in loco.</var><a name="5" href="#note5"><sup>5</sup></a></center>
                    <br /><br /><br />
                    <hr className='rightcol-hr' />
                    <br /><br />
                    NOTES:<br /><br />
                    <a name="note1" href="#1">1.</a> That is, Aristotle.<br />
                    <a name="note2" href="#2">2.</a> Pr&#233;montval's note: &quot;It has been claimed that Aristotle had known of the plurality of worlds (how could such a truth have eluded Aristotle? 'but that he never wanted to speak of it for fear of displeasing Alexander, who would have been in despair to see a world which he was unable to conquer.'&quot; Pr&#233;montval here quotes from Fontenelle's <var>Entretiens sur la pluralit&#233; des mondes</var> [Conversation on the Plurality of Worlds] (1686); I have used the English translation from Bernard le Bovier de Fontenelle, <var>Conversations on the Plurality of Worlds</var>, ed. and trans. Nina Rattner (Berkeley and Los Angeles, University of California Press, 1990), 64.<br />
                    <a name="note3" href="#3">3.</a> Pr&#233;montval's note: &quot;Pope, Essay on Man.&quot;<br />
                    <a name="note4" href="#4">4.</a> Pr&#233;montval's note: &quot;The ill-intentioned reader is warned to suspend his judgements unless he wants to receive, in the following piece, the most shameful refutation.&quot; This refers to an essay entitled &quot;Contrast between Alexander and the Sage of Brandenburg,&quot; in Pr&#233;montval, <var>Vues philosophiques</var>, II, 19-32.<br />
                    <a name="note5" href="#5">5.</a> A partial rendering of <var>dulce est desipere in loco</var>: It is delightful to play the fool occasionally, from Horace, <var>Odes</var> IV.xii.28.
                <br /><br /><br />
                    &#169; Lloyd Strickland 2018
                    <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Minerva;
