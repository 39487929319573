import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/fardella1692.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>S&#228;mtliche schriften und briefe series II, volume 2</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		p 594<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: mid-October 1692<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (12k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Michelangelo Fardella (mid-October 1692)</title>
            <meta name="description" content="An English translation of a LETTER TO MICHELANGELO FARDELLA by Gottfried Wilhelm Leibniz, from mid-October 1692" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO MICHELANGELO FARDELLA</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A II 2, p594</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;...The ideas of creatures derive from the idea of God, just as the idea of numbers derives from the idea of unity. By that very fact, then, when God comprehends himself, he mentally embraces all possibles, nor is there greater scope for freedom in this matter than in the derivation of numbers from the repetition of unity, nor is the idea of a circle more arbitrary than that of twoness or threeness. I therefore agree with the Scholastics that the existence of creatures is contingent, but that their essences are not. I hear the words of those who think otherwise, but they do not convince me. You rightly say that God is sufficient unto himself, so that he is wise and happy in the highest degree; but it should be understood that the essences of creatures are nothing other than various expressions of the divine perfection, and if God did not comprehend them, he would not sufficiently comprehend himself. Every free decree arises from deliberation, that is, from the intellect, and deliberations are distinguished in God not temporally, but logically [<var>signo rationis</var>], and it is his nature to understand before he wills. But I no more believe that God can suspend his own thought in respect of certain creatures than he can prescribe limits to his own perfection; or rather, that he can be acquainted with numbers only up to 1000 and no further. Whatever God knows or comprehends, he has always known and comprehended.
                <br /><br /><br />
                &#169; Lloyd Strickland 2009<br />
                With gratitude to John Thorley for advice and suggestions
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
