import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/essenceofbody.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
		Shelfmark LH 3, 4, 8d Bl. 1v<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 1694 (?)<br /><br />
        Translated from the French<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (6k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>The Essence of Body</title>
            <meta name="description" content="An English translation of THE ESSENCE OF BODY by Gottfried Wilhelm Leibniz, from 1694 (?)" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: THE ESSENCE OF BODY</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>LH 3, 4, 8d Bl. 1v</b>]<br /><br />
                <s>To speak properly about essence<br />
                The body is a substance<br />
                Force<br />
                I suppose bodies, the essence of<br />
                I think I have proved that the essence of body does not consist in extension; that is, that extension is not sufficient to explain everything that happens in bodies. For example, to explain in what the natural inertia of bodies consists, we must have recourse to force, as I have shown in another paper. And the mystery of the Eucharist seems to tell us that extension is not even part of the essence of body, any more than figure.</s><br />


                <br /><br /><br />
                        &#169; Lloyd Strickland 2023
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
