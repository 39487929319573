import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'; 
import { Helmet } from 'react-helmet';

function Submissions() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
 
  return (
    <div>
        <Helmet>
            <title>Submissions</title>
            <meta name="description" content="Details for submissions of translations for diplay on this website" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={2} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                    <></>
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>SUBMISSIONS</h2>
                Got a Leibniz translation lying around that you'd like the world to see? Or fancy doing one just for the thrill of it? If so please contact me via the email link provided at the bottom of the page. The following should be noted before you write:<br /><br />
                (1) Please do not send unsolicited translations as these cannot be considered. In all cases please send me an email <var>first</var> with details of the translation(s) you have available. Information such as file size and word count is appreciated but not necessary. I will respond to all emails as quickly as I can and let you know if I am interested in looking at the translation(s) offered, either in whole or in part.<br /><br />
                (2) Only original translations will be considered. Anyone submitting a translation to me (and therefore this site) thereby acknowledges that the translation is theirs to offer, i.e. that they are the author of that translation, and that no copyright law has been infringed in producing it. Further, anyone submitting a translation to me thereby agrees to indemnify and hold myself (Lloyd Strickland) and this site free from any and all liabilities, claims and expenses, including solicitors' fees, arising from disputes over authorship, copyright etc. in connection with that translation.<br /><br />
                (3) Submissions will only be published on this site at my discretion. Submitting a translation to me does not guarantee publication.<br /><br />
                (4) No machine translations please.<br /><br />
                (5) Submissions are preferred in Word format, with all formatting intact (i.e. paragraphing, italics, bold, underling etc.). If you do not have the translation available in Word format please let me know what format(s) you have it in.<br /><br />
                (6) All translations published on this site will of course be credited to their respective authors.
                        
                <br /><br /><br /><center>This site and its contents are &#169; Dr. Lloyd Strickland 2003-{new Date().getFullYear()} unless otherwise stated</center><br /><br />
                <form>
                  <input
                    type="button"
                    value="Click here to email me"
                    onClick={() => window.location.href = 'mailto:lloyd@leibniz-translations.com'}
                  />
                </form>
                <br /><br /><br />
                </Col>
                <Col md={2}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Submissions;