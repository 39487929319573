import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/trinity-fathers.pdf'; 
import { Helmet } from 'react-helmet';

function TrinityFathers() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series IV volume 7</var><br />
        Deutsche Akademie der Wissenschaften (ed)<br />
        p 823<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 1683 - spring 1698<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (146k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>On the Dogma of the Trinity in the Fathers and the Scholastics</title>
            <meta name="description" content="An English translation of ON THE DOGMA OF THE TRINITY IN THE FATHERS AND THE SCHOLASTICS by Gottfried Wilhelm Leibniz, from 1683 - spring 1698" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: ON THE DOGMA OF THE TRINITY IN THE FATHERS AND THE SCHOLASTICS</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 <br />
		        [<b>A IV 7, p823</b>]<br /><br />
                The fathers have spoken obscurely about the Trinity and used troublesome words. The Scholastics have propounded this dogma more intelligibly. A worthwhile book to read, but hardly better known, is entitled: <var>La methode, dont les Peres se sont servis en traitant des mysteres, dedi&#233;e au Roy, par Mr. l'Abb&#233; de Moissy, Conseiller Predicateur du Roy, &amp; Aumonier de la feu&#235; Reine Mere de Sa Majest&#233;. A Paris chez Jean Bapt[iste] Coignard, 1683. 4&#176;</var>.<a name="1" href="#note1"><sup>1</sup></a> Somebody asserted that the fathers are the way to the papacy. But I think otherwise.<br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTE:<br /><br />
                <a name="note1" href="#1">1.</a> [L. G. de Cordemoy], <var>La methode, dont les Peres se sont servis en traitant des mysteres, dedi&#233;e au Roy, par Mr. l'Abb&#233; de Moissy, Conseiller Predicateur du Roy, &amp; Aum&#244;nier de la feu&#235; Reine Mere de Sa Majest&#233;</var> (Paris: Jean Baptiste Coignard, 1683).
                <br /><br /><br />
                &#169; Lloyd Strickland 2021<br />
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default TrinityFathers;
