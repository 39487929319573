import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/dog.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>Opera Omnia</var> volume II<br />
		Ludovic Dutens (ed)<br />
		p 180<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 1706<br /><br />
		Note: This text is a summary of a letter which Leibniz submitted to the Academy of Sciences in Paris. The summary was published in <var>l'Histoire de l'Acad&#233;mie des Sciences de Paris</var> (1706), and written by its editors.<br /><br />
		Translated from the French<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (11k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Account of a letter from Mr Leibniz to the Abbe de St. Pierre, on a talking dog</title>
            <meta name="description" content="An English translation of ACCOUNT OF A LETTER FROM MR LEIBNIZ TO THE ABBE DE ST. PIERRE, ON A TALKING DOG by Gottfried Wilhelm Leibniz, from 1706" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>ACCOUNT OF A LETTER FROM MR LEIBNIZ TO THE ABB&#201; DE ST. PIERRE, ON A TALKING DOG</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>D II, p180</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Without a guarantor like Mr <var>Leibniz</var>, who is an eyewitness, we would not have the audacity to report that near Zeitz, in Misnie, there is a dog that speaks. It is a countryman's dog of normal shape and size. A young child heard it emit some sounds that he thought resembled German words, whereupon he got it into his head to teach it to speak. The master, who had nothing better to do, spared no time or trouble with this, and happily the disciple had an aptitude that was hard to find in another dog. Finally, after a number of years, the dog was able to pronounce around 30 words or so, among them <var>Th&#233;</var>,<a name="1" href="#note1"><sup>1</sup></a>  <var>Caff&#233;</var>,<a name="2" href="#note2"><sup>2</sup></a> <var>Chocolat</var><a name="3" href="#note3"><sup>3</sup></a> and <var>Assembl&#233;e</var>,<a name="4" href="#note4"><sup>4</sup></a> French words which have passed into German, as they do. It is notable that the dog was at least 3 years old when it was put in school. It only speaks by echoing, that is, after its master has pronounced a word, and it seems that it only repeats when forced, and despite itself, although it has not been maltreated. Once again, Mr <var>Leibniz</var> has seen it and heard it.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> Tea.<br />
                <a name="note2" href="#2">2.</a> Coffee.<br />
                <a name="note3" href="#3">3.</a> Chocolate.<br />
                <a name="note4" href="#4">4.</a> Meeting.<br /><br /><br />
                &#169; Lloyd Strickland 2007
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
