import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/importunate.pdf'; 
import { Helmet } from 'react-helmet';

function Importunate() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		    <var>S&#228;mtliche schriften und briefe series VI volume 4</var><br />
        Deutsche Akademie der Wissenschaften (ed)<br />
        pp 2706-2707<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        Date: 1677-1716?<br /><br />
        Translated from the French<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (88k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Importunate Reflections on Human Misery</title>
            <meta name="description" content="An English translation of IMPORTUNATE REFLECTIONS ON HUMAN MISERY by Gottfried Wilhelm Leibniz, from 1677-1716?" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: IMPORTUNATE REFLECTIONS ON HUMAN MISERY</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A VI 4, 2706</b>]<br /><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Experience has taught me that there is nothing which destroys courage more, and which, furthermore, takes away the enjoyment of beautiful things, than the importunate reflections we make on [<b>A VI 4, 2707</b>] human misery and on the vanity of our enterprises. Only for great souls is this is a stumbling block, on which it is so much easier to fail the greater one’s genius. For ordinary minds do not focus on this great consideration of the future which in some way embraces the whole universe, but in return they are more satisfied, for they enjoy apparent goods without thinking of destroying the pleasure of them by an overly clinical scrutiny. And as a happy simplicity is better than a sad prudence, I think we would do well to close our ears to reason in order to abandon ourselves to custom, or to reason only in order to amuse ourselves, if there were no way of reconciling wisdom with contentment. But thank God we are not so unfortunate, and nature would be a cruel mother if what brings about our perfection were the cause of our misery.
                <br /><br /><br />
                &#169; Lloyd Strickland 2005. Revised 2022
              <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Importunate;
