import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/remond1716.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>Die philophischen schriften von Gottfried Wilheim Leibniz, vol. III</var><br />
		C. I. Gerhardt (ed)<br />
		pp 677-678<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 19 October 1716<br /><br />
		Translated from the French<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (9k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Nicolas Remond (19 October 1716)</title>
            <meta name="description" content="An English translation of a LETTER TO NICOLAS REMOND by Gottfried Wilhelm Leibniz, from 19 October 1716" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO NICOLAS REMOND</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>G III p677</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Your silence for several weeks has troubled me. I feared for your health and even for that of your brother. In both cases I am delighted to be released from worrying about that. [<b>G III p678</b>] I am impatiently waiting for what Mr De Montmort will like to communicate to me. I have perhaps given occasion in my letter for some clarifications, and for some remarks which I await from him. I have no doubt at all that his letter to Mr Taylor will be excellent.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mr Huet, former bishop of Avranches,<a name="1" href="#note1"><sup>1</sup></a> has knowledge so universal and judgement so penetrating that I believe he will also be able to form a very appropriate opinion about my essay.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It is true that Mr Baluze is well versed in later history. As I have not had the honour of knowing Abb&#233; Longuerue other than by reputation,<a name="2" href="#note2"><sup>2</sup></a> I did not dare to address myself to him; but you will oblige me, Sir, by having recourse to his judgement also. A living historical dictionary is a marvel. There is a Prince in Germany who is almost that, namely the Duke of Saxe Zeiz.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mr Clarke and I have the honour of having our dispute pass through the hands of Madam the Princess of Wales.<a name="3" href="#note3"><sup>3</sup></a> I have sent my fourth reply, and I am waiting for hers, upon which I will settle, because in the last one I am more verbose in order to finish soon. He has almost pretended to be unaware of my <var>Theodicy</var>, and has forced me into repetitions. I have condensed the state of our dispute to this great axiom, that nothing exists or happens without there being a sufficient reason why it be thus rather than otherwise. If he continues to deny me this, where will his sincerity be then? If he grants this to me, goodbye to the void, atoms, and the whole philosophy of Mr Newton. When we have finished, I will not fail to let you know about it, and I hope that Madam the Princess of Wales will give me the permission to do that.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Abb&#233; du Bois so much wanted to be <var>incognito</var> here that I have not dared to interfere to seek the honour of his acquaintance, and the last time he took visitors I was very busy.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I am very obliged to you for having addressed to me a letter for him, which would have served as the means to introduce myself. But as it came too late, I send it back. Moreover, I am sincerely etc.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Hanover, 19 October 1716.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> Pierre Daniel Huet (1630-1721), bishop of Avranches from 1689 to 1699.<br />
                <a name="note2" href="#2">2.</a> Louis Dufour de Longuerue (1652-1733), abb&#233; of Saint-Jean-du-Jard.<br />
                <a name="note3" href="#3">3.</a> Princess Caroline (1683-1737).<br /><br /><br />
                &#169; Lloyd Strickland 2004. Revised 2018
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
