import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/bierling1712.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
    	<var>Die philophischen schriften von Gottfried Wilheim Leibniz, vol. VII</var><br />
		C. I. Gerhardt (ed)<br />
		pp 502-503<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 14 January 1712<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (11k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Friedrich Wilhelm Bierling (14 January 1712)</title>
            <meta name="description" content="An English translation of a LETTER TO FRIEDRICH WILHELM BIERLING by Gottfried Wilhelm Leibniz, from 14 January 1712" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO FRIEDRICH WILHELM BIERLING</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>G VII p502</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;While looking through my letters I saw that I still owed you a response, so I did not want to delay any longer. You have traced light back to the spiritual, and you denied that its resistance could be conceived by itself, which to me is proof of its corporality. But what, I ask, are refractions and reflections if not the effects of resistance? The idea that such things can be spiritual is the invention of certain new authors philosophising rather crassly. [<b>G VII p503</b>]  But active light is nothing more than the throwing of water, except that light is subtler and of a swifter motion. In short, the objection is that matter is not active in itself. What then? It is sufficient to be active once motion is impressed in it. Nor is it doubtful that, once it was created it was stirred into motion, and once a force has been accepted it is always preserved, for no force is destroyed, it is only transferred, scattered, collected. And light no more compels us to have recourse to atoms than to some fluid or other. And monads no more have a shape than do souls, nor are they parts of bodies, but are requisites for them.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;My attempt at theodicy is also rare in the present circumstances, and the copies have soon disappeared, and I am sometimes forced to give my own copies to those who ask, so that now I have virtually none left.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;What the Halenses have judged about this book I have not yet seen, and our bookseller does not have their books, as far as I know. It is accepted by theologians of the three religions of the empire, with greater applause than I was expecting. It only remains to say farewell and think well of me. Hanover 14. January 1712.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;P. S. Thomas Burnett's <var>Sacred Theory of the Earth</var> is ingenious and learned, nor is it to be doubted that the crust of our Earth became established from catastrophes. Therefore this is right in the principal part, but in the hypothesis itself, when you have examined it closely, you will discover many things which do not seem to be able to stand firm.
        <br /><br /><br />
                &#169; Lloyd Strickland 2005<br />
                With gratitude to John Thorley for advice and suggestions
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
