import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/naturegrace.pdf'; 
import { Helmet } from 'react-helmet';

function MalebrancheNotes() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series VI, volume 4</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 2639-2640<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: May 1684 - end 1684 (?)<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (12k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Notes on Malebranche's "Treatise on Nature and Grace"</title>
            <meta name="description" content="An English translation of NOTES ON MALEBRANCHE'S 'TREATISE ON NATURE AND GRACE' by Gottfried Wilhelm Leibniz, from May 1684 - end 1684 (?)" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: NOTES ON MALEBRANCHE'S <var>TREATISE ON NATURE AND GRACE</var></h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A VI 4, p2639</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Father Malebranche, on p.31 of his <var>Treatise on Nature and Grace</var>, says that God could have made a more perfect world than this one, but that he would have had to change the simplicity of his ways to do it.<a name="1" href="#note1"><sup>1</sup></a> But it would be better to say that we do not know the particular ends of all things.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;God did not produce any of his creatures in order to make them unhappy, and those who are excluded from salvation are only excluded by the simplicity of God's laws. Malebranche <var>Treatise on Nature and Grace</var> p.72.<a name="2" href="#note2"><sup>2</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[p.109] When God swears that he does not want the death of the sinner, he means it by a particular will.<a name="3" href="#note3"><sup>3</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Father Malebranche, on p.114 of his <var>Treatise on Nature and Grace</var>, says that the Word in as much as it is the Word, represents the nature of creatures or their essence, but not their existence.<a name="4" href="#note4"><sup>4</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[p.233] Father Malebranche claims that sin may be found where there is no freedom at all. <var>Clarification</var> on book 2, chapter 7.<a name="5" href="#note5"><sup>5</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A theologian said to an acquaintance of mine that God has the right to torment eternally an innocent creature, to which this acquaintance responded very [<b>A VI 4, p2640</b>] simply: is that then the idea you have of God? Truly it is the one I have of the devil.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Father Malebranche says that we are only assured of bodies by faith, as if faith was not rested on the state of the senses.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;One of my friends<a name="6" href="#note6"><sup>6</sup></a> told me that in fact Father Malebranche does not believe that there are bodies.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> Nicolas Malebranche, <var>Treatise on Nature and Grace</var> I.XIV. Leibniz's notes refer to the 1684 edition, published in Rotterdam.<br />
                <a name="note2" href="#2">2.</a> Malebranche, <var>Treatise on Nature and Grace</var> I.XXXVIII addition.<br />
                <a name="note3" href="#3">3.</a> Malebranche, <var>Treatise on Nature and Grace</var> XVI addition.<br />
                <a name="note4" href="#4">4.</a> Malebranche, <var>Treatise on Nature and Grace</var> XVI addition.<br />
                <a name="note5" href="#5">5.</a> Nicolas Malebranche, <var>De la recherche de la v&#233;rit&#233;. Tome troisi&#232;me. Contenant plusieurs Eclaircissemens sur les principales difficultez des pr&#233;c&#233;dens volumes</var> (Paris, 1678).<br />
                <a name="note6" href="#6">6.</a> Possibly Simon Foucher.
        <br /><br />
                &#169; Lloyd Strickland 2005
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default MalebrancheNotes;
