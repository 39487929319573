import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/theodicy.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
		Shelfmark LH 1, 1, Bl. 17<br /><br />
		Parts of this text can also be found in Gr pp 495-498, though note that Grua mixes in parts of another draft<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: Draft - early 1707?<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (106k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Draft Preface to the "Theodicy"</title>
            <meta name="description" content="An English translation of a DRAFT PREFACE TO THE 'THEODICY' by Gottfried Wilhelm Leibniz, from early 1707 (?)" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: DRAFT PREFACE TO THE <var>THEODICY</var></h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>LH 1, 1, Bl. 17r</b>]<br /><br />
                <table>
                <tr>
                <td width="45%"><center>Theodicy<br />
        or apology for our notions of God's attributes on the occasion of<a name="1" href="#note1"><sup>1</sup></a> Mr Bayle's last writings</center></td>
                <td width="10%">&nbsp;</td>
                <td><center>Theodicy<br />
        or apology for God's justice<a name="2" href="#note2"><sup>2</sup></a> by means of the notions he has given us of it</center>
                </td>
                </tr>
                </table>
        <br clear="all" />
        We have just been deprived of one of the most learned and ingenious authors of our times,<a name="3" href="#note3"><sup>3</sup></a> the loss of whom I regret all the more since I had benefited from his insights when conferring with him about the system of pre-established harmony, and since I hoped to benefit from them further in equally difficult and important matters, which I have tried to go further into for a number of years, and which he had started to examine with a great deal of care since he had been attacked by persons of reputation. I had written a <var>system of freedom and</var><a name="4" href="#note4"><sup>4</sup></a> <var>related matters</var>, the form of which will possibly have something new, but the materials of which are old, and I would have been delighted to submit it to the judgement of this excellent man as well as to that of those in dispute with him. He has just been taken from us, but as the matter is on the table, and as able people are still working on it and the public is still interested in it, I thought the occasion should be used to publish thoughts whose principal aim is such knowledge of God as is required to stimulate piety and nourish virtue. I hope that these thoughts will also serve to illuminate the thorny questions which have troubled people for a long time regarding the conformity of faith with reason and the usage of philosophy in theology. I am using the title <var>Theodicy</var> since the justice of God is the principal subject of this work, wherein the questions of his goodness and his holiness naturally enter. I intend to show that we have good and true notions of these attributes of God,<a name="5" href="#note5"><sup>5</sup></a> without which we would have no grounds to recognize them in him and to praise him for them.<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I know there are several distinguished theologians and philosophers who think we have no idea of divine goodness and justice. <var>Sunt superis sua jura</var>, said the poet.<a name="6" href="#note6"><sup>6</sup></a> With this view they respond to the awkward difficulties on evil, sin, the sufferings of good men, the prosperity of bad ones, and predestination, as if God's greatness and independence placed him above what we call justice and reason.<br /><br />

        [<b>LH 1, 1 Bl. 17v</b>]<br /><br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Those who use what they call strict methods with regard to grace sometimes speak in that way, and Mr Bayle<a name="7" href="#note7"><sup>7</sup></a> seems well-disposed towards them. I have always thought that this is to cut the Gordian Knot rather than to unravel it.<a name="8" href="#note8"><sup>8</sup></a> It even seems that it would follow that we should only fear God, and that there is no way to love him if the perfections which can make him loveable are absolutely unknown to us. If his power and greatness remain, and if the goodness, justice and wisdom of his government mean nothing, how will we be able to have grounds to attribute them to him if we do not even have any idea of them? I believe those who take a different view are very well intentioned, and I do not accuse these authors of distancing men from the love of God. There would be grounds to fear him if they acted in that way, but fortunately their speculative principles do not turn into practice. Mr Jurieu himself declares that, when in the pulpit, we should approach those whom he calls Pelagians. The supralapsarians write books of devotion that are as edifying as those of others. By looking at things in a certain way I even find something great, lofty, and worthy of God in these authors who appear so strict, and I have always had a liking for those who have asserted the sovereign independence of the divine nature. It is very certain that God depends only upon himself, but his will is ruled by his understanding, and his power by his wisdom, and this independence does not make God independent of the sovereign reason which is himself.<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Scarcely had I learned to understand the Latin authors when I began leafing through not only the historians and the poets but also the books on meditation, and I was especially charmed by the work of Laurentius Valla against Bo&#235;thius,<a name="9" href="#note9"><sup>9</sup></a> and by that of Luther against Erasmus,<a name="10" href="#note10"><sup>10</sup></a> which was the most profound one he ever wrote - it is true that these two books have need of mitigation. And I have also carefully read the ingenious book by the celebrated Hobbes against Bishop Bramhall,<a name="11" href="#note11"><sup>11</sup></a> which appeared only in English; not to mention many other authors who have set themselves apart by exalting God's right over creatures so far as to say that he has the right to damn innocents. It is therefore not without knowledge of the cause, nor by any prejudice or passion, that I have believed another position had to be taken. It seemed to me that these rigid dogmas were not always founded in reason, and even less in revelation, and that they could be harmful if the practice of those who follow them became too much in keeping with the theory.<a name="12" href="#note12"><sup>12</sup></a>
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> of | the last disputes between Mr Bayle and some other very able people | <var>deleted</var>.<br />
                <a name="note2" href="#2">2.</a> justice | and related attributes | <var>deleted</var>.<br />
                <a name="note3" href="#3">3.</a> times | . This is Mr Bayle, who died recently in Rotterdam. | <var>deleted</var>.<br />
                <a name="note4" href="#4">4.</a> <var>and</var> | <var>grace</var> | <var>deleted</var>.<br />
                <a name="note5" href="#5">5.</a> God, | or, which is the same thing, that we are right to attribute justice and goodness to him, which would be unfounded if these words meant nothing at all when applied to God. | <var>deleted</var>.<br />
                <a name="note6" href="#6">6.</a> 'The gods have their own laws.' Ovid, <var>Metamorpheses</var> X.83.<br />
                <a name="note7" href="#7">7.</a> Bayle | has declared himself in favour of them. | <var>deleted</var>.<br />
                <a name="note8" href="#8">8.</a> it. | But the worst thing is | <var>deleted</var>.<br />
                <a name="note9" href="#9">9.</a> Laurentius Valla, <var>Elegantiae linguae Latinae</var> (Venice, 1471).<br />
                <a name="note10" href="#10">10.</a> Martin Luther, <var>De servo arbitrio</var> (Wittenberg, 1525).<br />
                <a name="note11" href="#11">11.</a> Thomas Hobbes, <var>The Questions Concerning Liberty, Necessity, And Chance. Clearly Stated and Debated Between D. Brahmall Bishop of Derry, and Thomas Hobbes of Malmesbury</var> (1656).<br />
                <a name="note12" href="#12">12.</a> theory. | I have nevertheless benefited from the profound meditations of these authors, whose views are not in any way mine, and I have found that one can preserve much of them by accommodating them to common notions, and that one can humanize what is true in their austerity. | <var>deleted</var>.<br /><br /><br />
                &#169; Lloyd Strickland 2003. Revised 2016
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
