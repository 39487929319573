import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/bignon1703.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>S&#228;mtliche schriften und briefe series I, volume 22</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 332-333<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 7 April 1703<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (166k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Jean-Paul Bignon (7 April 1703)</title>
            <meta name="description" content="An English translation of a LETTER TO JEAN-PAUL BIGNON by Gottfried Wilhelm Leibniz, from 7 April 1703" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO JEAN-PAUL BIGNON</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A I 22, 332</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sir<span style={{float: 'right'}}>Berlin, 7 April 1703</span><br /><br />

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I have waited for a good reason to write to you so as not to abuse the honour you have done me by responding. A letter from China provided me with it.<a name="1" href="#note1"><sup>1</sup></a> I communicated to Reverend Father Bouvet my way of calculating by 0 and 1.<a name="2" href="#note2"><sup>2</sup></a> And he responded by saying that he discovered straightaway that it is precisely the meaning of the figures of Fuxi, Chinese king and philosopher thought to have lived more than 4,000 years ago.<a name="3" href="#note3"><sup>3</sup></a> It is the oldest known monument of science; the Chinese having lost its true explanation and having offered a great deal of chimerical ones, and yet now here we are, its deciphering by means of Europeans. This confluence is curious and could be of consequence for that country.
                    I have put it all in the attached paper to be inserted in the <var>Memoires</var> of the Academy that are printed from time to time,<a name="4" href="#note4"><sup>4</sup></a> if it is deemed appropriate. This is instead of the earlier paper I sent about this same arithmetic, which is not so appropriate to be printed.<a name="5" href="#note5"><sup>5</sup></a><br /><br />

                [<b>A I 22, 333</b>]<br /><br />

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I am with respect<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sir<span style={{float: 'right'}}>your L.</span>
                        <br /><br /><br />
                        <hr className='rightcol-hr' />
                        <br /><br />
                        NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> Namely, a letter from Joachim Bouvet to Leibniz of 4 November 1701 (see below, note 3).<br />
                        <a name="note2" href="#2">2.</a> See Leibniz's letter to Bouvet of 15 February 1701; A I 19, 401-415; English translation: Lloyd Strickland and Harry Lewis, <var>Leibniz on Binary: The Invention of Computer Arithmetic</var> (Cambridge, Mass.: MIT Press, 2022), 126-134.<br /> 		
                        <a name="note3" href="#3">3.</a> Bouvet had suggested - erroneously as it happens - that Leibniz's discovery of binary arithmetic was the key to understanding the mysterious hexagrams of the Yijing. See Bouvet's letter to Leibniz of 4 November 1701, A I 20, 533-555; English translation: Lloyd Strickland and Harry Lewis, <var>Leibniz on Binary: The Invention of Computer Arithmetic</var> (Cambridge, Mass.: MIT Press, 2022), 163-175.<br />
                        <a name="note4" href="#4">4.</a> This is Leibniz's &quot;Explanation of binary arithmetic,&quot; published in <var>Memoires de l'Academie Royale des Sciences</var>. English translation available <Link to="/binary" target="_blank">here</Link>.<br />
                        <a name="note5" href="#5">5.</a> Leibniz is referring here to his &quot;Essay d'une nouvelle science des nombres&quot; [Essay on a New Science of Numbers] (26 February 1701). See Hans J. Zacher, <var>Die Hauptschriften zur Dyadik von G. W. Leibniz</var> (Frankfurt: Klostermann, 1973), 250-261; English translation: Lloyd Strickland and Harry Lewis, <var>Leibniz on Binary: The Invention of Computer Arithmetic</var> (Cambridge, Mass.: MIT Press, 2022), 138-144.<br /><br /><br />
                        &#169; Lloyd Strickland 2020<br />
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
