import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import image from '../images/lateral.jpg';
import { Helmet } from 'react-helmet';

function Lateral() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        <a href="https://olympiapublishers.com/books/100-awesome-lateral-thinking-puzzles" target="_blank">Order from the publisher</a><br /><br />
        <a href="https://www.amazon.co.uk/100-Awesome-Lateral-Thinking-Puzzles/dp/1800747233/" target="_blank">Order from Amazon (UK)</a><br /><br />
      </div>
    );
  }
  
  return (
    <div>
      <Helmet>
            <title>100 Awesome Lateral Thinking Puzzles</title>
            <meta name="description" content="A book authored by Lloyd Strickland on '100 Awesome Lateral Thinking Puzzles'" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>100 AWESOME LATERAL THINKING PUZZLES</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}
                   
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                  <img src={image} alt="100 Awesome Lateral Thinking Puzzles" style={{ float: 'left', marginRight: '20px', height: isMobile ? '220px' : '364px', width: 'auto' }} />
                  <p style={{ margin: '10px 0px 0px 0px', fontSize: '13px' }}>Published 30 June 2022, by Olympia Books.</p><br></br>
                  <p>From the back cover blurb:</p>
                  <p>Leave logic at the door and prepare to test your creative thinking skills with these 100 brand new lateral thinking puzzles.</p>
                  <p>Each puzzle describes a situation which at first sounds odd or unlikely, and you have to work your way to the answer using creativity, imagination, and intuition.</p>
                  <p>To cater for all ability levels, the 100 puzzles are arranged into Easy, Moderate, and Difficult categories, and a series of hints is provided for each puzzle to help you along if you get stuck.</p>
                  <p>As lateral thinking puzzles are fun, engaging, and make good icebreakers, they are a perennial favourite at dinner parties, break times, and social evenings.</p> 
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Lateral;
