import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/consciousness.pdf'; 
import { Helmet } from 'react-helmet';

function Consciousness() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Sources:<br /><br />
		<var>Die Leibniz-Handschriften der K&#246;niglichen &#214;ffentlichen Bibliothek</var><br />
        Eduard Bodemann (ed)<br />
        pp 58-59<br /><br />
		<var>S&#228;mtliche schriften und briefe series VI volume 4</var><br />
        Deutsche Akademie der Wissenschaften (ed)<br />
        pp 1473-1474<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 1683 - 1685?<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (9k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>On consciousness and memory</title>
            <meta name="description" content="An English translation of ON CONSCIOUSNESS AND MEMORY by Gottfried Wilhelm Leibniz, from 1683 - 1685?" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: ON CONSCIOUSNESS AND MEMORY</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>BH p58</b>] [<b>A VI 4, p1473</b>]<br /><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Consciousness is memory of our actions. Descartes therefore holds that there can be no trust in a demonstration, because every demonstration requires memory of preceding propositions, in which it could be the case that we were perhaps deceived by the power of some evil genius. But if we prolong the pretext of doubting to this point, we cannot even trust our consciousness of the present, because memory is always involved, since nothing exists, absolutely speaking, except for the present moment.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Writings or notes assist the memory with regard to demonstrating, and nothing better confirms that there is no evil genius who should deceive us into falsifying even those things than success or a successful outcome, into which the confidence of all our certainty is ultimately resolved; [<b>BH p59</b>] otherwise a clear dream cannot be definitely distinguished from wakefulness.<br /><br />

                [<b>A VI 4, p1474</b>]<br />
                <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Consciousness of vision, of appearance, of pleasure and of pain is given; consciousness of truth, of certainty, of justice or of all those things which must be deduced, is not given, and therefore one cannot be certain about them.
                <br /><br /><br />
		
                &#169; Lloyd Strickland 2005<br />
                With gratitude to John Thorley for advice and suggestions
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Consciousness;
