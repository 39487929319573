import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/perception.pdf'; 
import { Helmet } from 'react-helmet';

function DistinctionPerception() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series VI volume 4</var><br />
        Deutsche Akademie der Wissenschaften (ed)<br />
        p 58<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: October 1677 - December 1678 (?)<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (73k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>On Distinct Perception</title>
            <meta name="description" content="An English translation of ON DISTINCT PERCEPTION by Gottfried Wilhelm Leibniz, from October 1677 - December 1678 (?)" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: ON DISTINCT PERCEPTION</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A VI 4, 58</b>]<br /><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>We perceive distinctly</var> that whose parts or attributes we perceive as pertaining to it, for example, when a person is before us, we perceive their face, and at the same time we think that the face pertains to this person. Otherwise, when we cast our eyes into a crowd, we perceive individual people and the faces of the individuals turned toward us, but we do so confusedly. And when we hear the sound of rushing water from afar, we hear the noise of a great many waves, for there is no reason why we should hear the sound of one rather than that of another; and if we did not hear the sound of any, we would not hear anything; but this perception is confused.<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Therefore, a distinct perception occurs when we attribute to things something similar to ourselves, for we know that we are a subject of various attributes, and so in a similar way we consider objects as certain substances or things. And a distinct perception is that which occurs together with some judgement without affirmation and negation. <var>Thought</var> is distinct imagination.

                <br /><br /><br />
                &#169; Lloyd Strickland 2007. Revised 2022
              <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default DistinctionPerception;
