import React, { useEffect } from 'react';

function TwitterFollowButton() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://platform.twitter.com/widgets.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <a className="twitter-follow-button" href="https://twitter.com/Dr_L_Strickland" data-size="large">Follow @Dr_L_Strickland</a>
  );
}

export default TwitterFollowButton;
