import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/dissipation.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
        Shelfmark LH 4, 6, 12f Bl. 18<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: mid-January 1687 (?)<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (74k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>On Defending Against Dispersing of the Soul</title>
            <meta name="description" content="An English translation of ON DEFENDING AGAINST DISPERSING OF THE SOUL by Gottfried Wilhelm Leibniz, from mid-Janary 1687 (?)" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: ON DEFENDING AGAINST DISPERSING OF THE SOUL</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 <small>Although dated by the Academy editors to winter 1685/1686 (see A VI 4, 2724), this short text is written on the back of an as-yet unpublished draft of a letter from Leibniz to Johann Heinrich Koch, probably written mid-January 1687. Another draft of Leibniz's letter is printed in A I 4, 609.</small><br /><br /><br />
                 [<b>LH 4, 6, 12f Bl. 18</b>]<br /><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;When our soul is brought to a crossroads of thinking it is always inclined to that side where the greater objective reality is presented; but this can be detrimental to its perfection and happiness if those realities are numerous and small. This can be called <var>dispersing of the soul</var>; it happens when we are stupefied and is similar to a body that has lost its motion as a result of hitting something soft, where the impetus is dispersed through innumerable parts of the body. And so we must defend ourselves against this dispersing of the soul by paying frequent attention to our goal, to certain general rules of truths, and to the laws of life once prescribed and made familiar to us by repeated practice of thinking and acting.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It must be seen, however, whether and how it can happen that there is more or less objective reality in our mind.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;There is also stupefaction of the soul when we very often double back on ourselves by thinking in a tight circle and so seem to think about the same thing for a long time.
                <br /><br /><br />
                &#169; Lloyd Strickland 2014. Revised 2023
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
