import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/landgrave1686.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
       Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series II, volume 2</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 3-4<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 1/11 February 1686<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (99k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Landgrave Ernst von Hessen-Rheinfels (1/11 February 1686)</title>
            <meta name="description" content="An English translation of a LETTER TO LANDGRAVE ERNST VON HESSEN-RHEINFELS by Gottfried Wilhelm Leibniz, from 1/11 February 1686" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO LANDGRAVE ERNST VON HESSEN-RHEINFELS</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A II 1, p3</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Extract from my letter to Monsignor Landgrave Ernst <span style={{float: "right"}}>1/11 February 1686</span><br />
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Having been somewhere where I had nothing to do for several days, I have recently written a short discourse on metaphysics,<a name="1" href="#note1"><sup>1</sup></a> on which I would be delighted to have Mr Arnauld's opinion. For questions about grace, God's concurrence with creatures, the nature of [<b>A II 2, p4</b>] miracles, the cause of sin and the origin of evil, the immortality of the soul, ideas etc., are there handled in a way that seems to give new openings suitable for clarifying very great difficulties. I have attached here the summary of the articles it contains,<a name="2" href="#note2"><sup>2</sup></a> for I have not yet been able to get a fair copy made.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I therefore beg Your Serene Highness to have this summary sent to him and to persuade him to consider it a little and give his opinion. For as he excels equally in theology and philosophy, in reading and meditation, I can think of no-one more suited to judge it than him. And I would very much like a critic as exact, as enlightened, and as reasonable as Mr Arnauld is, being as I am the world's most disposed man to give way to reason. Perhaps Mr Arnauld will find these few things not altogether unworthy of his consideration, especially since he has been somewhat occupied with examining these matters. If he finds some obscurity, I will explain myself sincerely and openly, and finally, if he finds me worthy of his instruction, I will see to it that he has grounds not to be dissatisfied.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I beg Your Serene Highness to attach this letter to the summary I am sending him, and to send both to Mr Arnauld.
                        <br /><br /><br />
                        <hr className='rightcol-hr' />
                        <br /><br />
                        NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> This remark has led to the text in question (which Leibniz left untitled) henceforth be known as the &quot;Discourse on Metaphysics&quot;; A VI 4, 1529-1588/L 303-330.<br />
                        <a name="note2" href="#2">2.</a> That is, the titles Leibniz gave to each of the text's 37 sections.<br />
                <br /><br />
                        &#169; Lloyd Strickland 2018
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
