import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import image from '../images/binary-normal.jpg';
import pdf from '../pdfs/flyerbinary.pdf'; 
import { Helmet } from 'react-helmet';

function LeibnizOnBinary() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function scrollToElement(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        <a href="https://mitpress.mit.edu/books/leibniz-binary" target="_blank">Order from the publisher (MIT Press)</a><br /><br />
        <a href="https://www.amazon.com/Leibniz-Binary-Invention-Computer-Arithmetic/dp/0262544342/" target="_blank">Order from Amazon (US)</a><br /><br />
        <a href="https://www.amazon.co.uk/Leibniz-Binary-Invention-Computer-Arithmetic/dp/0262544342" target="_blank">Order from Amazon (UK)</a><br /><br />
        <a href="https://www.academia.edu/88088074/Leibniz_on_Binary_The_Invention_of_Computer_Arithmetic" target="_blank">Download a sample of the book (the front matter)</a><br /><br />
        <Link to={pdf} target="_blank">Download the flyer for the book</Link><br></br><br></br>
        <a href="https://journals.uvic.ca/index.php/pir/article/view/21242" target="_blank">Review by Katalin Bimbo in <var>Philosophy in Review</var></a> (click the "PDF" button to read)<br /><br />
        <a href="https://dl.acm.org/doi/10.1145/3623800.3623802" target="_blank">Review by Wiliam Gasarch in <var>ACM SIGACT News</var>, Sept. 2023</a> (behind paywall)<br /><br />
      </div>
    );
  }

  function endorsements() {
    return (
      <>
      <p>Endorsements:</p>
      <p>"This book is a model of how the history of computer science and mathematics should be written. Leibniz pointed out the importance of putting ourselves into the place of others, and here we get to put ourselves into the shoes of Leibniz himself, as we're treated to dozens of his private notes, carefully translated into idiomatic English and thoroughly explained." <br></br>- Donald E Knuth, <var>Professor Emeritus of The Art of Computer Programming, Stanford University</var></p><br></br>
      <p>"A wonderful book that explains both the significance and scope of Leibniz' binary arithmetic using original sources. The authors' clear exposition makes the genesis of binary arithmetic accessible to everyone." <br></br>- Bharath Sriraman, <var>Professor of Mathematics, University of Montana - Missoula; Editor of The Handbook of the Mathematics of the Arts and Sciences, Springer Nature</var></p><br></br>
      <p>"Strickland and Lewis present Leibniz's development of binary through lovingly typeset translations of his papers, notes and letters, together with a contextual narrative that is both well-researched and quite enjoyable." <br></br>- Simson Garfinkel, <var>co-author of The Computer Book: From the Abacus to Artificial Intelligence, 250 Milestones in the History of Computing</var></p><br></br>
      <p>"Leibniz on Binary enhances our understanding of how binary arithmetic was developed and sheds light on the intellectual workings of one of the inventors of the modern age." <br></br>- Jim Waldo, <var>Gordon McKay Professor of the Practice of Computer Science and CTO, John A. Paulson School of Engineering and Applied Sciences, Harvard University</var></p><br></br>
      <p>"A fascinating read for anyone interested in how rationality combined with religious passion. This eminently readable translation highlights bold connections of newly invented binary algorithms with mechanization of thought, Chinese hexagrams, and creation out of nothing." <br></br>- Slava Gerovitch, <var>author of From Newspeak to Cyberspeak: A History of Soviet Cybernetics</var></p>
      </>
    );
  }
  
  return (
    <div>
       <Helmet>
            <title>Leibniz on Binary: The Invention of Computer Arithmetic</title>
            <meta name="description" content="A book authored by Lloyd Strickland and Harry Lewis on 'Leibniz on Binary: The Invention of Computer Arithmetic'" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()} <hr className="centered-hr" />
                    {endorsements()}
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ ON BINARY: THE INVENTION OF COMPUTER ARITHMETIC</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}
                    <p>View endorsements <span style={{color: 'blue'}} onClick={() => scrollToElement('endorsements')}>here</span></p>
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                  <img src={image} alt="Leibnizon Binary: The Invention of Computer Arithmetic" style={{ float: 'left', marginRight: '20px', height: isMobile ? '220px' : '364px', width: 'auto' }} />
                  <p style={{ margin: '10px 0px 0px 0px', fontSize: '13px' }}>Published 15 November 2022, by MIT Press.</p>
                  <p style={{ fontSize: '13px' }}>Co-authored with Harry Lewis.</p>
                  <p>From the back cover blurb:</p>
                  The polymath Gottfried Wilhelm Leibniz (1646–1716) is known for his independent invention of the calculus in 1675. Another major—although less studied—mathematical contribution by Leibniz is his invention of binary arithmetic, the representational basis for today's digital computing. This book offers the first collection of Leibniz’s most important writings on the binary system, all newly translated by the authors with many previously unpublished in any language. Taken together, these thirty-two texts tell the story of binary as Leibniz conceived it, from his first youthful writings on the subject to the
                  mature development and publication of the binary system.<br /><br />
                  As befits a scholarly edition, Strickland and Lewis have not only returned to Leibniz's original manuscripts in preparing their translations, but also provided full critical apparatus. In addition to extensive annotations, each text is accompanied by a detailed introductory &quot;headnote&quot; that explains the context and content. Additional mathematical commentaries offer readers deep dives into Leibniz's mathematical thinking. The texts are prefaced by a lengthy and detailed introductory essay, in which Strickland and Lewis trace Leibniz’s development of binary, place it in its historical context, and chart its posthumous influence, most notably on shaping our own computer age.<br />
                  <br clear = "all" />
                  <b>CONTENTS</b><br /><br />
                  List of Figures<br />
                  Abbreviations<br />
                  Preface<br />
                  Acknowledgments<br />
                  Introduction<br /><br />
                  <ol><li>Notes on Algebra, Arithmetic, and Geometric Series (October 1674)</li>
                  <li>The Series of All Numbers, and on Binary Progression (before 15/25 March 1679)</li>
                  <li>Binary Progression (before 15/25 March 1679) </li>
                  <li>Geometric Progressions and Positional Notation (before 15/25 March 1679)</li>
                  <li>Binary Arithmetic Machine (before 15/25 March 1679)</li>
                  <li>On the Binary Progression (15/25 March 1679)</li>
                  <li>Attempted Expression of the Circle in Binary Progression (c. 1679)</li>
                  <li>Sedecimal Progression (1679)</li>
                  <li>Binary Progression Is for Theory, Sedecimal for Practice (c. 1679)</li>
                  <li>On the Organon or Great Art of Thinking (first half [?] of 1679)</li>
                  <li>Binary Ancestral Calculations (early 1680s [?])</li>
                  <li>Sedecimal on an Envelope (c. 1682–1685)</li>
                  <li>Remarks on Weigel (1694–mid-March 1695)</li>
                  <li>Leibniz to Duke Rudolph August (7/17–8/18 May 1696)</li>
                  <li>A Wonderful Expression of All Numbers by 1 and 0 Representing the Origin of Things from God and Nothing, or the Mystery of Creation (7/17 May 1696)</li>
                  <li>Wonderful Origin of All Numbers from 1 and 0, Which Serves as a Beautiful Representation of the Mystery of Creation, since Everything Arises from God and Nothing Else (8/18 May 1696)</li>
                  <li>Leibniz to Duke Rudolph August (2/12 January 1697)</li>
                  <li>Duke Rudolph August to Johann Urban Müller (5/15 January 1697)</li>
                  <li>Leibniz to Claudio Filippo Grimaldi (mid-January–early February 1697)</li>
                  <li>Periods (May 1698–first half of January 1701)</li>
                  <li>Leibniz to Philippe Naudé (15 January 1701)</li>
                  <li>Leibniz to Joachim Bouvet (15 February 1701)</li>
                  <li>Essay on a New Science of Numbers (26 February 1701)</li>
                  <li>Binary Addition (spring–summer 1701 [?])</li>
                  <li>Periods in Binary (spring–fall 1701)</li>
                  <li>Periods and Powers (mid-to-late June 1701 [?])</li>
                  <li>Demonstration That Columns of Sequences Exhibiting Powers of Arithmetic Progressions, or Numbers Composed from These, Are Periodic (November 1701)</li>
                  <li>Joachim Bouvet to Leibniz (4 November 1701)</li>
                  <li>Leibniz to Bouvet (early April [?] 1703)</li>
                  <li>Explanation of Binary Arithmetic, Which Uses Only the Digits 0 and 1, with Some Remarks on Its Usefulness, and on the Light It Throws on the Ancient Chinese Figures of Fuxi (7 April 1703)</li>
                  <li>Leibniz to César Caze (23 June 1705)</li>
                  <li>On Binary (late June 1705)</li></ol>
                  Bibliography<br />
                  Index
                  <br></br><br></br>
                  {isMobile ? (
                    <div id="endorsements">
                      <hr></hr><p style={{paddingTop: '10px'}}>Go back to the top of the page <span style={{color: 'blue'}} onClick={() => scrollToElement('top')}>here</span></p>
                      {endorsements()}
                    </div>
                  ) : (
                    <>
                    </>
                  )}
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default LeibnizOnBinary;
