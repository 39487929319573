import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/remond-dec1715.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>Opera Omnia volume 3</var><br />
		Ludovic Dutens (ed)<br />
		pp 448-449<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 6 December 1715<br /><br />
		Translated from the French<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (176k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Nicolas Remond (6 December 1715)</title>
            <meta name="description" content="An English translation of a LETTER TO NICOLAS REMOND by Gottfried Wilhelm Leibniz, from 6 December 1715" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO NICOLAS REMOND</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>D III 448</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You will have received through Mr Hullin my Latin treatise on the origin of the French,<a name="1" href="#note1"><sup>1</sup></a> and by post my remarks on the refuter of Father Malebranche,<a name="2" href="#note2"><sup>2</sup></a> and finally my very long letter to Abb&#233; Conti,<a name="3" href="#note3"><sup>3</sup></a> which I sent to you by the last courier. I find something that I beg you, Sir, to add to it when sending it to him.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I forgot to name two able men I believe to be in London; they deserve to be known and are both friends of mine: Mr Sloane, who has an excellent cabinet and has long held the office of secretary of the Royal Society, and Mr Woodward, who has done very good research on changes of the globe.<a name="4" href="#note4"><sup>4</sup></a> Perhaps Abb&#233; Conti has already made their acquaintance.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Princess of Wales indicated to me in a letter I had the honour to receive that she would be very glad if my <var>Theodicy</var> was translated into English. But those to whom she spoke about it make it difficult and referred the matter to people partial to Mr. Newton.<a name="5" href="#note5"><sup>5</sup></a> There are doubtless enough other people capable of such a translation: I do not know whether the Frenchman Mr de la Roche (who has written the <var>Memoirs of Literature</var> in English) writes English well enough in the judgement of connoisseurs for the task to be entrusted to him.<a name="6" href="#note6"><sup>6</sup></a> If he does, I think he would be the man to [<b>D III, 449</b>] take it on; if not, I imagine we would find enough others. The able Mr Wotton, who once wrote in English impartially, learnedly and with moderation on the ancients and moderns, and on the progress of the sciences, would be quite capable of it, if he could be persuaded to do it, since I know he doesn't scorn my opinions.<a name="7" href="#note7"><sup>7</sup></a> But ultimately, if someone knew that they would please His Royal Highness by making this translation, I think they would be delighted to undertake it.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If Abb&#233; Conti is not yet known to the Princess of Wales, and if he wishes this honour, it would be enough for him to refer to me. He could be introduced near her, or through his compatriot, Mr Querini, or through that of Madam la Countess de la Lippe-Buckeburg, who is Countess of the Empire, much loved by Madam the Princess, for she has much merit, and she is also good-hearted towards me.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Something can be added to my long postscript to Abb&#233; Conti.<a name="8" href="#note8"><sup>8</sup></a> After these words &quot;will sufficiently attest&quot;, which will be towards the end of the first page of this postscript or scarcely far from the beginning of the second, can be added, &quot;It was only in France that I came to it [sc. advanced geometry], and Mr Huygens gave me access.&quot;<a name="9" href="#note9"><sup>9</sup></a> And at the end of the first paragraph, on the second page after these words: &quot;cause a division between him and me&quot;,<a name="10" href="#note10"><sup>10</sup></a> can be added, &quot;The Royal Society did not let me know that it wanted to examine the matter, so I have not been heard; and if I had been told the names of those who had been appointed as commissioners, I would have been able to speak plainly if I challenged some and wanted others. This is why, the essential formalities not having been observed, the Society declared that it does not claim to have judged definitively between Mr Newton and myself.&quot; I am etc.
                        <br /><br /><br />
                        <hr className='rightcol-hr' />
                        <br /><br />
                        NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> Namely Leibniz's <var>De origine francorum disquisitio</var> (Hanover: Foerster, 1715).<br />
                        <a name="note2" href="#2">2.</a> Namely Leibniz's letter to Remond of 4 November 1715. See G III, 656-660. English translation available <Link to="/remond1715" target="_blank">here</Link>.<br />
                        <a name="note3" href="#3">3.</a> Namely Leibniz's letter to Conti of 6 December 1715. See <var>Recueil de diverses Pieces sur la Philosophie, la Religion naturelle, l'Histoire, les Mathematiques, &amp;c. par MM. Leibniz, Clarke, Newton, &amp; autres Auteurs c&#233;l&#232;bres</var>, 2 vols. (Amsterdam: Duvillard & Changuion. 1720), II: 337-340 and 3-11.<br />
                        <a name="note4" href="#4">4.</a> Hans Sloane (1660-1753) and John Woodward (1665-1728).<br />
                        <a name="note5" href="#5">5.</a> See Caroline's letter to Leibniz of 3/14 November 1715, in <var>Correspondenz von Leibniz mit Caroline</var>, ed. Onno Klopp (Hildesheim: Olms, 1973), 50.<br />
                        <a name="note6" href="#6">6.</a> Leibniz is referring here to Michel de la Roche (1680-1742), a French Huguenot who settled in England at a young age and became an Anglican in 1701. He went on to make his name as editor of <var>Memoirs of Literature</var>, which ran from March 1710 to September 1714, and then again between January and April 1717.<br />
                        <a name="note7" href="#7">7.</a> Leibniz is referring here to William Wotton (1666-1727), an English theologian and classical scholar renowned for his <var>Reflections upon Ancient and Modern Learning</var> (London: J. Leake, 1694).<br />
                        <a name="note8" href="#8">8.</a> Namely, Leibniz's long &quot;Apostille&quot; [postscript] to his letter to Conti of 6 December 1715. See <var>Recueil de diverses Pieces</var>, II: 3-11.<br />
                        <a name="note9" href="#9">9.</a> The original sentence in Leibniz's postscript is as follows: &quot;I made Mr Collins' acquaintance on my second voyage from England because on the first (which was very short, since I had come with a public minister) I did not even have the slightest knowledge of advanced geometry, and had neither seen nor heard anything of Mr Collins' communication with Mr Gregory and Mr Newton, as the letters I exchanged with Mr Oldenburg at that time, and sometime after, will sufficiently attest.&quot; <var>Recueil de diverses Pieces</var>, II: 5.<br />
                        <a name="note10" href="#10">10.</a> The original sentence in Leibniz's postscript is as follows: &quot;I am displeased that a man as able as Mr Newton has incurred the criticism of intelligent people by deferring too much to the suggestions of certain flatterers, who wanted to cause a division between him and me.&quot; <var>Recueil de diverses Pieces</var>, II: 5.<br /><br /><br />
                        &#169; Lloyd Strickland 2019
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
