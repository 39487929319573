import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/cessation.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series VI volume 4</var><br />
        Deutsche Akademie der Wissenschaften (ed)<br />
        p 1643<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: March 1689 - March 1690 (?)<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (145k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>On the Cessation of Distinct Thoughts Without the Extinction of the Soul</title>
            <meta name="description" content="An English translation of ON THE CESSATION OF DISTINCT THOUGHTS WITHOUT THE EXTINCTION OF THE SOUL by Gottfried Wilhelm Leibniz, from March 1689 - March 1690 (?)" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: ON THE CESSATION OF DISTINCT THOUGHTS WITHOUT THE EXTINCTION OF THE SOUL</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A VI 4, 1643</b>]<br /><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;One who understands my demonstrations about the nature of substance will readily conclude that a soul cannot be extinguished, and will correctly perceive, as I have explained elsewhere, that distinct thoughts (which one can remember) can cease without any extinction of the soul, whenever its perceptions are manifold and in equilibrium, so that one perception does not draw the mind's attention to it much more than another.
                <br /><br /><br />
                &#169; Lloyd Strickland 2007. Revised 2024
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
