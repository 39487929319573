import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Image from '../images/slt-small.jpg';
import { Helmet } from 'react-helmet';

function Slt() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function scrollToElement(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        <a href="https://www.bloomsbury.com/uk/shorter-leibniz-texts-9780826489517/" target="_blank">Order from the publisher</a><br /><br />
        <a href="http://www.amazon.com/gp/product/0826489516/sr=8-2/qid=1154294533/ref=sr_1_2/103-3801727-6052643?ie=UTF8" target="_blank">Order from Amazon (US)</a><br /><br />
        <a href="https://www.amazon.co.uk/Shorter-Leibniz-Texts-Collection-Translations/dp/0826489508/ref=sr_1_1?crid=14IAXM3LE7WBV&dib=eyJ2IjoiMSJ9.x9w9pftOlm5YvnGxJj3v3A.VTXws2WdCC3KONWPeweNXZkfzA7B3vO_p6wJukacEug&dib_tag=se&keywords=shorter+leibniz+texts&qid=1718112638&s=books&sprefix=shorter+leibniz+texts%2Cstripbooks%2C69&sr=1-1" target="_blank">Order from Amazon (UK)</a><br /><br />
      </div>
    );
  }

  function endorsements() {
    return (
      <>
      Testimonials:<br /><br />
	    &quot;Since no one or two of Leibniz's works capture the breadth of his thought, Strickland's anthology is a happy solution and fills a neglected niche in hitherto published anthologies.&quot;<br />
        &nbsp;&nbsp;- Daniel J. Cook, <var>Professor Emeritus of Philosophy, Brooklyn College, CUNY</var><br /><br />
        &quot;Strickland's excellent collection not only provides a highly accessible entry point to students approaching Leibniz for the first time, but also constitutes a significant contribution to Leibniz scholarship; the selections are fresh and interesting, and succeed in revealing the breadth and depth of Leibniz's philosophy.&quot;<br />
        &nbsp;&nbsp;- Vernon Pratt, <var>Senior Lecturer in Philosophy, Lancaster University</var><br /><br />
        Review:<br /><br />
        &quot;A volume of Leibniz translations by Lloyd Strickland, <var>The Shorter Leibniz Texts</var> was very welcome. Strickland can render Leibniz clearly in a prose which enables, rather than steadies, the dizzying effects of Leibniz's different concepts as they encompass infinity. Many of the pieces - On The Death Penalty, On The Happy Life, to choose two - have not appeared in English before. Check out Strickland's peerless website [i.e. leibniz-translations.com] for many other short translations published online.&quot;<br />
        &nbsp;&nbsp;- Edmund Hardy, <var>from Ready Steady Book's review of the best books of 2006</var><br /><br />
      </>
    );
  }
  
  return (
    <div>
      <Helmet>
            <title>The Shorter Leibniz Texts</title>
            <meta name="description" content="A book authored by Lloyd Strickland on 'The Shorter Leibniz Texts'" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()} <hr className="centered-hr" />
                    {endorsements()}
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>THE SHORTER LEIBNIZ TEXTS</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}
                    <p>View testimonials and reviews <span style={{color: 'blue'}} onClick={() => scrollToElement('endorsements')}>here</span></p>
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                  <img src={Image} alt="The Shorter Leibniz Texts" style={{ float: 'left', marginRight: '20px', height: isMobile ? '220px' : '364px', width: 'auto' }} />
                  <p style={{ margin: '10px 0px 0px 0px', fontSize: '13px' }}>Published October 2006 (UK) / December 2006 (US).</p><br></br>
                  <p>THE SHORTER LEIBNIZ TEXTS contains 67 texts, including 40 that have not appeared in English before, and around a dozen that were previously only available in old and now hard to find anthologies or in other works that are not widely accessible to many students or scholars.<br /><br /></p>
                  <p>As is common with such anthologies, some of the selections are not provided in their entirety. In some cases I have translated the remaining parts of the text, which can be accessed via this page. Clicking on the titles in red will take you to the portion of that particular text that is not included in the anthology.<br clear = "all" /><br /></p>
                  <p>Texts included (those marked with * are previously unavailable in English, those with ** are not available in any of the recent English anthologies):<br /><br /></p>
                  <b>I. METAPHYSICS</b><br /><br />
                    A. CREATION
                    <ol><li>On first truths **</li>
                    <li>On the reason why these things exist rather than other things *</li>
                    <li>On the ultimate origination of things</li>
                    <li><Link to="/schulenburg-slt">Letter to Johann Christian Schulenburg, 1698</Link> *</li>
                    <li><Link to="/morellmay1698-slt">Letter to Andr&#233; Morell, May 1698</Link> *</li></ol>
                    B. TRUTH AND SUBSTANCE<br />
                    <ol><li>On the perfect concept of substances *</li>
                    <li>The principle of human knowledge</li>
                    <li>Remarks on Mr Arnauld's letter concerning my proposition: that the individual concept of each person includes once and for all everything that will ever happen to him</li>
                    <li>Letter to Antoine Arnauld, July 1686</li>
                    <li>Logical-metaphysical principles</li>
                    <li>On body and substance truly one *</li>
                    <li>Letter to Pierre Dangicourt, 11 September 1716 *</li></ol><br />
                    <b>II. MIND, BODY AND SOUL</b><br /><br />
                    A. SOULS AND THEIR NATURE<br />
                    <ol><li>Difficulties concerning the nature and origin of souls *</li>
                    <li>Souls are either created every day or are coeval with the world *</li>
                    <li>On the creation of souls and the origin of minds *</li>
                    <li>On the souls of men and beasts **</li></ol>
                    B. THE RELATIONSHIP BETWEEN SOUL AND BODY<br />
                    <ol><li>On the system of occasional causes</li>
                    <li>New system of the nature of the communication of substances, as well as the union that exists between the soul and the body</li>
                    <li>Explanation of New System</li></ol>
                    C. THE FATE OF SOULS<br />
                    <ol><li><Link to="/sophie1696-slt">Letter to Electress Sophie, 4 Nov. 1696</Link> *</li>
                    <li><Link to="/sophie1706-slt">Letter to Electress Sophie, 6 Feb. 1706</Link> *</li></ol><br />
                    <b>III. FREE WILL AND NECESSITY</b><br /><br />
                    A. THE NATURE OF FREE WILL<br />
                    <ol><li>On free will *</li>
                    <li>On freedom and spontaneity **</li>
                    <li>Conversation about freedom and fate *</li></ol>
                    B. CONTINGENCY AND NECESSITY<br />
                    <ol><li>Middle knowledge</li>
                    <li>On freedom from necessity in choosing *</li>
                    <li>A note on freedom and necessity *</li>
                    <li>On contingency</li>
                    <li><Link to="/provincial">Notes on Pierre Bayle's <var>Reply to the Questions from a Provincial</var></Link> *</li></ol><br />
                    <b>IV. SCIENCE</b><br /><br />
                    A. MATTER AND BODY<br />
                    <ol><li>Demonstration against atoms, taken from the contact of atoms **</li>
                    <li><Link to="/essence-slt">Whether the essence of body consists in extension</Link> **</li>
                    <li><Link to="/bernoulli">Letter to Johann Bernoulli, Aug.-Sept. 1698</Link></li>
                    <li>Letter to Burcher de Volder, 1699</li>
                    <li>Letter to Thomas Burnett, 1700</li>
                    <li>Letter to Burcher de Volder, 1704</li></ol>
                    B. THE LAWS OF NATURE<br />
                    <ol><li>On a general principle useful in explaining the laws of nature</li>
                    <li><Link to="/bayle-slt">Letter to Pierre Bayle, 1702?</Link> **</li>
                    <li><Link to="/fontenelle-slt">Letter to Bernard le Bovier de Fontenelle, 1703</Link> *</li></ol>
                    C. BIOLOGY<br />
                    <ol><li>On the origin of fossils *</li>
                    <li>Protogaea (selections) *</li>
                    <li>Letter from G. W. Leibniz, 1710 *</li></ol><br />
                    <b>V. LAW AND ETHICS</b><br /><br />
                    A. LAW<br />
                    <ol><li>Preface to the <var>Diplomatic Code of People's Rights</var></li>
                    <li>On the death penalty *</li>
                    <li>Expiation *</li></ol>
                    B. VIRTUE<br />
                    <ol><li>On generosity **</li>
                    <li><Link to="/sophie1697-slt">Letter to Electress Sophie, mid-August (?) 1697</Link> *</li>
                    <li>Definitions *</li>
                    <li>The true piety *</li></ol>
                    C. PLEASURE AND HAPPINESS<br />
                    <ol><li>On the happy life *</li>
                    <li>Happiness *</li>
                    <li>A dialogue *</li></ol><br />
                    <b>VI. THEOLOGY</b><br /><br />
                    A. THE EXISTENCE OF GOD<br />
                    <ol><li>On the reality of truth *</li>
                    <li>On necessary or eternal truths *</li>
                    <li>A proof of the existence of God from his essence *</li>
                    <li>Extract from a letter concerning the Cartesian demonstration for the existence of God, sent to the editor of the <var>Journal de Tr&#233;voux</var> **</li></ol>
                    B. OPTIMISM AND MELIORISM<br />
                    <ol><li>The elements of true piety *</li>
                    <li>On the continuous increase in perfection of the world *</li>
                    <li>Whether the world increases in perfection **</li>
                    <li><Link to="/morellsept1698">Letter to Andr&#233; Morell, Sept. 1698</Link> *</li>
                    <li>Letter to Louis Bourguet, Aug. 1715</li>
                    <li><Link to="/bourguetmarch1716">Letter to Louis Bourguet, Mar. 1716</Link> *</li>
                    <li><Link to="/bourguetapril1716">Letter to Louis Bourguet, Apr. 1716</Link> *</li>
                    <li><Link to="/bourguetjuly">Letter to Louis Bourguet, July 1716</Link> *</li></ol>
                    C. SIN AND EVIL<br />
                    <ol><li>On sin and orignal sin **</li>
                    <li>Can the bad outcomes of wicked actions be ascribed to wickedness? *</li>
                    <li><Link to="/jacquelot1706">Letter to Isaac Jacquelot, 1706</Link> *</li>
                    <li><Link to="/bourguet1712">Letter to Louis Bourguet, 1712</Link> *</li></ol><br /><br /><br />
                    Note - by 'recent anthologies', I mean those published in the last forty years or so, e.g.:<br /><br />
                    Paul Schrecker &amp; Anne Martin Schrecker, <font color="#B37947"><var>Monadology and other Philosophical Essays</var></font> (1965)<br />
                    G. H. R. Parkinson, <font color="#B37947"><var>Logical Papers</var></font> (1966)<br />
                    H. T. Mason, <font color="#B37947"><var>The Leibniz-Arnauld Correspondence</var></font> (1967)<br />
                    Leroy Loemker, <font color="#B37947"><var>Philosophical Papers and Letters</var></font> (2ed, 1969)<br />
                    Patrick Riley, <font color="#B37947"><var>Political Writings</var></font> (1972)<br />
                    Mary Morris &amp; G. H. R. Parkinson, <font color="#B37947"><var>Philosophical Writings</var></font> (1973)<br />
                    R. Niall D. Martin &amp; Stuart Brown, <font color="#B37947"><var>Discourse on Metaphysics and Related Writings</var></font> (1988)<br />
                    Roger Ariew &amp; Daniel Garber, <font color="#B37947"><var>Philosophical Essays</var></font> (1989)<br />
                    G. H. R. Parkinson, <font color="#B37947"><var>De summa rerum</var></font> (1992)<br />
                    Daniel J. Cook &amp; Henry Rosemont Jr., <font color="#B37947"><var>Writings on China</var></font> (1994)<br />
                    R. S. Woolhouse &amp; Richard Francks, <font color="#B37947"><var>Leibniz's 'New System' and Associated Contemporary Texts</var></font> (1997)<br />
                    R. S. Woolhouse &amp; Richard Francks, <font color="#B37947"><var>Philosophical Texts</var></font> (1998)<br />
                    Richard T. W. Arther, <font color="#B37947"><var>The Labyrinth of the Continuum</var></font> (2001)<br />
                    R. Sleigh Jr., <font color="#B37947"><var>Confessio Philosophi</var></font> (2005)<br></br><br></br>
                  {isMobile ? (
                    <div id="endorsements">
                      <hr></hr><p style={{paddingTop: '10px'}}>Go back to the top of the page <span style={{color: 'blue'}} onClick={() => scrollToElement('top')}>here</span></p>
                      {endorsements()}
                    </div>
                  ) : (
                    <>
                    </>
                  )}
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Slt;
