import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/sophie1699.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>S&#228;mtliche schriften und briefe series I, volume 16</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		p 101<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 8/18 February 1699<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (12k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Electress Sophie (8/18 February 1699)</title>
            <meta name="description" content="An English translation of a LETTER TO ELECTRESS SOPHIE by Gottfried Wilhelm Leibniz, from 8/18 February 1699" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO ELECTRESS SOPHIE</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A I 16, p101</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Madam de Brinon has written back to me; she admires Your Electoral Highness' letter as much as I do;<a name="1" href="#note1"><sup>1</sup></a> she accepts that the bad actions practised by Roman Catholics are wrong, but she claims that their religion does not teach those actions Your Electoral Highness disapproves of. The problem is that the powers that be, and those who teach the religion to others, authorize them to some extent. She cites the late Mr Arnauld's <var>Apology</var> for the Catholics of England.<a name="2" href="#note2"><sup>2</sup></a> I have seen this <var>Apology</var>, but it only concerns the most recent conspiracies ascribed to them, and does not justify them for the gunpowder plot, which is only too real. This is what I will say in my reply.<a name="3" href="#note3"><sup>3</sup></a> And I will also say to her that in order to cleanse their religion of these bad practices of the spirit of perfection and of violence that reigns there under the pretext of zeal, their Church would have to openly disapprove of them, whereas it seems to do completely the opposite. Madam de Brinon returns again to the passage of St. Paul, who feared for his salvation,<a name="4" href="#note4"><sup>4</sup></a> and claims that Your Electoral Highness has not replied to the authority of this Apostle, whose account should not - it is true - be challenged. But ultimately I do not think that there is a big difference on this point between our theologians and those on the side of Rome. For admittedly it is possible that a man who is presently in a good state can change to a worse one; and that one cannot vouch for one's own perseverance in the good.<a name="5" href="#note5"><sup>5</sup></a> However it is also to be admitted that we can have as much confidence in God's goodness as we should have fear of his justice.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTE:<br /><br />
                <a name="note1" href="#1">1.</a> Leibniz is referring to Sophie's letter to de Brinon of 23 December 1698/2 January 1699. See Foucher de Careil, <var>&#338;uvres</var> (1860) II: 227-229 (2nd edition: 231-233).<br />
                <a name="note2" href="#2">2.</a> Antoine Arnauld, <var>Apologie pour les Catholiques</var> (Li&#232;ge, 1681-2).<br />
                <a name="note3" href="#3">3.</a> Leibniz's reply was finally written more than 3 months later, on 15/25 May 1699. See A I 17, pp198-201. An English translation is available <Link to="/brinon1699">here</Link>.<br />
                <a name="note4" href="#4">4.</a> Cf. 1 Corinthians 4.4: 'For I know nothing by myself; yet am I not hereby justified; but he that judgeth me is the Lord.'<br />
                <a name="note5" href="#5">5.</a> On this matter see Leibniz's essay 'On the certainty of salvation' in LGR 239-241.<br />
        <br /><br />
                &#169; Lloyd Strickland 2007, 2009
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
