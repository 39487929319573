import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/hospital1.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>S&#228;mtliche schriften und briefe series III, volume 6</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		p 451<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 12/22 July 1695<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (181k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to the Marquis de l'Hospital (12/22 July 1695)</title>
            <meta name="description" content="An English translation of a LETTER TO THE MARQUIS DE L'HOSPITAL by Gottfried Wilhelm Leibniz, from 12/22 July 1695" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO THE MARQUIS DE L'HOSPITAL</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A III 6, 451</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Extract from my letter to the Marquis de l'Hospital, 12/22 July 1695.<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I shall be delighted to learn your opinion on my meditations which were recently included in your journals of June and July.<a name="1" href="#note1"><sup>1</sup></a>  It is mathematicians who should be asked to be judges, not the common run of philosophers. Metaphysical thoughts cannot fail to appear strange to minds unaccustomed to deep thinking. But I hope they will not be overly troubled by them. I am very much of Father Malebranche’s view that it is only God who acts immediately on substances by a real influence. But leaving aside our dependence upon him, which means that we are conserved by a continual creation, leaving that aside, I say, to speak only of secondary causes or of the ordinary course of nature, I hold that, without needing new operations from God, in order to explain things we can be satisfied with what God initially gave them. Thus in my view, every substance [already expresses in advance and]<a name="2" href="#note2"><sup>2</sup></a> produces within itself in an orderly way everything that will ever happen to it internally. God intended to concur with it only in accordance with [these primitive delineations or] the primitive nature of the thing, the consequences of which are merely developments of the future. Mr Arnauld initially believed that this could undermine grace and favour the Pelagians, but after receiving my clarification he absolved me of this accusation. Nevertheless I consider myself able to say that there is nothing more favourable to our freedom than the view that I have just mentioned. The key to my doctrine on this subject lies in the consideration that what is properly a real unity, a monad...<a name="3" href="#note3"><sup>3</sup></a>
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> That is, the issues of the <var>Journal des Savants</var> which contained Leibniz's "New System of the Nature of Substances and their Communication, and of the Union which Exists between the Soul and the Body". English translation in Lloyd Strickland (ed), <var>The Shorter Leibniz Texts</var> (Continuum: London, 2006), 68-77.<br />
                <a name="note2" href="#2">2.</a> Leibniz wrote “hoc omisi” [“I omitted this”] on his copy, presumably referring to all of the material in square brackets.<br />
                <a name="note3" href="#3">3.</a> The text breaks off here.<br />
        <br /><br />
                &#169; Lloyd Strickland 2006. Revised 2024
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
