import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/caze1705.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
		Shelfmark LBr 146 Bl. 2<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 16 January 1705<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (102k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>César Caze to Leibniz (16 January 1705)</title>
            <meta name="description" content="A transcription of a letter from CESAR CAZE TO GOTTFRIED WILHELM LEIBNIZ, from 16 January 1705" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>C&#201;SAR CAZE TO LEIBNIZ</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>LBr 146 Bl. 2r</b>]<br /><br />		

                <span style={{float: 'right'}}>Amsterdam, 16 January 1705</span><br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sir<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Last October, with the approval of Mr. Burgomaster Witsen,<a name="1" href="#note1"><sup>1</sup></a> I gave myself the honour of writing to you, sending you some remarks on what you have so delightedly thought regarding the 64 figures of Fuxi.<a name="2" href="#note2"><sup>2</sup></a> Because at that time I was obliged to take a short trip, and my packet was not of the size to be put in the post, I contented myself with giving the order that it be taken to the Coach Office in Hannover, believing that Mr. Burgomaster Witsen, having some opportunity to write to you, would at the same time give you notice of this despatch. But having heard on my return that he had neither written to you, Sir, nor received any letter from you, I thought I should let you know that it was last October 17th that the package was handed over to the Coach [Office] in Hannover, as can be seen from the Register of the Clerk of Carriages, so if it has failed, Sir, to be passed on to you, please take the trouble to have it requested. I also take this opportunity to wish you a good and happy year, followed by many others, and to assure you of my sincere passion to be able to obtain some part in your kindness, and to show you how much I am<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sir<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Your very humble and very obedient servant<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Caze

                    <br /><br /><br />
                        <hr className='rightcol-hr' />
                        <br /><br />NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> That is, Nicolaas Witsen (1641–1717), a renowned mapmaker, naval architect, and burgomaster of Amsterdam.<br />
                        <a name="note2" href="#2">2.</a> Caze is referring here to his lengthy memoir on binary arithmetic, in which he critiques Leibniz's belief that the 64 Hexagrams of the Yijing (i.e. the 64 &quot;figures of Fuxi&quot;) were designed as a binary number system. See Gottfried Wilhelm Leibniz, <var>Die Hauptschriften zur Dyadik von G. W. Leibniz</var>, ed. Hans J. Zacher (Frankfurt: Vittorio Klostermann, 1973), 311-338.<br /><br /><br />
                        &#169; Lloyd Strickland 2021
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
