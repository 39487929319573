import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/electressaug1697.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>S&#228;mtliche schriften und briefe series I, volume 14</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 59-60<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: mid-August (?) 1697<br /><br />
		Translated from the French<br /><br />
        Note - the portion of this text that is included in my <var>Shorter Leibniz Texts</var> is not included here.<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (14k)</Link><br /><br />
        <Link to="/slt">Back to <var>Shorter Leibniz Texts</var></Link><br /><br />
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Electress Sophie (mid-August (?) 1697)</title>
            <meta name="description" content="An English translation of a LETTER TO ELECTRESS SOPHIE by Gottfried Wilhelm Leibniz, from mid-August (?) 1697" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO ELECTRESS SOPHIE</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A I 14, p59</b>]<br /><br />
            .....<br /><br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;But I have been formulating these ideas since my youth. A great prince, who at the same time was a great prelate,<a name="1" href="#note1"><sup>1</sup></a> contributed a lot to this, by recommending to me the German book of Father Spee<a name="2" href="#note2"><sup>2</sup></a> on the three Christian virtues, published and republished more than once in Cologne.<a name="3" href="#note3"><sup>3</sup></a><br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This Father was one of the great men of his kind, and he deserves to be better known than he is. The same prince told me that he was the author of the famous book on the precautions that should be taken in witch trials - a book which caused such a commotion in the world that it was translated into several languages, from the original which was Latin, under the title of <var>Cautio criminalis</var>,<a name="4" href="#note4"><sup>4</sup></a> and which has seriously alarmed the burners without them having been able to know where it came from.<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;His book on the three Christian virtues is in my opinion one of the most solid and moving books on devotion that I have ever seen. In the main, the only thing I would wish is that the verse had been cut, because Father Spee did not have any idea of the perfection of German poetry and apparently did not have the ear to talk about the incomparable Opitz,<a name="5" href="#note5"><sup>5</sup></a> to whom we owe it. Also, we find even now that Roman Catholics almost do not know what a good German verse is, so that we could say that they are as little reformed with regard to our poetry as they are on the matter of religion, and that this difference of our verse is a mark of the Church for them.<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;But that has nothing to do with the present matter. It seems from the dedication of the bookseller that the author must have died in the odour of sanctity.<a name="6" href="#note6"><sup>6</sup></a><br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This Father's preface contains a beautiful dialogue, in which the difference between disinterested love [<b>A I 14, p60</b>] and hope is developed in a way as intelligible as it is profound, although there is something one can still find fault with. But I have the habit of stopping myself only at the good, which it deviates from somewhat, and I thought that Your Electoral Highness would not be displeased to see attached here the translation that I made of this dialogue some time ago.<a name="7" href="#note7"><sup>7</sup></a><br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I am with devotion<br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Madam, to Your Electoral Highness,<br />
            <div align="right">your very humble and very obedient servant<br />
            Leibniz</div>

                    <br /><br /><br />
                    <hr className='rightcol-hr' />
                    <br /><br />
                    NOTES:<br /><br />
                    <a name="note1" href="#1">1.</a> Johann Philipp von Sch&#246;nborn (1605-1673), Archbishop of Mainz and Bishop of W&#252;rzburg and Worms.<br />
                    <a name="note2" href="#2">2.</a> Friedrich von Spee (1591-1635), Jesuit and poet.<br />
                    <a name="note3" href="#3">3.</a> Friedrich von Spee, <var>G&#252;ldenes Tugend-Buch</var> [Golden Book of Virtues] (Cologne, 1646).<br />
                    <a name="note4" href="#4">4.</a> Friedrich von Spee, <var>Cautio criminalis</var> [Prudence in criminal cases] (Rinteln, 1631)<br />
                    <a name="note5" href="#5">5.</a> Martin Opitz von Boberfeld (1597-1639), German poet.<br />
                    <a name="note6" href="#6">6.</a> Leibniz is referring to the dedication in the 2nd edition of Spee's book (1656), written by Wilhelm Friessem. Cf. G VI 156-7/H 176-7.<br />
                    <a name="note7" href="#7">7.</a> Leibniz's translation of Spee's preface can be found in A VI 4, pp2517-2529.<br />
                    <br /><br />
                    &#169; Lloyd Strickland 2004
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
