import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/brinon1697.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>S&#228;mtliche schriften und briefe series I, volume 14</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 741-743<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 19/29 November 1697<br /><br />
		Translated from the French<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (17k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Marie de Brinon (19/29 November 1697 - unsent draft)</title>
            <meta name="description" content="An English translation of a LETTER TO MARIE DE BRINON by Gottfried Wilhelm Leibniz, from 19/29 November 1697" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO MARIE DE BRINON (UNSENT DRAFT)</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A I 14, p741</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I do not know why you take it to be teasing when your conversion [<b>A I 14, p742</b>] is brought up, and when the wish is expressed that Madam the Electress of Brunswick<a name="1" href="#note1"><sup>1</sup></a> have the honour of it. Is it that people are infallible in all things like you, or would you be the first who had recognized the illusions of his own side?<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Without being Jurieu,<a name="2" href="#note2"><sup>2</sup></a> I claim that the whole of France will be converted one day, and that people there will be ashamed of some current opinions. It would be glorious for you, Madam, and even praiseworthy, to give a good example by contributing to bringing back to the ways of reason and true piety a great nation which is so reasonable in everything else. It seems that you people from mild climates are ashamed to listen to the remonstrations of those from the Septentrion,<a name="3" href="#note3"><sup>3</sup></a> but this is a real shame because the truth is from every country. We owe to a man from Prussia the reestablishment of the true system of the world;<a name="4" href="#note4"><sup>4</sup></a> we owe to a man from Saxony the freeing of the human race from guardianship.<a name="5" href="#note5"><sup>5</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The follies of judicial astrology reign among the most spiritual nations of the Levant<a name="6" href="#note6"><sup>6</sup></a> and of the Midi.<a name="7" href="#note7"><sup>7</sup></a> The Italians and the Spanish, capable of sublime and profound thoughts, are so pigheaded and astounded by their superstitions that they thereby deprive themselves of the intellectual advantages which nature has given them above several others. Can one think of anything stranger than what they say, for example, about the House of Lorette? It is as if God had punished them with blindness since they use their heavenly graces so poorly. So you see, Madam, that those two nations are extremely backward as regards the sciences, and even in another way, for lack of a modest freedom of thought, which is necessary to cultivate reason. It must be admitted that the French are the most enlightened of the Roman side, and that as regards religion they are there what the Chinese think that the Europeans are among the other nations. It is this mixture of reason and pigheadedness which has enabled fine forms of knowledge to flourish even in France. If I were inclined to suspect people, I would believe that there is a policy in the doings of the French, and that they are quite content to keep alive the chimeras of others in order to put themselves at the head of the party, and to take advantage of the credulity of the Italians, the Spanish, and of some of the Germans and others. In thinking like this I would do justice to their intelligence but not to their heart. That is why I am persuaded that there are people of good faith about these things among you. And indeed I prefer that one does something by a poorly-ruled zeal than by secular motives. But ultimately it must be admitted that everything is wonderful in France, except for the religion. Steer me towards any other subject whatsoever, and I will be the admirer of your court.<br /><br />

        [<b>A I 14, p743</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I have the same sentiments with respect to you, Madam; I admire the soundness of your judgement, and the clarity of your expressions, when you do not speak to me of sending to hell everything which is not Roman. Thus we have only been in dispute over this point. Keep, if you want, purgatory, transubstantiation, and all your seven sacraments; even keep the Pope with all his clergy, we will not oppose ourselves to these. And I will even put myself on your side in several matters, without departing from our principles; for I do not retract what I wrote in the past to Mr Meaux, and to the late Mr Pelisson.<a name="8" href="#note8"><sup>8</sup></a> But keep yourself from just two things, namely, affecting God's honour by a cult of creatures which gives bad impressions to many people, and injuring the charity which is owed to men by a sectarian and condemnatory spirit. The consequences of the latter again reflect on the honour of God, whose idea is destroyed by condemnatory sectarians, by making him seem unjust and tyrannical, and in a word, by giving him the qualities of his enemy. You explained yourself well, Madam, as regards worship, and I would want that practice in France correspond to theory on this point. But if you also explained yourself properly against the so-called condemnations, I would say that you have no need to be converted at all.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I don't know whether you still think, Madam, that I am teasing. It is true that one can sometimes have grounds to amuse oneself on other matters, but I am so earnest when it comes to God's honour, and to charity, that any desire to tease leaves me as soon as they come up. Believe of me whatever will please you in every other matter, but believe me sincere at least, and that I am with respect<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Madam, your very humble and very obedient servant
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> Electress Sophie.<br />
                <a name="note2" href="#2">2.</a> Pierre Jurieu (1637-1713). Leibniz is referring obliquely to Jurieu's <var>L'Accomplissement des propheties</var> [The fulfilment of prophecies] (1686), in which Jurieu predicted that the fall of the Roman Catholic church would occur in 1689.<br />
                <a name="note3" href="#3">3.</a> That is, from the Northern regions.<br />
                <a name="note4" href="#4">4.</a> Copernicus.<br />
                <a name="note5" href="#5">5.</a> Martin Luther.<br />
                <a name="note6" href="#6">6.</a> A geographical area in the Middle East, covering roughly the area today occupied by Syria, Lebanon, and Israel.<br />
                <a name="note7" href="#7">7.</a> A geographical region corresponding to the South of France.<br />
                <a name="note8" href="#8">8.</a> Leibniz is referring to Jacques B&#233;nigne Bossuet, the Bishop of Meaux, and Paul Pelisson-Fontanier. Leibniz corresponded with both in the early 1690s on the issue of Church reunion. Many of the letters passed through the hands of Marie de Brinon.
        <br /><br /><br />
                &#169; Lloyd Strickland 2006
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
