import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/immenseuniverse.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
		Shelfmark LH 4, 5, 9 Bl. 7<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 1701<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (100k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Demonstrations Concerning the Immense and Eternal Universe</title>
            <meta name="description" content="An English translation of DEMONSTRATIONS CONCERNING THE IMMENSE AND ETERNAL UNIVERSE by Gottfried Wilhelm Leibniz, from 1701" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: DEMONSTRATIONS CONCERNING THE IMMENSE AND ETERNAL UNIVERSE; ON THE WORLD AND ITS AGES, DOWN TO THE STATE OF REMOTE AND FUTURE THINGS</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>LH 4, 5, 9 Bl. 7</b>]<br /><br />
                <center>Demonstrations concerning the immense and eternal universe; on the world and its ages, down to the state of remote and future things</center><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;What is said here should be added to my 'Limit of human knowledge'<a name="1" href="#note1"><sup>1</sup></a> and what I wrote about that to Fontenelle on 26 February 1701.<a name="2" href="#note2"><sup>2</sup></a><br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Supposing that the human race endured long enough, the time would necessarily come when nothing could be said which has not been said before. However it is not certain that a time would come when nothing can be said which has not been said before. For it could happen that certain things are never said, even if all eternity were used up, and therefore there will always remain things which could be said and were not yet said. There are no perfect returns as in circles or ellipses, nor can it happen that one place or one time in the universe be perfectly alike another; they only appear so to the senses.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;But supposing that at some point there is nothing to be said which has not been said before, then there should likewise be a time when the same events return and when nothing happens which has not happened before, since these events provide the material for words.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Indeed, there would necessarily be certain periods similar to the platonic year, so that in one century exactly the same things would happen, according to the senses, as have already happened in another century. For the events of a whole century can be considered as one big fact and the history of a whole century as one large statement, and it is even necessary that such things are either repeated or exhausted, that is, after being exhausted they are repeated again. This holds for different times of the same places, i.e. of the same events which can even be extended to different places at the same time, so that the same things return; indeed such periods can be understood with regard to each mind since each one always thinks, even when this is done confusedly and without the soul's attention.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;And although we may assume a time comes when the human race does not exist, nevertheless if intelligent substances survive, which possess no other things or notions to think about than we have, the same must happen to them.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Moreover, it follows from this that more perfect intelligences are made, which have other, more profound notions and which are capable of greater and more complex truths, because it is not in keeping with the dignity of nature that prior events are merely repeated. Thus knowledge will be able to progress to infinity.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Consequently it is fitting that if human minds endure and experience platonic years, the same person returns, not so that he just returns to the earth but so that he returns, as it were, in a spirally or twisting way, from which he progresses toward something greater. This is to step back in order to jump further, like across a ditch.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Nevertheless it can also happen that progress is not spiralling or, to put it another way, regressive such as that of a secondary cycloid, but direct such as in a primary cycloid. And perhaps certain creatures experience platonic periods and others don't.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;During these periods there would not only be an immortality of the soul but also something equivalent to a resurrection of the body, if not the resurrection itself. Indeed, I consider that platonic periods cannot be avoided, at least in regard to the notions that must remain or are distinct, in which there is no novelty in their matter but only in their form or combination, which is limited. And in fact if anyone understands confused notions, they are resolved into distinct ones. Therefore, since minds which advance to a greater perfection make much longer propositions which are composed of smaller ones, their actions will be composed of a much broader combination of earlier actions and thus in accordance with a spiralling progression, or platonic year, together with an advancement, so that it is credible that the same ones often return to pick up the thread. And this is perhaps Democritus's promise of resurrection, which Pliny obviously considers unintelligible.<a name="3" href="#note3"><sup>3</sup></a>
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> LH 4, 5, 9 Bl. 1.<br />
                <a name="note2" href="#2">2.</a> Leibniz to Fontenelle, 26 February 1701, in A II 4, 7-18.<br />
                <a name="note3" href="#3">3.</a> Pliny, <var>Natural History</var> VII.55.<br /><br /><br />
                &#169; Lloyd Strickland 2006
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
