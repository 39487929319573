import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/propositions.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series VI volume 4</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 2354-5<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: autumn 1685 - spring 1686 (?)<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (141k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Theological propositions</title>
            <meta name="description" content="An English translation of THEOLOGICAL PROPOSITIONS by Gottfried Wilhelm Leibniz, from autumn 1685 - spring 1686 (?)" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: THEOLOGICAL PROPOSITIONS</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A VI 4, p2354</b>]<br /><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Although the vulgate version of Scripture was declared <var>authentic</var> in the Council of Trent, it is not to be understood as though this version never departs from the original, but because the Church holds as certain that the difference, so far as it concerns the matter of faith and customs, is of no importance. And hence it has declared that this version can be safely consulted.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;When saints are invoked, we should be careful that we do not attribute greater mercy to them than to God. For mercy is a kind of virtue, and God possesses virtues in the highest degree.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The assistance of the Saints consists in intercession.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The church has not determined whether the punishment of purgatory consists in fire, properly speaking.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It is useful that a communion under both kinds is permitted to people, in particular to those who have been accustomed to it.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Also, the marriage of clerics will be rightly permitted everywhere.<br /><br />
                    
                [<b>A VI 4, p2355</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A free choice does not conflict with this necessity of favouring something, a necessity that arises from a clear appearance of a greater good, for a choice tends by own nature to the apparent best, and the formal object of the will is the apparent good. However, a lesser good has the character of evil.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;No one can be justified without a true love of God.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In the Eucharist, adoration should not be directed to the visible and sensible object of worship, as it were, but to the present God.

                        <br /><br /><br />
                        &#169; Lloyd Strickland 2007. Revised 2021
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
