import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/epicurus.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
		Shelfmark LH 1, 1, 4 Bl. 66<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 1702(?)<br /><br />
		Translated from the French and Latin<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (107k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Notes on Bayle's "Dictionary" article 'Epicurus'</title>
            <meta name="description" content="An English translation of a NOTES ON PIERRE BAYLE'S 'DICTIONARY' ARTICLE 'EPICURUS' by Gottfried Wilhelm Leibniz, from 1702?" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: NOTES ON PIERRE BAYLE'S <var>DICTIONARY</var> ARTICLE 'EPICURUS'</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>LH 1, 1, 4 Bl. 66</b>]<br /><br />

                Mr Bayle's <var>Dictionnaire</var>, 2nd edition, article &quot;Epicurus,&quot; note T, p. 1142:
                <br></br>

                <br></br><blockquote style={{padding: isMobile ? '' : '0px 70px 0 70px'}}>Neither the one nor the other of these two great philosophers (Epicurus and Chrysippus) understood that the truth of this maxim, 'every proposition is true or false', is independent of what is called <var>fate</var>; therefore<a name="1" href="#note1"><sup>1</sup></a> it could not serve as proof of the existence of <var>fate</var>, as Chrysippus claimed, and as Epicurus feared. Chrysippus could not admit, without overreaching himself, that there are propositions that are neither true nor false, but he was no better off establishing the contrary, for whether there are free causes or not, it is equally true that this proposition, 'The great Mogul will go hunting tomorrow' is true or false. It was right to consider this discourse of Tiresias as ridiculous: 'Everything that I will say will happen, or not, for the great Apollo endows me with the faculty to prophesy.'<a name="2" href="#note2"><sup>2</sup></a> If, though it is impossible, there were no God, it would nevertheless be certain that everything predicted by the greatest madman in the world would happen or would not happen. Neither Chrysippus nor Epicurus took heed of this.<a name="3" href="#note3"><sup>3</sup></a></blockquote>

                Bayle does not understand the words of Tiresias very well; they mean: whatever I say must happen or not happen, will happen or will not happen as I have said.

                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> Reading &quot;donc&quot; in place of &quot;dont&quot;.<br />
                <a name="note2" href="#2">2.</a> From Horace, <var>Satires</var>, 2.5.<br />
                <a name="note3" href="#3">3.</a> Pierre Bayle, <var>Dictionnaire historique et critique</var> (Rotterdam, 1702, 2nd edn), II: 1142 (article &quot;Epicurus&quot;, note T). Leibniz would go on to quote this passage in &#167;169 of his <var>Theodicy</var>.<br /><br /><br />
                &#169; Lloyd Strickland 2016
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
