import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Image from '../images/god-and-religion-medium.jpg';
import { Helmet } from 'react-helmet';

function GodAndReligion() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function scrollToElement(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        <a href="http://www.amazon.co.uk/Leibniz-God-Religion-Lloyd-Strickland/dp/1472580621/ref=sr_1_15?s=books&ie=UTF8&qid=1456611485&sr=1-15&keywords=leibniz" target="_blank">Buy from Amazon (UK)</a><br /><br />
        <a href="http://www.amazon.com/Leibniz-God-Religion-Lloyd-Strickland/dp/1472580621/ref=sr_1_17?s=books&ie=UTF8&qid=1456611578&sr=1-17&keywords=leibniz" target="_blank">Buy from Amazon (US)</a><br /><br />
        <a href="http://blogs.lse.ac.uk/lsereviewofbooks/2016/09/15/book-review-leibniz-on-god-and-religion-a-reader-edited-by-lloyd-strickland/" target="_blank">Review by Audrew Borowski, from <var>LSE Review of Books</var></a><br /><br />
        <a href="http://readingreligion.org/books/leibniz-god-and-religion" target="_blank">Review by Dustin Atlas, from <var>Reading Religion</var></a><br /><br />
        <a href="http://link.springer.com/article/10.1007/s11153-016-9584-z" target="_blank">Review by Charles Joshua Horn, from <var>International Journal for Philosophy of Religion</var></a> (subscription required for access)<br /><br />
        <a href="https://www.pdcnet.org/acpq/content/acpq_2019_0093_0001_0181_0184" target="_blank">Review by Thomas Feeney, from <var>American Catholic Philosophical Quarterly</var></a> (subscription required for access)<br /><br />
      </div>
    );
  }

  function endorsements() {
    return (
      <>
      Testimonial:<br /><br />
    "Lloyd Strickland has done students and teachers of Leibniz an enormous service in producing this fine selection from Leibniz's writings on philosophical theology, many of which appear in English for the first time. It will surely become a standard reference volume for many years to come. Whilst offering only a fraction of Leibniz's output on these issues, Leibniz on God and Religion serves to counter a tendency among some prominent Anglophone scholars to represent Leibniz as a philosopher whose views emerged primarily from his logical and/or scientific interests. In contrast, Strickland's volume clearly demonstrates the ways in which Leibniz's understanding of God and religion were at the very heart of his philosophical project throughout his career." - Paul Lodge, <var>Tutorial Fellow in Philosophy, Mansfield College, University of Oxford, UK</var><br /><br />
      </>
    );
  }
  
  return (
    <div>
      <Helmet>
            <title>Leibniz on God and Religion</title>
            <meta name="description" content="A book authored by Lloyd Strickland on 'Leibniz on God and Religion'" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()} <hr className="centered-hr" />
                    {endorsements()}
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ ON GOD AND RELIGION</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}
                    <p>View testimonial <span style={{color: 'blue'}} onClick={() => scrollToElement('endorsements')}>here</span></p>
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                  <img src={Image} alt="Leibniz on God and Religion" style={{ float: 'left', marginRight: '20px', height: isMobile ? '220px' : '364px', width: 'auto' }} />
                  <p style={{ margin: '0px 0px 0px 0px', fontSize: '13px' }}>Published February 2016, by Bloomsbury.</p><br></br>
                  <p>From the back cover blurb:</p>
                  <p>Bringing together Leibniz's writings on God and religion for the very first time, <var>Leibniz on God and Religion: A Reader</var> reflects the growing importance now placed on Leibniz's philosophical theology. This reader features a wealth of material, from journal articles and book reviews published in Leibniz's lifetime to private notes and essays, as well as items from his correspondence.<br /><br /></p>
                  <p>Organised thematically into the following sections, this reader captures the changes in Leibniz's thinking over the course of his career:<br clear = "all" /></p>
                  <ol>
                    <li>The Catholic Demonstrations</li>
                    <li>The existence and nature of God</li>
                    <li>Reason and faith</li>
                    <li>Ethics and the love of God</li>
                    <li>The Bible</li>
                    <li>Miracles and mysteries</li>
                    <li>The Churches and their doctrines</li>
                    <li>Grace and predestination</li>
                    <li>Sin, evil, and theodicy</li>
                    <li>The afterlife</li>
                    <li>Non-Christian religions</li>
                  </ol>
                    <br />
                    In preparing this reader, Strickland has returned to Leibniz's original manuscripts to ensure accurate translations of key texts, the majority of which have not been available in English before. The reader also contains a number of texts previously unpublished in any form.<br /><br />
                    Alongside the translations, this reader contains an introductory essay, explanatory notes on all of the texts, and suggestions for further reading. This valuable sourcebook enables students of all levels to achieve a well-rounded understanding of Leibniz's philosophical theology.
                  {isMobile ? (
                    <div id="endorsements">
                      <hr></hr><p style={{paddingTop: '10px'}}>Go back to the top of the page <span style={{color: 'blue'}} onClick={() => scrollToElement('top')}>here</span></p>
                      {endorsements()}
                    </div>
                  ) : (
                    <>
                    </>
                  )}
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default GodAndReligion;