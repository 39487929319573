import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Image from '../images/tercentenary.jpg';
import { Helmet } from 'react-helmet';

function Tercentenary() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        <a href="https://www.amazon.co.uk/Tercentenary-Essays-Philosophy-Science-Leibniz/dp/3319388290/" target="_blank">Order from Amazon (UK)</a><br /><br />
        <a href="https://www.amazon.com/Tercentenary-Essays-Philosophy-Science-Leibniz/dp/3319388290/" target="_blank">Order from Amazon (US)</a><br /><br />
      </div>
    );
  }
  
  return (
    <div>
      <Helmet>
            <title>Tercentenary Essays on the Philosophy and Science of Leibniz</title>
            <meta name="description" content="A book edited by Lloyd Strickland, Julia Weckend and Erik Vynckier on 'Tercentenary Essays on the Philosophy and Science of Leibniz'" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>TERCENTENARY ESSAYS ON THE PHILOSOPHY AND SCIENCE OF LEIBNIZ</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}
                   
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                  <img src={Image} alt="Tercentenary Essays on the Philosophy and Science of Leibniz" style={{ float: 'left', marginRight: '20px', height: isMobile ? '220px' : '334px', width: 'auto' }} />
                  <p style={{ margin: '0px 0px 0px 0px', fontSize: '13px' }}>Published December 2016, by Palgrave Macmillan.</p>
                  <p style={{ fontSize: '13px' }}>Co-edited with Julia Weckend and Erik Vynckier.</p>
                  <p>From the back cover blurb:</p>
                  <p>This book presents new research into key areas of the work of German philosopher and mathematician Gottfried Wilhelm Leibniz (1646-1716). Reflecting various aspects of Leibniz's thought, this book offers a collection of original research arranged into four separate themes: Science, Metaphysics, Epistemology, and Religion and Theology. With in-depth articles by experts such as Maria Rosa Antognazza, Nicholas Jolley, Agust&#237;n Echavarr&#237;a, Richard Arthur and Paul Lodge, this book is an invaluable resource not only for readers just beginning to discover Leibniz, but also for scholars long familiar with his philosophy and eager to gain new perspectives on his work. <br clear = "all" /></p>
                  <p style={{fontWeight: 'bold'}}>Contents</p>

                  Acknowledgements<br />
                  Notes on contributors<br />
                  Abbreviations<br /><br />

                  Introduction<br />
                  - Lloyd Strickland, Julia Weckend<br /><br />

                  <b>Section 1: Science</b><br />
                  Philosophy and science in Leibniz<br />
                  - Maria Rosa Antognazza<br /><br />

                  Between learned science and technical knowledge: Leibniz, Leeuwenhoek and the school for microscopists<br />
                  - Alessandro Becchi<br /><br />

                  Leibniz, organic matter and astrobiology<br />
                  - Richard T. W. Arthur<br /><br />


                  <b>Section 2: Metaphysics</b><br />
                  Plenitude and mirrors of God in Leibniz<br />
                  - Nicholas Jolley<br /><br />

                  As matter to form so passive to active? The irreducible metaphysics of Leibniz's Dynamics<br />
                  - Tzuchien Tho<br /><br />

                  Conceptual analysis and ontology in the Leibniz - De Volder correspondence<br />
                  - Stefano di Bella<br /><br />


                  <b>Section 3: Epistemology</b><br />
                  Leibniz's empirical, not empiricist methodology<br />
                  - Dale Jacquette<br /><br />

                  Leibniz on certainty<br />
                  - Julia Weckend<br /><br />

                  Leibniz and probability in the moral domain<br />
                  - Chris Meyns<br /><br />


                  <b>Section 4: Religion and Theology</b><br />
                  How Leibniz would have responded to the Lisbon earthquake<br />
                  - Lloyd Strickland<br /><br />

                  Leibniz on the efficacy and economy of divine grace<br />
                  - Agust&#237;n Echavarr&#237;a<br /><br />

                  Eternal punishment, universal salvation and pragmatic theology in Leibniz<br />
                  - Paul Lodge<br /><br />

                  <b>Biographical conclusion</b><br />
                  In the &quot;hinterland&quot; of globalization? Leibniz and the European expansion<br />
                  - Michael Kempe<br /><br />

                  Index<br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Tercentenary;