import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/onloveofgod.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>Opera Omnia volume 5</var><br />
		Ludovic Dutens (ed)<br />
		pp 28-29<br /><br />
	    {isMobile ? '' : <hr className="centered-hr" />}
		Date: after 1701 (?)<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (87k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Letter to a friend, on the love of God (after 1701 (?))</title>
            <meta name="description" content="An English translation of a LETTER TO A FRIEND, ON THE LOVE OF GOD by Gottfried Wilhelm Leibniz, after 1701 (?)" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: LETTER TO A FRIEND, ON THE LOVE OF GOD</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>D V p28</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The concept of love is best drawn from German. Germans express <var>to love</var> as <var>gern sehen</var> and <var>to hate</var> as <var>nicht gern sehen</var>. Accordingly, to love something is to take pleasure in the contemplation of it, and the deeper the knowledge one has of it the greater the pleasure will be. It is helpful always to dwell on this and apply it to the love of God. Therefore it is necessary that he who claims to love God above all things knows his beauty before other things. [<b>D V p29</b>] In order to know the beauty of a young woman, for example, it is not sufficient to contemplate her digits, nor is it necessary to contemplate all her limbs down to the pores and hairs; rather, the whole should be surveyed in a single glance, so to speak. Likewise, it is not sufficient to behold some of God's work, nor is it necessary or even possible to scrutinize all of his work, but it is sufficient to form a solid idea of him in general, as being indisputably the wisest and most powerful being in the universe. The love of this being consists in this: that we believe that whatever happens is good, being sufficiently contented, uncomplaining, uncritical, unopposing, and not engaging in useless protests, and that we do not neglect the opportunity to promote the good of everyone. If we should violate these laws, we should try with all our power to realign ourselves with them.<br /><br /><br />
                &#169; Lloyd Strickland 2018
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
