import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/exercises.pdf'; 
import { Helmet } from 'react-helmet';

function SpiritualExercises() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series VI volume 4</var><br />
        Deutsche Akademie der Wissenschaften (ed)<br />
        p 2717<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: November 1677<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (85k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>On Spiritual Exercises</title>
            <meta name="description" content="An English translation of ON SPIRITUAL EXERCISES by Gottfried Wilhelm Leibniz, from November 1677" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: ON SPIRITUAL EXERCISES</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A VI 4, p2717</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The rule of those who prescribe spiritual exercises is that as far as possible one should abstain from speculations, that is, from contemplations that are remote from practice. I, though, explain it in a different way, for practical contemplations are nothing other than spiritual exercises, and if one were to abstain from all contemplation one would also abstain from exercise of the spirit. But, you will say, exercise of the spirit can be the exercise of the will without the exercise of the understanding. My response is that the will is aroused by the understanding of good and evil, that is, by the practical understanding. Therefore, spiritual exercises are practical contemplations on sacred things.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;By spiritual exercises I mean those things that are done in solitude, but not exercises of charity, justice, etc. towards one’s neighbour.


                <br /><br /><br />
		
		        &#169; Lloyd Strickland 2018. Revised 2022
              <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default SpiritualExercises;