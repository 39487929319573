import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/burnett1706.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>Die philophischen schriften von Gottfried Wilheim Leibniz, vol. III</var><br />
		C. I. Gerhardt (ed)<br />
		pp 305-308<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 26 May 1706<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (20k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Thomas Burnett (26 May 1706)</title>
            <meta name="description" content="An English translation of a LETTER TO THOMAS BURNETT by Gottfried Wilhelm Leibniz, from 26 May 1706" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO THOMAS BURNETT</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>G III p305</b>]<br /><br /><center>Hanover, 26 May 1706</center><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sir<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I have just received the honour of your letter of 6 April, together with the defence of Mr Locke written by a very spirited young lady,<a name="1" href="#note1"><sup>1</sup></a> for which I thank you with all my heart. I am surprised that you have not received the long letter I had the honour of writing to you in response to yours. I did use the address that you had stipulated, however. I had told you that I thought I had received all of yours, my paper and the sermon. I had also told you that Mr Gywnne's letter was not authorized by our court, although there are several good things in it to prove the necessity of better assuring the succession by an effective establishment of the heir. Mr Gwynne also protests that it was not he who had the letter printed. He was angry with Lord Stamford who reprimanded Mr Gwynne for the fact that he wanted to communicate to others the letter from Madam the Electress to the Archbishop, which Mr Gwynne thought necessary, and he was surprised by the conduct of this Lord. This meant that he was led, in anger, to write this long letter to him which, by I don't know what accident, was published firstly in Holland, according to reports, and then in England. Madam the Electress had written her letter to the Archbishop in order to be passed on, and in that Mr [<b>G III, p306</b>] Gwynne was right; for this Princess did not want it thought that England was indifferent to her, but she also did not want it thought that she is overly hurried to go there, and that she wanted to engage in intrigue against the Queen, for she has a great deal of amity and respect for Her Majesty, and wants that everything happen by the right way, for the security of England and the good of her house.<a name="2" href="#note2"><sup>2</sup></a> We are waiting for Lord Halifax every day. He is a Lord whose merit is recognized by everyone, and Madam the Electress will be delighted to speak with him.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mr Spanheim informed me that you had finally arrived. I think you saw him a little late. He said that the first book of his work on medals is finished, and that Mr Muliga has sent to the press the Prolegomenes of his New Testament.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I have received Chevalier Fountain's <var>Nummos Anglo-Saxonicos</var>. Mr Jacquelot informed me that he has finished his response to Mr Bayle. I see that this excellent man is also now battling with Mr le Clerc and Mr Bernard; I would like both sides to show restraint, and to end the quarrel as soon as possible.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The true way of making Mr Bayle write usefully would be to (seemingly) attack him when he writes things that are good and true, as this would be the way to provoke him into continuing. Whereas there is no need to attack him when he says bad things, for that will goad him into saying other things that are just as bad in order to support the first, with no way out.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I have been told of Mr Dodwell's pleasing book; he should be forgiven his errors in favour of his erudition.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Bishop Fuller's dogma of the pre-existence of the soul of Jesus Christ seems rather extraordinary to me. Mr Mercury van Helmont believed that the soul of Jesus Christ was that of Adam, and that the new Adam puts right what the first had spoiled, it was the same individual who paid off his ancient debt. I believe that one does well to spare oneself the trouble of refuting such thoughts.<a name="3" href="#note3"><sup>3</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I have not seen Mr Hodely's sermon; you know my view on what is owed to sovereigns. The Church should not be confused with the nation. The Church in itself is owed a passive obedience: the reign of Jesus Christ is not of this world, but nations are not obliged to let themselves fall into ruin through the whim and maliciousness of one man. [<b>G III, p307</b>] However there is no need to resort to resistance, except when things have reached dire straits.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mr Locke's death has removed my desire to publish my remarks on his works;<a name="4" href="#note4"><sup>4</sup></a> I now prefer to publish my thoughts independently of those of someone else. Perhaps Lady Masham will send me her work.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I have read Miss Trotter's book. In the dedication she exhorts Mr Locke to give demonstrations of morals. I think he would have had difficulty pulling that off. The art of demonstrating was not his forte. I hold that we are often aware, without reasoning, of what is just and unjust, just as we are aware - without reasoning - of some geometrical theorems. But it is always good to come to a demonstration. Justice and injustice do not merely depend on human nature, but on the nature of intelligent substance in general, and Miss Trotter very rightly remarks that this comes from the nature of God, and is not in any way arbitrary. The nature of God is always founded in reason.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I do not agree that immortality is only probable through the natural light, as I think it certain that the soul can only be extinguished through a miracle. I also hold that the soul is never without perceptions, although it is often without awareness since it is only aware of distinguished perceptions, which can be lacking in a dream, in an apoplexy etc. This is what Mr Locke did not fully understand: he did not explain identity very well. I am also of the opinion that thought cannot be conceived in a being which has only extension and impenetrability. The Orientals did not know metaphysics, and they have as little conception of the immateriality of God as that of the soul. It does not follow that immateriality is nothing just because people who have never thought deeply do not arrive at it straightaway; it is as if one wanted to infer that incommensurability is nothing since few people will understand it first time.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;As for the question of whether there are natural (<var>innate</var>) ideas and truths, I think that people often quarrel over a name. However I have remarked that Mr Locke has not gone deep enough into the origin of necessary truths, which do not depend on the senses, experiences or facts, but on the consideration of the nature of our soul, which is a being, a substance, possessing unity, identity, action, passion, duration etc. [<b>G III, p308</b>] We should not be surprised if these ideas, and the truths that depend on them, are found in us, although we require experiences to stimulate our reflection or attention in order make us mindful of what our own nature supplies us with. It seems to me that in all these matters Mr Locke reasoned a little carelessly, although I admit that elsewhere he said a great number of fine, profound and useful things. I have covered in these few lines the majority of points touched in the nice book by the young lady, whose mind and manners you rightly praise, Sir. Her poems will doubtless have been very fitting.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> Catharine Trotter (1679-1749). Leibniz is referring to her book <var>The Defence of Mr Locke's Essay on Human Understanding</var> (1702).<br />
                <a name="note2" href="#2">2.</a> Leibniz is here referring to Electress Sophie's letter to the Archbishop of Canterbury of November 1705, in which she expressed her willingness to travel to England if it was wished of her: 'I am ready to do anything that my friends demand of me, supposing that Parliament thinks it would be necessary for me to cross the sea.' This letter, together with one from Sir Roland Gwynne to Lord Stamford, was printed in England in 1706 as <var>A letter from Her Royal Highness, the Princess Sophia, Electress of Brunswic and Luneburg, to his Grace the Archbishop of Canterbury. With another from Hannover, written by Sir Roland Gwynne to the Right Honourable The Earl of Stamford</var> (London, 1706). As Leibniz was involved in getting these letters printed and circulated, it is rather disingenuous of him to deny all knowledge of it, as he does in this letter to Burnett.<br />
                <a name="note3" href="#3">3.</a> The reference to Paul Buchius' <var>The Divine Being</var> (London, 1693), pp123-4. This book was, according to the title page, written 'According to the PRINCIPLES of <var>F. M. B.</var> of <var>Helmont</var>.'<br />
                <a name="note4" href="#4">4.</a> Leibniz is referring here to his <var>New Essays on Human Understanding</var>, written 1703-4, but not published until 1765.<br /><br /><br />
                &#169; Lloyd Strickland 2007
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
