import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/notes.pdf'; 
import { Helmet } from 'react-helmet';

function Metaphysics() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>     
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series VI, volume 3</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 399-400<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: December 1676<br /><br />
		Translated from the Latin<br /><br />{isMobile ? '' : <hr className="centered-hr" />}
		<Link to={pdf} target="_blank">View this translation in PDF format (11k)</Link><br /><br />
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Notes on Metaphysics</title>
            <meta name="description" content="An English translation of NOTES ON METAPHYSICS by Gottfried Wilhelm Leibniz, from December 1676" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: NOTES ON METAPHYSICS</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A VI 3, p399</b>]<br /><br />
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It is not surprising that the number of all numbers, all possibilities, all relations or reflections are not distinctly understood, since they are imaginary and have nothing corresponding to them in reality. So if there is a relation between <var>a</var> and <var>b</var>, and this relation is called <var>c</var>, and if a new relation is considered between <var>a</var> and <var>c</var>, which is called <var>d</var>, and so on to infinity, it does not seem that it can be said that all these relations are true and real ideas. Perhaps only those things [<b>A VI 3, p400</b>] which can be produced are purely intelligible, that is, those which have been or will be produced.<br />
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;There is no doubt that God understands how we perceive things, just as someone who wants to give a perfect idea of a town will represent it in several ways. And this understanding of God, insofar as it understands our way of understanding, is very similar to our understanding; indeed our understanding results from it, from which we can say that God has an understanding that is in some way similar to ours. For God understands things as we do but with the difference that he understands them at the same time in infinitely many other ways, whereas we understand them in one way only.<br />
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If it can be imagined that the mind can exist without the body, then a man would do everything in the same way, as if he did not have a mind; and men would say and write the same things, being ignorant of what they do, just as when they are playing. But this fiction that the body exists without a mind is impossible.<br />
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A substance or complete being is for me that which alone involves all things, or for the perfect understanding of which, no other thing needs to be understood. A shape is not of this kind, for in order that we understand from what a shape of such and such a kind has arisen, we must have recourse to motion. Each complete being can be produced in only one way: the fact that shapes can be produced in various ways is enough to indicate that they are not complete beings.<br />
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;From this principle, that the entire effect must be equipollent to the full cause, it is demonstrated that all things are full. From this it is evident that an actual infinite exists, because the impetus is conserved with the help of surrounding matter, which continues in motion when the body stops, and so carries the body away with it. This would not happen exactly unless secondary matter may be subdivided without end. Indeed, perhaps motion could not happen otherwise (assuming that all bodies touch each other).

		<br /><br /><br />
		&#169; Lloyd Strickland 2005
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Metaphysics;