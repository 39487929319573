import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/necessitycontingency.pdf'; 
import { Helmet } from 'react-helmet';

function NecessityContingency() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series VI volume 4</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 1449-1450<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: summer 1680 - summer 1684?<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (161k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>On Necessity and Contingency</title>
            <meta name="description" content="An English translation of ON NECESSITY AND CONTINGENCY by Gottfried Wilhelm Leibniz, from summer 1680 - summer 1684?" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: ON NECESSITY AND CONTINGENCY</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A VI 4, 1449</b>]<br /><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If all propositions, even contingent ones, are resolved into identical propositions, are they not all necessary? My answer is no, for although it is certain that the more perfect will exist, the less perfect is nevertheless still possible. In propositions of fact, existence is involved, and the notion of existence is such that what exists is such a state of the universe that is pleasing to God. And God is freely pleased by what is more perfect. And so a free act is ultimately involved. But can a reason not be given for that free act? Of course: if we were to suppose a free act of God as occurring in time then the reason for it will be another preceding act of God that is equally free, and so on. If we suppose an eternal free action, what is the reason for God always performing such an action? Surely it is his very nature, that is, divine perfection, and it must be said that in contingent propositions the predicate is not in fact demonstrated from the notion of the subject, but only its reason is given, which does not necessitate but inclines.<br />
		        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Human beings act freely whenever something follows in accordance with their choice, but what happens freely in a human being happens in a body by physical necessity in accordance with the hypothesis of the divine decree.<br /><br />

                [<b>A VI 4, 1450</b>]<br /><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;What Descartes said is foolish, as if the mind could determine the course of animal spirits,<a name="1" href="#note1"><sup>1</sup></a> for it is necessary that the determination of a body is changed by another motion. And it must be known that not only the same quantity of motion is preserved in the world but also the same determination of motion overall.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTE:<br /><br />
                <a name="note1" href="#1">1.</a> Leibniz is probably thinking of Descartes' claim that “the mind does not directly move the external limbs, but simply controls the animal spirits which flow from the heart via the brain into the muscles, and sets up certain motions in them”. René Descartes, <var>The Philosophical Writings of Descartes</var>, trans. and ed. John Cottingham, Robert Stoothoff, Dugald Murdoch, and Anthony Kenny, 3 vols. (Cambridge: Cambridge University Press, 1984-1991), 161.
                <br /><br /><br />
                &#169; Lloyd Strickland 2005. Revised 2022
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default NecessityContingency;
