import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/sophie1713.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
		Shelfmark LBr 948 Bl. 5<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 4 March 1713<br /><br />
		Translated from the French<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (73k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Electress Sophie (4 March 1713)</title>
            <meta name="description" content="An English translation of a letter from GOTTFRIED WILHELM LEIBNIZ TO ELECTRESS SOPHIE, from 4 March 1713" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO ELECTRESS SOPHIE</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>LBr 948 Bl. 5r</b>]<br /><br />		

                To Madam the Electress of Brunswick <span style={{float: 'right'}}>Vienna</span><br />
                <span style={{float: 'right'}}>4 March 1713</span><br /><br />
                Madam,<br /><br />
                A young German theologian<a name="1" href="#note1"><sup>1</sup></a> from the Sch&#252;rtenberg region, who lived for a time in England, and had the honour upon his return to pass through Hanover to pay his respects to Your Electoral Highness, wrote me that my book - entitled <var>Essays on Theodicy</var> - has been effective in England for disabusing those who are led to believe by the ill-intentioned that Monsignor the Elector and the Evangelicals of the Augsburg Confession believe in impanation, i.e. a consubstantiation and union of the bread with the body of Jesus Christ in the Eucharist, and in this matter is scarcely better than the Papists, whereas in fact they believe only in a concomitance, or perception of the one when one takes the other. An English journal editor, making mention of my book in<a name="2" href="#note2"><sup>2</sup></a> a learned journal entitled <var>Memoirs of Literature</var>,<a name="3" href="#note3"><sup>3</sup></a> has cited the passage of mine in which I reject this doctrine,<a name="4" href="#note4"><sup>4</sup></a> and this young theologian showed me just how apposite this is, as Your Electoral Highness can judge by the attached paper, which is a copy of the one he sent to me.<a name="5" href="#note5"><sup>5</sup></a> He begs me to indicate his devotion to Your Electoral Highness, of whom he speaks only with outbursts of veneration.
                    Now as the English journal editor finds my explanation all the more remarkable since, according to him, it has always been believed (that is, by ill-informed<a name="6" href="#note6"><sup>6</sup></a> people) that we accept consubstantiation, it will perhaps be fitting if a theologian of ours puts together a small work in order to justify my explanation, and to show through passages from our most renowned [<b>LBr 948 Bl. 5v</b>] theologians that we have always rejected this doctrine of impanation, or consubstantiation. I am quite certain that Abb&#233; Molanus is of my view. And if Monsignor the Elector approves it, one could give the responsibility for this work, which would not be overly long, to an acquaintance of Your Electoral Highness, namely Mr Benthem, superintendent of Harburg.<a name="7" href="#note7"><sup>7</sup></a> Mr Benthem is suited to it, because he knows the English. So if Your Electoral Highness thinks it appropriate, she could sound out Monsignor the Elector about it. And His Electoral Highness can judge by this sample that even in some of my works which seem most inconsequential to him, I try to contribute to his service and to honour him.
                Finally, there are earnest hopes for the impending return of the reigning Empress. I would like Monsignor Duke Maximilian be given the responsibility to go meet her in Genes, and accompany her.
                <br /><br /><br />

                <blockquote><small>Below is the original extract from the <var>Memoirs of Literature</var> that Samuel &#220;rlsperger copied out for Leibniz, enclosing it with his letter to Leibniz of 4 January 1713.</small></blockquote><br /><br />
                [<b>LBr 948 Bl. 3v</b>]<br />
                Mem. Of Literat: Vol 1. Num. LXI. pag. 242<br /><br />
                The Author gives us an historical Account of the Controversy concerning the use of Philosophy in divinity among several Protestant Writers. That dispute was chiefly occasion'd by the Mysteries of the Trinity, and Incarnation, and the Lord's Supper. The <var>Socinians</var> have been confuted by several divines, and frequently with good success. The <var>Lutherans</var> and the Reformed agree well enough among themselves, when they attack the <var>Socinians</var>: And because the Philosophy of those Sectaries is not very exact, it has been generally run down. But the two Protestant parties fell out about the Eucharist. Whereupon <var>Theodicaeus</var> informs us that the <var>Lutherans</var> "do not approve the Doctrine of <var>Consubstantiation</var> or <var>Impanation</var>, and that it cannot be ascribed to them, but by those, who are not well acquainted with their opinion. For they do not admit the Inclusion of the Body of Christ in the Bread, nor any Union between both, but only a Concomitancy, whereby those two Substances are received at the same [<b>LBr 948 Bl. 4r</b>] Time". This Passage will appear the more curious to the Readers, because 'tis generally believ'd that the <var>Lutherans</var> teach the Doctrine of <var>Consubstantiation</var>.
                        <br /><br /><br />
                        <hr className='rightcol-hr' />
                        <br /><br />NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> Leibniz wrote above this: "Samuel &#220;rlsperger."<br />
                        <a name="note2" href="#2">2.</a> in | an English | <var>deleted</var>.<br />
                        <a name="note3" href="#3">3.</a> Leibniz is referring here to Michel de la Roche (1680-1742), a French Huguenot who settled in England at a young age and became an Anglican in 1701. He went on to make his name as editor of <var>Memoirs of Literature</var>, which ran from March 1710 to September 1714, and then again between January and April 1717.<br />
                <a name="note4" href="#4">4.</a> In issue 61 of <var>Memoirs of Literature</var>, de la Roche quoted &#167;61 of Leibniz's <var>Theodicy</var>, writing: "Theodicaeus [i.e. Leibniz] informs us, that the Lutherans 'do not approve the Doctrine of Consubstantiation or Impanation, and that it cannot be ascribed to them, but by those who are not well acquainted with their Opinion: For they do not admit the Inclusion of the Body of Christ in the Bread, nor any Union between both, but only a Concomitancy, whereby those Two substances are received at the same time'". <var>Memoirs of Literature</var> LXI (7 May 1711), p. 242.<br />
                <a name="note5" href="#5">5.</a> The extract that &#220;rlsperger made for Leibniz is reproduced below.<br />
                <a name="note6" href="#6">6.</a> ill-informed | or malicious | <var>deleted</var>.<br />
                <a name="note7" href="#7">7.</a> Heinrich Ludolf Benthem (1661-1723). A Lutheran theologian and, as Leibniz notes, superintendent of Harburg.
                <br /><br /><br />
                        &#169; Lloyd Strickland 2016
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
