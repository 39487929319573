import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/desmaizeaux1716.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>Die philophischen schriften von Gottfried Wilheim Leibniz, vol. VII</var><br />
		C. I. Gerhardt (ed)<br />
		pp 536-537<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 21 August 1716<br /><br />
		Translated from the French<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (77k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Pierre Desmaizeaux (21 August 1716)</title>
            <meta name="description" content="An English translation of a LETTER TO PIERRE DESMAIZEAUX by Gottfried Wilhelm Leibniz, from 21 August 1716" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO PIERRE DESMAIZEAUX</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>G VII p536</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I must thank you for taking my interests in hand. I formerly had the honour of some business with you, both when Mr Bayle was alive and after his death, when you asked me if I had any letters by this excellent man that would deserve to be published. But I replied to you, Sir, that those I had received from him were very short and only acknowledgements of receiving my replies to his objections, to which he replied afterwards in his works, except my last reply, to which I do not know whether he [<b>G VII p537</b>] has responded, since there is nothing of it in what he published since. That is why I am sending you this last reply, which has still not appeared in public, in order that you have the opportunity to find out if Mr Bayle has replied, which does not appear so because I have not heard anything of it since.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I hope you will have received word about what has passed between Mr Clarke and myself up to and including his fourth piece of writing, to which I reply more fully than to the others, in order to clarify the matter thoroughly and to move closer to the end of the dispute. Madam the Princess of Wales will now receive the rest of this reply, and I am also sending you half of the copy now, Sir; you will have the other half in the next post. I hope that there are many people in England who will not be of Mr Newton or Mr Clarke's opinion on philosophy, and who do not have a taste for nicely-said attractions, nor for the void, nor for the <var>sensorium</var> of God, nor for this imperfection of the universe which requires God to set it right from time to time, nor for the necessity - in which the supporters of Mr Newton find themselves - to deny the great principle of the need for a <var>sufficient reason</var>, by means of which I beat them into ruin.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;As for the translation of the <var>Theodicy</var>, I hope that Madam the Princess of Wales will allow the translator or the bookseller to dedicate it to her, and even that it is indicated in the dedication, or in some part of the preface, that in producing it he wanted to satisfy her desires. I await her sentiments on this.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It will be good perhaps to know who its translator will be. For you know, Sir, how fussy the English are now about style. And perhaps Her Royal Highness would like to be informed of that herself, for fear that some might charge her with having chosen poorly. It is true that for me, I rely on your choice, Sir, and on your superintendence, if you are quite happy to extend your kindness that far.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I could perhaps also note some places where I would like to make some slight change. I also hope that a complete copy will be used, for I have seen some where something was missing in the additions. This can be seen from the list of additional pieces located on the second page immediately before the preface.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I wish to deserve your obliging attention, and I am sincerely etc.<br /><br /><br />
                &#169; Lloyd Strickland 2003, revised 2018
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
