import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/malebranche-to-leibniz.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>S&#228;mtliche schriften und briefe series II, volume 3</var><br />
		Deutsche Akademie der Wissenschaften<br />
		pp 489-491<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 13 December 1698<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (172k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Nicolas Malebranche to Leibniz (13 December 1698)</title>
            <meta name="description" content="An English translation of A LETTER FROM NICOLAS MALEBRANCHE to Gottfried Wilhelm Leibniz, from 13 December 1698" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>NICOLAS MALEBRANCHE TO LEIBNIZ</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A II 3, p489</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sir<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I received with much joy the letter that Father Torelli sent to me on your behalf, and I am extremely obliged to you for the honour of your remembrance. I am very much persuaded, Sir, that the friendship with which you honour me is not inconstant like those which are founded only on fickle passions. It is only the love of truth that binds hearts close together. And as you do me this justice of believing that I have some love for it, I am persuaded [<b>A II 3, p490</b>] that the love you have for it will always spread as far as your very humble servant. The particular obligations that all its disciples have to you, because of the new insights you have given them for advancing in the sciences, do not allow them to be indifferent as regards your merit; and if there are some who are so, or appear so, they wrong only themselves, at least in the mind of able people. The method of the infinitely small, of which you are the inventor, is a discovery so beautiful and so fecund that it will make you immortal in the mind of the learned. But what would the integral calculus not be capable of, if you wanted to pass on to geometers a part of what you know about it? Remember, Sir, that you are as it were engaged in it, and that we impatiently await the work on <var>Scientia infiniti</var><a name="1" href="#note1"><sup>1</sup></a> you have promised us. The ingratitude of the ignorant or of jealous minds should not deprive your admirers of the good that you can do for them, without becoming less rich in it; and the truth, which you love, does not tolerate being treated in the same way as the miserly treat their riches. You know better than me, Sir, what I have the honour to say to you, and I am convinced you will appreciate in me this ardour which makes me press you and importune you to release me from my ignorance.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Having some spare time while in the country, I reread the small, mediocre treatise on the communication of motion,<a name="2" href="#note2"><sup>2</sup></a> and wanting to satisfy myself on the third law, I realized that it was not possible to match experience with Descartes' principle that absolute motion always remains the same. I have therefore amended this treatise entirely, for I am now convinced that absolute motion continually increases and decreases, and that there is only the motion of the same part which is always conserved in the impact. I have therefore thoroughly corrected this treatise, but I still do not know when it will be reprinted.<a name="3" href="#note3"><sup>3</sup></a> I tell you this, Sir, so that you continue to be persuaded that I sincerely seek the truth, and that I deserve, partly by this disposition of my mind, that you continue to love me as much as I honour you.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It seems to me there is nothing new on mathematics and physics, except for the History of the Academy of Sciences, which Mr Hamel has given us in Latin.<a name="4" href="#note4"><sup>4</sup></a> People are occupied with refuting quietism and the so-called pure love. I was even urged, despite myself, to write on this matter. A year ago I wrote a short <var>Treatise on the Love of God</var>, [<b>A II 3, p491</b>] to which I added three letters to the Benedictine Father Lamy, which I was told was printed by Plaignard in Lyon.<a name="5" href="#note5"><sup>5</sup></a> It is still not here. And as it is published without privilege, I do not know whether it will come freely to Paris. I say nothing to you about the Marquis de Hospital since he told me that he would write to you,<a name="6" href="#note6"><sup>6</sup></a> and perhaps I will put this letter in with his. I am, Sir, with much respect and all the sentiments I owe to your merit<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Your very humble servant<br />
                <div align="right">Malebranche</div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;From Paris, 13 December 1698<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To Mr Leibniz, Aulick Counsellor of Monseigneur the Duke of Hanover<br />
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTE:<br /><br />
                <a name="note1" href="#1">1.</a> 'The science of infinity'.<br />
                <a name="note2" href="#2">2.</a> Nicolas Malebranche, <var>Des loix de la communication des mouvemens</var> (Paris, 1692).<br />
                <a name="note3" href="#3">3.</a> The second edition was eventually published in 1700.<br />
                <a name="note4" href="#4">4.</a> Jean-Baptiste du Hamel, <var>Regiae Scientiarum Academiae Historia</var> (Paris, 1698).<br />
                <a name="note5" href="#5">5.</a> Nicolas Malebranche, <var>Trait&#233; de l'amour de Dieu, en quel sens il doit &#234;tre d&#233;sint&#233;ress&#233;</var> (Lyon, 1697).<br />
                <a name="note6" href="#6">6.</a> L'Hopital wrote to Leibniz on 26 December 1798. See A III 7, 963-965.<br /><br /><br />
                &#169; Lloyd Strickland 2004. Revised 2016
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
