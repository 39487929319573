import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Image from '../images/reinterpreted.jpg';
import { Helmet } from 'react-helmet';

function Reinterpreted() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function scrollToElement(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        <a href="https://www.bloomsbury.com/uk/leibniz-reinterpreted-9780826490285/" target="_blank">Order from the publisher</a><br /><br />
        <a href="http://www.amazon.co.uk/exec/obidos/ASIN/082649028X/qid=1133099529/sr=1-3/ref=sr_1_0_3/026-4249480-8978024" target="_blank">Order from Amazon (UK)</a><br /><br />
      </div>
    );
  }

  function endorsements() {
    return (
      <>
      Testimonial:<br /><br />
	&quot;An outstanding success. The author shows a capacity for writing and arguing clearly and concisely, a skill and confidence in translation, a familiarity with both central and out-of-the-way Leibniz texts, sophistication in interpretation and a good knowledge of other optimists. The book is a pleasure to read: everything is perspicuous and the work is well structured.&quot;<br />
    &nbsp;&nbsp;- Stuart Brown <var>(Professor Emeritus of Philosophy, The Open University)</var><br /><br />
    Reviews:<br /><br />
    &quot;Leibniz claimed that this was the 'best possible world', but what did he mean by the 'best'? Strickland has trawled through all of the works available so far and given the best account to date; writing with verve and polish, he makes it a thoroughly enjoyable experience.&quot;<br />
    &nbsp;&nbsp;- Patrick Madigan (Heythrop College), <var>The Heythrop Journal</var>, March 2009<br /><br />
    &quot;<var>Leibniz Reinterpreted</var> exhibits a number of strengths.  It is clearly written, and the organization of the book is stellar, for its initial entry into the topic and its subsequent order of discussion lead the reader in a logical progression.  In this respect, it would serve as a fitting introduction for the reader unfamiliar with Leibniz's optimism.  Moreover, Strickland is obviously familiar with a broad range of Leibniz's writings, many of which are not available in English, and he draws on a number of Leibniz's lesser-known excerpts in a way that helps to illuminate a number of Leibnizian topics.&quot;<br />
    &nbsp;&nbsp;- Laurence Carlin (University of Wisconsin Oshkosh), <var>Notre Dame Philosophical Reviews</var>, February 2007<br /><br />
      </>
    );
  }
  
  return (
    <div>
      <Helmet>
            <title>Leibniz Reinterpreted</title>
            <meta name="description" content="A book authored by Lloyd Strickland on 'Leibniz Reinterpreted'" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()} <hr className="centered-hr" />
                    {endorsements()}
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ REINTERPRETED</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}
                    <p>View testimonials and reviews <span style={{color: 'blue'}} onClick={() => scrollToElement('endorsements')}>here</span></p>
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                  <img src={Image} alt="Leibniz Reinterpreted" style={{ float: 'left', marginRight: '20px', height: isMobile ? '220px' : '364px', width: 'auto' }} />
                  <p style={{ margin: '10px 0px 0px 0px', fontSize: '13px' }}>Published April 2006 (UK) / June 2006 (US).</p><br></br>
                  <p>From the back cover blurb:</p>
                  <p><var>Leibniz Reinterpreted</var> tackles head-on the central idea in Leibniz's philosophy, namely that we live in the best of all possible worlds. Strickland argues that Leibniz's theory has been consistently misunderstood by previous commentators. In the process, Strickland provides both an elucidation and a reinterpretation of a number of concepts central to Leibniz's work, such as 'richness', 'simplicity', 'harmony' and 'incompossibility', and shows where previous attempts to explain these concepts have failed. Thus the book provides an unignorable reinterpretation of many of the core themes of Leibniz's philosophy.<br /><br /></p>
                  <p>This clear and concise study is tightly focused and assumes no prior acquaintance with Leibniz or optimism. It thus serves as an ideal entry point into Leibniz's philosophy.<br clear = "all" /></p>
                  <table border="0" cellpadding="10">
                    <tr>
                        <td colspan="2"><center><b>CONTENTS</b></center>
                        </td>
                    </tr>
                    <tr>	<td width="85%"><var>Acknowledgements</var></td>
                        <td align="right">ix</td>
                    </tr>

                    <tr>	<td><var>Abbreviations</var></td>
                        <td align="right">xi</td>
                    </tr>
                    <tr>
                        <td>1 Introduction</td>
                        <td align="right">1</td>
                    </tr>
                    <tr>	<td>2 The Grounds for Optimism</td>
                        <td align="right">7</td>
                    </tr>
                    <tr>	<td>3 The Perfection of Things</td>
                        <td align="right">13</td>
                    </tr>
                    <tr>	<td>4 The Perfection of Worlds I: Richness</td>
                        <td align="right">43</td>
                    </tr>
                    <tr>	<td>5 The Perfection of Worlds II: Simplicity</td>
                        <td align="right">67</td>
                    </tr>
                    <tr>	<td>6 The Harmony of Things</td>
                        <td align="right">93</td>
                    </tr>
                    <tr>	<td>7 Does the World Increase in Perfection?</td>
                        <td align="right">115</td>
                    </tr>
                    <tr>	<td>8 Conclusion</td>
                        <td align="right">143</td>
                    </tr>
                    <tr>	<td><var>Index Locorum</var><a name="star" href="#note"><sup><b>*</b></sup></a></td>
                        <td align="right">153</td>
                    </tr>
                    <tr>	
                        <td><var>Bibliography</var></td>
                        <td align="right">167</td>
                    </tr>
                    <tr>	
                        <td><var>Index</var></td>
                        <td align="right">171</td>
                    </tr>
                    <tr>
                        <td colspan="2"><br /><br /><a name="note" href="#star"><sup><b>*</b></sup></a> - Index of passages
                        </td>
                    </tr>
                  </table><br></br>
                  {isMobile ? (
                    <div id="endorsements">
                      <hr></hr><p style={{paddingTop: '10px'}}>Go back to the top of the page <span style={{color: 'blue'}} onClick={() => scrollToElement('top')}>here</span></p>
                      {endorsements()}
                    </div>
                  ) : (
                    <>
                    </>
                  )}
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Reinterpreted;