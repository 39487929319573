import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/axioms.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
        Shelfmark LH 2, 3, 1 Bl. 9<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        Date: 1701 - 1705 (?)<br /><br />
        Translated from the French<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (163k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Definitions / Axioms or Principles of Right</title>
            <meta name="description" content="An English translation of DEFINITIONS / AXIOMS OR PRINCIPLES OF RIGHT by Gottfried Wilhelm Leibniz, from 1701 - 1705 (?)" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: DEFINITIONS / AXIOMS OR PRINCIPLES OF RIGHT</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 <small>Note that partial versions of this text have been published before. The &quot;Definitions&quot; part of the text was published in Gaston Grua's <var>Textes in&#233;dits</var>, 666-667, and the &quot;Axioms or principle of right&quot; part of the text was published in Mollat's <var>Mittheilungen aus Leibnizens ungredruckten Schriften</var>, 94-95.</small><br /><br /><br />
                [<b>LH 2, 3, 1 Bl. 9r</b>]<br /><br />
                <center><var>Definitions</var></center><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Justice</var> is a constant will to act so that nobody has grounds to complain about us.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>To complain about someone</var> is to blame him for causing us harm. By <var>harm</var>, I also mean the diminution of, or the impediment to our good.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>To blame someone</var> is to claim that he<a name="1" href="#note1"><sup>1</sup></a> acts in an unreasonable manner.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A voluntary action of a person is <var>unreasonable</var><a name="2" href="#note2"><sup>2</sup></a> when appearances are that it tends against his own good.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The <var>good for someone</var> is that which contributes to his happiness.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;And the <var>evil</var> is that which is contrary to this.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Happiness</var> is the state of durable joy.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Joy</var> consists in the sensing of perfections.<a name="3" href="#note3"><sup>3</sup></a><br /><br />

                <center>Axioms or principles of right</center>
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1) It is an imperfection to take pleasure in the evil of others, and it is a perfection to take pleasure in the good of others. This principle means that God himself acts in accordance with justice and that if he did otherwise, even though he has nothing whatsoever to fear or to hope for, he would be blameworthy. This principle also obliges men, insofar as they are instructed and capable of imitating the divinity, to do good even without any other interest than that of the pleasure there is in doing it.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2) It is bad for a man that there is another man who wishes him evil, and it is good for a man that there is another man who wishes him good. This principle does not concern the divinity or other powers superior to us, to whom we can neither do good nor evil, but it does concern men and obliges them to look out for each other. From this we can draw the rules of commutative and distributive justice, albeit in an imperfect way. For when someone believes that he can do great harm to someone else with impunity and obtain a very great good for himself in the process, this principle will not be able to hold him back, whereas the preceding one will be able to hold back at least people who are generous or appreciate perfection. But, as most men are not very refined and are scarcely capable of this pleasure which comes from honesty or virtue, the preceding principle will not have a very great hold over humankind either.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3) There is a sovereign master of the universe whose perfection is supreme and who provides for the general good to the point of ensuring that the best is also the most useful to those who do it. This is the great principle that gives fulfilment to universal justice, and it means not only that one should not [<b>LH 2, 3, 1 Bl. 9v</b>] harm others in a way that, if it were known, would be blameworthy even if it could be done with impunity here below, but also means that we act contrary to justice in everything which is contrary to virtue,<a name="4" href="#note4"><sup>4</sup></a> even when our life does not harm anyone, because it is enough that it makes us more imperfect, which is capable of drawing punishment upon us from this master who governs in the most perfect way.

                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> he ǀ is wicked ǀ <var>deleted</var>.<br />
                <a name="note2" href="#2">2.</a> <var>unreasonable</var> ǀ when it is against the good of the one who acts, and when he could easily judge that it is apparently contrary to his good. ǀ <var>deleted</var>.<br />
                <a name="note3" href="#3">3.</a> perfection. ǀ (1) That one must not do harm to someone without having grounds to expect some good for oneself therefrom. ǀ <var>deleted</var>.<br />
                <a name="note4" href="#4">4.</a> virtue ǀ because God wants us to strive for perfection ǀ <var>deleted</var>.<br /><br /><br />
                &#169; Lloyd Strickland 2021
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
