import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/hertel.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>S&#228;mtliche schriften und briefe series I volumes 10 &amp; 11</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: July 1694 - January 1695<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (23k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to/from Lorenz Hertel (July 1694 - January 1695)</title>
            <meta name="description" content="An English translation LETTERS TO/FROM LORENZ HERTEL (SELECTIONS) by Gottfried Wilhelm Leibniz, from July 1694 - January 1695" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO/FROM LORENZ HERTEL (SELECTIONS)</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 <div id="note">The following exchange centres around a book entitled <var>Seder Olam</var>, traditionally (but erroneously) attributed to Francis Mercury van Helmont (1614-1698), alchemist, philosopher, and student of the Kabbalah.</div><br /><br /><br />
		        <b><center>Lorenz Hertel to Leibniz (14/24 July 1694)</center></b><br />

                [<b>A I 10, p46</b>]<br /><br />

                ...I have received in manuscript the continuation of Professor van der Hart's <var>Ephemerides</var> on Matthew, and also a refutation of his published thoughts, written by a minister of Zellerfeld.<a name="1" href="#note1"><sup>1</sup></a> The same person has also written something on the <var>Seder Olam.</var><a name="2" href="#note2"><sup>2</sup></a> I have still not had the time to browse through these two writings to know if they have gone further into the matter. You have read the <var>Seder Olam</var>. If you have the time, oblige me by telling me your opinions on this book. I will send you either the copy or the extracts of those I have just mentioned.
                <br /><br />
                <hr className='rightcol-hr'/><br />
                        <b><center>Leibniz to Hertel (16/26 July 1694)</center></b><br />

                [<b>A I 10, p49</b>]<br /><br />

                ...As for the new <var>Seder Olam</var>, which comes from the store or the cabal of Mr Helmont - it is doubtless the most pleasant of all chimeras for you and for me. Our mystics talk seriously of it, however. Among all its nonsense there is something true and solid, and the author is not devoid of intelligence. But without reading the book, one could not understand how he draws his conclusions from Holy Scripture...
                <br /><br />
                <hr className='rightcol-hr'/><br />
                        <b><center>Hertel to Leibniz (1/11 August 1694)</center></b><br />

                [<b>A I 10, p51</b>]<br /><br />

                ...Please tell me, Sir, if you think the Helmont who authored the <var>Seder Olam</var> was the father or the son. The last printing in 12&#176; has to be a second edition. Horn mentioned some part of it in chapter 2 or 3 of his <var>Orbis politicus</var>, in which he treats the origin of the American peoples. The one who [<b>A I 10, p52</b>] wrote about the <var>Seder Olam</var> and against van der Hart's <var>Ephemerides</var> is the superintendent of the clergy of the diocese of Zellerfeld.<a name="3" href="#note3"><sup>3</sup></a> I am making a copy of these two writings in order to send them to you.
                <br /><br />
                <hr className='rightcol-hr'/><br />
                        <b><center>Leibniz to Hertel (2/12 October 1694)</center></b><br />

                [<b>A I 10, p73</b>]<br /><br />

                Finally, since we are on the subject of theological curiosities, I will say that Mr van Helmont has sent to Madam the Electress a work against hell.<a name="4" href="#note4"><sup>4</sup></a> Mr Bekker chased the devils out of this world,<a name="5" href="#note5"><sup>5</sup></a> but Mr Helmont goes even further since he claims that there is no hell at all. But I fear the devil catches those who make fun of him like this.<br /><br />
                <hr className='rightcol-hr'/><br />
                        <b><center>Hertel to Leibniz (7/17 November 1694)</center></b><br />

                [<b>A I 10, p89</b>]<br /><br />

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If you could let me know something of the circumstances of Mr van Helmont's book on hell you would greatly oblige me. I don't think his aim is to utterly destroy what is called hell, but rather to combat the common errors people have on this subject. I passed on to Mr van der Hart the manuscript by Calv&#246;r, about which he wrote to you. Here is another by the same author against the <var>Seder Olam</var>, which I am sending for you to look through. You will more or less know by this example what the author is capable of, and if you want  to see anything else please just let me know.<br /><br />
                <hr className='rightcol-hr'/><br />
                        <b><center>Leibniz to Hertel (8/18 January 1695 - unsent draft)</center></b><br />

                [<b>A I 11, p20</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I send back to you, Sir, that which you loaned me,<a name="6" href="#note6"><sup>6</sup></a> and I thank you for it. I do not find it bad. As for the new <var>Seder Olam</var>, I have learned that the book was not composed by Francis Mercury van Helmont, but by one of his doctor friends. I read this book some time ago, and I find some good thoughts in it, although mixed with many fancies which are not supported on any foundation of reason or Holy Scripture, since the opinions and the expressions of the ancient Hebrew Kabbalists could not serve as sufficiently solid proof, although there are a few who imagine that these are the traditions of Moses and of ancient sages, since in effect 'Kabbalah' means 'tradition'.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The author starts with a manner of speaking which has something shocking about it, for he says that God is a necessary agent.<a name="7" href="#note7"><sup>7</sup></a> Nevertheless I agree with the critic<a name="8" href="#note8"><sup>8</sup></a> that one can give a good sense to this, and this is what charity demands. For ultimately God is always determined to do the best and most perfect. And this is not in any way contrary to freedom. For true freedom consists in the most perfect usage of the faculties.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To say that God already created other creatures before this world is something that I would not dare to condemn absolutely.<a name="9" href="#note9"><sup>9</sup></a> It seems that this is a problematic question which doesn't change anything in the system of our world, nor in the workings of our salvation. Thus one can excuse those who imagine that it is in accordance with the divine perfection to have always worked outwardly to produce the most good as is possible.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I would say as much of those who maintain that there is an infinity of creatures at the same time, and that the operations of God are never limited to a finite number of substances. It is [<b>A I 11, p21</b>] indeed ridiculous to enclose the universe in a ball, filled with a certain number of small dice or atoms to play with, and to have ideas of it as ignoble as Aristotle and King Alphonse, who were not entirely wrong to criticize the system of the world as poorly made, supposing that it was as poor as the ancients convinced themselves it was.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The author speaks inconsistently of the Messiah; sometimes he says that it is the true God, equal to the father and of same nature,<a name="10" href="#note10"><sup>10</sup></a> sometimes he makes it midway between God and creatures,<a name="11" href="#note11"><sup>11</sup></a> which is a scarcely defensible statement. It is impossible that there be a midway point between absolute being and limited being.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The author renews the opinion of Origen and some Platonists, still supported in our time by Pierre Serrarius, that there are no eternal punishments, and that all souls will ultimately be converted and restored. All that can be said about that is that it would be true if it were possible, and if divine justice could allow it. But as we do not know the depths of it, it is safer not to advance opinions which are not soundly established and can be harmful since they are capable of keeping sinners in their security. It is very true that there would not be an eternity of punishments if there were not an eternity of sins. For sinners damn themselves, so to speak, and keep themselves in damnation by continuing to sin. So how do we know that there aren't minds which always get worse and worse, rather than those which [<b>A I 11, p22</b>] always get better and better and others which vary for a time before being determined one way or the other?<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This is what I thought could be called the vestibule of the book. As for the body of the work, I do not want to enter into it as it is only supported on fancies, and at the very most on some allegories which it is claimed are drawn from passages of holy scripture, in order to prove the regeneration of the same souls in other bodies after certain intervals, and to establish certain very extraordinary opinions concerning the centuries to come. I will merely say that the vulgar chronology on which it is based is not especially secure, and more able people have more of a preference for the calculation of the LXX.<a name="12" href="#note12"><sup>12</sup></a> And when the author supports his reasonings on the movement of the eighth sphere,<a name="13" href="#note13"><sup>13</sup></a> he makes it sufficiently clear that he is not well informed of the constitution of the universe.
                <br /><br />
                <hr className='rightcol-hr'/><br />
                        <b><center>Leibniz to Hertel (8/18 January 1695 - sent version)</center></b><br />

                [<b>A I 11, p22</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sir<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Here is the critique of the new <var>Seder Olam</var> which I send back to you with thanks.<a name="14" href="#note14"><sup>14</sup></a> I find it rather moderate in several places, in proportion to the paradoxes of the book, which are capable of making ordinary theologians angry.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The book itself was written not by Francis Mercury van Helmont, as was reported to the author of the critique, but by one of his doctor friends. It contains some good thoughts, for example that God is always determined to do the most perfect, that his operations have no limits, and than there is life spread throughout the whole of nature.<a name="15" href="#note15"><sup>15</sup></a> It contains some other thoughts which I would not dare to approve or condemn absolutely. [<b>A I 11, p23</b>] But there are also some unfounded thoughts in it. For how can he know, for example, that all souls ultimately turn to the good, since it is possible that there be some which always go from bad to worse.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;But the largest part of the book contains fancies about the apocalypse and about the explanation of  prophecies, as well as on the sequence of things, which each man can invent freely.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I had hoped for the honour of seeing you, but your absence and mine too, in part, have prevented it. It will be for another time. I am nevertheless sincerely, Sir, your very humble and very obedient servant<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Leibniz<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;W&#246;lfenbuttel 8 January 1695
                <br /><br /><br />
                <hr className='rightcol-hr'/><br />
                        NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> Caspar Calv&#246;r (1650-1725), Lutheran theologian and, from 1684, superintendent of Zellerfeld.<br />
                        <a name="note2" href="#2">2.</a> <var>Seder Olam</var> [The Order of the World] (London, 1694). According to the editors of the Academy edition, Hertel may be referring to a letter from Calv&#246;r to an unknown nobleman, in which he discusses van Helmont's work.<br />
                        <a name="note3" href="#3">3.</a> Caspar Calv&#246;r (see footnote 1).<br />
                        <a name="note4" href="#4">4.</a> <var>Verhandeling van de Helle</var> [Treatise on Hell] (1694). Although sometimes attributed to van Helmont, this work is in fact a Dutch translation of (some or all of) a book by Samuel Richardson entitled <var>A Discourse of the Torments of Hell</var> (London, 1657).<br />
                        <a name="note5" href="#5">5.</a>  Leibniz is referring here to Balthasar Bekker's <var>De Betoverde Wereld</var> [The Enchanted World] (Amsterdam, 1691-1693).<br />
                        <a name="note6" href="#6">6.</a> That is, Calv&#246;r's critique of the <var>Seder Olam</var>.<br />
                        <a name="note7" href="#7">7.</a> 'Because God doth all things according to his infinite Wisdom, therefore there is no indifference of Will in him; and therefore, in all things he doth, he is a necessary Agent, and yet also the most free Agent.' <var>Seder Olam</var> p5.<br />
                        <a name="note8" href="#8">8.</a> Caspar Calv&#246;r (see footnote 1).<br />
                        <a name="note9" href="#9">9.</a> 'Therefore the Ages of Worlds, in respect of human Intellect, are truly infinite and innumerable; and that not only in regard of things to come, but in regard of things past.' <var>Seder Olam</var> p22. 'after the end of this World, another World will be prepared, wherein the Multiplication and Propagation of Mankind will proceed, and be carried on as in this present World: and therefore also before this World there did another World heretofore exist, in which the Propagation of Mankind was made by successive Generations.' <var>Seder Olam</var> p31, cf. pp18, 30.<br />
                        <a name="note10" href="#10">10.</a> 'Christ Jesus...is the first begotten Son of the most excellent and supream God himself, and by reason of his miraculous Union with the supream Deity is very God, equal with God the Father.' <var>Seder Olam</var>, p9.<br />
                        <a name="note11" href="#11">11.</a> 'Moreover, there is necessarily intercedent between God and Creatures a certain middle Being, which indeed is less than the supream Deity, but greater and more perfect than all Creatures. And this <var>Ens medium</var>, or middle Being between God and Creatures, is Christ the Heavenly <var>Adam</var>.' <var>Seder Olam</var>, p7, cf. pp9-10.<br />
                        <a name="note12" href="#12">12.</a> A reference to the Septuagint, the ancient Greek translation of the Hebrew Bible.<br />
                        <a name="note13" href="#13">13.</a> Cf. <var>Seder Olam</var>, p35.<br />
                        <a name="note14" href="#14">14.</a> See footnote 6.<br />
                        <a name="note15" href="#15">15.</a> 'For from God, who is Life himself, and the fountain of it, nothing that hath not Life, or is uncapable thereof, can proceed.' <var>Seder Olam</var>, p13.
                <br /><br /><br />
                        &#169; Lloyd Strickland 2006-7<br />
                        With thanks to Geert de Wilde
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
