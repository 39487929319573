import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/1710toland.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
		Shelfmark LBr 933 Bl. 29<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: after 25 February 1710<br /><br />
		Translated from the French<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (105k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to John Toland (after 25 February 1710)</title>
            <meta name="description" content="An English translation of a letter from GOTTFRIED WILHELM LEIBNIZ TO JOHN TOLAND, written after 25 February 1710" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO JOHN TOLAND</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>LBr 933 Bl. 29r</b>]<br /><br />		

                Sir<a name="1" href="#note1"><sup>1</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Your <var>Phillipick</var> is in season.<a name="2" href="#note2"><sup>2</sup></a> It is right to think of one's security when making peace with France, <var>ne sit novissimus error pejor priore</var>.<a name="3" href="#note3"><sup>3</sup></a>  I thank you for it, Sir, as well as for your manuscript discourse on the death of Giordano Bruno.<a name="4" href="#note4"><sup>4</sup></a> I am not surprised at his misfortune, but I am surprised at his imprudence to return to Italy after what he had written. His genius seems mediocre, and I do not think that he was a great astronomer or a great philosopher, although he had rightly concluded that each fixed star could be taken as a sun, and as a separate system. But the knowledge of Copernicus' hypothesis easily gives rise to these ideas that Bruno pushed too far. He was preoccupied with Raymond Lully's Universal Art, and he produced a kind of commentary on it. But this Art is nothing special. The best of Bruno's books are those he wrote on infinity, one in Italian, the other in<a name="5" href="#note5"><sup>5</sup></a> Latin, both of which I have. The Latin one was dedicated to the Duke of Brunswick. Still, it would not hurt to put his works together by adding short notes to them which contain a justified criticism of unacceptable passages.<a name="6" href="#note6"><sup>6</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Schoppe was right to write to Rittershausen that all heretics are called Lutherans in Italy.<a name="7" href="#note7"><sup>7</sup></a> What is more, the ignorant included even the pagans under this name, and my host at Trent, showing me some Roman antique, told me that that had been the case in the time [<b>LBr 933 Bl. 29v</b>] <var>quando gli imperatori erano ancora Luterani</var>.<a name="8" href="#note8"><sup>8</sup></a>
                        <br /><br /><br />
                        <hr className='rightcol-hr' />
                        <br /><br />NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> Sir | I thank you for your discourse | <var>deleted</var>.<br />
                        <a name="note2" href="#2">2.</a> Leibniz is referring here to Cardinal Matthew Schiner's <var>A Phillipick Oration to Incite the English against the French</var>, trans. John Toland (London, 1707).<br />
                        <a name="note3" href="#3">3.</a> 'lest this last deception be more dangerous than the preceding one'. This is a slight misquoting of the Vulgate version of Matthew 27.64.<br />
                <a name="note4" href="#4">4.</a> Leibniz is referring to Toland's <var>De genere, loco et tempore mortis Jordani Bruno Nolani</var> (1709), which can be found in <var>A Collection of Several Pieces of Mr John Toland</var> (London, 1726), I: 304-315. A partial English translation of this text is available in Bartholomew Begley, &quot;John Toland's On the manner, place and time of the death of Giordano Bruno of Nola,&quot; <var>Journal of Early Modern Studies</var> 3 (2014), 103-115.<br />
                <a name="note5" href="#5">5.</a> in | French | <var>deleted</var>.<br />
                <a name="note6" href="#6">6.</a> passages. | &#182; Your <var>Phillipick</var> is in season. It is right to think of one's security when making peace with France, <var>ne sit novissimus error pejor priore</var> [lest this last deception be more dangerous than the preceding one]. | deleted.<br />
                <a name="note7" href="#7">7.</a> Toland's account of Bruno's death quoted a letter from Caspar Schoppe to Conrad Rittershausen, dated 17 February 1600, which contains an eyewitness account. In it, Schoppe claimed that the Italians called any heretic a Lutheran. See Toland, <var>A Collection of Several Pieces</var>, I: 306.<br />
                <a name="note8" href="#8">8.</a> 'when the emperors were still Lutherans'.
                <br /><br /><br />
                        &#169; Lloyd Strickland 2016
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
