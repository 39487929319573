import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/finalcauses.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>Opera Omnia volume VI, 1</var><br />
		Ludovic Dutens (ed)<br />
		p 319<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: no later than spring 1698<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (93k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Descartes on Final Causes</title>
            <meta name="description" content="An English translation of DESCARTES ON FINAL CAUSES by Gottfried Wilhelm Leibniz, written no later than spring 1698" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: DESCARTES ON FINAL CAUSES</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>D VI 1, p319</b>]<br /><br />In Meditation 4, Descartes says: &quot;I consider the customary search for final causes to be totally useless in physics; there is considerable rashness in thinking myself capable of investigating the purposes of God.&quot;<a name="1" href="#note1"><sup>1</sup></a> This is what Descartes says, albeit rashly. For otherwise it would not be possible to admire God's wisdom, which he reveals in the admirable design of all things for his own ends, nor would doctors be able to say anything about the use of parts.
        <       br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTE:<br /><br />
                <a name="note1" href="#1">1.</a> Ren&#233; Descartes, <var>The Philosophical Writings of Descartes. Volume II</var>, eds. and trans. John Cottingham, Robert Stoothoof, and Dugald Murdoch (Cambridge: Cambridge University Press, 1984), 39.
                <br /><br /><br />
                &#169; Lloyd Strickland 2018
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
