import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/dogmas.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
         Source:<br /><br />
        <var>Textes in&#233;dits tome 1</var><br />
        Gaston Grua (ed)<br />
        pp 333, 336<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        Date: 1691 - 1695 ?<br /><br />
        Note - The italicised text within + ... + is Leibniz's own comments on the material.<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (15k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Notes on Denis Petau's "Theological Dogmas"</title>
            <meta name="description" content="An English translation of NOTES ON DENIS PETAU'S 'THEOLOGICAL DOGMAS' by Gottfried Wilhelm Leibniz, from 1691 - 1695 ?" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: NOTES ON DENIS PETAU'S <var>THEOLOGICAL DOGMAS</var><a name="1" href="#note1"><sup>1</sup></a></h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>Gr p333</b>]<br /><br />
		        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Denis P&#233;tau says in book 5 <var>on God</var> chapter 3 article 6 that the power to sin is a certain perfection...<a name="2" href="#note2"><sup>2</sup></a>  + <var>This is a difficulty: if it is a perfection then it must also be in God; my explanation solves it best, for it can be demonstrated that God is not able to do evil; in man this cannot be demonstrated, it cannot even be demonstrated with respect to an angel. Nevertheless I am unwilling to say that this is a perfection</var>. +<br /><br />
                [<b>Gr p336</b>]<br /><br />
                Plotinus was also of this opinion, <var>Enneads</var> V.5.12, who maintained that God can bring forth nothing other than that which he has produced. P&#233;tau thinks this to be impious and silly, <var>On God</var>, book 5, chapter 6, article 11.<a name="3" href="#note3"><sup>3</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;+ <var>Or does this follow: 'This proposition is necessary: God does the best. Therefore that which God does is necessary'? The inference is not valid because the conclusion follows the weaker part. But it is not demonstrable that any particular thing is the best, nor, therefore, what must be done. Or shall we rather say that this proposition, 'God does the best', is not necessary but only certain? The previous opinion seems best, because this proposition, 'A is the best', is certain, but is not necessary because it cannot be demonstrated.</var> +<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#167;11. God is not free to choose evil, but can certainly choose from infinite goods, because nothing besides God is absolutely good, let alone the best.<a name="4" href="#note4"><sup>4</sup></a> + <var>But Petau himself does not exhaust the matter. For it should be said that the lesser good has the character of evil. And so God does not choose that when something better is available, since every choice and will is made on the principle of the good. And whoever says that no creature is absolutely good, is right, but misses the point; for the question is only concerned with limited goods or creatures. And whoever denies that there is anything of the best in creatures, also errs. For it must be judged [from the outcome and] from the wisdom of God what is best among possibles, which God has chosen. Whoever thinks otherwise destroys the perfection of God. And how do we respond to Abelard? This is his argument: whatever is not best is not possible, whatever is not made is not best. Therefore whatever is not made is not possible. I deny the first premise. And so ultimately we can say that those things in which there is intrinsic badness are not possible for God, not that they are driven out by better things</var>. +<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&#167;12.<a name="5" href="#note5"><sup>5</sup></a> + <var>This is P&#233;tau's sophism: God did not just make the best, because otherwise he would have made only angels and men. For if there were only angels and men, they would not be perfect because in many ways their intelligence would be lacking.</var> +
                <br /><br /><br />
                        <hr className='rightcol-hr' />
                        <br /><br />
                        NOTE:<br /><br />
                        <a name="note1" href="#1">1.</a> (Paris 1644-1650).<br />
                        <a name="note2" href="#2">2.</a> See Denis P&#233;tau, <var>Theologica Dogmata tomus primus: in quo de deo deique proprietatibus agitur</var> (Paris, 1865, new edition), 414-15.<br />
                        <a name="note3" href="#3">3.</a> Op. cit., 439.<br />
                        <a name="note4" href="#4">4.</a> Ibid.<br />
                        <a name="note5" href="#5">5.</a> Ibid.<br />
                <br /><br /><br />
                &#169; Lloyd Strickland 2004<br />
                With gratitude to John Thorley for advice and suggestions
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
