import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/ontological.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>S&#228;mtliche schriften und briefe series II, volume 3</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 641-645<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: after May 1700<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (356k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to ???, on the Cartesian demonstration for the existence of God (after May 1700)</title>
            <meta name="description" content="An English translation of a LETTER TO ???, ON THE CARTESIAN DEMONSTRATION OF THE EXISTENCE OF GOD by Gottfried Wilhelm Leibniz, written after May 1700" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO ???, ON THE CARTESIAN DEMONSTRATION OF THE EXISTENCE OF GOD</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 <small>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The following text, a letter to an unnamed recipient, was written in response to ongoing debates about the viability of Descartes' ontological proof for the existence of God. It was prompted by Leibniz's reading of an article by Isaac Jaquelot (1647-1708) in defence of Descartes' proof against objections raised by Samuel Werenfels (1657-1740).</small><br /><br /><br />
                [<b>A II 3, p641</b>]<br />
                <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I have not yet seen this little book published in Basel in 1699, entitled <var>Judicium de argumento Cartesii pro existentia Dei petito ab ejus idea</var> [Judgement on Descartes' argument for the existence God elicited from the idea of him].<a name="1" href="#note1"><sup>1</sup></a> But having previously examined the same argument in passing in an essay on knowledge, truth, and ideas, published in the <var>Acts</var> of Leipzig in 1684,<a name="2" href="#note2"><sup>2</sup></a> I was curious to read what an able man said in the May 1700 edition of <var>l'Histoire des ouvrages de savans</var> in favour of Descartes' argument and against the Latin book from Basel.<a name="3" href="#note3"><sup>3</sup></a> And I will tell you, Sir, that I hold the middle ground between the Basel book and the response. The author of the book thinks the argument is a sophism, the author of the response takes it for a demonstration, and I believe it is an imperfect argument that tacitly presupposes a proposition, but if the proof of that were added then the demonstration would be completed. So the argument is not to be despised; it is a good start at least. <var>Est aliquid prodire tenus, si non datur ultra</var>.<a name="4" href="#note4"><sup>4</sup></a><br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Geometers, who are the real masters in the art of reasoning, have seen that in order for the demonstrations drawn from definitions to be good, one must prove, or at least postulate, that the notion included in the definition is possible. This is why Euclid put among his postulates that the circle is something possible, when expecting one to be able to draw a circle whose centre and radius are given.<a name="5" href="#note5"><sup>5</sup></a> The same precaution applies in all sorts of reasonings, and particularly in the argument of Anselm, Archbishop of [<b>A II 3, p642</b>] Canterbury (in <var>Liber contra insipientem</var> [An apology against the fool]), discussed and examined by St. Thomas and other Scholastics and revived by Mr Descartes,<a name="6" href="#note6"><sup>6</sup></a> which proves that as God is the greatest or most perfect being, he also possesses that perfection called existence, and consequently exists. To this it may be said that the reasoning is quite apposite, supposing that the supremely perfect being, i.e. the one which includes all perfections, is possible, and that it is the privilege of the divine nature (<var>of a being from itself</var>) that its essence includes existence, that is, that the supremely perfect being exists provided it is possible. And even leaving aside any mention of perfection, it may be said that, <var>if the necessary being is possible, it exists</var>, which is doubtless the most wonderful proposition and the most important one of the doctrine of modalities, because it provides a passage from potentiality to actuality. And it is only here that <var>a posse ad esse valet consequentia</var>.<a name="7" href="#note7"><sup>7</sup></a> The principle of existences is thus found within.<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The author of the Basel book offers a counter-example to Descartes by using his reasoning and generating a false conclusion. For the Basel author says that existence is contained in the idea of a very perfect body (i.e. one that includes all perfections), and therefore such a body exists.<a name="8" href="#note8"><sup>8</sup></a> In my view, the response to this should be that the idea of a very perfect body, in this sense, is impossible, for as a body is limited by its essence, it cannot contain all perfections. The Basel book and the response sink a little too much into the terms and the distinctions between essence and existence, real (or formal) and objective. I do not think it necessary to follow them there, and it is sufficient to remark that the author of the Basel book, having proposed the argument of those who say that <var>God must exist necessarily because it is not impossible that God exist</var>, has hit upon the key point. And his response - that just because we don't see the impossibility of a thing it doesn't follow that it is possible, since our knowledge is limited - is not a bad one.<a name="9" href="#note9"><sup>9</sup></a> But this could make him conclude that the argument is not a sophism, and that those who have proposed it have erred only by concealing what they suppose instead of following the example of geometers, who have enough insight to see and sincerity to expressly indicate the axioms and inquiries they need and that they presuppose.<br /><br />

                [<b>A II 3, p643</b>]<br /><br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;As far as I can understand, the author of the response does not go far enough into this. On p211 he is quite right to reject this modification, &quot;that the all-perfect being includes existence, supposing that there is an all-perfect being, that is, actual.&quot;<a name="10" href="#note10"><sup>10</sup></a> But if is meant, &quot;supposing that there is a perfect being, possible, or among the essences,&quot; then the modification is good. He is right to say it is not permissible to doubt things which are known to us, on the pretext that our knowledge is limited. But in any case this does not appear to be the intention of the author of the Basel book. And I had already remarked in my aforementioned essay that the true mark of perfectly distinct knowledge is when one can prove <var>a priori</var> the possibility of the notion in question.<a name="11" href="#note11"><sup>11</sup></a> Thus, one is deeply mistaken here in attributing a clear and distinct notion when it cannot be verified by the mark which is essential to it.<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The example of the proposition that &quot;two and two is four,&quot; is not appropriate here,<a name="12" href="#note12"><sup>12</sup></a> since this can be demonstrated through definitions whose possibility is recognized. Here is the demonstration: the definitions are, <var>first</var>, 2 is 1 + 1, and <var>second</var>, 3 is 2 + 1, and <var>third</var>, 4 is 3 + 1. Now 2 + 2 is as much (by the first definition) as 2 + 1 + 1, that is (by the second definition), 3 + 1 or else (by the third definition), 4. Which was to be demonstrated. If everything that some people boast of knowing clearly and distinctly was demonstrated in the same way then it may be trusted, but on the pretext that exceedingly clear things do not need demonstration, such people often take as clear what is not clear enough. And this abuse of supposedly clear and distinct ideas and truths means that the late Mr. Stillingfleet, Bishop of Worcester, and others were right to rise up against the way of ideas which is in vogue today, and which is often a refuge of ignorance, as were the occult qualities of former times.<br /><br />

                [<b>A II 3, p644</b>]<br /><br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It must be confessed that the famous Mr. Locke in his <var>Essay concerning Human Understanding</var>, and in his writings against the late Mr. de Worcester,<a name="13" href="#note13"><sup>13</sup></a> was right to make the apology for ideas, but he did not do what is required here to show their proper use; since instead of simply saying that truths are only the agreement or disagreement of ideas, he would have to actually show it, that is, he would have to demonstrate the axioms by possible definitions, in the way I did with regard to the proposition that &quot;two and two is four.&quot; This is the only way to convince those who claim that, besides ideas or definitions, we need to use axioms, but also to rectify the method of ideas, quite questionable until now.<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In fact, it must be admitted that those who follow the way of ideas are even accustomed to abuse a principle which benefits them when they are stopped in their reasoning, for when alleging that everything that is clearly and distinctly conceived is true, they consider themselves exempt from proving what they claim to be evident. But this principle scarcely benefits anything but illusions so long as we do not have a mark of what is clear and distinct, which Descartes has not given us. The mark I have proposed of distinct knowledge of a notion is that its possibility can be shown; and the mark of distinct knowledge of a truth is that it can be demonstrated through definitions of possible notions. Thus these provocations with clear and distinct ideas and knowledge are useless, or rather damaging, and instead we should have recourse to the methods of logicians and geometers.<br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To return to the author of the response, it seems that on p210 he tries to prove that there is an idea of an all-perfect being, but in saying that the idea of being in general leads him to it,<a name="14" href="#note14"><sup>14</sup></a> he would have done well to show how. And when he says on p212 that it is indisputable that independence, or existence from oneself, is the first of the perfections,<a name="15" href="#note15"><sup>15</sup></a> he always presupposes this very possibility [<b>A II 3, p645</b>] which is in question. I do not know what he means on p216 by &quot;an arbitrary idea of the human mind,&quot; when he wants to prove that the idea of the all-perfect being is not arbitrary.<a name="16" href="#note16"><sup>16</sup></a> For all possible ideas are independent of the human mind, and those that are impossible are not arbitrary either, since it is not in our power to conceive them. He says on p220 that if anyone denied that two and two is four, it could not be proved to him, because the obviousness of this proposition is the only proof of it.<a name="17" href="#note17"><sup>17</sup></a> But I have just shown the contrary so that henceforth one does not seek to hide his suppositions without supposed obviousness. It must be admitted, however, that even without giving a metaphysical demonstration of the possibility of the all-perfect being, there is a very great presumption of it, which can go as far as a moral certainty, without speaking now of perfect demonstrations of the existence of God which can be drawn <var>a posteriori</var>, that is, from effects. And I do not doubt that we can reach a perfect demonstration of this possibility, after which the <var>a priori</var> demonstration begun by St. Anselm and supported by Mr. Descartes would be completed as rigorously as any geometrical demonstration.
                        <br /><br /><br />
                        <hr className='rightcol-hr' />
                        <br /><br />
                        NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> [Samuel Werenfels], <var>Judicium de argumento Cartesii pro existentia Dei petito ab ejus idea</var> (Basel: Joh. Conradum &#224; Mechel, 1699).<br />
                        <a name="note2" href="#2">2.</a> Gottfried Wilhelm Leibniz, &quot;Meditationes de cognitione, veritate, et ideis,&quot; <var>Acta Eruditorum</var> (November 1684), 537-542. English translation: &quot;Meditations on knowledge, truths, and ideas,&quot; in Gottfried Wilhelm Leibniz, <var>Philosophical Papers and Letters</var>, trans. Leroy E. Loemker (Dordrecht: Springer, 1969, 2ed.), 291-294.<br />
                        <a name="note3" href="#3">3.</a> Leibniz is referring here to [Isaac Jaquelot], &quot;<var>Examen d'un Ecrit qui a pour titre</var>, Judicium de argumento Cartesii pro existentia Dei petito ab ejus idea. Basileae apud Johann. Conradum &#224; Mechel. An 1699.&quot;, <var>Histoire des ouvrages des savans</var> (May 1700), 199-222.<br />
                        <a name="note4" href="#4">4.</a> &quot;It is something to proceed thus far, even if we are not allowed any further.&quot; Horace, <var>Epistles</var>, I.i.32.<br />
                        <a name="note5" href="#5">5.</a> Euclid, <var>Elements</var>, 1, postulate 3: &quot;[Let it be postulated] To describe a circle with any centre or radius.&quot;<br />
                        <a name="note6" href="#6">6.</a> The original argument can be found in Anselm, <var>Proslogion seu alloquium de dei existentia</var>, II-III, and his <var>Liber contra insipientem, seu Apologeticus adversus librum precedentem</var>. It was discussed by Aquinas in <var>Summa theologiae</var>, I, q2, a1, ad2. Descartes' version of the argument can be found in the fifth of his <var>Meditations</var>; see Ren&#233; Descartes, <var>The Philosophical Writings of Descartes Volume II</var>, trans. John Cottingham, Robert Stoothoof, and Dugald Murdoch (Cambridge: Cambridge University Press, 1984), 45-47.<br />
                        <a name="note7" href="#7">7.</a> &quot;we can move validly from possibility to being.&quot;<br />
                        <a name="note8" href="#8">8.</a> &quot;But if we can conclude a falsehood from an entirely similar argument, it follows that it [Descartes' argument] is both sophistical and invalid. Behold, then, a similar argument:<br />
                <var>What I clearly and distinctly perceive to be contained in the idea of something is to be affirmed of that thing</var>.<br />
                <var>I clearly and distinctly conceive that existence is contained in the idea of a most perfect body</var>.<br />
                <var>Therefore existence is to be affirmed of a most perfect body</var>. Or what is the same, <var>a most perfect body exists</var>.&quot;<br />[Werenfels], <var>Judicium de argumento Cartesii pro existentia Dei</var>, 8.<br />
                        <a name="note9" href="#9">9.</a> [Werenfels], <var>Judicium de argumento Cartesii pro existentia Dei</var>, 15-16.<br />
                        <a name="note10" href="#10">10.</a> &quot;I consider this idea of an <var>all-perfect being</var> as I examine the idea of a triangle. And as I recognize that <var>existence</var> is included in this idea of an <var>all-perfect being</var> as necessarily as - and more clearly than - is the equality of the three angles of a triangle to two right angles, I conclude with as much necessity and more obviousness that an <var>all-perfect being must necessarily exist</var>, that is, that <var>there is an all-perfect being</var>, just as I conclude that a triangle's three angles are equal to two right angles.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;These two arguments are so similar that no difference between them can be found. No suppositions are being made here; those who imagine that we must be implying &quot;if there is an all-perfect being,&quot; are mistaken. For just as when I say that &quot;the whole is greater than its part&quot; I am not speaking of the existence of any particular thing which subsists, but affirm only that &quot;the nature of the whole is to be greater than its part,&quot; a proposition which includes a truth independently of any idea and any understanding, likewise when I say that &quot;an all-perfect being necessarily includes existence,&quot; I simply wish to affirm that the nature of an all-perfect being necessarily contains in itself existence, which is true independently of any idea and any understanding.&quot;<br />[Jaquelot], &quot;Examen d'un ecrit,&quot; 210-211.<br />
                        <a name="note11" href="#11">11.</a> Leibniz, &quot;Meditations on knowledge, truths, and ideas,&quot; 293.<br />
                        <a name="note12" href="#12">12.</a> The example is used in [Jaquelot], &quot;Examen d'un ecrit,&quot; 218.<br />
                        <a name="note13" href="#13">13.</a> See John Locke, <var>A Letter to the Right Reverend Edward Ld Bishop of Worcester concerning Some Passages Relating to Mr. Locke's Essay of Humane Understanding: in a Late Discourse of his Lordships, in Vindication of the Trinity</var> (London: H. Clark, 1697), and <var>Mr Locke's Reply to the Right Reverend the Lord Bishop of Worcester's Answer to his Second Letter</var> (London: H. C., 1699).<br />
                        <a name="note14" href="#14">14.</a> &quot;this idea of <var>being</var> in general permits me, or rather leads me, to the idea of an <var>all-perfect being</var>. For if I have only the idea of an <var>imperfect being</var>, such as myself, this idea of imperfect being necessarily produces the idea of an <var>all-perfect being</var>.&quot; [Jaquelot], &quot;Examen d'un ecrit,&quot; 210.<br />
                        <a name="note15" href="#15">15.</a> &quot;And it is true that <var>the whole is greater than its part</var>, even if there were no idea of a <var>whole</var> or of a <var>part</var>; likewise, it is also certain that <var>existence</var> belongs necessarily to a <var>perfect being</var>, since <var>independence</var>, or <var>existence through oneself</var>, is the first of the perfections, which is incontestably true, even if there were be no idea of the all-perfect being.&quot; [Jaquelot], &quot;Examen d'un ecrit,&quot; 212.<br />
                        <a name="note16" href="#16">16.</a> &quot;It remains only to consider if the idea of the all-perfect being is an arbitrary idea of the human mind or an idea which presents itself to the understanding.&quot; [Jaquelot], &quot;Examen d'un ecrit,&quot; 216.<br />
                        <a name="note17" href="#17">17.</a> &quot;If such a man took it upon himself to deny that <var>two and two is four</var>, or that <var>it is impossible that what has been done has not been done</var>, it could not be proved to him since the obviousness and clarity of these propositions is the only proof of them.&quot; [Jaquelot], &quot;Examen d'un ecrit,&quot; 220.<br /><br /><br />
                        &#169; Lloyd Strickland 2017
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
