const pagesContext = require.context('../pages', false, /\.jsx$/);

const pages = {};
pagesContext.keys().forEach(key => {
  const fileName = key.replace(/^\.\/(.*)\.jsx$/, '$1');
  pages[fileName] = require(`../pages/${fileName}`).default;
});

export default pages;


 

