import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/theodicyreview.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>Unschuldige Nachrichten</var> (1710)<br />
        pp 405-410<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        Date: 1710<br /><br />
        Translated from the German by Dr Julia Weckend<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (375k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Review of Leibniz's "Theodicy" (1710)</title>
            <meta name="description" content="An English translation of REVIEW OF LEIBNIZ'S THEODICY, from 1710" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>REVIEW OF LEIBNIZ'S <var>THEODICY</var></h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
		        <center>News.<br /><br />
                I.<br />
                <var>Essais de Theodic&#233;e<br />
                sur la Bont&#233; de Dieu, la libert&#233; del<br />
                Homme, & l'Origine d&#251; Mal</var>.<br />
                Amsterdam/1710. 8. NOV</center>
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This kind of work reveals and praises its master, a most famous poly-historian and statesman, revered by all of Europe. The dispute Mr. Bayle had with Mr. de Clerc and others has encouraged him<a name="1" href="#note1"><sup>1</sup></a> to write this very piece in defence of God's honour, as he announces in the preface, and he herewith objects that people were more preoccupied with Stoic and Turkish conceptions of fate than they realised, especially since the danger is not sufficiently present [<var>satis praesens</var>] in relation to things where, out of sheer laziness, they relish fate instead of sticking to a better diet.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The volume as a whole consists of three parts, preceded by a discourse on the harmony of reason and faith, in which he refutes Bayle's ideas on the true contradiction between so-called reason, that is, between general notions and demonstrations (because there is no mention of probabilities) and faith. This argument is well executed by the author of the <var>Historia Literaria</var>,<a name="2" href="#note2"><sup>2</sup></a> with reference on p22 to the Pietists,<a name="3" href="#note3"><sup>3</sup></a> and he reminds us on p27 and onwards in particular that Newton and other excellent new physicists had suggested that action at a distance was attributable to an action of gravity [<var>actione gravitandi</var>] etc.;<a name="4" href="#note4"><sup>4</sup></a> from this the conclusion is drawn that even the presence of the body of Christ at far greater distance [<var>praesentia corporis Christi ad plura longe distantia</var>] is not altogether ruled out by the true physics. Against Bayle, the author defends the distinction between 'beyond reason' and 'against reason' very well; he draws a lovely distinction between the incomprehensible and the absurd, and shows how inadequate everything that is brought against God really is.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In part one he reminds us initially that scruples about the origin of evil turn on two considerations, namely, that we cannot reconcile the very idea with both human freedom and God's utter perfection as it should present itself in his holiness and justice; he further reminds us that people exaggerate their evils far too much. He beautifully explains the difference between material and formal sinful acts and that God can be reconciled with one and not the other by way of a comparison between a lightly and a heavily loaded ship, since the latter would always lag behind a little. Although the water current may be a cause of its slow progress, it is not responsible for its resistance, which must be attributed to the inertia of matter. The author anchors all his answers in the hypothesis of the pre-established harmony, as he calls it, or that God would have elected and established in advance a certain harmony amongst all things as the best, and this is the present one; therefore nothing better could come about than what God does now and permits, and prayer, diligence, and everything one should be doing in the world is already included in such harmony. This way the controversy of the 'peremptory term' p169 is also touched upon, for which the author seems to have the best hypothesis.<a name="5" href="#note5"><sup>5</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In the other part, Bayle's objections are very thoroughly refuted word by word, and it is shown especially that they mostly consist of comparisons, such as the authority of a prince, general, father or medic whose duties we all understand, and that in contrast, in matters of God's highest authority, we by far do not know everything. The author often puts into focus the difference between metaphysical and moral necessity, and he points out that God cannot do and permit anything different based on the latter necessity because His wisdom demands that he always chooses the best, but that this does not result in metaphysical necessity, or Stoic fate. He treats at length of moral indifference before God's will [<var>indifferentia morali ante voluntatem Dei</var>], which he discards, and on this point he does not think much of Pufendorf who, regarding the same issues, did not delve deeply enough into these very matters, p349.<a name="6" href="#note6"><sup>6</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In part three he responds to the issue of physical evil with very nice physical observations, and strives to prove that there is less evil in the world than is generally assumed. Hereupon he explicates many difficult metaphysical points, as that of contingency, indifference, spontaneity, [causal] influx and conservation. He finally rounds everything up, following the example of Lorenzo Valla,<a name="7" href="#note7"><sup>7</sup></a> in a nice dialogue and poem by Sextus Tarquinius, who wants to know his fate, and summarily draws to a close by putting all objections in form of syllogisms, to which he responds with precision.<a name="8" href="#note8"><sup>8</sup></a> In the appendix he writes about Hobbes' book against freedom in response to Bishop Bramhall,<a name="9" href="#note9"><sup>9</sup></a> and Bishop King's book on the origin of evil,<a name="10" href="#note10"><sup>10</sup></a> gets thoroughly reviewed and to a large extent refuted.<a name="11" href="#note11"><sup>11</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Otherwise, this book is a true cornucopia of all sorts of beautiful comments on a whole spectrum of scholarship. Thus, on p102 we are reminded that Thomas Bonartes, who wrote the <var>Concordiam Scientiae cum Fide</var>, condemned by Rome, is in fact the Jesuit Thomas Barton, who died in Ireland.<a name="12" href="#note12"><sup>12</sup></a> Also, that the Jesuit Johannes David edited a kind of bibliomancy, under the title <var>Veridicus Christianus</var>.<a name="13" href="#note13"><sup>13</sup></a> A nice hypothesis, that Oromasdes and Arimanius were two despicable kings, one an Oriental, the other a Celt, can be read on p285 and onwards.<a name="14" href="#note14"><sup>14</sup></a> Against the Petersenish Apokatastasis of all things<a name="15" href="#note15"><sup>15</sup></a> is objected on p809 that the devil could not have an advantage for the very reason that he has to stay with the condemned forever; it would be a miserable gain to be punished with the [company of the] corrupted for eternity.<a name="16" href="#note16"><sup>16</sup></a> There are all kinds of strange tales about Spinoza's adversary Bredenburg, p571 and onwards,<a name="17" href="#note17"><sup>17</sup></a> that amongst other things he drew up a proof bordering on atheism, which the Jew Orabio in 1684 in his <var>Certamine Philosophica</var> and then Aubert de Vers&#233; under the name Latinus Serbattus Sartensis are said to have refuted, and on the same p574 that the imposter Lucius Antistius Constans, who wrote <var>de Jure Ecclesiasticorum</var>,<a name="18" href="#note18"><sup>18</sup></a> in fact is a Mr. Van den Hoof,<a name="19" href="#note19"><sup>19</sup></a> who sympathised with the Wittische Party.<a name="20" href="#note20"><sup>20</sup></a> In the appendix is a separate refutation of the hypothesis on human free will by Bishop King which we reviewed in 1704, p347.   
                <br /><br /><br />
                        <hr className='rightcol-hr' />
                        <br /><br />
                        NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> Namely Leibniz, the author of the book under review.<br />
                        <a name="note2" href="#2">2.</a> Namely, Leibniz; see his <var>Contemplatio de historia literaria statuque praesenti eruditionis</var> (1682). A VI 4, 451.<br />
                        <a name="note3" href="#3">3.</a> See Leibniz, <var>Theodicy</var>, &#167;15 (of the preliminary dissertation); H83.<br />
                        <a name="note4" href="#4">4.</a> See Leibniz, <var>Theodicy</var>, &#167;19 (of the preliminary dissertation); H85-86.<br />
                        <a name="note5" href="#5">5.</a> &quot;It may be said, in a sense, that the peremptory term, prescribed to man for his repentance and amendment, is certain in the sight of God, with whom all is certain. God knows when a sinner will be so hardened that thereafter nothing can be done for him: not indeed that it would be impossible for him to do penance or that sufficient grace needs must be refused to him after a certain term, a grace that never fails; but because there will be a time whereafter he will no more approach the ways of salvation. But we never have certain marks for recognizing this term, and we are never justified in considering a man utterly abandoned: that would be to pass a rash judgement.&quot; Leibniz, <var>Theodicy</var>, &#167;57; H154.<br />
                        <a name="note6" href="#6">6.</a> &quot;Herr Pufendorf appeared to be of a different opinion, which he insisted on maintaining in the face of censure from some theologians; but he need not be taken into account, not having advanced far enough in subjects of this kind. He makes a vigorous protest against the absolute decree, in his <var>Fecialis divinus</var>, and yet he approves what is worst in the opinions of the champions of this decree, and without which this decree (as others of the Reformed explain) becomes endurable.&quot; Leibniz, <var>Theodicy</var>, &#167;182; H241.<br />
                        <a name="note7" href="#7">7.</a> See Lorenzo Valla, <var>De Latinae lingae elegantia</var> (Paris, 1541).<br />
                        <a name="note8" href="#8">8.</a> &quot;Summary of the controversy, reduced to formal arguments,&quot; in H377-388.<br />
                        <a name="note9" href="#9">9.</a> &quot;Reflexions on the work that Mr. Hobbes published in English on 'Freedom, necessity and chance',&quot; in H393-404.<br />
                        <a name="note10" href="#10">10.</a> <var>De origine mali</var> (1702), by William King, Archbishop of Dublin, later translated by Edmund Law as <var>Essay on the Origin of Evil</var> (1731).<br />
                        <a name="note11" href="#11">11.</a> &quot;Observations on the book concerning 'The origin of evil', published recently in London,&quot; in H405-442.<br />
                        <a name="note12" href="#12">12.</a> &quot;The Reverend Father des Bosses ... has informed me that the real name of Bonartes was Thomas Barton, and that after leaving the Society he retired to Ireland, where the manner of his death brought about a favourable verdict on his last opinions.&quot; Leibniz, <var>Theodicy</var>, &#167;86 (of the preliminary dissertation); H121-122.<br />
                        <a name="note13" href="#13">13.</a> &quot;Father John Davidius, the Jesuit, wrote a book entitled Veridicus Christianus, which is like a kind of Bibliomancy, where one takes passages at random, after the pattern of the Tolle, lege of St. Augustine, and it is like a devotional game.&quot; Leibniz, <var>Theodicy</var>, &#167;101; H179.<br />
                        <a name="note14" href="#14">14.</a> See Leibniz, <var>Theodicy</var>, &#167;138; H210.<br />
                        <a name="note15" href="#15">15.</a> Refers to Johann Wilhelm Petersen's <a href="http://reader.digitale-sammlungen.de/de/fs1/object/display/bsb10351347_00005.html" target="_blank"><var>Myst&#275;rion Apokatastase&#333;s Pant&#333;n, Oder Das Geheimni&#223; der Wiederbringung aller Dinge, Durch Jesum Christum</var></a> (Offenbach, 1703).<br />
                        <a name="note16" href="#16">16.</a> See Leibniz, <var>Theodicy</var>, &#167;156; H221.<br />
                        <a name="note17" href="#17">17.</a> See Leibniz, <var>Theodicy</var>, &#167;373; H349-350.<br />
                        <a name="note18" href="#18">18.</a> <a href="https://archive.org/details/bub_gb_4SJmm_1LGSEC" target="_blank">https://archive.org/details/bub_gb_4SJmm_1LGSEC</a><br />
                        <a name="note19" href="#19">19.</a> See Leibniz, <var>Theodicy</var>, &#167;375; H350.<br />
                        <a name="note20" href="#20">20.</a> One of two feuding parties in Holland at the time.
                <br /><br /><br />
                        &#169; Julia Weckend 2018
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
