import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/april1713.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>Opera Omnia volume 5</var><br />
		Ludovic Dutens (ed)<br />
		p 391<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 20 April 1713<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (91k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Friedrich Wilhelm Bierling to Leibniz (20 Apr. 1713)</title>
            <meta name="description" content="An English translation of a LEIBNIZ TO FRIEDRICH WILHELM BIERLING by Gottfried Wilhelm Leibniz, from January - April 1713" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>FRIEDRICH WILHELM BIERLING TO LEIBNIZ</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>D V, p391</b>]<br /><br />
                Certainly the doctrine of the immortality of the soul - as well as that of the punishments and rewards expected after this life - contributes a great deal to keeping men in their duty, although one cannot deny that Epicurus and his followers, when it comes to their external and civil honour, are not undeserving of every praise, as indeed the example of Pomponius Atticus shows. I think, moreover, that Puffendorf and Thomasius have restricted moral philosophy to the happiness of this life alone in order not to mix reason and revelation, which are certainly not contrary principles but are nevertheless distinct. But he who contends that one cannot demonstrate the immortality of the soul by reason does not thereby deny it, just as no Christian denies the mystery of the Trinity and Incarnation even though all agree that this is not known by reason.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You say, illustrious Sir, that the divinity does not avenge itself in this life. I think that you want this to be understood with a qualification, namely that the divinity does not <var>always</var> avenge itself in this life. This gives rise to the vexed question of pagans: since there is a providence, why are the good unhappy and the wicked happy? Claudian's response, <var>tolluntur in altum ut lapsu graviore ruant</var>,<a name="1" href="#note1"><sup>1</sup></a> does not answer the question adequately. Nor is the matter exhausted by Boethius' book on <var>The Consolation of Philosophy</var>.<a name="2" href="#note2"><sup>2</sup></a> But we have the example of David, as well as other examples, from which it is evident that crimes are avenged by the divinity even in this life, although we do not always heed this on account of the weakness of our reason, which does not sufficiently grasp the order and manner of providence. I have recently asked that you give your thoughts on &#167;11 of chapter VIII of my <var>Lineamenta</var>.<a name="3" href="#note3"><sup>3</sup></a> But without a doubt other difficulties have got in the way. Farewell, illustrious Sir, and think well of me. Rinteln, 20 April 1713.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                        <br /><br />
                        NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> &quot;they [the wicked] are raised on high in order that their fall be more severe&quot;. Claudian, <var>Against Rufinus</var>, I.22-23.<br />
                        <a name="note2" href="#2">2.</a> A classic sixth-century work of Philosophy. It is available in many editions, e.g. Boethius, <var>The Consolation of Philosophy</var>, trans. P. G. Walsh (Oxford: Oxford University Press, 2008).<br />
                        <a name="note3" href="#3">3.</a> Friedrich Wilhelm Bierling, <var>Lineamenta methodi studiorum, quae, ad expeditius discendas literas elegantiores, philosophiam, et historiam, in commodum studiosae Juventutis, suorumque auditorum, et usum collegii privati, indicatis subinde lectisimis libris &amp; auctoribus</var> (Rinteln, 1711). The section in question concerns the various ways of teaching moral philosophy.
                <br /><br /><br />
                        &#169; Lloyd Strickland 2016
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
