import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/bierling1710.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>Opera Omnia volume 5</var><br />
		Ludovic Dutens (ed)<br />
		p 362<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 10 November 1710<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (73k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Friedrich Wilhelm Bierling (10 November 1710)</title>
            <meta name="description" content="An English translation of a LETTER TO FRIEDRICH WILHELM BIERLING by Gottfried Wilhelm Leibniz, from 10 November 1710" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO FRIEDRICH WILHELM BIERLING</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>D p362</b>]<br /><br />
                It is most true and in accordance with many travel reports - and travellers have even verified this to me - that Americans of that region [Canada] live together without any magistrate and yet peacefully, and that there, quarrels, hostilities and wars do not occur, or rarely occur, except between men of different nations and languages. I would almost say it is a political miracle, unknown to Aristotle and unnoticed by Hobbes. Children playing together seldom come to quarrels, and when they do become heated they are quickly separated by their peers. Even more remarkable is their modesty in the household, with nature teaching them that they should deter from incest; and a brother will not use more colourful language in the presence of his sister. Nor is it sluggishness which makes these men peaceful, for no-one is more fierce against an enemy than them. And their sense of honour is apparent in their desire for vengeance and the steadfastness - even in the midst of torments - in the way they die. If they could combine such gifts of nature with our arts, our men would pale into insignificance in comparison.<br /><br /><br />
                &#169; Lloyd Strickland 2016
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
