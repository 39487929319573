import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/distinction.pdf'; 
import { Helmet } from 'react-helmet';

function Distinction() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>S&#228;mtliche schriften und briefe series VI volume 4</var><br />
        Deutsche Akademie der Wissenschaften (ed)<br />
        pp 1368-1369<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        Date: beginning 1677 - beginning 1678 (?)<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (171k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>The Distinction Between Mind and Body</title>
            <meta name="description" content="An English translation of THE DISTINCTION BETWEEN MIND AND BODY by Gottfried Wilhelm Leibniz, from beginning 1677 - beginning 1678 (?)" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: THE DISTINCTION BETWEEN MIND AND BODY</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                  <br />
                  [<b>A VI 4, 1368</b>]<br /><br />		
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&quot;I know that I am, and yet I doubt whether there is any such thing as body in the nature of things. Therefore, I am not body.&quot;<a name="1" href="#note1"><sup>1</sup></a> This argument is weak, unless it is shown that extension in itself constitutes a complete substance. For once this is assumed, it is obvious that thought can never arise from that substance. But this has not yet been shown. And so we know this at least, that thought is not a mode of extension, but we do not know whether or not it is a mode of substance, of which extension is itself another mode.<a name="2" href="#note2"><sup>2</sup></a> For they are different things in body, namely extension and extended matter itself.<br />		
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The true method of proving the distinction between mind and body, then, is this: that it is impossible for us to ever know with certainty whether there is body. And I call &quot;body&quot; everything that is like those things we sense. But that this is impossible is evident from the fact that it is impossible for us to know with certainty about the existence of bodies in any way,<a name="3" href="#note3"><sup>3</sup></a> that is, it is impossible ever to prove by philosophical arguments whether bodies are appearances or substances.<br /><br />

                  [<b>A VI 4, 1369</b>]<br /><br />		
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I am no more certain that other people think than that brutes are sentient.<br />		
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;From the fact that I am, it can be sufficiently understood that I am not alone; for I easily understand that others can be like me, much more perfect than me, and that I cannot be the reason they exist too.<br /><br />		
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It is certain that there are appearances in me, but it does not therefore follow that there are as many substances outside me as there seem to be causes of appearances, that is, that there are as many bodies.<br />		
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In no way can natural reason prove that there are divisible or corporeal substances.
                  <br /><br /><br />
                  <hr className='rightcol-hr' />
                  <br /><br />
                  NOTES:<br /><br />
                  <a name="note1" href="#1">1.</a> This is Leibniz's summary of an argument found in several of Descartes' writings, for example in <var>Discourse on Method</var>, IV: &quot;Next I examined tentatively what I was. I saw that while I could pretend that I had no body and that there was no world and no place for me to be in, I could not for all that pretend that I did not exist. I saw on the contrary that from the mere fact that I thought of doubting the truth of other things, it followed quite evidently and certainly that I existed; whereas if I had merely ceased thinking, even if everything else I had ever imagined had been true, I should have had no reason to believe that I existed. From this I knew I was a substance whose whole essence or nature is simply to think, and which does not require any place, or depend on any material thing, in order to exist. Accordingly this 'I' - that is, the soul by which I am what I am - is entirely distinct from the body.&quot; English translation from Ren&#233; Descartes, <var>The Philosophical Writings of Descartes Volume I</var>, trans. John Cottingham, Robert Stoothoof, and Dugald Murdoch (Cambridge: Cambridge University Press, 1984), 127. See also Descartes, <var>Meditations</var> II, in Descartes, <var>The Philosophical Writings of Descartes Volume II</var>, 17–18.<br />
                  <a name="note2" href="#2">2.</a> mode. │ But that doesn't seem to be the case, because two different modes must be resolved into something common. │ <var>deleted</var>.<br />
                  <a name="note3" href="#3">3.</a> way, │ except through <var>a priori</var> reasoning, from the known nature of God │ <var>deleted</var>.<br /><br /><br />
                  &#169; Lloyd Strickland 2003. Revised 2022
                  <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Distinction;
