import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/molanusoct1698.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>S&#228;mtliche schriften und briefe series I, volume 16</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		p 177<br /><br />
		<var>Textes in&#233;dits tome 1</var><br />
		Gaston Grua (ed)<br />
		p 425<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 2/12 October 1698<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (10k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Gerhard Wolter Molanus (2/12 October 1698)</title>
            <meta name="description" content="An English translation of a LETTER TO GERHARD WOLTER MOLANUS by Gottfried Wilhelm Leibniz, from 2/12 October 1698" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO GERHARD WOLTER MOLANUS</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A I 16, p177</b>] [<b>Gr p425</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;There is no reason why you should have any regard for my teachings beyond what seems to be agreeable. Since a substance consists in productive power, its existence in general certainly will consist in the immediate application of power to work, which in the body is naturally ordered according to dimensions, though it is not supernaturally bound to them. Matter differs from spirit in that (among other things) it has resistance and parts. I would not dare to say that omnipresence is communicable, indeed neither is omniscience or omnipotence, so that, strictly speaking, human nature cannot be said to be omnipotent or omnipresent, unless we understand by such a manner of speaking that human nature is endowed with these divine attributes, so that it can acquire as much perfection as it is capable; or unless we understand it to mean nothing other than this: that the human nature of Christ is the human nature of a man who is omnipotent because he is united with the divinity, so that in fact such a statement about human nature in the abstract is explained in a concrete statement about a man, which adds nothing substantial to him. And certainly omnipotence is not applicable to human nature in itself, nor applicable to a man insofar as he is a man; and the abstracts are determined in this way, so that what may be said about them is applicable to the appropriate concrete concept, for example, what is applicable to Christ not formally because of his divinity, but insofar as he is a man, ought to be assigned to human nature.
        <br /><br /><br />
                &#169; Lloyd Strickland 2004<br />
                With gratitude to John Thorley for advice and suggesions
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
