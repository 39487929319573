import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/preparatory.pdf'; 
import { Helmet } from 'react-helmet';

function Preparatory() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series VI volume 4</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		p 2298<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 1685<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (93k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Brief Preparatory Remarks in Relation to Apologetic Works</title>
            <meta name="description" content="An English translation of BRIEF PREPATORY REMARKS IN RELATION TO APOLOGETIC WORKS by Gottfried Wilhelm Leibniz, from 1685" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: BRIEF PREPATORY REMARKS IN RELATION TO APOLOGETIC WORKS</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A VI 4, p2298</b>]<br /><br />		
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Book 4 of Augustine's <var>De trinitate</var>: &quot;No sensible person will decide against reason, no Christian against the Scriptures, no peaceful man against the Church.&quot;<a name="1" href="#note1"><sup>1</sup></a><br />		
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Apology for the Catholic faith from right reason</var>.<br />		
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;There would be no obligation to believe unless God himself, speaking in us through reason, had provided the signs by which God’s word could be distinguished from the word of an imposter. Christ himself said: &quot;if they had not heard me, they would not have sinned.&quot;<a name="2" href="#note2"><sup>2</sup></a><br />		
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Protestant Theologians themselves are compelled to separate their theory from practice, and in so doing they give testimony against themselves, for they do not grant to others the freedom they have given themselves. And when they transferred the Protestant theory about instruction into practice, Anabaptists and Quakers and others of their ilk introduced that dreadful confusion in which anything that comes to anyone’s mind is considered right to say and do, as if it were a dictate of the divine spirit. On the other hand, while the Antitrinitarians and those like them put into practice the same theory about doctrine, the mysteries of the Christian faith had already been reduced almost to nothing among them.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> Augustine, <var>De trinitate libri XV</var>, IV.6.10. English translation from Saint Augustine, <var>The Trinity</var>, ed. and trans. Stephen McKenna (Washington, D.C.: The Catholic University of America Press, 1963), 144.<br />
                <a name="note2" href="#2">2.</a> John 15.22.<br /><br /><br />
                &#169; Lloyd Strickland 2019. Revised 2022
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Preparatory;
