import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/bourguetfeb1716.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>Die philophischen schriften von Gottfried Wilheim Leibniz, vol. III</var><br />
		C. I. Gerhardt (ed)<br />
		pp 587-588<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 7 February 1716<br /><br />
		Translated from the French<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (76k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Louis Bourguet to Leibniz (7 February 1716)</title>
            <meta name="description" content="An English translation of a LETTER FROM LOUIS BOURGUET TO LEIBNIZ, from 7 February 1716" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LOUIS BOURGUET TO LEIBNIZ</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>G III p587</b>]<br /><br />
		        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I come to the curious and important article that concerns the nature of things, which you represent, Sir, as explicable by rectangle A, supposing that nature is always equally perfect, or by the ordinates of hyperbola B, supposing that perfection has been increasing for all eternity without there having been a beginning, and lastly according to the hypothesis of triangle C, supposing a beginning in things whose perfection grows, forever increasing without ever being able to attain a complete perfection, although the result is the most perfect possible because God always chooses the best possible.<a name="1" href="#note1"><sup>1</sup></a> Here are some propositions which seem to me to be able to help clarify the question, supposing for their foundation the existence of God, and that he is a being that must not be confused with, or included among, those things that compose the universe.<br />

            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. The universe is a collection of different beings, all limited, the number of which has no last term.<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. Every determined number of relations makes the individuality of beings, and distinguishes between them.<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3. The duration of these beings consists in a continual change of relations, which must necessarily be connected, which is to say that they must naturally follow one from another, so that they do not lose their numerical individuality.<br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4. Suppose an entire change of relations without any connection, and the result is another being. Apply this change to a given sequence of beings, and it will result in a new universe. And it is the idea of all these changes and of all these combinations in an infinity of given sequences, which produces in the divine understanding the knowledge of all possibles.<br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5. The possibility of all these sequences, or of all these worlds, proves that God's wisdom made a choice, and necessarily supposes that the actual world was among the number of possibles before receiving existence.<br /><br />[<b>G III p588</b>]<br /><br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6. The perfection of all beings that compose the universe consists in the agreement of their reciprocal relations, which ultimately all tend to the same end.<br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7. The end of all these relations is solely to represent the existence and attributes of God, principally his infinite power, wisdom and goodness.<br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8. At any given time, all limited beings can only respond to a limited number of relations, which prevents them from being able to receive all possible perfection at the outset.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Morges, 7 Feb. 1716
                        <br /><br /><br />
                        <hr className='rightcol-hr' />
                        <br /><br />
                        NOTE:<br /><br />
                        <a name="note1" href="#1">1.</a> Bourguet is here referring to 3 hypotheses described by Leibniz in his letter of 5 August 1715. English translation in SLT 198.<br />
                        <br /><br />
                        &#169; Lloyd Strickland 2003. Revised 2019<br />
                        With gratitude to Elizabeth Vinestock for advice and suggestions
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
