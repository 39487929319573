import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/toland1710.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>A Collection of Several Pieces of John Toland, now first publish'd from his Original Manuscripts: with some memoirs of his life and writings, volume II</var><br />
		John Toland (author), Pierre Desmaizeaux (ed)<br />
		pp 400-402<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 1 March 1710<br /><br />
		Translated from the French<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (17k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to John Toland (1 March 1710)</title>
            <meta name="description" content="An English translation of a LETTER TO JOHN TOLAND by Gottfried Wilhelm Leibniz, from 1 March 1710" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO JOHN TOLAND</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>JT II p400</b>]<br /><br />
                <div align="right">Hanover, 1 March 1710</div>
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sir,<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I have received what you sent me against Doctor Sacheverel, as well as the Sermon of the archbishop of Dublin,<a name="1" href="#note1"><sup>1</sup></a> with the refutation, for which I thank you. I found some good things in [<b>JT II p401</b>] this prelate's book on the <var>origin of evil</var>;<a name="2" href="#note2"><sup>2</sup></a> but I could not appreciate his opinion, which tends to make us believe that there is in free substances a will or choice that is not founded in the representation of the good or evil of objects, but in an I know not what arbitrary power to choose without grounds. His Sermon likewise does not satisfy me as it seems to deny that we have true notions of God's attributes.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It is true that Strabo is a good author, but nevertheless I think it can be said that he is very much mistaken in speaking about the Jews. He does not appear to be on firm ground in advancing the following points: 1, that some Edomites, chased away from Arabia, joined themselves to the Jews and took their laws;<a name="3" href="#note3"><sup>3</sup></a> 2, that the Jews are Egyptians in origin;<a name="4" href="#note4"><sup>4</sup></a> 3, that Moses was an Egyptian priest;<a name="5" href="#note5"><sup>5</sup></a> 4, that Moses believed that God is the world;<a name="6" href="#note6"><sup>6</sup></a> 5, that Moses occupied the vicinity of Jerusalem;<a name="7" href="#note7"><sup>7</sup></a> 6, that he obtained this land without a fight;<a name="8" href="#note8"><sup>8</sup></a> 7, that the land of the Jews was hardly worthy of being the subject of fights;<a name="9" href="#note9"><sup>9</sup></a> 8, that instead of weapons Moses employed the ceremonies of religion;<a name="10" href="#note10"><sup>10</sup></a> 9, that the neighbouring peoples joined themselves to him;<a name="11" href="#note11"><sup>11</sup></a> 10, that his successors introduced circumcision and the abstinence of certain meats.<a name="12" href="#note12"><sup>12</sup></a> I do not want to go over the rest, but I would not conceal the mistake that he made in a related 'fact' of his time, in believing that Herod was one of the Jews' priests or pontiffs.<a name="13" href="#note13"><sup>13</sup></a> Mr Casaubon has [<b>JT II p402</b>] even pointed out that Strabo, deceived by some other authors, confused Lake Sirbon with the Dead Sea, in which Jordan was lost.<a name="14" href="#note14"><sup>14</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The Coptic language retains much of the ancient Egyptian, and some people versed in it believe it to be rather different from Arabic.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mr Huet, being doubtless one of the more able men of our time, deserves to be spoken about with moderation.<a name="15" href="#note15"><sup>15</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;As for the Chinese, I think we should distinguish between their characters and their language. The characters in it are difficult to learn, and the Jesuits are right to maintain that one needs a lot of time so as to become able to properly understand the books on this notion; but the language is not very difficult when one has picked up its pronunciation: also it is very imperfect; scholars do not cultivate it at all because they concentrate on the characters. Father Grimaldi told me that it sometimes happens with the Chinese in conversation that they trace the characters in the air or in another way, in order to explain themselves better.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Besides I am,<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sir,
                <div align="right"><var>Your very humble and very obedient servant</var><br />
                Leibniz</div>

                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> William King.<br />
                <a name="note2" href="#2">2.</a> William King, <var>De origine mali</var> [On the origin of evil], (London, 1702).<br />
                <a name="note3" href="#3">3.</a> Strabo, <var>The Geography</var> XVI.2.34.<br />
                <a name="note4" href="#4">4.</a> Strabo, <var>The Geography</var> XVI.2.34.<br />
                <a name="note5" href="#5">5.</a> Strabo, <var>The Geography</var> XVI.2.35.<br />
                <a name="note6" href="#6">6.</a> Strabo, <var>The Geography</var> XVI.2.35.<br />
                <a name="note7" href="#7">7.</a> Strabo, <var>The Geography</var> XVI.2.36.<br />
                <a name="note8" href="#8">8.</a> Strabo, <var>The Geography</var> XVI.2.36.<br />
                <a name="note9" href="#9">9.</a> Strabo, <var>The Geography</var> XVI.2.36.<br />
                <a name="note10" href="#10">10.</a> Strabo, <var>The Geography</var> XVI.2.36.<br />
                <a name="note11" href="#11">11.</a> Strabo, <var>The Geography</var> XVI.2.36.<br />
                <a name="note12" href="#12">12.</a> Strabo, <var>The Geography</var> XVI.2.37.<br />
                <a name="note13" href="#13">13.</a> Strabo, <var>The Geography</var> XVI.2.46.<br />
                <a name="note14" href="#14">14.</a> Strabo, <var>The Geography</var> XVI.2.42.<br />
                <a name="note15" href="#15">15.</a> In his letter to Leibniz of 14 February 1710, Toland had written: 'You own that Monsieur HUET, in applying the Pagan Fables to the Person or Doctrine of MOSES, intended rather to shew his learning than his exactness; and I agree with you, that in other things he has shewn himself exact enough. But this subject, methinks, requir'd more exactness than Romances either in Love or Philosophy; and his very title of <var>Demonstrations</var> ought to have remov'd afar off every thing that was not of the utmost accuracy. But the truth of it is, that, whatever I may with you ascribe to his learning, there runs a large vein of Priestcraft throughout that tedious work, which has not charms enough to make any Infidel read it; and you, who have no superior in the Mathematical Sciences, well know, that the very arrangement of his Propositions (to say nothing of what he alledges for proof of 'em) is far from being exact.' John Toland, <var>A Collection of Several Pieces of John Toland, now first publish'd from his Original Manuscripts: with some memoirs of his life and writings</var> (London: J. Peele, 1726) vol. II, pp392-3.<br /><br /><br />
                &#169; Lloyd Strickland 2005<br />
                With thanks to Geert de Wilde
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
