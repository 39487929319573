import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/electress1706.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>Die philophischen schriften von Gottfried Wilheim 		Leibniz, vol. VII</var><br />
		C. I. Gerhardt (ed)<br />
		pp 565-566<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 6 February 1706<br /><br />
		Translated from the French<br /><br />
        Note - the portion of this text that is included in my <var>Shorter Leibniz Texts</var> is not included here.<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (11k)</Link><br /><br />
        <Link to="/slt">Back to <var>Shorter Leibniz Texts</var></Link><br /><br />
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Electress Sophie (6 February 1706)</title>
            <meta name="description" content="An English translation of a LETTER TO ELECTRESS SOPHIE by Gottfried Wilhelm Leibniz, from 6 February 1706" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO ELECTRESS SOPHIE</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>G VII p565</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I am delighted that my paper<a name="1" href="#note1"><sup>1</sup></a> served as entertainment for some moments to Your Electoral Highness and to Madam;<a name="2" href="#note2"><sup>2</sup></a> but I am also delighted that the Duke of Orl&#233;ans<a name="3" href="#note3"><sup>3</sup></a> approves it: not only because he is a distinguished prince, but because his insight matches his dignity. If the Sultan approved it, I would hardly be concerned.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This is not a small matter either, as even the Duke of Burgundy<a name="4" href="#note4"><sup>4</sup></a> and also Madam the Duchess of Maine<a name="5" href="#note5"><sup>5</sup></a> understand such deep subjects. It is important to mankind that a prince such as this Duke, who is destined to govern one day a beautiful and large area, is instructed properly in the more solid and important truths regarding the sources of things.<br /><br />

        [<b>G VII p566</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For these are the true foundations of natural theology and of all that can rightly be said about God and the soul. And knowledge of these principles, which make us think fittingly of the divine perfections, is able to give great depth to the beautiful feelings of well-born souls, which they carry with them to imitate the first intelligence in producing good through the power that is given to them.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;And one of the most important goods that distinguished persons can do for others is to spread the light, in favouring researches of the marvels of God, who shines in nature. This also contributes to virtue and health, the two most important goods of man.<br /><br />
                .....
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> Leibniz is referring to his letter to Sophie of 31 October 1705, an English translation of which is available in <var>Leibniz and the Two Sophies</var>, ed. and trans. Lloyd Strickland (Toronto: CRRS, 2011), 340-343.<br />
                <a name="note2" href="#2">2.</a> Elisabeth Charlotte, Duchess of Orl&#233;ans.<br />
                <a name="note3" href="#3">3.</a> Philippe II, Duke of Orl&#233;ans (1674-1723), son of Elisabeth Charlotte, Duchess of Orl&#233;ans.<br />
                <a name="note4" href="#4">4.</a> Louis, Duke of Burgundy.<br />
                <a name="note5" href="#5">5.</a> Anne-Louise-B&#233;n&#233;dicte de Bourbon-Cond&#233;.<br />
                <br /><br />
                &#169; Lloyd Strickland 2003
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
