import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/fardella.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>Opera philosophica</var><br />
		J. E. Erdmann (ed)<br />
		p 145<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 15 June 1697<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (9k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Michelangelo Fardella (15 June 1697)</title>
            <meta name="description" content="An English translation of a LETTER TO MICHELANGELO FARDELLA by Gottfried Wilhelm Leibniz, from 1697" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO MICHELANGELO FARDELLA</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>E p145</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Many splendid things are discovered in the writings of Plato and Augustine, but which in themselves are not, I think, sufficiently understood, and from the attempt to do so more heat than light has been generated. Regarding the nature of monads and substances that you ask about again, I should think that I will easily be able to satisfy this, if you point out what it is about them that you would like to be explained. Regarding their origin, I think I have already established that all things are without doubt perpetual and do not begin except by creation, and are not able to perish except by annihilation, that is, they neither begin nor end naturally, which is the case with aggregates. I should wish to be allowed to see first what you will say about my opinions in your Augustinian work, which you plan to write.
        <br /><br /><br />
                &#169; Lloyd Strickland 2005
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
