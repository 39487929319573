import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/apocalypse.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series IV volume 7</var><br />
        Deutsche Akademie der Wissenschaften (ed)<br />
        pp 672-673<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: not later than spring 1698<br /><br />
        Translated from the French<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (51k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>On Some Books Concerning the Apocalypse</title>
            <meta name="description" content="An English translation of ON SOME BOOKS CONCERNING THE APOCALYPSE by Gottfried Wilhelm Leibniz, from no later than Spring 1698" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: ON SOME BOOKS CONCERNING THE APOCALYPSE</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A IV 7, p672</b>]<br /><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mr Knorr of Sulzbach has written in German a <em>Commentary on the Apocalypse</em> in duodecimo, without putting his name to it.<a name="1" href="#note1"><sup>1</sup></a> He has also given us a book entitled <em>Harmonia Evangelica</em>,<a name="2" href="#note2"><sup>2</sup></a>  published in Frankfurt, the title of which gave the impression that it had been found among the manuscripts of Ussher,<a name="3" href="#note3"><sup>3</sup></a> and that it had been translated from English into German. I attach great importance to the book of the Englishman Joseph Mede, called <em>Clavis Apocalyptica</em>, which has been translated into Latin.<a name="4" href="#note4"><sup>4</sup></a> This book is very ingenious, and [its claims] very probable. It is said that Mr Jurieu used it extensively. Martin Luther's translation of the Apocalypse has expressions so strong that they would be capable of making people into visionaries. [<b>A IV 7, p673</b>] And the book in itself is written in a style so excellent, so florid, and so poetic, that people who do not understand it, or who understand only the tiniest part of it, are filled with admiration for it. For if they understood it, the admiration would cease also. Mr Thomasius, the Father, has written a nice dissertation about the duty of the prudent man concerning future contingents (<em>de officio viri prudentis circa futura contingentia</em>),<a name="5" href="#note5"><sup>5</sup></a> in which among other things he shows the different problems which could arise from the wrong interpretation of the Apocalypse.
		        <br /><br /><br />
                <hr className='rightcol-hr' />
                        <br /><br />
                        NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> Christian Knorr von Rosenroth [under the pseudonym Peganium], <em>Eigentliche Erkl&#228;rung &#252;ber die Gesichter der Offenbarung S. Johannis</em> (1670).<br />
                        <a name="note2" href="#2">2.</a> Christian Knorr von Rosenroth, <em>Harmonia evangeliorum</em> (Frankfurt, 1672).<br />
                        <a name="note3" href="#3">3.</a> That is, James Ussher (1581-1656), Primate of all Ireland<br />
                        <a name="note4" href="#4">4.</a> Joseph Mede, <em>Clavis Apocalyptica Ex Innatis Et Insitis Visionum Characteribus Eruta Et Demonstrata</em> (Cambridge, 1627).<br />
                        <a name="note5" href="#5">5.</a> Jacob Thomasius, <em>Disputatio ethica de officio hominis circa notitiam futurorum contingentium</em> (Leipzig, 1664).
                <br /><br /><br />
                        &#169; Lloyd Strickland 2013
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
