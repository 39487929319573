import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/predestination.pdf'; /*Change pdf*/
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {       /*Change left hand panel content*/
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
        Shelfmark LH 439 Bl. 75r<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        Date: December 1704 (?)<br /><br />
        Translated from the Latin<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (208k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>On the Dogma of Predestination</title> {/*Change title*/}
            <meta name="description" content="An English translation of ON THE DOGMA OF PREDESTINATION by Gottfried Wilhelm Leibniz, from December 1704 (?)" /> {/*Change meta description*/}
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: ON THE DOGMA OF PREDESTINATION</h2> {/*Change title*/}
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br /> {/*Change page content*/}
                 Background: This short piece was written on one side of a small strip of paper cut from a larger sheet. On the reverse side is written “Sir” and “Dresden, 8 December 1704”. The manuscript is filed among Leibniz's correspondence with Daniel Ernst Jablonski.<br /><br /><br />
                 [<b>LH 439 Bl. 75r</b>]<br /><br />
                <center>On the Dogma of Predestination</center><br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For the Reformed, the decree to grant salvation is prior to the decree to grant faith, that is, God wants people to be happy before [he wants them to be] good. Thus a greater Φιλανθρωπια<a name="1" href="#note1"><sup>1</sup></a> seems to be attributed to God.
We may call the divine will that has effect “effectual”.<br />
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;God wills every good that happens with an effectual will. For God wills every good that happens, and that will has effect.<br />
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;There exists an effectual will to grant faith. For the object of this will is the good, and because there are people to whom faith is given, therefore this good that God wills happens. Therefore, there exists here a will that has effect, that is, an effectual will.
The effectual will to grant faith is particular. For not everyone receives faith.<br />
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The particular effectual will to grant faith has the nature of an absolute decree, the reasons for which are hidden.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> “philanthropy”, that is, “love of humankind”.<br />
               <br /><br />
                  &#169; Lloyd Strickland 2023
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
