import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/morellmay1697.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>S&#228;mtliche schriften und briefe series I, volume 14</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 254-255<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 31 May/10 June 1697<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (12k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Andre Morell (31 May/10 June 1697)</title>
            <meta name="description" content="An English translation of a LETTER TO ANDRE MORELL by Gottfried Wilhelm Leibniz, from 31 May/10 June 1697" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO ANDR&#201; MORELL</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A I 14, p254</b>]<br /><br />
                Extract from my letter to Mr Morell<br />
                <div align="right">31 May 1697</div><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;There is a debate between the Archbishop of Cambrai and others about whether the love of God must be disinterested,<a name="1" href="#note1"><sup>1</sup></a> and by contagion the same matter has passed to England, where it has caused trouble between Dr. Sherlock and Mr Norris. Yet these gentlemen would have no need to argue if they had some distinct concepts, that is to say some good definitions, and if they would consider that when one truly loves one finds one's [<b>A I 14, p255</b>] own pleasure in the happiness of the object loved, even if one would not derive any benefit from it. And this pleasure is all the more great when the object is more amiable and its happiness greater. As love and charity is the basis of justice, I gave the definition of love that I have just expressed in the preface of my <var>Codex juris gentium</var>.<a name="2" href="#note2"><sup>2</sup></a> Now charity towards fellow man is only a result of the love of God, and God being the most amiable and most worthy of being happy, and also being the happiest absolutely, love of him gives the greatest and most solid pleasure. But one is only capable of feeling it insofar as one knows God. And St. John was right to say that there are many liars among those who claim to love God.<a name="3" href="#note3"><sup>3</sup></a> He also revealed the true sign by which we may recognize them.<a name="4" href="#note4"><sup>4</sup></a> I recognize the love of God only in those who show some fervour to obtain the good in general.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> Leibniz is referring to the dispute between Fran&#231;ois F&#233;nelon (Archbishop of Cambrai) and Jacques B&#233;nigne Bossuet (Bishop of Meaux) over disinterested love, which occurred between 1697 and 1699 following the publication of F&#233;nelon's <var>Explication des Maximes des Saints</var> [Explanation of the Maxims of the Saints] (1697). The dispute was finally halted on 12 March 1699 by the condemnation of F&#233;nelon's book by Pope Innocent XII.<br />
                <a name="note2" href="#2">2.</a> 'Preface to the <var>Diplomatic Code of People's Rights</var>' (1693), in G III pp386-389. English translation in SLT 149-152.<br />
                <a name="note3" href="#3">3.</a> 1 John 2.4.<br />
                <a name="note4" href="#4">4.</a> 1 John 2.5.<br /><br /><br />
                &#169; Lloyd Strickland 2004<br />
                With gratitude to Elizabeth Vinestock for advice and suggestions.
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
