import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/bernoulli1705.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>Die mathematische schriften von Gottfried Wilheim Leibniz, vol. III</var><br />
		C. I. Gerhardt (ed)<br />
		pp 774-775<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 30 October 1705<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (178k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Johann Bernoulli (30 October 1705)</title>
            <meta name="description" content="An English translation of a LETTER TO JOHANN BERNOULLI by Gottfried Wilhelm Leibniz, from 30 October 1705" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO JOHANN BERNOULLI</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>GM III 774</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I am sincerely glad that you have arrived safely with your family, but I admit it pains me that you have not found your brother alive.<a name="1" href="#note1"><sup>1</sup></a> From your conversation and collaboration I was hoping for great fruits for the Republic of Letters and for you both. But because God wanted otherwise, it is appropriate to conform our will to the divine.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I would like everything he has left to be preserved and put to public use. I am glad you are now enjoying your homeland and your friends,<a name="2" href="#note2"><sup>2</sup></a> and I trust you will do so for a long time. I have no doubt this agreeable condition of your affairs will be an incentive for you to pursue your most praiseworthy labours.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;My services have always been at the illustrious Hermann’s disposal.<a name="3" href="#note3"><sup>3</sup></a> So if the illustrious De Volder asks for my advice or if some other occasion to recommend him presents itself, I shall not fail to do so. I am awaiting a response to the letter I wrote him about the grief we have felt in the Berlin Society at the news of the death of your illustrious brother, and about delivering on his behalf a consolatary office to his widow, children and relatives,<a name="4" href="#note4"><sup>4</sup></a> which is above all addressed to you. By order of the Society, I reiterate that it will always keep the memory of this illustrious man. His eulogy will be written and inserted in the <var>Acta Eruditorum</var>.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;As far as I understand it, the professorships of mathematics in Groningen and Utrecht are vacant, and the one in Leiden is semi-vacant, since you speak of De Volder's feelings for you. I fear, however, that the latter is going to go to someone else.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It is remarkable that in our Germany there is hardly anyone who has made even a little headway in our analysis. That young Swabian who greeted me in Berlin and whom you also know (his name was Jenisch, if I am not mistaken),<a name="5" href="#note5"><sup>5</sup></a> seemed to have some interest in this science, but seemed not to have made great progress in it. I do not know whether Mr. Wolff, who has printed a dissertation on our calculus in Leipzig,<a name="6" href="#note6"><sup>6</sup></a> has done much more. I hope, however, that the latter will make more progress, since the former, if I am not mistaken, was above all concerned with practical matters.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A learned Scottish physician has brought me Cheyne's book on the truth of religion from nature,<a name="7" href="#note7"><sup>7</sup></a> and his response to Moivre.<a name="8" href="#note8"><sup>8</sup></a> I have not had the time to read either. I hope he has brought better things for religion than to analysis.<br /><br />

        [<b>GM III 775</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I don’t know why you think my method of presenting infinite equal [parts] to a given curve requires a very complicated calculation.<a name="9" href="#note9"><sup>9</sup></a> If it was from the fact that I did not attempt the calculation, you are holding on to a non-reason instead of the [real] reason for my omission. For often I decline to do even the easiest things because among the multitude of things I still want to do, the necessary things must be given preference. You have not brought forward any example of your construction by the motion of crawling, and have remained in general considerations no less than I, which made me less prompt to examine the matter more attentively. You rightly say about Mr. Craig that [his solution] does not satisfy; although he has done something, he has attempted too much.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I am also thinking about my earlier letters to Mr. Hermann, in which I sent him a demonstration that not only all arithmetical sequences but also [sequences] of powers, and of quantities composed from these, have periodic columns if they are expressed in binary.<a name="10" href="#note10"><sup>10</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I recently read in the Proceedings of the Royal Academy of Sciences that they still have doubts about your barometric phosphorus experiment and whether it can be performed whenever one pleases.<a name="11" href="#note11"><sup>11</sup></a> I would like to know whether they have got rid of their scruples yet. Goodbye and fare well etc.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Hanover, October 30, 1705
        <br />
        <br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> That is, Jacob Bernoulli (1655-1705), who died on 16 August 1705.<br />
                <a name="note2" href="#2">2.</a> Johann Bernoulli had travelled back to his hometown of Basel in August 1705.<br />
                <a name="note3" href="#3">3.</a> That is, Jakob Hermann (1678-1733), a student of Jacob Bernoulli. Leibniz and Hermann corresponded from 1704 until Leibniz's death in 1716.<br />
                <a name="note4" href="#4">4.</a> Leibniz is referring here to his letter to Hermann of 21 September 1705; see GM IV, 284-285.<br />
                <a name="note5" href="#5">5.</a> That is, the architect and builder Philipp Joseph Jenisch (1671-1736). Both Leibniz and Bernoulli met him in Berlin in 1703, following the completion of Jenisch's mathematical studies.<br />
                <a name="note6" href="#6">6.</a> Christian Wolff, <var>Dissertatio algebraica de algorithmo infinitesimali differentiali</var> (Leipzig: Goetze, 1704).<br />
                <a name="note7" href="#7">7.</a> George Cheyne, <var>Philosophical Principles of Natural Religion</var> (London: George Strahan, 1705).<br />
                <a name="note8" href="#8">8.</a> George Cheyne, <var>Rudimentorum methodi fluxionum inversae specimina: quae responsionem continent ad animadversiones Ab. de Moive</var> (London: George Strahan, 1705).<br />
                <a name="note9" href="#9">9.</a> See Johann Bernoulli, &quot;Motus reptorius ejusque insignis usus pro lineis curvis in unam omnibus aequalem colligendis, vel a se mutuo subtrahendis; atque hinc deducta Problematis de Transformatione Curvarum in Diario Gallico Paris. 12 Febr. 1703 propositi genuina Solutio,&quot; <var>Acta eruditorum</var> (1705), 347-360.<br />
                <a name="note10" href="#10">10.</a> See Leibniz's letter to Hermann of 26 June 1705; GM IV, 272-275.<br />
                <a name="note11" href="#11">11.</a> See Johann Bernoulli, &quot;Nouveau phosphore,&quot; in <var>Histoire de l'Academie royale des sciences. Annee MDCCI</var> (Paris: Martin, Coignard and Guerin, 1703), 1-9 (of the second part, the <var>Memoires</var>), and the essay (perhaps written by Bernard le Bovier de Fontenelle) &quot;Sur le phosphore du barometre&quot; in the same volume, 1-8 (of the first part, the <var>Histoire</var>).
        <br /><br /><br />
                &#169; Lloyd Strickland 2022
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
