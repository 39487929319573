import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/progressinfinity.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>Textes in&#233;dits tome 1</var><br />
		Gaston Grua (ed)<br />
		pp 94-95<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: c. 1694-1696<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (10k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>On Progress to Infinity</title>
            <meta name="description" content="An English translation of ON PROGRESS TO INFINITY by Gottfried Wilhelm Leibniz, from c.1694-1696" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: ON PROGRESS TO INFINITY</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>Gr p94</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If all things in the course of ascending descend again, and do not make true progress, the question is: how may progress to infinity be defined? Should it be the ascent or the descent or neither? If we say a thing is ascending, someone else will say that it descends again after long periods, even if at some time it ascends again. Therefore I say that the ascent is genuine if we can now assume that there is a point below which the thing does not descend further, and after some time, no matter how long, it arrives again at a higher point below which it does not descend further. [<b>Gr p95</b>] And so on to infinity. And conversely, the same is true of the descent. But if there is no point about which it can be said now or at any time that the thing will not be returned here, there will be a moving back and forth in which there is neither ascent nor descent.<br /><br /><br />
                &#169; Lloyd Strickland 2004<br />
                With gratitude to John Thorley for advice and suggestions
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
