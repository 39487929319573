import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/kortholt.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        <var>Opera Omnia volume 5</var><br />
		Ludovic Dutens (ed)<br />
		p 316<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
		Date: 22 March 1711<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (60k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Sebastian Kortholt (22 March 1711)</title>
            <meta name="description" content="An English translation of a LETTER TO SEBASTIAN KORTHOLT by Gottfried Wilhelm Leibniz, from 22 March 1711" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO SEBASTIAN KORTHOLT</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>D V, p316</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The material about the life of the dead according to the opinion of the ancient heathens is excellent, and worthy of you. The Englishman <em>Toland</em> somewhere wanted to persuade us that the immortality of the soul was an invention of the Egyptians,<a name="1" href="#note1"><sup>1</sup></a> but it is evident that the Greeks of the most distant times thought the same thing. <em>Lucan</em> testifies that the Celtic druids thought the same,<a name="2" href="#note2"><sup>2</sup></a> and in America itself the people of Virginia believe that the souls of the dead dwell on the other side of a great chain of mountains, and lastly there is the most ancient opinion of the Indians about the migration of souls.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> Leibniz is referring here to the second of John Toland's <em>Letters to Serena</em> (London, 1704), 19-68 (entitled 'The history of the soul's immortality among the heathens'), especially 40-42.<br />
                <a name="note2" href="#2">2.</a> An allusion to Lucan, <em>Pharsalia</em> I.451-457.
                <br /><br /><br />
                &#169; Lloyd Strickland 2013
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
