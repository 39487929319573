import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/law2.pdf'; 
import { Helmet } from 'react-helmet';

function NaturalLaw() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series VI volume 4</var><br />
		Deutsche Akademie der Wissenschaften (ed)<br />
		pp 2761-2762<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: summer 1678 - winter 1680/1?<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (147k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>On Natural Law</title>
            <meta name="description" content="An English translation of ON NATURAL LAW by Gottfried Wilhelm Leibniz, from summer 1678 - winter 1680/81?" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: ON NATURAL LAW</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 <br />
                [<b>A VI 4, 2761</b>]<br /><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Justice</var> is rightly ordered charity, that is, virtue serving reason in one person's affection towards another.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>A good person</var> is one who is endowed with justice, and thus seeks the common good as much as possible.<a name="1" href="#note1"><sup>1</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Good for society</var> is that which does more good to one than harm to another.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Bad for society</var> is that which does more harm to one than good to another.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Good</var> for someone is that which contributes more to their joy than to their sadness.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Bad</var> for someone is that which contributes more to their sadness than to their joy.<a name="2" href="#note2"><sup>2</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Happiness</var> (<var>misery</var>) is enduring joy (sadness).<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Reward</var> is a good given to a private individual by the will of society because of the good accruing to society from the will of that individual.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Punishment</var> is an evil inflicted on a private individual by the will of society because of the evil inflicted on society by the will of that individual.<br /><br />

                [<b>A VI 4, 2762</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Obligatory</var> is a good for society arising from the will of a private individual in accordance with the agent's prudence; therefore it must be such that it can be procured by rewards and punishments in accordance with right reasoning.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Forbidden</var> is a bad for society arising from the will of a private individual contrary to the agent’s prudence; therefore it must be such that it can be prevented by rewards and punishments in accordance with right reasoning.<a name="3" href="#note3"><sup>3</sup></a><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;As the necessary, contingent, possible, and impossible relate to each other, so do the obligatory, the not obligatory, the permitted, and the forbidden.<br />
                <blockquote style={{padding: isMobile ? '' : '0 70px 0 70px'}}>For obligatory is nothing other than what is necessary for a good person, and forbidden is nothing other than what is impossible for a good person. Now a good person is one whose charity is rightly ordered, that is, one who seeks the common good as much as is possible without detriment to their salvation. From this it is evident that all the theorems about modalities can be applied here, and that as many new propositions about the just and the unjust can be asserted. The same theorems can be repeated not only with respect to a good person, but also with respect to a prudent person.</blockquote>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<var>Free</var> is what is neither obligatory nor forbidden. Each [action] is presumed to be free.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                        <br /><br />
                        NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> possible. │ <var>Permitted</var> is whatever is not to be avoided. To be <var>avoided</var> is an act that, once posited, is likely to bring about evil in society. ¶ <var>Imperative</var> is an action that, once posited, is likely to bring about good in society. ¶ <var>The good (or bad) for society</var> is the difference between the sums of the goods and evils of the citizens. │ <var>deleted</var>.<br />
                        <a name="note2" href="#2">2.</a> joy. │ <var>Obligatory</var> is a voluntary action │ <var>deleted</var>.<br />
                        <a name="note3" href="#3">3.</a> reasoning. │ ¶ There is no obligation to do the impossible. ¶ For the impossible cannot be procured by any rewards or punishments. ¶ Everything necessary is permitted, that is, necessity has no law. ¶ For what is necessary cannot be prevented in any way, and consequently neither by rewards nor by punishments. │ <var>deleted</var>.<br /><br /><br />
                &#169; Lloyd Strickland 2003. Revised 2022
              <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default NaturalLaw;