import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/foucher1687.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>S&#228;mtliche schriften und briefe series II, volume 2</var><br />
		Deutsche Akademie der Wissenschaften<br />
		pp 217-218<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: July 1687<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (186k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Leibniz to Simon Foucher (July 1687)</title>
            <meta name="description" content="An English translation of a LETTER FROM LEIBNIZ TO SIMON FOUCHER, from July 1687" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ TO SIMON FOUCHER</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>A II 2, p217</b>]<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sir<br /><br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I received your letter together with Mr Thevenot's.<a name="1" href="#note1"><sup>1</sup></a> I am greatly obliged to you for your trouble. I replied to Mr Catelan in the <var>Nouvelles de la R&#233;publique des Lettres</var> at the beginning of this year,<a name="2" href="#note2"><sup>2</sup></a> and as I had touched in passing on the corrections Reverend Father Malebranche wanted to make to Mr Descartes' rules of motion, this Father replied and agreed that I was partly right.<a name="3" href="#note3"><sup>3</sup></a> But as he adds things which do not agree with my principles, I have sent to Holland a reply to his reply, which will perhaps be printed in the aforementioned <var>Nouvelles</var>.<a name="4" href="#note4"><sup>4</sup></a> I hope to have the satisfaction of making you understand my thoughts on these matters and others, and of learning your views on them.<br /><br />

        [<b>A II 2, p218</b>]<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I have seen Augustinus Steuchus Jugubinus' <var>De perenni philosophia</var>,<a name="5" href="#note5"><sup>5</sup></a> but his goal is mainly to reconcile the ancients with Christianity (which is indeed a very fine goal) rather than to elucidate the thoughts of philosophy.<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I think I noticed that Leucippus conceived something similar to Descartes' vortices; he called it &#948;&#953;&#965;&#951;&#965;, <var>vorticem</var>.<a name="6" href="#note6"><sup>6</sup></a> I have also found in the ancients the comparison with the stick Mr Descartes uses to explain vision.<a name="7" href="#note7"><sup>7</sup></a> I will also tell you that the ancients had a certain geometric analysis, completely different from algebra, and that neither Mr Descartes nor others I am aware of knew anything about it. Many meditations would be required to put it on the right path, however. One day I will be able to inform the public about it. It has quite different usages from algebra, and just as it yields to algebra in certain things, it surpasses it in others.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> Foucher's letter, which elicited this one from Leibniz, is now lost.<br />
                <a name="note2" href="#2">2.</a> &quot;Replique de M. L. &#224; M. l'Abb&#232; D. C. contenu&#235; dans une lettre ecrite &#224; l'auteur de ces Nouvelles le 9. de Janv. 1687. Touchant ce qu'a dit M. Descartes que Dieu conserve toujours dans la nature la m&#234;me quantit&#232; de mouvement&quot;, <var>Nouvelles de la R&#232;publique des Lettres</var> (1687), 131-145.<br />
                <a name="note3" href="#3">3.</a> See Nicolas Malebranche, &quot;Extrait d'une Lettre du P. M. &#224; M. l'Abb&#232; D. C.&quot;, <var>Nouvelles de la R&#232;publique des Lettres</var> (1687), 448-450.<br />
                <a name="note4" href="#4">4.</a> Leibniz is referring here to his essay &quot;Extrait d'une lettre de M. L. sur un principe g&#232;n&#232;ral, utile &#224; l'explication des loix de la nature, par la consid&#232;ration de la sagesse divine; pour servir de r&#232;plique &#224; la r&#232;ponse du R.P. M&quot;, <var>Nouvelles de la R&#232;publique des Lettres</var> (1687), 744-753. English translation in SLT 131-134.<br />
                <a name="note5" href="#5">5.</a> Augustinus Steuchus Jugubinus, <var>De perenni philosophia libri X</var> [Ten books on perennial philosophy] (Lyon, 1540). For more information on Steuchus and Leibniz on perennial philosophy, see Charles B. Schmitt, &quot;Perrenial philosophy: from Agostino Steuco to Leibniz&quot;, <var>Journal of the History of Ideas</var> 27 (1966), 505-532.<br />
                <a name="note6" href="#6">6.</a> Both the Greek &quot;&#948;&#953;&#965;&#951;&#965;&quot; and Latin &quot;<var>vorticem</var>&quot; mean &quot;vortex&quot;. Leibniz is alluding here to a summary of Leucippus' thought to be found in Diogenes Laertius' <var>Life of Leucippus</var>, from his <var>Lives of the Philosophers</var>, IX.3: &quot;many bodies, of various kinds and shapes, are borne by amputation from the infinite, into a vast vacuum; and then, they being collected together, produce one vortex; according to which they, dashing against one another, and whirling about in every direction, are separated in such a way that like attaches itself to like.&quot;<br />
                <a name="note7" href="#7">7.</a> Possibly a reference to Galen's <var>De placitis Hippocratis et Platonis</var> [On the doctrines of Hippocrates and Plato] 7.7, in which he criticizes the Stoic belief &quot;that we see by means of the surrounding air as with a walking stick.&quot; See Galen, <var>Opera Omnia</var>, ed. C. G. K&#252;hn (Leipzig, 1821-33, 20 volumes), vol. 5 (1823), 642. For Descartes' use of the comparison, see the first discourse of his <var>Optics</var>, in Descartes, <var>Discourse on Method, Optics, Geometry, and Meteorology</var>, trans. Paul J. Olscamp (Indianapolis: Hackett, 2001), 67-68.
        <br /><br /><br />
                &#169; Lloyd Strickland 2008. Revised 2016
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
