import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Image from '../images/proofsofgod.jpg';
import pdf from '../pdfs/sample.pdf';
import pdf2 from '../pdfs/proofsflyer.pdf';  
import { Helmet } from 'react-helmet';

function Proofs() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function scrollToElement(elementId) {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        <Link to={pdf} target="_blank">Download a sample (the book's front matter)</Link><br /><br />
        <Link to={pdf2} target="_blank">Flyer, with 20% discount</Link><br /><br />
        <a href="https://www.amazon.co.uk/Proofs-God-Early-Modern-Europe/dp/1481309315/ref=sr_1_1?crid=3HU91D1CIOLU8&dib=eyJ2IjoiMSJ9.WZzRN2YYSle56f_PcuRZrA.6glj6n6VX1Zsqd8avo-jJ2Tz4OOfmHkAXFSgyfFAoPU&dib_tag=se&keywords=Proofs+of+God+in+Early+Modern+Europe%3A+An+Anthology&qid=1718111992&s=books&sprefix=proofs+of+god+in+early+modern+europe+an+anthology%2Cstripbooks%2C73&sr=1-1" target="_blank">Order from Amazon (UK)</a><br /><br />
        <a href="http://www.baylorpress.com/Book/573/Proofs_of_God_in_Early_Modern_Europe.html" target="_blank">Order from the publisher</a><br /><br />
        <a href="https://link.springer.com/article/10.1007/s11153-019-09712-3" target="_blank">Review by Charles Joshua Horn, from International Journal for Philosophy of Religion</a> (subscription required for access)<br /><br />
        <a href="http://readingreligion.org/books/proofs-god-early-modern-europe" target="_blank">Review by Derek A. Michaud, from Reading Religion</a><br /><br />
      </div>
    );
  }

  function endorsements() {
    return (
      <>
      Testimonials:<br /><br />
        &quot;Strickland offers us a brilliant historical introduction to the philosophical quest to prove the existence of God, and a wonderful collection of judiciously edited texts from the work of the best philosophers in 17th and 18th century. This is a masterpiece, a dream come true for those interested in the philosophy of God in early modern European philosophy.&quot;<br />
        - Charles Taliaferro, <var>Professor and Chair of Philosophy, St. Olaf College</var><br /><br />
        &quot;After the work of Richard Popkin and Jonathan Israel, it is difficult to read early modern texts without thinking primarily of religious skepticism. For this reason, as well as for the precision and erudition of its introduction and notes, Lloyd Strickland's felicitous sourcebook meets a pressing need. All those who think that philosophical reasoning has repudiated or demonstrated God's existence must read this book. No philosophical topic, today as in early modernity, has more at stake.&quot;<br />
        - Matthew Levering, <var>James N. and Mary D. Perry Jr. Chair of Theology, Mundelein Seminary</var><br /><br />
      </>
    );
  }
  
  return (
    <div>
      <Helmet>
            <title>Proofs of God in Early Modern Europe</title>
            <meta name="description" content="A book authored by Lloyd Strickland on 'Proofs of God in Early Modern Europe'" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()} <hr className="centered-hr" />
                    {endorsements()}
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>PROOFS OF GOD IN EARLY MODERN EUROPE</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}
                    <p>View testimonials <span style={{color: 'blue'}} onClick={() => scrollToElement('endorsements')}>here</span></p>
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                  <img src={Image} alt="Proofs of God in Early Modern Europe" style={{ float: 'left', marginRight: '20px', height: isMobile ? '220px' : '364px', width: 'auto' }} />
                  <p style={{ margin: '10px 0px 0px 0px', fontSize: '13px' }}>Published August 2018 by Baylor University Press.</p><br></br>
                  <p>From the back cover blurb:</p>
                  <p><var>Proofs of God in Early Modern Europe</var> offers a fascinating window into early modern efforts to prove God's existence. Assembled here are twenty-two key texts, many translated into English for the first time, which illustrate the variety of arguments that philosophers of the seventeenth and eighteenth centuries offered for God. These selections feature traditional proofs - such as various ontological, cosmological, and design arguments - but also introduce more exotic proofs, such as the argument from eternal truths, the argument from universal aseity, and the argument <var>ex consensu gentium</var>. Drawn from the work of eighteen philosophers, this book includes both canonical figures (such as Descartes, Spinoza, Newton, Leibniz, Locke, and Berkeley) and noncanonical thinkers (such as Norris, Fontenelle, Voltaire, Wolff, Du Ch&#226;telet, and Maupertuis).<br /><br /></p>
                  Lloyd Strickland provides fresh translations of all selections not originally written in English and updates the spelling and grammar of those that were. Each selection is prefaced by a lengthy headnote, giving a biographical account of its author, an analysis of the main argument(s), and important details about the historical context. Strickland's introductory essay provides further context, focusing on the various reasons that led so many thinkers of early modernity to develop proofs of God's existence.<br /><br />
                  <var>Proofs of God</var> is perfect for both students and scholars of early modern philosophy and philosophy of religion.<br></br><br clear = "all" />
                <><b>CONTENTS</b><br /><br />
                Preface<br />
                Introduction: Proving God in Early Modern Europe<br />
                A Note on the Texts in This Volume<br /><br />
                <b>PART I - Classic Presentations of the Traditional Physical and Metaphysical Proofs</b><br /><br></br>
                <p>A. Metaphysical Proofs</p>
                &#167;1. Four Proofs of God's Existence (1644)<br />
                Ren&#233; Descartes<br /><br />
                &#167;2. Concerning God (1677)<br />
                Baruch Spinoza<br /><br />
                &#167;3. Of Our Knowledge of the Existence of God (1690)<br />
                John Locke<br /><br />
                &#167;4. On the Ultimate Origination of Things (1697)<br />
                Gottfried Wilhelm Leibniz<br /><br />
                <p>B. Physical Proofs</p>
                &#167;5. The Best Argument for a God (1675)<br />
                Isaac Newton<br /><br />
                &#167;6. The True Author of the World (1706)<br />
                Isaac Newton<br /><br />
                &#167;7. Demonstration of God's Existence (1713)<br />
                Fran&#231;ois de Salignac de La Mothe-F&#233;nelon<br /><br />
                <b>PART II - Alternative Presentations of the Traditional Physical and Metaphysical Proofs</b><br /><br></br>
                &#167;8. What God Is and That He Is (1662)<br />
                Henry More<br /><br />
                &#167;9. On the Cartesian Demonstration for the Existence of God (1678)<br />
                Gottfried Wilhelm Leibniz<br /><br />
                &#167;10. On the Possibility and Existence of God (1701)<br />
                Gottfried Wilhelm Leibniz<br /><br />
                &#167;11. On the Existence of God (late 1690s?)<br />
                Bernard le Bovier de Fontenelle<br /><br />
                &#167;12. How One Can Be Assured through Reason That God Exists (1704)<br />
                Pierre Sylvan Regis<br /><br />
                &#167;13. Vision as Divine Language (1732)<br />
                George Berkeley<br /><br />
                &#167;14. What Is Our Evidence of a God? (1732)<br />
                Susanna Newcome<br /><br />
                &#167;15. Whether There Is a God (1734)<br />
                Voltaire<br /><br />
                &#167;16. A Necessary Being Exists (1736)<br />
                Christian Wolff<br /><br />
                &#167;17. On the Existence of God (1740)<br />
                &#201;milie du Ch&#226;telet<br /><br />
                &#167;18. Essay on Cosmology (1751)<br />
                Pierre Louis Moreau de Maupertuis<br /><br />
                <b>PART III - Other Metaphysical and Moral Proofs</b><br /><br></br>
                &#167;19. On God, Creator of the Soul and the Body, and Author of Their Life (1670)<br />
                Jacques-B&#233;nigne Bossuet<br /><br />
                &#167;20. Different Proofs of the Divinity (1683)<br />
                Fran&#231;ois Diroys<br /><br />
                &#167;21. A Metaphysical Essay towards the Demonstration of a God, from the Steady and Immutable Nature of Truth (1687)<br />
                John Norris<br /><br />
                &#167;22. The Argument from Universal Aseity (1755)<br />
                Andr&#233;-Pierre Le Guay de Prémontval<br /><br />
                Further Reading<br />
                Index</><br></br><br></br>
                  {isMobile ? (
                    <div id="endorsements">
                      <hr></hr><p style={{paddingTop: '10px'}}>Go back to the top of the page <span style={{color: 'blue'}} onClick={() => scrollToElement('top')}>here</span></p>
                      {endorsements()}
                    </div>
                  ) : (
                    <>
                    </>
                  )}
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Proofs;
