import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/chrysippus.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
		Shelfmark LH 1, 1, 4 Bl. 20<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: 1706<br /><br />
		Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (175k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Notes on Pierre Bayle's "Dictionary", article 'Chrysippus'</title>
            <meta name="description" content="A transcription of NOTES ON PIERRE BAYLE'S 'DICTIONARY' ARTICLE 'CHRYSIPPUS', from 1706" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: NOTES ON PIERRE BAYLE'S <var>DICTIONARY</var> ARTICLE 'CHRYSIPPUS'</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 <small>Leibniz's <var>Theodicy</var> grew out of reading notes he made in 1706 on the various works of Pierre Bayle,<a name="1" href="#note1"><sup>1</sup></a> of which the following piece is one. The same passage from Bayle's <var>Dictionnaire historique et critique</var> (1702) that is the focus of this piece was later discussed in &#167;209 of the <var>Theodicy</var> (1710).</small><br /><br /><br />
		        [<b>LH 1, 1, 4 Bl. 20</b>]<br /><br />		

                <center>Bayle's <var>Dictionnaire</var>, 2nd edition, Article 'Chrysippus', note T, page 930.</center><br />
                'In his work on providence, Chrysippus examined among other questions this one: &quot;Did the nature of things, or the providence that made the world and humankind, also make the diseases to which men are subject?&quot; He responds that nature's principal intention was not to make men diseased, for that would not be in keeping with the cause of all goods; rather, in preparing and producing a number of great things that are very well ordered and of great usefulness, nature found that some drawbacks came about as a result, and as such they were not in keeping with its original design and purpose. Thus evils came about after the work, and have existed only as consequences. For the formation of the human body, Chrysippus said, the finest idea and the very nature<a name="2" href="#note2"><sup>2</sup></a> of the work demanded that the head be composed of a tissue of thin, fine bones, but because of that it had to have the disadvantage of not being able to resist blows. Nature made health, and at the same time it had to be that, by a kind of concomitance, the source of diseases was opened up. The same is true with regard to virtue; the direct action of nature, which gave rise to virtue, produced by indirect consequence the range of vices. I have not translated literally, why is why I give here the actual Latin of Aulus Gellius, for the benefit of those who understand that language (Aulus Gellius, book 6, chapter 1).'<a name="3" href="#note3"><sup>3</sup></a>  I don't think that a pagan could have said anything more reasonable given his ignorance of the fall of the first man, a fall which we have been able to know only by revelation, and which is the true cause of our woes. If we had a number of similar extracts from Chrysippus' works, or rather if we had his works, we would have a more favourable idea of the beauty of his genius than we do have.

                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> See for example those in Grua II, pp. 491-4.<br />
                <a name="note2" href="#2">2.</a> Bayle has 'utility' rather than 'nature'.<br />
                <a name="note3" href="#3">3.</a> Pierre Bayle, <var>Dictionnaire historique et critique</var> (Rotterdam, 1702, 2nd edn), p. 930 (article 'Chrysippus', note T).<br /><br /><br />
                &#169; Lloyd Strickland 2014
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
