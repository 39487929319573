import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/divination.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
		Shelfmark LH 1, 20 Bl. 211r<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: after 1692<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (198k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>On reason and divination</title>
            <meta name="description" content="An English translation of ON REASON AND DIVINATION by Gottfried Wilhelm Leibniz, from after 1692" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: ON REASON AND DIVINATION</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 <div id="note">On the reverse side of the manuscript, Leibniz wrote “To Her Electoral Highness, Madam the Electress of Brunswick”, indicating that the text was written after 1692, the year in which Brunswick-Lüneburg became an electorate.</div><br /><br />
                [<b>LH 1, 20 Bl. 211r</b>]<br /><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Man surpasses beast as genii surpass man. And just as beasts’ instinct sometimes imitates human reason, so human instinct<a name="1" href="#note1"><sup>1</sup></a> (which is often prophetic) sometimes imitates the divinatory power of genii. Therefore, we have these degrees of substance, and in them these degrees of presentiment:<br clear = "all" /><br />
        <table width="100%">
            <tr>
                <td width="15%">&nbsp;</td>
                <td width="30%">Beast<br />Instinct</td>
                <td width="30%">Man<br />Reason</td>
                <td width="25%">Genius<br />Divination</td>
            </tr>
        </table>
        <br clear = "all" />
        For there should be no doubt that intelligences are so superior to man that they perceive by a kind of external sense, and so to speak sniff out, those things which we scarcely—indeed not even scarcely—attain by a long chain of reasonings. And this is what mystics sometimes want a sort of divine power in us to reveal in the very silence of reason. For even though reason never ceases in us, sometimes the agitation of reason by the appetite<a name="2" href="#note2"><sup>2</sup></a> and so to speak care of possession ceases, as in dreams where, free from emotions, we sometimes see things as if they were indifferent to us and located far below us.
        <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> Leibniz may have intended to write &quot;human reason&quot; here.<br />
                <a name="note2" href="#2">2.</a> appetite │ and emotion │ <var>deleted</var>.<br /><br /><br />
                &#169; Lloyd Strickland 2005. Revised 2024<br />
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
