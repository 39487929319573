import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/onarnauld.pdf'; /*Change pdf*/
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {       /*Change left hand panel content*/
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
        Manuscript held by G. W. Leibniz Bibliothek, Hanover<br />
        Shelfmark LBr 16 Bl. 44-45<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        Date: 23 March 1685<br /><br />
        Translated from the French<br /><br />
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (258k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>Remarks on the Person and Passions of the otherwise so Famous and Very Learned and Able Mr. Abbé Antoine Arnauld</title> {/*Change title*/}
            <meta name="description" content="An English translation of 'REMARKS ON THE PERSON AND PASSIONS OF THE OTHERWISE SO FAMOUS AND VERY LEARNED AND ABLE MR. ABBÉ ANTOINE ARNAULD' by Landgrave Ernst von Hessen Rheinfels, from 23 March 1685" /> {/*Change meta description*/}
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LANDGRAVE ERNST VON HESSEN-RHEINFELS: REMARKS ON THE PERSON AND PASSIONS OF THE OTHERWISE SO FAMOUS AND VERY LEARNED AND ABLE MR. ABBÉ ANTOINE ARNAULD</h2> {/*Change title*/}
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br /> {/*Change page content*/}
                 <small>Background: This piece was sent to Leibniz by the Landgrave on 23 March 1685. Leibniz had his amanuensis make a copy, from which the following translation has been made.</small><br /><br /><br />
                 [<b>LBr 16 Bl. 44r</b>]<br /><br />
                 This should not be shown to anyone<br /><br />

<center>Remarks on the Person and Passions of the otherwise so Famous and Very Learned and Able Mr. Abbé Antoine Arnauld</center><br />

&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mr. Arnauld is certainly, in his own way, a great man and one of the best writers of this century. But he is also, and with all that, still a man and subject to certain passions, namely, he is very choleric, sometimes a bit too hasty and very irritable, as I have experienced on three different occasions while conversing with him.<br />
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1) About fifteen or sixteen years ago, I once found myself in Paris at his residence in the Faubourg St. Marceau, where I believe, to show me the most eminent men of his party, he had gathered five or six of them, among whom were Mr. Nicole and Mr. St. Amand. The conversation heading in this way, I mentioned to him a certain short prayer, about the length of the Pater Noster,<a name="1" href="#note1"><sup>1</sup></a> in which everything is implicitly found, and, moreover, contained in a certain order, and which could be said not only by every Christian but also by every Jew and Mohammedan. The prayer went as follows:<br />
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Short and implicitly all-encompassing prayer: “Oh! 1) Unique, 2) eternal, 3) all-powerful, 4) all-knowing, and 5) omnipresent God, 6) unique, true, and supreme good, I, your poor creature, above all things 1) believe and 2) hope in you; 3) I love you above all things; 4) I adore you; 5) I praise you; 6) I thank you, and 7) resign myself to you; 1) forgive me my sins, and give 2) both to me and to all men what, according to your holy will, is proper for our temporal and eternal good, and 3) preserve us from all evil. Amen.”<br /><br />

[<b>LBr 16 Bl. 45v</b>]<br /><br />

	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;As soon as he heard this, he [Arnauld] exclaimed, while we were all seated in a circle, “This is worthless, because there is no mention of our Lord Jesus Christ in it.” I was at first, to tell the truth, a little taken aback by such a swift and harsh censure; nevertheless, I did not lose my composure and immediately replied: “It must be, then, that for the same reason, the Lord's Prayer and so many good prayers found in the Acts and Epistles of the Apostles are worthless, and especially the one that St. Peter offered in Acts 1 on the occasion of casting lots for the successor to Judas's apostleship,<a name="2" href="#note2"><sup>2</sup></a> in which Christ or the Holy Trinity is not always explicitly mentioned, although they are always implicitly understood.” And there you have it: my good man on edge, and we fell silent for a bit, to catch our breath.<br />
	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2) The second time was in 1671, and the last time I was in Paris, where, during our conversation, I praised a certain apology that one of his friends had previously written expressly for him against Father Petau and the Jesuits, on the occasion of their disputes over [Arnauld's] <var>On Frequent Communion</var>.<a name="3" href="#note3"><sup>3</sup></a> I don't know by what bad humour, although it was extremely well written and very much in his favour, he also said very brusquely: “That is worthless.” When on another day or visit I complained about this to one of his associates, and perhaps it was Mr. Nicole, he retracted his statement.<br />
	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3) The third time was the same year, in a discussion where, after I merely mentioned and named Father Véron, he brusquely exclaimed [<b>LBr 16 Bl. 45r</b>] that Father Véron was an ass. Now, although I cannot nor wish to deny that here and there he [Véron] sometimes had rather ridiculous manners, and that it would have been better if he had refrained from writing <var>The Silencing of the Jansenists</var> at the behest of the Jesuits,<a name="4" href="#note4"><sup>4</sup></a> it cannot be denied that, despite all this, Father François Véron had been a good servant of God, and a very pious, learned, and zealous theologian whose method has been very usefully employed by great bishops and controversialists, and employed also against the Reformed, and that he was anything but an ass.<br />
	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Moreover, in various correspondences I have had with him [Arnauld], I have also noticed his weakness, namely that he is very hot-tempered and irritable. Nevertheless, one should not be too surprised by this, considering his great age and the many labours and persecutions he has had to endure from all sides. And then, where is the man who is entirely exempt from all shortcomings in this, our life?<br />
	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For the rest, he is a man very united with his God, austere with himself, and not at all interested in the world or worldly desires. In an extraordinary way, he has countered all the seemingly plausible arguments that Calvinist theologians have ever put forward, making himself highly commendable to the Church, and even receiving approval from the current Pope, although the Jesuits have very much envied him. But I do not know how he will ever be able to satisfy himself or all his good friends if he does not dare to undertake his own defence against the malicious book entitled <var>His Spirit</var>,<a name="5" href="#note5"><sup>5</sup></a> as in this, he will find himself [<b>LBr 16 Bl. 44v</b>] all the more hampered since, without associating himself with the courts of both Rome and France, he will not be able to answer as well, or duly to all, in sincerity and without dissimulation.<br />
	&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I forgot to mention in its proper place that when the discussion with Mr. Arnauld touched on the first article, namely the concept of that short prayer, I do not remember having told him at the time that, having sent it (printed in Italian) a few years earlier from Venice, where I was then, to Pope Alexander VII, he not only graciously thanked me but even approved it. And if I had only wanted to, I could have obtained indulgences for it.
                <br /><br /><br />
                <hr className='rightcol-hr' />
                <br /><br />
                NOTES:<br /><br />
                <a name="note1" href="#1">1.</a> That is, the “Our Father” or Lord's prayer.<br />
                <a name="note2" href="#2">2.</a> Acts 1.24-25: “Lord, you know everyone’s heart. Show us which of these two you have chosen to take over this apostolic ministry, which Judas left to go where he belongs.”<br />
                <a name="note3" href="#3">3.</a> That is, Antoine Arnauld, <var>De la fréquente communion</var> (Paris: Antoine Vitré, 1643).<br />
                <a name="note4" href="#4">4.</a> That is, François Véron, <var>La condemnation de la doctrine des jansenistes par cinq conciles francois, huict cents ans y a; selon les methodes de S. Augustin. Ou, Jansenii Gothescalcus haereticus; et, Le baillon des jansenistes</var> (Paris: Langlois, 1648).<br />
                <a name="note5" href="#5">5.</a> That is, [Pierre Jurieu], <var>L'esprit de M. Arnauld</var>, 2 vols. (Deventer: Jean Colombius, 1684).
                <br /><br /><br />
                  &#169; Lloyd Strickland 2024
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
