import '../App.css';
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import pdf from '../pdfs/allthings.pdf'; 
import { Helmet } from 'react-helmet';

function Page() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function renderLinks() {
    return (
      <div style={{lineHeight: isMobile ? '1' : '', fontSize: isMobile ? '15px' : ''}}>
        Source:<br /><br />
		<var>Opera Omnia volume 6, 1</var><br />
		Ludovic Dutens (ed)<br />
		p 315<br /><br />
		{isMobile ? '' : <hr className="centered-hr" />}
		Date: January 1697 (?)<br /><br />
        Translated from the Latin<br /><br /> 
        {isMobile ? '' : <hr className="centered-hr" />}
        <Link to={pdf} target="_blank">View this translation in PDF format (89k)</Link>
      </div>
    );
  }
  
  return (
    <div>
        <Helmet>
            <title>All Things Are Full of Souls</title>
            <meta name="description" content="An English translation of ALL THINGS ARE FULL OF SOULS by Gottfried Wilhelm Leibniz, from January 1697 (?)" />
        </Helmet>
        <Routes>
          <Route path="/" element={
            <Container fluid className="Container">
              <Row className="main-content">
                <Col md={3} className={`LeftColumn ${isMobile ? 'mobile' : ''}`}>
                  {isMobile ? (
                    <></>
                  ) : (
                    <>
                    {renderLinks()}<br></br>
                 
                    </>
                  )}
                </Col>
                <Col md={8} className={`RightColumn ${isMobile ? 'mobile' : ''}`}>
                  <h2 id='top' className={`PageTitle ${isMobile ? 'mobile' : ''}`}>LEIBNIZ: ALL THINGS ARE FULL OF SOULS</h2>
                  {isMobile ? (
                    <>
                    {renderLinks()}  
                    </>
                  ) : (
                    <>
                    
                    </>
                  )}
                 <br />
                 [<b>D VI 1, p315</b>]<br /><br />
                <var>Henry More</var> establishes the pre-existence of <var>souls</var>, that is, that created souls have existed with the world,<a name="1" href="#note1"><sup>1</sup></a> an opinion maintained long ago by Plato, Origen, and others. My opinion is that all things are, so to speak, full of souls, or things of an analogous nature, and that not even the souls of beasts perish.<a name="2" href="#note2"><sup>2</sup></a>
                <br /><br /><br />
                <hr className='rightcol-hr' />
                        <br /><br />
                        NOTES:<br /><br />
                        <a name="note1" href="#1">1.</a> See Henry More, <var>The Immortality of the Soul</var>, ed. Alexander Jacob (Dodrecht: Martinus Nijhoff, 1987), 145-155 (book II, chapters XII and XIII).<br />
                        <a name="note2" href="#2">2.</a> This last sentence is also found verbatim in Leibniz's letter to Johann Bernoulli of 28 December 1696/7 January 1697; see A III 7, 245.
                <br /><br /><br />
                        &#169; Lloyd Strickland 2018
                <br></br><br></br>
                </Col>
                <Col md={1}></Col>
              </Row>
              <Row className="footer"><h6>&copy; LLOYD STRICKLAND 2003-{new Date().getFullYear()}</h6></Row>
            </Container>
          } />
        </Routes>
      </div>
  );
}

export default Page;
